import React from 'react';
import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';
import { useMasterTermStore } from '../MasterTerm/Provider/useMasterTermStore';

function IvsCodeRenderer({ ivsCodeId, masterTermId }) {
    const masterTerms = useMasterTermStore(state => state.data);
    const ivsCode = masterTerms
        .find(x => x.masterTermId === masterTermId)
        .ivsCodes.find(x => x.ivsCodeId === ivsCodeId);
    return `${ivsCode.ivsCode} - ${ivsCode.modelDescription}`;
}

function DescriptionRenderer({ rowData: { ivsCodeId, masterTermId, description } }) {
    return ivsCodeId === null ? description : <IvsCodeRenderer ivsCodeId={ivsCodeId} masterTermId={masterTermId} />;
}

export default {
    id: idColumnDef('Id', 'agreementTermId'),
    name: {
        ...basicColumnDef('Name', 'name'),
        type: 'text',
        sortable: true,
        filter: true,
        width: 60,
        help: {
            helpId: 'name',
            helpText: 'The name of the term'
        }
    },
    devCode: {
        ...basicColumnDef('Model Code', 'devCode'),
        type: 'text',
        sortable: true,
        filter: true,
        noGrow: true,
        cellRenderer: ({ rowData: { ivsCodeId, devCode } }) => (ivsCodeId === null ? devCode : 'EXCLUSION'),
        help: {
            helpId: 'devCode',
            helpText: 'The dev code of the term'
        }
    },
    description: {
        ...basicColumnDef('Description', 'description'),
        type: 'text',
        sortable: true,
        filter: true,
        cellRenderer: props => <DescriptionRenderer {...props} />,
        help: {
            helpId: 'description',
            helpText: 'The description of the term'
        }
    },
    publicSector: {
        ...basicColumnDef('Public Sector', 'publicSector'),
        type: 'text',
        sortable: true,
        help: {
            helpId: 'publicSector',
            helpText: 'The public sector of the term'
        }
    }
};
