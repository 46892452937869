import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Button } from 'reactstrap';
import FormikText from '../../common/FormkControls/FormikText';
import { resetReducers } from '../../../actions/resetReducers';
import { Link } from 'react-router-dom';
import { apiUserLogin } from '../../../actions/ApiAuth/apiAuthUserLogin';
import { isAnyLoading } from '../../../reducers/Rest/loadingStates';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import LoginHeader from '../../app/LoginHeader';
import LoginFooter from '../../app/LoginFooter';
import { useCacheBusterContext } from '../../CacheBuster';

const LoginForm = props => {
    const [state, setState] = useState({
        email: process.env.REACT_APP_DEFAULT_USERNAME || ''
    });

    const handleSubmit = ({ email, password }) => props.logIn(email, password);

    const { loadingStates, className } = props;
    const { refreshCacheAndReload, loading, isLatestVersion } = useCacheBusterContext();

    if (isAnyLoading(loadingStates)) {
        return <ShowLoadingSpinner color="#fff" isLoading />;
    }

    const initialValues = {
        email: state.email,
        password: process.env.REACT_APP_DEFAULT_PASSWORD || ''
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={formikProps => {
                formikProps.formName = 'loginForm';
                return (
                    <form className={className} onSubmit={formikProps.handleSubmit}>
                        <LoginHeader />
                        <div className="mb-4">
                            <FormikText
                                fieldName="email"
                                placeholder="Email"
                                type="text"
                                {...formikProps}
                                handleChange={e => {
                                    setState({ email: e.target.value });
                                    formikProps.handleChange(e);
                                }}
                                hideLabel
                            />
                            <FormikText
                                fieldName="password"
                                placeholder="Password"
                                type="password"
                                {...formikProps}
                                hideLabel
                            />
                            <div className="d-grid grid-cols-2 grid-column-gap-2">
                                <div />
                                <Link className="nav-link m-0 p-0 grid-js-end" to="/forgotpassword/">
                                    Forgotten Password
                                </Link>
                            </div>
                        </div>
                        <Button
                            className="w-100"
                            color="primary"
                            data-test-id="login-button"
                            disabled={!formikProps.values.email || !formikProps.values.password}
                            type="submit"
                        >
                            Login
                        </Button>
                        {!loading && !isLatestVersion ? (
                            <div style={{ textAlign: 'center' }}>
                                <button
                                    className="btn"
                                    type="button"
                                    onClick={() => refreshCacheAndReload()}
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '18px',
                                        borderRadius: '0.5rem',
                                        border: 'none',
                                        backgroundColor: '#FFC107',
                                        color: '#555',
                                        padding: '7px'
                                    }}
                                >
                                    Update Available. Click to restart.
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <LoginFooter />
                    </form>
                );
            }}
        />
    );
};

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    login: state.login
});

const mapDispatchToProps = dispatch => ({
    logOut: () => dispatch(resetReducers()),
    logIn: (userName, password) => dispatch(apiUserLogin(userName, password))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
