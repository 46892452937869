import React from 'react';
import PropTypes from 'prop-types';
import { NavRouterLink } from './NavRouterLink/index';
import moment from 'moment';
import { Icons } from '../common/Icons';
import { permissions, useUsersPermission } from '../../reducers/Api/User/permissions';

const NavigationItems = props => {
    const { onLinkOpen, navigation } = props;

    const className = 'd-none d-md-inline-flex';

    const canOpenAgreementsAdminPage = useUsersPermission(permissions.AGREENENTS_ADMIN_PAGE).canRead;

    return (
        <React.Fragment>
            {navigation && navigation.home && !navigation.home.hideFromToolbar && (
                <NavRouterLink
                    className={className}
                    icon={Icons.home}
                    isVisible
                    link="/dashboard/home"
                    name="Home"
                    onLinkOpen={onLinkOpen}
                />
            )}
            {navigation && navigation.companies && !navigation.companies.hideFromToolbar && (
                <NavRouterLink
                    className={className}
                    icon={Icons.companies}
                    isVisible
                    link="/dashboard/companies"
                    name="Companies"
                    onLinkOpen={onLinkOpen}
                />
            )}
            {navigation && navigation.calendar && !navigation.calendar.hideFromToolbar && (
                <NavRouterLink
                    className={className}
                    icon={Icons.calendar}
                    isVisible
                    link={`/calendar/month/${moment().year()}/${moment().month() + 1}/${moment().date()}`}
                    name="Calendar"
                    onLinkOpen={onLinkOpen}
                />
            )}

            {navigation && navigation.calendar && canOpenAgreementsAdminPage && (
                <NavRouterLink
                    icon={Icons.agreements}
                    isVisible
                    link="/agreements/admin"
                    name="Agreements"
                    onLinkOpen={onLinkOpen}
                />
            )}

            {navigation &&
                Object.entries(navigation)
                    .filter(([key, value]) => value.isNamedDashboard && !value.hideFromToolbar)
                    .map(([key, value]) => (
                        <NavRouterLink
                            className={className}
                            icon={Icons.value.name}
                            isVisible
                            key={key}
                            link={`/dashboard/${key}`}
                            name={value.name}
                            onLinkOpen={onLinkOpen}
                        />
                    ))}
        </React.Fragment>
    );
};

NavigationItems.propTypes = {
    onLinkOpen: PropTypes.func,
    isSidebar: PropTypes.bool
};

export default NavigationItems;
