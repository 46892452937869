import moment from 'moment';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import meeting from '../../../containers/Meeting/meeting';
import { setPropertiesSyncing } from '../helper';

export function filterState(state, filter, newData) {
    const matchesState = filterToMatchFunction(filter);
    return state.filter(x => !newData.some(newComm => x.meetingId === newComm.meetingId) && !matchesState(x));
}

export function filterToMatchFunction({ meetingId, assigneeId, companyId, startDate, endDate }) {
    const start = startDate === undefined ? undefined : moment(startDate);
    const end = endDate === undefined ? undefined : moment(endDate);

    return meeting => {
        return (
            (!companyId || _.get(meeting, 'company.id', '') === companyId) &&
            (!assigneeId || _.get(meeting, 'assignee.id', '') === assigneeId) &&
            ((!start && !end) ||
                ((!start || start.isBefore(moment(meeting.startDate))) &&
                    (!end || end.isAfter(moment(meeting.startDate))))) &&
            (!meetingId || meeting.meetingId === meetingId)
        );
    };
}

const idField = meeting.id.field;

function updateStateBeforeSendingToServer(state, { data }) {
    const original = state.find(x => data[idField] === x[idField]);
    if (original) {
        setPropertiesSyncing(original, data);
    }
    data.syncing = true;
    return [data, ...state.filter(x => x[idField] !== data[idField])];
}

function postCommit(state, action) {
    const {
        meta: { modified },
        payload: { data: remote }
    } = action;
    return [remote, ...state.filter(x => modified[idField] !== x[idField] && x[idField] !== remote[idField])];
}

export default (state = [], action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return [];
        case ENDPOINTS.API.PAGE:
            if (!action.data.meetingFilter) return state;

            const withCompanies = action.data.meetings.map(x => {
                const company = (action.data.companies || []).find(company => company.companyId === x.companyId);
                return {
                    ...x,
                    company: company,
                    companyDescriptor: company?.descriptor || x.companyDescriptor
                };
            });
            return update(state, withCompanies, action.data.meetingFilter);
        case ENDPOINTS.API.MEETING_FILTER:
            return update(state, action.data, action.filter);
        case ENDPOINTS.API.MEETING_POST:
            return updateStateBeforeSendingToServer(state, action);
        case ENDPOINTS.API.MEETING_POST_COMMIT:
            return postCommit(state, action);
        default:
            return state;
    }
};

const update = (state, update, filter) => [...update, ...filterState(state, filter, update)];
