import React from 'react';
import css from './MonthDayNumberSelector.module.css';

const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function nth(dayNumber) {
    if (dayNumber > 3 && dayNumber < 21) return 'th';
    switch (dayNumber % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}

const MonthDayNumbersSelector = ({ value, onChange }) => {

    const [selectedDay, setSelectedDay] = React.useState(value || Array(12).fill(null));
    const [currentMonth, setCurrentMonth] = React.useState(0);
    const setMonthValue = (value) => {
        setSelectedDay(s => {
            const tmp = [...s];
            tmp[currentMonth] = tmp[currentMonth] === value ? null : value; //unselectable by clicking it again
            onChange && onChange(tmp);
            return tmp;
        })
    }

    return <div className={css.monthDayNumbersSelector}>
        {monthNames.map((month, index) =>
            <button className={css.monthName}
                data-active={(index === currentMonth ? "true" : "false")}
                key={month}
                onClick={() => setCurrentMonth(index)}
            >{month} {(
                selectedDay[index] === null
                    ? <span className={css.unselectedDate}>-</span>
                    : <span className={css.selectedDate}>{selectedDay[index]}{nth(selectedDay[index])}</span>
            )}</button>
        )}
        <div className={css.numbersGrid}>
            {Array(daysInMonth[currentMonth]).fill(null).map((_, index) => (
                <button data-active={(selectedDay[currentMonth] === index + 1 ? "true" : "false")} key={index}
                    onClick={() => setMonthValue(index + 1)}>{index + 1}{nth(index + 1)}</button>
            ))}
            <button className={css.noneButton} data-active={(selectedDay[currentMonth] === null ? "true" : "false")} onClick={() => setMonthValue(null)}>#None#</button>
        </div>
    </div>
}

export default MonthDayNumbersSelector;