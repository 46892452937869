import React, { useEffect, useState } from 'react';
import { Button, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import _ from 'lodash';
import InputRange from 'react-input-range';
import FormikText from '../FormkControls/FormikText';
import FormikDate from '../FormkControls/FormikDate';
import FormikSelectChoicelist from '../FormkControls/FormikSelectChoicelist';
import { SaveCancelButton } from '../SaveCancelButton';
import FormikErrors from '../FormkControls/FormikErrors';
import AuditSummary from '../CommonForms/AuditSummary';
import { DebugSection } from '../DebugSection';
import { DisplayField } from '../DisplayField';
import {
    mapColumnDefToCheckbox,
    mapColumnDefToChoiceList,
    mapColumnDefToDate,
    mapColumnDefToText
} from '../FilteredTable/tableAndFilterHelpers';
import FormikCheckbox from '../FormkControls/FormikCheckbox';
import ButtonGroup from '../ButtonGroup';
import ReactiveFormDropzone from './ReactiveFormDropzone';
import FormikHtmlEditor from '../FormkControls/FormikHtmlEditor';
import { useFilteredChoiceList } from '../Hooks/useChoiceList';
import useDebounce from '../Hooks/useDebounce';
import { FormikMultiselect } from '../FormkControls/FormikMultiselect';
import FormikMentions from '../FormkControls/FormikMentions';
import { ValidationProvider } from '../FormkControls/ValidationProvider';
import { FieldLabel } from '../DisplayField/FieldLabel';
import { useResources } from '../../../selectors/Api/useResources';
import FormikFormGroup from '../FormkControls/FormikFormGroup';

//TODO: Do we want a test to make sure things have formik props passed to them
// https://github.com/kentcdodds/advanced-react-patterns-v2/blob/master/src/exercises-final/02.extra.js

const MapToButtonGroup = ({
    resources,
    columnDef,
    options,
    className,
    formikProps,
    canChange,
    submits = true,
    customOnClick
}) => (
    <ButtonGroup
        canChange={canChange}
        choiceList={options || _.get(resources, `choiceList.${columnDef.choiceList}`, [])}
        className={className}
        onButtonClick={x => {
            formikProps.setFieldValue(columnDef.field, x, true);
            submits && formikProps.handleSubmit();
            if (customOnClick) {
                customOnClick();
            }
        }}
        selectedKey={formikProps.values[columnDef.field]}
    />
);

function RenderForm({
    props,
    cancel,
    children,
    debounced = false,
    disableSave,
    hideCancelButton,
    hideMetaData,
    hideSaveButton,
    isSaving,
    saveButtonContent,
    onChange,
    validationSchema,
    ...rest
}) {
    const resources = useResources();

    function handleChange(formikOnChange, event) {
        formikOnChange && formikOnChange(event);
        onChange && onChange(event);
    }

    useDebouncedField(props.values, e => handleChange(undefined, e), debounced);
    const formikProps = {
        ...props,
        handleChange: event => {
            if (!debounced) handleChange(props.handleChange, event);
            else props.handleChange(event);
        }
    };

    return (
        <ValidationProvider yup={validationSchema}>
            <form onSubmit={formikProps.handleSubmit}>
                <div className="row">
                    {React.Children.map(children, (child, index) => {
                        return React.cloneElement(child, { formikProps, index, resources, ...rest });
                    })}
                </div>
                <div className="grid-cs-2-form">
                    <FormikErrors {...formikProps} />
                </div>
                <DebugSection sections={[{ name: 'Form Values', object: formikProps.values }]} />
                <div className="d-flex justify-content-between">
                    {formikProps.values.createdDate && !hideMetaData && (
                        <AuditSummary
                            choiceList={resources.choiceList}
                            createdBy={formikProps.values.createdBy}
                            createdDate={formikProps.values.createdDate}
                            updatedBy={formikProps.values.updatedBy}
                            updatedDate={formikProps.values.updatedDate}
                        />
                    )}

                    {hideSaveButton ? null : (
                        <SaveCancelButton
                            disableSave={disableSave}
                            hideCancelButton={hideCancelButton}
                            isSaving={isSaving}
                            onCancel={cancel}
                            pullRight
                            saveButtonContent={saveButtonContent}
                        />
                    )}
                </div>
            </form>
        </ValidationProvider>
    );
}

const ReactiveForm = ({
    cancel,
    children,
    debounced = false,
    disableSave,
    enableReinitialize = false,
    handleSubmit,
    hideCancelButton,
    hideMetaData,
    hideSaveButton,
    isInitialValid = false,
    initialValues,
    isSaving,
    saveButtonContent,
    onChange,
    validationSchema,
    ...rest
}) => {
    return (
        <Formik
            cancel={cancel}
            enableReinitialize={enableReinitialize}
            initialValues={initialValues}
            isInitialValid={isInitialValid}
            onSubmit={handleSubmit}
            render={props => (
                <RenderForm
                    props={props}
                    cancel={cancel}
                    children={children}
                    debounced={debounced}
                    disableSave={disableSave}
                    hideCancelButton={hideCancelButton}
                    hideMetaData={hideMetaData}
                    hideSaveButton={hideSaveButton}
                    isSaving={isSaving}
                    onChange={onChange}
                    saveButtonContent={saveButtonContent}
                    validationSchema={validationSchema}
                    {...rest}
                />
            )}
            validationSchema={validationSchema}
        />
    );
};

export function useDebouncedField(formValue, onChange, enabled = true, delay = 500) {
    const [storedValue, setStoredValue] = useState(formValue);
    const value = useDebounce(formValue, delay);

    useEffect(() => {
        if (value !== storedValue && enabled) {
            onChange(value);
            setStoredValue(value);
        }
    }, [enabled, value, onChange, storedValue, setStoredValue]);
}

// index: index of the component, 1 is added to form the section number displayed on the screen
// title: heading of the section
// formikProps: a prop from the Formik form library, do not override from your component
// children: react children
// grow: change to false if the form body should always be underneath the section title
// size: boostrap column size, the default 12 is fullscreen
ReactiveForm.Section = ({ show = true, index, title, formikProps, children, grow = true, size = 12, ...rest }) => {
    const marginTop = index === 0 ? 'mt-2' : 'mt-xl-5 mt-3';

    // By default, the title is next to the form body on large screens, and underneath each other
    // on smaller screens. We can override this to make them be always underneath each other
    // no matter the size of the screen. It's useful for columns that are next to each other
    const classNameHeading = grow ? `col-12 col-md-4 ${marginTop}` : `col-12 ${marginTop}`;
    const classNameBody = grow && title ? `col-12 col-md-8 ${marginTop}` : `col-12 ${marginTop}`;

    return (
        ((_.isFunction(show) && show(formikProps)) || show === true) && (
            <div className={`col-12 col-md-${size}`}>
                <div className="row">
                    {title && (
                        <div className={classNameHeading}>
                            <span>
                                <span className="mr-2 logo-font form-subsection-number">{index + 1}</span>
                                <h4 className="d-inline logo-font form-subsection-heading">{title}</h4>
                            </span>
                        </div>
                    )}
                    <div className={classNameBody}>
                        {React.Children.map(children, child => {
                            if (React.isValidElement(child)) {
                                const hide = child.props.hide && child.props.hide(formikProps);
                                return hide ? null : React.cloneElement(child, { formikProps, ...rest });
                            }
                            return child;
                        })}
                    </div>
                </div>
            </div>
        )
    );
};

ReactiveForm.DisplayInColumns = ({
    columnClasses = ['col-12 col-sm-6 text-left px-0', 'col-12 col-sm-6 px-0'],
    children,
    formikProps,
    ...rest
}) =>
    React.Children.map(children, (child, index) => {
        const hide = child.props.hide && child.props.hide(formikProps);
        return hide ? null : (
            <div className={columnClasses[index % columnClasses.length]}>
                {React.cloneElement(child, { formikProps, ...rest })}
            </div>
        );
    });

function Slider({ columnDef, formikProps, minValue, maxValue, ...rest }) {
    const { setFieldValue, values } = formikProps;
    const value = values[columnDef.field];

    const onChange = value => {
        setFieldValue(columnDef.field, value);
    };

    return (
        <FormikFormGroup fieldName={columnDef.field} {...columnDef} useBigTitle={rest.useBigTitle}>
            <div className="mt-2 mb-4">
                <InputRange maxValue={maxValue} minValue={minValue} onChange={onChange} value={value} {...rest} />
            </div>
        </FormikFormGroup>
    );
}
ReactiveForm.Slider = Slider;

ReactiveForm.Text = ({ formikProps, columnDef, ...rest }) => (
    <FormikText {...mapColumnDefToText(columnDef)} {...rest} {...formikProps} />
);

ReactiveForm.Mentions = ({ columnDef, resources, ...rest }) => (
    <FormikMentions
        data={_.chain(resources)
            .get(`choiceList.${columnDef.mentions}`, [])
            .map(item => ({ id: item.key, display: item.value }))
            .value()}
        {...mapColumnDefToText(columnDef)}
        {...{ ...rest, columnDef }}
    />
);

ReactiveForm.HtmlEditor = ({ formikProps, columnDef, ...rest }) => (
    <DisplayField
        help={columnDef.help}
        title={columnDef.title}
        value={<FormikHtmlEditor fieldName={columnDef.field} {...formikProps} {...rest} />}
    />
);

ReactiveForm.Choicelist = ({ formikProps, columnDef, options, ...rest }) => (
    <FormikSelectChoicelist {...mapColumnDefToChoiceList(columnDef)} options={options} {...rest} {...formikProps} />
);

const personSelectorMapStateToProps = state => ({ people: state.people });
const PersonSelector = ({ people, formikProps, columnDef, companyId, ...rest }) => {
    const options = people
        .filter(person => (companyId ? person.companyId === companyId : true))
        .map(x => ({
            key: x.personId,
            value: x.descriptor
        }));
    return (
        <FormikSelectChoicelist {...mapColumnDefToChoiceList(columnDef)} {...rest} {...formikProps} options={options} />
    );
};
ReactiveForm.PersonSelector = connect(personSelectorMapStateToProps)(PersonSelector);

ReactiveForm.TextWithInputGroupAddons = ({
    formikProps,
    resources,
    buttonGroupColumnDef,
    canChange,
    submits,
    options,
    columnDef,
    ...rest
}) => (
    <FormikText
        inputGroupAddons={
            <MapToButtonGroup
                canChange={canChange}
                className="input-group-append"
                columnDef={buttonGroupColumnDef}
                formikProps={formikProps}
                options={options}
                resources={resources}
                submits={submits}
            />
        }
        {...mapColumnDefToText(columnDef)}
        resources
        {...rest}
        {...formikProps}
    />
);

ReactiveForm.TextWithSubmit = ({ formikProps, resources, buttonText, canChange, columnDef, ...rest }) => (
    <FormikText
        inputGroupAddons={
            <Button className="btn btn-primary" type="submit">
                {buttonText}
            </Button>
        }
        {...mapColumnDefToText(columnDef)}
        resources
        {...rest}
        {...formikProps}
    />
);

ReactiveForm.ButtonGroup = ({
    hideTitle = false,
    formikProps,
    options,
    submits,
    columnDef,
    resources,
    canChange,
    customOnClick,
    ...rest
}) => {
    return (
        <DisplayField
            title={!hideTitle && columnDef.title}
            value={
                <div className="mt-1">
                    <MapToButtonGroup
                        canChange={!canChange || canChange(formikProps)}
                        columnDef={columnDef}
                        customOnClick={customOnClick}
                        formikProps={formikProps}
                        options={options}
                        resources={resources}
                        submits={submits}
                    />
                </div>
            }
        />
    );
};

ReactiveForm.Checkbox = ({ formikProps, columnDef, ...rest }) => (
    <FormikCheckbox {...mapColumnDefToCheckbox(columnDef)} {...rest} {...formikProps} />
);

function TriStateCheckBox({
    columnDef: { field, title },
    debounced = false,
    disabled,
    formikProps: { setFieldValue, values },
    onFormValueChanged
}) {
    const onChange = value => {
        onFormValueChanged && onFormValueChanged(values, field, value);
    };

    const value = values[field];
    useDebouncedField(value, onChange, debounced);
    return (
        <div
            className={`checkbox-container ${disabled ? 'disabled' : ''}`}
            onClick={() => {
                if (!disabled) {
                    const newValue = value === false ? undefined : !value;
                    setFieldValue(field, newValue);

                    if (!debounced) onChange(newValue);
                }
            }}
        >
            <button
                className={`checkbox-checkmark ${
                    value === null || value === undefined ? 'minus' : value ? 'check' : 'times'
                }`}
                disabled={disabled}
                type="button"
            />
            {title || field}
        </div>
    );
}
ReactiveForm.TriStateCheckBox = TriStateCheckBox;

ReactiveForm.Date = ({ formikProps, columnDef, ...rest }) => (
    <FormikDate {...mapColumnDefToDate(columnDef)} {...rest} {...formikProps} />
);

ReactiveForm.ColumnValue = ({ formikProps, columnDef, options }) => {
    const value = formikProps.values[columnDef.field];
    const option = options && options.find(x => x.key === value);

    return (
        <DisplayField
            title={columnDef.title}
            value={<div className="mt-3 mb-4 ml-2">{option ? option.value : value}</div>}
        />
    );
};
ReactiveForm.Dropzone = ({ formikProps, columnDef, ...rest }) => (
    <DisplayField {...columnDef} value={<ReactiveFormDropzone {...rest} {...formikProps} {...columnDef} />} />
);

ReactiveForm.Link = ({ title, value, formikProps, link, className }) => (
    <DisplayField className={className} title={title} value={value(formikProps)} link={link} />
);

ReactiveForm.Value = ({ title, value, formikProps }) => (
    <DisplayField title={title} value={<div className="mt-3 mb-4 ml-2">{value(formikProps)}</div>} />
);

ReactiveForm.Visible = ({ formikProps, visible, children, ...rest }) => (
    <>
        {visible(formikProps.values)
            ? React.Children.map(children, child => {
                  if (React.isValidElement(child)) {
                      const hide = child.props.hide && child.props.hide(formikProps);
                      return hide ? null : React.cloneElement(child, { formikProps, ...rest });
                  }
                  return child;
              })
            : null}
    </>
);

ReactiveForm.Other = ({ formikProps, children }) => children(formikProps);

function CollapseSection({ formikProps, title, help, expand = false, children, ...rest }) {
    const [isOpen, setIsOpen] = useState(expand);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="mb-2">
            <FieldLabel className="cursor-pointer" expand={isOpen} help={help} large={true} onClick={toggle}>
                {title}
            </FieldLabel>
            <Collapse className="mt-2" isOpen={isOpen}>
                {React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        const hide = child.props.hide && child.props.hide(formikProps);
                        return hide ? null : React.cloneElement(child, { formikProps, ...rest });
                    }
                    return child;
                })}
            </Collapse>
        </div>
    );
}
ReactiveForm.Collapse = CollapseSection;

ReactiveForm.Multiselect = ({ columnDef: { field, title, choiceList }, formikProps, options, resources, ...rest }) => (
    <FormikMultiselect
        fieldName={field}
        options={options || _.get(resources, `choiceList.${choiceList}`, [])}
        title={title}
        {...formikProps}
        {...rest}
    />
);

//Relies on filter fields being set in resources choicelist
export function FilteredChoiceList({ formikProps, columnDef, isMulti, ...rest }) {
    const { setFieldValue, values } = formikProps;
    const { choiceList, field } = columnDef;
    const options = useFilteredChoiceList(choiceList, undefined, undefined, values, isMulti);

    const optionsCount = options.length;
    useEffect(() => {
        if (optionsCount === 0) {
            setFieldValue(field, undefined);
        }
    }, [setFieldValue, optionsCount, field]);
    return options.length > 0 ? (
        <ReactiveForm.Choicelist
            columnDef={columnDef}
            formikProps={formikProps}
            isMulti={isMulti}
            options={options}
            {...rest}
        />
    ) : null;
}

//Relies on filter fields being set in resources choicelist
export function FilteredCheckbox({ formikProps, columnDef, isDisabled }) {
    const { setFieldValue, values } = formikProps;
    const { choiceList, field } = columnDef;
    const options = useFilteredChoiceList(choiceList, undefined, undefined, values);
    const optionsCount = options.length;

    useEffect(() => {
        if (optionsCount === 0) {
            setFieldValue(field, undefined);
        }
    }, [setFieldValue, optionsCount, field]);

    return options.length > 0 ? (
        <ReactiveForm.Checkbox columnDef={columnDef} disabled={isDisabled(formikProps)} formikProps={formikProps} />
    ) : null;
}

export default ReactiveForm;
