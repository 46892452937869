import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import SidebarNavLink from './SidebarNavLink';
import { getNavigationItems } from '../../../config/navigation';
import { Icons } from '../../common/Icons';

const Sidebar = ({ onLinkOpen, user }) => {
    const navigationItems = getNavigationItems(user);

    return (
        <div className="sidebar-background-image">
            <ul className="nav flex-column">
                {navigationItems.home && (
                    <SidebarNavLink onLinkOpen={onLinkOpen} icon="home" isVisible name="Home" link="/dashboard/home" />
                )}
                {navigationItems.companies && (
                    <SidebarNavLink
                        onLinkOpen={onLinkOpen}
                        icon={Icons.companies}
                        isVisible
                        name="Companies"
                        link="/dashboard/companies"
                    />
                )}
                {navigationItems.calendar && (
                    <SidebarNavLink
                        onLinkOpen={onLinkOpen}
                        icon={Icons.calendar}
                        isVisible
                        name="Calendar"
                        link={`/calendar/month/${moment().year()}/${moment().month() + 1}/${moment().date()}`}
                    />
                )}
                {navigationItems.calendar && (
                    <SidebarNavLink
                        onLinkOpen={onLinkOpen}
                        icon={Icons.agreements}
                        isVisible
                        name="Agreements"
                        link={`/agreement`}
                    />
                )}
                {navigationItems.matrix && (
                    <SidebarNavLink
                        onLinkOpen={onLinkOpen}
                        icon={Icons.terms}
                        isVisible
                        name="Terms Matrix"
                        link={`/matrix`}
                    />
                )}
                {navigationItems &&
                    Object.entries(navigationItems)
                        .filter(([key, value]) => value.isNamedDashboard)
                        .map(([key, value]) => (
                            <SidebarNavLink
                                key={key}
                                onLinkOpen={onLinkOpen}
                                icon={value.icon}
                                isVisible
                                name={value.name}
                                link={`/dashboard/${key}`}
                            />
                        ))}
                {navigationItems.support && (
                    <SidebarNavLink
                        onLinkOpen={onLinkOpen}
                        icon={Icons.support}
                        isVisible
                        name="Support"
                        link={`/support/request/`}
                    />
                )}
            </ul>
        </div>
    );
};

const mapStateToProps = state => ({ user: state.user });

export default connect(
    mapStateToProps,
    {}
)(Sidebar);
