import create from 'zustand';
import { useDispatch } from 'react-redux';
import { apiAgreementNotificationsPost } from '../../../actions/Api/Agreement/apiAgreement';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import agreementNotificationDef from '../agreementNotificationDef';

const idField = agreementNotificationDef.id.field;
export const useAgreementNotificationStore = create(set => ({
    ...storeWithModal(set, idField),
    data: []
}));

export function useUpdateNotifications() {
    const updateNotificationsFromFetch = useAgreementNotificationStore(state => state.updateDataFromFetch);
    const setError = useAgreementNotificationStore(state => state.setError);
    const dispatch = useDispatch();

    return notifications => {
        return dispatch(apiAgreementNotificationsPost(notifications))
            .then(response => {
                updateNotificationsFromFetch(response, false, notifications);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
