import create from 'zustand';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import { useDispatch } from 'react-redux';
import { apiRolePermissionPost } from '../../../actions/Api/Agreement/apiAgreement';

const rowKey = 'id';
export const useEngageRolePermissionsStore = create(set => storeWithModal(set, rowKey));

export function useUpdateRolePermission() {
    const updateData = useEngageRolePermissionsStore(state => state.updateDataFromFetch);
    const setError = useEngageRolePermissionsStore(state => state.setError);
    const dispatch = useDispatch();

    return rolePermission => {
        return dispatch(apiRolePermissionPost(rolePermission))
            .then(response => {
                updateData([response], false, [rolePermission]);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
