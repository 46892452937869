import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { FieldLabel } from '../DisplayField/FieldLabel';

const defaultStyle = {
    control: {
        marginBottom: '15px'
    },
    '&multiLine': {
        control: {
            minHeight: 64
        },
        highlighter: {
            padding: 9
        },
        input: {
            padding: 9,
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            color: '#495057'
        }
    },
    '&singleLine': {
        display: 'inline-block',
        width: 180,
        highlighter: {
            padding: 1
        },
        input: {
            padding: 1,
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            color: '#495057'
        }
    },
    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            borderRadius: 'calc(0.3rem - 1px)',
            fontSize: 14,
            maxHeight: '300px',
            overflowY: 'auto'
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#064f7a',
                color: 'white'
            }
        }
    }
};
const defaultMentionStyle = {
    backgroundColor: '#cee4e5'
};

const FormikMentions = ({ data, formikProps, columnDef, title, inline }) => {
    return (
        <React.Fragment>
            <FieldLabel className={inline ? 'd-inline-block' : ''} help={columnDef.help}>
                {title}
            </FieldLabel>
            <MentionsInput
                markup="@[__display__](user:__id__)"
                onChange={(_event, newValue, newPlainTextValue, mentions) => {
                    formikProps.setFieldValue('mentionItem', { message: newPlainTextValue, mentions }, false);
                    formikProps.setFieldValue(columnDef.field, newValue, true);
                }}
                style={defaultStyle}
                value={formikProps.values[columnDef.field]}
            >
                <Mention
                    data={data}
                    renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                        <div className={`user ${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>
                    )}
                    style={defaultMentionStyle}
                    trigger="@"
                />
            </MentionsInput>
        </React.Fragment>
    );
};

export default FormikMentions;
