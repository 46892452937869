import React from 'react';
import { useSelector } from 'react-redux';
import BaseTableInCard from '../../common/BaseTable/BaseTableInCard';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import useDimensions from '../../common/Hooks/useDimensions';

export default function AgreementRulesTable() {
    const salesChannels = useChoiceList('salesChannel');
    const choiceList = useSelector(state => state.resources.choiceList);
    const dependOnSalesChannel = Object.entries(choiceList).filter(f =>
        f[1].some(s => s.filterOnChoiceList === 'salesChannel')
    );

    const tableData = salesChannels.map(m => ({
        id: m.value,
        salesChannel: m.value,
        ...dependOnSalesChannel.reduce((acc, f) => {
            acc[f[0]] = f[1].filter(v => v.filterOnValue === m.key);
            return acc;
        }, {})
    }));

    const [ref, { y }] = useDimensions({ liveMeasure: false });

    const columns = [
        {
            field: 'salesChannel',
            title: 'Sales Channel',
            cellRenderer: ({ cellData }) => <div className="align-self-start">{cellData}</div>
        },
        ...dependOnSalesChannel.map(m => ({
            field: m[0],
            title: m[0],
            cellRenderer: ({ cellData }) => (
                <div className="align-self-start">
                    {cellData.map(m => (
                        <div key={m.key}>{m.value}</div>
                    ))}
                </div>
            )
        }))
    ];

    return (
        <>
            <BaseTableInCard
                cardTitle="Agreement Rules"
                columns={columns}
                data={tableData}
                expandAll={true}
                groupBy={['salesChannel']}
                helpId="agreementRulesTableHeading"
                refForTableHeight={ref}
                rowKey="id"
                tableSizeStyle={{ height: `calc(100vh - ${y}px - 1rem`, width: '100%' }}
            />
        </>
    );
}
