import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { FORM_NAMES } from '../../../actions/formNames';
import { getPrimaryAddress } from '../../../reducers/Api/Company/company';
import ReactiveForm from '../../common/ReactiveForm';

import meeting from '../meeting';
import validation from '../validation';

const FORM_NAME = FORM_NAMES.EDIT_MEETING;

class EditMeetingForm extends React.Component {
    handleSubmit = formValues => {
        formValues.descriptor = formValues.subject;
        const meeting = {
            ...formValues,
            personId: formValues.personId ? parseInt(formValues.personId, 10) : undefined,
            assigneeId: formValues.assigneeId ? parseInt(formValues.assigneeId, 10) : undefined,
            companyId: formValues.companyId ? parseInt(formValues.companyId, 10) : undefined
        };

        this.props.save(meeting, this.props.onCancel);
    };

    emptyForm = () => {
        const {
            user: { userId, descriptor },
            meetingId,
            companyId,
            person
        } = this.props;

        const startDate = moment()
            .hour(9)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        const endDate = moment()
            .hour(10)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        return {
            activityRegarding: '',
            action: 'Meeting',
            companyId,
            meetingId,
            subject: '',
            details: '',
            status: 'Pending',
            location: '',
            assigneeId: userId.toString(),
            assigneeName: descriptor,
            personId: (person && person.id) || '',
            meetingMode: '',
            startDate,
            endDate,
            createdBy: userId,
            createdDate: undefined,
            updatedBy: userId,
            updatedDate: undefined
        };
    };

    render() {
        const { meetingId, companyId, meetings, companies, onCancel, resources } = this.props;
        let initialValues = meetings.find(x => x.meetingId === meetingId);

        if (!initialValues && meetingId < 0) {
            initialValues = this.emptyForm();
            initialValues.meetingId = meetingId;
        }
        console.log(initialValues);
        initialValues.assigneeId = initialValues.assigneeId.toString();
        initialValues.companyId = initialValues.companyId.toString();
        initialValues.personId = initialValues.personId ? initialValues.personId.toString() : '';

        const primaryAddress = getPrimaryAddress(companies, companyId);
        if (primaryAddress) {
            initialValues.location = primaryAddress.descriptor;
        }

        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={onCancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Choicelist columnDef={meeting.action} disabled />
                    <ReactiveForm.Text columnDef={meeting.subject} />
                    <ReactiveForm.Text columnDef={meeting.details} type="textarea" />
                    <ReactiveForm.Choicelist columnDef={meeting.status} />
                    <ReactiveForm.Choicelist
                        columnDef={meeting.activityRegarding}
                        filter={x => x.value.startsWith('BMW') && x.value !== 'BMWCompanyFollowUpCall'}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={meeting.activityResult}
                        filter={x =>
                            ['AppointmentHeld', 'ApptPostCancClient', 'ApptPostCancProspect'].includes(x.value)
                        }
                        isClearable={true}
                    />
                    <ReactiveForm.Choicelist columnDef={meeting.meetingMode} />
                    <ReactiveForm.Text columnDef={meeting.location} />
                    {/* meetingMode is not on requirements but things don't make sense without it, see BE-117 */}
                    <ReactiveForm.Choicelist columnDef={meeting.assigneeId} />
                    <ReactiveForm.Date
                        showTimeSelect={true}
                        columnDef={meeting.startDate}
                        customFieldSetValue={(setFieldValue, value) =>
                            setFieldValue(
                                'endDate',
                                moment(value)
                                    .add(1, 'hours')
                                    .startOf('minute')
                                    .format(REST_TIME_FORMAT)
                            )
                        }
                    />
                    <ReactiveForm.Date showTimeSelect={true} columnDef={meeting.endDate} />

                    {/* <ReactiveForm.Choicelist columnDef={meeting.rating} /> */}
                    {/* <ReactiveForm.Text columnDef={meeting.feedback} type="textarea" /> */}
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    meetings: state.meetings,
    communications: state.communications,
    companies: state.companies,
    user: state.user,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (meeting, cancel) => {
        dispatch(apiMeetingPost(meeting));
        if (cancel) {
            cancel();
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditMeetingForm);
