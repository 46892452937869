export const agreementPermissions = {
    headerForm: 'HeaderForm',
    opportunitySummary: 'OpportunitySummary',
    anticipatedVolumes: 'AnticipatedVolumes',
    contractSignatories1: 'ContractSignatories1',
    contractSignatories2: 'ContractSignatories2',
    leasingCompanies: 'LeasingCompanies',
    agreementChecksSection: 'AgreementChecksSection',
    completeDraftButton: 'CompleteDraftButton',
    completeAddendumButton: 'CompleteAddendumButton',
    createAddendumButton: 'CreateAddendumButton',
    checksVisible: 'ChecksVisible',
    passChecksButton: 'PassChecksButton',
    rejectButton: 'RejectButton',
    approveButton: 'ApproveButton',
    approveAddendumButton: 'ApproveAddendumButton',
    cancelAddendumButton: 'CancelAddendumButton',
    customerTerminatedButton: 'CustomerTerminatedButton',
    bMWTerminatedButton: 'BMWTerminatedButton',
    makeLiveButton: 'MakeLiveButton',
    moveToMatrix: 'MoveToMatrix',
    baseDiscount: 'BaseDiscount',
    discretionaryDiscount: 'DiscretionaryDiscount',
    nextBaseDiscount: 'NextBaseDiscount',
    nextDiscretionaryDiscount: 'NextDiscretionaryDiscount',
    totalDiscount: 'TotalDiscount',
    publicSectorDiscount: 'PublicSectorDiscount',
    createExceptions: 'CreateExceptions',
    createAddendumExceptions: 'CreateAddendumExceptions',
    rebate: 'Rebate',
    nextRebate: 'NextRebate',
    activeSupportBonus: 'ActiveSupportBonus',
    nextActiveSupportBonus: 'NextActiveSupportBonus',
    deleteDraftButton: 'DeleteDraftButton',
    passAddendumChecksButton: 'PassAddendumChecksButton',
    failAddendumChecksButton: 'FailAddendumChecksButton',
    rejectAddendumButton: 'RejectAddendumButton',
    failChecksButton: 'FailChecksButton'
};

export function getPermission(agreementPermission, agreementWorkflow) {
    return (
        (agreementWorkflow || []).find(x => x.permission === agreementPermission) || {
            permission: agreementPermission,
            isEnabled: false,
            isVisible: false
        }
    );
}

const StatusColors = {
    CHPE: 'neutral',
    APRV: 'positive',
    ADOK: 'positive',
    ADDE: 'negative',
    DECL: 'negative',
    CLDO: 'negative',
    CUTE: 'negative',
    BWTE: 'negative',
    APAW: 'positive',
    CHFA: 'negative',
    DELT: 'negative'
};

export function getStatusColor(status) {
    return StatusColors[status] || 'neutral';
}
