import React from 'react';
import { Alert } from 'reactstrap';
import * as Yup from 'yup';
import { FORM_NAMES } from '../../../actions/formNames';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef, choiceListColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useResources } from '../../../selectors/Api/useResources';
import { useChoiceListObject } from '../../common/Hooks/useChoiceList';
import { useAgreementContext } from '../Provider/AgreementProvider';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import agreementNotificationDef from '../../AgreementNotifications/agreementNotificationDef';
import { useAgreementNotificationStore } from '../../AgreementNotifications/Provider';
import NotificationsToBeSend from './NotificationsToBeSend';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const evidenceMandatoryValidationSchema = Yup.object().shape({
    evidence: Yup.array().required('Evidence is required.')
});

function AgreementStatusChangeReason({ statusFrom, statusTo, cancel, permission, buttonText, evidenceMandatory, helpText }) {
    const agreement = useAgreementsStore(state => state.agreement);
    const agreementNotification = useAgreementNotificationStore(state => state.data).find(
        agreementNotification => agreementNotification.agreementStatus === statusTo
    );

    const sendToCustomer = agreementNotification?.sendToCustomer;
    const emailSubject = agreementNotification?.emailSubject;
    const emailBody = agreementNotification?.emailBody;

    const initialValues = {
        ...agreement,
        AuditComments: '',
        emailSubject,
        emailBody
    };

    const { updateAgreementStatus, saving } = useAgreementContext();
    const agreementStatuses = useChoiceListObject('agreementHeaderStatus');
    const resources = useResources();

    const statusFromText = agreementStatuses[statusFrom];
    const statusToText = agreementStatuses[statusTo];
    const entity = {
        companyId: agreement.companyId,
        entityId: agreement.agreementId,
        entityName: 'agreement',
        description: `Agreement Status Change from: ${statusFromText} to ${statusToText}`
    };

    const save = form => {
        updateAgreementStatus(agreement.agreementId, permission, statusFrom, statusTo, form)
            .then(() => {
                cancel();
            })
            .catch(err => console.error('error', err));
    };

    return (
        <>
            {helpText ? <Alert color="info">{helpText}</Alert> : null}
            <ReactiveForm
                cancel={cancel}
                formName={FORM_NAME}
                handleSubmit={save}
                hideMetaData={true}
                initialValues={initialValues}
                isSaving={saving}
                resources={resources}
                saveButtonContent={buttonText}
                validationSchema={evidenceMandatory ? evidenceMandatoryValidationSchema : undefined}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Text columnDef={{ ...basicColumnDef('Comments', 'comment') }} />
                    <ReactiveForm.Dropzone columnDef={{ ...basicColumnDef('Evidence', 'evidence') }} entity={entity} />
                    <ReactiveForm.Choicelist
                        columnDef={{
                            ...choiceListColumnDef('Additional Notifees', 'peopleForAgreement', 'userAuthors', true),
                            help: {
                                helpId: 'peopleForAgreement',
                                helpText: 'Send the notifications to these people too'
                            }
                        }}
                        isMulti={true}
                    />
                    {sendToCustomer && <ReactiveForm.Text columnDef={agreementNotificationDef.emailSubject} disabled />}
                    {sendToCustomer && (
                        <ReactiveForm.HtmlEditor columnDef={agreementNotificationDef.emailBody} disabled />
                    )}
                    <NotificationsToBeSend statusTo={statusTo} />
                </ReactiveForm.Section>
            </ReactiveForm>
        </>
    );
}

export default AgreementStatusChangeReason;
