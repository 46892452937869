import pipe from 'lodash/fp/pipe';
import filter from 'lodash/fp/filter';
import allPass from 'lodash/fp/allPass';
import sortBy from 'lodash/fp/sortBy';
import forEach from 'lodash/fp/forEach';
import moment from 'moment';
import { calendarOptions, overrideIcon } from '../../calendarOptions';
import { getChoiceListValue } from '../../../containers/common/LabelText';

const isAssignedToUser = (communication, userId) => communication.assigneeId === userId;
const belongsToCompany = (communication, companyId) => communication.companyId === companyId;

const inFuture = communication => moment(communication.start).isAfter(moment());
const isNext7Days = communication =>
    moment(communication.start).isBefore(
        moment()
            .startOf('day')
            .add(7, 'day') /*.add(-1, 'month')*/
    ) && moment(communication.start).isAfter(moment().startOf('day'));

const isPending = communication => communication.status === 'Pending';
export const isAppointment = communication => communication.category === 'Appointment';
export const isTask = communication => communication.category === 'Task';
export const isMeeting = communication => communication.eventType === 'Meeting';
export const isPhoneOut = communication => communication.eventType === 'PhoneOut';
export const isNotCancelled = communication => communication.status !== 'Cancelled';

export const isMeetingOrPhoneOut = communication => ['Meeting', 'PhoneOut'].includes(communication.eventType);
export const isEmailOut = communication => communication.eventType === 'EmailOut';

export const isVisitAction = communication => communication.eventType === 'VisitReport';

const isCsmEvent = communication => communication.activityRegarding === 'CSMEvent';
const isHoliday = communication =>
    communication.subject === 'Holiday' || communication.activityRegarding === 'CSMAnnualLeave';
export const isAdminDay = communication =>
    (communication.descriptor && communication.descriptor.indexOf('Admin Day') > -1) ||
    communication.activityRegarding === 'CSMAdminDay';

export const isPsaDay = communication => {
    return isAdminDay(communication) || isHoliday(communication) || isCsmEvent(communication);
};

const isBeforeNow = communication => new Date(communication.startDate).getTime() < Date.now();
const isSameDay = (communication, day) => moment(communication.start).isSame(day, 'day');

export const filterIsSameDayMeeting = (communications, momentDay) =>
    communications.filter(x => isSameDay(x, momentDay) && isMeeting(x));

export const filterAssignedToUser = (communications, userId) =>
    communications.filter(communication => isAssignedToUser(communication, userId)).filter(x => isNotCancelled(x));

export const filterBelongsToCompany = (communications, companyId) =>
    communications.filter(communication => belongsToCompany(communication, companyId));

export const filterChildCommunications = (communications, communicationId) =>
    communications.filter(communication => communication.parentCommunicationId === communicationId);

export const filterTasksBelongingToCommunication = pipe(
    filterChildCommunications,
    filter(allPass([isTask])),
    sortBy('start')
);

export const filterOutstandingUserTasks = pipe(
    filterAssignedToUser,
    filter(allPass([isBeforeNow, isVisitAction, isPending])),
    sortBy('start')
);

export const filterCompanyVisitActions = pipe(
    filterBelongsToCompany,
    filter(allPass([isVisitAction])),
    sortBy('start')
);

export const filterNext7Days = pipe(
    filterAssignedToUser,
    filter(allPass([isNext7Days, isNotCancelled])),
    sortBy('start')
);

export const vwCompanyCommunications = pipe(
    filterBelongsToCompany,

    sortBy('start')
);

export const psaFilterCompanyCommunications = pipe(
    filterBelongsToCompany,
    filter(allPass([isMeetingOrPhoneOut, isAppointment])),
    sortBy('start')
);

export const vwFilterOutstandingMeetings = pipe(
    filter(allPass([isMeetingOrPhoneOut, isAppointment])),
    sortBy('start')
);

export const psaFilterOutstandingVisitReports = pipe(
    filterAssignedToUser,
    filter(allPass([isBeforeNow, isPending, isMeeting /*, isNotPsaDay, isNotTelephone*/])),
    sortBy('start')
);

export const bmwFilterCompanyCommunications = pipe(
    filterBelongsToCompany,
    filter(allPass([isMeetingOrPhoneOut])),
    sortBy('start')
);

export const filterFutureCompanyCommunications = pipe(
    filterBelongsToCompany,
    filter(allPass([inFuture, isNotCancelled])),
    sortBy('start')
);

export const bmwFilterOutstandingVisitReports = pipe(
    filterAssignedToUser,
    filter(allPass([isBeforeNow, isPending, isMeeting /*, isNotPsaDay, isNotTelephone*/])),
    sortBy('start')
);

//Temp function to test database logic against javascript. For OP-583
function validateEntityTypeFromSqlMatchesOldCode(communication) {
    //let a = '';
    switch (communication.eventType) {
        case 'Meeting':
            //if (communication.eventMethod === 'FaceToFace') {
            //    a = 'faceToFace';
            //} else {
            //    a = 'telephone';
            //}
            break;
        case 'AdminDay':
            //a = 'adminDay';
            break;
        case 'Event':
            //a = 'event';
            break;
        case 'Holiday':
            //a = 'holiday';
            break;
        default:
            //a = 'contactOut';
            communication.engageEntityType = 'contactOut'; //TODO: Hack SQL returning wrong engageEntitiyTypes so setting from code for now.
            break;
    }
    if (isTask(communication)) {
        communication.engageEntityType = 'visitReportAction'; //TODO: Hack SQL returning wrong engageEntitiyTypes so setting from code for now.
    }

    //if (a !== communication.engageEntityType) {
    //    console.error(`Incorrect Entity Type From Code: ${a} From SQL: ${communication.engageEntityType}`);
    //}
}

export const addColorToCommunication = communication => {
    communication.start = moment(communication.startDate)
        .utc()
        .valueOf();
    communication.end = moment(communication.endDate)
        .utc()
        .valueOf();
    communication.end =
        communication.end < communication.start ? communication.start + 60 * 60 * 1000 : communication.end;
    communication.assigneeName = communication.assignee !== undefined ? communication.assignee.descriptor : '';

    validateEntityTypeFromSqlMatchesOldCode(communication); //TODO: Hack SQL returning wrong engageEntitiyTypes so setting from code for now, in the validation.
    communication.calendarOption = communication.engageEntityType;
};

const getCommunicationTitle = ({ subject, activityRegarding, companyName }, choiceList) => {
    if (subject) {
        return subject;
    }
    const activityRegardingChoice = getChoiceListValue(
        { choiceList },
        'communicationActivityRegarding',
        activityRegarding
    );
    return activityRegardingChoice ? activityRegardingChoice.value : activityRegarding;
};

const getCompanyName = communicaiton => {
    if (communicaiton.company && communicaiton.company.descriptor) {
        return communicaiton.company.descriptor;
    }
    return '';
};

export const mapCommunicationToCalendarEvent = (communication, choiceList) => {
    const { communicationId, startDate, endDate, calendarOption, eventType, category, activityRegarding } = communication;

    let calendarOpt = calendarOptions[calendarOption];
    calendarOpt = calendarOpt ? calendarOpt : calendarOptions.default;
    const icon = overrideIcon(category, eventType, activityRegarding, calendarOpt.icon);
    const color = calendarOpt.color;
    return {
        id: `comm-${communicationId}`,
        title: getCommunicationTitle(communication, choiceList),
        companyName: getCompanyName(communication),
        communication,
        icon,
        startDate,
        endDate,
        start: moment(startDate).toDate(),
        end: moment(endDate).add(45,'minutes').toDate(),
        color
    };
};

export const eventStyleGetter = event => {
    const { icon, color } = event;
    const style = {
        icon
    };
    return {
        style: style,
        className: `border-0 rounded-0 background-hover background-color-${color}`
    };
};

export const addColorToCommunications = pipe(forEach(addColorToCommunication));
