import React from 'react';
import { useDispatch } from 'react-redux';
import ActionButton from '../../common/ActionButtons/ActionButton';
import DashboardAdminCard from '../../Agreement/Dashboard/DashboardAdminCard';
import { permissions, RenderIfHasReadPermission } from '../../../reducers/Api/User/permissions';
import { apiClearCache } from '../../../actions/Api/Cache/apiCache';

export default function AgreementsAdminSettingsLinks() {
    const dispatch = useDispatch();

    const onClearCache = () => {
        dispatch(apiClearCache());
    }

    return (
        <DashboardAdminCard helpId="agreementsAdminSettings" title="Settings">
            <ActionButton
                bootstrapColor="secondary"
                buttonText={
                    <div>
                        <h5>Recent Activity</h5>
                        <p>See all recent activity to the agreements</p>
                    </div>
                }
                fontawesomeIcon="fa-clock"
                to="../recentactivity"
            />
            <ActionButton
                bootstrapColor="secondary"
                buttonText={
                    <div>
                        <h5>Templates</h5>
                        <p>Upload the word document template files that the agreement letter is based off</p>
                    </div>
                }
                fontawesomeIcon="fa-address-card"
                to="../templates"
            />
            <ActionButton
                bootstrapColor="secondary"
                buttonText={
                    <div>
                        <h5>Checks</h5>
                        <p>Define what checks are required as agreements move through the workflow</p>
                    </div>
                }
                fontawesomeIcon="fa-check-double"
                to="../checks"
            />
            <ActionButton
                bootstrapColor="secondary"
                buttonText={
                    <div>
                        <h5>Notifications</h5>
                        <p>
                            Notifications can be sent when the status of an agreement changes. The notifications
                            settings give you control who gets these notifications for different types of agreements
                        </p>
                    </div>
                }
                fontawesomeIcon="fa-envelope"
                to="../agreements/admin/notifications"
            />
            <ActionButton
                bootstrapColor="secondary"
                buttonText={
                    <div>
                        <h5>Agreement Workflow</h5>
                        <p></p>
                    </div>
                }
                fontawesomeIcon="fa-project-diagram"
                to="../agreements/admin/workflow"
            />
            <ActionButton
                bootstrapColor="secondary"
                buttonText={
                    <div>
                        <h5>Agreement Rules</h5>
                        <p></p>
                    </div>
                }
                fontawesomeIcon="fa-clipboard-list"
                to="../agreementrules"
            />
            <RenderIfHasReadPermission permission={permissions.USER_ADMIN}>
                <ActionButton
                    bootstrapColor="secondary"
                    buttonText={
                        <div>
                            <h5>User Management</h5>
                            <p>Manage users in engage. Along with their access levels</p>
                        </div>
                    }
                    fontawesomeIcon="fa-users"
                    to="../user/management/engine"
                />
            </RenderIfHasReadPermission>
            <RenderIfHasReadPermission permission={permissions.CLEAR_CACHE}>
                <ActionButton
                    bootstrapColor="secondary"
                    buttonText={
                        <div>
                            <h5>Clear Cache</h5>
                            <p>If data has changed in the database it may not show as some tables are cached.</p>
                        </div>
                    }
                    fontawesomeIcon="fa-database"
                    onClickAction={onClearCache}
                />
            </RenderIfHasReadPermission>
        </DashboardAdminCard>
    );
}
