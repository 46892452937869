import { SET_INCLUDE_MEETING_NOTES_IN_EMAIL } from '../../actions/emailSettingsActions';

const defaultState = {
    includeNotesInEmailComms: []
};

//let newState;

export const emailSettings = (state = defaultState, { type, data }) => {
    switch (type) {
        case SET_INCLUDE_MEETING_NOTES_IN_EMAIL:
            const { communicationId, includeNotesInVisitReportEmail } = data;
            let includeNotesInEmailComms = state.includeNotesInEmailComms;
            if (!includeNotesInVisitReportEmail) {
                includeNotesInEmailComms = includeNotesInEmailComms.filter(x => x !== communicationId);
            } else {
                includeNotesInEmailComms = uniq([...includeNotesInEmailComms, communicationId]);
            }
            return { ...state, includeNotesInEmailComms };

        default:
            return state;
    }
};

const uniq = arr => [...new Set(arr)];
