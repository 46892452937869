import create from 'zustand';
import { useDispatch } from 'react-redux';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import notificationDef from '../notificationDef';
import { apiNotificationsPost } from '../../../actions/Api/Notification/apiNotification';

const idField = notificationDef.id.field;
export const useNotificationStore = create(set => storeWithModal(set, idField));

export function useUpdateNotifications() {
    const updateNotificationsFromFetch = useNotificationStore(state => state.updateDataFromFetch);
    const setError = useNotificationStore(state => state.setError);
    const dispatch = useDispatch();

    return notifications => {
        return dispatch(apiNotificationsPost(notifications.map(m => m[idField])))
            .then(response => {
                updateNotificationsFromFetch(response, false);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
