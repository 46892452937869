import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm/index';
import { AppModalHeader } from '../../common/AppModalHeader';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import WarningPopover from '../../common/WarningPopover';
import useToggleOpen from '../../common/Hooks/useToggleOpen';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { apiUploadSignaturePost } from '../../../actions/Api/Agreement/apiAgreement';

const validationSchema = Yup.object().shape({
    signatureFile: Yup.object().required('Signature is required.')
});

function UploadSignatureForm({ cancel, setHasSignature }) {
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);

    const onSave = form => {
        dispatch(apiUploadSignaturePost({ excelFile: form.signatureFile?.contentAsBase64 }))
            .then(() => {
                setSaving(false);
                setHasSignature();
                cancel();
            })
            .catch(() => {
                setSaving(false);
            });
    };

    return (
        <ReactiveForm cancel={cancel} handleSubmit={onSave} isSaving={saving} validationSchema={validationSchema}>
            <ReactiveForm.Section>
                <ReactiveForm.Dropzone
                    accept=".png"
                    columnDef={{ ...basicColumnDef('Signature', 'signatureFile') }}
                    entity={{}}
                    multiple={false}
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}

function UploadSignatureModal({ children, setHasSignature }) {
    const { close, isOpen, open, toggle } = useToggleOpen(false);
    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={toggle}>
                <AppModalHeader toggle={toggle}>Signature Upload</AppModalHeader>
                <ModalBody>
                    <UploadSignatureForm cancel={close} setHasSignature={setHasSignature} />
                </ModalBody>
            </Modal>
            {children({ open })}
        </>
    );
}

export default function UploadSignature({ hasSignature, setHasSignature }) {
    return (
        <UploadSignatureModal setHasSignature={setHasSignature}>
            {({ open }) => (
                <ActionButton
                    bootstrapColor="info"
                    buttonText={
                        <>
                            {hasSignature && <WarningPopover bodyText="Signature exists for user" />}
                            Upload Signature
                        </>
                    }
                    fontawesomeIcon="fa-signature"
                    onClickAction={open}
                />
            )}
        </UploadSignatureModal>
    );
}
