import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactiveForm from '../../common/ReactiveForm';
import { apiLeadEdit } from '../../../actions/Api/Lead/apiLeadEdit';
import { FORM_NAMES } from '../../../actions/formNames';
import { createFakeId } from '../../../reducers/util';
import lead from '../lead';
import validation from '../lead.validation';

const FORM_NAME = FORM_NAMES.LEAD;
export const LEAD_FORM_NAME = FORM_NAME;
export const EmptyLeadForm = {
    leadId: -1,
    companyName: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    county: '',
    postcode: '',
    website: '',
    phoneNumber: '',
    salutation: '',
    jobTitle: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    fleetSize: 0,
    numberOfCars: 0,
    numberOfLCV: 0,
    createdBy: -1,
    createdDate: undefined,
    updatedBy: -1,
    updatedDate: undefined,
    stage: 'UCNRequested'
};

function CreateLeadForm({ cancel, leadId, leads, save, resources }) {
    const [saving, setSaving] = useState(false);
    const handleSubmit = form => {
        save(form, setSaving);
    };

    let initialValues = leads.find(lead => lead.leadId === leadId);

    if (!initialValues && leadId < 0) {
        initialValues = EmptyLeadForm;
        initialValues.leadId = createFakeId();
    }

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            isSaving={saving}
            resources={resources}
            validationSchema={validation}
        >
            <ReactiveForm.Section title="Company Details">
                <ReactiveForm.Text columnDef={lead.companyName} />
                <ReactiveForm.Text columnDef={lead.website} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Contact Details">
                <ReactiveForm.Choicelist columnDef={lead.salutation} />
                <ReactiveForm.Text columnDef={lead.firstName} />
                <ReactiveForm.Text columnDef={lead.lastName} />
                <ReactiveForm.Text columnDef={lead.jobTitle} />
                <ReactiveForm.Text columnDef={lead.phoneNumer} />
                <ReactiveForm.Text columnDef={lead.mobile} />
                <ReactiveForm.Text columnDef={lead.email} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Address">
                <ReactiveForm.Text columnDef={lead.address1} />
                <ReactiveForm.Text columnDef={lead.address2} />
                <ReactiveForm.Text columnDef={lead.address3} />
                <ReactiveForm.Text columnDef={lead.city} />
                <ReactiveForm.Text columnDef={lead.county} />
                <ReactiveForm.Text columnDef={lead.postcode} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Fleet Profile">
                <ReactiveForm.Text columnDef={lead.fleetSize} />
                <ReactiveForm.Text columnDef={lead.numberOfCars} />
                <ReactiveForm.Text columnDef={lead.numberOfLCV} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}

const mapStateToProps = state => ({
    leads: state.leads,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (lead, setSaving) => {
        setSaving(true);
        dispatch(apiLeadEdit(lead))
            .then(() => {
                setSaving(false);
            })
            .catch(err => {
                setSaving(false);
                console.error(err);
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateLeadForm);