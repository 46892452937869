import React from 'react';
import moment from 'moment';
import { eventStyleGetter, mapCommunicationToCalendarEvent } from '../../../reducers/Api/Communications/Comms';
import CalendarEvent from './CalendarEvent';

const AgendaDayCommunication = ({ communication, updateCommunication, choiceList, openCompany }) => {
    const event = mapCommunicationToCalendarEvent(communication, choiceList);
    event.prepend = `${moment(communication.start).format('H:mm')} - ${moment(communication.end).format('H:mm')}`;
    event.type = 'communication';


    return (
        <div
            className={`w-100 mt-1 p-2 hover-pointer background-hover background-color-${event.color}`}
            key={communication.communicationId}
            onClick={() => updateCommunication(communication)}
            style={eventStyleGetter(event).style}
        >
            <CalendarEvent event={event} openCompany={openCompany} />
        </div>
    );
};

export default AgendaDayCommunication;
