import { useDispatch } from 'react-redux';
import create from 'zustand';
import { apiAgreementMasterTermsPost } from '../../../actions/Api/Agreement/apiAgreement';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import masterTermDef from '../masterTermDef';

const rowKey = masterTermDef.id.field;
export const useMasterTermStore = create(set => storeWithModal(set, rowKey));

export const createNewMasterTerm = (version, brand, series, ordinal) => {
    return {
        masterTermId: null,
        masterTermVersionId: version.masterTermVersionId,
        brand,
        series,
        name: '',
        devCode: '',
        description: '',
        leasing: 0,
        neu: 0,
        neuDiscretionary: 0,
        flmDiscretionary: 0,
        totalDiscretionary: 0,
        publicSector: 0,
        specialist: 0,
        rental: 0,
        ordinal
    };
};

export function useUpdateTerms() {
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    return terms =>
        dispatch(apiAgreementMasterTermsPost(terms))
            .then(response => {
                updateAuditsFromFetch(response.masterTermVersionAudits || []);
                return response.masterTerms || [];
            });
}