import { FORM_PATHS, openInModalOrPage } from '../../modals';

export const editDay = communication => (dispatch, getState) => {
    dispatch(
        openInModalOrPage(
            FORM_PATHS.EditDayModal,
            communication.eventId,
            communication,
            communication.startDate,
            communication.eventType
        )
    );
};
