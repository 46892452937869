import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseTableInCard, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { openMeeting } from '../../../actions/Navigation/Meeting/meetingSelected';
import meeting from '../meeting';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const OutstandingMeetingsTable = () => {
    const user = useSelector(state => state.user);
    const meetings = useSelector(state => state.meetings);
    const rows = meetings.filter(
        x => x.assigneeId === user.userId && x.status === 'Pending' && new Date(x.startDate) < new Date()
    );
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const dispatch = useDispatch();

    const columns = [
        { ...meeting.startDate, filter: true, sortable: true },
        { ...meeting.startTime, filter: true, sortable: true },
        { ...meeting.companyNameFromCompanyId, filter: true, sortable: true },
        useChoicelistCellDefinition(meeting.activityRegarding, { width: 200 }),
        useChoicelistCellDefinition(meeting.meetingMode, { width: 150 })
    ];

    return (
        <BaseTableInCard
            cardTitle={`Incomplete Meetings (${rows.length})`}
            columns={columns}
            data={rows}
            isLoading={isLoading}
            noItemMessage="No Contacts"
            onRowClicked={({ rowData }) => dispatch(openMeeting(rowData.meetingId, rowData.descriptor))}
            rowKey={meeting.id.field}
            tableSizeStyle={{ height: 'calc(100vh /3)', width: '100%' }}
        />
    );
};
