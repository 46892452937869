import { IEvent } from '.';
import { ICalendarEvent } from '../../../containers/Calendar/Components/CalendarEvent';
import moment from 'moment';

interface IChoice {
	key: string | number;
	value: string;
}

interface IChoiceList extends Array<IChoice> {}

export const eventToCalendarEvent = (
	event: IEvent
): ICalendarEvent => ({
	id: `event-${event.eventId}`,
	title:event.action ==='Meeting' ? 'Internal Meeting' : event.action ==='CSMAnnualLeave' ? 'Holiday'
			: 'Event',
	event,
	icon: getIcon(event),
	color: getColor(event),
	start: moment(event.startDate).toDate(),
	end: moment(event.endDate).toDate()
});

const getColor = (event: IEvent) => {
	switch (event.activityRegarding) {
		case 'CSMEvent':
			return 6;

		case 'CSMAnnualLeave':
			return 1;

		default:
			return 2;
	}
};

const getIcon = (event: IEvent) => {
	switch (event.activityRegarding) {
		case 'CSMEvent':
			return 'fa-glass';

		case 'CSMAnnualLeave':
			return 'fa-plane';

		default:
			return 'fa-pencil';
	}
};
