import React from 'react';
import AgreementRevisionComparePageContent from '../../AgreementRevisionCompare/Components/AgreementRevisionComparePageContent';

export default function RevisionCompareToAgreementPage({
    match: {
        params: { agreementId: strAgreementId, revisionId: strRevisionId, compareId: strCompareId }
    }
}) {
    const agreementId = parseInt(strAgreementId, 10);
    const revisionId = parseInt(strRevisionId, 10);
    const compareId = parseInt(strCompareId, 10);

    return (
        <AgreementRevisionComparePageContent agreementId={agreementId} revisionId={revisionId} compareId={compareId} />
    );
}
