import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import _ from 'lodash';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';
import ReactiveForm from '../../common/ReactiveForm';
import meeting from '../meeting';

const schemaFor = communication =>
    communication && communication.roiBooked === 'Y'
        ? yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string().required(),
              feedback: yup.string()
          })
        : yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string(),
              feedback: yup.string()
          });

class CancelMeetingForm extends React.Component {
    handleSubmit = formValues => {
        const { communicationId, meetings } = this.props;
        const communication = meetings.find(x => x.communicationId === communicationId);

        this.props.save(
            {
                ...communication,
                ...formValues
            },
            this.props.onCancel
        );
    };

    render() {
        const { communicationId, meetings } = this.props;
        const communication = meetings.find(x => x.meetingId === communicationId);

        const { company, ...communicationFields } = communication;

        const initialValues = {
            ...communicationFields,
            communicationId,
            companyId: communication.companyId || communication.company?.companyId,
            status: 'Cancelled',
            rating: 'G1',
            feedback: _.get(communication, 'feedback', '') || '',
            notes: _.get(communication, 'notes', '') || ''
        };

        return (
            <ReactiveForm
                cancel={this.props.onCancel}
                handleSubmit={this.handleSubmit}
                initialValues={initialValues}
                validationSchema={schemaFor(communication)}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Choicelist
                        columnDef={meeting.status}
                        filter={x => ['Cancelled', 'Postponed'].includes(x.value)}
                    />
                    <ReactiveForm.Choicelist columnDef={meeting.rating} />
                    <ReactiveForm.Text columnDef={meeting.feedback} type="textarea" />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    meetings: state.meetings,
    companies: state.companies,
    user: state.user,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (communication, cancel) => {
        dispatch(apiMeetingPost(communication));
        cancel();
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelMeetingForm);
