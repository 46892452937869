import axios from 'axios';
import { getAxiosPostRequest, getAxiosPutRequest } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiAddendumCreate = addendum => {
    return axios(getAxiosPutRequest(['addendum', 'put-addendum'], undefined, addendum))
        .then(({ data }) => {
            toastr.success('Create Addendum', 'Success');
            return data;
        })
        .catch(err => {
            toastr.error('Create Addendum', `Response ${err.message}`);
            throw err;
        });
};

export const apiAddendumUpdate = addendum => {
    return axios(getAxiosPostRequest(['addendum', 'post-addendum'], undefined, addendum))
        .then(({ data }) => {
            toastr.success('Update Addendum', 'Success');
            return data;
        })
        .catch(err => {
            toastr.error('Update Addendum', `Response ${err.message}`);
            throw err;
        });
};

export const apiAddendumRuleUpdate = addendumRule => {
    return axios(getAxiosPostRequest(['addendum', 'post-addendum-rule'], undefined, addendumRule))
        .then(({ data }) => {
            toastr.success('Update Addendum Rule', 'Success', { disableCloseButtonFocus: true });
            return data;
        })
        .catch(err => {
            toastr.error('Update Addendum Rule', `Response ${err.message}`, { disableCloseButtonFocus: true });
            throw err;
        });
};

export const apiAddendumStatusChange = (addendumId, statusFrom, statusTo, addendum) => {
    return axios(
        getAxiosPostRequest(['addendum', 'status-change', addendumId, statusFrom, statusTo], undefined, addendum)
    )
        .then(({ data }) => {
            toastr.success('Addendum Status Change', 'Success');
            return data;
        })
        .catch(err => {
            toastr.error('Addendum Status Change', `Response ${err.message}`);
            throw err;
        });
};

export const apiToggleAgreementIsOnAddendum = (isOnAgreement, addendumId, agreementId) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    return axios(
        getAxiosPostRequest(
            ['addendum', isOnAgreement ? 'add_agreement_to_addendum' : 'remove_agreement_from_addendum'],
            { addendumId, agreementId }
        )
    )
        .then(({ data }) => {
            toastr.success('Agreement Updated', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiToggleAgreementsOnAddendum = (addendumId, agreementIds) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    return axios(getAxiosPostRequest(['addendum', 'toggle_agreements_on_addendum'], { addendumId }, { agreementIds }))
        .then(({ data }) => {
            toastr.success('Agreements Updated', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreements Updated', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiProcessAgreementsOnAddendum = (addendumId, agreementIds) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }
    return axios(
        getAxiosPostRequest(
            ['addendum', 'process-agreements-on-addendum'],
            { addendumId },
            {
                agreementIds,
                url: window.location.href
            }
        )
    )
        .then(({ data }) => {
            toastr.success('Agreements Updated', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreements Updated', `Response ${err.message}`);
            }

            throw err;
        });
};
