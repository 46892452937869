import { useDispatch } from 'react-redux';
import create from 'zustand';
import { addGroupsToDataI, tableStoreState } from '@returnoninvestment/base-table';
import { apiAgreementVolumesPost } from '../../../actions/Api/Agreement/apiAgreement';
import { useAgreementAuditStore } from './useAgreementAuditStore';
import { updateFrequencyOfAnticipatedVolume } from './updateFrequencyOfAnticipatedVolume';

const rowKey = 'type';
export const useAnticipatedVolumesStore = create((set, get) => ({
    ...tableStoreState(set, get),
    updateDataFromFetch: (anticipatedVolumes, newFrequency) =>
        set(state => {
            const frequency =
                newFrequency || (anticipatedVolumes.length > 0 ? anticipatedVolumes[0].frequency : state.frequency);

            return {
                dataCopy: [...anticipatedVolumes],
                count: state.count + 1,
                currentPage: 1,
                dataState: addGroupsToDataI(anticipatedVolumes, undefined, undefined, undefined, false),
                frequency
            };
        }),
    frequency: 'Y',
    onUpdate: ({ row, column }, value, agreement, updateVolumes) => {
        const state = get();
        let anticipatedVolume = state.dataCopy.find(vol => vol.type === column);

        if (!anticipatedVolume) anticipatedVolume = createEmptyVolume(agreement.agreementId, column, state.frequency);

        anticipatedVolume[row] = value;

        return updateVolumes([anticipatedVolume]);
    }
}));

export function allWithValue(...params) {
    if (!params) return;

    for (const param of params) {
        if (param === undefined || param === null) return false;
    }

    return true;
}

function volumeToQuarterArray(x) {
    return [x.q1, x.q2, x.q3, x.q4];
}

function volumeToMonthArray(x) {
    return [x.jan, x.feb, x.mar, x.apr, x.may, x.jun, x.jul, x.aug, x.sep, x.oct, x.nov, x.dec];
}

export function useIsValidAnticipatedVolume() {
    const anticipatedVolumes = useAnticipatedVolumesStore(state => state.dataCopy);
    const bmwVolume = anticipatedVolumes.find(x => x.type.toUpperCase() === 'BMW');
    const bmwiVolume = anticipatedVolumes.find(x => x.type.toUpperCase() === 'BMWI');
    const miniVolume = anticipatedVolumes.find(x => x.type.toUpperCase() === 'MINI');

    return (
        bmwVolume &&
        (allWithValue(bmwVolume.year) ||
            allWithValue(...volumeToQuarterArray(bmwVolume)) ||
            allWithValue(...volumeToMonthArray(bmwVolume))) &&
        bmwiVolume &&
        (allWithValue(bmwiVolume.year) ||
            allWithValue(...volumeToQuarterArray(bmwiVolume)) ||
            allWithValue(...volumeToMonthArray(bmwiVolume))) &&
        miniVolume &&
        (allWithValue(miniVolume.year) ||
            allWithValue(...volumeToQuarterArray(miniVolume)) ||
            allWithValue(...volumeToMonthArray(miniVolume)))
    );
}

function createEmptyVolume(agreementId, type, frequency) {
    return {
        agreementId,
        type,
        frequency
    };
}

export function useChangeFrequency() {
    const data = useAnticipatedVolumesStore(state => state.dataCopy);
    const setData = useAnticipatedVolumesStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    return newFrequency => {
        if (data.length === 0) {
            setData(data, newFrequency);
            return;
        }
        const updated = data.map(x => updateFrequencyOfAnticipatedVolume(x, newFrequency));

        dispatch(apiAgreementVolumesPost(updated))
            .then(response => {
                setData(response.agreementAnticipatedVolumes, newFrequency);
                updateAuditsFromFetch(response.agreementHeaderAudits || []);
            })
            .catch(error => {
                console.error(error);
            });
    };
}

export function useUpdateVolumes() {
    const updateRows = useAnticipatedVolumesStore(state => state.updateRows);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    return volumes =>
        dispatch(apiAgreementVolumesPost(volumes))
            .then(response => {
                updateRows(response.agreementAnticipatedVolumes, rowKey);
                updateAuditsFromFetch(response.agreementHeaderAudits || []);
            })
            .catch(error => {
                console.error(error);
            });
}
