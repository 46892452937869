import React from 'react';
import { Link } from 'react-router-dom';
import TableCard from '../../common/Card/TableCard';
import CaptionBadge from '../../common/FilteredTable/CaptionBadge';
import { useResources } from '../../../selectors/Api/useResources';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';
import { useRevisionsStore } from '../Provider/useRevisionsStore';

function Row({ title, link, caption, choicelist, resources, show }) {
    return show ? (
        <>
            <div>{title}</div>
            {link}
            {caption ? <CaptionBadge caption={caption} choiceList={choicelist} resources={resources} /> : <div />}
        </>
    ) : null;
}

export default function MasterTermsInformation() {
    const agreementId = useAgreementsStore(state => state.agreement?.agreementId);
    const currentMatrix = useAgreementsStore(state => state.agreement?.masterTermVersion);
    const previousMatrix = useAgreementsStore(state => state.agreement?.previvousMasterTermVersion);
    const revisionId = useAgreementsStore(state => state.agreement?.revision);
    const revisions = useRevisionsStore(state => state.data);
    const revision = revisions?.find(f => f.revisionId === revisionId);
    const addendum = useAddendaStore(state => state.addendum);
    const resources = useResources();

    return (
        <TableCard title="Information">
            <div className="d-grid" style={{ gridTemplateColumns: 'auto auto auto' }}>
                <Row
                    caption={currentMatrix?.status}
                    choicelist="masterTermVersionStatus"
                    link={
                        <Link className="d-block" to={`/matrix/${currentMatrix?.masterTermVersionId}`}>
                            {currentMatrix?.reference}
                        </Link>
                    }
                    resources={resources}
                    show={!!currentMatrix?.masterTermVersionId}
                    title={
                        addendum?.addendumId &&
                        currentMatrix?.masterTermVersionId !== previousMatrix?.masterTermVersionId
                            ? 'Moving To Terms'
                            : 'Current Terms'
                    }
                />
                <Row
                    caption={previousMatrix?.status}
                    choicelist="masterTermVersionStatus"
                    link={
                        <Link className="d-block" to={`/matrix/${previousMatrix?.masterTermVersionId}`}>
                            {previousMatrix?.reference}
                        </Link>
                    }
                    resources={resources}
                    show={!!previousMatrix?.masterTermVersionId}
                    title={
                        addendum?.addendumId &&
                        currentMatrix?.masterTermVersionId !== previousMatrix?.masterTermVersionId
                            ? 'Current Live Terms'
                            : 'Previous Terms'
                    }
                />
                <Row
                    caption={addendum?.status}
                    choicelist="addendumStatus"
                    link={
                        <Link className="d-block" to={`/addendum/${addendum?.addendumId}`}>
                            {addendum?.name}
                        </Link>
                    }
                    resources={resources}
                    show={!!addendum?.addendumId}
                    title="Addendum"
                />
                <Row
                    link={
                        <Link className="d-block" to={`/agreement/${agreementId}/${revision?.revisionId}`}>
                            {revision?.name}
                        </Link>
                    }
                    show={!!revision?.revisionId}
                    title="Current Revision"
                />
            </div>
        </TableCard>
    );
}
