import React from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import TermExclusionsForm from '../Forms/TermExclusionsForm';

export const useTermExclusionsModalStore = create(set => ({
    term: {},
    ivsCodes: [],
    setModalOpen: (useTableStore, term) => {
        set(() => ({ useTableStore, term, open: true }));
    },
    onClose: () => {
        set(() => ({ open: false }));
    },
    setState: newState => {
        set(() => newState);
    }
}));

export default function TermExclusionsModal() {
    const [{ description }, open, onClose] = useTermExclusionsModalStore(
        ({ term, open, onClose }) => [term, open, onClose],
        shallow
    );

    return (
        <Modal className="modal-md" isOpen={open} toggle={onClose}>
            <AppModalHeader toggle={onClose}>{`${description} - Exclusions`}</AppModalHeader>
            <ModalBody>
                <TermExclusionsForm />
            </ModalBody>
        </Modal>
    );
}
