import { Card } from 'reactstrap';
import BaseTable from './BaseTable';
import React, { Fragment } from 'react';
import HelpPopover from '../HelpPopover';
import { Toggle } from '../../Agreement/Dashboard/AgreementsDashboardCard';

export function TableHelpText({ columns }) {
    return (
        <div className="d-grid" style={{ gridTemplateColumns: '1fr auto', gap: '1rem' }}>
            {columns
                .filter(f => f.help?.helpText || f.actions?.length > 0)
                .map(m => {
                    if (m.actions?.length > 0)
                        return m.actions.map(action => (
                            <Fragment key={action.field}>
                                <strong>{action.help.helpTitle || action.title}</strong>
                                <p>{action.help.helpText}</p>
                            </Fragment>
                        ));

                    if (m.mergeFields)
                        return [
                            <Fragment key={m.field}>
                                <strong>{m.help.helpTitle || m.title}</strong>
                                <p>{m.help.helpText}</p>
                            </Fragment>,
                            <Fragment key={m.mergeFields.key}>
                                <strong>{m.mergeFields.helpTitle}</strong>
                                <div>
                                    {m.mergeFields?.values.map(value => (
                                        <p key={value}>{value}</p>
                                    ))}
                                </div>
                            </Fragment>
                        ];

                    return (
                        <Fragment key={m.field}>
                            <strong>{m.help.helpTitle || m.title}</strong>
                            <p>{m.help.helpText}</p>
                        </Fragment>
                    );
                })}
        </div>
    );
}

export function BaseTableInCardButton({ onClick, className, disabled = false, content, icon }) {
    return (
        <button className={`btn btn-primary text-white mr-2 ${className}`} disabled={disabled} onClick={onClick}>
            {icon ? <i className={`fa ${icon} mr-2`} /> : null}
            {content}
        </button>
    );
}

export function BaseTableInCardContentRight({ content, buttons = [], helpId, columns, helpText, toggles = [] }) {
    return (
        <div className="d-flex" style={{ gap: '1rem', alignItems: 'center' }}>
            {buttons.map(button => (
                <BaseTableInCardButton
                    className={button.className}
                    content={button.content}
                    disabled={button.disabled}
                    icon={button.icon}
                    key={button.id}
                    onClick={button.onClick}
                />
            ))}
            {toggles.map(toggle => (
                <Toggle
                    id={toggle.id}
                    key={toggle.id}
                    option={toggle.option}
                    options={toggle.options}
                    selectOption={toggle.selectOption}
                />
            ))}
            {content}
            {helpId ? (
                <HelpPopover
                    color="white"
                    helpId={helpId}
                    helpText={helpText ? helpText : columns ? <TableHelpText columns={columns} /> : undefined}
                    style={{ right: '-25px' }}
                />
            ) : null}
        </div>
    );
}

export default function BaseTableInCard({
    cardTitle,
    buttons,
    helpId,
    titleContentRight,
    tableSizeStyle = { height: 200, width: '100%' },
    refForTableHeight,
    ...rest
}) {
    return (
        <Card className="mb-4 shadow-sm simple-card" style={{ overflow: 'hidden' }}>
            <div className="d-flex justify-content-between base-table-in-card-header">
                <h5 className="d-inline logo-font gradient mb-0">{cardTitle}</h5>
                <BaseTableInCardContentRight
                    buttons={buttons}
                    columns={rest.columns}
                    content={titleContentRight}
                    helpId={helpId}
                />
            </div>
            <div ref={refForTableHeight} style={tableSizeStyle}>
                <BaseTable {...rest} />
            </div>
        </Card>
    );
}

export * from './BaseTable';
