import React from 'react';
import { Alert, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import ReactiveForm, { FilteredCheckbox, FilteredChoiceList } from '../../common/ReactiveForm';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import { FormatDate } from '../../common/FormatDate';
import { agreementPermissions, getPermission } from '../agreementPermissions';
import validation from './agreementFormValidation';
import storeStates from '../Provider/storeStates';
import { useAgreementsStore, useUpdateAgreement } from '../Provider/useAgreementsStore';
import { termIsEditedInAddendum, useFilteredTermsInDatabase } from '../Provider/useTermsStore';
import { useResetTerms } from '../Provider/useUpdateTerms';
import useIsContractApproved from '../Provider/useIsContractApproved';
import AgreementUpliftModal from '../Modals/AgreementUpliftModal';
import agreementDef from '../agreementDef';
import { useCreatableAgreements } from '../../Company/Provider/CompanyAgreementsProvider';

function MasterTermsVersion({ formikProps, disabled }) {
    return process.env.NODE_ENV === 'development' ? (
        <ReactiveForm.Text columnDef={agreementDef.masterTermVersionId} disabled={disabled} formikProps={formikProps} />
    ) : null;
}

function ResetTermsWarning({ termsInDatabaseCount, headerFormDisabled }) {
    const resetTerms = useResetTerms();

    return !headerFormDisabled && termsInDatabaseCount > 0 ? (
        <Alert className="d-flex justify-content-between" color="info">
            <div>
                <strong>Terms have been edited</strong>
                <p style={{ margin: 0, padding: 0 }}>Reset terms to edit locked fields?</p>
            </div>
            <div>
                <Button color="info" onClick={resetTerms}>
                    Reset Terms
                </Button>
            </div>
        </Alert>
    ) : null;
}

function ApprovedInfo() {
    const { approved, people } = useIsContractApproved();
    return approved ? (
        <Alert color="success">
            <strong>Customer Approved</strong>
            {people.map(({ person, signedDate }, index) => (
                <div key={person?.personId || index}>
                    Approved by {person?.descriptor || 'Unknown'} on <FormatDate date={signedDate} />
                </div>
            ))}
        </Alert>
    ) : null;
}

function UpliftAgreement({ disabled }) {
    const salesChannel = useAgreementsStore(state => state.agreement?.salesChannel);
    const status = useAgreementsStore(state => state.agreement?.status);
    const openUpliftModal = useAgreementsStore(state => state.openModal);
    const anyAddendumTerms = useFilteredTermsInDatabase(termIsEditedInAddendum).length > 0;
    return (
        <>
            {salesChannel === 'NEU' && status === 'ADEM' && (
                <Button
                    color="info"
                    disabled={disabled || anyAddendumTerms}
                    onClick={openUpliftModal}
                    title={anyAddendumTerms ? 'Terms have been edited in addendum' : ''}
                >
                    Uplift Agreement
                </Button>
            )}
        </>
    );
}

function DirtyWarning() {
    const formDirty = useAgreementsStore(state => state.formDirty);
    return formDirty ? (
        <Alert className="mt-2" color="warning">
            <strong>Please Save your Changes</strong>
        </Alert>
    ) : null;
}

export default function AgreementForm({ disabled = false }) {
    const agreement = useAgreementsStore(state => state.agreement);
    const workflow = agreement?.workflow;
    const setFormDirty = useAgreementsStore(state => state.setFormDirty);
    const saving = useAgreementsStore(state => state.storeState) === storeStates.saving;
    const updateAgreement = useUpdateAgreement();
    const termsInDatabaseCount = useFilteredTermsInDatabase(term => !!term.agreementTermId).length;
    const opportunitySummaryEnabled = getPermission(agreementPermissions.opportunitySummary, workflow).isEnabled;
    const headerFormEnabled = getPermission(agreementPermissions.headerForm, workflow).isEnabled;
    const headerFormDisabledForFieldsThatAffectTerms = disabled || !headerFormEnabled || termsInDatabaseCount > 0;
    const headerFormDisabled = disabled || !headerFormEnabled;
    const opportunitySummaryDisabled = disabled || !opportunitySummaryEnabled;
    const creatableAgreements = useCreatableAgreements();
    const agreementTypeDisabled = headerFormDisabledForFieldsThatAffectTerms || creatableAgreements.some(s => s.agreementType);
    const agreementTypeChoiceList = useChoiceList('agreementType');
    const matrixBandingChoiceList = useChoiceList('matrixBanding');
    const choiceList = useSelector(state => state.resources.choiceList);
    const dependOnSalesChannel = Object.entries(choiceList)
        .filter(f => f[1].some(s => s.filterOnChoiceList === agreementDef.salesChannel.field))
        .map(m => m[0]);

    const onChange = () => {
        setFormDirty();
    };

    return (
        <>
            <AgreementUpliftModal />
            <ApprovedInfo />
            <ResetTermsWarning headerFormDisabled={!headerFormEnabled} termsInDatabaseCount={termsInDatabaseCount} />
            <ReactiveForm
                enableReinitialize={true}
                formName="AGREEMENT_FORM"
                handleSubmit={updateAgreement}
                hideCancelButton={true}
                hideSaveButton={disabled}
                initialValues={agreement}
                isSaving={saving}
                key={agreement.id}
                onChange={onChange}
                validationSchema={validation(agreementTypeChoiceList, matrixBandingChoiceList)}
            >
                <ReactiveForm.DisplayInColumns>
                    <ReactiveForm.Section>
                        <ReactiveForm.Choicelist
                            columnDef={agreementDef.salesChannel}
                            disabled={true}
                            onFormValueChanged={(values, fieldName, _, setFieldValue) => {
                                dependOnSalesChannel.forEach(f => {
                                    setFieldValue(f, undefined);
                                });
                            }}
                        />
                        {agreement.addendumId
                            ? (
                                <ReactiveForm.Text
                                    columnDef={agreementDef.reference}
                                    disabled={opportunitySummaryDisabled} max={100}
                                />
                            )
                            : (
                                <ReactiveForm.Choicelist
                                    columnDef={{ ...agreementDef.reference, choiceList: 'agreementReference' }}
                                    disabled={opportunitySummaryDisabled}
                                />
                            )
                        }
                        <ReactiveForm.Text columnDef={agreementDef.contractVolume} disabled={headerFormDisabled} />
                        <ReactiveForm.Text
                            columnDef={agreementDef.opportunitySummary}
                            disabled={opportunitySummaryDisabled}
                            max={500}
                            type="textarea"
                        />

                        <ReactiveForm.Date
                            columnDef={agreementDef.startDate}
                            disabled={true}
                            hide={formikProps => !formikProps?.values?.startDate}
                        />
                        <ReactiveForm.Date
                            columnDef={agreementDef.endDate}
                            disabled={true}
                            hide={formikProps => !formikProps?.values?.startDate}
                        />
                    </ReactiveForm.Section>
                    <ReactiveForm.Section>
                        <FilteredChoiceList
                            columnDef={agreementDef.matrixBanding}
                            disabled={headerFormDisabledForFieldsThatAffectTerms}
                        />
                        <FilteredChoiceList
                            columnDef={agreementDef.agreementType}
                            disabled={agreementTypeDisabled}
                        />
                        <FilteredCheckbox
                            columnDef={agreementDef.rebate}
                            isDisabled={({ values }) =>
                                values.activeSupportBonus || headerFormDisabledForFieldsThatAffectTerms
                            }
                        />
                        <FilteredCheckbox
                            columnDef={agreementDef.activeSupportBonus}
                            isDisabled={({ values }) => values.rebate || headerFormDisabledForFieldsThatAffectTerms}
                        />
                        <ReactiveForm.Checkbox columnDef={agreementDef.sendToCustomer} disabled={disabled} />
                        <FilteredChoiceList
                            columnDef={agreementDef.businessType}
                            disabled={headerFormDisabledForFieldsThatAffectTerms}
                        />
                        <FilteredChoiceList
                            columnDef={agreementDef.minimumHoldingPeriod}
                            disabled={headerFormDisabledForFieldsThatAffectTerms}
                        />
                        <MasterTermsVersion disabled={headerFormDisabledForFieldsThatAffectTerms} />
                        <UpliftAgreement disabled={disabled} />
                    </ReactiveForm.Section>
                </ReactiveForm.DisplayInColumns>
            </ReactiveForm>
            <DirtyWarning />
        </>
    );
}
