import { getFieldsNotUpdated } from '../helper';

const propertiesToIgnoreForErrors = [
    'person',
    'company',
    'communicationId',
    'descriptor',
    'assignee',
    'updatedDate',
    'start',
    'end',
    'assigneeName',
    'calendarOption'
];

export const getCommunicationUpdateErrors = (local, remote) => {
    return getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
};
