import React from 'react';
import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: idColumnDef('Id', 'id'),
    title: {
        ...basicColumnDef('Title', 'title'),
        sortable: true,
        filter: true
    },
    subject: {
        ...basicColumnDef('Subject', 'subject'),
        sortable: true,
        filter: true
    },
    message: {
        ...basicColumnDef('Message', 'message'),
        sortable: true,
        filter: true
    },
    status: {
        ...basicColumnDef('Status', 'status'),
        sortable: true,
        filter: true
    },
    link: {
        ...basicColumnDef('Link', 'link'),
        sortable: true,
        filter: true
    },
    createdDate: {
        ...basicColumnDef('Date', 'createdDate'),
        sortable: true,
        filter: true
    },
    comments: {
        ...basicColumnDef('Comments', 'comments'),
        mentions: 'userAuthors',
        help: {
            helpId: 'commentNotification',
            helpTitle: 'Mention',
            helpText: (
                <>
                    <div>{'Mention someone in the comment by typing @ followed by their name.'}</div>
                    <div>{'They will be sent a notification.'}</div>
                </>
            )
        }
    }
};
