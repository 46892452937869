import * as yup from 'yup';
import moment from 'moment';

const validation = yup
    .object()
    .shape({
        eventType: yup.string(),
        startDate: yup.date().required('Please enter a start date'),
        endDate: yup.date().required('Please select an end date'),
        body: yup.string()
    })
    .test('evidence', function({ startDate, endDate }) {
        return (
            moment(startDate).isSameOrBefore(moment(endDate), 'day') ||
            this.createError({
                path: 'endDate',
                message: 'Event must end on the same day or after the start'
            })
        );
    });

export default validation;
