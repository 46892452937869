import React, { useEffect } from 'react';
import _ from 'lodash';
import BaseTable, {
    EditablePercentage,
    HeaderEditRenderer,
    ComparePercentage,
    EditableText
} from '../../common/BaseTable/BaseTable';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { IvsCodesEdit } from './IvsCodesEdit';
import { AddTableRowButton, DeleteTableRowButton, TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { IvsModalProvider } from '../Modal/IvsModal';
import { usePreviousMasterTermsStore } from '../Provider/useTermsStore';
import { createNewMasterTerm, useUpdateTerms } from '../Provider/useMasterTermStore';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import masterTermDef from '../masterTermDef';

export const numberColWidth = 120;

function ActionsRenderer({ onRemoveTerm, disabled, rowData, useTableStore }) {
    const newRowData = useTableStore(state => state.dataState.pageData[rowData.index]);
    return (
        <TableButtonsContainer>
            {!disabled && <DeleteTableRowButton onClick={() => onRemoveTerm(newRowData)} />}
        </TableButtonsContainer>
    );
}

function WithPreviousDiscount({ column, rowData, cellData, useTableStore, onUpdate, editable }) {
    const previousTermsMap = usePreviousMasterTermsStore(state => state.map);
    const lastRevisionValue = previousTermsMap?.get(rowData.previousMasterTermId)?.[column.dataKey];

    return (
        <ComparePercentage
            dataKey={column.dataKey}
            defaultValue={cellData}
            downIsGood={false}
            editable={editable}
            lastRevisionValue={lastRevisionValue}
            onUpdate={onUpdate}
            rowData={rowData}
            rowKey={rowKey}
            useTableStore={useTableStore}
            value={cellData}
        />
    );
}
const CellRenderer = (editable, onUpdate) => (props, { useTableStore }) => (
    <WithPreviousDiscount {...props} editable={editable} onUpdate={onUpdate} useTableStore={useTableStore} />
);

export function useMatrixTableColumns(version, brand, rowKey, onUpdate, onUpdateMultiple, useTableStore) {
    const workflow = useMasterTermVersionsStore(state => state.version?.workflow);
    const editable = workflow?.find(f => f.permission === 'ChangeSalesChannel')?.isEnabled;
    const updateTerms = useUpdateTerms();
    const updateRows = useTableStore(state => state.updateRows);
    const setTermsData = useTableStore(state => state.setTermsData);

    const onUpdateText = (colKey, masterTermId, value) => {
        const terms = useTableStore.getState().dataCopy;
        return updateTerms(
            terms
                .filter(term => term.masterTermId === masterTermId)
                .map(term => {
                    const newTerm = { ...term };
                    newTerm[colKey] = value;
                    return newTerm;
                })
        ).then(d => {
            updateRows(d);
            return d;
        });
    };

    const onAddTerm = ({ series }) => {
        const terms = useTableStore.getState().dataCopy;
        const lastTermInSeries = _.findLast(terms, term => term.series === series);
        const ordinal = lastTermInSeries ? lastTermInSeries.ordinal + 1 : 0;

        const changedOrdinals = terms
            .filter(f => f.ordinal >= ordinal)
            .map(m => {
                m.ordinal++;
                return m;
            });

        return updateTerms([createNewMasterTerm(version, brand, series, ordinal)]).then(d =>
            setTermsData([...d, ...changedOrdinals], new Set(changedOrdinals.map(m => m.masterTermId)), ordinal - 1)
        );
    };

    const onRemoveTerm = term => {
        return updateTerms([
            {
                ...term,
                deleted: 1
            }
        ]).then(d => setTermsData([], new Set([term.masterTermId])));
    };

    return [
        {
            ...masterTermDef.name,
            editable,
            width: 170,
            noGrow: true,
            cellRenderer: ({ column, cellData, rowData }) => (
                <EditableText
                    dataKey={column.dataKey}
                    editable={editable}
                    inputOptions={{ maxLength: 100 }}
                    onUpdate={(dataKey, currentValue) =>
                        onUpdateText(dataKey, rowData[rowKey], currentValue).then(response => {
                            if (response.length === 1 && response[0][rowKey]) {
                                return response[0].name;
                            }
                            throw new Error('Invalid Server Response');
                        })
                    }
                    value={cellData}
                />
            )
        },
        {
            ...masterTermDef.devCode,
            editable,
            noGrow: true,
            cellRenderer: ({ column, cellData, rowData }) => (
                <EditableText
                    dataKey={column.dataKey}
                    editable={editable}
                    inputOptions={{ maxLength: 3 }}
                    onUpdate={(dataKey, currentValue) =>
                        onUpdateText(dataKey, rowData[rowKey], currentValue).then(response => {
                            if (response.length === 1 && response[0][rowKey]) {
                                return response[0].devCode;
                            }
                            throw new Error('Invalid Server Response');
                        })
                    }
                    value={cellData}
                />
            )
        },
        {
            ...masterTermDef.description,
            editable,
            cellRenderer: ({ column, cellData, rowData }) => (
                <EditableText
                    dataKey={column.dataKey}
                    editable={editable}
                    inputOptions={{ maxLength: 100 }}
                    onUpdate={(dataKey, currentValue) =>
                        onUpdateText(dataKey, rowData[rowKey], currentValue).then(response => {
                            if (response.length === 1 && response[0][rowKey]) {
                                return response[0].description;
                            }
                            throw new Error('Invalid Server Response');
                        })
                    }
                    value={cellData}
                />
            )
        },
        {
            ...masterTermDef.ivsCodes,
            editable,
            noGrow: true,
            width: 220,
            cellRenderer: (props, rest) => <IvsCodesEdit {...props} {...rest} disabled={!editable} />
        },
        {
            ...masterTermDef.leasing,
            editable,
            noGrow: true,
            width: numberColWidth,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...masterTermDef.neuLeasing,
            editable,
            noGrow: true,
            width: numberColWidth + 10,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...masterTermDef.neu,
            editable,
            noGrow: true,
            width: numberColWidth,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...masterTermDef.neuDiscretionary,
            editable,
            noGrow: true,
            width: numberColWidth,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...masterTermDef.flmDiscretionary,
            editable,
            noGrow: true,
            width: numberColWidth,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...masterTermDef.publicSector,
            editable,
            noGrow: true,
            width: numberColWidth,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...masterTermDef.specialist,
            editable,
            noGrow: true,
            width: numberColWidth,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...masterTermDef.rental,
            editable,
            noGrow: true,
            width: numberColWidth,
            cellRenderer: CellRenderer(editable, onUpdate),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            field: 'actions',
            title: 'Actions',
            noGrow: true,
            width: 70,
            cellRenderer: (props, rest) => (
                <ActionsRenderer {...props} {...rest} disabled={!editable} onRemoveTerm={onRemoveTerm} />
            ),
            groupRenderer: (_, { rowData }) => (
                <>{editable && <AddTableRowButton onClick={() => onAddTerm(rowData)} />}</>
            )
        }
    ];
}

const rowKey = masterTermDef.id.field;

export default function MatrixTermsTable({ useTableStore, brand }) {
    const version = useMasterTermVersionsStore(state => state.version);
    const termsChangedFilter = useMasterTermVersionsStore(state => state.termsChangedFilter);
    const updateTerms = useUpdateTerms();
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const previousTermsMap = usePreviousMasterTermsStore(state => state.map);
    const updateRows = useTableStore(state => state.updateRows);
    const onExternalFilter = useTableStore(state => state.onExternalFilter);

    const onUpdate = (colKey, masterTermId, value) => {
        const terms = useTableStore.getState().dataCopy;
        return updateTerms(
            terms
                .filter(term => term.masterTermId === masterTermId)
                .map(term => {
                    term[colKey] = parseFloat(value);
                    return term;
                })
        ).then(d => {
            updateRows(d);
            return d;
        });
    };

    const onUpdateMultiple = (colKey, ids, value) => {
        const terms = useTableStore.getState().dataCopy;
        return updateTerms(
            terms
                .filter(term => ids.has(term.masterTermId))
                .map(term => {
                    term[colKey] = parseFloat(value);
                    return term;
                })
        ).then(d => updateRows(d));
    };

    const onDragEnd = (_, changedData) => {
        updateTerms(Array.from(changedData.values()));
    };

    const columns = useMatrixTableColumns(version, brand, rowKey, onUpdate, onUpdateMultiple, useTableStore);
    const someEnabled = columns.some(e => e.editable);

    useEffect(() => {
        onExternalFilter(x => {
            if (termsChangedFilter === 'all') return true;
            else {
                const fieldsToCheck = new Set([
                    masterTermDef.leasing.field,
                    masterTermDef.neu.field,
                    masterTermDef.neuLeasing.field,
                    masterTermDef.neuDiscretionary.field,
                    masterTermDef.flmDiscretionary.field,
                    masterTermDef.publicSector.field,
                    masterTermDef.specialist.field,
                    masterTermDef.rental.field
                ]);
                const lastRevisionTerm = previousTermsMap?.get(x.previousMasterTermId);
                return (
                    lastRevisionTerm &&
                    Object.entries(x)
                        .filter(f => fieldsToCheck.has(f[0]))
                        .some(s => lastRevisionTerm[s[0]] !== s[1])
                );
            }
        });
    }, [termsChangedFilter, onExternalFilter, previousTermsMap]);

    return (
        <div>
            <IvsModalProvider />
            <BaseTable
                autoHeight={true}
                columns={columns}
                enableDragDrop={someEnabled}
                headerEditRenderer={({ dataKey, title }, { useTableStore, selectedRowKeys }) => (
                    <EditablePercentage
                        dataKey={dataKey}
                        editable={true}
                        inputOptions={{ placeholder: `${title} %` }}
                        onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                        useTableStore={useTableStore}
                    />
                )}
                helpId="matrixTermsTable"
                groupBy={['series']}
                isLoading={isLoading}
                onDragEnd={onDragEnd}
                onUpdate={onUpdate}
                rowKey={rowKey}
                selectable={someEnabled}
                tableSizeStyle={{ width: '100%' }}
                useStore={useTableStore}
            />
        </div>
    );
}
