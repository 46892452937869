import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { closeModal, FORM_PATHS } from '../../../actions/modals';
import { AppModalHeader } from '../../common/AppModalHeader';
import CompleteCommunicationForm from '../Forms/CompleteCommunicationForm';

class CompleteCommunicationModal extends React.Component {
    render() {
        const { modals, setModalClosed } = this.props;
        return modals.CompleteCommunicationModal.isOpen ? (
            <Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
                <AppModalHeader toggle={() => setModalClosed()}>Complete Meeting</AppModalHeader>
                <ModalBody>
                    <CompleteCommunicationForm
                        onCancel={() => setModalClosed()}
                        communicationId={modals.CompleteCommunicationModal.data.communicationId}
                        companyId={modals.CompleteCommunicationModal.data.companyId}
                    />
                </ModalBody>
            </Modal>
        ) : (
            ''
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.CompleteCommunicationModal.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompleteCommunicationModal);
