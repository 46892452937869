import create from 'zustand';
import { useDispatch } from 'react-redux';
import { apiCapCodesPost } from '../../../actions/Api/Agreement/apiAgreement';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import capCodesDef from '../capCodesDef';

const idField = capCodesDef.id.field;
export const useCapCodesStore = create(set => storeWithModal(set, idField));

export function useUpdateCapCodes() {
    const updateCapCodesFromFetch = useCapCodesStore(state => state.updateDataFromFetch);
    const setError = useCapCodesStore(state => state.setError);
    const dispatch = useDispatch();

    return capCodes => {
        dispatch(apiCapCodesPost(capCodes))
            .then(response => {
                updateCapCodesFromFetch(response, false);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
