import React from 'react';
import * as Yup from 'yup';
import { FORM_NAMES } from '../../../actions/formNames';
import ReactiveForm from '../../common/ReactiveForm';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../Provider/storeStates';
import { useAgreementAuditStore, useCreateComment } from '../Provider/useAgreementAuditStore';
import notificationDef from '../../Notifications/notificationDef';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    comments: Yup.string()
        .required('Comments are required.')
        .max(500)
});

export default function AgreementAuditForm({ parentEntity, parentEntityId, parentStatus }) {
    const resources = useResources();
    const cancel = useAgreementAuditStore(state => state.closeModal);
    const selected = useAgreementAuditStore(state => state.selected);
    const saving = useAgreementAuditStore(state => state.storeState) === storeStates.saving;

    const create = useCreateComment();

    const initialValues = selected
        ? {
              parentEntity,
              parentEntityId,
              status: parentStatus,
              ...selected,
              comments: '',
              mentionItem: {}
          }
        : {};

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={create}
            initialValues={initialValues}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Mentions columnDef={notificationDef.comments} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
