import React from 'react';
import { Link } from 'react-router-dom';
import BaseTableInCard, { ToggleRenderer, AuditTableButton } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIvsCodesStore, useUpdateIvsCodes } from '../Provider/IvsCodesProvider';
import { ToolTipProvider } from '../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import IvsCodeAuditModal from '../Modals/IvsCodeAuditModal';
import ivsCodeDef from '../ivsCodeDef';

function getDistinctModelRangesAsString(ivsCodes = []) {
    return Array.from(new Set((ivsCodes || []).map(x => x.modelRange))).join(', ');
}

function useColumns() {
    const updateIvsCodes = useUpdateIvsCodes();

    const onToggleDelete = (ivsCode, checked) => {
        updateIvsCodes([{ ...ivsCode, deleted: checked ? 1 : null }]);
    };

    return [
        ivsCodeDef.ivsCode,
        ivsCodeDef.modelDescription,
        {
            ...ivsCodeDef.capCodes,
            cellRenderer: ({ cellData }) => (
                <>
                    {getDistinctModelRangesAsString(cellData)}
                    <Link className="cursor-pointer ml-2" to={`../capcodes/${(cellData || []).map(m => m.ivsCode)}`}>
                        ({cellData?.length || 0})
                    </Link>
                </>
            )
        },
        ivsCodeDef.devCode,
        {
            field: 'exceptionCount',
            title: 'Exceptions Count',
            noGrow: true,
            width: 170,
            cellRenderer: ({ cellData, rowData }) => (
                <>
                    {cellData ? <Link to={`/agreementliveterms?ivsCodeId=${rowData.ivsCodeId}`}>
                        ({rowData.exceptionMin.toFixed(2)}% to {rowData.exceptionMax.toFixed(2)}%) * {cellData}
                    </Link> : 0}
                </>
            )
        },
        ivsCodeDef.updatedBy,
        ivsCodeDef.updatedDate,
        {
            ...ivsCodeDef.deleted,
            noGrow: true,
            width: 120,
            cellRenderer: props => <ToggleRenderer {...props} onToggle={onToggleDelete} />
        },
        {
            ...ivsCodeDef.audits,
            width: 75,
            noGrow: true,
            align: 'right',
            cellRenderer: props => (
                <TableButtonsContainer>
                    <AuditTableButton {...props} entity="IvsCode" entityId={props.rowData[ivsCodeDef.id.field]} />
                </TableButtonsContainer>
            )
        }
    ];
}

export default function IvsCodeTable() {
    const ivsCodes = useIvsCodesStore(state => state.data);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const columns = useColumns();

    return (
        <ToolTipProvider>
            <IvsCodeAuditModal />
            <BaseTableInCard
                cardTitle="IVS Codes"
                columns={columns}
                data={ivsCodes}
                helpId="ivsCodeTableHeading"
                isLoading={isLoading}
                rowKey={ivsCodeDef.id.field}
                tableSizeStyle={{ height: 'calc(100vh - 197px)', width: '100%' }}
            />
        </ToolTipProvider>
    );
}
