import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import { getFieldsNotUpdated, setPropertiesSyncing } from '../helper';
const initialState = [];

const propertiesToIgnoreForErrors = [];

export const getLeadUpdateErrors = (local, remote) => {
    return getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
};

const updateLead = (state, { data }) => {
    return [...data, ...state.filter(x => !data.some(y => y.leadId === x.leadId))];
};

const updateLeadPostRollback = (state, { meta, payload }) => {
    const rollbackTo = meta.unmodifiedLead;
    rollbackTo.syncing = false;
    rollbackTo.error = payload.message;
    return [_.clone(rollbackTo), ...state.filter(x => x.leadId === rollbackTo.leadId)];
};

const updateLeadPostCommit = (state, { meta: { lead: local }, payload: { data: remote } }) => {
    return [remote, ...state.filter(x => local.leadId !== x.leadId && remote.leadId !== x.leadId)];
};

const updateLeadPost = (state, { data }) => {
    const original = state.find(x => data.leadId === x.leadId);
    setPropertiesSyncing(original ? original : { r: 0 }, data);
    if (!data.stage) {
        data.stage = 'CompanyRequestRequested';
    }
    return [data, ...state.filter(x => data.leadId !== x.leadId)];
};

export function leads(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.LEAD_POST_ROLLBACK:
            return updateLeadPostRollback(state, action);
        case ENDPOINTS.API.LEAD_POST_COMMIT:
            return updateLeadPostCommit(state, action);
        case ENDPOINTS.API.LEAD_POST:
            return updateLeadPost(state, action);
        case ENDPOINTS.API.LEAD:
            return updateLead(state, action);
        default:
            return state;
    }
}
