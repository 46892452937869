import { makePostRequest } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiCalendarInvite = (communicationId, url) => async (dispatch, getState) => {
    try {
        await makePostRequest(['calender'], undefined, { url, communicationId });
        toastr.success('Invite Sent', 'Please check your inbox');
    } catch (err) {
        toastr.error('Error', `Could not send calendar invite at this time.`);
    }
};
