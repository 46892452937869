import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { IEvent } from '../../../reducers/Api/Events';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';
import { viewCompany } from '../../../actions/Navigation/viewCompany';
import {useCompanyById} from "../../Company/Providers/useCompanyById";
import {IMeeting} from "../../../reducers/Api/Meeting/filters";
import {Link} from "react-router-dom";

export interface ICalendarEvent {
	id?: string;
	prepend?: string;
	desc?: string;
	title?: string;
	companyName?: string;
	visitAction?: any;
	communication?: any;
	event?: IEvent;
	meeting?: IMeeting;
	icon?: string;
	color?: number;
	start?: Date;
	end?: Date;
	type?: string;
}




interface IProps {
	event: ICalendarEvent;
	resources: any;
	openCompany: (companyId: number, descriptor: string) => void;
}

// @ts-ignore
const CalendarEvent: React.SFC<IProps> = ({ event, resources, openCompany, color }) => {
	const bgColorClass = `background-color-${color || event.color}`;

	const companyId = event.visitAction ? event.visitAction.companyId
		:event.meeting? event.meeting.companyId
		:event.communication ? event.communication.companyId
				:undefined

	const company = useCompanyById(companyId);


	if (event.visitAction) {
		const arChoices = resources.choiceList.communicationActivityRegarding;
		let activityRegarding = arChoices.find((x: any) => x.key === event.visitAction.activityRegarding).value;
		if (activityRegarding && activityRegarding.startsWith('Visit Action - ')) {
			activityRegarding = activityRegarding.substring(15);
		} else if (!activityRegarding) {
			activityRegarding = event.visitAction.activityRegarding;
		}

		return (
			<span className={bgColorClass}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a
					onClick={e => {
						PreventClickThrough(e);
						openCompany(companyId, company.descriptor);
					}}
					href=""
				>
					{company.descriptor}
				</a>
				<br />
				{event.icon ? <i className={`fa ${event.icon} mr-2 ${event.prepend ? 'ml-2' : ''}`} /> : ''}
				{activityRegarding}
			</span>
		);
	}

	if (event.meeting) {
		event.prepend = moment(event.start).format(process.env.REACT_APP_TIME_FORMAT) + ' - ' + moment(event.end).format(process.env.REACT_APP_TIME_FORMAT);

		return (
			<span className={bgColorClass}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a
					onClick={e => {
						PreventClickThrough(e);
						event.meeting && event.meeting.companyId && openCompany(companyId, company.descriptor);
					}}
					href=""
				>
					{company.descriptor}
				</a>
				<br />
				{event.icon ? <i className={`fa ${event.icon} mr-2 ${event.prepend ? 'ml-2' : ''}`} /> : ''}
				{event.meeting.subject}
				{event.prepend ? ' ' + event.prepend : ''} 
			</span>
		);
	}

	if (event.event) {
		return (
			<div className={`${bgColorClass} d-flex flex-row align-items-center`}>
				{event.icon ? <i className={`fa ${event.icon} mr-2 ${event.prepend ? 'ml-2' : ''}`} /> : ''}
				<div className="d-flex flex-column">
					<div>{event.title} (All Day)</div>
					{event.event.details ?<div className="font-italic">{event.event.details}</div>:null}
				</div>
			</div>
		);
	}

	return (
		<span className={bgColorClass}>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			{company?
				<span onClick={e => {
					PreventClickThrough(e);
					//openCompany(companyId, company.descriptor);
				}}>
			<Link

				to={`../company/${companyId}/${company.descriptor}`}
			>
				{company.descriptor}
			</Link></span>:null}

			<br />
			{event.icon ? <i className={`fa ${event.icon} mr-2 ${event.prepend ? 'ml-2' : ''}`} /> : ''}
			{event.title}
			{event.type === "meeting" ? event.prepend ? ` ${event.prepend}` : '' : (<></>)}
		</span>
	);
};

const mapStateToProps = (state: any) => ({ resources: state.resources });

const mapDispatchToProps = (dispatch: any) => ({
	openCompany: (companyId: number, descriptor: string) => dispatch(viewCompany(companyId, descriptor))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CalendarEvent);
