import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { filterNext7DaysVisitActions } from '../../../reducers/Api/VisitActions/VisitAction';
import { createSelector } from 'reselect';
import { visitActionsSelector } from '../../../selectors/Api/VisitActions/visitActionsSelector';
import { getUserIdFromState } from '../../../selectors/Api/User/userSelector';
import UnfilteredAgendaCard from '../../Calendar/Cards/UnfilteredAgendaCard';
import SimpleCard from '../../common/Card/SimpleCard';
import FilteredRows from '../../common/FilteredTable/FilteredRows';
import { filterSpec } from '../../common/FilteredTable/tableAndFilterHelpers';
import { getDaysInFuture } from '../../../reducers/Api/helper';
import { filterNext7DaysEvents } from '../../../reducers/Api/Events/filters';
import { eventsSelector } from '../../../reducers/Api/Events/selectors';
import { meetingsSelector } from '../../../reducers/Api/Meeting/selectors';
import { filterNext7DaysMeetings } from '../../../reducers/Api/Meeting/filters';
import { communicationsSelector } from '../../../reducers/Api/Communications/selectors';
import { filterNext7DaysCommunications } from '../../../reducers/Api/Communications/filter';
import { getResources } from '../../../selectors/Api/Resources/resourcesSelector';

class Next7DaysAgendaCard extends React.Component {
    filters = [
        filterSpec('Status', 'status', 'communicationStatus'),
        //filterSpec('Activities', 'activityRegarding', ['communicationActivityRegarding', 'visitActionActions'])
        {
            label: 'Activities',
            field: 'activityRegarding',
            choiceList: ['communicationActivityRegarding', 'visitActionActions'],
            extraOptions: [
                { key: 'CSMEvent', value: 'Event' },
                { key: 'CSMAnnualLeave', value: 'Holiday' }
            ],
            index: 0
        }
    ];

    render() {
        const { events, visitActions, meetings, communications, isLoading } = this.props;

        return (
            <FilteredRows
                filters={this.filters}
                rows={[...events, ...visitActions, ...meetings, ...communications].map(row =>
                    row.start ? row : { ...row, start: moment(row.startDate) }
                )}
                showFilter={true}
            >
                {({ filter, filteredRows }) => {
                    return (
                        <SimpleCard isLoading={isLoading} title={`Next 7 Days (${filteredRows.length})`}>
                            {filter}
                            <UnfilteredAgendaCard agenda={filteredRows} daysArray={getDaysInFuture(7)} showDayName />
                        </SimpleCard>
                    );
                }}
            </FilteredRows>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            eventsSelector,
            visitActionsSelector,
            meetingsSelector,
            communicationsSelector,
            getUserIdFromState,
            getResources
        ],
        (events, visitActions, meetings, communications, userId, resources) => {
            return {
                events: filterNext7DaysEvents(events, userId),
                communications: filterNext7DaysCommunications(communications, userId),
                visitActions: filterNext7DaysVisitActions(visitActions, userId).map(({ action, ...rest }) => ({
                    action,
                    activityRegarding: action,
                    ...rest
                })),
                meetings: filterNext7DaysMeetings(meetings, userId)
            };
        }
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(Next7DaysAgendaCard);
