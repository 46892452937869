import { useAgreementNotificationStore } from '../../../AgreementNotifications/Provider';
import { Handle } from 'react-flow-renderer';
import { EditNotificationTableRowButton } from '../../../common/Buttons/TableButtons';
import React from 'react';

export const NotificationNodeComponent = ({ data }) => {
    const openModal = useAgreementNotificationStore(state => state.openModal);
    const notifications = useAgreementNotificationStore(state => state.data);

    return (
        <div className="ma-3 bg-light" style={{ padding: '10px', borderRadius: '3px', border: '1px solid black' }}>
            <Handle position="left" style={{ borderRadius: 0 }} type="target" />
            <div>
                <EditNotificationTableRowButton
                    className="mr-2"
                    onClick={() => openModal(notifications.find(x => (x.agreementNotificationId = data.id)))}
                />
                {data.label}
            </div>
        </div>
    );
};
