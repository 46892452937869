import React from 'react';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import {
    useAgreementNotificationStore,
    useAgreementNotificationPeopleStore,
    useUpdateNotificationPeople
} from '../Provider/index';
import { agreementNotificationPeopleDef } from '../agreementNotificationDef';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    agreementNotificationId: Yup.number().required('Notification is required.'),
    personId: Yup.number().required('Person is required.')
});

export default function AgreementNotificationPeopleForm() {
    const notifications = useAgreementNotificationStore(state => state.data);
    const notificationOptions = notifications.map(m => ({
        key: m.agreementNotificationId,
        value: m.agreementNotificationName
    }));
    const cancel = useAgreementNotificationPeopleStore(state => state.closeModal);
    const selected = useAgreementNotificationPeopleStore(state => state.selected);
    const saving = useAgreementNotificationPeopleStore(state => state.storeState) === storeStates.saving;
    const setSaving = useAgreementNotificationPeopleStore(state => state.setSaving);
    const resources = useResources();
    const updateNotifications = useUpdateNotificationPeople();

    const save = notification => {
        setSaving();
        updateNotifications([
            {
                ...notification
            }
        ]);
    };

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={save}
            initialValues={{
                ...selected,
                active: selected?.active !== undefined ? selected.active : true
            }}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Choicelist
                    columnDef={agreementNotificationPeopleDef.agreementNotificationId}
                    options={notificationOptions}
                />
                <ReactiveForm.Choicelist columnDef={agreementNotificationPeopleDef.person} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationPeopleDef.active} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
