import React from 'react';
import CollapseChildren from './CollapseChildren';

export const DebugSection = ({ sections }) =>
    process.env.NODE_ENV === 'development' ? (
        <div className="col-12">
            {sections.map(section => (
                <CollapseChildren key={`name${section.name}`} name={`Debug: ${section.name}`}>
                    <pre>{JSON.stringify(section.object, null, 2)}</pre>
                </CollapseChildren>
            ))}
        </div>
    ) : (
        ''
    );
