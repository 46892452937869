import React, { useEffect } from 'react';
import LoginForm from '../Forms/LoginForm';
import { useDispatch } from 'react-redux';
import { RESET_REDUCER } from '../../../actions/resetReducers';

const LoginPage = () => {
    const dispatch = useDispatch();

    useEffect(() => dispatch({ type: RESET_REDUCER }), [dispatch]);

    return (
        <div className="login-background d-flex no-block justify-content-center align-items-center">
            <LoginForm className="bg-white p-4" />
        </div>
    );
};
export default LoginPage;
