import React from 'react';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import { apiUploadIvsCodesPost } from '../../../actions/Api/Agreement/apiAgreement';
import { useIvsCodesStore } from '../Provider/IvsCodesProvider';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import storeStates from '../../Agreement/Provider/storeStates';

export default function IvsCodesUpload() {
    const updateIvsCodesFromFetch = useIvsCodesStore(state => state.updateDataFromFetch);
    const setSaving = useIvsCodesStore(state => state.setSaving);
    const setError = useIvsCodesStore(state => state.setError);
    const saving = useIvsCodesStore(state => state.storeState) === storeStates.saving;
    const dispatch = useDispatch();

    const onDrop = ([file]) => {
        const reader = new FileReader();
        reader.onload = () => {
            const contentAsBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');

            setSaving();
            dispatch(apiUploadIvsCodesPost({ excelFile: contentAsBase64 }))
                .then(response => {
                    updateIvsCodesFromFetch(response, false);
                })
                .catch(err => setError(err));
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.readAsDataURL(file);
    };

    return (
        <Dropzone
            accept=".xlsx"
            className="button btn btn-secondary"
            disabled={saving}
            multiple={false}
            onDrop={onDrop}
        >
            {saving ? <ShowLoadingSpinner isLoading size={18} /> : 'Upload IVS Codes'}
        </Dropzone>
    );
}
