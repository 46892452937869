import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { types } from '@returnoninvestment/base-table';

export const FilterPopover = ({ column, onFilter, grouped, onGroup }) => {
    const popoverRef = useRef(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(new Set());
    const filtered = filter || selectedFilters.size > 0;

    useEffect(() => {
        if (column.filters && column.type === types.SELECT) setSelectedFilters(column.filters);
        else if (column.filters) setFilter(column.filters);
    }, [column.filters, column.type]);

    const toggle = e => {
        e && e.stopPropagation();

        if (popoverOpen) {
            let theFilter = filter;

            if (column.type === types.SELECT && selectedFilters.size > 0) theFilter = selectedFilters;

            onFilter(column, theFilter);
        }

        setPopoverOpen(!popoverOpen);
    };

    const onSetFilter = e => {
        setFilter(e.target.value);
    };

    const onKeyPress = e => {
        if (e.key === 'Enter') toggle();
    };

    const onSelectFilter = key => {
        let filtersCopy = new Set(selectedFilters);

        if (filtersCopy.has(key)) filtersCopy.delete(key);
        else {
            if (column.singleSelect) filtersCopy.clear();

            filtersCopy.add(key);
        }

        setSelectedFilters(filtersCopy);
    };

    const renderFilterSection = () => {
        let html;

        if (column.type === types.SELECT && column.select) {
            html = (
                <div>
                    {Array.from(column.select.entries()).map(([key, value]) => {
                        const selected = selectedFilters.size === 0 || selectedFilters.has(key);
                        return (
                            <div
                                className={'d-block badge badge-light mb-2 ' + (!selected ? 'line-through' : '')}
                                key={key}
                                onClick={() => onSelectFilter(key)}
                                role="presentation"
                            >
                                {value}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            html = (
                <input
                    autoFocus={true}
                    className="form-control"
                    onChange={onSetFilter}
                    onKeyPress={onKeyPress}
                    type="text"
                    value={filter}
                />
            );
        }

        return html;
    };

    if (!column.filter && !column.groupable) return null;

    return (
        <>
            <i
                className={'BaseTable__filter-indicator fa fa-filter mx-2 mt-1 ' + (filtered ? 'filtered' : '')}
                onClick={toggle}
                ref={popoverRef}
                role="presentation"
                style={{ fontSize: '14px' }}
            />
            {popoverRef.current && (
                <Popover
                    isOpen={popoverOpen}
                    onClick={e => e.stopPropagation()}
                    placement="bottom"
                    target={popoverRef.current}
                >
                    <PopoverHeader>
                        <i className="fa fa-times mt-1 cursor-pointer pull-right" onClick={toggle} />
                        Filter
                    </PopoverHeader>
                    <PopoverBody>
                        {column.groupable && (
                            <button className="btn btn-primary btn-xs" onClick={() => onGroup(column.dataKey)}>
                                {grouped ? 'Clear Group' : 'Group'}
                            </button>
                        )}
                        {column.filter && renderFilterSection()}
                    </PopoverBody>
                </Popover>
            )}
        </>
    );
};

const FilterPopoverHeaderCell = ({ column, ...rest }) => {
    return (
        <div>
            <FilterPopover column={column} {...rest} />
            {column.title}
        </div>
    );
};

export default FilterPopoverHeaderCell;
