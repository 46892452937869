import { useDispatch } from 'react-redux';
import create from 'zustand';
import { apiAgreementMasterTermVersionsPost } from '../../../actions/Api/MasterTermVersion/apiMasterTermVersion';
import { dataStore, storeWithModal, updateDataFromFetch } from '../../Agreement/Provider/storeStates';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { apiMasterTermVersionStatusChange } from '../../../actions/Api/MasterTermVersion/apiMasterTermVersion';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';

const rowKey = 'masterTermVersionId';
export const useMasterTermVersionsStore = create(set => ({
    ...storeWithModal(set, rowKey),
    version: {},
    termsChangedFilter: 'all',
    updateDataFromFetch: (fetchedRows, clearState, postedRows = []) => {
        set(state => {
            const newState = updateDataFromFetch(rowKey, state, fetchedRows, clearState, postedRows);
            return {
                ...newState,
                version: newState.data[0] || {}
            };
        });
    },
    setTermsChangedFilter: termsChangedFilter => set(() => ({ termsChangedFilter }))
}));

export const useCountsStore = create(set => dataStore(set));
export const useUsersVisibleToUserStore = create(set => dataStore(set));

export function useUpdateMasterTermVersion() {
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setSaving = useMasterTermVersionsStore(state => state.setSaving);
    const setError = useMasterTermVersionsStore(state => state.setError);
    const dispatch = useDispatch();

    return version => {
        setSaving();
        return dispatch(apiAgreementMasterTermVersionsPost(version))
            .then(response => {
                updateMasterTermVersionsFromFetch(response.masterTermVersions || []);
                updateAuditsFromFetch(response.masterTermVersionAudits || []);
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useUpdateMasterTermVersionStatus() {
    const updateFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateAddendumFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const setAgreementCounts = useCountsStore(state => state.setData);
    const setSaving = useMasterTermVersionsStore(state => state.setSaving);
    const setError = useMasterTermVersionsStore(state => state.setError);

    return (version, statusFrom, statusTo, cancel) => {
        setSaving();
        return apiMasterTermVersionStatusChange(version.masterTermVersionId, statusFrom, statusTo, version)
            .then(response => {
                updateFromFetch(response.masterTermVersions || []);
                updateAuditsFromFetch(response.masterTermVersionAudits || []);
                updateAddendumFromFetch(response.addendum || []);
                setAgreementCounts(response.agreementAdminCounts);
                cancel && cancel();
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
