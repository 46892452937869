import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { closeModal, FORM_PATHS } from '../../../actions/modals';
import { AppModalHeader } from '../../common/AppModalHeader';
import CancelMeetingForm from '../Forms/CancelMeetingForm';

class CancelMeetingModal extends React.Component {
    render() {
        const { modals, setModalClosed } = this.props;
        return modals.CancelMeetingModal.isOpen ? (
            <Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
                <AppModalHeader toggle={() => setModalClosed()}>Cancel Meeting</AppModalHeader>
                <ModalBody>
                    <CancelMeetingForm
                        onCancel={() => setModalClosed()}
                        communicationId={modals.CancelMeetingModal.data.communicationId}
                        companyId={modals.CancelMeetingModal.data.companyId}
                    />
                </ModalBody>
            </Modal>
        ) : (
            ''
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.CancelMeetingModal.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelMeetingModal);
