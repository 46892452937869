import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import BaseTableInCard, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTableInCard';
import { CompanyVisitActionsContext } from '../../Company/Provider/CompanyVisitActionsProvider';
import meeting from '../../Communication/meeting';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import visitAction from '../visitAction';
import { editVisitAction } from '../../../actions/Navigation/VisitAction/editVisitAction';

const CompanyVisitActionsTableCard = () => {
    const { visitActions } = useContext(CompanyVisitActionsContext);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const dispatch = useDispatch();
    const columns = [
        { ...visitAction.startDate, filter: true, sortable: true },
        useChoicelistCellDefinition(visitAction.activityRegarding, { width: 200 }),
        useChoicelistCellDefinition(visitAction.status, { width: 200 })
    ];
    const editRow = visitAction => dispatch(editVisitAction(visitAction));
    return (
        <BaseTableInCard
            cardTitle={`Visit Actions (${visitActions.length})`}
            columns={columns}
            data={visitActions}
            isLoading={isLoading}
            noItemMessage="No Contacts"
            onRowClicked={({ rowData }) => editRow(rowData)}
            rowKey={meeting.id.field}
            tableSizeStyle={{ height: 'calc(100vh /5)', width: '100%' }}
        />
    );
};

export default CompanyVisitActionsTableCard;
