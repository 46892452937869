import React from 'react';
import { connect } from 'react-redux';
import SimpleCard from '../../common/Card/SimpleCard';
import { CompanyConsumer } from '../../Company/Provider/CompanyProvider';
import UpdateFleetProfileEditForm from '../Forms/UpdateFleetProfileEditForm';

const CompanyFleetProfileEditCard = () => (
    <CompanyConsumer>
        {({ isLoading }) => (
            <SimpleCard collapseName="Company Fleet Profile" isLoading={isLoading} title={'Fleet Profile'}>
                <UpdateFleetProfileEditForm />
            </SimpleCard>
        )}
    </CompanyConsumer>
);

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, undefined)(CompanyFleetProfileEditCard);
