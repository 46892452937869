import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import { FormatDate } from '../../common/FormatDate';
import { useAgreementsStore } from '../../Agreement/Provider/useAgreementsStore';
import { useRevisionsStore } from '../../Agreement/Provider/useRevisionsStore';
import TermCompareTable from '../Tables/TermCompareTable';

export default function AgreementRevisionComparePageContent({ agreementId, revisionId, compareId }) {
    const dispatch = useDispatch();
    const { goBack } = useHistory();
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateRevisionsFromFetch = useRevisionsStore(state => state.updateDataFromFetch);
    const agreement = useAgreementsStore(state => state.agreement);
    const revision = useRevisionsStore(state => state.data[0]);
    const [terms, setTerms] = useState([]);

    //fetch page
    useEffect(() => {
        dispatch(apiPage('agreement-revision-compare', undefined, { agreementId, revisionId, compareId }))
            .then(response => {
                updateRevisionsFromFetch(response.agreementRevisions || [], true);
                updateAgreementsFromFetch(response.agreements || [], true);
                setTerms(response.agreementTermCompares);
            })
            .catch(err => console.error('error', err));
    }, [updateAgreementsFromFetch, updateRevisionsFromFetch, agreementId, revisionId, compareId, dispatch]);

    return (
        <PageSection
            onBackClicked={goBack}
            title={
                revision && (
                    <>
                        {revision.header.reference || 'No Agreement Reference'} ({revision.header.agreementId}) -{' '}
                        {revision.name} <FormatDate date={revision.createdDate} />
                    </>
                )
            }
            titleContent={
                <div>
                    Compared to Agreement: {agreement?.reference} ({agreement?.agreementId})
                </div>
            }
            titleExtra={<PageLoadingSpinner />}
        >
            <TermCompareTable data={terms} />
        </PageSection>
    );
}
