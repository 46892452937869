const storeStates = {
    initial: 'INITIAL',
    saving: 'SAVING',
    hasData: 'HAS_DATA',
    error: 'ERROR'
};

export const updateDataFromFetch = (rowKey, state, fetchedRows, clearState, postedRows = []) => {
    const filterAlreadyInState = state.data.reduce((acc, x) => {
        const updated = fetchedRows.find(r => r[rowKey] === x[rowKey]);
        if (updated) acc.push(updated);
        else if (!postedRows.some(s => s[rowKey] === x[rowKey])) acc.push(x);

        return acc;
    }, []);
    const data = [
        ...filterAlreadyInState,
        ...fetchedRows.filter(x => !filterAlreadyInState.some(c => c[rowKey] === x[rowKey]))
    ];

    return {
        ...state,
        modalOpen: false,
        modalOpen2: false,
        formDirty: false,
        selected: undefined,
        data: clearState ? fetchedRows : data,
        storeState: storeStates.hasData
    };
};

export const dataStore = set => ({
    data: [],
    setData: data => set(() => ({ data }))
});

export const storeWithModal = (set, rowKey) => ({
    data: [],
    storeState: storeStates.initial,
    selected: undefined,
    modalOpen: false,
    modalOpen2: false,
    formDirty: false,
    setFormDirty: () => set(() => ({ formDirty: true })),
    setSaving: () => {
        set(() => ({ storeState: storeStates.saving }));
    },
    setError: error => {
        set(() => ({ storeState: storeStates.error, error }));
    },
    openModal: (selected, second = false, modalData = {}) => {
        set(() => ({ selected, modalOpen: !second, modalOpen2: second, modalData }));
    },
    closeModal: () => {
        set(() => ({ selected: undefined, modalOpen: false, modalOpen2: false }));
    },
    updateDataFromFetch: (fetchedRows, clearState, postedRows = []) => {
        set(state => updateDataFromFetch(rowKey, state, fetchedRows, clearState, postedRows));
    },
    clearData: () => set(() => ({ data: [] }))
});

export default storeStates;
