import React, { useContext } from 'react';
import PageSectionContentTable from '../../common/PageSection/PageSectionContentTable';
import meetingDef from '../meeting';
import { MeetingContext } from '../../Meeting/Provider/MeetingProvider';

export default () => {
	const { meeting } = useContext(MeetingContext);
	return (
		<PageSectionContentTable
			values={meeting}
			columnDefs={[
				meetingDef.status,
				meetingDef.meetingMode,
				meetingDef.meetingType,
				meetingDef.startDate,
				meetingDef.location
			]}
		/>
	);
};
