import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const CompanyVisitActionsContext = React.createContext({
    companyId: undefined,
    visitActions: [],
    isLoading: false
});

const CompanyVisitActionsProvider = ({ companyId, isLoading, visitActions, children }) => (
    <CompanyVisitActionsContext.Provider value={{ companyId, visitActions, isLoading }}>
        {children}
    </CompanyVisitActionsContext.Provider>
);

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) => state.visitActions.filter(x => x.companyId === companyId)
        ],
        (isLoading, visitActions) => ({
            isLoading,
            visitActions
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyVisitActionsProvider);
