import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { getPermission, agreementPermissions } from '../agreementPermissions';
import { useResources } from '../../../selectors/Api/useResources';
import { termsExclusionFormValidationSchema } from './termsExclusionFormValidationSchema';
import { useTermExclusionsModalStore } from '../Modals/TermExclusionsModal';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useUpdateTerms } from '../Provider/useUpdateTerms';
import { useRemainingExclusions } from '../Provider/useTermsStore';

function IvsCodeDependantFormFields({ formikProps, ivsCodesFiltered }) {
    return (
        <ReactiveForm.Choicelist
            columnDef={{ ...basicColumnDef('IVS', 'ivsCodeIds') }}
            formikProps={formikProps}
            isMulti={true}
            options={ivsCodesFiltered.map(x => ({
                key: x.ivsCodeId.toString(),
                value: `${x.ivsCode} - ${x.modelDescription}`
            }))}
        />
    );
}

function ExclusionFormFields({ formikProps, index, ivsCodesFiltered }) {
    const workflow = useAgreementsStore(state => state.agreement?.workflow);
    const baseDiscountPermission = getPermission(agreementPermissions.baseDiscount, workflow);
    const discretionaryDiscountPermission = getPermission(agreementPermissions.discretionaryDiscount, workflow);
    const nextRebatePermission = getPermission(agreementPermissions.nextRebate, workflow);
    const nextActiveSupportBonusPermission = getPermission(agreementPermissions.nextActiveSupportBonus, workflow);
    const nextBaseDiscountPermission = getPermission(agreementPermissions.nextBaseDiscount, workflow);
    const nextDiscretionaryDiscountPermission = getPermission(agreementPermissions.nextDiscretionaryDiscount, workflow);
    const rebatePermission = getPermission(agreementPermissions.rebate, workflow);
    const activeSupportBonusPermission = getPermission(agreementPermissions.activeSupportBonus, workflow);

    return (
        <ReactiveForm.Section formikProps={formikProps} index={index} title="IVS Codes">
            <IvsCodeDependantFormFields ivsCodesFiltered={ivsCodesFiltered} />
            {baseDiscountPermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Base', 'base') }}
                    disabled={!baseDiscountPermission.isEnabled}
                    type="number"
                />
            ) : null}
            {nextBaseDiscountPermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Base', 'nextBase') }}
                    disabled={!nextBaseDiscountPermission.isEnabled}
                    type="number"
                />
            ) : null}
            {discretionaryDiscountPermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Discretionary', 'discretionary') }}
                    disabled={!discretionaryDiscountPermission.isEnabled}
                    type="number"
                />
            ) : null}
            {nextDiscretionaryDiscountPermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Discretionary', 'nextDiscretionary') }}
                    disabled={!nextDiscretionaryDiscountPermission.isEnabled}
                    type="number"
                />
            ) : null}
            {rebatePermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Rebate', 'rebate') }}
                    disabled={!rebatePermission.isEnabled}
                    type="number"
                />
            ) : null}
            {nextRebatePermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Rebate', 'nextRebate') }}
                    disabled={!nextRebatePermission.isEnabled}
                    type="number"
                />
            ) : null}
            {activeSupportBonusPermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Active Support Bonus', 'activeSupportBonus') }}
                    disabled={!activeSupportBonusPermission.isEnabled}
                    type="number"
                />
            ) : null}
            {nextActiveSupportBonusPermission.isVisible ? (
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Active Support Bonus', 'nextActiveSupportBonus') }}
                    disabled={!nextActiveSupportBonusPermission.isEnabled}
                    type="number"
                />
            ) : null}
        </ReactiveForm.Section>
    );
}

function useValidationSchema(ivsCodesFiltered) {
    const workflow = useAgreementsStore(state => state.agreement?.workflow);
    const rebatePermission = getPermission(agreementPermissions.rebate, workflow);
    const activeSupportBonusPermission = getPermission(agreementPermissions.activeSupportBonus, workflow);
    const validateRebate = rebatePermission.isVisible && rebatePermission.isEnabled;
    const validateActiveSupportBonus = activeSupportBonusPermission.isVisible && activeSupportBonusPermission.isEnabled;
    return termsExclusionFormValidationSchema(validateRebate, validateActiveSupportBonus, ivsCodesFiltered);
}

const useIvsCodesFiltered = () => {
    const { useTableStore, term } = useTermExclusionsModalStore(
        ({ useTableStore, term }) => ({ useTableStore, term }),
        shallow
    );
    const getIvsCodes = useRemainingExclusions();
    return useMemo(() => {
        const terms = useTableStore.getState().dataCopy;
        return getIvsCodes(terms, term);
    }, [getIvsCodes, term, useTableStore]);
};

export default function TermExclusionsForm() {
    const { useTableStore, term, isSaving } = useTermExclusionsModalStore(
        ({ useTableStore, term, ivsCodes, isSaving }) => ({ useTableStore, term, ivsCodes, isSaving }),
        shallow
    );
    const { onClose, setState } = useTermExclusionsModalStore(
        ({ onClose, setState }) => ({ onClose, setState }),
        shallow
    );
    const setTermsData = useTableStore(state => state.setTermsData);
    const updateTerms = useUpdateTerms();

    const ivsCodesFiltered = useIvsCodesFiltered();
    const validationSchema = useValidationSchema(ivsCodesFiltered);

    const save = form => {
        setState({ isSaving: true });
        const ivsCodeIds = form.ivsCodeIds.split(',').map(x => parseInt(x));
        return updateTerms(
            ivsCodeIds && ivsCodeIds.length > 0
                ? ivsCodeIds.map(ivsCodeId => ({
                      ...term,
                      ...form,
                      agreementTermId: null,
                      ivsCodeId
                  }))
                : { ...term, ...form }
        )
            .then(d => {
                setTermsData(d, undefined, term.ordinal);
                setState({ isSaving: false });
                onClose();
            })
            .catch(error => setState({ isSaving: false }));
    };

    const resources = useResources();

    return (
        <ReactiveForm
            cancel={onClose}
            formName="add-exclusions"
            handleSubmit={save}
            initialValues={term}
            isSaving={isSaving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ExclusionFormFields ivsCodesFiltered={ivsCodesFiltered} />
        </ReactiveForm>
    );
}
