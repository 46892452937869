import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../../../common/AppModalHeader';
import { storeWithModal } from '../../../Provider/storeStates';
import create from 'zustand';
import TestPdfTemplateForm, { testPdfTemplateDef } from '../Forms/TestPdfTemplateForm';

export const useTestPdfTemplateStore = create(set => ({
    ...storeWithModal(set),
    openModal: selected => {
        set(() => ({ selected, modalOpen: true }));
    }
}));

export const TestPdfTemplateModal = ({ templateName }) => {
    const isOpen = useTestPdfTemplateStore(state => state.modalOpen);
    const close = useTestPdfTemplateStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Test PDF {testPdfTemplateDef[templateName]?.title}</AppModalHeader>
                <ModalBody>
                    <TestPdfTemplateForm templateName={templateName} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default TestPdfTemplateModal;
