import create from 'zustand';
import { useDispatch } from 'react-redux';
import { apiAgreementCheckTypesPost } from '../../../actions/Api/Agreement/apiAgreement';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import agreementCheckTypeDef from '../agreementCheckTypeDef';

const idField = agreementCheckTypeDef.id.field;
export const useAgreementCheckTypeStore = create(set => storeWithModal(set, idField));

export function useUpdateCheckTypes() {
    const updateCheckTypesFromFetch = useAgreementCheckTypeStore(state => state.updateDataFromFetch);
    const setError = useAgreementCheckTypeStore(state => state.setError);
    const dispatch = useDispatch();

    return checkTypes => {
        return dispatch(apiAgreementCheckTypesPost(checkTypes))
            .then(response => {
                updateCheckTypesFromFetch(response, false, checkTypes);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}


export function useUpdateCheckTypesWithoutTableUpdate() {
    const setError = useAgreementCheckTypeStore(state => state.setError);
    const dispatch = useDispatch();

    return checkTypes => {
        return dispatch(apiAgreementCheckTypesPost(checkTypes))
            .then(response => {
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}