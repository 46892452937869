import React from 'react';
import * as Yup from 'yup';
import { FORM_NAMES } from '../../../actions/formNames';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../Provider/storeStates';
import { useAgreementChecksStore, useUpdateChecks } from '../Provider/useAgreementChecksStore';
import { useAgreementsStore } from '../Provider/useAgreementsStore';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object()
    .shape({
        comments: Yup.string()
            .required('Comments are required.')
            .max(500),
        outcome: Yup.bool()
            .required('Please indicate if the check has passed or failed')
            .nullable()
    })
    .test('evidence', function({ mandatoryEvidence, evidence }) {
        return (
            !mandatoryEvidence ||
            evidence ||
            this.createError({
                path: 'evidence',
                message: 'Please add evidence'
            })
        );
    });

export default function AgreementCheckForm() {
    const agreement = useAgreementsStore(state => state.agreement);
    const resources = useResources();
    const cancel = useAgreementChecksStore(state => state.closeModal);
    const saving = useAgreementChecksStore(state => state.storeState) === storeStates.saving;
    const check = useAgreementChecksStore(state => state.selected);
    const updateChecks = useUpdateChecks(agreement.companyId);

    const entity = check
        ? {
              companyId: agreement.companyId,
              entityId: parseInt(check.id),
              entityName: 'agreementCheck',
              description: `${check.name} check - evidence`
          }
        : {};

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={check => updateChecks([check])}
            initialValues={{ ...check, comments: '' }}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.TriStateCheckBox columnDef={{ ...basicColumnDef('Check Passed?', 'outcome') }} />
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Comments', 'comments') }} type="textarea" />
                <ReactiveForm.Dropzone columnDef={{ ...basicColumnDef('Evidence', 'evidence') }} entity={entity} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
