import { base64ToArrayBuffer, makePostRequest } from '../../../../../actions/Api/apiHelpers';
import { toastr } from 'react-redux-toastr';

export default function downloadAgreementPdf(e, agreement, nextMatrix, setDownloading, testPdfTemplate) {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }

    if (setDownloading) setDownloading(true);

    makePostRequest(['agreement', 'generate-pdf-agreement'], undefined, {
        agreementId: agreement.agreementId,
        watermark: 'SAMPLE',
        nextMatrix,
        testPdfTemplate
    })
        .then(d => {
            const array = base64ToArrayBuffer(d.data);
            const blob = new Blob([array], { type: 'application/pdf' });

            if (setDownloading) setDownloading(false);

            window.open(window.URL.createObjectURL(blob), '_blank');
        })
        .catch(err => {
            console.error('error', err);
            if (testPdfTemplate) {
                toastr.error('PDF Generation', 'File structure invalid, please review and try again.')
            } else {
                toastr.error('PDF Generation', err.message);
            }

            if (setDownloading) setDownloading(false);
        });
}
