import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import CapCodesTable from '../Tables/CapCodesTable';
import CapCodesCSV from './CapCodesCSV';
import CapCodesUpload from './CapCodesUpload';
import { useCapCodesStore } from '../Provider/useCapCodesStore';

export default function CapCodesPageContent({ ivsCodes }) {
    const { goBack } = useHistory();
    const updateCapCodesFromFetch = useCapCodesStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    //fetch page
    useEffect(() => {
        dispatch(apiPage('cap-codes'))
            .then(response => {
                updateCapCodesFromFetch(response.capCodes || [], true);
            })
            .catch(err => console.error('error', err));
    }, [updateCapCodesFromFetch, dispatch]);

    return (
        <PageSection
            onBackClicked={goBack}
            title="Cap Codes"
            titleExtra={<PageLoadingSpinner />}
            titleButtonArea={
                <>
                    <CapCodesUpload />
                    <CapCodesCSV className="ml-2" />
                </>
            }
        >
            <CapCodesTable ivsCodes={ivsCodes} />
        </PageSection>
    );
}
