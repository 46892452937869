import React from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FormatDate } from '../../common/FormatDate';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { useRevisionsStore } from '../Provider/useRevisionsStore';
import { useAgreementsStore } from '../Provider/useAgreementsStore';

function useGotoRevisionPage(agreement) {
    const push = useSafeDispatchPush(revision => ['agreement', agreement.agreementId, revision.revisionId]);
    return revision => push(revision);
}

export default function AgreementRevisionsDropdown({ className }) {
    const agreement = useAgreementsStore(state => state.agreement);
    const revisions = useRevisionsStore(state => state.data);
    const gotoRevision = useGotoRevisionPage(agreement);

    return (
        <UncontrolledDropdown className={'d-inline-block ' + className}>
            <DropdownToggle disabled={revisions.length === 0} caret={revisions.length > 0}>
                Revisions
            </DropdownToggle>
            <DropdownMenu right>
                {revisions.map(m => {
                    return (
                        <DropdownItem key={m.revisionId} onClick={() => gotoRevision(m)}>
                            Revision {m.revisionId} <FormatDate date={m.createdDate} />
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}
