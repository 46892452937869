import React, { useState } from 'react';
import Pagination from '../../common/Table/Pagination';
import { ConnectedFieldDetails } from '../../common/Table/addColumnsToRow';
import person from '../../Person/person';
import SimpleCard from '../../common/Card/SimpleCard';

export default function CompanyPeople({ people }) {
    const [showAll, setShowAll] = useState(false);
    const [page, setPage] = useState(0);
    const pageSize = 1;
    const minRow = page * pageSize;
    const maxRow = (page + 1) * pageSize - 1;

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    return (
        <SimpleCard title="People" isLoading={false}>
            {people
                .filter((_, i) => showAll || (i >= minRow && i <= maxRow))
                .map(m => {
                    return (
                        <ConnectedFieldDetails
                            key={m.personId}
                            values={m}
                            idColumnDef={person.id}
                            fields={[
                                person.descriptorWithSalutation,
                                person.jobTitle,
                                person.businessEmailLink,
                                person.businessPhone,
                                person.ddiPhone,
                                person.mobilePhone,
                                person.gdprPreferences
                            ]}
                        />
                    );
                })}
            <Pagination
                key="pagination"
                page={page}
                itemsPerPage={pageSize}
                rowCount={people.length}
                maxPageBtns={4}
                onPageChange={page => setPage(page)}
                toggleShowAll={toggleShowAll}
                showAll={showAll}
            />
        </SimpleCard>
    );
}
