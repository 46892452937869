export const choiceListAdditions = () => [];

export const choiceListsConfig = () => ({
    visitActionActions: {
        CompanyDetailsChanges: { color: ['primary', 'white'] },
        DecisionMakerChanges: { color: ['primary', 'white'] },
        EventInvite: { color: ['primary', 'white'] },
        FleetProfileChange: { color: ['primary', 'white'] },
        GeneralNotes: { color: ['primary', 'white'] },
        IndicativeOffer: { color: ['primary', 'white'] },
        LeasingCompanyContact: { color: ['primary', 'white'] },
        NewMeeting: { color: ['primary', 'white'] },
        NewSageCategory: { color: ['primary', 'white'] },
        OrganiseDemo: { color: ['primary', 'white'] },
        PassToContractHire: { color: ['primary', 'white'] },
        PassToKAM: { color: ['primary', 'white'] },
        PassToOtherBrand: { color: ['primary', 'white'] },
        PassToRetailer: { color: ['primary', 'white'] },
        PolicyRenewalDetails: { color: ['primary', 'white'] },
        ScheduleCall: { color: ['primary', 'white'] },
        SendLiteratureInformation: { color: ['primary', 'white'] },
        AgreementAmended: { color: ['primary', 'white'] },
        Agreementterminated: { color: ['primary', 'white'] },
        VolumeAmended: { color: ['primary', 'white'] },
        TransferredtoanotherAFMCFM: { color: ['primary', 'white'] },
        ProductFeedback: { color: ['primary', 'white'] },
        ALMAction: { color: ['info', 'white'] },
        VanCentreAction: { color: ['primary', 'white'] }
    },
    visitActionStatus: {
        Pending: { color: ['info', 'white'] },
        InProgress: { color: ['warning', 'white'] },
        Complete: { color: ['primary', 'white'] }
    },
    communicationStatus: {
        InProgress: { color: ['warning', 'white'] },
        Cancelled: { color: ['danger', 'white'] },
        Complete: { color: ['primary', 'white'] },
        Pending: { color: ['info', 'white'] },
        Postponed: { color: ['secondary', 'white'] },
        QCDefected: { color: ['danger', 'white'] }
    },
    communicationActivityRegarding: {
        AFMExistingProspectMeeting: { color: ['#6ac890', 'white'] },
        CSMDealerMeeting: { color: ['primary', 'white'] },
        VisitReportActionArrangeDemo: { value: 'Arrange Demo', color: ['warning', 'white'] },
        VisitReportActionBookMeeting: { value: 'Book Meeting', color: ['primary', 'white'] },
        VisitReportActionProvideProductInfo: { value: 'Provide Product Info', color: ['info', 'white'] },
        VisitReportActionProvideQuote: { value: 'Provide Quote', color: ['danger', 'white'] },
        VisitReportActionProvideTerms: { value: 'Provide Terms', color: ['secondary', 'white'] },
        CSMPhoneOut: { color: ['#c39bd3', 'white'], value: 'Phone Out' }
    },
    VisitReportActionArrangeDemo: {
        color: ['warning', 'white'],
        value: { color: ['Arrange Demo'] },
        VisitReportActionBookMeeting: { color: ['primary', 'white'], value: 'Book Meeting' },
        VisitReportActionProvideProductInfo: { color: ['info', 'white'], value: 'Provide Product Info' },
        VisitReportActionProvideQuote: { color: ['danger', 'white'], value: 'Provide Quote' },
        VisitReportActionProvideTerms: { color: ['secondary', 'white'], value: 'Provide Terms' },
        CSMAdminDay: { value: 'Admin Day' },
        CSMAnnualLeave: { value: 'Annual Leave' },
        CSMEvent: { value: 'Event' },
        CSMOBCAMeeting: { value: 'OBCA Meeting' },
        CSMDealerMeeting: { value: 'Dealer Meeting' },
        CSMEmailOut: { value: 'Email Out' },
        PSALeasingRVReviewMeeting: { value: 'RV Review Meeting' },
        PSALeasingPricingReview: { value: 'Pricing Review' },
        PSALeasingTacticalCampaign: { value: 'Tactical Campaign' }
    },
    dealerStatus: {
        Customer: { color: ['#FF6384', 'white'] },
        Prospect: { color: ['#6ac890', 'white'] },
        Suspect: { color: ['#392061', 'white'] }
    },
    leadStage: {
        UCNComplete: { color: ['#c39bd3', 'dark'] },
        UCNIssue: { color: ['#7dcea0', 'dark'] },
        UCNInProgress: { color: ['#7f83d5', 'dark'] },
        UCNCompleted: { color: ['#76d7c4', 'dark'] }
    }
});
