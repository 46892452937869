import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';
/*
allGroupData: ["1 Series"]
brand: "BMW"
createdBy: 1
createdDate: "2021-03-01T09:04:38.563"
deleted: null
deletedBy: null
deletedDate: null
description: "1 Series 5 door (116d, 118d M Sport)"
descriptor: null
devCode: "F40"
flmDiscretionary: 2
id: "2"
index: 0
ivsCodes: (3) [{…}, {…}, {…}]
leasing: 19
masterTermId: 66
masterTermVersionId: 2
name: "5 Door HatchBack"
neu: 23
neuDiscretionary: 0
ordinal: 65
parentId: "g_1 Series"
previousMasterTermId: 2
publicSector: 27
rental: 26
series: "1 Series"
specialist: 23
timeStamp: "2021-03-19T14:16:10.487"
totalDiscretionary: 2
updatedBy: 28
updatedDate: "2021-03-19T14:16:10.487"
 */
function mapToCsvRow(terms) {
    return terms
        .filter(x => x.parentId)
        .map(
            ({
                id,
                brand,
                description,
                devCode,
                flmDiscretionary,
                ivsCodes,
                leasing,
                masterTermId,
                masterTermVersionId,
                name,
                neu,
                neuDiscretionary,
                ordinal,
                parentId,
                previousMasterTermId,
                publicSector,
                rental,
                series,
                specialist,
                totalDiscretionary
            }) => ({
                id,
                brand,
                series,
                devCode,
                description,
                leasing,
                neu,
                neuDiscretionary,
                flmDiscretionary,
                ivsCodes: ivsCodes?.map(x => x.ivsCode).join(','),
                publicSector,
                specialist,
                rental,
                masterTermId,
                masterTermVersionId,
                name,
                ordinal,
                parentId,
                previousMasterTermId,
                totalDiscretionary
            })
        );
}

export default function MatrixPageCsv() {
    const [mappedData, setMappedData] = useState([]);

    const bmwData = useBMWStore(state => state.dataState.data);
    const miniData = useMiniStore(state => state.dataState.data);

    return (
        <CSVLink
            className="button btn btn-primary ml-2"
            data={mappedData}
            filename="matrix.csv"
            headers={[
                'id',
                'brand',
                'series',
                'devCode',
                'description',
                'ivsCodes',
                'leasing',
                'neu',
                'neuDiscretionary',
                'flmDiscretionary',
                'publicSector',
                'specialist',
                'rental'
            ]}
            onClick={() => {
                setMappedData([...mapToCsvRow(bmwData), ...mapToCsvRow(miniData)]);
            }}
        >
            Download Matrix
        </CSVLink>
    );
}
