import React from 'react';
import BaseTableInCard, { ChoiceListRenderer, types } from '../../common/BaseTable/BaseTableInCard';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useCompanyStore } from '../../Company/Providers/useCompanyStore';
import companyDef from '../../Company/companyDef';

export default function CompaniesTable() {
    const data = useCompanyStore(state => state.data);
    const open = useSafeDispatchPush(company => ['company', company.companyId, company.descriptor]);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const salesChannels = useChoiceListForBaseTable(companyDef.salesChannel.choiceList);

    const columns = [
        companyDef.name,
        {
            ...companyDef.city,
            noGrow: true,
            width: 150
        },
        {
            ...companyDef.postcode,
            noGrow: true,
            width: 120
        },
        {
            ...companyDef.salesChannel,
            noGrow: true,
            width: 150,
            filter: true,
            sortable: true,
            type: types.SELECT,
            select: salesChannels,
            cellRenderer: props => <ChoiceListRenderer columnDef={companyDef.salesChannel} {...props} />
        },
        {
            ...companyDef.accountManager,
            noGrow: true,
            width: 150,
            cellRenderer: props => <ChoiceListRenderer columnDef={companyDef.accountManager} {...props} />
        }
    ];

    return (
        <BaseTableInCard
            cardTitle="Companies"
            columns={columns}
            data={data}
            helpId="companiesTable"
            isLoading={isLoading}
            noItemMessage="No Matching Companies Found"
            onRowClicked={row => open(row.rowData)}
            rowKey={companyDef.id.field}
            tableSizeStyle={{ height: 'calc(100vh - 197px)', width: '100%' }}
        />
    );
}