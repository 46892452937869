import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import FormikLabel from './FormikLabel';
import { MandatoryStar } from './ValidationProvider';
import { Link } from 'react-router-dom';

const FormikFormGroup = props => {
    const { formName, fieldName, useBigTitle = false, help, children, title, hideLabel, style = {}, to } = props;
    return (
        <FormGroup style={{ position: 'relative', ...style }}>
            {hideLabel ? null : (
                <FormikLabel
                    fieldName={fieldName}
                    formName={formName}
                    help={help}
                    title={
                        to ? (
                            <>
                                <Link to={to}>{title}</Link>
                                <MandatoryStar fieldName={fieldName} />
                            </>
                        ) : (
                            <>
                                {title}
                                <MandatoryStar fieldName={fieldName} />
                            </>
                        )
                    }
                    useBigTitle={useBigTitle}
                />
            )}
            {children}
        </FormGroup>
    );
};

FormikFormGroup.propTypes = {
    formName: PropTypes.string,
    fieldName: PropTypes.string.isRequired
};

export default FormikFormGroup;
