import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const addendumRulesDef = {
    id: idColumnDef('id', 'addendumRuleId'),
    addendumId: {
        ...basicColumnDef('AddendumId', 'addendumId')
    },
    name: {
        ...basicColumnDef('Name', 'name')
    },
    masterTermId: {
        ...basicColumnDef('Master Term', 'masterTermId'),
        filter: true,
        sortable: true
    },
    masterTerm: {
        ...basicColumnDef('Master Term', 'masterTerm'),
        filter: true,
        sortable: true
    },
    ivsCodeId: {
        ...basicColumnDef('IVS Code', 'ivsCodeId'),
        filter: true,
        sortable: true
    },
    ivsCode: {
        ...basicColumnDef('IVS Code', 'ivsCode'),
        filter: true,
        sortable: true
    },
    changeType: {
        ...basicColumnDef('Change Type', 'changeType'),
        filter: true,
        sortable: true
    },
    targetDiscount: {
        ...basicColumnDef('Target Discount', 'targetDiscount'),
        type: 'number',
        filter: true,
        sortable: true
    },
    comments: {
        ...basicColumnDef('Comments', 'comments')
    },
    insertIfNotPresent: {
        ...basicColumnDef('Insert If Not Present', 'insertIfNotPresent'),
        sortable: true,
        help: {
            helpId: 'insertIfNotPresent',
            helpText: 'If selected then a term or exclusion will be added'
        }
    }
};

export default addendumRulesDef;
