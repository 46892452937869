import {
    basicColumnDef,
    idColumnDef
    //choiceListColumnSettings
} from '../common/FilteredTable/tableAndFilterHelpers';

import React from 'react';
import Map from './Map';
import IsPrimaryPerson from '../Person/Tables/IsPrimaryPerson';
const addressDef = {
    id: idColumnDef('id', 'addressId'),
    map: {
        ...basicColumnDef('Location', 'descriptor'),
        mapping: (resources, values, field, actions) => <Map address={values} />
    },
    streetAddress: {
        ...basicColumnDef('Address', 'address1'),
        mapping: (resources, values, field, actions) =>
            [values.address1, values.address2, values.address3, values.address4]
                .map(x => (x ? x.trim() : ''))
                .filter(x => x)
                .join(', ')
    },
    address1: {
        ...basicColumnDef('Address 1', 'address1'),
        primary: true
    },
    address2: {
        ...basicColumnDef('Address 2', 'address2')
    },
    address3: {
        ...basicColumnDef('Address 3', 'address3')
    },
    address4: {
        ...basicColumnDef('Address 4', 'address4')
    },
    address5: {
        ...basicColumnDef('Address 5', 'address5')
    },
    city: {
        ...basicColumnDef('City', 'city'),
        type: 'text'
    },
    county: {
        ...basicColumnDef('County', 'county'),
        type: 'text'
    },
    country: {
        ...basicColumnDef('Country', 'country'),
        type: 'text'
    },
    postCode: {
        ...basicColumnDef('Postcode', 'postCode'),
        type: 'text'
    },
    isPrimary: {
        ...basicColumnDef('Is Primary Address', 'isPrimaryAddress'),
        cellRenderer: ({ cellData, rowData }) =>
            cellData ? <IsPrimaryPerson id={`isPrimary_${rowData.isPrimaryAddress}`} /> : null,
        type: 'checkbox'
    }
};

export default addressDef;
