import React from 'react';
import { addendumDiscountFieldOptions } from '../Forms/AddendumRuleForm';

function PrintChangesRule({ discount, first, fieldName, changeToFieldType }) {
    return (
        <li>
            {first ? 'First' : 'Then if required'} set <strong>{fieldName}</strong> to a {changeToFieldType} of{' '}
            <strong>{discount?.toFixed(2)}%</strong>
        </li>
    );
}

function PrintChangesRules({ rowData, rowType }) {
    const changeName = rowData.changeType === 'MIN' ? 'Minimum' : 'Maximum';
    const changeToFieldType = rowData.changeType === 'MIN' ? 'Maximum' : 'Minimum';

    const orderedTargets = addendumDiscountFieldOptions
        .reduce((acc, { key, value }) => {
            const discount = rowData[key];
            if (discount) {
                acc.push({ order: rowData[`${key}Priority`], discount, fieldName: value });
            }
            return acc;
        }, [])
        .sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

    return (
        <>
            <p>
                Try to set Total Discount of <strong>{rowType}</strong> to a <strong>{changeName}</strong> of{' '}
                <strong>{rowData.targetDiscount?.toFixed(2)}%</strong>
            </p>
            <ul className="mb-3" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                {orderedTargets.map(({ discount, fieldName }, index) => (
                    <PrintChangesRule
                        changeToFieldType={changeToFieldType}
                        discount={discount}
                        fieldName={fieldName}
                        first={index === 0}
                        key={fieldName}
                    />
                ))}
            </ul>
        </>
    );
}

export function RulesInEnglish({ rowData, rowData: { changeType, ivsCodeId } }) {
    const rowType = ivsCodeId ? 'Exclusion' : 'Term';

    return (
        <div>
            {changeType === 'DEL' ? (
                <p>
                    <strong>Delete {rowType}</strong> if exists
                </p>
            ) : (
                <PrintChangesRules rowData={rowData} rowType={rowType} />
            )}
        </div>
    );
}
