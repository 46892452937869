import React from 'react';
import { CustomInputRenderer, EditableInputRenderer } from '@returnoninvestment/base-table';
import ShowLoadingSpinner from '../../ShowLoadingSpinner';

export function EditableTextAreaRenderer({
    value,
    editable,
    onUpdate,
    dataKey,
    inputOptions,
    onInputChangeMutate = currentValue => currentValue
}) {
    return (
        <CustomInputRenderer
            value={value}
            loadingElement={<ShowLoadingSpinner isLoading={true} />}
            dataKey={dataKey}
            onUpdate={value => onUpdate(dataKey, value)}
        >
            {({ current, setCurrent, save }) => (
                <textarea
                    className="form-control"
                    value={current}
                    disabled={!editable}
                    {...inputOptions}
                    onChange={event => setCurrent(onInputChangeMutate(event.target.value))}
                    onBlur={save}
                    onKeyPress={event => (event.key === 'Enter' ? save() : {})}
                />
            )}
        </CustomInputRenderer>
    );
}

export default function EditableText({ value, editable, onUpdate, dataKey, inputOptions }) {
    return (
        <EditableInputRenderer
            loadingElement={<ShowLoadingSpinner isLoading={true} />}
            editable={editable}
            value={value}
            dataKey={dataKey}
            type="text"
            inputOptions={inputOptions}
            onUpdate={value => onUpdate(dataKey, value)}
        />
    );
}
