import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import { FormatDate } from '../../common/FormatDate';
import { TabsContent, TabsGroup, TabsInCard, TabsTabForCard } from '../../common/Tabs';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import AgreementChecksTable from '../../Agreement/Table/AgreementChecksTable';
import { useAgreementsStore } from '../../Agreement/Provider/useAgreementsStore';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useAgreementChecksStore } from '../../Agreement/Provider/useAgreementChecksStore';
import { useBMWStore, useMiniStore } from '../../Agreement/Provider/useTermsStore';
import { ToolTipProvider } from '../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import AgreementForm from '../../Agreement/Forms/AgreementForm';
import TermsTable from '../../Agreement/Table/TermsTable';
import { CompanyDetailsCard } from '../../Company/Components/CompanyDetails';
import CompanyPeople from './CompanyPeople';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';
import AgreementRevisionsDropdown from '../../Agreement/Components/AgreementRevisionsDropdown';
import { useRevisionsStore } from '../../Agreement/Provider/useRevisionsStore';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import { ContractSignatories } from '../../Agreement/Components/ContractSignatories';
import CompareToAgreementCard from './CompareToAgreementCard';
import AgreementDownloadPdf from '../../Agreement/Components/AgreementDownloadPdf';

export default function AgreementRevisionPageContent({ agreementId, revisionId }) {
    const dispatch = useDispatch();
    const { goBack } = useHistory();
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateRevisionsFromFetch = useRevisionsStore(state => state.updateDataFromFetch);
    const updateAddendaFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);
    const updateChecksFromFetch = useAgreementChecksStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const [revision, setRevision] = useState();

    //fetch page
    useEffect(() => {
        dispatch(apiPage('agreement-revision', undefined, { agreementId, revisionId }))
            .then(response => {
                const newRevision = response.agreementRevisions?.find(f => f.revisionId === revisionId);
                const bmwTerms = (newRevision?.terms || []).filter(term => term.brand === 'BMW');
                const miniTerms = (newRevision?.terms || []).filter(term => term.brand === 'MINI');
                setBMWTerms(bmwTerms);
                setMiniTerms(miniTerms);

                updateMasterTermsFromFetch(response.masterTerms || [], true);
                updateAgreementsFromFetch(
                    [
                        {
                            ...newRevision.header,
                            agreementId,
                            endDate: newRevision.endDate,
                            startDate: newRevision.startDate
                        }
                    ],
                    true
                );
                updateRevisionsFromFetch(response.agreementRevisions || [], true);
                updateAddendaFromFetch(response.addendum || [], true);
                updateChecksFromFetch(response.agreementChecks || [], true);
                setRevision(newRevision);
            })
            .catch(err => console.error('error', err));
    }, [
        setBMWTerms,
        setMiniTerms,
        updateAgreementsFromFetch,
        updateAddendaFromFetch,
        updateMasterTermsFromFetch,
        updateChecksFromFetch,
        agreementId,
        revisionId,
        dispatch,
        updateRevisionsFromFetch
    ]);

    return (
        <PageSection
            onBackClicked={goBack}
            title={
                revision && (
                    <>
                        Revision {revision.revisionId}{' '}
                        <span className="ml-3">
                            <FormatDate date={revision.startDate} /> to <FormatDate date={revision.endDate} />
                        </span>
                    </>
                )
            }
            titleButtonArea={
                <div className="d-flex" style={{ gap: '0.5rem' }}>
                    <AgreementDownloadPdf
                        agreementId={agreementId}
                        companyName={revision?.header.company?.name}
                        revisionId={revisionId}
                    >
                        Download PDF
                    </AgreementDownloadPdf>
                    <AgreementRevisionsDropdown />
                </div>
            }
            titleExtra={<PageLoadingSpinner />}
        >
            <ToolTipProvider>
                {revision && (
                    <div className="row">
                        <div className="col-lg-3">
                            <CompareToAgreementCard agreementId={agreementId} revisionId={revisionId} />
                            <CompanyDetailsCard company={revision.header.company} isLoading={false} />
                            <CompanyPeople people={revision.header.people || []} />
                        </div>
                        <div className="col-lg-9">
                            <TabsInCard>
                                <TabsGroup>
                                    <TabsTabForCard>{`${revision.header.status} Agreement`}</TabsTabForCard>
                                    <TabsContent withPadding={true}>
                                        <AgreementForm disabled={true} />
                                    </TabsContent>
                                </TabsGroup>
                                <TabsGroup>
                                    <TabsTabForCard>Signatories</TabsTabForCard>
                                    <TabsContent>
                                        <CompanyProvider companyId={revision.header.company.companyId}>
                                            <ContractSignatories />
                                        </CompanyProvider>
                                    </TabsContent>
                                </TabsGroup>
                            </TabsInCard>
                            <AgreementChecksTable disabled={true} />
                            <TermsTable brand="BMW" title="BMW" useTableStore={useBMWStore} />
                            <TermsTable brand="MINI" title="MINI" useTableStore={useMiniStore} />
                        </div>
                    </div>
                )}
            </ToolTipProvider>
        </PageSection>
    );
}
