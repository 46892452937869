import create from 'zustand';
import { useDispatch } from 'react-redux';
import {
    apiAgreementTemplatesPost,
    apiAgreementValidateTemplatesPost
} from '../../../actions/Api/Agreement/apiAgreement';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import agreementTemplateDef from '../agreementTemplateDef';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';

const idField = agreementTemplateDef.id.field;
export const useAgreementTemplateStore = create(set => storeWithModal(set, idField));

export const templateEntity = {
    agreement: 'Agreement',
    addendum: 'Addendum',
    manualAddendum: 'ManualAddendum',
    addendumBase: 'AddendumBase',
    matrix: 'Matrix'
};

export const agreementEntities = [templateEntity.agreement, templateEntity.manualAddendum, templateEntity.addendumBase];

export function getEntity(template) {
    const entity = typeof template === 'string' ? template : template?.templateBelongsToEntity;
    return agreementEntities.includes(entity) ? templateEntity.agreement : entity;
}

export function useUpdateTemplates() {
    const updateTemplateFromFetch = useAgreementTemplateStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setError = useAgreementTemplateStore(state => state.setError);
    const dispatch = useDispatch();

    return templates => {
        return dispatch(apiAgreementTemplatesPost(templates))
            .then(response => {
                updateTemplateFromFetch(response.agreementTemplates, false);
                updateAuditsFromFetch(response.agreementHeaderAudits, false);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useValidateTemplates() {
    const setError = useAgreementTemplateStore(state => state.setError);
    const dispatch = useDispatch();

    return templates => {
        return dispatch(apiAgreementValidateTemplatesPost(templates))
            .then(response => {
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
