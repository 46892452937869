import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiNotificationsPost } from '../../../actions/Api/Notification/apiNotification';
import { STATUS } from './Notification';
import Table from './Table';

const NotificationListItems = ({ id, status, children }) => {
    const dispatch = useDispatch();
    const [listItemStyle, setListItemStyle] = useState({});

    return (
        <ul className="list-group-flush" key={id} style={{ paddingLeft: 0 }}>
            <li
                className="list-group-item"
                onBlur={() => setListItemStyle({})}
                onFocus={() => setListItemStyle({ background: '#F1F1F1', borderRadius: '5px' })}
                onMouseOut={() => setListItemStyle({})}
                onMouseOver={() => setListItemStyle({ background: '#F1F1F1', borderRadius: '5px' })}
                style={{ ...listItemStyle }}
            >
                <div
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (status === STATUS.CREATED) dispatch(apiNotificationsPost([id]));
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex={0}
                >
                    {children}
                </div>
            </li>
        </ul>
    );
};

const NotificationItemsList = ({ data, title }) => {    
    return (
        <React.Fragment key={title}>
            {title}
            {data.map(notification => {
                const { id, status } = notification;
                return (
                    <NotificationListItems id={id} key={id} status={status}>
                        <Table data={notification}>
                            <Table.Header />
                            <Table.Body />
                        </Table>
                    </NotificationListItems>
                );
            })}            
        </React.Fragment>
    );
};

export default NotificationItemsList;
