import React from 'react';
import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    timeColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import ColumnTypeFormatCompanyName from '../common/Table/ColumnTypeFormatCompanyName';

const communication = {
    id: idColumnDef('id', 'communicationId'),
    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        )
    },
    eventType: {
        ...choiceListColumnDef('Event Type', 'action', 'communicationEventType'),
        emptyOption: 'Select an Action...'
    },
    personId: {
        ...choiceListColumnDef('Person', 'personId', 'peopleOptions'),
        emptyOption: 'Select a Person...',
        noOptionsText: 'No people in company'
    },
    subject: {
        ...basicColumnDef('Subject', 'subject'),
        searchable: true
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'communicationStatus'),
        emptyOption: 'Select a status...'
    },
    assigneeId: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee'),
        emptyOption: 'Select an assignee...',
        searchable: true,
        mapping: (resources, values) => (values && values.assigneeId ? values.assigneeId.toString() : '')
    },
    startDate: {
        ...dateColumnDef('Communication Date', 'startDate'),
        searchable: true
    },
    startTime: {
        ...timeColumnDef('Meeting Time', 'startDate')
    },
    endDate: {
        ...dateColumnDef('Close Date', 'endDate')
    },
    activityRegarding: {
        ...choiceListColumnDef('Activity Regarding', 'activityRegarding', 'communicationActivityRegarding'),
        emptyOption: 'Select an activity regarding...',
        searchable: true
    },
    action: {
        ...choiceListColumnDef('Action', 'action', 'communicationAction'),
        emptyOption: 'Select an action...',
        searchable: true
    },
    meetingMode: {
        ...choiceListColumnDef('Meeting Mode', 'eventMethod', 'communicationMeetingMode'),
        emptyOption: 'Select a meeting mode...',
        searchable: true
    },
    notes: {
        ...basicColumnDef('Details', 'notes'),
        type: 'textarea'
    },
    body: {
        ...basicColumnDef('Details', 'details'),
        type: 'textarea',
        mapToDisplayedValue: (resources, values) => (values && values.body ? values.body.substring(0, 100) : '')
    },
    location: {
        ...basicColumnDef('Location', 'location')
    },
    activityResult: {
        ...choiceListColumnDef('Activity Result', 'activityResult', 'communicationActivityResult'),
        emptyOption: 'Select an action...',
        searchable: true
    }
};

export default communication;
