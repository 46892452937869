import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import useToggleOpen from './Hooks/useToggleOpen';

const helpTexts = {
    addendaTable: {
        title: 'Addenda',
        content: <p>Use the filter to find addenda</p>
    },
    agreementsAdminAgreementsCard: {
        title: 'Agreements',
        content: (
            <>
                <p>Search for and manage agreements</p>
                <p className="italic">* Recently updated agreements do not include updates to single terms</p>
            </>
        )
    },
    userRolePermissionsTable: {
        title: 'User Role Permissions',
        content: (
            <>
                <p>Sets read, write, and create permissions in the app</p>
                <p>Changes do not take effect until the user is looged in/out</p>
            </>
        )
    },
    agreementsAdminTermsMatrixCard: {
        title: 'Terms Matrices',
        content: 'Manage terms matrices and update the discounts on the draft terms matrix'
    },
    agreementsAdminCompanyCountCard: {
        title: 'Company Card',
        content: ''
    },
    agreementsAdminSettingsActionIVS: {
        title: 'Managing CAP Codes',
        content: ''
    },
    agreementsAdminSettings: {
        title: 'Agreements Settings',
        content: 'BMW Engage Agreements is highly configurable....'
    },
    agreementsAdminExternalData: {
        title: 'Managing External Data',
        content: 'Manage the External Data that powers agreements....'
    },
    agreementsAdminUserManagement: {
        title: 'User Management',
        content: 'Placeholder....'
    },

    agreementsAdminAddendaCard: {
        title: 'Addenda',
        content: 'Manage addenda. Create new addenda and manage agreements through the mass addendum process'
    },
    termsMatricesTableHeading: {
        title: 'Terms Matrices',
        content: <>Some Test Help</>
    },
    agreementNotificationsTableHeading: {
        title: 'Notifications',
        content: <div>Manage the notifications sent to users by editing the table.</div>
    },
    agreementsPageTemplateTab: {
        title: 'Templates',
        content: (
            <>
                <p>The templates used to create the terms letter can be overwritten here.</p>
                <p>
                    The table below shows the default templates used for the letter. And any specific templates uploaded
                    for this agreement.
                </p>
                <p>This will generally be used to give the customer different clauses.</p>
            </>
        )
    },
    matrixPageTemplateTab: {
        title: 'Templates',
        content: (
            <>
                <p>The templates used to create the terms letter can be overwritten here.</p>
                <p>
                    You can provide different templates for different sales channels, if none are provided the defaults
                    are used.
                </p>
            </>
        )
    }
};

export default function HelpPopover({ helpId, helpText, helpTitle, className, color = 'info', style = {} }) {
    const { isOpen, toggle } = useToggleOpen(false);
    return (
        <>
            <i
                className={`fa d-inline fa-lg fa-question-circle cursor-pointer text-${color} ${className}`}
                id={`popover${helpId}`}
                onClick={e => {
                    e.stopPropagation();
                    toggle();
                }}
                style={style}
            />

            <Popover isOpen={isOpen} key="popover" target={`popover${helpId}`} toggle={toggle}>
                <PopoverHeader>{helpTitle || helpTexts[helpId]?.title}</PopoverHeader>
                <PopoverBody>
                    {helpTexts[helpId]?.content}
                    {helpText}
                </PopoverBody>
            </Popover>
        </>
    );
}
