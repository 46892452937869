import { useAgreementsStore } from './useAgreementsStore';
import { agreementPermissions, getPermission } from '../agreementPermissions';
import { useRevisionsStore } from './useRevisionsStore';

function addSignatoryFromRevision(revision, personIdfield, dateField, signatories) {
    const personId = revision[personIdfield];
    const signedDate = revision[dateField];

    if (!personId || !signedDate) {
        return;
    }

    const existing = signatories.find(x => x.personId === personId) || signatories.find(x => !x.personId);
    if (existing) {
        existing.signedDate = signedDate;
        existing.visible = true;
        existing.personId = personId;
    } else {
        signatories.push({
            personId,
            isEditable: false,
            signedDate,
            visible: true
        });
    }
}

export function useContractSignatories() {
    const workflow = useAgreementsStore(state => state.agreement?.workflow);
    const isEditable = getPermission(agreementPermissions.contractSignatories1, workflow).isEnabled;
    const is2ndVisible = getPermission(agreementPermissions.contractSignatories2, workflow).isVisible;

    const agreementRevision = useAgreementsStore(state => state.agreement?.revision);
    const personId1 = useAgreementsStore(state => state.agreement?.contractSignatory1PersonId);
    const personId2 = useAgreementsStore(state => state.agreement?.contractSignatory2PersonId);

    const revisions = useRevisionsStore(state => state.data);
    const revision = revisions.find(revision => revision.revisionId === agreementRevision) || {};

    const signatories = [
        {
            updatedField: 'contractSignatory1PersonId',
            personId: personId1,
            visible: true,
            isEditable
        },
        {
            updatedField: 'contractSignatory2PersonId',
            personId: personId2,
            visible: is2ndVisible,
            isEditable
        }
    ];
    addSignatoryFromRevision(revision, 'firstApproverPersonId', 'firstApprovalDate', signatories);
    addSignatoryFromRevision(revision, 'secondApproverPersonId', 'secondApprovalDate', signatories);

    return signatories.filter(x => x.visible);
}
