import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeSelector = () =>
    createSelector(
        state => state.companies,
        (_, id) => id,
        (companies, id) => id ===undefined|| companies.find(company => company.companyId === id)
    );

export const useCompanyById = id => {
    const selector = useMemo(makeSelector, []);
    return useSelector(state => selector(state, id));
};
