import * as Yup from 'yup';

export const termsExclusionFormValidationSchema = (hasRebate, hasAsB, ivsCodesFiltered) =>
    Yup.object()
        .shape({
            ivsCodeIds: Yup.string()
                .nullable()
                .required('Please select a model')
        })
        .test('t-b-test', function({
            base,
            nextBase,
            discretionary,
            nextDiscretionary,
            totalDiscount,
            rebate,
            nextRebate,
            activeSupportBonus,
            nextActiveSupportBonus
        }) {
            const message = `Total of Base & Discretionary ${hasRebate ? '& Rebate ' : ''}${
                hasAsB ? '& Active Support Bonus ' : ''
            }must be different to original`;
            const newTotal =
                (nextBase || base) +
                (nextDiscretionary || discretionary || 0) +
                (nextActiveSupportBonus || activeSupportBonus || 0) -
                (nextRebate || rebate || 0);
            return (
                newTotal !== totalDiscount ||
                this.createError({
                    path: 'total',
                    message: message
                })
            );
        })
        .test('t-b-test', function({ ivsCodeIds }) {
            return (
                (ivsCodeIds || '').split(',').length < (ivsCodesFiltered?.length || 0) ||
                this.createError({
                    path: 'ivsCodeIds',
                    message: 'Cannot add all ivs codes as exclusion'
                })
            );
        });
