import React from 'react';
import { Button } from 'reactstrap';
import { DebugSection } from './DebugSection';
import ShowLoadingSpinner from './ShowLoadingSpinner';

export const SaveCancelButton = ({
    onSave,
    onCancel,
    disableSave,
    disableCancel,
    pullRight,
    hideCancelButton,
    hideSaveButton,
    saveButtonContent,
    debugSections,
    isSaving
}) => (
    <div className="d-flex  justify-content-end w-100">
        {debugSections ? <DebugSection sections={debugSections} /> : null}

        <Button
            className="mr-2"
            color="warning"
            disabled={disableCancel || isSaving}
            hidden={!!hideCancelButton}
            onClick={() => {
                if (onCancel) onCancel();
            }}
            type="button"
        >
            Cancel
        </Button>
        {onSave ? (
            <Button
                color={isSaving ? 'secondary' : 'primary'}
                disabled={disableSave || isSaving}
                hidden={hideSaveButton}
                onClick={() => onSave()}
                type="button"
            >
                {isSaving ? <ShowLoadingSpinner isLoading size={18} /> : 'Save'}
            </Button>
        ) : (
            <Button
                color={isSaving ? 'secondary' : 'primary'}
                disabled={disableSave || isSaving}
                hidden={hideSaveButton}
                type="submit"
            >
                {isSaving ? <ShowLoadingSpinner isLoading size={18} /> : saveButtonContent || 'Save'}
            </Button>
        )}
    </div>
);
