import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import AgendaDay from '../Components/AgendaDay';
import MapArray from '../../common/MapArray';
import { FieldValue } from '../../common/DisplayField/FieldValue';
import { communicationSelected } from '../../../actions/Navigation/Communication/communicationSelected';
import { editVisitAction } from '../../../actions/Navigation/VisitAction/editVisitAction';
import PropTypes from 'prop-types';

class UnfilteredAgendaCard extends React.Component {
    mapDay = (day, index, { ...data }) => ({
        day: day,
        first: index === 0,
        ...data
    });

    static propTypes = {
        agenda: PropTypes.array,
        daysArray: PropTypes.array
    };

    render = () => {
        const { agenda, updateCommunication, editVisitAction, choiceList, showDayName } = this.props;

        const days = new Array(7).fill(1).map((_, index) =>
            moment()
                .startOf('day')
                .add('days', index)
        );

        return days.length === 0 ? (
            <FieldValue>No Events</FieldValue>
        ) : (
            <MapArray
                data={{
                    agenda,
                    updateCommunication,
                    editVisitAction,
                    choiceList,
                    showDayName
                }}
                from={days}
                map={this.mapDay}
                mapKey={day => day}
            >
                <AgendaDay />
            </MapArray>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    updateCommunication: communication => dispatch(communicationSelected(communication)),
    editVisitAction: visitAction => dispatch(editVisitAction(visitAction))
});

export default connect(undefined, mapDispatchToProps)(UnfilteredAgendaCard);
