import React from 'react';
import { TabsContent, TabsGroup, TabsInCard, TabsTabForCard } from '../../common/Tabs';
import AddendumForm from '../Forms/AddendumForm';
import AddendumAuditsTable from '../Tables/AddendumAuditsTable';
import { useChoiceListValue } from '../../common/Hooks/useChoiceList';
import { useAddendaStore } from '../Provider/useAddendaStore';
import AddendumRulesTable from '../Tables/AddendumRulesTable';
import AddendumRuleAuditModal from '../Modal/AddendumRuleAuditModal';
import AddendumRuleModal from '../Modal/AddendumRuleModal';
import { TableHelpText } from '../../common/BaseTable/BaseTableInCard';
import { useAddendumRulesStore } from '../Provider/useAddendumRulesStore';
import AgreementHeaderTemplateTable from '../../AgreementTemplates/Tables/AgreementHeaderTemplateTable';
import AgreementTemplateModal from '../../AgreementTemplates/Modals/AgreementTemplateModal';
import { useAgreementTemplateStore } from '../../AgreementTemplates/Provider/useAgreementTemplateStore';
import { useAddendumRulesTableColumns } from '../Tables/useAddendumRulesTableColumns';
import { useDownloadAuditCsv } from '../../Agreement/Table/AgreementAuditsTable';

function RulesTitle() {
    const rules = useAddendumRulesStore(state => state.data);
    return `Rules (${rules.length})`;
}

function TemplateTabTitle() {
    const templates = useAgreementTemplateStore(state => state.data);
    const addendumId = useAddendaStore(state => state.addendum?.id);

    if (!addendumId) return 'Templates';

    const activeCount = templates.filter(x => x.active).length;

    return <span>Templates {activeCount > 0 ? <span className="ml-2">({activeCount} active)</span> : null}</span>;
}

function useRulesTabInformation() {
    const workflow = useAddendaStore(state => state.addendum?.workflow);
    const addRulesPermission = workflow?.find(f => f.permission === 'AddRulesToAddendum');
    const canEditRules = addRulesPermission?.isEnabled;
    const addendumRulesTableColumns = useAddendumRulesTableColumns(canEditRules);
    const openRulesModal = useAddendumRulesStore(state => state.openModal);

    return {
        addendumRulesTableColumns,
        openRulesModal,
        canEditRules
    };
}

export default function AddendumTabs() {
    const addendumId = useAddendaStore(state => state.addendum?.addendumId);
    const addendumStatus = useAddendaStore(state => state.addendum?.status);
    const statusName = useChoiceListValue('addendumStatus', addendumStatus);
    const openTemplatesModal = useAgreementTemplateStore(state => state.openModal);
    const { addendumRulesTableColumns, openRulesModal, canEditRules } = useRulesTabInformation();
    const downloadAuditCsv = useDownloadAuditCsv();

    return (
        <>
            <AddendumRuleAuditModal />
            <AddendumRuleModal />
            <AgreementTemplateModal />
            <TabsInCard
                buttons={{
                    1: [
                        {
                            id: 1,
                            onClick: openRulesModal,
                            disabled: !canEditRules,
                            content: 'Add Rule',
                            icon: 'fa-plus'
                        }
                    ],
                    2: [
                        {
                            onClick: () =>
                                openTemplatesModal(undefined, undefined, {
                                    entityId: addendumId,
                                    entity: 'Addendum'
                                }),
                            content: 'Add Template',
                            icon: 'fa-plus'
                        }
                    ],
                    3: [downloadAuditCsv(3, `addendum_${addendumId}`)]
                }}
                help={{
                    1: {
                        helpId: 'addendumRulesTableHeading',
                        helpText: <TableHelpText columns={addendumRulesTableColumns} />
                    },
                    2: {
                        helpId: 'matrixPageTemplateTab'
                    }
                }}
                isLoading={!addendumId}
            >
                <TabsGroup>
                    <TabsTabForCard>{`${statusName} Addendum`}</TabsTabForCard>
                    <TabsContent withPadding={true}>
                        <AddendumForm />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>
                        <RulesTitle />
                    </TabsTabForCard>
                    <TabsContent>
                        <AddendumRulesTable canEditRules={canEditRules} columns={addendumRulesTableColumns} />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>
                        <TemplateTabTitle />
                    </TabsTabForCard>
                    <TabsContent>
                        <AgreementHeaderTemplateTable type="Addendum" />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>Audit</TabsTabForCard>
                    <TabsContent>
                        <AddendumAuditsTable />
                    </TabsContent>
                </TabsGroup>
            </TabsInCard>
        </>
    );
}
