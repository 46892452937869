import React, { useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createMeeting } from '../../../actions/Navigation/Communication/createMeeting';
import { createCommunication } from '../../../actions/Navigation/Communication/createCommunication';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { CompanyContext } from '../Provider/CompanyProvider';
import { apiAgreementCreate } from '../../../actions/Api/Agreement/apiAgreement';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { useCreatableAgreements } from '../Provider/CompanyAgreementsProvider';

function CompanyActions({ createCommunication, /*createAgreement,*/ createMeeting }) {
    const { companyId } = useContext(CompanyContext);
    const dispatch = useDispatch();
    const affiliates = useSelector(state => state.affiliates);
    const parentCompany = affiliates.find(x => x.affiliatedCompanyId === companyId);
    const goToAgreementPage = useSafeDispatchPush(agreement => ['agreement', agreement.agreementId]);

    const agreementButtons = useCreatableAgreements();

    return (
        <ActionButtons>
            <ActionButton
                bootstrapColor="secondary"
                buttonText="Add Task"
                fontawesomeIcon="fa-plus"
                onClickAction={() => createCommunication(companyId, 'communication')}
            />
            <ActionButton
                bootstrapColor="secondary"
                buttonText="Add Meeting"
                fontawesomeIcon="fa-plus"
                onClickAction={() => createMeeting(companyId)}
            />
            {agreementButtons.map(({ text, disabled, agreementType }) => (
                <ActionButton
                    bootstrapColor="secondary"
                    buttonText={text}
                    disabled={parentCompany || disabled}
                    fontawesomeIcon="fa-plus"
                    key={text}
                    onClickAction={() => dispatch(apiAgreementCreate(companyId, agreementType)).then(goToAgreementPage)}
                />
            ))}
        </ActionButtons>
    );
}

// const mapStateToProps = state => ({
// 	//user: state.user
// });

const mapDispatchToProps = dispatch => ({
    // createAgreement: (companyId, name) => dispatch(createAgreement(companyId, name)),
    createMeeting: companyId => dispatch(createMeeting(companyId)),
    createCommunication: companyId => dispatch(createCommunication(companyId))
});

export default connect(
    // mapStateToProps,
    undefined,
    mapDispatchToProps
)(withRouter(CompanyActions));
