import React from 'react';
import PageSection from '../../common/PageSection';
import _ from 'lodash';
import { SubSectionTitle } from '../../common/SubSection/SubSectionTitle';

const versions = [
    {
        version: '1.0.000',
        title: 'Initial Release',
        date: '01/04/2019',
        changes: []
    },
    {
        version: '1.1.000',
        title: 'Agreements Bug Fixes',
        date: '20/08/2021',
        changes: ['Fixes text in emails not being replaced', 'Fixes overwriting pdf and not being able to download it']
    },
    {
        version: '1.1.002',
        title: 'Agreements Bug Fixes',
        date: '20/08/2021',
        changes: [
            'Fixes not being able to clear notifications',
            'Fixes saving notifications',
            'Fixes overwriting existing PDF',
            'Lines in address on agreement',
            'Alows comms not to be send twice fro ccs and ds agreement',
            'Changes end date on agreement to end of the following year if created after oct 31',
            'Surname in lead sheet',
            'Matrix Banding on Mass addendum',
            'Decimals in mass addendum',
            'Improves logos on pdf',
            'Mass addendum Notifications, requires setting up at least 1 notification. Status to and from are ignored',
            'Various fixes to PDFs'
        ]
    },
    {
        version: '1.1.10',
        title: 'Change Requests',
        date: '16/11/2021',
        changes: ['Multiple Change Requests. SQL Fixes']
    },
    {
        version: '1.1.012',
        title: 'Change Requests',
        date: '03/12/2021',
        changes: ['Multiple Change Requests. SQL Fixes']
    }
];

export const currentVersion = () => _.orderBy(versions, ['version'], 'desc')[0];

const VersionsPage = ({ history }) => (
    <PageSection onBackClicked={history.goBack} title="Versions">
        {versions
            .sort((a, b) => (a.version < b.version ? 1 : a.version > b.version ? -1 : 0))
            .map(({ version, date, title, changes }) => (
                <div key={version}>
                    <SubSectionTitle title={`${version} - ${date} - ${title}`} />
                    <ul>
                        {changes.map((change, index) => (
                            <li key={index}>{change}</li>
                        ))}
                    </ul>
                </div>
            ))}
    </PageSection>
);

export default VersionsPage;
