import create from 'zustand';
import { apiAgreementEntityCommentCreate } from '../../../actions/Api/Agreement/apiAgreement';
import { useDispatch } from 'react-redux';
import { storeWithModal } from './storeStates';
import moment from 'moment';

export const auditEntity = {
    addendum: 'Addendum',
    addendumRule: 'AddendumRule',
    agreement: 'Agreement',
    agreementTerm: 'AgreementTerm',
    agreementVolume: 'AgreementVolume',
    check: 'Check',
    ivsCode: 'IvsCode',
    matrix: 'Matrix',
    masterTerm: 'MasterTerm',
    template: 'Template'
};

export const useAgreementAuditStore = create(set => ({
    ...storeWithModal(set, 'auditId'),
    openModal: (entityId, entity, secondaryEntity, secondaryEntityId) => {
        set(state => {
            return {
                ...state,
                selected: { entity, entityId, secondaryEntity, secondaryEntityId },
                modalOpen: true
            };
        });
    }
}));

export function useGroupedAuditComments(entity, entityId, secondaryEntity, secondaryEntityId) {
    //Secondary Entity is for comments on agreement terms
    //That have no changes, hence have no term id
    const data = useAgreementAuditStore(state => state.data);
    if (!entityId && !secondaryEntityId) {
        return [];
    }

    return data
        .filter(
            audit =>
                (audit.entity === entity && audit.entityId === entityId) ||
                (audit.entity === secondaryEntity && audit.entityId === secondaryEntityId)
        )
        .reduce((acc, audit) => {
            const last = acc[acc.length - 1];
            if (
                last &&
                last.createdBy === audit.createdBy &&
                moment(last.createdDate).isSame(moment(audit.createdDate), 'day')
            ) {
                if (last.comments && audit.comments) {
                    acc.push({ ...audit, hideUser: true });
                } else {
                    last.comments = last.comments || audit.comments;
                    last.changes = [
                        ...audit.changes,
                        ...last.changes.filter(c => !audit.changes.some(a => a.col === c.col))
                    ];
                }
            } else {
                acc.push({ ...audit });
            }

            return acc;
        }, []);
}

export function useCreateComment() {
    const updateDataFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setSaving = useAgreementAuditStore(state => state.setSaving);
    const setError = useAgreementAuditStore(state => state.setError);
    const dispatch = useDispatch();

    return comment => {
        //Secondary Entity is for comments on agreement terms
        //That have no changes, hence have no term id
        setSaving();
        return dispatch(
            apiAgreementEntityCommentCreate({
                ...comment,
                entityId: comment.entityId || comment.secondaryEntityId,
                entity: comment.entityId ? comment.entity : comment.secondaryEntity,
                mentions: comment.mentions
            })
        )
            .then(response => {
                updateDataFromFetch([response]);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
