import React, { useState } from 'react';
import { connect } from 'react-redux';
import { apiPersonEdit } from '../../../actions/Api/Person/apiPersonEdit';
import ReactiveForm from '../../common/ReactiveForm';
import person from '../person';
import validation from '../person.validation';
import { createFakeId } from '../../../reducers/util';
import { FORM_NAMES } from '../../../actions/formNames';

const emptyForm = {
    companyId: -1,
    createdBy: 0,
    updatedBy: 0,
    createdDate: '0001-01-01T00:00:00',
    updatedDate: '0001-01-01T00:00:00',
    id: '',
    personId: -1,
    privacyNotice: false,
    salutation: '',
    jobTitle: '',
    firstName: '',
    lastName: '',
    businessEmail: '',
    businessPhone: '',
    mobilePhone: '',
    ddiPhone: '',
    gdprEmail: '',
    gdprDirectMail: '',
    gdprTelephone: '',
    gdprSms: '',
    gdprConfirmed: false,
    isPrimaryPerson: false
};

const PersonForm = ({ save, cancel, personId, companyId, resources, people }) => {
    const [saving, setSaving] = useState(false);
    let initialValues = people.find(person => person.personId === personId);

    if (!initialValues && personId < 0) {
        initialValues = {
            ...emptyForm,
            companyId,
            personId: createFakeId()
        };
    }

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAMES.PERSON}
            handleSubmit={values => save(values, setSaving)}
            initialValues={initialValues}
            isSaving={saving}
            resources={resources}
            validationSchema={validation}
        >
            <ReactiveForm.Section title="Personal Information">
                <ReactiveForm.Choicelist
                    columnDef={person.salutation}
                    disabled={initialValues.personId > 0 && !person.salutation}
                />
                <ReactiveForm.Text columnDef={person.firstName} disabled={initialValues.personId > 0} />
                <ReactiveForm.Text columnDef={person.lastName} disabled={initialValues.personId > 0} />
                <ReactiveForm.Text columnDef={person.jobTitle} />
                <ReactiveForm.Checkbox columnDef={person.isPrimaryPerson} />
                <ReactiveForm.Choicelist columnDef={person.contactRole} isMulti={true} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Contact Details">
                <ReactiveForm.Text columnDef={person.businessEmail} />
                <ReactiveForm.Text columnDef={person.businessPhone} />
                <ReactiveForm.Text columnDef={person.mobilePhone} />
                <ReactiveForm.Text columnDef={person.ddiPhone} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="GDPR">
                <ReactiveForm.Choicelist columnDef={person.gdprEmail} />
                <ReactiveForm.Choicelist columnDef={person.gdprDirectMail} />
                <ReactiveForm.Choicelist columnDef={person.gdprTelephone} />
                <ReactiveForm.Choicelist columnDef={person.gdprSms} />
                <ReactiveForm.Checkbox columnDef={person.gdprConfirmed} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapStateToProps = state => ({
    people: state.people,
    loadingStates: state.loadingStates,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (person, setSaving) => {
        setSaving(true);
        dispatch(apiPersonEdit(person))
            .then(() => {
                setSaving(false);
            })
            .catch(err => {
                setSaving(false);
                console.error(err);
            });
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonForm);
