import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Alert } from 'reactstrap';
import { apiCommunicationPost } from '../../../actions/Api/Communication/apiCommunicationPost';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { FORM_NAMES } from '../../../actions/formNames';
import ReactiveForm from '../../common/ReactiveForm';
import communication from '../communication';
import validation from '../communication.validation';
import _ from 'lodash';

const FORM_NAME = FORM_NAMES.EDIT_COMMUNICATION;

class EditCommunicationForm extends React.Component {
    handleSubmit = formValues => {
        const communication = {
            ...formValues,
            assigneeId: parseInt(formValues.assigneeId, 10),
            assignee: { id: parseInt(formValues.assigneeId, 10) },
            endDate: formValues.startDate
        };
        this.props.save(communication, this.props.onCancel);
    };

    emptyForm = () => {
        const {
            user: { userId, descriptor },
            companyId,
            communicationId
        } = this.props;

        const startDate = moment()
            .hour(9)
            .minute(0)
            .seconds(0)
            .milliseconds(0)
            .format(REST_TIME_FORMAT);

        const endDate = startDate;

        return {
            communicationId,
            activityRegarding: '',
            activityResult: '',
            action: '',
            subject: '',
            assigneeId: userId.toString(),
            assigneeName: descriptor,
            assignee: {
                id: userId,
                descriptor
            },
            status: '',
            details: '',
            companyId,
            company: {
                id: companyId,
                descriptor: `${this.props.companies.find(x => x.companyId === companyId).descriptor}`
            },
            startDate,
            endDate
        };
    };

    render() {
        const { onCancel, resources, communications, communicationId, user } = this.props;
        const options = resources.choiceList.communicationAction.filter(x => {
            switch (x.key) {
                case 'Meeting':
                    return false;
                case 'Sickness':
                    return false;
                case 'Event':
                    return false;
                case 'VisitReportAction':
                    return false;
                default:
                    return true;
            }
        });

        let initialValues = _.cloneDeep(communications.find(x => x.communicationId === communicationId));

        if (!initialValues && communicationId < 0) {
            initialValues = this.emptyForm();
            initialValues.communicationId = communicationId;
        }

        initialValues.assigneeId = initialValues.assigneeId.toString();
        initialValues.companyId = initialValues.companyId.toString();

        const userCannotEdit = initialValues.communicationId > 0 && initialValues.assigneeId !== user.userId.toString();

        return (
            <>
                {userCannotEdit ? (
                    <Alert color="info">You cannot update a communication which has not been assigned to you.</Alert>
                ) : null}
                <ReactiveForm
                    initialValues={initialValues}
                    cancel={onCancel}
                    resources={resources}
                    formName={FORM_NAME}
                    validationSchema={validation}
                    handleSubmit={this.handleSubmit}
                    hideSaveButton={userCannotEdit}
                    hideCancelButton={userCannotEdit}
                >
                    <ReactiveForm.Section>
                        <ReactiveForm.Choicelist
                            columnDef={communication.action}
                            options={options}
                            disabled={userCannotEdit}
                        />
                        <ReactiveForm.Text columnDef={communication.subject} disabled={userCannotEdit} />
                        <ReactiveForm.Text columnDef={communication.body} disabled={userCannotEdit} />
                        <ReactiveForm.Choicelist columnDef={communication.status} disabled={userCannotEdit} />
                        <ReactiveForm.Choicelist
                            columnDef={communication.activityRegarding}
                            filter={x =>
                                initialValues.communicationId > 1
                                    ? x.value.startsWith('BMW')
                                    : x.value === 'BMWCompanyFollowUpCall'
                            }
                            disabled={userCannotEdit}
                        />
                        <ReactiveForm.Choicelist columnDef={communication.activityResult} disabled={userCannotEdit} />
                        <ReactiveForm.Date columnDef={communication.startDate} disabled={userCannotEdit} />
                        <ReactiveForm.Choicelist columnDef={communication.assigneeId} disabled={userCannotEdit} />
                    </ReactiveForm.Section>
                </ReactiveForm>
            </>
        );
    }
}

const mapStateToProps = state => ({
    communications: state.communications,
    companies: state.companies,
    user: state.user,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (communication, cancel) => {
        dispatch(apiCommunicationPost(communication));
        if (cancel) {
            cancel();
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCommunicationForm);
