import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { setShowCommunicationTypeOnCalendar } from '../../../actions/settings';

const AgendaKeyOption = ({ toggleShowOnCalendar, showOnCalendarSettings, color, icon, name, calendarKey }) => {
    const showOnCalendar = showOnCalendarSettings[calendarKey];
    return (
        <Badge
            onClick={() => toggleShowOnCalendar(calendarKey, !showOnCalendar)}
            className={
                showOnCalendar
                    ? `ml-2 hover-pointer background-hover background-color-${color}`
                    : 'ml-2 hover-pointer list-group-item-secondary'
            }
        >
            <i className={`fa ${icon} mr-3`} />
            {showOnCalendar ? name : <s>{name}</s>}
        </Badge>
    );
};

const mapStateToProps = state => ({
    settings: state.settings,
    showOnCalendarSettings: state.settings.showOnCalendarCommunicationTypes
});

const mapDispatchToProps = dispatch => ({
    toggleShowOnCalendar: (type, showOnCalendar) => dispatch(setShowCommunicationTypeOnCalendar(type, showOnCalendar))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgendaKeyOption);
