import * as yup from 'yup';

const validation = (agreementTypeChoiceList, matrixBandingChoiceList) =>
    yup
        .object()
        .shape({
            eventType: yup.string(),
            reference: yup
                .string()
                .nullable()
                .max(100),
            opportunitySummary: yup
                .string()
                .required('Opportunity Summary is required')
                .typeError('Opportunity Summary is required'),
            contractVolume: yup
                .number()
                .required('Contract Volume is required')
                .min(5, 'Contract Volume must be greater than or equal to 5')
                .max(9999, 'Contract Volume must be lower than 10,000')
        })
        .test('agreementType', function({ agreementType, ...values }) {
            return (
                !agreementTypeChoiceList.some(({ filterOnChoiceList, filterOnValue }) => {
                    return !values || !filterOnChoiceList || values[filterOnChoiceList] === filterOnValue;
                }) ||
                agreementType ||
                this.createError({
                    path: 'agreementType',
                    message: 'Please select an Agreement Type'
                })
            );
        })
        .test('matrixBanding', function({ matrixBanding, ...values }) {
            return (
                !matrixBandingChoiceList.some(({ filterOnChoiceList, filterOnValue }) => {
                    return !values || !filterOnChoiceList || values[filterOnChoiceList] === filterOnValue;
                }) ||
                matrixBanding ||
                this.createError({
                    path: 'matrixBanding',
                    message: 'Please select a Matrix Banding'
                })
            );
        });

export default validation;
