import { basicColumnDef, choiceListColumnSettings } from '../common/FilteredTable/tableAndFilterHelpers';

const day = {
	eventType: {
		...basicColumnDef('Event Type', 'eventType'),
		...choiceListColumnSettings('communicationEventType')
	},
	startDate: {
		...basicColumnDef('Start Date', 'startDate'),
		type: 'date'
	},
	endDate: {
		...basicColumnDef('End Date', 'endDate'),
		type: 'date'
	},
	details: {
		...basicColumnDef('Notes', 'details'),
		type: 'textarea'
	}
};

export default day;
