import React, { useState } from 'react';
import { Card } from 'reactstrap';
import ShowLoadingSpinner from '../ShowLoadingSpinner';
import { BaseTableInCardContentRight } from '../BaseTable/BaseTableInCard';

function Tabs({ defaultGroup = 0, children }) {
    const [active, setActive] = useState(defaultGroup);

    return (
        <div>
            <div className="d-flex px-1 py-4">
                <div className="flex-grow-1">
                    {/* returns each Tabs.Name */}
                    {React.Children.map(children, (group, index) => {
                        const tab = group.props.children.find(
                            child => child.type.name === TabsTab.name || child.type.name === TabsTabForCard.name
                        );
                        return tab ? React.cloneElement(tab, { index, active, setActive }) : undefined;
                    })}
                </div>
                <div className="flex-shrink-0">
                    {React.Children.map(children, (group, index) => {
                        const tabButtons = group.props.children.find(child => child.type.name === TabsButtons.name);
                        return tabButtons ? React.cloneElement(tabButtons, { index, active, setActive }) : undefined;
                    })}
                </div>
            </div>
            {React.Children.map(children, (group, index) => {
                const tabContent = group.props.children.find(child => child.type.name === TabsContent.name);
                return tabContent ? React.cloneElement(tabContent, { index, active, setActive }) : undefined;
            })}
        </div>
    );
}

export function TabsInCard({
    defaultGroup = 0,
    onTabChanged,
    children,
    isLoading,
    titleContentRight = {},
    buttons = {},
    help = {}
}) {
    const [active, setActiveTab] = useState(defaultGroup);

    const setActive = value => {
        if (value === active) return;

        onTabChanged && onTabChanged(value);
        setActiveTab(value);
    };

    return (
        <Card className="mb-4 shadow-sm simple-card" style={{ overflow: 'hidden' }}>
            <div className="d-flex justify-content-between base-table-in-card-header text-white">
                <div className="flex-grow-1 d-flex" style={{ gap: '1.25rem' }}>
                    {/* returns each Tabs.Name */}
                    {React.Children.map(children, (group, index) => {
                        if (group === null) {
                            return undefined;
                        }
                        const tab = group.props.children.find(
                            child =>
                                (child.type.name === TabsTab.name || child.type.name === TabsTabForCard.name) &&
                                !child.props.hidden
                        );
                        return tab ? React.cloneElement(tab, { index, active, setActive }) : undefined;
                    })}
                </div>
                <div className="flex-shrink-0">
                    {React.Children.map(children, (group, index) => {
                        if (group === null) {
                            return undefined;
                        }
                        const tabButtons = group.props.children.find(child => child.type.name === TabsButtons.name);
                        return tabButtons ? React.cloneElement(tabButtons, { index, active, setActive }) : undefined;
                    })}
                </div>
                <BaseTableInCardContentRight
                    buttons={buttons[active]}
                    columns={help[active] ? help[active].columns : undefined}
                    content={titleContentRight[active] ? titleContentRight[active] : undefined}
                    helpId={help[active] ? help[active].helpId : undefined}
                    helpText={help[active] ? help[active].helpText : undefined}
                />
            </div>
            {isLoading ? (
                <ShowLoadingSpinner isLoading={true} />
            ) : (
                React.Children.map(children, (group, index) => {
                    if (group === null) {
                        return undefined;
                    }
                    const tabContent = group.props.children.find(child => child.type.name === TabsContent.name);
                    return tabContent ? React.cloneElement(tabContent, { index, active, setActive }) : undefined;
                })
            )}
        </Card>
    );
}

export const TabsGroup = ({ children }) => {
    return <div>{children}</div>;
};

export const TabsTabForCard = ({ index, active, setActive, style = {}, children }) => {
    const styles = {
        background: 'none',
        border: 'none',
        borderBottom: 'none',
        cursor: 'pointer',
        fontSize: '1.25rem',
        margin: '0.25rem 0',
        outline: 'none',
        padding: '0.25rem',
        color: '#FFFFFFBB'
    };
    const stylesActive = { ...style.tabActive, borderBottom: '2px solid rgb(217, 217, 217)', color: 'white' };
    const stylesDetermined = index === active ? { ...styles, ...stylesActive } : styles;
    return (
        <button onClick={() => setActive(index)} style={stylesDetermined}>
            {children}
        </button>
    );
};

export const TabsTab = ({ index, active, setActive, style = {}, children }) => {
    const styles = {
        background: 'none',
        border: 'none',
        borderBottom: 'none',
        cursor: 'pointer',
        fontSize: '1.25rem',
        margin: '0 0.25rem',
        outline: 'none',
        padding: '0.25rem',
        color: 'rgb(33, 37, 41)'
    };
    const stylesActive = { borderBottom: '2px solid rgb(217, 217, 217)', ...style.tabActive };
    const stylesDetermined = index === active ? { ...styles, ...stylesActive } : styles;
    return (
        <button onClick={() => setActive(index)} style={stylesDetermined}>
            {children}
        </button>
    );
};

export const TabsButtons = ({ index, active, children }) => {
    return index === active && <div>{children}</div>;
};

export const TabsContent = ({ index, active, children, withPadding = false }) => {
    return index === active && <div className={withPadding ? 'px-3 pt-3 pb-4' : ''}>{children}</div>;
};

export default Tabs;
