import React from 'react';
import { useHistory } from 'react-router';
import { Badge } from 'reactstrap';
import BaseTable, {
    types,
    FileRenderer,
    ToggleRenderer,
    AuditTableButton,
    useChoicelistCellDefinition
} from '../../common/BaseTable/BaseTable';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import useDimensions from '../../common/Hooks/useDimensions';
import { TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import {
    getEntity,
    templateEntity,
    useAgreementTemplateStore,
    useUpdateTemplates
} from '../Provider/useAgreementTemplateStore';
import AgreementTemplateModal from '../Modals/AgreementTemplateModal';
import agreementTemplateDef from '../agreementTemplateDef';

const MultiRenderer = ({ cellData, column: { multiSelect } }) => {
    const items = cellData?.split(',').map(m => {
        const label = multiSelect.get(m);
        return (
            <div key={m}>
                <span className="badge badge-light">{label}</span>
            </div>
        );
    });

    return <div className="w-100">{items || 'All'}</div>;
};

function EntityRenderer({ entity, entityId }) {
    const { push } = useHistory();

    let icon;

    switch (entity) {
        case templateEntity.agreement:
        case templateEntity.manualAddendum:
        case templateEntity.addendumBase:
            icon = <i className={`fa fa-handshake-o mr-3`} />;
            break;

        case templateEntity.addendum:
            icon = <i className={`fa fa-list-alt mr-3`} />;
            break;

        case templateEntity.matrix:
            icon = <i className={`fa fa-sitemap mr-3`} />;
            break;

        default:
    }

    const onGotoEntity = () => {
        const theEntity = getEntity(entity);
        push(`/${theEntity.toLowerCase()}/${entityId}`);
    };

    if (!entity) return null;

    return (
        <div className="table-link" onClick={onGotoEntity}>
            {icon} {entity} {entityId}
        </div>
    );
}

export const useAgreementTemplateTableColumns = (type = false, showFilesUsed = false) => {
    const matrixBandingList = useChoiceListForBaseTable('matrixBanding');
    const updateTemplates = useUpdateTemplates();

    const onToggleActive = (template, checked) => {
        updateTemplates([{ ...template, active: checked }]);
    };

    const agreementTemplateTableColumns = [
        {
            ...agreementTemplateDef.name,
            cellRenderer: ({ cellData, rowData }) => (
                <div>
                    {cellData} {type && rowData.isBaseTemplate && <Badge color="primary">DEFAULT</Badge>}
                </div>
            )
        },
        {
            field: 'entityId',
            title: 'Entity Id',
            width: 175,
            noGrow: true,
            visible: type === templateEntity.agreement,
            cellRenderer: ({ rowData }) => (
                <EntityRenderer entity={rowData.templateBelongsToEntity} entityId={rowData.templateBelongsToEntityId} />
            )
        },
        {
            ...agreementTemplateDef.addendumId,
            width: 175,
            noGrow: true,
            visible: type === templateEntity.addendum,
            cellRenderer: ({ cellData }) => <EntityRenderer entity={templateEntity.addendum} entityId={cellData} />
        },
        {
            ...agreementTemplateDef.matrixId,
            width: 175,
            noGrow: true,
            visible: type === templateEntity.matrix,
            cellRenderer: ({ cellData }) => <EntityRenderer entity={templateEntity.matrix} entityId={cellData} />
        },
        useChoicelistCellDefinition(agreementTemplateDef.salesChannel, { visible: !type }),
        {
            ...agreementTemplateDef.matrixBanding,
            type: types.SELECT,
            select: matrixBandingList,
            multiSelect: matrixBandingList,
            visible: !type,
            cellRenderer: MultiRenderer
        },
        useChoicelistCellDefinition(agreementTemplateDef.agreementType, { visible: !type }),
        {
            ...agreementTemplateDef.agreementTemplateFile,
            cellRenderer: ({ cellData, rowData }) => (
                <>
                    <FileRenderer cellData={cellData} downloadPath="agreement" />
                    {showFilesUsed && rowData.templateFileUsed && (
                        <Badge className="ml-2" color="primary">
                            USED
                        </Badge>
                    )}
                </>
            )
        },
        {
            ...agreementTemplateDef.standardTermsAndConditionsFile,
            cellRenderer: ({ cellData, rowData }) => (
                <>
                    <FileRenderer cellData={cellData} downloadPath="agreement" />
                    {showFilesUsed && rowData.clauseFileUsed && (
                        <Badge className="ml-2" color="primary">
                            USED
                        </Badge>
                    )}
                </>
            )
        },
        {
            ...agreementTemplateDef.coverLetterFile,
            cellRenderer: ({ cellData, rowData }) => (
                <>
                    <FileRenderer cellData={cellData} downloadPath="agreement" />
                    {showFilesUsed && rowData.coverLetterFileUsed && (
                        <Badge className="ml-2" color="primary">
                            USED
                        </Badge>
                    )}
                </>
            )
        },
        {
            ...agreementTemplateDef.forAddendum,
            width: 75,
            noGrow: true
        },
        {
            ...agreementTemplateDef.active,
            width: 75,
            noGrow: true,
            cellRenderer: props => (
                <ToggleRenderer
                    {...props}
                    disabled={type && getEntity(props.rowData) !== type}
                    onToggle={onToggleActive}
                />
            )
        },
        {
            field: 'actions',
            width: 75,
            noGrow: true,
            align: 'right',
            title: 'Actions',
            cellRenderer: props => (
                <TableButtonsContainer>
                    <AuditTableButton {...props} entity="Template" entityId={props.rowData.agreementTemplateId} />
                </TableButtonsContainer>
            )
        }
    ];

    return agreementTemplateTableColumns;
};

const AgreementTemplateTable = ({ type }) => {
    const templates = useAgreementTemplateStore(state => state.data);
    const openModal = useAgreementTemplateStore(state => state.openModal);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const columns = useAgreementTemplateTableColumns(type);

    const filteredTemplates = templates?.filter(
        template => (!type && template.isBaseTemplate) || (type && getEntity(template) === type)
    );

    const [ref, { y }] = useDimensions({ liveMeasure: false });
    return (
        <>
            <AgreementTemplateModal />
            <div ref={ref} style={{ height: `calc(100vh - ${y}px - 5rem`, width: '100%' }}>
                <BaseTable
                    columns={columns}
                    data={filteredTemplates}
                    expandAll={true}
                    isLoading={isLoading}
                    onRowClicked={!type && (({ rowData }) => !rowData.groupRow && openModal(rowData))}
                    rowKey={agreementTemplateDef.id.field}
                />
            </div>
        </>
    );
};

export default AgreementTemplateTable;
