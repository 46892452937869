import React, { useMemo } from 'react';
import * as yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useAddendumRulesStore, useUpdateAddendumRules } from '../Provider/useAddendumRulesStore';
import masterTermDef from '../../MasterTerm/masterTermDef';
import addendumRulesDef from '../addendumRulesDef';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import ivsCodeDef from '../../IvsCode/ivsCodeDef';
import { useAddendaStore } from '../Provider/useAddendaStore';
import { maxTermDiscountPct } from '../../../config.json';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;
const validationSchema = yup
    .object()
    .shape({
        name: yup
            .string()
            .nullable()
            .required('Please enter a name.'),
        masterTermId: yup
            .number()
            .nullable()
            .required('Please select a Master Term.'),
        changeType: yup
            .string()
            .nullable()
            .required('Please select a Change Type.'),
        amount: yup
            .number()
            .max(maxTermDiscountPct, `Value cannot be greater than ${maxTermDiscountPct}`)
            .min(0)
    })
    .test('targetDiscount', function({ changeType, targetDiscount }) {
        return (
            targetDiscount !== undefined ||
            changeType === 'DEL' ||
            this.createError({
                path: 'targetDiscount',
                message: 'Please enter a target discount.'
            })
        );
    });

function addendumDiscountFieldNames(rowNumber) {
    return [`field_${rowNumber}`, `value_${rowNumber}`];
}

export const addendumDiscountFieldOptions = [
    { key: 'base', value: 'Base' },
    { key: 'discretionary', value: 'Discretionary' },
    { key: 'activeSupportBonus', value: 'ASB' },
    { key: 'rebate', value: 'Rebate' }
];

function DiscountsRow({ rowNumber, formikProps }) {
    const currentSelections = addendumDiscountFieldOptions.map((_, index) => {
        const [field, val] = addendumDiscountFieldNames(index);
        return {
            index,
            key: formikProps.values[field],
            discount: formikProps.values[val]
        };
    });

    const selectionsAtLowerIndex = currentSelections.slice(0, rowNumber);
    const optionsNotUsedAlready = addendumDiscountFieldOptions.filter(
        x => !selectionsAtLowerIndex.some(y => x.key === y.key)
    );

    const selectionsAtHigherIndex = currentSelections.slice(rowNumber + 1).filter(x => x.key);

    const [fieldField, valueField] = addendumDiscountFieldNames(rowNumber);
    const [fieldFieldPrevious, valueFieldPrevious] = addendumDiscountFieldNames(rowNumber - 1);

    const hidden =
        rowNumber !== 0 && !(formikProps.values[fieldFieldPrevious] && formikProps.values[valueFieldPrevious]);

    if (hidden) {
        return null;
    }

    //TODO:change to numbers for discount
    //minimum must be above 0
    return (
        <>
            <ReactiveForm.Choicelist
                columnDef={{ ...basicColumnDef(fieldField, fieldField) }}
                formikProps={formikProps}
                onFormValueChanged={(values, fieldName, value, setFieldValue) => {
                    if (selectionsAtHigherIndex) {
                        const current = values[fieldField];
                        const [fieldFieldNext] = addendumDiscountFieldNames(
                            selectionsAtHigherIndex.find(x => x.key === value)?.index
                        );
                        setFieldValue(fieldFieldNext, current);
                    }
                }}
                onlyShowSelect={true}
                options={optionsNotUsedAlready}
            />
            <ReactiveForm.Text
                columnDef={{ ...basicColumnDef(valueField, valueField) }}
                formikProps={formikProps}
                hideLabel={true}
                max={maxTermDiscountPct}
                min={0}
                step={0.01}
                type="number"
            />
        </>
    );
}

function DiscountsTable({ formikProps }) {
    const changeType = formikProps.values.changeType;
    if (changeType !== 'MIN' && changeType !== 'MAX') {
        return null;
    }

    return (
        <div className="d-grid" style={{ gridTemplateColumns: 'auto auto', gap: '1px' }}>
            <div className="base-table-in-card-header">Field</div>
            <div className="base-table-in-card-header">
                {changeType === 'MIN' ? 'Maximum' : 'Minimum'} Discount For Field
            </div>
            <DiscountsRow formikProps={formikProps} rowNumber={0} />
            <DiscountsRow formikProps={formikProps} rowNumber={1} />
            <DiscountsRow formikProps={formikProps} rowNumber={2} />
            <DiscountsRow formikProps={formikProps} rowNumber={3} />
        </div>
    );
}

export default function AddendumRuleForm() {
    const resources = useResources();
    const masterTerms = useMasterTermStore(state => state.data);
    const ivsCodes = useIvsCodesStore(state => state.data);
    const addendum = useAddendaStore(state => state.addendum);
    const rules = useAddendumRulesStore(state => state.data);
    const cancel = useAddendumRulesStore(state => state.closeModal);
    const saving = useAddendumRulesStore(state => state.storeState) === storeStates.saving;
    const update = useUpdateAddendumRules();

    const masterTermsForForm = useMemo(() => {
        return masterTerms
            .filter(
                f =>
                    !rules.some(
                        r =>
                            r.masterTermId === f.masterTermId &&
                            (!r.ivsCodeId || f.ivsCodes.every(i => rules.some(ri => ri.ivsCodeId === i.ivsCodeId)))
                    )
            )
            .map(m => ({
                key: m[masterTermDef.id.field],
                value: m[masterTermDef.description.field]
            }));
    }, [masterTerms, rules]);

    const ivsCodesForForm = useMemo(() => {
        return ivsCodes
            .filter(f => !rules.some(r => r.ivsCodeId === f.ivsCodeId))
            .map(m => ({
                key: m[ivsCodeDef.id.field],
                value: `${m[ivsCodeDef.ivsCode.field]} - ${m[ivsCodeDef.modelDescription.field]}`
            }));
    }, [ivsCodes, rules]);

    const handleSubmit = record => {
        const discountValues = addendumDiscountFieldOptions.reduce((acc, _, index) => {
            const [field, val] = addendumDiscountFieldNames(index);
            if (record[field]) {
                acc[record[field]] = parseFloat(record[val]);
                acc[`${record[field]}Priority`] = index;
            }
            return acc;
        }, {});
        update({
            addendumId: addendum.addendumId,
            ...record,
            ...discountValues
        });
    };

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={handleSubmit}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={addendumRulesDef.name} />
                <ReactiveForm.Choicelist columnDef={addendumRulesDef.masterTermId} options={masterTermsForForm} />
                <ReactiveForm.Other>
                    {formikProps => (
                        <ReactiveForm.Choicelist
                            columnDef={addendumRulesDef.ivsCodeId}
                            filter={f =>
                                masterTerms
                                    .find(
                                        m => formikProps.values[addendumRulesDef.masterTermId.field] === m.masterTermId
                                    )
                                    ?.ivsCodes.some(i => i.ivsCodeId === f.value)
                            }
                            formikProps={formikProps}
                            isClearable={true}
                            options={ivsCodesForForm}
                        />
                    )}
                </ReactiveForm.Other>
                <ReactiveForm.Choicelist
                    columnDef={addendumRulesDef.changeType}
                    options={[
                        { key: 'MIN', value: 'Minimum' },
                        { key: 'MAX', value: 'Maximum' },
                        { key: 'DEL', value: 'Delete' }
                    ]}
                />
                <DiscountsTable />
                <ReactiveForm.Visible visible={values => 'MIN,MAX'.includes(values[addendumRulesDef.changeType.field])}>
                    <ReactiveForm.Text
                        columnDef={addendumRulesDef.targetDiscount}
                        max={maxTermDiscountPct}
                        step="0.01"
                        type="number"
                    />
                </ReactiveForm.Visible>
                <ReactiveForm.Text columnDef={addendumRulesDef.comments} />
                <ReactiveForm.Checkbox columnDef={addendumRulesDef.insertIfNotPresent} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
