import React from 'react';
import { connect } from 'react-redux';
import Sidebar from '../Sidebar';
import { Nav, Navbar, NavbarBrand } from 'reactstrap';
import NavRouterLink from '../NavRouterLink/.';
import NavigationItems from '../NavigationItems';
import { AppLogo } from '../AppLogo';
import SidebarUserMenu from '../Sidebar/SidebarUserMenu';
import { getNavigationItems } from '../../../config/navigation';
import DealerLogo from '../../app/DealerLogo';
import useToggleOpen from '../../common/Hooks/useToggleOpen';
import Notifications from '../../Notifications/Components/Notification';

const PageWithNavigation = ({ children, user }) => {
    const { toggle, isOpen } = useToggleOpen();
    const { toggle: toggleUserMenu, isOpen: showUserMenu } = useToggleOpen();

    return (
        <>
            <div className="wrapper" key="wrapper">
                <nav className={`${isOpen ? 'active' : ''} sidebar-background shadow`} id="sidebar">
                    <div className="navbar-img sidebar-header-image">
                        <AppLogo className="mt-4" />
                        <div
                            className="p-2 d-grid grid-cols-2 hover-pointer"
                            onClick={toggleUserMenu}
                            role="presentation"
                        >
                            <span>{user.email}</span>
                            <span
                                className={`grid-js-end fa d-inline ${
                                    showUserMenu ? 'fa-chevron-down' : 'fa-chevron-right'
                                }`}
                            />
                        </div>
                    </div>
                    {showUserMenu ? <SidebarUserMenu onLinkOpen={toggle} /> : <Sidebar onLinkOpen={toggle} />}
                </nav>

                <button
                    className={`navbar-btn position-fixed zindex-501 ${isOpen ? '' : 'active'}`}
                    id="sidebarCollapse"
                    onClick={() => toggle()}
                    type="button"
                >
                    <span />
                    <span />
                    <span />
                </button>
                <NavbarBrand className="navbar-brand position-fixed zindex-501" href="/dashboard/home">
                    <AppLogo />
                    <DealerLogo />
                </NavbarBrand>
                <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={() => toggle()} role="presentation" />

                <Navbar
                    className={`px-md-5 px-sm-4 px-2 navbar-img ${
                        isOpen ? 'navbar-open' : ''
                    } position-fixed zindex-500 w-100  shadow`}
                    color="primary"
                    dark
                    expand="md"
                >
                    <Nav className="ml-auto" navbar>
                        <NavigationItems navigation={getNavigationItems(user)} />
                        <Notifications data={user.notifications} />
                        <NavRouterLink icon="sign-out" isVisible link="/" name="Sign Out" />
                    </Nav>
                </Navbar>
                <div className="navbar-offset" id="content" key="MainContent">
                    {children}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(PageWithNavigation);
