import React from 'react';
import { useDispatch} from 'react-redux';
import moment from 'moment';
import { eventStyleGetter, mapCommunicationToCalendarEvent } from '../../../reducers/Api/Communications/Comms';
import CalendarEvent from './CalendarEvent';
import { viewCompany } from '../../../actions/Navigation/viewCompany';
import { openMeeting } from '../../../actions/Navigation/Meeting/meetingSelected';
import { calendarOptions } from '../../../reducers/calendarOptions';

export default function AgendaDayMeeting({ communication, choiceList }){
    const dispatch = useDispatch();

    const updateMeeting= ({ meetingId, descriptor }) => dispatch(openMeeting(meetingId, descriptor));
    const openCompany= (companyId, descriptor) => dispatch(viewCompany(companyId, descriptor));

    const event = mapCommunicationToCalendarEvent(communication, choiceList);
    event.prepend = `${moment(communication.startDate).format(process.env.REACT_APP_TIME_FORMAT)} - ${moment(
        communication.endDate
    ).format(process.env.REACT_APP_TIME_FORMAT)}`;
    event.type = 'meeting';

    return (
        <div
            className={`w-100 mt-1 p-2 hover-pointer background-hover background-color-${calendarOptions.faceToFace.color}`}
            key={communication.communicationId}
            onClick={() => updateMeeting(communication)}
            style={eventStyleGetter(event).style}
        >
            <CalendarEvent
                color={calendarOptions.faceToFace.color}
                event={event}
                openCompany={openCompany}
            />
        </div>
    );
};
