import React from 'react';
import { Button } from 'reactstrap';
import AgreementsTable, { useAgreementsTableDefaultColumns } from '../../Agreements/Table/AgreementsTable';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { HeaderEditRenderer, ToggleRenderer } from '../../common/BaseTable/CusomCellRenderers';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';
import WarningPopover from '../../common/WarningPopover';
import { useAddendaStore } from '../Provider/useAddendaStore';
import {
    useAgreementsStore,
    useProcessAgreementsOnAddendum,
    useToggleAgreementIsOnAddendum,
    useToggleAgreementsOnAddendum
} from '../../Agreement/Provider/useAgreementsStore';
import storeStates from '../../Agreement/Provider/storeStates';
import { useAddendumRulesStore } from '../Provider/useAddendumRulesStore';
import { addendumProcessStatus } from '../addendumDef';
import { FormatDate } from '../../common/FormatDate';
import agreementDef from '../../Agreement/agreementDef';

function toggleDisabledReason(canAdd, canRemove, rowData) {
    if ((!canAdd && !canRemove) || (!canAdd && !rowData.onThisAddendum) || (!canRemove && rowData.onThisAddendum))
        return 'Not avaiable at this stage';

    if (rowData.onAnotherAddendum) return 'Agreement is on another addendum';

    if (rowData.addendumProcessStatus === 1) return 'Agreement has been processed';

    if (rowData.modifiedAfterAddendumApplied) return 'Agreement has been modified';
}

function processDisabledReason(canProcess, rowData) {
    if (!canProcess) return 'Not avaiable at this stage';

    if (!rowData.onThisAddendum && !rowData.onAnotherAddendum) return 'Agreement is not on addendum';

    if (rowData.onAnotherAddendum) return 'Agreement is on another addendum';

    if (rowData.modifiedAfterAddendumApplied) return 'Agreement has been modified, must be processed manually';
}

function ToggleAgreementsRenderer({ canAdd, canRemove, saving, selectedRowKeys, useTableStore }) {
    const addendumId = useAddendaStore(state => state.addendum?.addendumId);
    const agreements = useTableStore(state => state.dataCopy);
    const toggleAgreementsOnAddendum = useToggleAgreementsOnAddendum();
    const selectedAgreements = agreements.filter(f => selectedRowKeys.has(f.agreementId));
    const enabledCount = selectedAgreements.filter(
        f =>
            ((canAdd && !f.onThisAddendum) || (canRemove && f.onThisAddendum)) &&
            !f.onAnotherAddendum &&
            f.addendumProcessStatus !== 1 &&
            !f.modifiedAfterAddendumApplied
    ).length;
    const allRowsDisabled = enabledCount === 0;

    const onToggleAgreements = () => {
        toggleAgreementsOnAddendum(addendumId, Array.from(selectedRowKeys));
    };

    const warnings = [];

    if (selectedAgreements.some(s => s.onAnotherAddendum))
        warnings.push('At least one agreement is on another addendum');

    if (selectedAgreements.some(s => s.addendumProcessStatus === 1))
        warnings.push('At least one agreement has been processed');

    if (selectedAgreements.some(s => s.modifiedAfterAddendumApplied))
        warnings.push('At least one agreement has been modified');

    return (
        <>
            <WarningPopover
                bodyText={
                    <>
                        {allRowsDisabled ? 'All agreements cannot be toggled' : 'Some agreements cannot be toggled'}
                        <br />
                        <br />
                        {warnings.map((m, i) => (
                            <div key={i}>{m}</div>
                        ))}
                    </>
                }
                className="mr-2"
                id="processDisabledWarnings"
                visible={allRowsDisabled || warnings.length > 0}
            />
            <Button color="info" disabled={allRowsDisabled || saving} onClick={onToggleAgreements}>
                {!saving ? <>Toggle ({enabledCount})</> : <ShowLoadingSpinner isLoading={true} size={18} />}
            </Button>
        </>
    );
}

function ProcessAgreementsRenderer({ saving, selectedRowKeys, useTableStore }) {
    const addendumId = useAddendaStore(state => state.addendum?.addendumId);
    const agreements = useTableStore(state => state.dataCopy);
    const processAgreementsOnAddendum = useProcessAgreementsOnAddendum();
    const selectedAgreements = agreements.filter(f => selectedRowKeys.has(f.agreementId));
    const enabledCount = selectedAgreements.filter(
        f =>
            !f.onAnotherAddendum && f.onThisAddendum && f.addendumProcessStatus !== 1 && !f.modifiedAfterAddendumApplied
    ).length;
    const allRowsDisabled = enabledCount === 0;

    const onProcessAgreements = () => {
        processAgreementsOnAddendum(addendumId, Array.from(selectedRowKeys));
    };

    const warnings = [];

    if (selectedAgreements.some(s => !s.onThisAddendum && !s.onAnotherAddendum))
        warnings.push('At least one agreement is not on addendum');

    if (selectedAgreements.some(s => s.onAnotherAddendum))
        warnings.push('At least one agreement is on another addendum');

    if (selectedAgreements.some(s => s.addendumProcessStatus === 1))
        warnings.push('At least one agreement has been processed');

    if (selectedAgreements.some(s => s.modifiedAfterAddendumApplied))
        warnings.push('At least one agreement has been modified');

    return (
        <>
            <WarningPopover
                bodyText={
                    <>
                        {allRowsDisabled ? 'All agreements cannot be processed' : 'Some agreements cannot be processed'}
                        <br />
                        <br />
                        {warnings.map((m, i) => (
                            <div key={i}>{m}</div>
                        ))}
                    </>
                }
                className="mr-2"
                id="toggleDisabledWarnings"
                visible={allRowsDisabled || warnings.length > 0}
            />
            <Button color="info" disabled={allRowsDisabled || saving} onClick={onProcessAgreements}>
                {!saving ? <>Process ({enabledCount})</> : <ShowLoadingSpinner isLoading={true} size={18} />}
            </Button>
        </>
    );
}

export default function AssignAgreementsToAddendumTable() {
    const addendumId = useAddendaStore(state => state.addendum?.addendumId);
    const versionId = useAddendaStore(state => state.addendum?.masterTermVersionId);
    const workflow = useAddendaStore(state => state.addendum?.workflow);
    const rulesCount = useAddendumRulesStore(state => state.data?.length);
    const saving = useAgreementsStore(state => state.storeState) === storeStates.saving;
    const defaultColumns = useAgreementsTableDefaultColumns();

    const toggleAgreementIsOnAddendum = useToggleAgreementIsOnAddendum();
    const processAgreementsOnAddendum = useProcessAgreementsOnAddendum();

    const addPermission = workflow?.find(f => f.permission === 'AddAgreementsToAddendum');
    const removePermission = workflow?.find(f => f.permission === 'RemoveAgreementsFromAddendum');
    const processPermission = workflow?.find(f => f.permission === 'ProcessAgreements');
    const visible = addPermission?.isVisible;
    const canAdd = addPermission?.isEnabled;
    const canRemove = removePermission?.isEnabled;
    const canProcess = processPermission?.isEnabled;

    const onToggle = (agreement, checked) => {
        toggleAgreementIsOnAddendum(checked, addendumId, agreement.agreementId);
    };

    const onProcess = (e, agreement) => {
        PreventClickThrough(e);
        processAgreementsOnAddendum(addendumId, [agreement.agreementId]);
    };

    const columns = [
        ...defaultColumns,
        agreementDef.matrixBanding,
        agreementDef.status,
        {
            ...basicColumnDef('Updating Version', 'updatingVersion'),
            noGrow: true,
            width: 110,
            cellRenderer: ({ rowData }) => (
                <ToggleRenderer
                    cellData={rowData.masterTermVersionId !== versionId
                        || (rowData.previousMasterTermVersionId
                            && rowData.previousMasterTermVersionId !== rowData.masterTermVersionId)}
                    disabled={true}
                />
            )
        },
        {
            ...basicColumnDef('Rules Matches', 'addendumRules'),
            cellRenderer: ({ cellData }) => (
                <div>
                    {rulesCount > 0 ? (
                        cellData?.length > 0 ? (
                            cellData.map(m => <div key={m.id}>{m.name}</div>)
                        ) : (
                            <div>None</div>
                        )
                    ) : (
                        <div>N/A</div>
                    )}
                </div>
            )
        },
        {
            ...basicColumnDef('On Addendum', 'onThisAddendum'),
            sortable: false,
            filter: false,
            editable: canAdd || canRemove,
            noGrow: true,
            width: 130,
            cellRenderer: ({ cellData, rowData }) => {
                const disabledReason = toggleDisabledReason(canAdd, canRemove, rowData);
                const disabled = !addendumId || saving || !!disabledReason;
                return (
                    <ToggleRenderer
                        cellData={cellData}
                        disabled={disabled}
                        onToggle={onToggle}
                        rowData={rowData}
                        title={disabledReason}
                    />
                );
            },
            headerEditRenderer: (_, { selectedRowKeys, useTableStore }) => (
                <ToggleAgreementsRenderer
                    canAdd={canAdd}
                    canRemove={canRemove}
                    saving={saving}
                    selectedRowKeys={selectedRowKeys}
                    useTableStore={useTableStore}
                />
            ),
            groupRenderer: ({ editable }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <ToggleAgreementsRenderer
                            canAdd={canAdd}
                            canRemove={canRemove}
                            saving={saving}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        },
        {
            ...basicColumnDef('Processed', 'addendumProcessStatus'),
            sortable: false,
            filter: false,
            editable: canProcess,
            noGrow: true,
            width: 130,
            cellRenderer: ({ cellData, rowData }) => {
                const disabledReason = processDisabledReason(canProcess, rowData);
                const disabled = !addendumId || saving || !!disabledReason;

                return (
                    <>
                        {['Addendum Approved'].includes(rowData.status) ? (
                            <div>Manually Complete</div>
                        ) : !cellData ? (
                            <Button
                                color="info"
                                disabled={disabled}
                                onClick={e => onProcess(e, rowData)}
                                title={disabledReason}
                            >
                                {!saving ? <>Process</> : <ShowLoadingSpinner isLoading={true} size={18} />}
                            </Button>
                        ) : (
                            <div>
                                {addendumProcessStatus[cellData]}
                                <div>
                                    {rowData.addendumProcessedDate && (
                                        <FormatDate date={rowData.addendumProcessedDate} showTime={true} />
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                );
            },
            headerEditRenderer: (_, { selectedRowKeys, useTableStore }) => (
                <ProcessAgreementsRenderer
                    saving={saving}
                    selectedRowKeys={selectedRowKeys}
                    useTableStore={useTableStore}
                />
            ),
            groupRenderer: ({ editable }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <ProcessAgreementsRenderer
                            saving={saving}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            )
        }
    ];

    if (!visible) return null;

    return (
        <AgreementsTable
            columns={columns}
            customMap={a => ({
                onThisAddendum: a.addendumId === addendumId,
                onAnotherAddendum: a.addendumId && a.addendumId !== addendumId
            })}
            fromAddendumPage={true}
            groupBy={[]}
            height="500px"
            selectable={canAdd || canRemove}
        />
    );
}
