import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: idColumnDef('Id', 'linkId'),
    name: {
        ...choiceListColumnDef('Name', 'leasingCompanyId', 'leasingCompany'),
        sortable: true,
        filter: true,
        type: 'text',
        help: {
            helpId: 'leasingCompanyId',
            helpText: 'The name of the leasing company'
        }
    },
    createdDate: {
        ...dateColumnDef('Created Date', 'createdDate'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'createdDate',
            helpText: 'The create date of the leasing company'
        }
    },
    active: {
        ...basicColumnDef('Active', 'deleted'),
        sortable: true
    },
    sendEmail: {
        ...basicColumnDef('Send Email', 'sendEmail')
    }
};
