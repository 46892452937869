import React, { useContext, useEffect, useState } from 'react';

const ValidationContext = React.createContext(undefined);

export const ValidationProvider = ({ yup, children }) => {
    const [schema, setSchema] = useState({ fields: {}, tests: [] });

    useEffect(() => {
        setSchema(yup?.describe() || {});
    }, [yup, setSchema]);

    return (
        <ValidationContext.Provider
            value={{
                schema
            }}
        >
            {children}
        </ValidationContext.Provider>
    );
};

export const useValidationContext = () => {
    const state = useContext(ValidationContext);
    if (state === undefined) {
        throw new Error(`useValidationContext must be used inside ValidationProvider`);
    }
    return state;
};

export function MandatoryStar({ fieldName }) {
    const { schema } = useValidationContext();
    const required = (schema.fields?.[fieldName]?.tests || []).find(test => (test.name = 'required' && !test.params));

    return required ? <span className="super ml-1">*</span> : null;
}
