import React, { useState, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import Filter from '../FilteredTable/Filter';
import { DisplayField } from '../DisplayField/index';
import MapArray from '../../common/MapArray';
import { TableFilterInTableRow } from './TableFilter';

const buildFilters = (filterDefs, resources) => (filterDefs || []).map(x => new Filter(x, resources));

const FilteredRows = ({ filters: filterDefs, resources, rows, children, showFilter, showCount, searchBox }) => {
    const [filters, setFilters] = useState(buildFilters(filterDefs, resources));

    useEffect(
        () => {
            setFilters(buildFilters(filterDefs, resources));
        },
        [filterDefs, resources]
    );

    const filteredRows = filters.reduce((remainingRows, filter) => filter.filterRows(remainingRows), rows);

    const filterElements = !showFilter ? null : (
        <div className="border-bottom-1 w-100" key="filter">
            {searchBox && (
                <div className="row">
                    <div className="col-12">{searchBox}</div>
                </div>
            )}
            <table className="table table-borderless table-sm">
                <tbody>
                    <MapArray
                        from={filters}
                        map={(filter, index, { update }) => ({
                            ...filter.buildRenderData(filteredRows),
                            update
                        })}
                        mapKey={filter => filter.field}
                        data={{
                            update: (field, option, value) => {
                                const filterIndex = filters.findIndex(x => x.field === field);
                                if (filterIndex > -1) {
                                    filters[filterIndex] = filters[filterIndex].updateOption(option, value);
                                    setFilters([...filters]);
                                }
                            }
                        }}
                    >
                        <TableFilterInTableRow />
                    </MapArray>
                </tbody>
            </table>
            {showCount && <DisplayField title={'Count:'} value={filteredRows.length} inline />}
        </div>
    );

    return children({ filter: filterElements, filteredRows });
};

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(FilteredRows);
