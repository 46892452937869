import React from 'react';
import { Button } from 'reactstrap';
import BaseTable, { ChoiceListRenderer, ToggleRenderer } from '../../common/BaseTable/BaseTable';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { FormatDate } from '../../common/FormatDate';
import LeasingCompanyModal from '../Modals/LeasingCompanyModal';
import { useLeasingCompaniesStore, useUpdateLeasingCompanies } from '../Provider/useLeasingCompaniesStore';
import leasingCompanyDef from '../leasingCompanyDef';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { agreementPermissions, getPermission } from '../agreementPermissions';
import { sendLeasingCompanyEmail } from '../../../actions/Api/LeasingCompany/sendLeasingCompanyEmail';

export default function LeasingCompaniesTable() {
    const agreement = useAgreementsStore(state => state.agreement);
    const { status, workflow, hasLeasingNotifications } = { ...agreement };
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const leasingCompanies = useLeasingCompaniesStore(state => state.data);
    const agreementCompanyId = useAgreementsStore(state => state.agreement?.companyId);
    const updateLeasingCompanies = useUpdateLeasingCompanies(agreementCompanyId);
    const isEditable = getPermission(agreementPermissions.leasingCompanies, workflow).isEnabled;

    const onToggleActive = (rowData, checked) => {
        updateLeasingCompanies([{ ...rowData, deleted: checked ? null : 1 }]);
    };

    const columns = [
        {
            ...leasingCompanyDef.name,
            cellRenderer: props => <ChoiceListRenderer columnDef={leasingCompanyDef.name} {...props} />
        },
        {
            ...leasingCompanyDef.createdDate,
            cellRenderer: ({ cellData }) => <FormatDate date={cellData} />
        },
        status === 'LIVE' && {
            ...leasingCompanyDef.sendEmail,
            cellRenderer: ({ rowData }) => (
                <Button
                    disabled={!hasLeasingNotifications}
                    onClick={() => {
                        sendLeasingCompanyEmail(agreement.id, rowData.leasingCompanyId);
                    }}
                    title={!hasLeasingNotifications ? 'No matching notifications found' : undefined}
                >
                    Send
                </Button>
            )
        },
        {
            ...leasingCompanyDef.active,
            width: 90,
            noGrow: true,
            cellRenderer: props => <ToggleRenderer {...props} disabled={!isEditable} onToggle={onToggleActive} />
        }
    ];

    const tableData = leasingCompanies.map(m => ({ ...m, deleted: m.deleted !== 1 }));

    return (
        <div style={{ height: 400, width: '100%' }}>
            <LeasingCompanyModal />
            <BaseTable columns={columns} data={tableData} isLoading={isLoading} rowKey={leasingCompanyDef.id.field} />
        </div>
    );
}
