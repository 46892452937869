import { useState } from 'react';

const useToggleOpen = (initialIsOpen = false) => {
    const [isOpen, setIsOpen] = useState(initialIsOpen);
    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);
    return {
        toggle: () => setIsOpen(!isOpen),
        setIsOpen,
        isOpen,
        open,
        close
    };
};

export default useToggleOpen;
