import { toastr } from 'react-redux-toastr';
import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { makePostRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';
import { closeModal, FORM_PATHS } from '../../modals';

export const apiAddressEdit = addressForm => async (dispatch, getState) => {
	const type = ENDPOINTS.API.ADDRESS_POST;

	const address = { ...addressForm };

	if (!getState().offline.online) {
		return false;
	}

	isLoading(dispatch, type, true, {});

	try {
		const data = { ...address };
		const companyId = data.companyId;

		if (data.addressId < 0) {
			data.addressId = undefined;
		}

		let result = await makePostRequest(['address', 'update'], { companyId }, data);
		//dispatch({ type, updatedAddress: result.data, companyId });
		dispatch(closeModal(FORM_PATHS.EditAddress.modalName, result.data));
		isLoading(dispatch, type, false, {});
		return result || true;
	} catch (error) {
		isError(dispatch, type, true, error.message, {});
		toastr.error(type, `Response ${error.message}`);
		return false;
	}
};
