import { offlinePost } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

export const apiVisitActionPost = visitAction => async (dispatch, getState) =>
    dispatch(
        offlinePost(
            ENDPOINTS.API.VISIT_ACTION_POST,
            ENDPOINTS.API.VISIT_ACTION_POST_COMMIT,
            ENDPOINTS.API.VISIT_ACTION_POST_ROLLBACK,
            visitAction,
            getUnmodified(visitAction, getState),
            getPostData(visitAction, getState),
            visitAction.visitActionId,
            ['visitAction'],
            {}
        )
    );

const getUnmodified = (visitAction, getState) => {
    let unmodified = getState().visitActions.find(x => visitAction.visitActionId === x.visitActionId);
    if (!unmodified) {
        unmodified = { ...visitAction };
    }
    return unmodified;
};

const getPostData = (visitAction, getState) => {
    const data = { ...visitAction };
    if (data.visitActionId < 0) {
        data.visitActionId = null;
    }
    data.dealershipId = getState().user.dealershipId; //TODO:Should this be in the form
    data.assigneeId = parseInt(data.assigneeId);
    if (!data.endDate || data.endDate === '0001-01-01T00:00:00') {
        data.endDate = data.startDate;
    }
    return data;
};
