import React from 'react';
import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import { ColumnTypeFormatCompanyNameFromCompanyId } from '../common/Table/ColumnTypeFormatCompanyName';
import { useChoiceListForBaseTable } from '../common/Hooks/useChoiceList';
import { ChoiceListRenderer } from '../common/BaseTable/BaseTable';

function UserName({ userId }) {
    const users = useChoiceListForBaseTable('communicationAssignee');
    return users?.get(userId.toString()) || '';
}

function MasterTermVersionCellRenderer({ cellData, rowData }) {
    return (
        <div className="w-100">
            <div className="badge badge-light">{`${cellData?.reference} (${rowData.masterTermVersion?.status})`}</div>
            {rowData.nextMasterTermVersion && <div className="my-2">moving to</div>}
            <div className="badge badge-light">{rowData.nextMasterTermVersion?.reference}</div>
        </div>
    );
}

MasterTermVersionCellRenderer.propTypes = {};

const agreementDef = {
    id: idColumnDef('id', 'agreementId'),
    salesChannel: {
        ...choiceListColumnDef('Sales Channel', 'salesChannel', 'salesChannel'),
        type: 'text',
        searchable: true,
        filter: true,
        sortable: true,
        cellRenderer: props => (
            <ChoiceListRenderer
                columnDef={choiceListColumnDef('Sales Channel', 'salesChannel', 'salesChannel')}
                {...props}
            />
        ),
        help: {
            helpId: 'salesChannel',
            helpText: 'The sales channel of the agreement'
        }
    },
    addendum: {
        ...basicColumnDef('Addendum', 'addendum'),
        filter: true
    },
    agreementTemplate: {
        ...choiceListColumnDef('Agreement Template', 'agreementTemplate', 'agreementTemplate'),
        type: 'text',
        searchable: true
    },
    agreementClause: {
        ...basicColumnDef('Agreement Clauses', 'agreementClause'),
        filter: true
    },
    clauseComments: {
        ...basicColumnDef('Comments', 'clauseComments'),
        filter: true
    },
    createdBy: {
        ...choiceListColumnDef('Created By', 'createdBy', 'userAuthors'),
        cellRenderer: ({ rowData }) => (
            <div>
                <UserName userId={rowData.createdBy} />
            </div>
        ),
        filter: true
    },
    agreementType: {
        ...choiceListColumnDef('Agreement Type', 'agreementType', 'agreementType'),
        type: 'text',
        searchable: true,
        help: {
            helpId: 'agreementType',
            helpText: 'The agreement type'
        },
        filter: true,
        sortable: true,
        cellRenderer: props => (
            <ChoiceListRenderer
                columnDef={choiceListColumnDef('Agreement Type', 'agreementType', 'agreementType')}
                {...props}
            />
        )
    },
    businessType: {
        ...choiceListColumnDef('Business Type', 'businessType', 'businessType'),
        type: 'text',
        searchable: true
    },
    minimumHoldingPeriod: {
        ...choiceListColumnDef('Minimum Holding Period', 'minimumHoldingPeriod', 'minimumHoldingPeriod'),
        type: 'text',
        searchable: true
    },
    matrixBanding: {
        ...choiceListColumnDef('Matrix Banding', 'matrixBanding', 'matrixBanding'),
        type: 'text',
        searchable: true
    },
    reference: {
        ...basicColumnDef('Reference', 'reference'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'reference',
            helpText: 'The reference of the agreement'
        }
    },
    opportunitySummary: {
        ...basicColumnDef('Opportunity Summary', 'opportunitySummary'),
        type: 'text',
        sortable: true,
        filter: true
    },
    revision: {
        ...basicColumnDef('Revision', 'revision'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'agreementRevision',
            helpText: 'everytime an agreement is changed a new revision is created'
        }
    },
    contractVolume: {
        ...basicColumnDef('Contract Volume', 'contractVolume'),
        type: 'number',
        sortable: true,
        filter: true
    },
    termsOfBusiness: {
        ...basicColumnDef('Terms of Business', 'termsOfBusiness'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'termsOfBusiness',
            helpText: 'The terms of business of the agreement'
        }
    },
    companyName: {
        ...basicColumnDef('Company', 'companyName'),
        cellRenderer: props => <ColumnTypeFormatCompanyNameFromCompanyId companyId={props.rowData.companyId} />,
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'companyName',
            helpText: 'The company of the agreement'
        }
    },
    rebate: {
        ...basicColumnDef('UK Rebate', 'rebate'),
        choiceList: 'rebate', //Required for header form, do not delete
        help: {
            helpId: 'rebate',
            helpText: 'Allows rebate agreements to be included/excluded from the agreement search.'
        }
    },
    activeSupportBonus: {
        ...basicColumnDef('International Active Support Bonus', 'activeSupportBonus'),
        choiceList: 'activeSupportBonus', //Required for header form, do not delete
        help: {
            helpId: 'activeSupportBonus',
            helpText: 'Allows ASB agreements to be included/excluded from the agreement search.'
        }
    },
    masterTermVersionId: {
        ...basicColumnDef('Master Term Version', 'masterTermVersionId')
    },
    masterTermVersion: {
        ...basicColumnDef('Master Term Version', 'masterTermVersion'),
        cellRenderer: ({ cellData, rowData }) => <MasterTermVersionCellRenderer cellData={cellData} rowData={rowData} />
    },
    sendToCustomer: {
        ...basicColumnDef('Send To Customer', 'sendToCustomer'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'sendToCustomer',
            helpText: 'If emails should be sent to the customer'
        }
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'agreementHeaderStatus'),
        type: 'text',
        searchable: true,
        sortable: true,
        filter: true
    },
    startDate: {
        ...dateColumnDef('Start Date', 'startDate'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'startDate',
            helpText: 'The start date of the agreement'
        }
    },
    endDate: {
        ...dateColumnDef('End Date', 'endDate'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'endDate',
            helpText: 'The end date of the agreement'
        }
    },
    expiryDate: {
        ...dateColumnDef('Expiry Date', 'endDate')
    }
};

export default agreementDef;
