import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../../../common/AppModalHeader';
import TestEmailForm from '../Forms/TestEmailForm';
import { storeWithModal } from '../../../Provider/storeStates';
import create from 'zustand';

export const useTestEmailDropdownStore = create(set => ({
    ...storeWithModal(set),
    openModal: selected => {
        set(() => ({ selected, modalOpen: true }));
    }
}));

export default function TestEmailDropdownModal({ agreementId }) {
    const isOpen = useTestEmailDropdownStore(state => state.modalOpen);
    const close = useTestEmailDropdownStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Send Email Test</AppModalHeader>
                <ModalBody>
                    <TestEmailForm agreementId={agreementId} />
                </ModalBody>
            </Modal>
        </>
    );
}
