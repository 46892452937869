import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Alert } from 'reactstrap';
import MapArray from '../../common/MapArray';
import BrowserHistoryRow from './BrowserHistoryRow';
import { getBrowsingHistoryFromState } from '../../../selectors/Api/User/userSelector';
import DashboardAdminCard from '../../Agreement/Dashboard/DashboardAdminCard';

class BrowserHistory extends React.Component {
    render() {
        const { title, filteredHistory } = this.props;

        //const filteredHistoryCompanies = filteredHistory.filter(x => x.entity === 'Company');

        return (
            <DashboardAdminCard title={title ? title : 'Recently Visited'}>
                {filteredHistory.length > 0 ? (
                    <MapArray from={filteredHistory.slice(0, 5)}>
                        <BrowserHistoryRow />
                    </MapArray>
                ) : (
                    <Alert className="mb-0" color="warning">
                        There is no browsing history
                    </Alert>
                )}
            </DashboardAdminCard>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [getBrowsingHistoryFromState, (state, props) => props.urlsStartWith],
        (browsingHistory, urlsStartWith) => ({
            filteredHistory: filteredHistory(browsingHistory, urlsStartWith)
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(BrowserHistory);

const filteredHistory = (browsingHistory, urlsStartWith) => {
    return urlsStartWith ? browsingHistory.filter(x => x.url.indexOf(urlsStartWith) === 0) : browsingHistory;
};
