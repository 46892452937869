import React, { useEffect } from 'react';
import PageSection from '../../common/PageSection';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useEngageUsersStore } from '../../EngageUser/Provider/useEngageUsersStore';
import EngageUserTable, { useEngageUserTableColumns } from '../../EngageUser/Table/EngageUserTable';
import { useEngageRolesStore } from '../../EngageUser/Provider/useEngageRolesStore';
import { useEngageRolePermissionsStore } from '../../EngageUser/Provider/useEngageRolePermissionsStore';
import EngageRoles from '../../EngageUser/Components/EngageRoles';
import { TabsContent, TabsGroup, TabsInCard, TabsTabForCard } from '../../common/Tabs';
import { TableHelpText } from '../../common/BaseTable/BaseTableInCard';
import { useEngageAuthorizationLevelStore } from '../../EngageUser/Provider/useEngageAuthorizationLevelStore';
import EngageAuthorizationLevelTable from '../../EngageUser/Table/EngageAuthorizationLevelTable';
import {
    mapAndGroupUserVisibility,
    useAgreementsAdminUsersVisibilityStore
} from '../../EngageUser/Provider/AgreementsAdminUsersVisibilityStore';

const UserManagementEngage = () => {
    const { goBack } = useHistory();
    const dispatch = useDispatch();
    const userColumns = useEngageUserTableColumns();

    const setUserData = useEngageUsersStore(state => state.updateDataFromFetch);
    const setRolePermissionsData = useEngageRolePermissionsStore(state => state.updateDataFromFetch);
    const setAgreementsAdminUsersVisibilityData = useAgreementsAdminUsersVisibilityStore(
        state => state.updateDataFromFetch
    );
    const setRolesData = useEngageRolesStore(state => state.updateDataFromFetch);
    const setAuthLevelsData = useEngageAuthorizationLevelStore(state => state.updateDataFromFetch);

    useEffect(() => {
        let mounted = true;
        dispatch(apiPage('engage-users'))
            .then(response => {
                if (mounted) {
                    setAgreementsAdminUsersVisibilityData(
                        mapAndGroupUserVisibility(response.agreementsAdminUsersVisibility)
                    );
                    setUserData(response.users || []);
                    setRolesData(response.roles || [], true);
                    setRolePermissionsData(response.allRolePermissions || []);
                    setAuthLevelsData(response.agreementAuthorizationLevels || []);
                }
            })
            .catch(err => {
                // mounted && setData(data => ({ ...data, loading: false }));
                console.error(err);
            });
        return () => (mounted = false);
    }, [
        dispatch,
        setUserData,
        setRolePermissionsData,
        setRolesData,
        setAuthLevelsData,
        setAgreementsAdminUsersVisibilityData
    ]);

    return (
        <PageSection onBackClicked={goBack} title="User management Agreements Engine">
            <TabsInCard
                help={{
                    0: {
                        helpId: 'checkTableHeading',
                        helpText: <TableHelpText columns={userColumns} />
                    },
                    1: {
                        helpId: 'userRolePermissionsTable'
                    },
                    2: {
                        helpId: 'userAuthorisationTable',
                        helpText: <TableHelpText columns={EngageAuthorizationLevelTable.columns} />
                    }
                }}
                isLoading={false}
            >
                <TabsGroup>
                    <TabsTabForCard>Users</TabsTabForCard>
                    <TabsContent>
                        <EngageUserTable />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>Roles</TabsTabForCard>
                    <TabsContent>
                        <EngageRoles />
                    </TabsContent>
                </TabsGroup>

                <TabsGroup>
                    <TabsTabForCard>Auth Levels</TabsTabForCard>
                    <TabsContent>
                        <EngageAuthorizationLevelTable />
                    </TabsContent>
                </TabsGroup>
            </TabsInCard>
        </PageSection>
    );
};

export default UserManagementEngage;
