import { IEvent } from '../../../reducers/Api/Events';
import { Dispatch } from 'redux';
import { IAction, ENDPOINTS } from '../constants';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makePostRequest } from '../apiHelpers';

const type = ENDPOINTS.API.EVENT_POST;

export const apiEventPost = (event: IEvent) => async (dispatch: Dispatch<IAction>, getState: () => any) => {
	try {
		if (!getState().offline.online) {
			return false;
		}

		if (event.eventId && event.eventId < 0) {
			event.eventId = undefined;
		}

		const response: IEvent = (await makePostRequest(['event'], {}, event)).data;

		dispatch({
			type,
			data: response
		});

		return true;
	} catch (err) {
		isError(dispatch, type, true, err.message, {});

		if (getState().user.userId > -1) {
			toastr.error(type, `Response ${err.message}`);
		}

		return false;
	}
};
