import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import BaseTable, {
    ExpandIcon,
    CreatedByRenderer,
    formatAuditValue
} from '../../common/BaseTable/BaseTable';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useChoiceList, useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { useResources } from '../../../selectors/Api/useResources';
import { useAgreementAuditStore } from '../Provider/useAgreementAuditStore';
import { getStatusColor } from '../agreementPermissions';
import agreementAuditDef from '../agreementAuditDef';

export function useDownloadAuditCsv() {
    const audits = useAgreementAuditStore(state => state.data);
    const [data, setData] = useState([]);
    const csvRef = useRef();
    const users = useChoiceListForBaseTable('userAuthors');
    const resources = useResources();

    const formatAuditChange = ({ parentEntity, entity }, { col, change, from }) => {
        parentEntity.replace(/\s\s+/g, ' ');
        const [formatCol, formatChange] = formatAuditValue(resources, parentEntity, entity, col, change);
        const formatFrom = formatAuditValue(resources, parentEntity, entity, col, from)[1];
        return `${formatCol}: ${from ? formatFrom : '_'} to ${formatChange}`;
    };

    const getData = () => {
        const mapped = audits
            .sort((a, b) => {
                if (a.createdDate < b.createdDate) return 1;
                else if (a.createdDate > b.createdDate) return -1;

                return 0;
            })
            .map(audit => {
                const changes = audit.changes?.map(change => formatAuditChange(audit, change))?.join('\n') || '';
                const author = users.get(audit.createdBy?.toString());
                const createdDate = moment(audit.createdDate).format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`);
                return [
                    audit.parentEntity,
                    audit.entity,
                    audit.newStatus || audit.status,
                    changes,
                    audit.comments,
                    author || '',
                    createdDate
                ];
            });
        setData(mapped);
    };

    useEffect(() => {
        if (data.length > 0 && csvRef.current && csvRef.current.link) {
            setTimeout(() => {
                csvRef.current && csvRef.current.link.click();
                setData([]);
            });
        }
    }, [data]);

    return (id, entity) => ({
        id,
        onClick: getData,
        content: (
            <>
                Download CSV
                {data.length > 0 ? (
                    <CSVLink
                        data={data}
                        filename={`${entity}_audits.csv`}
                        headers={['Parent Entity', 'Entity', 'Status', 'Changes', 'Comments', 'Created By', 'Created Date']}
                        ref={csvRef}
                    />
                ) : null}
            </>
        ),
        icon: 'fa-arrow-down'
    });
}

export function AuditsTable({ statusChoiceList }) {
    const audits = useAgreementAuditStore(state => state.data);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const authorsList = useChoiceList('userAuthors');
    const statusChoiceMap = useChoiceListForBaseTable(statusChoiceList);

    const checksColumns = [
        agreementAuditDef.changes,
        agreementAuditDef.comments,
        {
            ...agreementAuditDef.createdBy,            
            cellRenderer: CreatedByRenderer,
            authorsList
        }
    ];

    //sort by createdData desc, group by change of status
    const tableData = audits
        .sort((a, b) => {
            if (a.createdDate < b.createdDate) return 1;
            else if (a.createdDate > b.createdDate) return -1;

            return 0;
        })
        .reduce((acc, f, i) => {
            f.newStatus = f.newStatus || f.status;

            if (i === 0) f.revision = 0;
            else if (acc[i - 1].newStatus === f.newStatus) f.revision = acc[i - 1].revision;
            else f.revision = acc[i - 1].revision + 1;

            f.grp = `${f.revision}_${f.newStatus}`;
            return [...acc, f];
        }, []);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <BaseTable
                className="agreement-audits"
                columns={checksColumns}
                data={tableData}
                expandAll={true}
                groupBy={['grp']}
                groupRenderer={({ rowData, expanded, setExpandedRow }) => (
                    <div className="py-2" style={{ display: 'inherit' }}>
                        <ExpandIcon {...rowData} expanded={expanded} setExpandedRow={setExpandedRow} />
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {statusChoiceMap.get(rowData.grp.split('_')[1])} ({rowData.groupCount})
                        </div>
                    </div>
                )}
                isLoading={isLoading}
                rowClassName={({ groupRow, grp }) => {
                    if (groupRow) {
                        const status = grp.split('_')[1];
                        const statusColour = getStatusColor(status);
                        return `tooltip-color-${statusColour}`;
                    }

                    return '';
                }}
                rowKey={agreementAuditDef.id.field}
            />
        </div>
    );
}

export default function AgreementAuditsTable() {
    return <AuditsTable statusChoiceList="agreementHeaderStatus" />;
}
