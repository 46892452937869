import { RESET_REDUCER } from '../../../actions/resetReducers';
import { API_LOGIN_ENDPOINTS } from '../../../actions/ApiAuth/ApiAuthEndpoints';

const initialState = [];

export const resetPassword = (state = { linkSent: false, sentTo: '' }, action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case API_LOGIN_ENDPOINTS.API.API_AUTH_PASSWORD_FORGOT:
            return { linkSent: true, sentTo: action.data.sentTo };
        default:
            return state;
    }
};
