import React from 'react';
import * as yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import { useEngageUsersStore, useResetPassword } from '../Provider/useEngageUsersStore';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;
const passwordMatchRegex = /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$/;
const passwordMatchMsg =
    'Passwords must contain at 3 of 4 of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (e.g. !@#$%^&*)';
const validationSchema = yup.object().shape({
    password: yup
        .string()
        .nullable()
        .required('Please enter a password')
        .min(6, 'Must be at least 6 characters long')
        .matches(passwordMatchRegex, passwordMatchMsg)
});

export default function ResetPasswordForm() {
    const resources = useResources();
    const user = useEngageUsersStore(state => state.selected);
    const cancel = useEngageUsersStore(state => state.closeModal);
    const saving = useEngageUsersStore(state => state.storeState) === storeStates.saving;
    const update = useResetPassword();

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={update}
            initialValues={user}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Password', 'password') }} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
