import React, { useContext, useReducer } from 'react';
import { DropdownItem, Modal, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import ReactiveForm from '../../common/ReactiveForm/index';
import { AppModalHeader } from '../../common/AppModalHeader';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';
import { useContractSignatories } from '../Provider/useContractSignatories';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useRevisionsStore } from '../Provider/useRevisionsStore';
import { useAgreementAuditStore } from '../Provider/useAgreementAuditStore';
import storeStates from '../Provider/storeStates';
import { useAgreementErrors } from './AgreementActions';
import { apiExistingDataUploadPost } from '../../../actions/Api/Agreement/apiAgreement';

const validationSchema = Yup.object()
    .shape({
        pdfFile: Yup.object().required('PDF file is required.')
    });

function ExistingDataUploadForm() {
    const dispatch = useDispatch();
    const agreement = useAgreementsStore(state => state.agreement);
    const saving = useRevisionsStore(state => state.storeState) === storeStates.saving;
    const cancel = useRevisionsStore(state => state.closeModal);
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateRevisionsFromFetch = useRevisionsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setSaving = useRevisionsStore(state => state.setSaving);
    const setError = useRevisionsStore(state => state.setError);
    const signatories = useContractSignatories();
    const { people } = useContext(CompanyPeopleContext);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const peopleOptions = otherPersonId => {
        return (people || [])
            .filter(f => f.contactRole?.includes('Signatory') || signatories.some(x => x.personId === f.personId))
            .filter(f => f.personId !== otherPersonId)
            .map(person => ({
                key: person.personId,
                value: person.descriptor
            }));
    };

    const onSave = (form) => {
        setSaving();
        dispatch(apiExistingDataUploadPost(agreement?.agreementId, { ...form, pdfPath: form.pdfFile?.contentAsBase64 }))
            .then(response => {
                updateAgreementsFromFetch(response.agreements || []);
                updateRevisionsFromFetch(response.agreementRevisions || []);
                updateAuditsFromFetch(response.agreementHeaderAudits || []);
            })
            .catch(err => setError(err));
    };

    const today = new Date();
    return (
        <ReactiveForm
            cancel={cancel}
            enableReinitialize={true}
            handleSubmit={onSave}
            initialValues={{
                ...agreement,
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: moment(new Date(today.getFullYear() + 1, 0, 1)).subtract(1, 'day'),
                firstApproverPersonId: agreement.contractSignatory1PersonId,
                secondApproverPersonId: agreement.contractSignatory2PersonId
            }}
            isSaving={saving}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Date
                    columnDef={basicColumnDef('Start Date', 'startDate')}
                    isClearable={true}
                />
                <ReactiveForm.Date
                    columnDef={basicColumnDef('End Date', 'endDate')}
                    isClearable={true}
                />
                <ReactiveForm.Other>
                    {formikProps => (
                        <ReactiveForm.Choicelist
                            columnDef={basicColumnDef('Signatory 1', 'firstApproverPersonId')}
                            onFormValueChanged={forceUpdate}
                            options={peopleOptions(formikProps.values.secondApproverPersonId)}
                            isClearable={true}
                            {...formikProps}
                        />
                    )}
                </ReactiveForm.Other>
                
                <ReactiveForm.Date
                    columnDef={basicColumnDef('Signatory 1 Signed Date', 'firstApprovalDate')}
                    isClearable={true}
                />
                <ReactiveForm.Other>
                    {formikProps => (
                        <ReactiveForm.Choicelist
                            columnDef={basicColumnDef('Signatory 2', 'secondApproverPersonId')}
                            onFormValueChanged={forceUpdate}
                            options={peopleOptions(formikProps.values.firstApproverPersonId)}
                            isClearable={true}
                            {...formikProps}
                        />
                    )}
                </ReactiveForm.Other>
                <ReactiveForm.Date
                    columnDef={basicColumnDef('Signatory 2 Signed Date', 'secondApprovalDate')}
                    isClearable={true}
                />
                <ReactiveForm.Dropzone
                    accept=".pdf"
                    columnDef={basicColumnDef('PDF File', 'pdfFile')}
                    multiple={false}
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}

function ExistingDataUploadModal() {
    const isOpen = useRevisionsStore(state => state.modalOpen);
    const close = useRevisionsStore(state => state.closeModal);
    return (
        <Modal className="modal-md" isOpen={isOpen} toggle={close}>
            <AppModalHeader toggle={close}>Existing Date Upload</AppModalHeader>
            <ModalBody>
                <ExistingDataUploadForm />
            </ModalBody>
        </Modal>
    );
}

export default function ExistingDataUpload({ className = 'button btn btn-secondary' }) {    
    const agreementStatus = useAgreementsStore(state => state.agreement?.status);
    const agreementErrors = useAgreementErrors();
    const openModal = useRevisionsStore(state => state.openModal);

    if (agreementStatus !== 'DRFT') return null;

    const disabled = agreementErrors.length > 0;
    return (
        <>
            <ExistingDataUploadModal />
            <DropdownItem
                className={`${!disabled ? 'cursor-pointer' : 'disabled'} ${className}`}
                disabled={disabled}
                onClick={openModal}
                style={{ pointerEvents: 'auto' }}
                title={agreementErrors[0]}
            >
                Existing Data Upload
            </DropdownItem>
        </>
    );
}