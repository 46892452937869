import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { closeModal, FORM_PATHS } from '../../../actions/modals';
import { AppModalHeader } from '../../common/AppModalHeader';
import EditCommunicationForm from '../Forms/EditCommunicationForm';

class EditCommunicationModal extends React.Component {
    render() {
        const { modals, setModalClosed } = this.props;
        const communicationId = modals.EditCommunicationModal.data.communicationId;
        const companyId = modals.EditCommunicationModal.data.companyId;
        return modals.EditCommunicationModal.isOpen ? (
            <Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
                <AppModalHeader toggle={() => setModalClosed()}>
                    {`${communicationId < 0 ? 'Create' : 'Edit'} Communication`}
                </AppModalHeader>
                <ModalBody>
                    <EditCommunicationForm
                        onCancel={() => setModalClosed()}
                        communicationId={communicationId}
                        companyId={companyId}
                        schema="communication"
                    />
                </ModalBody>
            </Modal>
        ) : (
            ''
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.EditCommunicationModal.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCommunicationModal);
