import React from 'react';
import { TabsContent, TabsGroup, TabsInCard, TabsTabForCard } from '../../common/Tabs';
import AgreementForm from '../Forms/AgreementForm';
import AgreementAuditsTable, { useDownloadAuditCsv } from '../Table/AgreementAuditsTable';
import LeasingCompaniesTable from '../Table/LeasingCompaniesTable';
import UpdateFleetProfileEditForm from '../../FleetProfile/Forms/UpdateFleetProfileEditForm';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import { useChoiceListValue } from '../../common/Hooks/useChoiceList';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useLeasingCompaniesStore } from '../Provider/useLeasingCompaniesStore';
import AgreementHeaderTemplateTable from '../../AgreementTemplates/Tables/AgreementHeaderTemplateTable';
import { useAgreementTemplateStore } from '../../AgreementTemplates/Provider/useAgreementTemplateStore';
import AgreementTemplateModal from '../../AgreementTemplates/Modals/AgreementTemplateModal';
import WarningPopover from '../../common/WarningPopover';
import { agreementPermissions, getPermission } from '../agreementPermissions';
import { ContractSignatories } from './ContractSignatories';
import { useContractSignatories } from '../Provider/useContractSignatories';
import { downloadRevisionCSV } from '../../../actions/Api/LeasingCompany/downloadRevisionCSV';

function LeasingTabTitle() {
    const leasingCompanies = useLeasingCompaniesStore(state => state.data);
    const agreementId = useAgreementsStore(state => state.agreement?.id);

    if (!agreementId) return 'Leasing Companies';

    const activeCount = leasingCompanies.filter(x => !x.deleted).length;

    return (
        <span>
            {activeCount === 0 ? (
                <WarningPopover bodyText="No Leasing Companies" className="mr-2" id="noLeasingCompanies" />
            ) : null}
            Leasing Companies
            {activeCount > 0 ? <span className="ml-2">({activeCount} active)</span> : null}
        </span>
    );
}

function TemplateTabTitle() {
    const templates = useAgreementTemplateStore(state => state.data);
    const agreementId = useAgreementsStore(state => state.agreement?.id);

    if (!agreementId) return 'Templates';

    const activeCount = templates.filter(x => x.active).length;

    return (
        <span>
            {activeCount === 0 ? <WarningPopover bodyText="No Templates" className="mr-2" id="noTemplates" /> : null}
            Templates
            {activeCount > 0 ? <span className="ml-2">({activeCount} active)</span> : null}
        </span>
    );
}

function SignatoriesTabTitle() {
    const signatories = useContractSignatories();
    const signatureCount = signatories.filter(x => x.personId !== null).length;

    return (
        <span>
            {signatureCount === 0 ? (
                <WarningPopover bodyText="No Signatories" className="mr-2" id="noSignatories" />
            ) : null}
            Signatories
            {signatureCount > 0 ? <span className="ml-2">({signatureCount})</span> : null}
        </span>
    );
}

export default function AgreementTabs() {
    const workflow = useAgreementsStore(state => state.agreement?.workflow);
    const agreementStatus = useAgreementsStore(state => state.agreement?.status);
    const agreementId = useAgreementsStore(state => state.agreement?.agreementId);
    const companyId = useAgreementsStore(state => state.agreement?.companyId);
    const statusName = useChoiceListValue('agreementHeaderStatus', agreementStatus);
    const openTemplatesModal = useAgreementTemplateStore(state => state.openModal);
    const openLeasingCompaniesModal = useLeasingCompaniesStore(state => state.openModal);
    const hasSignatories = getPermission(agreementPermissions.contractSignatories1, workflow).isVisible;
    const leasingCompaniesPermission = getPermission(agreementPermissions.leasingCompanies, workflow);
    const leasingCompaniesVisible = leasingCompaniesPermission.isVisible;
    const leasingCompaniesEnabled = leasingCompaniesPermission.isEnabled;
    const downloadAuditCsv = useDownloadAuditCsv();

    return (
        <>
            <AgreementTemplateModal />
            <TabsInCard
                buttons={{
                    1: [
                        {
                            id: 1,
                            onClick: () =>
                                openTemplatesModal(undefined, undefined, {
                                    entityId: agreementId,
                                    entity: 'Agreement'
                                }),
                            content: 'Add Template',
                            icon: 'fa-plus'
                        }
                    ],
                    3: [
                        {
                            id: 2,
                            onClick: () => {
                                downloadRevisionCSV(agreementId);
                            },
                            content: 'Download CSV',
                            icon: 'fa-file-download'
                        },
                        {
                            id: 3,
                            content: 'Add Leasing Company',
                            disabled: !leasingCompaniesEnabled,
                            icon: 'fa-plus',
                            onClick: openLeasingCompaniesModal
                        }
                    ],
                    5: [downloadAuditCsv(5, `agreement_${agreementId}`)]
                }}
                help={{
                    1: {
                        helpId: 'agreementsPageTemplateTab'
                    }
                }}
                isLoading={!agreementId}
            >
                <TabsGroup>
                    <TabsTabForCard>{`${statusName} Agreement`}</TabsTabForCard>
                    <TabsContent withPadding={true}>
                        <AgreementForm />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>
                        <TemplateTabTitle />
                    </TabsTabForCard>
                    <TabsContent>
                        <AgreementHeaderTemplateTable showFilesUsed={true} />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>Profile</TabsTabForCard>
                    <TabsContent>
                        <CompanyProvider companyId={companyId}>
                            <UpdateFleetProfileEditForm agreementStyle={true} />
                        </CompanyProvider>
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard hidden={!leasingCompaniesVisible}>
                        <LeasingTabTitle />
                    </TabsTabForCard>
                    <TabsContent>
                        <LeasingCompaniesTable />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard hidden={!hasSignatories}>
                        <SignatoriesTabTitle />
                    </TabsTabForCard>
                    <TabsContent>
                        <CompanyProvider companyId={companyId}>
                            <ContractSignatories />
                        </CompanyProvider>
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>Audit</TabsTabForCard>
                    <TabsContent>
                        <AgreementAuditsTable />
                    </TabsContent>
                </TabsGroup>
            </TabsInCard>
        </>
    );
}
