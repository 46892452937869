import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import React from 'react';
import { useWorkflowStore } from '../Provider/useWorkflowStore';
import WorkflowStatusChangeForm from '../Forms/WorkflowStatusChangeForm';

export default function WorkflowModal() {
    const isOpen = useWorkflowStore(state => state.modalOpen);
    const close = useWorkflowStore(state => state.closeModal);
    const data = useWorkflowStore(state => state.selected);
    const { label } = data || { label: '', source: '', target: '' };

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>{label}</AppModalHeader>
                <ModalBody>
                    <WorkflowStatusChangeForm {...data} />
                </ModalBody>
            </Modal>
        </>
    );
}
