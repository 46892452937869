import React, { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Alert, Modal, ModalBody } from 'reactstrap';
import queryString from 'query-string';
import { AppModalHeader } from '../../common/AppModalHeader';
import { FormatDate } from '../../common/FormatDate';
import AgreementAuditForm from '../Forms/AgreementAuditForm';
import { useAgreementAuditStore, useGroupedAuditComments } from '../Provider/useAgreementAuditStore';
import { useChoiceList, useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import agreementDef from '../agreementDef';

export function Comment({ comment: { createdBy, createdDate, changes, comments, hideUser }, users, user }) {
    const isOwnComment = user.userId === createdBy;

    return (
        <>
            {hideUser ? null : (
                <div className="d-flex w-100 mb-2" style={{ justifyContent: isOwnComment ? 'flex-start' : 'flex-end' }}>
                    <h5 className={`my-0 ${isOwnComment ? 'mr-2' : 'ml-2'}`} style={{ order: isOwnComment ? 0 : 1 }}>
                        {users.get(createdBy.toString())}
                    </h5>
                    <FormatDate className="align-self-end small" date={createdDate} />
                </div>
            )}
            <Alert color="info">
                <strong>{comments}</strong>
                {(changes || []).map(change => (
                    <div key={change.col}>
                        Changed {change.col} from {change.from ?? '_'} to {change.change}
                    </div>
                ))}
            </Alert>
        </>
    );
}

export function CommentsSummary({ commentsAry }) {
    const { changes, comments, createdBy, createdDate } = commentsAry[commentsAry.length - 1];
    const user = useChoiceList('userAuthors').find(x => x.key === createdBy.toString())?.value;

    return (
        <Alert className="mb-0" color="info" style={{ width: '300px', maxWidth: '300px' }}>
            <div className="d-flex w-100 mb-2">
                <h6 className="my-0 mr-2">{user}</h6>
                <FormatDate className="align-self-end small" date={createdDate} />
            </div>

            <strong>{comments}</strong>
            {(changes || []).map(change => (
                <div key={change.col}>
                    Changed {change.col} from {change.from ?? '_'} to {change.change}
                </div>
            ))}
            {commentsAry.length > 1 ? (
                <h6 className="underline mt-2" style={{ color: 'navy' }}>
                    + {commentsAry.length - 1} more
                </h6>
            ) : null}
        </Alert>
    );
}

export function Comments() {
    const selected = useAgreementAuditStore(state => state.selected);
    const user = useSelector(state => state.user);
    const users = useChoiceListForBaseTable('userAuthors');

    const scrollRef = useRef(null);
    useLayoutEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView();
        }
    }, []);
    const comments = useGroupedAuditComments(
        selected?.entity,
        selected?.entityId,
        selected?.secondaryEntity,
        selected?.secondaryEntityId
    );

    return (
        <div className="d-flex flex-column" style={{ height: '500px', maxHeight: '500px', overflow: 'auto' }}>
            {comments.map(comment => (
                <Comment comment={comment} key={comment.auditId} user={user} users={users} />
            ))}
            <div ref={scrollRef} />
        </div>
    );
}

function DebugInfoMasterTerm({ masterTermId }) {
    const {
        name,
        devCode,
        description,
        leasing,
        neu,
        neuDiscretionary,
        flmDiscretionary,
        totalDiscretionary,
        publicSector,
        specialist,
        rental
    } = useMasterTermStore(state => state.data.find(t => t.masterTermId === masterTermId));

    return (
        <pre>
            {JSON.stringify(
                {
                    name,
                    devCode,
                    description,
                    leasing,
                    neu,
                    neuDiscretionary,
                    flmDiscretionary,
                    totalDiscretionary,
                    publicSector,
                    specialist,
                    rental
                },
                null,
                2
            )}
        </pre>
    );
}

function DebugInfo() {
    const { search } = useLocation();
    const debug = search && queryString.parse(search)?.debug === 'true';
    const selected = useAgreementAuditStore(state => state.selected);

    if (!debug) return null;

    if (selected?.secondaryEntity !== 'MasterTerm') return null;

    return (
        <Alert color="info">
            <strong>Debug Master Terms.</strong>
            <p>Will not be in Live version of engage</p>
            <DebugInfoMasterTerm masterTermId={selected.secondaryEntityId} />
        </Alert>
    );
}

export default function AgreementAuditModal() {
    const agreement = useAgreementsStore(state => state.agreement);
    const isOpen = useAgreementAuditStore(state => state.modalOpen);
    const close = useAgreementAuditStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Comments</AppModalHeader>
                <DebugInfo />
                <ModalBody>
                    <Comments />
                    <AgreementAuditForm
                        parentEntity="Agreement"
                        parentEntityId={agreement?.[agreementDef.id.field]}
                        parentStatus={agreement?.[agreementDef.status.field]}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}
