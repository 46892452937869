import React from 'react';
import { toastr } from 'react-redux-toastr';
import { EditableInputRenderer } from '@returnoninvestment/base-table';
import ShowLoadingSpinner from '../../ShowLoadingSpinner';

export default function EditableNumber({ value, editable, onUpdate, dataKey, inputOptions, rowData, useTableStore }) {
    const newRowData = useTableStore?.(state => state.dataState.pageData[rowData.index]);

    return (
        <EditableInputRenderer
            editable={editable}
            dataKey={dataKey}
            inputOptions={inputOptions}
            loadingElement={<ShowLoadingSpinner isLoading={true} />}
            onError={msg => toastr.error('Update', msg)}
            onInputChangeMutate={currentValue => parseFloat(currentValue)}
            onUpdate={value => onUpdate(dataKey, value, newRowData)}
            type="number"
            value={value}
        />
    );
}
