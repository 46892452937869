import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';

export function affiliates(state = [], action) {
    switch (action.type) {
        case ENDPOINTS.API.PAGE:
            return action.data.affiliateFilter
                ? [
                      ...action.data.affiliates,
                      ...state.filter(
                          s =>
                              !action.data.affiliates.some(
                                  a =>
                                      a.affiliateId === s.affiliateId ||
                                      (action.data.affiliateFilter.companyId &&
                                          (action.data.affiliateFilter.companyId === s.companyId ||
                                              action.data.affiliateFilter.affiliatedCompanyId === s.companyId))
                              )
                      )
                  ]
                : state;
        case RESET_REDUCER:
            return [];

        default:
            return state;
    }
}
