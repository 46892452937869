import React from 'react';
import { useTestPdfTemplateStore } from '../Modal/TestPdfTemplateModal';
import ReactiveForm from '../../../../common/ReactiveForm';
import { FORM_NAMES } from '../../../../../actions/formNames';
import { basicColumnDef } from '../../../../common/FilteredTable/tableAndFilterHelpers';
import { useAgreementsStore } from '../../../Provider/useAgreementsStore';
import * as Yup from 'yup';
import downloadAgreementPdf from '../DropdownActions/downloadAgreementPdf';

const getValidationObject = (field, title) =>
    Yup.object().shape({
        [field]: Yup.array()
            .of(
                Yup.object().shape({
                    fileName: Yup.string().matches(
                        /^.*?<!|doc|docx|DOC|DOCX$/,
                        `Agreement ${title} File must be a word document (.doc .docx file extension types)`
                    )
                })
            )
            .required(`Agreement ${title} File is required.`)
    });

export const testPdfTemplateDef = {
    testTemplate: {
        ...basicColumnDef('Template', 'template'),
        validation: (propertyName, templateName) => getValidationObject(propertyName, templateName)
    },
    testAgreementClauses: {
        ...basicColumnDef('Clauses', 'clauses'),
        validation: (propertyName, templateName) => getValidationObject(propertyName, templateName)
    },
    testCoverLetter: {
        ...basicColumnDef('Cover Letter', 'coverLetter'),
        validation: (propertyName, templateName) => getValidationObject(propertyName, templateName)
    }
};

const validationSchema = templateName =>
    testPdfTemplateDef[templateName]?.validation(
        testPdfTemplateDef[templateName].field,
        testPdfTemplateDef[templateName].title
    );

const FORM_NAME = FORM_NAMES.TEST_PDF_TEMPLATE;

const TestPdfTemplateForm = ({ templateName }) => {
    const cancel = useTestPdfTemplateStore(state => state.closeModal);
    const agreement = useAgreementsStore(state => state.agreement);

    const save = ({ [testPdfTemplateDef[templateName]?.field]: file }) => {
        if (!file) return;

        const [{ contentAsBase64 }] = file;
        downloadAgreementPdf(undefined, agreement, undefined, undefined, { [templateName]: contentAsBase64 });
    };

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={save}
            initialValues={{}}
            saveButtonContent="Test"
            validationSchema={validationSchema(templateName)}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Dropzone columnDef={testPdfTemplateDef[templateName]} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

export default TestPdfTemplateForm;
