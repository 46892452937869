import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Bar } from 'react-chartjs-2';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import { makeGetRequest } from '../../../actions/Api/apiHelpers';
import DashboardAdminCard from '../Dashboard/DashboardAdminCard';
import { salesChannelColors } from './useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true
                }
            }
        ]
    }
};

function getKey(row, keyField) {
    return `${row[keyField]}_${row.salesChannel}`;
}

function useMappedData(data, keyField, brand, series, name, seriesData) {
    const salesChannels = useChoiceList('salesChannel');

    const useSeriesData = keyField === 'description';

    const aggregateData = (useSeriesData ? seriesData : data).reduce((acc, row) => {
        if (
            !useSeriesData &&
            ((brand && brand !== row.brand) || (series && series !== row.series) || (name && brand !== row.name))
        ) {
            return acc;
        }
        const key = getKey(row, keyField);
        if (!acc[key]) {
            acc[key] = {
                count: 0,
                total: 0
            };
        }
        acc[key].count += row.numberDiscounted;
        acc[key].total += row.totalDiscount;
        acc[key].average = (row.totalDiscount / row.numberDiscounted).toFixed(2);
        acc[key].label = row[keyField];
        acc[key].salesChannel = row.salesChannel;

        return acc;
    }, {});

    const labels = Array.from(new Set(Object.values(aggregateData).map(x => x.label))).sort((a, b) =>
        a < b ? -1 : a > b ? 1 : 0
    );

    return {
        labels,
        datasets: salesChannels.map(({ key, value }) => {
            return {
                label: value,
                key: key,
                data: labels.map(label => {
                    const rowKey = `${label}_${key}`;
                    return aggregateData[rowKey]?.average || 0;
                }),
                backgroundColor: salesChannelColors[key]
            };
        })
    };
}

const AverageLiveDiscount = ({ agreementsAdminStatusGroupedData, averageLiveDiscountsData }) => {
    const [state, setState] = useState({
        keyField: 'brand',
        brand: undefined,
        series: undefined,
        name: undefined,
        seriesData: [],
        previousStates: []
    });
    const gotoLiveTermsPage = useSafeDispatchPush(() => ['agreementliveterms']);

    const { keyField, brand, series, name, previousStates, seriesData } = state;

    //const openAgreementsSearch = useSafeDispatchPush(() => ['agreement']);
    const graphData = useMappedData(averageLiveDiscountsData, keyField, brand, series, name, seriesData);

    const getElementAtEvent = element => {
        if (!element.length) return;
        const { _index } = element[0];
        switch (keyField) {
            case 'brand':
                setState({
                    ...state,
                    seriesData: [],
                    keyField: 'series',
                    brand: graphData.labels[_index],
                    previousStates: [state, ...previousStates]
                });
                break;
            case 'series':
                //todo: fetch data for descriptions if get this far or bring back more data on initial load
                makeGetRequest(['live-terms', 'average-discount-for-series'], {
                    brand,
                    series: graphData.labels[_index]
                })
                    .then(response => {
                        setState({
                            ...state,
                            keyField: 'description',
                            series: graphData.labels[_index],
                            seriesData: response.data,
                            previousStates: [state, ...previousStates]
                        });
                    })
                    .catch(error => toastr.error('Failed to fetch data'));

                break;
            case 'description':
                gotoLiveTermsPage(undefined, { termSeries: series, termDescription: graphData.labels[_index] });
                break;
            default:
                break;
        }
    };

    return (
        <DashboardAdminCard helpId="agreementsAdminAgreementsCard" title="Average Live Discount">
            <div className="slight-glow relative" style={{ background: 'rgb(245 245 250)' }}>
                <Bar data={graphData} getElementAtEvent={getElementAtEvent} options={options} />
                <button
                    style={{
                        top: '2px',
                        right: '2px',
                        position: 'absolute',
                        visibility: previousStates.length ? 'visible' : 'hidden'
                    }}
                    onClick={() => {
                        const [last, ...prev] = previousStates;
                        setState({ ...last, seriesData: [], previousStates: prev });
                    }}
                >
                    <i className="fa fa-arrow-left" />
                </button>
            </div>
        </DashboardAdminCard>
    );
};

export default AverageLiveDiscount;
