import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { CompanyContext } from './CompanyProvider';
import { isOldOrDeletedAgreement } from '../../Agreement/Provider/AgreementProvider';

export const CompanyAgreementsContext = React.createContext({
    companyId: undefined,
    agreements: [],
    isLoading: false
});

const CompanyAgreementsProvider = ({ companyId, isLoading, agreements, children }) => {
    return (
        <CompanyAgreementsContext.Provider value={{ companyId, agreements, isLoading }}>
            {children}
        </CompanyAgreementsContext.Provider>
    );
};

export function useCreatableAgreements() {
    const { agreements } = useContext(CompanyAgreementsContext);
    const { company = {} } = useContext(CompanyContext);

    if (company.salesChannel === 'LeasingTactical' || company.salesChannel === 'PublicSector') {
        return [];
    }

    const neuOutrightPurchaseCount = agreements.filter(
        agreement =>
            agreement.salesChannel === 'NEU' &&
            agreement.agreementType === 'END_OUTPU' &&
            !isOldOrDeletedAgreement(agreement)
    ).length;

    const neuContractHireCount = agreements.filter(
        agreement =>
            agreement.salesChannel === 'NEU' &&
            agreement.agreementType === 'END_CONHI' &&
            !isOldOrDeletedAgreement(agreement)
    ).length;

    return company.salesChannel === 'NationalEndUser'
        ? [
            {
                text: 'Add CCS Agreement',
                disabled: neuContractHireCount > 0,
                agreementType: 'END_CONHI'
            },
            {
                text: 'Add DS Agreement',
                disabled: neuOutrightPurchaseCount > 0,
                agreementType: 'END_OUTPU'
            }
        ]
        : [
            {
                text: 'Add Agreement'
            }
        ];
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) =>
                state.agreements.filter(
                    agreement => agreement.companyId === companyId || agreement.affiliatedCompanyId === companyId
                )
        ],
        (isLoading, agreements) => ({
            isLoading,
            agreements
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyAgreementsProvider);
