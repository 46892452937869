import { basicColumnDef, choiceListColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

export const addendumProcessStatus = {
    0: 'Pending',
    1: 'Processed',
    2: 'Removed'
};

const addendumDef = {
    id: idColumnDef('id', 'addendumId'),
    salesChannel: {
        ...choiceListColumnDef('Sales Channel', 'salesChannel', 'salesChannel'),
        type: 'text',
        searchable: true,
        help: {
            helpId: 'salesChannel',
            helpText: 'The sales channel of the addendum'
        }
    },
    matrixBanding: {
        ...choiceListColumnDef('Matrix Banding', 'matrixBanding', 'matrixBanding'),
        type: 'text',
        searchable: true,
        help: {
            helpId: 'matrixBanding',
            helpText: 'The matrix banding(s) of the addendum'
        }
    },
    masterTermVersionId: {
        ...choiceListColumnDef('Terms Matrix Version', 'masterTermVersionId', ''),
        type: 'text',
        searchable: true,
        help: {
            helpId: 'masterTermVersionId',
            helpText: 'Agreements will be moved to this master terms version if they are not on it already'
        }
    },
    reason: {
        ...basicColumnDef('Reason', 'reason'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'reason',
            helpText: 'The Reason for the addendum'
        }
    },
    reasonCode: {
        ...choiceListColumnDef('Reason Code', 'reasonCode', 'addendumReasonCode'),
        emptyOption: 'Select the appropriate reason code...',
        sortable: true,
        filter: true,
        help: {
            helpId: 'status',
            helpText: 'The reason code relating to the addendum'
        }
    },
    activeSupportBonus: {
        ...basicColumnDef('Include/Exclude ASB', 'activeSupportBonus'),
        help: {
            helpId: 'activeSupportBonus',
            helpText: 'Allows ASB agreements to be included/excluded from the addendum.'
        }
    },
    rebate: {
        ...basicColumnDef('Include/Exclude Rebate', 'rebate'),
        help: {
            helpId: 'rebate',
            helpText: 'Allows rebate agreements to be included/excluded from the addendum.'
        }
    },
    name: {
        ...basicColumnDef('Name', 'name'),
        help: {
            helpId: 'name',
            helpText: 'Free text, give a friendly name to addendum.'
        }
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'addendumStatus'),
        type: 'text',
        searchable: true,
        help: {
            helpId: 'addendumStatus',
            helpText: 'The status of the addendum'
        }
    },
    standardTermsAndConditionsFile: {
        ...basicColumnDef('Addendum Clauses', 'standardTermsAndConditionsFile'),
        filter: true,
        help: {
            helpId: 'standardTermsAndConditionsFile',
            helpText: 'The file to be used for the terms and conditions'
        }
    },
    coverLetterFile: {
        ...basicColumnDef('Cover Letter', 'coverLetterFile'),
        filter: true,
        help: {
            helpId: 'coverLetterFile',
            helpText: 'The file to be used for the cover letter'
        }
    },
    comments: {
        ...basicColumnDef('Comments', 'comments'),
        filter: true
    },
    emailSubject: {
        ...basicColumnDef('Email Subject', 'emailSubject'),
        filter: true
    },
    emailBody: {
        ...basicColumnDef('Email Body', 'emailBody'),
        filter: true
    },
    addendumUpdatesStartAndEndDate: {
        ...basicColumnDef('Updates Start and End Date', 'addendumUpdatesStartAndEndDate'),
        filter: true
    },
    updatedAgreementStatus: {
        ...choiceListColumnDef('Updated Agreement Status', 'updatedAgreementStatus', 'agreementHeaderStatus'),
        filter: true
    },
    updatedAgreementStartDate: {
        ...basicColumnDef('Updated Start Date', 'updatedAgreementStartDate'),
        filter: true
    },
    updatedAgreementEndDate: {
        ...basicColumnDef('Updated End Date', 'updatedAgreementEndDate'),
        filter: true
    }
};

export default addendumDef;
