import React from 'react';
import { useHistory } from 'react-router';
import PageSection from '../../common/PageSection';
import AddendumHistoryBreadcrumb from './AddendumHistoryBreadcrumb';
import { ToolTipProvider } from '../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import AddendumTabs from './AddendumTabs';
import AssignAgreementsToAddendumTable from '../Tables/AssignAgreementsToAddendumTable';
import { useAddendaStore } from '../Provider/useAddendaStore';

export default function AddendumPageContent() {
    const { goBack } = useHistory();
    const salesChannel = useAddendaStore(state => state.addendum?.salesChannel);
    const name = useAddendaStore(state => state.addendum?.name);

    return (
        <ToolTipProvider>
            <PageSection
                onBackClicked={goBack}
                title={`${salesChannel} Addendum - ${name}`}
                titleContent={
                    <div style={{ gridColumn: '1 / -1', overflowX: 'auto' }}>
                        <AddendumHistoryBreadcrumb />
                    </div>
                }
            >
                <AddendumTabs />
                <AssignAgreementsToAddendumTable />
            </PageSection>
        </ToolTipProvider>
    );
}
