import React from 'react';

export default function Initials({ name }) {
    if (!name) {
        return null;
    }

    const initials = name
        .toUpperCase()
        .split(' ')
        .map(x => x.slice(0, 1))
        .join('.');

    return <span title={name}>{initials}.</span>;
}
