import React from 'react';
import { TotalRenderer } from '../common/BaseTable/CusomCellRenderers';
import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';
import { numberColWidth } from '../MasterTerm/Tables/MatrixTermsTable';
import agreementDef from '../Agreement/agreementDef';
import companyDef from '../Company/companyDef';
import masterTermDef from '../MasterTerm/masterTermDef';
import ivsCodeDef from '../IvsCode/ivsCodeDef';
import { ColumnTypeCompanyName } from '../common/Table/ColumnTypeFormatCompanyName';

export default {
    id: idColumnDef('Id', 'agreementLiveTermId'),
    agreementReference: agreementDef.reference,
    companyId: companyDef.id,
    companyName: {
        ...basicColumnDef('Company', 'companyName'),
        cellRenderer: props => {
            return <ColumnTypeCompanyName companyId={props.rowData.companyId} descriptor={props.rowData.companyName} />;
        },
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'companyName',
            helpText: 'The company of the agreement'
        }
    },
    brand: {
        ...masterTermDef.brand,
        width: 60,
        noGrow: true
    },
    series: {
        ...masterTermDef.series,
        width: 150,
        noGrow: true
    },
    masterTermDescription: masterTermDef.description,
    ivsCode: {
        ...ivsCodeDef.ivsCode,
        width: 110,
        noGrow: true
    },
    ivsCodeDescription: ivsCodeDef.modelDescription,
    totalDiscount: {
        field: 'totalDiscount',
        title: 'Total',
        sortable: true,
        noGrow: true,
        width: numberColWidth,
        cellRenderer: (props, rest) => <TotalRenderer {...props} {...rest} />
    },
    isRebate: {
        ...basicColumnDef('Rebate', 'isRebate'),
        choiceList: 'rebate',
        width: 110,
        noGrow: true,
        cellRenderer: ({ cellData, rowData }) => (cellData ? 'Yes' : 'No')
    },
    isActiveSupportBonus: {
        ...basicColumnDef('ASB', 'isActiveSupportBonus'),
        choiceList: 'activeSupportBonus',
        width: 110,
        noGrow: true,
        cellRenderer: ({ cellData, rowData }) => (cellData ? 'Yes' : 'No')
    }
};
