import React, { useState } from 'react';
import { Badge } from 'reactstrap';

function CodeSelector({
    title,
    subTitle,
    dataSet,
    noDataText,
    color,
    setDataSet,
    remove,
    newDataSet,
    fieldId,
    visibleKeys
}) {
    return (
        <div>
            <div style={{ fontWeight: 'semi-bold', fontSize: '14px' }}>{title}</div>
            <p style={{ fontSize: '9.6px', color: '#6c757d', marginBottom: '5px' }}>{subTitle}</p>
            <div
                style={{
                    alignContent: 'flex-start',
                    height: 'calc(100% - 40px)',
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '5px',
                    rowGap: '5px',
                    border: 'solid 1px #aaa',
                    padding: '10px',
                    borderRadius: '7px'
                }}
            >
                {dataSet?.length > 0
                    ? dataSet.map(x => {
                          return (
                              <Badge
                                  color={color}
                                  key={x[fieldId]}
                                  onClick={() => setDataSet(alterCode(x, remove, dataSet, newDataSet, fieldId))}
                                  pill
                                  role="button"
                                  style={{ fontWeight: '100' }}
                              >
                                  {visibleKeys.map(key => {
                                      return `${x[key]} `;
                                  })}
                              </Badge>
                          );
                      })
                    : `${noDataText}`}
            </div>
        </div>
    );
}

function alterCode(code, remove, dataSet, newDataSet, fieldId) {
    if (remove) {
        for (let i = 0; i < dataSet.length; i++) {
            if (dataSet[i][fieldId] === code[fieldId]) {
                dataSet.splice(i, 1);
            }
        }
    } else {
        dataSet = newDataSet.concat([code]);
    }

    return dataSet;
}

export function FormikSearchableSelector({ selection, options, setSelection, fieldId, visibleKeys }) {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <>
            <div style={{ width: '100%', marginBottom: '10px' }}>
                <div style={{ fontWeight: 'semi-bold', fontSize: '14px' }}>Search</div>
                <p style={{ fontSize: '9.6px', color: '#6c757d', marginBottom: '5px' }}>TYPE TO SEARCH IVS CODES</p>
                <div
                    style={{
                        width: '50%',
                        borderRadius: '5px',
                        border: 'solid 1px #aaa',
                        display: 'flex',
                        paddingLeft: '7px',
                        alignItems: 'center'
                    }}
                >
                    <i className="fa fa-search" />
                    <input
                        className="small-placeholder-input"
                        onChange={e => setSearchTerm(e.target.value)}
                        placeholder="Enter search terms..."
                        style={{ padding: '7px', border: 'none', outline: 'none', width: '100%', borderRadius: '5px' }}
                    />
                </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px' }}>
                <CodeSelector
                    color="success"
                    dataSet={
                        selection
                            ? selection.filter(x =>
                                  Object.values(x)
                                      .join(' ')
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                              )
                            : []
                    }
                    fieldId={fieldId}
                    noDataText="No codes assigned..."
                    remove
                    setDataSet={setSelection}
                    subTitle="SELECT TO REMOVE"
                    title="Current"
                    visibleKeys={visibleKeys}
                />
                <CodeSelector
                    color="danger"
                    dataSet={
                        options
                            ? options.filter(x =>
                                  Object.values(x)
                                      .join(' ')
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                              )
                            : []
                    }
                    fieldId={fieldId}
                    newDataSet={selection ? selection : []}
                    noDataText="No codes available..."
                    setDataSet={setSelection}
                    subTitle="SELECT TO ADD"
                    title="Available"
                    visibleKeys={visibleKeys}
                />
            </div>
        </>
    );
}
