import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PageSection from '../../common/PageSection';
import Layout3Columns, { Column1, Column2And3 } from '../../Dashboard/Components/Layout3Columns';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { useDownloadAuditCsv } from '../../Agreement/Table/AgreementAuditsTable';
import { ToolTipProvider } from '../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import RecentActivityTable from '../Tables/RecentActivityTable';
import RecentActivityFilter from './RecentActivityFilter';

export default function RecentActivityPageContent() {
    const { goBack } = useHistory();
    const { search } = useLocation();
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();
    const downloadCsv = useDownloadAuditCsv()(1, 'all');

    //fetch page
    useEffect(() => {
        const filter = queryString.parse(search);
        const {
            entity,
            createdBy
        } = filter;

        const filterClean = {
            entity: entity && entity !== '' ? entity.split(',') : undefined,
            createdBy: createdBy && createdBy !== '' ? createdBy.split(',') : undefined
        };

        dispatch(apiPage('recent-activity', undefined, filterClean))
            .then(response => {
                updateAuditsFromFetch(response.agreementHeaderAudits || [], true);
            })
            .catch(err => console.error('error', err));
    }, [updateAuditsFromFetch, search, dispatch]);

    return (
        <ToolTipProvider>
            <PageSection
                onBackClicked={goBack}
                title="Recent Activity"
                titleButtonArea={
                    <>
                        <Button color="info" onClick={downloadCsv.onClick}>
                            {downloadCsv.content}
                        </Button>
                    </>
                }
            >
                <Layout3Columns>
                    <Column1>
                        <RecentActivityFilter />
                    </Column1>
                    <Column2And3>
                        <RecentActivityTable />
                    </Column2And3>
                </Layout3Columns>
            </PageSection>
        </ToolTipProvider>
    );
}