import React, { useEffect, useState } from 'react';
import BaseTableInCard, { EditableNumber, EditableTextAreaRenderer } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import {
    useAnticipatedVolumesStore,
    useChangeFrequency,
    useIsValidAnticipatedVolume,
    useUpdateVolumes
} from '../Provider/useAnticipatedVolumesStore';
import { useChoiceListForSelect } from '../../common/Hooks/useChoiceList';
import { agreementPermissions, getPermission } from '../agreementPermissions';
import WarningPopover from '../../common/WarningPopover';
import { BaseTableInCardContentRight } from '../../common/BaseTable/BaseTableInCard';

export function FrequencySelect({ isLoading }) {
    const anticipatedVolumeFrequencyChoice = useAnticipatedVolumesStore(state => state.frequency);
    const onChangeFrequency = useChangeFrequency();
    const [option, selectOption] = useState(anticipatedVolumeFrequencyChoice);

    useEffect(() => {
        if (anticipatedVolumeFrequencyChoice !== option) {
            selectOption(anticipatedVolumeFrequencyChoice);
        }
    }, [option, anticipatedVolumeFrequencyChoice, selectOption]);

    const frequencies = useChoiceListForSelect('anticipatedVolumeFrequency');
    const options = frequencies.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {});

    return isLoading ? null : (
        <BaseTableInCardContentRight
            toggles={[
                {
                    id: 1,
                    option,
                    options,
                    selectOption: x => x !== option && onChangeFrequency(x)
                }
            ]}
        />
    );
}

const titles = {
    year: 'Year *',
    comments: 'Comments',
    q1: 'Quarter 1',
    q2: 'Quarter 2',
    q3: 'Quarter 3',
    q4: 'Quarter 4',
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
};

function TableTitle({ isLoading }) {
    const validAnticipatedVolume = useIsValidAnticipatedVolume();
    return (
        <>
            <WarningPopover
                bodyText="Please Complete the Anticipated Volume information"
                id="anticipatedVolumeError"
                visible={!isLoading && !validAnticipatedVolume}
            />
            Anticipated Volume
        </>
    );
}

export default function AnticipatedVolumeTableVertical() {
    const agreement = useAgreementsStore(state => state.agreement);
    const workflow = agreement?.workflow;
    const onUpdateStore = useAnticipatedVolumesStore(state => state.onUpdate);
    const anticipatedVolumeFrequencyChoice = useAnticipatedVolumesStore(state => state.frequency);
    const updateVolumes = useUpdateVolumes();
    const anticipatedVolumesVisible = getPermission(agreementPermissions.anticipatedVolumes, workflow).isVisible;
    const anticipatedVolumesEnabled = getPermission(agreementPermissions.anticipatedVolumes, workflow).isEnabled;
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const brands = ['BMW', 'BMWi', 'MINI'];

    const columns = [
        { field: 'title', title: '', sortable: false, filter: false },
        ...brands.map(brand => ({
            field: brand,
            title: brand,
            cellRenderer: ({ rowData, column }, { onUpdate }) =>
                rowData.row === 'comments' ? (
                    <EditableTextAreaRenderer
                        dataKey={{ row: rowData.row, column: column.key }}
                        editable={anticipatedVolumesEnabled}
                        onUpdate={onUpdate}
                        rowKey="type"
                        value={rowData[brand] || ''}
                    />
                ) : (
                    <EditableNumber
                        dataKey={{ row: rowData.row, column: column.key }}
                        editable={anticipatedVolumesEnabled}
                        onUpdate={onUpdate}
                        rowKey="type"
                        value={rowData[brand]}
                    />
                )
        }))
    ];

    if (!isLoading && !anticipatedVolumesVisible) return null;

    const onUpdate = (dataKey, value) => {
        return onUpdateStore(dataKey, value, agreement, updateVolumes);
    };

    return (
        <div className="d-grid" style={{ width: '100%', border: 0, padding: 0 }}>
            <BaseTableInCard
                cardTitle={<TableTitle />}
                columns={columns}
                groupRenderer={undefined}
                isLoading={isLoading}
                mapData={anticipatedVolumes => {
                    const rows =
                        anticipatedVolumeFrequencyChoice === 'Y'
                            ? ['year', 'comments']
                            : anticipatedVolumeFrequencyChoice === 'Q'
                            ? ['q1', 'q2', 'q3', 'q4', 'comments']
                            : [
                                  'jan',
                                  'feb',
                                  'mar',
                                  'apr',
                                  'may',
                                  'jun',
                                  'jul',
                                  'aug',
                                  'sep',
                                  'oct',
                                  'nov',
                                  'dec',
                                  'comments'
                              ];

                    const volumesInColumnOrder = brands
                        .map(brand => anticipatedVolumes.find(vol => vol.type === brand))
                        .filter(x => !!x);

                    return rows.map(row =>
                        volumesInColumnOrder.reduce(
                            (acc, brand) => {
                                acc[brand.type] = brand[row];
                                return acc;
                            },
                            { row, title: titles[row] }
                        )
                    );
                }}
                onUpdate={onUpdate}
                rowKey="row"
                tableSizeStyle={{ width: '100%', height: '250px' }}
                titleContentRight={<FrequencySelect isLoading={isLoading} />}
                useStore={useAnticipatedVolumesStore}
            />
        </div>
    );
}
