import { replace } from 'connected-react-router';
import { openModal } from '../modals';
import { isCookieLoggedIn } from '../../reducers/cookies';

export const redirectIfNotLoggedIn = actionIfLoggedIn => (dispatch, getState) => {
    const user = getState().user;

    if (!user) {
        dispatch(replace('/'));
        return false;
    }

    const tokenValid = isCookieLoggedIn();

    if (!tokenValid) {
        if (user.userName) {
            dispatch(openModal('ExpiredTokenModal', {}));
        } else {
            dispatch(replace('/'));
        }

        return false;
    }

    if (actionIfLoggedIn) {
        actionIfLoggedIn();
    }

    return true;
};
