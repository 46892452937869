import React, { useMemo, useState } from 'react';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import { FormikSearchableSelector } from '../../common/FormkControls/FormikSearchableSelector';
import { useUpdateTerms } from '../Provider/useMasterTermStore';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';
import { SaveCancelButton } from '../../common/SaveCancelButton';

export default function IvsCodesForm() {
    const updateTerms = useUpdateTerms();
    const rowData = useIvsCodesStore(state => state.selected) || {};
    const useTableStore = rowData?.brand === 'BMW' ? useBMWStore : useMiniStore;
    const updateRows = useTableStore(state => state.updateRows);
    const terms = useTableStore(state => state.dataCopy);
    const usedCodes = useMemo(() => {
        return new Set(terms.filter(f => f.masterTermId !== rowData.masterTermId && f.ivsCodes)
            .flatMap(m => m.ivsCodes)
            .map(m => m.ivsCode));
    }, [terms, rowData.masterTermId]);
    const close = useIvsCodesStore(state => state.closeModal);
    const ivsCodes = useIvsCodesStore(state => state.data);
    const [saving, setSaving] = useState(false);
    const [current, setCurrent] = useState(rowData.ivsCodes);
    const ivsCodesByDevCode = useMemo(() => {
        return ivsCodes?.filter(f => !usedCodes.has(f.ivsCode) && f.devCode === rowData.devCode
            && !current?.some(s => s.ivsCode === f.ivsCode));
    }, [ivsCodes, usedCodes, rowData.devCode, current]);

    function saveCodes() {
        setSaving(true);
        updateTerms([{ ...rowData, ivsCodes: current }])
            .then(response => {
                setSaving(false);
                updateRows(response);
                close();
            })
            .catch(() => setSaving(false));
    }

    return (
        <form>
            <div className="row mb-3">
                <div className="col-12">
                    <FormikSearchableSelector
                        fieldId="ivsCodeId"
                        options={ivsCodesByDevCode}
                        selection={current}
                        setSelection={setCurrent}
                        visibleKeys={['ivsCode', 'modelDescription']}
                    />                    
                </div>
            </div>
            <SaveCancelButton
                isSaving={saving}
                onCancel={close}
                onSave={saveCodes}
                pullRight={true}
            />
        </form>
    );
}
