import React from 'react';
import connect from 'react-redux/es/connect/connect';
import BaseTableInCard from '../../common/BaseTable/BaseTableInCard';
import addressDef from '../addressDef';
import { editAddress } from '../../../actions/Navigation/Address/editAddress';
import { createFakeId } from '../../../reducers/util';
import meeting from '../../Communication/meeting';

const AddressTableCard = ({
    rows,
    resources,
    columns = [
        { ...addressDef.isPrimary },
        { ...addressDef.address1, filter: true, sortable: true },
        { ...addressDef.address2, filter: true, sortable: true },
        { ...addressDef.address3, filter: true, sortable: true },
        { ...addressDef.address4, filter: true, sortable: true },
        { ...addressDef.city, filter: true, sortable: true },
        { ...addressDef.postCode, filter: true, sortable: true }
    ],
    isLoading,
    editRow,
    companyId
}) => {
    return (
        <BaseTableInCard
            buttons={[
                {
                    content: 'Add Address',
                    icon: 'fa-plus',
                    onClick: () => editRow({ addressId: createFakeId() }, companyId)
                }
            ]}
            cardTitle={`Addresses (${rows.length})`}
            columns={columns}
            data={rows}
            isLoading={isLoading}
            noItemMessage="No Contacts"
            onRowClicked={({ rowData }) => editRow(rowData)}
            rowKey={meeting.id.field}
            tableSizeStyle={{ height: 'calc(100vh - 200px)', width: '100%' }}
        />
    );
};

const mapStateToProps = state => ({
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    editRow: (address, companyId) => dispatch(editAddress(address, companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressTableCard);
