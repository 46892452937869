import create from 'zustand';
import { useDispatch } from 'react-redux';
import { apiAgreementNotificationPeoplePost } from '../../../actions/Api/Agreement/apiAgreement';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import { agreementNotificationPeopleDef } from '../agreementNotificationDef';

const idField = agreementNotificationPeopleDef.id.field;
export const useAgreementNotificationPeopleStore = create(set => storeWithModal(set, idField));

export function useUpdateNotificationPeople() {
    const updateNotificationsFromFetch = useAgreementNotificationPeopleStore(state => state.updateDataFromFetch);
    const setError = useAgreementNotificationPeopleStore(state => state.setError);
    const dispatch = useDispatch();

    return notifications => {
        return dispatch(apiAgreementNotificationPeoplePost(notifications))
            .then(response => {
                updateNotificationsFromFetch(response, false, notifications);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
