import { ICalendarEvent } from '../../../containers/Calendar/Components/CalendarEvent';
import moment from 'moment';
import {IMeeting} from "./filters";

export const meetingToCalendarEvent = (meeting: IMeeting): ICalendarEvent => ({
	id: `meeting-${meeting.meetingId}`,
	title: meeting.subject,
	meeting,
	icon: meeting.meetingMode === 'Telephone' ? 'fa-phone' : 'fa-handshake-o',
	color: meeting.meetingMode === 'Telephone' ? 7 : 3,
	start: moment(meeting.startDate).toDate(),
	end: moment(meeting.endDate).toDate()
});
