import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import ReactFlow from 'react-flow-renderer';
import {
    useAgreementNotificationPeopleStore,
    useAgreementNotificationStore
} from '../../AgreementNotifications/Provider';
import { Button } from 'reactstrap';
import { StatusChangeCustomNodeComponent } from '../../Workflow/Components/Nodes/StatusChangeCustomNodeComponent';
import { NotificationNodeComponent } from '../../Workflow/Components/Nodes/NotificationNodeComponent';
import { StatusCustomNodeComponent } from '../../Workflow/Components/Nodes/StatusCustomNodeComponent';
import { getWorkflowElements } from '../../Workflow';
import { useWorkflowStore } from '../../Workflow/Provider/useWorkflowStore';
import _ from 'lodash';

function usePageData(setData) {
    const dispatch = useDispatch();
    const updateNotificationsFromFetch = useAgreementNotificationStore(state => state.updateDataFromFetch);
    const updateNotificationPeopleFromFetch = useAgreementNotificationPeopleStore(state => state.updateDataFromFetch);

    useEffect(() => {
        dispatch(apiPage('agreement-workflow'))
            .then(response => {
                const workFlowRulesRecords = response?.workFlowRulesRecords || [];

                setData({
                    notificationList: response?.agreementNotifications || [],
                    statusList: workFlowRulesRecords.filter(
                        workflowRuleRecord => workflowRuleRecord.choiceListName === 'agreementHeaderStatus'
                    ),
                    workFlowButtons: workFlowRulesRecords.filter(workflowRuleRecord =>
                        workflowRuleRecord.choiceListName.includes('agreementWorkflowButton')
                    ),
                    workFlowRules: _.get(response, 'workFlowRules', [])
                });
            })
            .catch(err => console.error('error', err));
    }, [dispatch, updateNotificationsFromFetch, updateNotificationPeopleFromFetch, setData]);
}

function useWorkflowPageButtons() {
    const [data, setData] = useState({ showNotifications: false });

    return {
        ...data,
        buttons: (
            <Button color="info" onClick={() => setData({ ...data, showNotifications: !data.showNotifications })}>
                <i className="fa fa-pencil mr-2" />
                {data.showNotifications ? 'Hide Notifications' : 'Show Notifications'}
            </Button>
        )
    };
}

const changeEdgeStyling = (node, edges, elements, setElements) => {
    const sources = edges.filter(edge => edge.source.includes(node.id));
    const targets = edges.filter(edge => node.id.includes(edge.target));

    const highlightedSources = (node.type === 'status' ? sources : targets).reduce((accumulator, currentValue) => {
        if (currentValue.hasOwnProperty('highlight')) {
            currentValue.style = currentValue.oldStyle;
            delete currentValue.highlight;
            delete currentValue.oldStyle;
        } else {
            currentValue.oldStyle = currentValue.style;
            currentValue.style = { strokeWidth: '3px', stroke: '#FFA500' };
            currentValue.highlight = true;
        }

        accumulator.push(currentValue);

        return accumulator;
    }, []);

    setElements([
        ...elements.filter(element => !_.some(highlightedSources, val => _.includes(element.id, val.id))),
        ...highlightedSources
    ]);
};

function Page({ notifications, statusChanges, edges, statuses }) {
    const { buttons, showNotifications } = useWorkflowPageButtons();
    const openModal = useWorkflowStore(state => state.openModal);
    const { goBack } = useHistory();

    const [elements, setElements] = useState([]);

    const nodeTypes = {
        statusChange: StatusChangeCustomNodeComponent,
        notification: NotificationNodeComponent,
        status: StatusCustomNodeComponent
    };

    //TODO replace showNotifications with true value to test notification edges
    useEffect(() => {
        setElements([
            ...statuses,
            ...statusChanges,
            ...(showNotifications ? notifications : []),
            ...(showNotifications ? edges : edges.filter(edge => !edge.id.endsWith('-notification')))
        ]);
    }, [statuses, statusChanges, showNotifications, notifications, edges]);

    return (
        <PageSection
            onBackClicked={goBack}
            title="Agreements Workflow"
            titleButtonArea={buttons}
            titleExtra={<PageLoadingSpinner />}
        >
            <div className="row no-gutters" style={{ height: 'calc(100vh - 145px)' }}>
                <ReactFlow
                    elements={elements}
                    nodeTypes={nodeTypes}
                    onNodeDoubleClick={(_event, { data }) => openModal(data)}
                    onNodeMouseEnter={(_event, node) => changeEdgeStyling(node, edges, elements, setElements)}
                    onNodeMouseLeave={(_event, node) => changeEdgeStyling(node, edges, elements, setElements)}
                />
            </div>
        </PageSection>
    );
}

export default function AgreementAdminWorkflowPageContent() {
    const [data, setData] = useState({ statusList: [], workFlowRules: [], workFlowButtons: [], notificationList: [] });

    usePageData(setData);

    const [statusNodes, statusChangeNodes, edges, notificationNodes] = getWorkflowElements(data);

    return (
        <Page
            edges={edges}
            notifications={notificationNodes}
            statusChanges={statusChangeNodes}
            statuses={statusNodes}
        />
    );
}
