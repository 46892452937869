import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import BaseTable, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTable';
import { useGoToAgreementPage } from './CompanyAgreementsTableCard';
import { CompanyAgreementsContext } from '../../Company/Provider/CompanyAgreementsProvider';
import agreementDef from '../agreementDef';
import downloadAgreementPdf from '../Components/AgreementTestsDropdown/DropdownActions/downloadAgreementPdf';

const CompanyAgreementsTable = () => {
    const { agreements, isLoading } = useContext(CompanyAgreementsContext);
    const goToPage = useGoToAgreementPage();
    const columns = [
        { ...agreementDef.startDate, width: 100 },
        { ...agreementDef.endDate, width: 150 },
        useChoicelistCellDefinition(agreementDef.salesChannel, { width: 150 }),
        { ...agreementDef.reference, width: 150 },
        useChoicelistCellDefinition(agreementDef.status, { width: 150 }),
        {
            field: 'download',
            title: 'Download PDF',
            cellRenderer: ({ rowData: values }) => (
                <>
                    {values[agreementDef.revision.field] && (
                        <Button color="info" onClick={e => downloadAgreementPdf(e, values, false, () => {})}>
                            Download PDF
                        </Button>
                    )}
                </>
            )
        }
    ];
    return (
        <div style={{ height: 'calc(100vh /2)', width: '100%' }}>
            <BaseTable
                columns={columns}
                data={agreements}
                isLoading={isLoading}
                onRowClicked={({ rowData }) => !rowData.groupRow && goToPage(rowData)}
                rowClassName={rowData => !rowData.termsVisible && 'bg-lightgrey cursor-not-allowed'}
                rowKey={agreementDef.id.field}
            />
        </div>
    );
};

export default CompanyAgreementsTable;
