import { makePostRequest } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const downloadRevisionCSV = agreementHeaderId => {
    makePostRequest(['agreement', 'download-revision-csv', agreementHeaderId], undefined, true)
        .then(d => {
            const blob = new Blob([d.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `Agreement ${agreementHeaderId} Revision.csv`;
            document.body.appendChild(a);
            a.click();
        })
        .catch(error => toastr.error('Download Revision CSV', `Response ${error.message}`));
};
