import React from 'react';
import BaseTableInCard, {
    ChoiceListRenderer,
    FileRenderer,
    ToggleRenderer,
    types
} from '../../common/BaseTable/BaseTableInCard';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { FormatDate } from '../../common/FormatDate';
import masterTermVersionDef from '../masterTermVersionDef';
import { useMasterTermVersionsStore } from '../Provider/useMasterTermVersionsStore';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';

function useColumns() {
    const statusList = useChoiceListForBaseTable('masterTermVersionStatus');
    const typeOfChangeList = useChoiceListForBaseTable('masterTermVersionTypeOfChange');

    return [
        { ...masterTermVersionDef.id },
        {
            ...masterTermVersionDef.status,
            type: types.SELECT,
            select: statusList,
            cellRenderer: props => <ChoiceListRenderer columnDef={masterTermVersionDef.status} {...props} />
        },
        { ...masterTermVersionDef.reference },
        { ...masterTermVersionDef.comments },
        {
            ...masterTermVersionDef.typeOfChange,
            type: types.SELECT,
            select: typeOfChangeList,
            cellRenderer: props => <ChoiceListRenderer columnDef={masterTermVersionDef.typeOfChange} {...props} />
        },
        {
            ...masterTermVersionDef.customersEmailBody,
            cellRenderer: ({ cellData }) => <div dangerouslySetInnerHTML={{ __html: cellData }} />
        },
        {
            ...masterTermVersionDef.bmwApproved,
            cellRenderer: props => <ToggleRenderer {...props} disabled={true} />
        },
        {
            ...masterTermVersionDef.bmwApprovalEvidence,
            cellRenderer: FileRenderer
        },
        {
            ...masterTermVersionDef.coverLetterFile,
            cellRenderer: FileRenderer
        },
        {
            ...masterTermVersionDef.activeFrom,
            cellRenderer: ({ cellData }) => <FormatDate date={cellData} />
        },
        {
            ...masterTermVersionDef.activeTo,
            cellRenderer: ({ cellData }) => <FormatDate date={cellData} />
        }
    ];
}

export default function MatrixVersionTable() {
    const versions = useMasterTermVersionsStore(state => state.data);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const goToTermsPage = useSafeDispatchPush(version => ['matrix', version.masterTermVersionId]);
    const columns = useColumns();

    return (
        <BaseTableInCard
            cardTitle="Terms Matrices"
            columns={columns}
            data={versions}
            helpId="termsMatricesTableHeading"
            isLoading={isLoading}
            onRowClicked={({ rowData }) => goToTermsPage(rowData)}
            rowKey="masterTermVersionId"
            tableSizeStyle={{ height: 'calc(100vh - 197px)', width: '100%' }}
        />
    );
}
