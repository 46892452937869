import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import IvsCodesForm from '../Forms/IvsCodesForm';

export const IvsModalProvider = () => {
    const isOpen = useIvsCodesStore(state => state.modalOpen);
    const rowData = useIvsCodesStore(state => state.selected);
    const close = useIvsCodesStore(state => state.closeModal);

    return (
        <div>
            {rowData && (
                <Modal isOpen={isOpen} toggle={close} className="modal-xl">
                    <AppModalHeader toggle={close}>{rowData.description || 'No Description'}</AppModalHeader>
                    <ModalBody>
                        <IvsCodesForm />
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};
