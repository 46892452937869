export const filterNext7DaysCommunications = (communications, userId) => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    const end = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 8);

    return communications.filter(
        x => new Date(x.startDate) >= start && new Date(x.startDate) <= end && x.assignee && x.assigneeId === userId
    );
};

export const filterCommunicationsAssignedTo = (communications, userId) => {
    return communications.filter(x => x.assignee && x.assigneeId === userId);
};
