import React from 'react';
import AgreementAdminWorkflowPageContent from '../../Agreement/Components/AgreementAdminWorkflowPageContent';
import AgreementNotificationModal from '../../AgreementNotifications/Modals/AgreementNotificationModal';
import WorkflowModal from '../../Workflow/Components/WorkflowModal';

export default function AgreementAdminWorkflowPage() {
    return (
        <>
            <WorkflowModal />
            <AgreementNotificationModal />
            <AgreementAdminWorkflowPageContent />
        </>
    );
}
