import React, { useEffect, useState } from 'react';
import PageSection from '../../common/PageSection';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import AgreementsDashboardCard from '../../Agreement/Dashboard/AgreementsDashboardCard';
import AddendumDashboardCard from '../../Agreement/Dashboard/AddendumDashboardCard';
import TermsMatrixDashboardCard from '../../Agreement/Dashboard/TermsMatrixDashboardCard';
import CompanyCountDashboardCard from '../../Agreement/Dashboard/CompanyCountDashboardCard';
import BrowserHistory from '../../BrowserHistory/Tables/BrowserHistory';
import useDimensions from '../../common/Hooks/useDimensions';
import AgreementsAdminExternalDataLinks from '../../Agreements/Components/AgreementsAdminExternalDataLinks';
import AgreementsAdminSettingsLinks from '../../Agreements/Components/AgreementsAdminSettingsLinks';
import { permissions, useUsersPermission } from '../../../reducers/Api/User/permissions';
import AgreementsAdminReportingLinks from '../../Agreements/Components/AgreementsAdminReportingLinks';

export default function AgreementsAdminPage() {
    const [data, setData] = useState({
        counts: [],
        usersVisibleToUser: [],
        loading: true
    });
    const dispatch = useDispatch();
    const { goBack } = useHistory();

    const authorized = useUsersPermission(permissions.AGREENENTS_ADMIN_PAGE).canRead;

    useEffect(() => {
        let mounted = true;
        authorized &&
            dispatch(apiPage('agreements-admin'))
                .then(response => {
                    mounted &&
                        setData(data => ({
                            loading: false,
                            counts: response.agreementAdminCounts,
                            usersVisibleToUser: response.usersVisibleToUser
                        }));
                })
                .catch(err => {
                    mounted && setData(data => ({ ...data, loading: false }));
                    console.error(err);
                });
        return () => (mounted = false);
    }, [dispatch, authorized]);

    const [ref, { y }] = useDimensions({ liveMeasure: false });

    if (data.loading || !authorized) {
        return <></>;
    }

    const countData = data.counts || [];

    return (
        <PageSection onBackClicked={goBack}>
            <div className="d-grid page-grid">
                <div style={{ gridColumn: '1 / 3' }}>
                    <div ref={ref} style={{ height: `calc(100vh - ${y}px - 1rem` }}>
                        <div className="d-grid h-100" style={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem' }}>
                            <AgreementsDashboardCard data={countData} usersVisibleToUser={data.usersVisibleToUser} />
                            <CompanyCountDashboardCard data={countData} />
                            <AddendumDashboardCard data={countData} />
                            <TermsMatrixDashboardCard data={countData} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="d-grid" style={{ gap: '1rem' }}>
                        <BrowserHistory urlsStartWith="/agreeme" />
                        <AgreementsAdminSettingsLinks />
                        <AgreementsAdminExternalDataLinks />
                        <AgreementsAdminReportingLinks />
                    </div>
                </div>
            </div>
        </PageSection>
    );
}
