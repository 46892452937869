import React from 'react';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import { useAgreementNotificationStore, useUpdateNotifications } from '../Provider';
import agreementNotificationDef from '../agreementNotificationDef';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    agreementNotificationName: Yup.string().required('Name is required.'),
    agreementStatus: Yup.string().required('Agreement Status is required.'),
    emailSubject: Yup.string()
        .nullable()
        .required('Email Subject is required'),
    emailBody: Yup.string()
        .nullable()
        .required('Email body is required'),
    emailCC: Yup.string()
        .nullable()
        .test({
            test: val => {
                if (!val || val === '') return true;

                const emails = val.split(',').map(x => x.trim());
                const emailValidator = Yup.string()
                    .required()
                    .email();

                return Promise.all(emails.map(e => emailValidator.isValid(e))).then(arr => arr.every(x => x));
            },
            message: 'CC addresses must be one or more valid email addresses, separated by commas, or empty.'
        })
});

export default function AgreementNotificationForm() {
    const cancel = useAgreementNotificationStore(state => state.closeModal);
    const selected = useAgreementNotificationStore(state => state.selected);
    const saving = useAgreementNotificationStore(state => state.storeState) === storeStates.saving;
    const setSaving = useAgreementNotificationStore(state => state.setSaving);
    const resources = useResources();
    const updateNotifications = useUpdateNotifications();

    const save = notification => {
        setSaving();
        updateNotifications([
            {
                ...notification
            }
        ]);
    };

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={save}
            initialValues={{
                ...selected,
                active: selected?.active !== undefined ? selected.active : true
            }}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={agreementNotificationDef.agreementNotificationName} />
                <ReactiveForm.Choicelist columnDef={agreementNotificationDef.statusFrom} isMulti={true} />
                <ReactiveForm.Choicelist columnDef={agreementNotificationDef.agreementStatus} />
                <ReactiveForm.Choicelist columnDef={agreementNotificationDef.salesChannels} isMulti={true} />
                <ReactiveForm.Choicelist columnDef={agreementNotificationDef.agreementType} isMulti={true} />
                <ReactiveForm.Choicelist columnDef={agreementNotificationDef.sender} />
                <ReactiveForm.Text columnDef={agreementNotificationDef.emailCC} />
                <ReactiveForm.Text columnDef={agreementNotificationDef.emailSubject} />
                <ReactiveForm.HtmlEditor columnDef={agreementNotificationDef.emailBody} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.isMassAddendumEmail} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.active} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.sendToOwner} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.sendToCustomer} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.sendToLeasingCompany} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.attachToEmail} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.attachLeasingCsv} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.appendCompanyToMessage} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.appendAgreementToMessage} />
                <ReactiveForm.Checkbox columnDef={agreementNotificationDef.appendLinkToEngage} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
