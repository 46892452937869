import React from 'react';
import { Link } from 'react-router-dom';

function MetricValueContent({ value, units, fontSize, fontSize2, styleType, title, total }) {
    return (
        <div className={`${styleType === 'none' ? 'text-white-20' : 'text-white'}`} style={{ fontSize }}>
            <h3 className={`text-center fancy-serif my-0`} style={{ lineHeight: fontSize, fontSize }}>
                {value}
                {total ? (
                    <span className="text-white-80" style={{ fontSize: '0.4em', lineHeight: '0.4em' }}>
                        /{total}
                    </span>
                ) : null}
            </h3>
            <div className="text-center" style={{ fontSize: fontSize2 }}>
                {title}
            </div>
        </div>
    );
}

export default function DashboardMetricValue({
    value,
    href,
    units,
    last,
    fontSize,
    fontSize2,
    styleType,
    title,
    column,
    total
}) {
    const lastStyle = last ? { gridColumnStart: column + 1, gridColumnEnd: -1 } : {};

    const classForStyleType =
        styleType === 'info'
            ? 'bg-info'
            : styleType === 'warning'
            ? 'bg-warning'
            : styleType === 'positive'
            ? 'bg-success'
            : 'bg-darkgrey';
    const classForHref = href ? 'background-hover text-decoration-none' : '';
    //
    if (href) {
        return (
            <Link
                className={`d-grid ${classForStyleType} ${classForHref} p-2 slight-glow`}
                style={{ justifyContent: 'center', alignItems: 'center', ...lastStyle, position: 'relative' }}
                to={href}
            >
                <MetricValueContent
                    fontSize={fontSize}
                    fontSize2={fontSize2}
                    styleType={styleType}
                    title={title}
                    total={total}
                    units={units}
                    value={value}
                />
            </Link>
        );
    }
    return (
        <div
            className={`d-grid ${classForStyleType} ${classForHref} p-2 slight-glow`}
            style={{ justifyContent: 'center', alignItems: 'center', ...lastStyle, position: 'relative' }}
        >
            <MetricValueContent
                fontSize={fontSize}
                fontSize2={fontSize2}
                styleType={styleType}
                title={title}
                units={units}
                value={value}
                total={total}
            />
        </div>
    );
}
