import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';
import CompanyCommunicationsProvider from './CompanyCommunicationsProvider';
import CompanyDemosProvider from './CompanyDemosProvider';
import CompanyPeopleProvider from './CompanyPeopleProvider';
import CompanyVisitActionsProvider from './CompanyVisitActionsProvider';
import CompanyMeetingsProvider from './CompanyMeetingsProvider';
import CompanyAgreementsProvider from './CompanyAgreementsProvider';

export const CompanyContext = React.createContext({
    company: { name: '', descriptor: '', profile: {}, leasingProfile: {}, addresses: [] },
    resources: {},
    isLoading: false
});

export const CompanyConsumer = CompanyContext.Consumer;

const CompanyProvider = ({ loadingStates, companyId, profile, leasingProfile, resources, company, isLoading, children }) => (
    <CompanyCommunicationsProvider companyId={companyId}>
        <CompanyMeetingsProvider companyId={companyId}>
            <CompanyDemosProvider companyId={companyId}>
                <CompanyVisitActionsProvider companyId={companyId}>
                    <CompanyPeopleProvider companyId={companyId}>
                        <CompanyAgreementsProvider companyId={companyId}>
                            <CompanyContext.Provider
                                value={{
                                    loadingStates,
                                    companyId,
                                    profile,
                                    leasingProfile,
                                    resources,
                                    company,
                                    isLoading
                                }}
                            >
                                {children}
                            </CompanyContext.Provider>
                        </CompanyAgreementsProvider>
                    </CompanyPeopleProvider>
                </CompanyVisitActionsProvider>
            </CompanyDemosProvider>
        </CompanyMeetingsProvider>
    </CompanyCommunicationsProvider>
);

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.loadingStates,
            (state, { companyId }) => state.fleetProfiles.find(x => x.companyId === companyId),
            (state, { companyId }) => state.leasingProfiles.find(x => x.companyId === companyId),
            state => state.resources,
            (state, { companyId }) =>
                state.companies.find(company => company.companyId === companyId) || { addresses: [] }
        ],
        (loadingStates, profile, leasingProfile, resources, company) => ({
            loadingStates,
            isLoading: _.get(loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            resources,
            profile,
            leasingProfile,
            company
        })
    );

    return (state, props) => {
        const newState = getState(state, props);
        return newState;
    };
};

export default connect(makeMapStateToProps)(CompanyProvider);
