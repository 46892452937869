import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import IvsCodesCSV from './IvsCodesCSV';
import IvsCodesUpload from './IVSCodesUpload';
import IvsCodeTable from '../Tables/IvsCodeTable';
import { useIvsCodesStore } from '../Provider/IvsCodesProvider';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';

export default function IvsCodesPageContent() {
    const { goBack } = useHistory();

    const updateIvsCodesFromFetch = useIvsCodesStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    //fetch page
    useEffect(() => {
        dispatch(apiPage('ivs-codes'))
            .then(response => {
                updateIvsCodesFromFetch(response.ivsCodes || [], true);
                updateAuditsFromFetch(response.agreementHeaderAudits || [], true);
            })
            .catch(err => console.error('error', err));
    }, [updateIvsCodesFromFetch, updateAuditsFromFetch, dispatch]);

    return (
        <PageSection
            onBackClicked={goBack}
            title="IVS Codes"
            titleButtonArea={
                <>
                    <IvsCodesUpload />
                    <IvsCodesCSV className="ml-2" />
                </>
            }
        >
            <IvsCodeTable />
        </PageSection>
    );
}
