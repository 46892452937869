import React from 'react';
import { HistoryBreadcrumb, useStatusChanges } from '../../Agreement/Components/AgreementHistoryBreadcrumb';
import { useToolTipContext } from '../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import AgreementStatusChangeModal from '../../Agreement/Modals/AgreementStatusChangeModal';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { useStatusChangeButtons } from '../../Agreement/Provider/useStatusChangeButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import MasterTermVersionStatusChangeForm from '../../MasterTermVersion/Forms/MasterTermVersionStatusChangeForm';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

function StatusChangeButton({
    permission,
    modalTitle,
    statusFrom,
    statusTo,
    buttonClass,
    buttonText,
    evidenceMandatory,
    helpText,
    open,
    close
}) {
    const { setTooltipOpen, setTooltipClosed } = useToolTipContext();
    const disabledReason =
        permission.disabledReason ?? (!permission.isEnabled ? 'Not available at this stage.' : undefined);

    return (
        <ActionButton
            bootstrapColor="secondary"
            buttonClass={buttonClass}
            buttonText={buttonText}
            disabled={!!disabledReason}
            fontawesomeIcon="fa-plus"
            onClickAction={() =>
                open(
                    modalTitle,
                    <MasterTermVersionStatusChangeForm
                        buttonText={buttonText}
                        cancel={() => close()}
                        evidenceMandatory={evidenceMandatory}
                        helpText={helpText}
                        statusFrom={statusFrom}
                        statusTo={statusTo}
                    />
                )
            }
            onMouseEnter={() => {
                if (disabledReason) {
                    setTooltipOpen(<div>{disabledReason}</div>);
                }
            }}
            onMouseLeave={() => setTooltipClosed()}
            title={disabledReason}
        />
    );
}

function StatusChangeButtons({ workflow, statusFrom }) {
    const statusChangeButtons = useStatusChangeButtons(workflow, statusFrom);
    return (
        <AgreementStatusChangeModal>
            {({ open, close }) =>
                statusChangeButtons.length === 0 ? (
                    <li>
                        <div />
                    </li>
                ) : (
                    <li className="status-crumb-actions">
                        {statusChangeButtons.map(x => (
                            <StatusChangeButton
                                key={x.id}
                                {...x}
                                buttonClass={`hover-pointer nowrap ml-3 ${
                                    x.buttonType === 'negative'
                                        ? 'btn btn-danger'
                                        : x.buttonType === 'positive'
                                        ? 'btn btn-success'
                                        : 'btn btn-info'
                                }`}
                                close={close}
                                open={open}
                                style={{ zIndex: 5000 }}
                            />
                        ))}
                    </li>
                )
            }
        </AgreementStatusChangeModal>
    );
}

export default function MasterTermVersionHistoryBreadcrumb() {
    const workflow = useMasterTermVersionsStore(state => state.version?.workflow);
    const status = useMasterTermVersionsStore(state => state.version?.status);
    const audits = useAgreementAuditStore(state => state.data);
    const statusChanges = useStatusChanges(audits, 'masterTermVersionStatus');
    return (
        <HistoryBreadcrumb statusChanges={statusChanges}>
            <StatusChangeButtons statusFrom={status} workflow={workflow} />
        </HistoryBreadcrumb>
    );
}
