import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import { Comments } from '../../Agreement/Modals/AgreementAuditModal';
import AgreementAuditForm from '../../Agreement/Forms/AgreementAuditForm';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';

export default function IvsCodeAuditModal() {
    const isOpen = useAgreementAuditStore(state => state.modalOpen);
    const close = useAgreementAuditStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Comments</AppModalHeader>
                <ModalBody>
                    <Comments />
                    <AgreementAuditForm parentEntity={'IvsCode'} parentEntityId={-1} parentStatus={''} />
                </ModalBody>
            </Modal>
        </>
    );
}
