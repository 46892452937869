import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { setOpenInModals } from '../../../actions/settings';
import SidebarDisplayField from './SidebarDisplayField';
import { Link } from 'react-router-dom';
import { currentVersion } from '../Pages/VersionsPage';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';

function AuthorisationLevel() {
    const user = useSelector(state => state.user);
    const userTypes = useChoiceListForBaseTable('userType');

    return <SidebarDisplayField title="User Type" value={userTypes.get(user.userType)} />;
}

const SidebarUserMenum = ({ onLinkOpen, openInModals, setOpenInModals, user }) => {
    const version = currentVersion();
    return (
        <div className="sidebar-background-image">
            <ul className="nav flex-column">
                <Link className="nav-link m-0 p-0" to={'/versions/'}>
                    <SidebarDisplayField
                        title="Current Version"
                        value={version && `${version.version} - ${version.title}`}
                        onClick={() => onLinkOpen && onLinkOpen()}
                    />
                </Link>
                <AuthorisationLevel />

                {process.env.NODE_ENV === 'development' ? (
                    <li className="nav-item sidebar-nav-item border-bottom">
                        <div className="py-3 px-4 text-danger">
                            <Input
                                id="modalCheckbox"
                                type="checkbox"
                                checked={openInModals}
                                onChange={({ target: { checked } }) => setOpenInModals(checked)}
                            />
                            <label htmlFor="modalCheckbox">modals?</label>
                        </div>
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

const mapStateToProps = state => ({
    openInModals: state.settings.openInModals,
    user: state.user
});

export default connect(mapStateToProps, { setOpenInModals })(SidebarUserMenum);
