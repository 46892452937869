import React from 'react';
import AddressTable from '../../Address/Tables/AddressTable';
import CompanyTitle from '../../Company/Components/CompanyTitle';
import PageSection from '../../common/PageSection';
import CompanyProvider from '../../Company/Provider/CompanyProvider';

const AddressesPage = props => {
    const companyId = parseInt(props.match.params.companyId, 10);

    return (
        <CompanyProvider companyId={companyId}>
            <PageSection title={<CompanyTitle />} onBackClicked={props.history.goBack}>
                <AddressTable breakpoint={600} companyId={companyId} />
            </PageSection>
        </CompanyProvider>
    );
};

export default AddressesPage;
