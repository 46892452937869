import React from 'react';
import { useHistory } from 'react-router';
import PageSection from '../../common/PageSection';
import MatrixVersionTable from '../Tables/MasterTermVersionTable';

export default function MatricesPageContent() {
    const { goBack } = useHistory();

    return (
        <PageSection onBackClicked={goBack} title="Master Term Versions">
            <MatrixVersionTable />
        </PageSection>
    );
}
