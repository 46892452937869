import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { apiAddressEdit } from '../../../actions/Api/Address/apiAddressEdit';
import { FORM_NAMES } from '../../../actions/formNames';
import { useAddressById } from '../../../reducers/Api/Company/company';
import addressDef from '../addressDef';

const FORM_NAME = FORM_NAMES.ADDRESS;

const validationSchema = Yup.object().shape({
    address1: Yup.string().required('Address line 1 is required.'),
    city: Yup.string().required('City is required.'),
    postCode: Yup.string().required('Postcode is required.'),
    county: Yup.string().required('County is required.')
});

const EditAddressForm = ({ companyId, save, cancel, addressId }) => {
    const [saving, setSaving] = useState(false);
    let initialValues = useAddressById(addressId);

    if (!initialValues && addressId < 0) {
        initialValues = {
            addressId,
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            address5: '',
            city: '',
            county: '',
            country: '',
            postCode: '',
            isPrimaryAddress: false
        };
    }
    initialValues.companyId = initialValues.companyId || companyId;

    return (
        <ReactiveForm
            formName={FORM_NAME}
            handleSubmit={form => save(form, setSaving, cancel)}
            initialValues={initialValues}
            isSaving={saving}
            cancel={cancel}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={addressDef.address1} />
                <ReactiveForm.Text columnDef={addressDef.address2} />
                <ReactiveForm.Text columnDef={addressDef.address3} />
                <ReactiveForm.Text columnDef={addressDef.address4} />
                <ReactiveForm.Text columnDef={addressDef.address5} />
                <ReactiveForm.Text columnDef={addressDef.city} />
                <ReactiveForm.Text columnDef={addressDef.county} />
                <ReactiveForm.Text columnDef={addressDef.country} />
                <ReactiveForm.Text columnDef={addressDef.postCode} />
                <ReactiveForm.Checkbox columnDef={addressDef.isPrimary} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapDispatchToProps = dispatch => ({
    save: (address, setSaving, cancel) => {
        setSaving(true);
        dispatch(apiAddressEdit(address))
            .then(() => {
                setSaving(false);
                cancel();
            }).catch(err => {
                setSaving(false);
                console.error(err);
            });
    }
});

export default connect(undefined, mapDispatchToProps)(EditAddressForm);
