import _ from 'lodash';
import { useSelector } from 'react-redux';

export const getCompany = (companies, companyId) => companies.find(company => company.companyId === companyId);

export const isPrimaryAddress = address => address.isPrimaryAddress;

export const setCompanyLoading = (company, isLoading) => (company.loading = isLoading);

export const useCompanyPrimaryAddress = companyId => {
    const addresses = useSelector(state => state.address);
    return addresses.filter(address => address.companyId === companyId && address.isPrimaryAddress);
};

export const useCompanyAddresses = companyId => {
    const addresses = useSelector(state => state.address);
    return addresses.filter(address => address.companyId === companyId);
};

export const useAddressById = addressId => {
    const addresses = useSelector(state => state.address);
    return addresses.find(address => address.addressId === addressId);
};

export const getPrimaryOrFirstAddress = company => {
    if (!_.has(company, 'addresses[0]')) {
        return;
    }
    const primaryAddress = company.addresses.find(isPrimaryAddress);
    return primaryAddress || company.addresses[0];
};

export const getPrimaryAddress = (companies, companyId) => {
    const company = getCompany(companies, companyId);
    if (!company) {
        return;
    }
    return getPrimaryOrFirstAddress(company);
};

export const EmptyCompany = {
    companyId: 0,
    businessPhone: '',
    profile: {
        fleetProfileId: 0,
        changeCycleMiles: '',
        changeCycleMilesLcv: '',
        changeCycleMonths: '',
        changeCycleMonthsLcv: '',
        co2Cap: 'string',
        companyId: 0,
        currentFunder: 'string',
        fleetSize: 0,
        numberOfBrand: 0,
        numberOfBrandLcv: 0,
        numberOfCars: 0,
        numberOfLCV: 0,
        otherManufacturers: '',
        otherManufacturerslcv: '',
        id: '',
        createdBy: 0,
        createdDate: undefined,
        updatedBy: 0,
        updatedDate: undefined,
        descriptor: ''
    },
    prospectType: '',
    soleTrader: true,
    registrationNumber: '',
    webSite: '',
    people: [],
    addresses: [],
    id: 'string',
    createdBy: 0,
    createdDate: undefined,
    updatedBy: 0,
    updatedDate: undefined,
    descriptor: ''
};
