import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';
import ReactiveForm from '../../common/ReactiveForm';
import meetingDef from '../meeting';

const schemaFor = meeting =>
    meeting && meeting.roiBooked === 'Y'
        ? yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string().required(),
              feedback: yup.string().nullable()
          })
        : yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string().nullable(),
              feedback: yup.string().nullable()
          });

class CompleteMeetingForm extends React.Component {
    handleSubmit = formValues => {
        formValues.status = 'Complete';
        this.props.save(
            {
                ...formValues
            },
            this.props.onCancel
        );
    };

    render() {
        const { meetingId, meetings } = this.props;

        const meeting = meetings.find(x => x.meetingId === meetingId);
        const { company, ...meetingFields } = meeting;

        const initialValues = { ...meetingFields, companyId: meeting.companyId || meeting.company?.companyId };
        return (
            <ReactiveForm
                cancel={this.props.onCancel}
                initialValues={initialValues}
                handleSubmit={this.handleSubmit}
                validationSchema={schemaFor(meeting)}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Choicelist columnDef={meetingDef.rating} />
                    <ReactiveForm.Text columnDef={meetingDef.feedback} type="textarea" />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    communications: state.communications,
    meetings: state.meetings,
    companies: state.companies,
    user: state.user,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (meeting, cancel) => {
        dispatch(apiMeetingPost(meeting));
        cancel();
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteMeetingForm);
