import { push } from 'connected-react-router';
// import { editMeeting } from './editMeeting';
import { safeDispatchPush } from '../../../reducers/util';

export const meetingSelected = meeting => dispatch => {
    dispatch(openMeeting(meeting.meetingId, meeting.descriptor));
};

export const openMeeting = (meetingId, meetingDescriptor) => dispatch =>
    safeDispatchPush(dispatch, push, ['meeting', 'view', meetingId, meetingDescriptor]);
