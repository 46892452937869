import React from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import ReactiveForm, { FilteredChoiceList } from '../../common/ReactiveForm';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import { useAddendaStore, useUpdateAddenda } from '../Provider/useAddendaStore';
import addendumDef from '../addendumDef';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;
const validationSchema = yup
    .object()
    .shape({
        name: yup
            .string()
            .nullable()
            .required('Please enter a name'),
        salesChannel: yup
            .string()
            .nullable()
            .required('Please select a sales channel')
    })
    .test('updatedAgreementStartDate', function({ updatedAgreementStartDate, addendumUpdatesStartAndEndDate }) {
        return (
            !addendumUpdatesStartAndEndDate ||
            updatedAgreementStartDate ||
            this.createError({
                path: 'updatedAgreementStartDate',
                message: 'Please set the updated start date'
            })
        );
    })
    .test('updatedAgreementEndDate', function({ updatedAgreementEndDate, addendumUpdatesStartAndEndDate }) {
        return (
            !addendumUpdatesStartAndEndDate ||
            updatedAgreementEndDate ||
            this.createError({
                path: 'updatedAgreementEndDate',
                message: 'Please set the updated end date'
            })
        );
    });

export default function AddendumForm() {
    const resources = useResources();
    const addendum = useAddendaStore(state => state.addendum);
    const workflow = addendum?.workflow;
    const canEditSalesChannel = workflow?.find(f => f.permission === 'ChangeSalesChannel')?.isEnabled ?? true;
    const cancel = useAddendaStore(state => state.closeModal);
    const saving = useAddendaStore(state => state.storeState) === storeStates.saving;
    const update = useUpdateAddenda();
    const versions = useMasterTermVersionsStore(state => state.data).filter(x => x.status === 'LIVE');
    const choiceList = useSelector(state => state.resources.choiceList);
    const dependOnSalesChannel = Object.entries(choiceList)
        .filter(f => f[1].some(s => s.filterOnChoiceList === addendumDef.salesChannel.field))
        .map(m => m[0]);

    if (!addendum.addendumId) {
        addendum.masterTermVersionId = versions.find(version => version.status === 'LIVE')?.masterTermVersionId;
        addendum.updatedAgreementStatus = 'LIVE';
    }

    const agreementHeaderStatuses = useChoiceList('agreementHeaderStatus').filter(
        x => x.key === 'LIVE' || x.key === 'ADOK'
    );

    return (
        <ReactiveForm
            cancel={cancel}
            enableReinitialize={true}
            formName={FORM_NAME}
            handleSubmit={record => update(record)}
            initialValues={addendum}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.DisplayInColumns>
                <ReactiveForm.Section>
                    <ReactiveForm.Text columnDef={addendumDef.name} />
                    <ReactiveForm.Choicelist
                        columnDef={addendumDef.reasonCode}
                        disabled={!canEditSalesChannel}
                        filter={f => f.value !== 'LEA'}
                    />
                    <ReactiveForm.Text columnDef={addendumDef.reason} type="textarea" />
                    <ReactiveForm.Choicelist
                        columnDef={addendumDef.salesChannel}
                        disabled={!canEditSalesChannel}
                        onFormValueChanged={(values, fieldName, _, setFieldValue) => {
                            dependOnSalesChannel.forEach(f => {
                                setFieldValue(f, undefined);
                            });
                        }}
                    />
                    <FilteredChoiceList
                        columnDef={addendumDef.matrixBanding}
                        disabled={!canEditSalesChannel}
                        isMulti={true}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={addendumDef.masterTermVersionId}
                        disabled={true}
                        options={versions
                            .filter(version => version.status !== 'EXPI')
                            .map(version => ({ key: version.masterTermVersionId, value: version.reference }))}
                        titleAsLink={`/matrix/${addendum.masterTermVersionId}`}
                    />
                    <ReactiveForm.TriStateCheckBox
                        columnDef={addendumDef.activeSupportBonus}
                        disabled={!canEditSalesChannel}
                    />
                    <ReactiveForm.TriStateCheckBox
                        columnDef={addendumDef.rebate}
                        disabled={!canEditSalesChannel}
                        inline
                    />
                    <ReactiveForm.Text columnDef={addendumDef.comments} type="textarea" />
                </ReactiveForm.Section>
                <ReactiveForm.Section>
                    <ReactiveForm.Checkbox columnDef={addendumDef.addendumUpdatesStartAndEndDate} />
                    <ReactiveForm.Date
                        columnDef={addendumDef.updatedAgreementStartDate}
                        hide={({ values: { addendumUpdatesStartAndEndDate } }) => !addendumUpdatesStartAndEndDate}
                    />
                    <ReactiveForm.Date
                        columnDef={addendumDef.updatedAgreementEndDate}
                        hide={({ values: { addendumUpdatesStartAndEndDate } }) => !addendumUpdatesStartAndEndDate}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={addendumDef.updatedAgreementStatus}
                        options={agreementHeaderStatuses}
                    />
                    {addendum.masterTermVersionId && <ReactiveForm.Text columnDef={addendumDef.emailSubject} />}
                    {addendum.masterTermVersionId && <ReactiveForm.HtmlEditor columnDef={addendumDef.emailBody} />}
                </ReactiveForm.Section>
            </ReactiveForm.DisplayInColumns>
        </ReactiveForm>
    );
}
