import React from 'react';
import { useAgreementNotificationStore } from '../../../../AgreementNotifications/Provider';
import BaseTable from '../../../../common/BaseTable/BaseTable';
import { Button } from 'reactstrap';
import sendTestEmail from '../DropdownActions/sendTestEmail';

const columns = agreementId => [
    { field: 'agreementNotificationName', title: 'Name' },
    { field: 'agreementStatus', title: 'Agreement Status' },
    { field: 'emailSubject', title: 'Subject' },
    {
        field: 'test',
        title: 'Test',
        cellRenderer: ({ rowData }) => (
            <div style={{ margin: '10px 0 10px 0' }}>
                <Button
                    className="w-100"
                    color="primary"
                    data-test-id="email-send-button"
                    onClick={() => sendTestEmail(agreementId, rowData)}
                    type="submit"
                >
                    Send
                </Button>
            </div>
        )
    }
];

const TestEmailForm = ({ agreementId }) => {
    const { data } = useAgreementNotificationStore();

    return (
        <div style={{ height: 400, width: '100%' }}>
            <BaseTable columns={columns(agreementId)} data={data} expandAll={true} isLoadgin={false} />
        </div>
    );
};

export default TestEmailForm;
