import React from 'react';
import { useHistory } from 'react-router';
import PageSection from '../../common/PageSection';
import { ToolTipProvider } from '../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import MatrixPageButtons from './MatrixPageButtons';
import MatrixPageTabs from './MatrixPageTabs';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import AddMasterTermSeriesModal from '../Modal/AddMasterTermSeriesModal';
import MasterTermVersionHistoryBreadcrumb from './MasterTermVersionHistoryBreadcrumb';

export default function MatrixPageContent() {
    const versionId = useMasterTermVersionsStore(state => state.version?.id);
    const { goBack } = useHistory();

    if (!versionId) {
        return <PageSection onBackClicked={goBack} title="Terms Matrix" />;
    }

    return (
        <ToolTipProvider>
            <PageSection
                onBackClicked={goBack}
                title="Terms Matrix"
                titleButtonArea={<MatrixPageButtons />}
                titleContent={
                    <div style={{ gridColumn: '1 / -1', overflowX: 'auto' }}>
                        <MasterTermVersionHistoryBreadcrumb />
                    </div>
                }
            >
                <AddMasterTermSeriesModal>
                    {({ open }) => (
                        <MatrixPageTabs openAddMasterTermSeriesButton={open} />
                    )}
                </AddMasterTermSeriesModal>
            </PageSection>
        </ToolTipProvider>
    );
}
