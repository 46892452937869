import React from 'react';
import TinyMCE from 'react-tinymce';

export default ({ handleChange, initialValues, fieldName, config, disabled }) => (
    <TinyMCE
        config={{ ...(config || tinyMceDefaultConfig), readonly: disabled }}
        content={initialValues[fieldName]}
        onChange={e => {
            e.target.value = e.target.getContent();
            e.target.id = fieldName;
            handleChange(e);
        }}
    />
);

export const tinyMceDefaultConfig = {
    plugins: 'link image',
    height: 350,
    toolbar: 'undo redo | bold underline italic | alignleft aligncenter alignright',
    branding: false,
    elementPath: false,
    statusbar: false,
    menu: {}
};
