import React from 'react';
import BaseTableInCard, { AuditTableButton } from '../../common/BaseTable/BaseTableInCard';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import AgreementCheckModal from '../Modals/AgreementCheckModal';
import {
    AgreementCheckStatus,
    useAgreementChecksSortedWithStatus,
    useAgreementChecksStore
} from '../Provider/useAgreementChecksStore';
import HelpPopover from '../../common/HelpPopover';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { downloadFile } from '../../../actions/Api/Agreement/apiAgreement';
import agreementCheckDef from '../agreementCheckDef';
import WarningPopover from '../../common/WarningPopover';
import { Badge } from 'reactstrap';
import { TableButtonsContainer } from '../../common/Buttons/TableButtons';

const FileListRenderer = ({ cellData }) => {
    return (
        <div>
            {cellData &&
                cellData.map(m => {
                    return (
                        <div key={m.id}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" onClick={e => downloadFile(e, m)}>
                                {m.fileName}
                            </a>
                        </div>
                    );
                })}
        </div>
    );
};
const NameHelpRenderer = ({ cellData, column, rowData }) => {
    const agreement = useAgreementsStore(state => state.agreement);
    return (
        <div className="w-100 py-2 justify-content-between" style={{ display: 'inline-flex' }}>
            <span>
                {cellData}{' '}
                {rowData.revision !== agreement.revision && rowData.revision != null && rowData.revision !== 0
                    ? ` (revision ${rowData.revision})`
                    : null}
            </span>
            {rowData.helpText ? (
                <HelpPopover
                    className="ml-3"
                    color="primary"
                    helpId={`agreementCheck${rowData.agreementCheckId}`}
                    helpText={rowData.helpText}
                    helpTitle={rowData.name}
                    style={{ top: '-3px', right: '-25px', display: 'flex' }}
                />
            ) : null}
        </div>
    );
};

const checksColumns = [
    {
        ...agreementCheckDef.outcome,
        width: 100,
        cellRenderer: props => (
            <div>
                {props.rowData.status === AgreementCheckStatus.needsValue ||
                props.rowData.status === AgreementCheckStatus.needsPositive ? (
                    <Badge className="py-2 px-2" color="danger">
                        {props.rowData.status}
                    </Badge>
                ) : (
                    <div className="checkbox-container">
                        <span
                            className={`checkbox-checkmark ${
                                props.cellData === null ? 'minus' : props.cellData ? 'check' : 'times'
                            }`}
                        />
                    </div>
                )}
            </div>
        )
    },
    {
        ...agreementCheckDef.name,
        cellRenderer: NameHelpRenderer
    },
    {
        ...agreementCheckDef.evidence,
        cellRenderer: FileListRenderer
    },
    {
        field: 'actions',
        width: 75,
        noGrow: true,
        align: 'right',
        title: 'Actions',
        cellRenderer: props => (
            <TableButtonsContainer>
                <AuditTableButton {...props} entity="Check" entityId={props.rowData.agreementCheckId} />
            </TableButtonsContainer>
        )
    }
];

function ChecksWarning({ needsValueCount, mustPassCount }) {
    return (
        <>
            {mustPassCount > 0 ? <div>{`${mustPassCount} Checks must pass`}</div> : null}
            {needsValueCount > 0 ? <div>{`${needsValueCount} Checks need a value`}</div> : null}
        </>
    );
}

export default function AgreementChecksTable({ disabled = false }) {
    const open = useAgreementChecksStore(state => state.openModal);

    const checksWithStatus = useAgreementChecksSortedWithStatus();

    const [needsValueCount, mustPassCount] = checksWithStatus.reduce(
        (acc, check) => [
            acc[0] + (check.status === AgreementCheckStatus.needsValue ? 1 : 0),
            acc[1] + (check.status === AgreementCheckStatus.needsPositive ? 1 : 0)
        ],
        [0, 0]
    );
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);

    return (
        <>
            <AgreementCheckModal />
            <BaseTableInCard
                cardTitle={
                    <>
                        <WarningPopover
                            bodyText={<ChecksWarning mustPassCount={mustPassCount} needsValueCount={needsValueCount} />}
                            id="checksRequired"
                            visible={mustPassCount + needsValueCount > 0}
                        />
                        Mandatory Agreement Checks
                    </>
                }
                className="agreement-checks"
                columns={checksColumns}
                data={checksWithStatus}
                helpId="checkTableHeading"
                isLoading={isLoading}
                onRowClicked={!disabled ? row => row.rowData.isEditable && open(row.rowData) : undefined}
                rowKey={agreementCheckDef.id.field}
                tableSizeStyle={{ height: 250, width: '100%' }}
            />
        </>
    );
}
