import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import { useEngageUsersStore } from '../Provider/useEngageUsersStore';
import ResetPasswordForm from '../Forms/ResetPasswordForm';

export default function ResetPasswordModal() {
    const user = useEngageUsersStore(state => state.selected);
    const isOpen = useEngageUsersStore(state => state.modalOpen2);
    const close = useEngageUsersStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Reset Password for {user?.name}</AppModalHeader>
                <ModalBody>
                    <ResetPasswordForm />
                </ModalBody>
            </Modal>
        </>
    );
}
