import { ENDPOINTS } from '../../../actions/Api/constants';

export default (state = [], { type, data }) => {
    switch (type) {
        case ENDPOINTS.API.PAGE:
            return data.demoFilter
                ? [
                      ...data.demos,
                      ...state.filter(s => !data.demos.some(a => a.companyId === s.companyId || a.demoId === s.demoId))
                  ]
                : state;

        default:
            return state;
    }
};
