import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import queryString from 'query-string';
import ReactiveForm from '../../common/ReactiveForm';
import agreementAuditDef from '../../Agreement/agreementAuditDef';
import { auditEntity } from '../../Agreement/Provider/useAgreementAuditStore';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';

const FormCard = ({ title, className, inContainer = true, children }) => (
    <Card className={`mb-4 shadow-sm simple-card ${className}`} style={{ overflow: 'hidden' }}>
        <div className="d-flex justify-content-between base-table-in-card-header">
            <h5 className="d-inline logo-font gradient mb-0">{title}</h5>
        </div>
        {inContainer ? <Container>{children}</Container> : children}
    </Card>
);

export default function RecentActivityFilter() {
    const history = useHistory();
    const { search } = useLocation();
    const entities = [
        auditEntity.agreement,
        auditEntity.addendum,
        auditEntity.matrix,
        auditEntity.ivsCode,
        auditEntity.template
    ].map(m => ({ key: m, value: m }));

    const handleSubmit = change => {
        const newFilter = {
            ...change
        };

        history.push(`/recentactivity?${queryString.stringify(newFilter, { skipEmptyString: true })}`);
    };

    return (
        <FormCard className="pb-4" title="Recent Activity">
            <ReactiveForm
                debounced={true}
                enableReinitialize={true}
                hideCancelButton={true}
                hideSaveButton={true}
                initialValues={queryString.parse(search)}
                onChange={handleSubmit}
            >
                <ReactiveForm.Section>                  
                    <ReactiveForm.Multiselect
                        columnDef={basicColumnDef('Entity', 'entity')}
                        expand={true}
                        options={entities}
                    />
                    <ReactiveForm.Multiselect columnDef={agreementAuditDef.createdBy} />                    
                </ReactiveForm.Section>
            </ReactiveForm>
        </FormCard>
    );
}