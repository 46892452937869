import React from 'react';
import ReactDOM /*, { render }*/ from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from './containers/app';
import { withRouter } from 'react-router';
import registerServiceWorker from './registerServiceWorker';
//REACT BASE TABLE STYLES
import '@returnoninvestment/base-table/dist/index.css';
import 'react-input-range/lib/css/index.css';
import './css/app.css';
import CacheBusterProvider from './containers/CacheBuster';

const BlockAvoider = withRouter(App);

const target = document.querySelector('#root');

export const persistCallback = () => {
    ReactDOM.render(
        <Provider store={store}>
            <CacheBusterProvider>
                <ConnectedRouter history={history}>
                    <div>
                        <BlockAvoider />
                    </div>
                </ConnectedRouter>
            </CacheBusterProvider>
        </Provider>,
        target
    );
};

registerServiceWorker();
