export default [
    {
        name: 'Portfolio Information',
        fields: [
            {
                label: 'Total Portfolio',
                type: 'number',
                field: 'totalPortfolio',
                max: 999999,
            },
            {
                label: 'Total Salary Sacrifice',
                type: 'number',
                field: 'totalSalarySacrifice',
                max: 999999,
            },
            {
                label: 'BMW Year Renewals',
                type: 'number',
                field: 'bmwYearRenewal',
                max: 999999,
            },
            {
                label: 'BMW Year Renewals + 1',
                type: 'number',
                field: 'bmwYearRenewal1',
                max: 999999,
            },
            {
                label: 'MINI Year Renewals',
                type: 'number',
                field: 'miniYearRenewal',
                max: 999999,
            },
            {
                label: 'MINI Year Renewals + 1',
                type: 'number',
                field: 'miniYearRenewal1',
                max: 999999,
            },
        ]
    },
    {
        name: 'Total Industry Volume (TIV)',
        fields: [
            {
                label: 'Rank 1 Manufacturers',
                type: 'choiceList',
                field: 'rank1Manufacturer',
                choiceList: 'leaseProfileManufacturers',
            },
            {
                label: 'Rank 2 Manufacturers',
                type: 'choiceList',
                field: 'rank2Manufacturer',
                choiceList: 'leaseProfileManufacturers',
            },
            {
                label: 'Rank 3 Manufacturers',
                type: 'choiceList',
                field: 'rank3Manufacturer',
                choiceList: 'leaseProfileManufacturers',
            },
            {
                label: 'Rank 1 Premium',
                type: 'choiceList',
                field: 'rank1Premium',
                choiceList: 'leaseProfilePremium'
            },
            {
                label: 'Rank 2 Premium',
                type: 'choiceList',
                field: 'rank2Premium',
                choiceList: 'leaseProfilePremium'
            },
            {
                label: 'Rank 3 Premium',
                type: 'choiceList',
                field: 'rank3Premium',
                choiceList: 'leaseProfilePremium'
            }

        ]
    },
    {
        name: 'Leasing TDP',
        fields: [
            {
                label: 'Account Strategy',
                type: 'text',
                field: 'accountStrategy'
            },
            {
                label: 'Customer TDP Status',
                readonly: true,
                type: 'choiceList',
                multiple: false,
                field: 'customerStatus',
                choiceList: 'companyCustomerStatus'
            },
            {
                label: 'BMW Forecast',
                type: 'number',
                field: 'bmwForecast',
                max: 999999,
            },
            {
                label: 'MINI Forecast',
                type: 'number',
                field: 'miniForecast',
                max: 999999,
            },
            {
                label: 'Number of Employees',
                type: 'number',
                field: 'numberOfEmployees',
                max: 999999,
            },
            {
                label: 'BMW Orders',
                type: 'number',
                field: 'bmwOrders',
                readonly: true
            },
            {
                label: 'MINI Orders',
                type: 'number',
                field: 'miniOrders',
                readonly: true
            },
            {
                label: 'BMW Regs',
                type: 'number',
                field: 'comp_roi_bmwregistrations2022',
                readonly: true
            },
            {
                label: 'MINI Regs',
                type: 'number',
                field: 'comp_roi_miniregistrations2022',
                readonly: true
            }
        ]
    },
    {
        name: 'Repatriation & Registration Cut Off',
        fields: [
            {
                label: 'Repatriation',
                type: 'text',
                field: 'repatriation',
            },
            {
                label: 'Leasing Company',
                type: 'dayOfMonth',
                field: 'leasingCompany',
                choiceList: 'fleetprofileLeasingCompany'
            },
        ]
    }
];