import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Card, Container } from 'reactstrap';
import queryString from 'query-string';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import agreementDef from '../../Agreement/agreementDef';
import { useChoiceList } from '../../common/Hooks/useChoiceList';

const FormCard = ({ title, className, inContainer = true, children }) => (
    <Card className={`mb-4 shadow-sm simple-card ${className}`} style={{ overflow: 'hidden' }}>
        <div className="d-flex justify-content-between base-table-in-card-header">
            <h5 className="d-inline logo-font gradient mb-0">{title}</h5>
        </div>
        {inContainer ? <Container>{children}</Container> : children}
    </Card>
);

export default function AgreementLiveTermsFilter() {
    const history = useHistory();
    const { search } = useLocation();
    const masterTerms = useMasterTermStore(state => state.data);
    const distinctSeries = useMemo(() => {
        return Array.from(new Set(masterTerms.filter(f => f.series).map(m => m.series))).map(m => ({
            key: m,
            value: m
        }));
    }, [masterTerms]);
    const ivsCodes = useIvsCodesStore(state => state.data);
    const formIvsCodes = useMemo(() => {
        return ivsCodes.map(m => ({
            key: m.ivsCodeId,
            value: `${m.ivsCode} ${m.modelDescription}`
        }));
    }, [ivsCodes]);

    const handleSubmit = change => {
        const newFilter = {
            ...change,
            totalDiscount: undefined,
            minTotalDiscount: change.totalDiscount.min,
            maxTotalDiscount: change.totalDiscount.max
        };

        history.push(`/agreementliveterms?${queryString.stringify(newFilter, { skipEmptyString: true })}`);
    };

    const parsedQueryString = queryString.parse(search);

    const filter = {
        ...parsedQueryString,
        ivsCodeId: parsedQueryString.ivsCodeId ? parseInt(parsedQueryString.ivsCodeId) : undefined,
        isExclusion:
            parsedQueryString.isExclusion === 'true'
                ? true
                : parsedQueryString.isExclusion === 'false'
                ? false
                : undefined,
        totalDiscount: {
            min: parsedQueryString.minTotalDiscount ? parseFloat(parsedQueryString.minTotalDiscount) : 0,
            max: parsedQueryString.maxTotalDiscount ? parseFloat(parsedQueryString.maxTotalDiscount) : 50
        },
        activeSupportBonus:
            parsedQueryString.activeSupportBonus === 'true'
                ? true
                : parsedQueryString.activeSupportBonus === 'false'
                ? false
                : undefined,
        rebate: parsedQueryString.rebate === 'true' ? true : parsedQueryString.rebate === 'false' ? false : undefined
    };

    const matrixBandings = Object.values(
        useChoiceList('matrixBanding').reduce((acc, { key, value }) => {
            acc[key] = { key, value };
            return acc;
        }, {})
    );

    return (
        <FormCard className="pb-4" title="Search">
            <ReactiveForm
                debounced={true}
                enableReinitialize={true}
                hideCancelButton={true}
                hideSaveButton={true}
                initialValues={filter}
                onChange={handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Multiselect
                        columnDef={{ ...basicColumnDef('Series', 'termSeries') }}
                        options={distinctSeries}
                        useBigTitle={true}
                    />
                    <ReactiveForm.Text
                        columnDef={{ ...basicColumnDef('Description', 'termDescription') }}
                        useBigTitle={true}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={{ ...basicColumnDef('Ivs Code', 'ivsCodeId') }}
                        isClearable={true}
                        options={formIvsCodes}
                        useBigTitle={true}
                    />
                    <ReactiveForm.TriStateCheckBox columnDef={{ ...basicColumnDef('Is Exclusion', 'isExclusion') }} />
                    <ReactiveForm.Multiselect columnDef={agreementDef.salesChannel} />
                    <ReactiveForm.Multiselect columnDef={agreementDef.matrixBanding} options={matrixBandings} />
                    <ReactiveForm.Collapse title="Type" expand={true}>
                        <ReactiveForm.TriStateCheckBox columnDef={agreementDef.activeSupportBonus} />
                        <ReactiveForm.TriStateCheckBox columnDef={agreementDef.rebate} />
                    </ReactiveForm.Collapse>
                    <ReactiveForm.Slider
                        columnDef={{ ...basicColumnDef('Total Discount', 'totalDiscount') }}
                        minValue={0}
                        maxValue={50}
                        useBigTitle={true}
                    />
                </ReactiveForm.Section>
            </ReactiveForm>
        </FormCard>
    );
}
