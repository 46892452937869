import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import AgreementCheckForm from '../Forms/AgreementCheckForm';
import { useAgreementChecksStore } from '../Provider/useAgreementChecksStore';

export default function AgreementCheckModal() {
    const isOpen = useAgreementChecksStore(state => state.modalOpen);
    const selectedCheck = useAgreementChecksStore(state => state.selected);
    const close = useAgreementChecksStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>{selectedCheck?.name}</AppModalHeader>
                <ModalBody>
                    <AgreementCheckForm />
                </ModalBody>
            </Modal>
        </>
    );
}
