import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: {
        ...idColumnDef('Id', 'ivsCodeId'),
        sortable: true,
        filter: true
    },
    ivsCode: {
        ...basicColumnDef('IVS Code', 'ivsCode'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'ivsCode',
            helpText: 'The IVS Code'
        }
    },
    devCode: {
        ...basicColumnDef('DEV Code', 'devCode'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'devCode',
            helpText: 'The Dev code, dev code from capcodes data will be used on update if found'
        }
    },
    modelDescription: {
        ...basicColumnDef('Model Description', 'modelDescription'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'modelDescription',
            helpText: 'The description of the IVS Code'
        }
    },
    capCodes: {
        ...basicColumnDef('Cap Codes', 'capCodes'),
        filter: true,
        help: {
            helpId: 'capCodes',
            helpText: 'The number of cap codes linked to this IVS Code'
        }
    },
    updatedBy: {
        ...basicColumnDef('Updated By', 'updatedBy'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'updatedBy',
            helpText: 'The person who last updated the ivs code'
        }
    },
    updatedDate: {
        ...basicColumnDef('Updated Date', 'updatedDate'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'updatedDate',
            helpText: 'The date the ivs code was last updated'
        }
    },
    deleted: {
        ...basicColumnDef('Obsolete', 'deleted'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'deleted',
            helpText: 'Set to no, if ivs code is no longer required'
        }
    },
    audits: {
        ...basicColumnDef('Actions', 'audits'),
        help: {
            helpId: 'audits',
            helpText: 'The comments about this IVS Code'
        }
    }
};
