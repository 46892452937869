import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';

export default function AgreementStatusChangeModal({ children }) {
    const [state, setState] = useState({});
    const close = () => setState({});
    const open = (title, content) => setState({ title, content, open: true });

    return (
        <>
            <Modal className="modal-xl" isOpen={state.open} toggle={close}>
                <AppModalHeader toggle={close}>{state.title}</AppModalHeader>
                <ModalBody>{state.content}</ModalBody>
            </Modal>
            {children({ open, close })}
        </>
    );
}
