import React, { useContext } from 'react';
import { CompanyContext } from '../Provider/CompanyProvider';

const CompanyTitle = () => {
    const {
        company: { descriptor }
    } = useContext(CompanyContext);
    return <React.Fragment>{descriptor}</React.Fragment>; // Because <></> syntax makes webpack complain even though there's nothing wrong here
};

export default CompanyTitle;
