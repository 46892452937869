import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import SimpleCard from '../../common/Card/SimpleCard';
import { generateFourWeeksCounts, MiniCalendar } from '../../common/MiniCalendar/index';
import { safeDispatchPush } from '../../../reducers/util';

class UpcomingCommunications extends React.Component {
    render() {
        const {
            meetings,
            visitActions,
            user: { userId },
            viewDay,
            className
        } = this.props;
        const userMeetings = meetings.filter(x => x.assigneeId === userId);

        const weeks = generateFourWeeksCounts();
        weeks.forEach(week =>
            week.forEach(day => {
                day.count =
                    userMeetings.filter(x => moment(x.startDate).isSame(day.day, 'day')).length +
                    visitActions.filter(x => moment(x.startDate).isSame(day.day, 'day')).length;
            })
        );
        const total = weeks.reduce(
            (fourWeekTotal, week) => fourWeekTotal + week.reduce((weekTotal, day) => weekTotal + day.count, 0),
            0
        );

        return (
            <div className={className}>
                <SimpleCard title={'Upcoming Communications'}>
                    <div className=" pb-3 d-grid grid-cols-2">
                        <div className="text-center grid-js-vcenter">
                            <div className="h5 small">Visits Pending</div>
                            <div className="h1 logo-font">{total}</div>
                            <div className="h6 small">Next 4 weeks</div>
                        </div>
                        <div className="grid-js-center grid-js-vcenter">
                            <MiniCalendar month={weeks} clickDay={viewDay} highlightedDay={moment()} />
                        </div>
                    </div>
                </SimpleCard>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    meetings: state.meetings,
    visitActions: state.visitActions
});

const mapDispatchToProps = dispatch => ({
    viewDay: momentDay =>
        safeDispatchPush(dispatch, push, ['calendar', 'day', momentDay.year(), momentDay.month() + 1, momentDay.date()])
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpcomingCommunications);
