import React from 'react';
import BaseTable, {
    CreatedByRenderer,
    ExpandIcon,
    lookups
} from '../../common/BaseTable/BaseTable';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import useDimensions from '../../common/Hooks/useDimensions';
import { CaptionBadgeResources } from '../../common/FilteredTable/CaptionBadge';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { auditEntity, useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import agreementAuditDef from '../../Agreement/agreementAuditDef';

function EntityRenderer({ entity, parentEntity, parentEntityId }) {
    const gotoEntity = useSafeDispatchPush(({ id, url }) => [url, id]);
    const theEntity = entity || parentEntity;
    let icon;
    let url;

    switch (theEntity) {
        case auditEntity.agreement:
        case auditEntity.check:
        case auditEntity.agreementTerm:
        case auditEntity.agreementVolume:
            icon = <i className={`fa fa-handshake-o mr-3`} />;
            break;

        case auditEntity.addendum:
        case auditEntity.addendumRule:
            icon = <i className={`fa fa-list-alt mr-3`} />;
            break;

        case auditEntity.matrix:
        case auditEntity.masterTerm:
            icon = <i className={`fa fa-sitemap mr-3`} />;
            break;

        case auditEntity.template:
            icon = <i className={`fa fa-address-card mr-3`} />;
            url = 'templates';
            break;

        case auditEntity.ivsCode:
            icon = <i className={`fa fa-address-card mr-3`} />;
            url = 'ivs';
            break;

        default:
            url = parentEntity.toLowerCase();
            break;
    }

    return (
        <div className="table-link" onClick={() => gotoEntity({ id: parentEntityId, url })}>
            {icon} {theEntity}
        </div>
    );
}

export default function RecentActivityTable() {
    const audits = useAgreementAuditStore(state => state.data);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const authorsList = useChoiceList('userAuthors');    

    const checksColumns = [
        {
            field: 'entity',
            title: 'Entity',
            width: 175,
            noGrow: true,
            cellRenderer: ({ rowData }) => <EntityRenderer {...rowData} />
        },
        {
            field: 'status',
            title: 'Status',
            width: 100,
            noGrow: true,
            cellRenderer: ({ rowData }) => (
                <CaptionBadgeResources
                    caption={rowData.newStatus || rowData.status}
                    choiceList={lookups[`${rowData.parentEntity}_status`]?.[1]
                        || `${rowData.parentEntity.toLowerCase()}Status`}
                />
            )
        },
        agreementAuditDef.changes,
        agreementAuditDef.comments,
        {
            ...agreementAuditDef.createdBy,
            width: 175,
            noGrow: true,
            cellRenderer: CreatedByRenderer,
            authorsList
        }
    ];

    const tableData = audits
        .sort((a, b) => {
            if (a.createdDate < b.createdDate) return 1;
            else if (a.createdDate > b.createdDate) return -1;
            return 0;
        }).map(m => ({ ...m, grp: `${m.parentEntity}_${m.parentEntityId}` }));

    const [ref, { y }] = useDimensions({ liveMeasure: false });
    return (
        <div ref={ref} style={{ height: `calc(100vh - ${y}px - 5rem`, width: '100%' }}>
            <BaseTable
                columns={checksColumns}
                data={tableData}
                expandAll={true}
                groupBy={['grp']}
                groupRenderer={({ rowData, expanded, setExpandedRow }) => (
                    <div className="py-2" style={{ display: 'inherit' }}>
                        <ExpandIcon {...rowData} expanded={expanded} setExpandedRow={setExpandedRow} />
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {rowData.grp.split('_')[0]} {rowData.grp.split('_')[1]} ({rowData.groupCount})
                        </div>
                    </div>
                )}
                isLoading={isLoading}
                rowKey={agreementAuditDef.id.field}
            />
        </div>
    );
}