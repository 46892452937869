import React from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import SyncingOrErrorIndicator from '../../common/SyncingOrErrorIndicator';

const PageLoadingSpinner = () => {
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE])

    return <SyncingOrErrorIndicator isLoading={isLoading} />;
};

export default PageLoadingSpinner;
