import { FORM_PATHS, openInModalOrPage } from '../../modals';

export const editCommunication = (communication, schema) => (dispatch, getState) => {
    dispatch(
        openInModalOrPage(
            FORM_PATHS.EditCommunicationModal,
            communication.communicationId,
            communication,
            communication.companyId,
            schema
        )
    );
};
