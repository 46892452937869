import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import BaseTableInCard from '../../common/BaseTable/BaseTableInCard';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';
import { createPerson } from '../../../actions/Navigation/Person/createPerson';
import { editPerson } from '../../../actions/Navigation/Person/editPerson';
import personDef from '../person';

export default function CompanyPeopleTable() {
    const { people, companyId } = useContext(CompanyPeopleContext);
    const dispatch = useDispatch();
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);

    const columns = [
        personDef.isPrimaryPerson,
        personDef.descriptor,
        personDef.jobTitle,
        personDef.contactRole,
        personDef.businessPhone,
        personDef.ddiPhone,
        personDef.mobilePhone,
        personDef.businessEmailLink,
        personDef.gdprPreferencesInTable
    ];

    return (
        <BaseTableInCard
            buttons={[{
                id: 1,
                onClick: () => dispatch(createPerson(companyId)),
                content: 'Add Person',
                icon: 'fa-plus'
            }]}
            cardTitle="Contacts"
            columns={columns}
            data={people}
            helpId="contactsTable"
            isLoading={isLoading}
            noItemMessage="No Contacts"
            onRowClicked={row => dispatch(editPerson(row.rowData))}
            rowKey={personDef.id.field}
            tableSizeStyle={{ height: 'calc(100vh - 197px)', width: '100%' }}
        />
    );
}