import create from 'zustand';
import { storeWithModal, updateDataFromFetch } from '../../Agreement/Provider/storeStates';
import companyDef from '../companyDef';

const rowKey = companyDef.id.field;
export const useCompanyStore = create(set => ({
    ...storeWithModal(set, rowKey),
    company: {},
    updateDataFromFetch: (fetchedRows, clearState, postedRows = []) => {
        set(state => {
            const newState = updateDataFromFetch(rowKey, state, fetchedRows, clearState, postedRows);
            return {
                ...newState,
                company: newState.data[0] || {}
            };
        });
    }
}));