import React, { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import AuditSummary from '../../common/CommonForms/AuditSummary';
import { Alert } from 'reactstrap';
import MapArray from '../../common/MapArray';
import FleetProfileSection, { FleetProfileSectionAgreementStyle } from './FleetProfileSection';
import { CompanyContext } from '../../Company/Provider/CompanyProvider';
import _ from 'lodash';
import kamFleetProfileSchema from '../../../reducers/Schema/kamFleetProfileSchema';
import nonKamFleetProfileSchema from '../../../reducers/Schema/nonKamFleetProfileSchema';
import { ValidationProvider } from '../../common/FormkControls/ValidationProvider';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';

const UpdateFleetProfileEditForm = ({ profileView, choiceList, hideMetaData, user, agreementStyle }) => {
    const { company = {} } = useContext(CompanyContext);
    const isLoading = useSelector(state => state.loadingStates.ANY.isLoading);

    const { profile } = useContext(CompanyContext);

    if (isLoading) {
        return (
            <ShowLoadingSpinner isLoading={isLoading} />
        );
    }

    if (!profile) {
        return (
            <Alert color="danger">
                <b>No Fleet Profile found.</b>
                <span className="ml-3">Please contact support.</span>
            </Alert>
        );
    }

    const roles = _.get(user, 'roles', []);
    const isKamOrLeasing = roles.indexOf('SpecialistSales') === -1;

    const from = profileView === 'kam' || isKamOrLeasing ? kamFleetProfileSchema : nonKamFleetProfileSchema;

    const classes = ['d-grid', 'grid-column-gap-1'];
    let allowFullWidth = false;

    if (from.length === 3) {
        allowFullWidth = true;
        classes.push('grid-cols-3-center-panel');
    } else {
        classes.push('grid-cols-2-center-panel');
    }


    const leasingTermsText = 'BusinessTerms';
    const isLeasing = company.salesChannel === leasingTermsText;
    const fromCondintionalTDP = isLeasing ? from.filter(x => x.name !== "TDP") : from;
    return (
        <ValidationProvider yup={undefined}>
            {agreementStyle ? (
                <div className="d-grid" style={{ gap: '0', marginTop: '1px', gridTemplateColumns: '1fr 1fr' }}>
                    <MapArray
                        data={{ ...profile, schema: fromCondintionalTDP }}
                        from={fromCondintionalTDP}
                        map={(section, index, data) => ({ ...section, index, data, allowFullWidth })}
                    >
                        <FleetProfileSectionAgreementStyle />
                    </MapArray>

                    {!hideMetaData && (
                        <AuditSummary
                            choiceList={choiceList}
                            className="pr-3 mb-3"
                            createdBy={profile.createdBy}
                            createdDate={profile.createdDate}
                            summaryStyle={{ gridColumnEnd: '-1', placeSelf: 'end' }}
                            updatedBy={profile.updatedBy}
                            updatedDate={profile.updatedDate}
                        />
                    )}
                </div>
            ) : (
                <React.Fragment>
                    <div className={classes.join(' ')}>
                        <MapArray
                            data={{ ...profile, schema: from }}
                            from={from}
                            map={(section, index, data) => ({ ...section, index, data, allowFullWidth })}
                        >
                            <FleetProfileSection />
                        </MapArray>
                    </div>
                    {!hideMetaData && (
                        <AuditSummary
                            choiceList={choiceList}
                            createdBy={profile.createdBy}
                            createdDate={profile.createdDate}
                            updatedBy={profile.updatedBy}
                            updatedDate={profile.updatedDate}
                        />
                    )}
                </React.Fragment>
            )}
        </ValidationProvider>
    );
};

const mapStateToProps = state => ({
    choiceList: state.resources.choiceList,
    user: state.user
});

export default connect(mapStateToProps)(UpdateFleetProfileEditForm);
