import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import _ from 'lodash';
import { Formik } from 'formik';
import FormikText from '../../common/FormkControls/FormikText';
import FormikSelectChoicelist from '../../common/FormkControls/FormikSelectChoicelist';
import { apiCommunicationPost } from '../../../actions/Api/Communication/apiCommunicationPost';
import FormikForm from '../../common/FormkControls/FormikForm';

const COMMUNICATION_FORM_NAME = 'cancelCommunicationForm';

const schemaFor = communication =>
    communication && communication.roiBooked === 'Y'
        ? yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string().required(),
              feedback: yup.string()
          })
        : yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string(),
              feedback: yup.string()
          });

class CancelCommunicationForm extends React.Component {
    handleSubmit = formValues => {
        const { communicationId, communications } = this.props;
        const communication = communications.find(x => x.communicationId === communicationId);

        this.props.save(
            {
                ...communication,
                ...formValues
            },
            this.props.onCancel
        );
    };

    render() {
        const { communicationId, communications } = this.props;
        const communication = communications.find(x => x.communicationId === communicationId);

        const initialValues = {
            status: 'Cancelled',
            rating: 'G1',
            feedback: _.get(communication, 'feedback', '') || '',
            notes: _.get(communication, 'notes', '') || ''
        };

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schemaFor(communication)}
                onSubmit={this.handleSubmit}
                render={formikProps => {
                    formikProps.formName = COMMUNICATION_FORM_NAME;
                    return (
                        <FormikForm
                            formikProps={formikProps}
                            cancel={this.props.onCancel}
                            resources={this.props.resources}
                            debugSections={[{ name: 'Form', object: formikProps.values }]}
                        >
                            <div className="col-12">
                                <FormikSelectChoicelist
                                    emptyOption="Select a status"
                                    fieldName="status"
                                    choiceList="communicationStatus"
                                    filter={x => ['Cancelled', 'Postponed'].includes(x.value)}
                                    {...formikProps}
                                />
                                <FormikSelectChoicelist
                                    emptyOption="Select a rating..."
                                    fieldName="rating"
                                    choiceList="communicationAppointmentRating"
                                    filter={x => x.value.startsWith('G')}
                                    {...formikProps}
                                />
                                <FormikText fieldName="feedback" type="textarea" {...formikProps} />
                            </div>
                        </FormikForm>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    communications: state.communications,
    companies: state.companies,
    user: state.user,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (communication, cancel) => {
        dispatch(apiCommunicationPost(communication));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelCommunicationForm);
