import React, { useState } from 'react';
import DashboardValuesContainer from './DashboardValuesContainer';
import DashboardAdminCard from './DashboardAdminCard';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';
import AddendumModal from '../../Addendum/Modal/AddendumModal';

const options = {
    headerStatus: 'View By Status',
    status: 'All (Status)',
    statusRecent: 'Updated This Week (Status)',
    headerSalesChannel: 'View By Sales Channel',
    statusSalesChannel: 'All (Sales Channel)',
    statusRecentSalesChannel: 'Updated This Week (Sales Channel)',
    headerReasonCode: 'View By Reason Code',
    reasonCode: 'All (Reason Code)',
    recentReasonCode: 'Updated this week (Reason Code)'
};

export default function AddendumDashboardCard({ data }) {
    const [option, selectOption] = useState('statusRecent');
    const create = useAddendaStore(state => state.openModal);
    const metrics = data
        .filter(i => i.entity === 'addendum' && i.property === option)
        .map(i => ({
            ...i,
            href:
                option === 'reasonCode'
                    ? `../addendum?reasonCode=${i.rawValue}`
                    : option === 'recentReasonCode'
                    ? `../addendum?reasonCode=${i.rawValue}&recent=true`
                    : option === 'statusRecentSalesChannel'
                    ? `../addendum?salesChannel=${i.rawValue}&recent=true`
                    : option === 'statusSalesChannel'
                    ? `../addendum?salesChannel=${i.rawValue}`
                    : option === 'statusRecent'
                    ? `../addendum?status=${i.rawValue}&recent=true`
                    : `../addendum?status=${i.rawValue}`
        }));

    return (
        <>
            <AddendumModal />
            <DashboardAdminCard
                buttons={[
                    {
                        id: 1,
                        onClick: create,
                        content: 'Create Mass Addendum',
                        icon: 'fa-plus'
                    }
                ]}
                helpId="agreementsAdminAddendaCard"
                title="Mass Addendum"
                toggles={[
                    {
                        id: 1,
                        option,
                        options,
                        selectOption
                    }
                ]}
            >
                <DashboardValuesContainer metrics={metrics} />
            </DashboardAdminCard>
        </>
    );
}
