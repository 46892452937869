import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import capCodesDef from '../capCodesDef';
import { useCapCodesStore } from '../Provider/useCapCodesStore';

export default function CapCodesCSV({ className }) {
    const [mappedData, setMappedData] = useState([]);
    const capCodes = useCapCodesStore(state => state.data);

    return (
        <CSVLink
            className={'button btn btn-secondary ' + className}
            data={mappedData}
            filename="cap_codes.csv"
            headers={[
                capCodesDef.capId.title,
                capCodesDef.capCode.title,
                capCodesDef.modelDescription.title,
                capCodesDef.ivsCode.title,
                capCodesDef.devCode.title,
                capCodesDef.modelRange.title,
                capCodesDef.capStatus.title,
                capCodesDef.vdrVehicleId.title
            ]}
            onClick={() => {
                setMappedData(
                    capCodes.map(capCode => [
                        capCode[capCodesDef.capId.field],
                        capCode[capCodesDef.capCode.field],
                        capCode[capCodesDef.modelDescription.field],
                        capCode[capCodesDef.ivsCode.field],
                        capCode[capCodesDef.devCode.field],
                        capCode[capCodesDef.modelRange.field],
                        capCode[capCodesDef.capStatus.field] ? 'Y' : 'N',
                        capCode[capCodesDef.vdrVehicleId.field]
                    ])
                );
            }}
        >
            Download Cap Codes
        </CSVLink>
    );
}
