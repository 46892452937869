import React from 'react';
import DashboardValuesContainer from './DashboardValuesContainer';
import DashboardAdminCard from './DashboardAdminCard';

export default function CompanyCountDashboardCard({ data }) {
    const metrics = data
        .filter(i => i.entity === 'company')
        .map(i => ({
            ...i,
            href: `../dashboard/companies?salesChannel=${i.rawValue}`
        }));

    return (
        <DashboardAdminCard helpId="agreementsAdminCompanyCountCard" title="Companies with Agreements by Sales Channel">
            <DashboardValuesContainer numberOfCols={2} metrics={metrics} />
        </DashboardAdminCard>
    );
}
