import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, Input, Label } from 'reactstrap';
import { useDebouncedField } from '../ReactiveForm';

const FormikCheckbox = ({
    debounced = false,
    disabled,
    errors,
    fieldName,
    handleBlur,
    handleChange,
    hide,
    hideLabel,
    onFormValueChanged,
    style,
    title,
    touched,
    values
}) => {
    const onChange = value => {
        onFormValueChanged && onFormValueChanged(values, fieldName, value);
    };

    useDebouncedField(values[fieldName], onChange, debounced);

    const invalid = touched[fieldName] && errors[fieldName];
    /*if (hide && hide(values)) {
        return null;
    }*/
    return (
        <Label className="checkbox-container">
            {!hideLabel ? title || fieldName : null}
            <Input
                checked={!!values[fieldName]}
                disabled={disabled}
                id={fieldName}
                onBlur={handleBlur}
                onChange={a => {
                    handleChange(a);

                    if (!debounced) onChange(a.target.checked);
                }}
                style={style}
                type="checkbox"
            />
            <span className="checkbox-checkmark" />
            {invalid && <FormFeedback tooltip>{errors[fieldName]}</FormFeedback>}
        </Label>
    );
};

FormikCheckbox.propTypes = {
    values: PropTypes.object.isRequired,
    formName: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    type: PropTypes.string
};

FormikCheckbox.defaultProps = {
    type: 'text'
};

export default FormikCheckbox;
