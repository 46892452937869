import React from 'react';
import {
    EditablePercentage,
    HeaderEditRenderer,
    TotalRenderer,
    ComparePercentage,
    DualComparePercentage,
    AuditTableButton,
    types,
    TotalSupportRenderer
} from '../../common/BaseTable/BaseTable';
import { agreementPermissions, getPermission } from '../agreementPermissions';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { AddTableRowButton, DeleteTableRowButton, TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { numberColWidth } from '../../MasterTerm/Tables/MatrixTermsTable';
import { useRemainingExclusions } from '../Provider/useTermsStore';
import agreementTermDef from '../agreementTermDef';

function ActionsRenderer({ onAddExclusions, onRemoveExclusion, rowData, useTableStore }) {
    const newRowData = useTableStore(state => state.dataState.pageData[rowData.index]);
    const getIvsCodes = useRemainingExclusions();
    const ivsCodes = getIvsCodes(useTableStore.getState().dataCopy, newRowData);

    const canAdd = !newRowData.ivsCodeId && ivsCodes.length > 1 && onAddExclusions;
    const canDelete = newRowData.ivsCodeId && onRemoveExclusion;

    return (
        <TableButtonsContainer>
            {canAdd ? (
                <AddTableRowButton onClick={() => onAddExclusions(newRowData)} />
            ) : canDelete ? (
                <DeleteTableRowButton onClick={() => onRemoveExclusion(newRowData)} />
            ) : null}
            <AuditTableButton
                entity="AgreementTerm"
                entityId={newRowData.agreementTermId}
                rowData={newRowData}
                secondaryEntity="MasterTerm"
                secondaryEntityId={newRowData.masterTermId}
            />
        </TableButtonsContainer>
    );
}

export function useTermsTableColumns(onUpdateMultiple, onAddExclusions, onRemoveExclusion) {
    const workflow = useAgreementsStore(state => state.agreement?.workflow);
    const baseDiscountPermission = getPermission(agreementPermissions.baseDiscount, workflow);
    const discretionaryDiscountPermission = getPermission(agreementPermissions.discretionaryDiscount, workflow);
    const rebatePermission = getPermission(agreementPermissions.rebate, workflow);
    const activeSupportBonusPermission = getPermission(agreementPermissions.activeSupportBonus, workflow);
    const nextRebatePermission = getPermission(agreementPermissions.nextRebate, workflow);
    const nextActiveSupportBonusPermission = getPermission(agreementPermissions.nextActiveSupportBonus, workflow);
    const nextBaseDiscountPermission = getPermission(agreementPermissions.nextBaseDiscount, workflow);
    const nextDiscretionaryDiscountPermission = getPermission(agreementPermissions.nextDiscretionaryDiscount, workflow);
    const totalDiscountPermission = getPermission(agreementPermissions.totalDiscount, workflow);
    const publicSectorDiscountPermission = getPermission(agreementPermissions.publicSectorDiscount, workflow);

    return [
        agreementTermDef.name,
        agreementTermDef.devCode,
        agreementTermDef.description,
        {
            field: nextBaseDiscountPermission.isVisible ? 'nextBase' : 'base',
            title: 'Base',
            noGrow: true,
            width: numberColWidth,
            type: types.PERCENTAGE,
            cellRenderer: ({ column, rowData, cellData }, { onUpdate, rowKey, useTableStore }) => (
                <DualComparePercentage
                    baseValue={rowData.defaultBase}
                    dataKey={column.dataKey}
                    dataKeys={[column.dataKey, 'base', 'defaultBase']}
                    editable={column.editable}
                    onUpdate={onUpdate}
                    previousValue={column.dataKey === 'nextBase' ? rowData.base : undefined}
                    rowData={rowData}
                    rowKey={rowKey}
                    useTableStore={useTableStore}
                    value={cellData}
                />
            ),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            ),
            sortable: true,
            filter: true,
            editable: baseDiscountPermission.isEnabled || nextBaseDiscountPermission.isEnabled,
            visible: baseDiscountPermission.isVisible || nextBaseDiscountPermission.isVisible
        },
        {
            field: nextDiscretionaryDiscountPermission.isVisible ? 'nextDiscretionary' : 'discretionary',
            title: 'Disc',
            noGrow: true,
            width: numberColWidth,
            type: types.PERCENTAGE,
            cellRenderer: ({ column, rowData, cellData }, { onUpdate, rowKey, useTableStore }) => (
                <DualComparePercentage
                    baseValue={rowData.defaultDiscretionary}
                    dataKey={column.dataKey}
                    dataKeys={[column.dataKey, 'discretionary', 'defaultDiscretionary']}
                    editable={column.editable}
                    onUpdate={onUpdate}
                    previousValue={column.dataKey === 'nextDiscretionary' ? rowData.discretionary : undefined}
                    rowData={rowData}
                    rowKey={rowKey}
                    useTableStore={useTableStore}
                    value={cellData}
                />
            ),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            ),
            sortable: true,
            filter: true,
            editable: discretionaryDiscountPermission.isEnabled || nextDiscretionaryDiscountPermission.isEnabled,
            visible: discretionaryDiscountPermission.isVisible || nextDiscretionaryDiscountPermission.isVisible
        },
        {
            field: 'totalSupport',
            title: 'Total Support',
            sortable: true,
            visible: rebatePermission.isVisible || nextRebatePermission.isVisible,
            noGrow: true,
            width: numberColWidth + 20,
            cellRenderer: (props, rest) => <TotalSupportRenderer {...props} {...rest} />
        },
        {
            field: nextRebatePermission.isVisible ? 'nextRebate' : 'rebate',
            title: 'VRB Payment',
            noGrow: true,
            width: numberColWidth + 20,
            type: types.PERCENTAGE,
            cellRenderer: ({ column, rowData, cellData }, { onUpdate, rowKey, useTableStore }) => (
                <ComparePercentage
                    dataKey={column.dataKey}
                    dataKeys={[column.dataKey, 'rebate']}
                    editable={column.editable}
                    lastRevisionValue={rowData.rebate}
                    onUpdate={onUpdate}
                    rowData={rowData}
                    rowKey={rowKey}
                    useTableStore={useTableStore}
                    value={cellData}
                />
            ),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            ),
            sortable: true,
            filter: true,
            editable: rebatePermission.isEnabled || nextRebatePermission.isEnabled,
            visible: rebatePermission.isVisible || nextRebatePermission.isVisible
        },
        {
            field: nextActiveSupportBonusPermission.isVisible ? 'nextActiveSupportBonus' : 'activeSupportBonus',
            title: 'ASB',
            noGrow: true,
            width: numberColWidth,
            type: types.PERCENTAGE,
            cellRenderer: ({ column, rowData, cellData }, { onUpdate, rowKey, useTableStore }) => (
                <ComparePercentage
                    dataKey={column.dataKey}
                    dataKeys={[column.dataKey, 'activeSupportBonus']}
                    editable={column.editable}
                    lastRevisionValue={rowData.activeSupportBonus}
                    onUpdate={onUpdate}
                    rowData={rowData}
                    rowKey={rowKey}
                    useTableStore={useTableStore}
                    value={cellData}
                />
            ),
            groupRenderer: ({ editable, dataKey }, rest) => (
                <HeaderEditRenderer editable={editable} {...rest}>
                    {({ selectedRowKeys }) => (
                        <EditablePercentage
                            dataKey={dataKey}
                            editable={true}
                            onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                            selectedRowKeys={selectedRowKeys}
                            useTableStore={rest.useTableStore}
                        />
                    )}
                </HeaderEditRenderer>
            ),
            sortable: true,
            filter: true,
            editable: activeSupportBonusPermission.isEnabled || nextActiveSupportBonusPermission.isEnabled,
            visible: activeSupportBonusPermission.isVisible || nextActiveSupportBonusPermission.isVisible
        },
        {
            ...agreementTermDef.publicSector,
            visible: publicSectorDiscountPermission.isVisible,
            noGrow: true,
            cellRenderer: ({ column, rowData, cellData }, { onUpdate, rowKey, useTableStore }) => (
                <ComparePercentage
                    dataKey={column.dataKey}
                    dataKeys={[column.dataKey, 'publicSector']}
                    editable={false}
                    lastRevisionValue={rowData.publicSector}
                    onUpdate={onUpdate}
                    rowData={rowData}
                    rowKey={rowKey}
                    useTableStore={useTableStore}
                    value={cellData}
                />
            )
        },
        {
            field: 'totalDiscount',
            title: rebatePermission.isVisible || nextRebatePermission.isVisible ? 'Payment Off Invoice' : 'Total',
            sortable: true,
            visible: totalDiscountPermission.isVisible,
            noGrow: true,
            width: numberColWidth + (rebatePermission.isVisible ? 20 : 0),
            cellRenderer: (props, rest) => <TotalRenderer {...props} {...rest} />
        },
        {
            field: 'actions',
            title: 'Actions',
            align: 'right',
            noGrow: true,
            width: 120,
            cellRenderer: (props, rest) => (
                <ActionsRenderer
                    {...props}
                    {...rest}
                    onAddExclusions={onAddExclusions}
                    onRemoveExclusion={onRemoveExclusion}
                />
            )
        }
    ];
}
