import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { useAgreementTemplateStore } from '../../AgreementTemplates/Provider/useAgreementTemplateStore';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import MatrixPageContent from '../../MasterTerm/Components/MatrixPageContent';
import { useBMWStore, useMiniStore, usePreviousMasterTermsStore } from '../../MasterTerm/Provider/useTermsStore';
import { useMasterTermVersionSalesChannelsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionSalesChannelsStore';
import {
    useMasterTermVersionsStore,
    useCountsStore,
    useUsersVisibleToUserStore
} from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';

export default function MatrixPage({
    match: {
        params: { matrixId: strMatrixId }
    }
}) {
    const masterTermVersionId = parseInt(strMatrixId, 10);
    const setAgreementCounts = useCountsStore(state => state.setData);
    const setUsersVisibleToUser = useUsersVisibleToUserStore(state => state.setData);
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateAddendumFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const setPreviousMasterTermsStore = usePreviousMasterTermsStore(state => state.setData);
    const updateIvsCodesFromFetch = useIvsCodesStore(state => state.updateDataFromFetch);
    const updateTemplateFromFetch = useAgreementTemplateStore(state => state.updateDataFromFetch);
    const updateMasterTermsSalesChannelsFromFetch = useMasterTermVersionSalesChannelsStore(
        state => state.updateDataFromFetch
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(apiPage('matrix', masterTermVersionId))
            .then(response => {
                const version = (response.masterTermVersions || [])[0] || {};

                setAgreementCounts(response.agreementAdminCounts);
                setUsersVisibleToUser(response.usersVisibleToUser);
                updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
                updateAuditsFromFetch(response.masterTermVersionAudits || [], true);
                updateIvsCodesFromFetch(response.ivsCodes || [], true);
                updateMasterTermsSalesChannelsFromFetch(response.masterTermVersionSalesChannels || [], true);
                updateAddendumFromFetch(response.addendum || [], true);
                const previousTermsMap = new Map(
                    (response.masterTerms || [])
                        .filter(term => term.masterTermVersionId !== version.masterTermVersionId)
                        .map(({ masterTermId, ...value }) => [masterTermId, value])
                );

                const bmwTerms = (response.masterTerms || []).filter(
                    term => term.brand === 'BMW' && term.masterTermVersionId === version.masterTermVersionId
                );
                const miniTerms = (response.masterTerms || []).filter(
                    term => term.brand === 'MINI' && term.masterTermVersionId === version.masterTermVersionId
                );

                setBMWTerms(bmwTerms);
                setMiniTerms(miniTerms);

                setPreviousMasterTermsStore(previousTermsMap);
                updateTemplateFromFetch(response.agreementTemplates || [], true);
            })
            .catch(err => console.error('error', err));
    }, [
        masterTermVersionId,
        dispatch,
        setAgreementCounts,
        setUsersVisibleToUser,
        updateMasterTermVersionsFromFetch,
        updateAuditsFromFetch,
        updateIvsCodesFromFetch,
        updateMasterTermsSalesChannelsFromFetch,
        setBMWTerms,
        setMiniTerms,
        setPreviousMasterTermsStore,
        updateTemplateFromFetch,
        updateAddendumFromFetch
    ]);

    return <MatrixPageContent />;
}
