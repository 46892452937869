import { useMasterTermStore } from '../../../../MasterTerm/Provider/useMasterTermStore';
import { useBMWStore, useMiniStore } from '../../../Provider/useTermsStore';
import { useDispatch } from 'react-redux';
import { apiPreviewDraftVersion } from '../../../../../actions/Api/Agreement/apiAgreement';

export default function usePreviewDraftVersion() {
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const dispatch = useDispatch();

    return (agreement, preview) =>
        dispatch(apiPreviewDraftVersion(agreement, preview))
            .then(response => {
                updateMasterTermsFromFetch(response.masterTerms || [], true);

                const bmwTerms = (response.agreementTerms || []).filter(term => term.brand === 'BMW');
                const miniTerms = (response.agreementTerms || []).filter(term => term.brand === 'MINI');
                setBMWTerms(bmwTerms);
                setMiniTerms(miniTerms);
            })
            .catch(err => {
                throw err;
            });
}
