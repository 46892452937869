import React from 'react';
import Switch from 'react-switch';
import { PreventClickThrough } from '../../Table/PreventClickThrough';

const ToggleRenderer = ({ cellData, columnItem, disabled, onToggle, rowData, testIfToggledFunction, title }) => {
    const onChange = (checked, event) => {
        PreventClickThrough(event);
        onToggle(rowData, checked, columnItem);
    };

    const checked = testIfToggledFunction ? testIfToggledFunction(rowData) : !!cellData;

    return (
        <div className="p-2" onClick={PreventClickThrough}>
            <Switch checked={checked} disabled={disabled} height={20} onChange={onChange} title={title} width={40} />
        </div>
    );
};

export default ToggleRenderer;
