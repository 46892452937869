import { ENDPOINTS } from '../../../actions/Api/constants';
import { FORM_PATHS, SET_MODAL_OPEN } from '../../../actions/modals';
import { RESET_REDUCER } from '../../../actions/resetReducers';

export function address(state = [], action) {
    switch (action.type) {
        case SET_MODAL_OPEN:
            if (action.isOpen || action.name !== FORM_PATHS.EditAddress.modalName || !action.data.addressId) {
                return state;
            }

            return [
                action.data,
                ...state
                    .filter(address => address.addressId !== action.data.addressId)
                    .map(address => ({
                        ...address,
                        isPrimaryAddress: action.data.isPrimaryAddress ? false : address.isPrimaryAddress
                    }))
            ];
        case ENDPOINTS.API.ADDRESS:
            const { data } = action;
            return {
                ...state,
                data
            };
        case ENDPOINTS.API.PAGE:
            return action.data.addressFilter
                ? [
                      ...action.data.addresses,
                      ...state.filter(
                          s =>
                              !action.data.addresses.some(
                                  a =>
                                      a.addressId === s.addressId ||
                                      (action.data.addressFilter.companyId &&
                                          action.data.addressFilter.companyId === s.companyId)
                              )
                      )
                  ]
                : state;
        case ENDPOINTS.API.ADDRESS_BY_ID:
            const { data: address } = action;
            return {
                address
            };
        case RESET_REDUCER:
            return [];

        default:
            return state;
    }
}
