import { useAgreementAuditStore, useGroupedAuditComments } from '../../../Agreement/Provider/useAgreementAuditStore';
import { useToolTipContext } from '../../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import React from 'react';
import { CommentTableRowButton } from '../../Buttons/TableButtons';
import { PreventClickThrough } from '../../Table/PreventClickThrough';
import { CommentsSummary } from '../../../Agreement/Modals/AgreementAuditModal';

export default function AuditTableButton({ entity, entityId, secondaryEntity, secondaryEntityId, rowData }) {
    const open = useAgreementAuditStore(state => state.openModal);

    const { setTooltipOpen, setTooltipClosed } = useToolTipContext();
    const comments = useGroupedAuditComments(entity, entityId, secondaryEntity, secondaryEntityId);

    return (
        <CommentTableRowButton
            count={comments.length}
            onClick={event => {
                PreventClickThrough(event);
                open(entityId, entity, secondaryEntity, secondaryEntityId);
            }}
            onMouseEnter={() => {
                comments.length && setTooltipOpen(<CommentsSummary commentsAry={comments} />, true);
            }}
            onMouseLeave={() => comments.length && setTooltipClosed()}
        />
    );
}
