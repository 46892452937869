import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import PageSection from '../../common/PageSection';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import Layout3Columns, { Column1, Column2, Column3 } from '../../Dashboard/Components/Layout3Columns';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import CompanyFilter from '../../CompanySummary/Components/CompanyFilter';
import BrowserHistory from '../../BrowserHistory/Tables/BrowserHistory';
import CompaniesTable from '../../CompanySummary/Tables/CompaniesTable';
import { useCompanyStore } from '../../Company/Providers/useCompanyStore';

export default function CompaniesPage() {
    const { goBack, push } = useHistory();
    const { search } = useLocation();
    const updateCompaniesFromFetch = useCompanyStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    //fetch page
    useEffect(() => {
        const filter = queryString.parse(search);
        const { name, salesChannel, accountManagerId } = filter;

        const filterClean = {
            name,
            salesChannels: salesChannel && salesChannel !== '' ? salesChannel?.split(',') : undefined,
            accountManagerIds: accountManagerId && accountManagerId !== '' ? accountManagerId?.split(',') : undefined
        };

        //TODO: maybe something different here in future
        if (Object.entries(filterClean).some(([_, x]) => x !== undefined)) {
            dispatch(apiPage('companies', undefined, filterClean))
                .then(response => {
                    updateCompaniesFromFetch(response.companies || [], true);
                })
                .catch(err => console.error('error', err));
        }
    }, [updateCompaniesFromFetch, dispatch, push, search]);

    return (
        <PageSection onBackClicked={goBack} title="Companies">
            <RedirectNotLoggedIn />
            <Layout3Columns>
                <Column1>
                    <CompanyFilter />
                </Column1>
                <Column2>
                    <CompaniesTable />
                </Column2>
                <Column3>
                    <div className="d-grid" style={{ gap: '1rem' }}>
                        <BrowserHistory title="Recent Companies" urlsStartWith="/company/" />
                    </div>
                </Column3>
            </Layout3Columns>
        </PageSection>
    );
}
