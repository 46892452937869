import React from 'react';
import ResponsiveBaseTable, { ExpandIcon, useSelectionData } from '@returnoninvestment/base-table';
import { FilterPopover } from './CusomCellRenderers/FilterPopoverHeaderCell';
import ShowLoadingSpinner from '../ShowLoadingSpinner';
import { ThDiv } from '../Table/Th';

function HeaderCellRenderer({ column, useTableStore, sortBy, onSort, onFilter, headerEditRenderer }) {
    const selectedRowKeys = useTableStore(state => state.selectedRowKeys);

    if (column.editable && column.groupRenderer && selectedRowKeys.size > 0) {
        const headerEditRendererToUse = column.headerEditRenderer || headerEditRenderer;
        return <>{headerEditRendererToUse({ ...column }, { useTableStore, selectedRowKeys })}</>;
    } else {
        return (
            <ThDiv
                setTableSortColumn={() => onSort(column)}
                sort={column.sortable}
                sortField={column.dataKey}
                sortOrder={sortBy && { column: sortBy.key, sort_asc: sortBy.order === 'asc' }}
                value={
                    <>
                        {column.title} <FilterPopover column={column} onFilter={onFilter} />
                    </>
                }
            />
        );
    }
}

function SelectionCell(props) {
    const { checked, checkboxRef, disabled, handleChange, handleClick, inputOptions } = useSelectionData(props);
    return (
        <label className="checkbox-container p-0">
            <input
                {...(inputOptions || {})}
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                onClick={handleClick}
                ref={checkboxRef}
                type="checkbox"
            />
            <span className="checkbox-checkmark" style={{ marginTop: '-6px' }} />
        </label>
    );
}

export default function BaseTable(props) {
    return (
        <ResponsiveBaseTable
            disableDefaultSort={true}
            groupRenderer={({ rowData, expanded, setExpandedRow }) => (
                <div className="py-2" style={{ display: 'inherit' }}>
                    <ExpandIcon {...rowData} expanded={expanded} setExpandedRow={setExpandedRow} />
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {rowData.allGroupData.join(', ')} ({rowData.groupCount})
                    </div>
                </div>
            )}
            headerCellRenderer={(headerProps, rest) => (
                <HeaderCellRenderer {...headerProps} {...rest} headerEditRenderer={props.headerEditRenderer} />
            )}
            loadingElement={<ShowLoadingSpinner isLoading />}
            selectionColumnDef={{ width: 55 }}
            selectionRenderer={SelectionCell}
            {...props}
        />
    );
}

export * from '@returnoninvestment/base-table';
export * from './CusomCellRenderers/index';
