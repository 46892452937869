import React from 'react';
import BaseTableInCard from '../../common/BaseTable/BaseTableInCard';
import agreementTermDef from '../../Agreement/agreementTermDef';
import useDimensions from '../../common/Hooks/useDimensions';

export default function TermCompareTable({ data = [] }) {
    const columns = [
        agreementTermDef.name,
        agreementTermDef.devCode,
        agreementTermDef.description,
        {
            title: 'Revision Total',
            field: 'revisionTotal',
            sortable: true,
            noGrow: true,
            width: 180,
            cellRenderer: ({ cellData }) => <div>{cellData.toFixed(2)}</div>
        },
        {
            title: 'Agreement Total',
            field: 'agreementTotal',
            sortable: true,
            noGrow: true,
            width: 180,
            cellRenderer: ({ cellData }) => <div>{cellData.toFixed(2)}</div>
        },
        {
            title: 'Change',
            field: 'change',
            noGrow: true,
            width: 70,
            cellRenderer: ({ rowData }) => (
                <>
                    {rowData.agreementTotal > rowData.revisionTotal ? (
                        <i className="fa fa-arrow-up" />
                    ) : rowData.agreementTotal < rowData.revisionTotal ? (
                        <i className="fa fa-arrow-down" />
                    ) : null}
                </>
            )
        }
    ];

    const tableData = data.map(m => ({
        ...m[0],
        revisionTotal: m[0].nextTotalDiscount || m[0].totalDiscount,
        agreementTotal: m[1].nextTotalDiscount
    }));

    const [ref, { y }] = useDimensions({ liveMeasure: false });
    return (
        <BaseTableInCard
            cardTitle="Terms Compare"
            columns={columns}
            data={tableData}
            expandAll={true}
            groupBy={['series']}
            refForTableHeight={ref}
            rowClassName={({ ivsCodeId }) => (ivsCodeId ? 'bg-orange' : '')}
            rowKey="id"
            tableSizeStyle={{ height: `calc(100vh - ${y}px - 5rem`, width: '100%' }}
        />
    );
}
