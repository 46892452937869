import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PageSection from '../../common/PageSection';
import Layout3Columns, { Column1, Column2And3 } from '../../Dashboard/Components/Layout3Columns';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { useAgreementLiveTermsStore } from '../Provider/useAgreementLiveTermsStore';
import AgreementLiveTermsFilter from './AgreementLiveTermsFilter';
import AgreementLiveTermsTable from '../Tables/AgreementLiveTermsTable';
import AgreementLiveTermsGraph from './AgreementLiveTermsGraph';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';

export default function AgreementPageContent() {
    const { goBack, push } = useHistory();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const [graphFilter, setGraphFilter] = useState();
    const updateTermsFromFetch = useAgreementLiveTermsStore(state => state.updateDataFromFetch);
    const updateIvsCodesFromFetch = useIvsCodesStore(state => state.updateDataFromFetch);
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);

    //fetch page
    useEffect(() => {
        //dispatch(apiPage('agreement-live-terms'))
        if (!search)
            push(`/agreementliveterms?${queryString.stringify({ minTotalDiscount: 0, maxTotalDiscount: 50 })}`);
        else {
            const filter = search ? queryString.parse(search) : {};
            const {
                termSeries,
                termDescription,
                ivsCodeId,
                isExclusion,
                salesChannel,
                matrixBanding,
                minTotalDiscount,
                maxTotalDiscount,
                activeSupportBonus,
                rebate
            } = filter;

            const filterClean = {
                termSeries,
                termDescription,
                ivsCodeId,
                isExclusion: isExclusion === 'true' ? true : isExclusion === 'false' ? false : undefined,
                minTotalDiscount,
                maxTotalDiscount,
                salesChannels: salesChannel && salesChannel !== '' ? salesChannel?.split(',') : undefined,
                matrixBanding: matrixBanding && matrixBanding !== '' ? matrixBanding?.split(',') : undefined,
                activeSupportBonus:
                    activeSupportBonus === 'true' ? true : activeSupportBonus === 'false' ? false : undefined,
                rebate: rebate === 'true' ? true : rebate === 'false' ? false : undefined
            };

            dispatch(apiPage('agreement-live-terms', undefined, filterClean))
                .then(response => {
                    updateTermsFromFetch(response.agreementLiveTerms || [], true);
                    updateMasterTermsFromFetch(response.masterTerms || [], true);
                    updateIvsCodesFromFetch(response.ivsCodes || [], true);
                    setGraphFilter(undefined);
                })
                .catch(err => console.error('error', err));
        }
    }, [push, search, dispatch, updateTermsFromFetch, updateIvsCodesFromFetch, updateMasterTermsFromFetch]);

    return (
        <PageSection onBackClicked={goBack} title="Agreement Live Terms">
            <Layout3Columns>
                <Column1>
                    <AgreementLiveTermsFilter />
                    <AgreementLiveTermsGraph setGraphFilter={setGraphFilter} />
                </Column1>
                <Column2And3>
                    <AgreementLiveTermsTable totalDiscount={graphFilter} />
                </Column2And3>
            </Layout3Columns>
        </PageSection>
    );
}
