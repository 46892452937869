export const FORM_NAMES = {
    ADDRESS: 'addressForm',
    PERSON: 'personForm',
    EDIT_DAY: 'dayForm',
    EDIT_VISIT_ACTION: 'editVisitActionForm',
    EDIT_MEETING: 'editMeetingForm',
    EDIT_COMMUNICATION: 'editCommunicationForm',
    LEAD: 'leadForm',
    SUPPORT: 'supportForm',
    EDIT_USER: 'EDIT_USER',
    REJECT_AGREEMENT: 'rejectAgreement',
    EDIT_MASTER_TERMS: 'editMasterTermsForm',
    AGREEMENTS_FILTER: 'AGREEMENTS_FILTER',
    AGREEMENTS_FILTER_IVS: 'AGREEMENTS_FILTER_IVS',
    TEST_PDF_TEMPLATE: 'TEST_PDF_TEMPLATE'
};
