import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import { getFieldsNotUpdated, setPropertiesSyncing } from '../helper';
import moment from 'moment';
import { addColorToVisitAction, addColorToVisitActions } from './VisitAction';

const defaultState = [];

const propertiesToIgnoreForErrors = [
    'person',
    'company',
    'visitActionId',
    'descriptor',
    'assignee',
    'updatedDate',
    'start',
    'end',
    'assigneeName',
    'calendarOption'
];

export const getVisitActionUpdateErrors = (local, remote) => {
    return getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
};

const updateVisitActionPost = (state, { data }) => {
    const original = state.find(x => data.visitActionId === x.visitActionId);
    if (original) {
        setPropertiesSyncing(original, data);
    }
    addColorToVisitAction(data);
    return [data, ...state.filter(oldComm => data.visitActionId !== oldComm.visitActionId)];
};

const postCommit = (state, action) => {
    const {
        meta: { modified },
        payload: { data: remote }
    } = action;

    addColorToVisitActions([remote]);

    return [
        remote,
        ...state.filter(x => modified.visitActionId !== x.visitActionId && remote.visitActionId !== x.visitActionId)
    ];
};

const countFilterMatches = (
    { companyId, communicationId, startDate, endDate, status, eventType, subject, assigneeId, dealershipId },
    visitAction,
    mEndDate,
    mStartDate
) =>
    (companyId !== undefined && companyId === visitAction.companyId ? 1 : 0) +
    (communicationId !== undefined && communicationId === visitAction.communicationId ? 1 : 0) +
    (status !== undefined && status === visitAction.status ? 1 : 0) +
    (eventType !== undefined && eventType === visitAction.eventType ? 1 : 0) +
    (subject !== undefined && subject === visitAction.subject ? 1 : 0) +
    (assigneeId !== undefined && assigneeId === visitAction.assigneeId ? 1 : 0) +
    (dealershipId !== undefined && dealershipId === visitAction.dealershipId ? 1 : 0) +
    (startDate !== undefined && moment(visitAction.startDate).isAfter(mStartDate) ? 1 : 0) +
    (endDate !== undefined && moment(visitAction.endDate).isBefore(mEndDate) ? 1 : 0);

export const filterState = (state, filter, newData) => {
    const {
        companyId,
        communicationId,
        startDate,
        endDate,
        status,
        eventType,
        subject,
        assigneeId,
        dealershipId
    } = filter;
    const mEndDate = endDate === undefined ? undefined : moment(endDate);
    const mStartDate = startDate === undefined ? undefined : moment(startDate);

    const activeFilterCount =
        (companyId === undefined ? 0 : 1) +
        (communicationId === undefined ? 0 : 1) +
        (status === undefined ? 0 : 1) +
        (eventType === undefined ? 0 : 1) +
        (subject === undefined ? 0 : 1) +
        (assigneeId === undefined ? 0 : 1) +
        (dealershipId === undefined ? 0 : 1) +
        (endDate === undefined ? 0 : 1) +
        (startDate === undefined ? 0 : 1);

    return state.filter(
        x =>
            !newData.some(newComm => x.visitActionId === newComm.visitActionId) &&
            activeFilterCount !== countFilterMatches(filter, x, mEndDate, mStartDate)
    );
};

export const visitActions = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return defaultState;
        case ENDPOINTS.API.PAGE:
            if (action.data.visitActionFilter) {
                addColorToVisitActions(action.data.visitActions);

                const withCompanies = action.data.visitActions
                    .map(x => ({
                        ...x,
                        company: (action.data.companies || []).find(company => company.companyId === x.companyId)
                    }))
                    .map(x => ({
                        ...x,
                        companyDescriptor: x.company?.descriptor || x.companyDescriptor
                    }));

                return [
                    ...withCompanies,
                    ...state.filter(
                        oldComm => !action.data.visitActions.some(x => oldComm.visitActionId === x.visitActionId)
                    )
                ];
            }
            return state;
        case ENDPOINTS.API.VISIT_ACTION_POST_ROLLBACK:
            const rollbackTo = {
                ...action.meta.unmodified,
                syncing: false,
                error: action.payload.message
            };
            return [rollbackTo, ...state.filter(oldComm => rollbackTo.visitActionId !== oldComm.visitActionId)];
        case ENDPOINTS.API.VISIT_ACTION_POST:
            return updateVisitActionPost(state, action);
        case ENDPOINTS.API.VISIT_ACTION_POST_COMMIT:
            return postCommit(state, action);
        case ENDPOINTS.API.VISIT_ACTION_GET:
            addColorToVisitActions(action.data);
            return [action.data, ...state.filter(oldComm => oldComm.visitActionId !== action.data.visitActionId)];
        default:
            return state;
    }
};
