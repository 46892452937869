import React, { useMemo } from 'react';
import BaseTableInCard, { ToggleRenderer } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useCapCodesStore, useUpdateCapCodes } from '../Provider/useCapCodesStore';
import capCodesDef from '../capCodesDef';

function useColumns() {
    const updateCapCodes = useUpdateCapCodes();

    const onToggleDelete = (capCode, checked) => {
        updateCapCodes([{ ...capCode, deleted: checked ? 1 : null }]);
    };

    const columns = [
        {
            ...capCodesDef.capId,
            noGrow: true,
            width: 100
        },
        {
            ...capCodesDef.capCode,
            noGrow: true,
            width: 150
        },
        {
            ...capCodesDef.capStatus,
            noGrow: true,
            width: 120,
            cellRenderer: props => <ToggleRenderer {...props} disabled={true} />
        },
        {
            ...capCodesDef.modelRange
        },
        {
            ...capCodesDef.modelDescription
        },
        {
            ...capCodesDef.ivsCode,
            noGrow: true,
            width: 120
        },
        {
            ...capCodesDef.devCode,
            noGrow: true,
            width: 110
        },
        {
            ...capCodesDef.vdrVehicleId,
            noGrow: true,
            width: 150
        },
        {
            ...capCodesDef.deleted,
            cellRenderer: props => <ToggleRenderer {...props} onToggle={onToggleDelete} />
        }
    ];

    return columns;
}

export default function CapCodesTable({ ivsCodes }) {
    const capCodes = useCapCodesStore(state => state.data);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const columns = useColumns();

    const filteredCodes = useMemo(() => {
        if (ivsCodes) {
            const ivsCodesMap = new Set(ivsCodes.split(','));
            return capCodes.filter(f => ivsCodesMap.has(f.ivsCode));
        } else return capCodes;
    }, [capCodes, ivsCodes]);

    return (
        <BaseTableInCard
            cardTitle="Cap Codes"
            columns={columns}
            data={filteredCodes}
            helpId="capCodesTableHeading"
            isLoading={isLoading}
            rowKey={capCodesDef.id.field}
            tableSizeStyle={{ height: 'calc(100vh - 197px)', width: '100%' }}
        />
    );
}
