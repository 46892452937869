import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import create from 'zustand';
import {
    apiAgreementPost,
    apiAgreementQuickPost,
    apiUpliftAgreementPost
} from '../../../actions/Api/Agreement/apiAgreement';
import { AgreementContext, setBmwAndMiniTerms } from './AgreementProvider';
import { storeWithModal, updateDataFromFetch } from './storeStates';
import { useAgreementChecksStore } from './useAgreementChecksStore';
import { useAgreementAuditStore } from './useAgreementAuditStore';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import { useRevisionsStore } from './useRevisionsStore';
import { useAnticipatedVolumesStore } from './useAnticipatedVolumesStore';
import { useBMWStore, useMiniStore } from './useTermsStore';
import { useAgreementTemplateStore } from '../../AgreementTemplates/Provider/useAgreementTemplateStore';
import {
    apiProcessAgreementsOnAddendum,
    apiToggleAgreementIsOnAddendum,
    apiToggleAgreementsOnAddendum
} from '../../../actions/Api/Addendum/apiAddendum';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';

const rowKey = 'agreementId';
export const useAgreementsStore = create(set => ({
    ...storeWithModal(set, rowKey),
    agreement: {},
    updateDataFromFetch: (fetchedRows, clearState, postedRows = []) => {
        set(state => {
            const newState = updateDataFromFetch(rowKey, state, fetchedRows, clearState, postedRows);
            return {
                ...newState,
                agreement: newState.data[0] || {}
            };
        });
    },
    removeDeleted: () =>
        set(state => {
            return {
                ...state,
                data: state.data.filter(x => x.status !== 'DELT')
            };
        }),
    clearData: () => {
        set(() => ({ agreement: {}, data: [] }));
    }
}));

// doesn't work for some reason :(
export function useUpdatePageFromFetch() {
    const context = useContext(AgreementContext); //TODO: Fix TEMP
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const updateChecksFromFetch = useAgreementChecksStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateAnticipatedVolumesFromFetch = useAnticipatedVolumesStore(state => state.updateDataFromFetch);
    const updateRevisionsFromFetch = useRevisionsStore(state => state.updateDataFromFetch);

    return response => {
        updateMasterTermsFromFetch(response.masterTerms || [], true);

        const bmwTerms = (response.agreementTerms || []).filter(term => term.brand === 'BMW');
        const miniTerms = (response.agreementTerms || []).filter(term => term.brand === 'MINI');
        setBMWTerms(bmwTerms);
        setMiniTerms(miniTerms);

        updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
        updateRevisionsFromFetch(response.agreementRevisions || [], true);
        updateAgreementsFromFetch(response.agreements || [], true);
        updateChecksFromFetch(response.agreementChecks || [], true);
        updateAuditsFromFetch(response.agreementHeaderAudits || [], true);
        updateAnticipatedVolumesFromFetch(response.agreementAnticipatedVolumes || []);
        context && context.setPageData(response, true);
    };
}

export function useUpdateAgreement() {
    const context = useContext(AgreementContext); //TEMP
    //const updatePageFromFetch = useUpdatePageFromFetch();
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const updateChecksFromFetch = useAgreementChecksStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateAnticipatedVolumesFromFetch = useAnticipatedVolumesStore(state => state.updateDataFromFetch);
    const updateRevisionsFromFetch = useRevisionsStore(state => state.updateDataFromFetch);
    const updateTemplatesFromFetch = useAgreementTemplateStore(state => state.updateDataFromFetch);
    const setSaving = useAgreementsStore(state => state.setSaving);
    const setError = useAgreementsStore(state => state.setError);
    const dispatch = useDispatch();

    return agreement => {
        setSaving();
        dispatch(
            apiAgreementPost({
                ...agreement,
                masterTermVersionId: !agreement.masterTermVersionId || parseInt(agreement.masterTermVersionId)
            })
        )
            .then(response => {
                //updatePageFromFetch(response);

                updateMasterTermsFromFetch(response.masterTerms || [], true);

                const bmwTerms = (response.agreementTerms || []).filter(term => term.brand === 'BMW');
                const miniTerms = (response.agreementTerms || []).filter(term => term.brand === 'MINI');
                setBMWTerms(bmwTerms);
                setMiniTerms(miniTerms);

                updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
                updateRevisionsFromFetch(response.agreementRevisions || [], true);
                updateAgreementsFromFetch(response.agreements || [], true);
                updateChecksFromFetch(response.agreementChecks || [], true);
                updateAuditsFromFetch(response.agreementHeaderAudits || [], true);
                updateAnticipatedVolumesFromFetch(response.agreementAnticipatedVolumes || []);
                updateTemplatesFromFetch(response.agreementTemplates || [], true);
                context && context.setPageData(response, true);
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useQuickUpdateAgreement() {
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);

    const setSaving = useAgreementsStore(state => state.setSaving);
    const setError = useAgreementsStore(state => state.setError);
    const dispatch = useDispatch();

    return agreement => {
        setSaving();
        dispatch(apiAgreementQuickPost(agreement))
            .then(response => {
                updateAgreementsFromFetch(response.agreements || [], true);
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useToggleAgreementIsOnAddendum() {
    const updateAddendumFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setSaving = useAgreementsStore(state => state.setSaving);
    const setError = useAgreementsStore(state => state.setError);
    const dispatch = useDispatch();

    return (isOnAgreement, addendumId, agreementId) => {
        setSaving();
        dispatch(apiToggleAgreementIsOnAddendum(isOnAgreement, addendumId, agreementId))
            .then(response => {
                updateAddendumFromFetch(response.addendum || []);
                updateAgreementsFromFetch(response.agreements || [], false, [{ agreementId }]);
                updateAuditsFromFetch(response.addendumAudits || []);
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useToggleAgreementsOnAddendum() {
    const updateAddendumFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setSaving = useAgreementsStore(state => state.setSaving);
    const setError = useAgreementsStore(state => state.setError);
    const dispatch = useDispatch();

    return (addendumId, agreementIds) => {
        setSaving();
        dispatch(apiToggleAgreementsOnAddendum(addendumId, agreementIds))
            .then(response => {
                updateAddendumFromFetch(response.addendum || []);
                updateAgreementsFromFetch(
                    response.agreements || [],
                    false,
                    agreementIds.map(agreementId => ({ agreementId }))
                );
                updateAuditsFromFetch(response.addendumAudits || []);
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useProcessAgreementsOnAddendum() {
    const updateAddendumFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setSaving = useAgreementsStore(state => state.setSaving);
    const setError = useAgreementsStore(state => state.setError);
    const dispatch = useDispatch();

    return (addendumId, agreementIds) => {
        setSaving();
        dispatch(apiProcessAgreementsOnAddendum(addendumId, agreementIds))
            .then(response => {
                updateAddendumFromFetch(response.addendum || []);
                updateAgreementsFromFetch(
                    response.agreements || [],
                    false,
                    agreementIds.map(agreementId => ({ agreementId }))
                );
                updateAuditsFromFetch(response.addendumAudits || []);
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useUpliftNeuAddendumAgreement(agreementId) {
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const setSaving = useAgreementsStore(state => state.setSaving);
    const setError = useAgreementsStore(state => state.setError);
    const dispatch = useDispatch();

    return ({ matrixBanding }) => {
        setSaving();
        dispatch(apiUpliftAgreementPost(agreementId, matrixBanding))
            .then(response => {
                updateAgreementsFromFetch(response.agreements || [], false);
                updateAuditsFromFetch(response.agreementHeaderAudits || []);
                setBmwAndMiniTerms(response.agreementTerms || [], setBMWTerms, setMiniTerms);
            })
            .catch(error => {
                setError(error);
            });
    };
}
