import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import SimpleCard from '../../common/Card/SimpleCard';
import moment from 'moment';
import AgendaDay from '../Components/AgendaDay';
import MapArray from '../../common/MapArray';
import { FieldValue } from '../../common/DisplayField/FieldValue';
import { communicationSelected } from '../../../actions/Navigation/Communication/communicationSelected';
import AgendaKey from '../Components/AgendaKey';
import { editVisitAction } from '../../../actions/Navigation/VisitAction/editVisitAction';

class AgendaCard extends React.Component {
    mapDay = (day, index, { ...data }) => ({
        day: day,
        first: index === 0,
        ...data
    });

    render = () => {
        const {
            loadingStates,
            agenda,
            visitActions,
            updateCommunication,
            editVisitAction,
            choiceList,
            showDayName,
            daysArray,
            title,
            calendarOptionsSelected,
            filters = [],
            filterData = {},
            resources
        } = this.props;

        const loadingState = getLoadingState(loadingStates, ENDPOINTS.API.COMMUNICATIONS_FILTER);

        const fullAgenda = [...agenda, ...visitActions];

        const days =
            daysArray ||
            fullAgenda.map(comm => moment(comm.start)).reduce((x, y) => (x.includes(y) ? x : [...x, y]), []);

        let filteredAgenda = fullAgenda.filter(comm => calendarOptionsSelected[comm.calendarOption] !== false);

        filters.forEach(f => {
            const vals = f.options
                ? f.options.map(o => o.value)
                : _.get(resources, `choiceList.${f.choiceList}`, []).map(o => o.key);
            const blockVals = vals.filter(v => filterData[`${f.label}/${v}`] === false);
            filteredAgenda = filteredAgenda.filter(x => !blockVals.includes(x[f.type]));
        });

        return (
            <SimpleCard title={title || 'Agenda'} isLoading={loadingState.isLoading}>
                {days.length === 0 ? (
                    <FieldValue>No Events</FieldValue>
                ) : (
                    <MapArray
                        from={days}
                        map={this.mapDay}
                        data={{
                            agenda: filteredAgenda,
                            updateCommunication,
                            editVisitAction,
                            choiceList,
                            showDayName
                        }}
                        mapKey={day => day}
                    >
                        <AgendaDay />
                    </MapArray>
                )}
                <hr />
                <AgendaKey />
            </SimpleCard>
        );
    };
}

const mapStateToProps = state => ({
    resources: state.resources,
    calendarOptionsSelected: state.settings.showOnCalendarCommunicationTypes,
    choiceList: state.resources.choiceList,
    loadingStates: state.loadingStates,
    filterData: state.settings.agendaFilters
});

const mapDispatchToProps = dispatch => ({
    updateCommunication: communication => dispatch(communicationSelected(communication)),
    editVisitAction: visitAction => dispatch(editVisitAction(visitAction))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgendaCard);
