import React, { useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ShowLoadingSpinner from '../../../common/ShowLoadingSpinner';
import useToggleOpen from '../../../common/Hooks/useToggleOpen';
import IconFactory from '../../../common/IconFactory';
import { useAgreementsStore } from '../../Provider/useAgreementsStore';
import MassTermsUpload from '../../Downloads/MassTermsUpload';
import LeasingCsv from '../../Downloads/LeasingCsv';
import downloadAgreementPdf from './DropdownActions/downloadAgreementPdf';
import usePreviewDraftVersion from './DropdownActions/perviewDraftVersion';
import TestEmailDropdownModal, { useTestEmailDropdownStore } from './Modal/TestEmailDropdownModal';
import ExistingDataUpload from '../ExistingDataUpload';
import TestPdfTemplateModal, { useTestPdfTemplateStore } from './Modal/TestPdfTemplateModal';
import OverwritePdfUpload from '../OverwritePdfUpload';
import downloadPdfDebug from './DropdownActions/downloadPdfDebug';

export default function AgreementTestsDropdown() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [testPdfTemplate, setTestPdfTemplate] = useState(null);
    const agreement = useAgreementsStore(state => state.agreement);
    const openTestEmailModal = useTestEmailDropdownStore(state => state.openModal);
    const openTestPdfTemplateModal = useTestPdfTemplateStore(state => state.openModal);
    const leasingCsvEnabled = ['LEA_AFFIN', 'LEA_CARSH', 'END_CONHI'].includes(agreement.agreementType);
    const leasingCsvDisabledReason =
        'Agreement type must be one of: Leasing Affinity, Leasing Car Scheme and End User (Contract Hire)';
    const { toggle: togglePreview, isOpen: isPreview } = useToggleOpen();
    const previewDraftVersion = usePreviewDraftVersion();

    const toggle = () => setDropdownOpen(prevState => !prevState);

    function onPreviewDraftVersion() {
        previewDraftVersion(agreement, !isPreview)
            .then(() => {
                togglePreview();
            })
            .catch(err => console.error(err));
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>
                <i className="fa fa-cog mr-2" />
                Tests
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem header>Current Matrix</DropdownItem>
                <Button
                    className="dropdown-item"
                    color="info"
                    disabled={downloading}
                    onClick={e => downloadAgreementPdf(e, agreement, false, setDownloading)}
                >
                    {!downloading ? (
                        <>
                            <i className="fa fa-file-pdf-o mr-2" />
                            Generate test pdf
                        </>
                    ) : (
                        <ShowLoadingSpinner isLoading size={18} />
                    )}
                </Button>
                <Button
                    className="dropdown-item"
                    color="info"
                    disabled={downloading}
                    onClick={e => downloadPdfDebug(e, agreement, false, setDownloading)}
                >
                    {!downloading ? (
                        <>
                            <i className="fa fa-file-pdf-o mr-2" />
                            Template help
                        </>
                    ) : (
                        <ShowLoadingSpinner isLoading size={18} />
                    )}
                </Button>
                <TestEmailDropdownModal agreementId={agreement.agreementId} />
                <DropdownItem
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        openTestEmailModal();
                    }}
                >
                    Send Test Email
                </DropdownItem>
                <LeasingCsv disabledReason={!leasingCsvEnabled ? leasingCsvDisabledReason : null} toggle={toggle} />
                <MassTermsUpload className="dropdown-item" />
                <ExistingDataUpload className="dropdown-item" />
                <OverwritePdfUpload className="dropdown-item" />
                <DropdownItem divider />
                <DropdownItem header>Next Matrix</DropdownItem>
                <DropdownItem disabled={agreement?.status !== 'LIVE'} onClick={onPreviewDraftVersion}>
                    Preview Draft Version{' '}
                    {isPreview ? <IconFactory icon={{ type: 'fa', id: 'fa-check', color: 'green' }} /> : ''}
                </DropdownItem>
                <LeasingCsv
                    disabledReason={!leasingCsvEnabled ? leasingCsvDisabledReason : null}
                    nextMatrix={true}
                    toggle={toggle}
                />
                <Button
                    className="dropdown-item"
                    color="info"
                    disabled={agreement?.status !== 'LIVE' || downloading}
                    onClick={e => downloadAgreementPdf(e, agreement, true, setDownloading)}
                >
                    {!downloading ? (
                        <>
                            <i className="fa fa-file-pdf-o mr-2" />
                            Generate test pdf
                        </>
                    ) : (
                        <ShowLoadingSpinner isLoading size={18} />
                    )}
                </Button>
                <DropdownItem divider />
                <DropdownItem header>Template Tests</DropdownItem>
                <TestPdfTemplateModal templateName={testPdfTemplate} />
                <DropdownItem
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTestPdfTemplate('testTemplate');
                        openTestPdfTemplateModal();
                    }}
                >
                    Test With Template
                </DropdownItem>
                <DropdownItem
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTestPdfTemplate('testAgreementClauses');
                        openTestPdfTemplateModal();
                    }}
                >
                    Test With Agreement Clauses
                </DropdownItem>
                <DropdownItem
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTestPdfTemplate('testCoverLetter');
                        openTestPdfTemplateModal();
                    }}
                >
                    Test With Cover Letter
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
