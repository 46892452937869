import React from 'react';
import _ from 'lodash';

export const ThDiv = props => {
    const { tableId, sort, value, field, right, setTableSortColumn, sortField, sortOrder } = props;
    return (
        <div
            className={`border-top-0 ${right ? 'text-right' : ''}`}
            onClick={() => (sort ? setTableSortColumn(tableId, sortField || field) : undefined)}
            style={{ width: '100%', cursor: sort ? 'pointer' : 'initial' }}
        >
            <small className="text-uppercase">
                {sort ? (
                    <i
                        className={`fa d-inline fa-lg fa-sort mr-2 ${
                            sortField === _.get(sortOrder, 'column', '')
                                ? `text-warning ${sortOrder.sort_asc ? 'fa-sort-desc' : 'fa-sort-asc'}`
                                : 'fa-sort'
                        }`}
                    />
                ) : null}
                {value}
            </small>
        </div>
    );
};