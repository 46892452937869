import React from 'react';
import CalendarEvent from './CalendarEvent';
import { eventToCalendarEvent } from '../../../reducers/Api/Events/mappers';
import { editDay } from '../../../actions/Navigation/Communication/editDay';
import { useDispatch } from 'react-redux';

const AgendaDayEvent = ({ event, updateCommunication }) => {
    const calendarEvent = eventToCalendarEvent(event);
    const dispatch = useDispatch();

    return (
        <div
            className={`w-100 mt-1 p-2 hover-pointer background-hover background-color-${calendarEvent.color}`}
            key={event.eventId}
            onClick={() => dispatch(editDay(event))}
        >
            <CalendarEvent event={calendarEvent} />
        </div>
    );
};

export default AgendaDayEvent;
