import pipe from 'lodash/fp/pipe';
import filter from 'lodash/fp/filter';
import allPass from 'lodash/fp/allPass';
import sortBy from 'lodash/fp/sortBy';
import forEach from 'lodash/fp/forEach';
import moment from 'moment';
import { calendarOptions } from '../../calendarOptions';
import { getChoiceListValue } from '../../../containers/common/LabelText';

const isAssignedToUser = (visitAction, userId) => parseInt(visitAction.assigneeId, 10) === userId;
const belongsToCompany = (visitAction, companyId) => visitAction.companyId === companyId;

export const filterBelongsToCommunication = (visitActions, communicationId) =>
    visitActions.filter(visitAction => visitAction.communicationId === communicationId);

const inFuture = visitAction => moment(visitAction.start).isAfter(moment());
const isNext7Days = visitAction =>
    moment(visitAction.start).isBefore(
        moment()
            .startOf('day')
            .add(7, 'day')
    ) && moment(visitAction.start).isAfter(moment().startOf('day'));

export const isNotCancelled = visitAction => visitAction.status !== 'Cancelled';
const isNotComplete = visitAction => visitAction.status !== 'Complete';
const isOverdue = visitAction => new Date(visitAction.endDate).getTime() < Date.now();

export const filterAssignedToUser = (visitActions, userId) =>
    visitActions.filter(visitAction => isAssignedToUser(visitAction, userId)).filter(x => isNotCancelled(x));

export const filterBelongsToCompany = (visitActions, companyId) =>
    visitActions.filter(visitAction => belongsToCompany(visitAction, companyId));

export const cvOutstandingVisitActions = pipe(
    filterAssignedToUser,
    filter(allPass([isNotComplete, isOverdue])),
    sortBy('dueBy')
);

export const cvCompanyVisitActions = pipe(filterBelongsToCompany, sortBy('start'));
export const cvCommmunicaitonVisitActions = pipe(filterBelongsToCommunication, sortBy('start'));

export const filterFutureCompanyVisitActions = pipe(
    filterBelongsToCompany,
    filter(allPass([inFuture, isNotCancelled])),
    sortBy('start')
);

export const filterNext7DaysVisitActions = pipe(
    filterAssignedToUser,
    filter(allPass([isNext7Days, isNotCancelled])),
    sortBy('start')
);

export const addColorToVisitAction = visitAction => {
    visitAction.start = moment(visitAction.startDate)
        .utc()
        .valueOf();
    visitAction.end = moment(visitAction.endDate)
        .utc()
        .valueOf();
    visitAction.end = visitAction.end < visitAction.start ? visitAction.start + 60 * 60 * 1000 : visitAction.end;
    //visitAction.assigneeName = visitAction.assignee.descriptor;
    visitAction.calendarOption = 'visitReportAction';
};

const getVisitActionTitle = ({ subject, action }, visitAction, choiceList) => {
    const actionName = getChoiceListValue({ choiceList }, 'visitActionActions', action);
    return actionName && subject
        ? `${actionName} - ${subject}`
        : actionName
        ? actionName
        : subject
        ? subject
        : 'UNKNOWN';
};

export const mapVisitActionToCalendarEvent = (visitAction, choiceList) => {
    const { visitActionId, start, end } = visitAction;
    let calendarOpt = calendarOptions.visitReportAction;
    const icon = calendarOpt.icon;
    const color = calendarOpt.color;

    return {
        id: `visitAction-${visitActionId}`,
        title: getVisitActionTitle(visitAction, choiceList),
        visitAction,
        icon,
        start: moment(start).toDate(),
        end: moment(end)
            .add(45, 'minutes')
            .toDate(),
        color
    };
};

export const eventStyleGetter = event => {
    const { icon, color } = event;
    const style = {
        icon
    };
    return {
        style: style,
        className: `border-0 rounded-0 background-hover background-color-${color}`
    };
};

export const addColorToVisitActions = pipe(forEach(addColorToVisitAction));

/*
//Temp function to test database logic against javascript. For OP-583
function validateEntityTypeFromSqlMatchesOldCode(visitAction) {
    let a = '';
    switch (visitAction.eventType) {
        case 'Meeting':
            if (visitAction.eventMethod === 'FaceToFace') {
                a = 'faceToFace';
            } else {
                a = 'telephone';
            }
            break;
        case 'AdminDay':
            a = 'adminDay';
            break;
        case 'Event':
            a = 'event';
            break;
        case 'Holiday':
            a = 'holiday';
            break;
        default:
            a = 'contactOut';
            visitAction.engageEntityType = 'contactOut'; //TODO: Hack SQL returning wrong engageEntitiyTypes so setting from code for now.
            break;
    }
    if (isTask(visitAction)) {
        a = 'visitReportAction';
        visitAction.engageEntityType = 'visitReportAction'; //TODO: Hack SQL returning wrong engageEntitiyTypes so setting from code for now.
    }

    if (a !== visitAction.engageEntityType) {
        console.error(`Incorrect Entity Type From Code: ${a} From SQL: ${visitAction.engageEntityType}`);
    }
}










export const bmwFilterOutstandingVisitReports = pipe(
    filterAssignedToUser,
    filter(allPass([isBeforeNow, isPending, isMeeting ])),
sortBy('start')
);



export const filterTasksBelongingToVisitAction = pipe(
    filterChildVisitActions,
    filter(allPass([isTask])),
    sortBy('start')
);

export const filterOutstandingUserTasks = pipe(
    filterAssignedToUser,
    filter(allPass([isBeforeNow, isVisitAction, isPending])),
    sortBy('start')
);

export const filterCompanyVisitActions = pipe(
    filterBelongsToCompany,
    filter(allPass([isVisitAction])),
    sortBy('start')
);

export const filterNext7Days = pipe(
    filterAssignedToUser,
    filter(allPass([isNext7Days, isNotCancelled])),
    sortBy('start')
);

export const psaFilterCompanyVisitActions = pipe(
    filterBelongsToCompany,
    filter(allPass([isMeetingOrPhoneOut, isAppointment])),
    sortBy('start')
);

export const psaFilterOutstandingVisitReports = pipe(
    filterAssignedToUser,
    filter(allPass([isBeforeNow, isPending, isMeeting
    //, isNotPsaDay, isNotTelephone
])),
    sortBy('start')
);

export const bmwFilterCompanyVisitActions = pipe(
    filterBelongsToCompany,
    filter(allPass([isMeetingOrPhoneOut])),
    sortBy('start')
);

export const filterFutureCompanyVisitActions = pipe(
    filterBelongsToCompany,
    filter(allPass([inFuture, isNotCancelled])),
    sortBy('start')
);
*/
