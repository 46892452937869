import React from 'react';
import MapArray from '../common/MapArray';
import LeasingProfileItem from './LeasingProfileItem';

const LeasingProfileSectionAgreementStyle = ({ name, fields, data, cancel }) => {
    const className = `list-group mx-0 my-0 no-shadow d-grid`;

    const style = { borderRadius: 0, height: 'min-content' };
    return (
        <ul className={className} style={style}>
            {name && <li
                className={`list-group-item ${'base-table-in-card-header'}`}
                style={{ height: '50px' }}
            >
                {name}
            </li>}
            <MapArray data={data} from={fields} map={(fields, index, data) => ({ ...fields, data })}>
                <LeasingProfileItem cancel={cancel} />
            </MapArray>
        </ul>
    );
}

export default LeasingProfileSectionAgreementStyle;