import React, { useRef } from 'react';
import { FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormikFormGroup from './FormikFormGroup';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';

function FormikDate({
	children,
	customFieldSetValue,
	disabled,
	errors,
	fieldName,
	formName,
	handleChange,
	isClearable,
	onChange,
	onFormValueChanged,
	setFieldValue,
	showTimeSelect,
	title,
	touched,
	useBigTitle,
	values
}) {
	const pickerRef = useRef();

	const onOutsideClick = () => {
		if (pickerRef.current)
			pickerRef.current.cancelFocusInput();
	};

	const invalid = touched[fieldName] && errors[fieldName];
	const dateFormat = showTimeSelect
		? `${process.env.REACT_APP_DATE_FORMAT} H:mm`
		: process.env.REACT_APP_DATE_FORMAT;

	return (
		<FormikFormGroup fieldName={fieldName} formName={formName} title={title} useBigTitle={useBigTitle}>
			<DatePicker
				className="w-100 rounded border form-control pl-2 py-2"
				dateFormat={dateFormat}
				disabled={disabled}
				isClearable={isClearable}
				minTime={moment()
					.hours(9)
					.minutes(0)}
				maxTime={moment()
					.hours(17)
					.minutes(30)}
				onChange={a => {
					handleChange(a);

					setFieldValue(fieldName, a ? moment(a)
						.startOf('minute')
						.format(REST_TIME_FORMAT) : a);

					if (onChange)
						onChange(a);

					if (onFormValueChanged)
						onFormValueChanged(values, fieldName, a);

					if (customFieldSetValue)
						customFieldSetValue(setFieldValue, a);
				}}
				onChangeRaw={() => {
					// do nothing
				}}
				onClickOutside={onOutsideClick}
				ref={pickerRef}
				selected={values[fieldName] && moment(values[fieldName])}
				showTimeSelect={showTimeSelect}
				timeCaption="time"
				timeFormat="H:mm"
				timeIntervals={15}
				value={values[fieldName] && moment(values[fieldName]) && moment(values[fieldName]).format(dateFormat)}
			/>
			{invalid && (
				<FormFeedback tooltip className="d-block">
					{errors[fieldName]}
				</FormFeedback>
			)}
			{children}
		</FormikFormGroup>
	);
}

FormikDate.propTypes = {
	values: PropTypes.object.isRequired,
	formName: PropTypes.string,
	fieldName: PropTypes.string.isRequired,
	handleChange: PropTypes.func,
	handleBlur: PropTypes.func,
	type: PropTypes.string
};

FormikDate.defaultProps = {
	type: 'text'
};

export default FormikDate;