import React, { useContext, memo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../Provider/CompanyProvider';
import SimpleCard from '../../common/Card/SimpleCard';
import CardDropDownButton from '../../common/Buttons/CardDropDownButton';

const CompanyAffiliatesCard = memo(({ affiliates, isLoading }) => {

    const [showAll, setShowAll] = useState(affiliates.length <= 10);

    const renderButtons = () => {
        return (
            <CardDropDownButton
                items={[
                    {
                        text: 'View All',
                        fontAwesomeName: 'fa-th-list',
                        onClick: () => setShowAll(true)
                    }
                ]}
            />
        );
    };

    return (
        <SimpleCard title="Affiliates" isLoading={isLoading} buttons={!showAll && renderButtons()}>
            {affiliates.filter((_, i) => showAll || i < 10).map(m => {
                return (
                    <div key={m.affiliatedCompanyId}>
                        <Link to={`/company/${m.affiliatedCompanyId}/${m.affiliatedCompanyName}/`}>
                            {m.affiliatedCompanyName}
                        </Link>
                    </div>
                );
            })}
        </SimpleCard>
    );
});

function CompanyAffiliates({ affiliates }) {
    const { company = {} } = useContext(CompanyContext);
    const isLoading = company.loading;
    const companyAffiliates = affiliates.filter(x => x.companyId === company.companyId);

    if (companyAffiliates.length === 0)
        return null;

    return <CompanyAffiliatesCard affiliates={companyAffiliates} isLoading={isLoading} />;
}

const mapStateToProps = state => ({
    affiliates: state.affiliates
});

export default connect(mapStateToProps)(CompanyAffiliates);
