import React from 'react';
import { useCountTermsWhereApprovalRequired, useFilteredTermsInDatabase } from '../Provider/useTermsStore';
import WarningPopover from '../../common/WarningPopover';
import { useAgreementErrors } from './AgreementActions';

export function DiscountWarnings() {
    const countTermsOverLimit = useFilteredTermsInDatabase(term => term.totalDiscount > 30).length;
    const countTermsWhereApprovalRequired = useCountTermsWhereApprovalRequired();
    const needsApproval = countTermsWhereApprovalRequired > 0;
    const overLimit = countTermsOverLimit > 0;
    const approvalText =
        overLimit && needsApproval
            ? `${countTermsOverLimit} term${
                  countTermsOverLimit > 1 ? 's have discounts' : ' has a discount'
              } above 30% \n\n ${countTermsWhereApprovalRequired} term${
                  countTermsWhereApprovalRequired > 1 ? 's ' : ''
              } require authorisation`
            : needsApproval
            ? `${countTermsWhereApprovalRequired} term${
                  countTermsWhereApprovalRequired > 1 ? 's ' : ''
              } require authorisation`
            : overLimit
            ? `${countTermsOverLimit} term${countTermsOverLimit > 1 ? 's have discounts' : ' has a discount'} above 30%`
            : null;
    const agreementValidationWarnings = useAgreementErrors();

    return (
        <>
            <WarningPopover
                bodyText={
                    (approvalText || agreementValidationWarnings.length > 0) && (
                        <>
                            {approvalText}
                            <br />
                            <br />
                            {agreementValidationWarnings.map((m, i) => (
                                <div key={i}>{m}</div>
                            ))}
                        </>
                    )
                }
            />
        </>
    );
}
