import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ListGroupItem } from 'reactstrap';
import { calendarOptions } from '../../../reducers/calendarOptions';

class BrowserHistoryRow extends React.Component {
    getIcon = () => {
        const { entity } = this.props;
        switch (entity) {
            case 'Company':
                return 'fa-building';
            case 'Agreement':
                return 'fa-handshake-o';
            default:
                return 'fa-link';
        }
    };
    getColor = () => {
        const { entity } = this.props;
        switch (entity) {
            case 'Company':
                return 6;
            case 'Agreement':
                return 3;
            case 'Communication':
                return calendarOptions.contactOut.color;
            case 'Meeting':
                return calendarOptions.faceToFace.color;
            default:
                return 'secondary';
        }
    };

    render() {
        const { url, link, descriptor } = this.props;

        return (
            <ListGroupItem
                tag="button"
                type="button"
                action
                key={url}
                className={`hover-pointer background-hover background-color-${this.getColor()}`}
                onClick={() => link(url)}
            >
                <i className={`fa ${this.getIcon()} mr-3`} />
                {decodeURIComponent(descriptor)}
            </ListGroupItem>
        );
    }
}
/* style={{backgroundColor:'#990000', color:'white'}} */
//TODO: Communications style here should match the agenda

const mapStateToProps = state => ({
    //companies: state.companies
});

const mapDispatchToProps = dispatch => ({
    link: path => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(BrowserHistoryRow);
