import React from 'react';
import { TabsGroup, TabsInCard, TabsContent, TabsTabForCard } from '../../common/Tabs';
import MatrixTermsTable, { useMatrixTableColumns } from '../Tables/MatrixTermsTable';
import MatrixAuditsTable from '../Tables/MatrixAuditsTable';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';
import AgreementTemplateModal from '../../AgreementTemplates/Modals/AgreementTemplateModal';
import {
    useMasterTermVersionsStore,
    useCountsStore,
    useUsersVisibleToUserStore
} from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import EditMasterTermVersionForm from '../../MasterTermVersion/Forms/EditMasterTermVersionForm';
import { useChoiceListValue } from '../../common/Hooks/useChoiceList';
import AgreementsDashboardCard from '../../Agreement/Dashboard/AgreementsDashboardCard';
import WarningPopover from '../../common/WarningPopover';
import { AddendaTableNoCard } from '../../Addendum/Tables/AddendaTable';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';
import { useDownloadAuditCsv } from '../../Agreement/Table/AgreementAuditsTable';

function useButtons(openAddMasterTermSeriesButton) {
    const matrixId = useMasterTermVersionsStore(state => state.version?.masterTermVersionId);
    const workflow = useMasterTermVersionsStore(state => state.version?.workflow);
    const editable = workflow?.find(f => f.permission === 'ChangeSalesChannel')?.isEnabled;
    const termsChangedFilter = useMasterTermVersionsStore(state => state.termsChangedFilter);
    const setTermsChangedFilter = useMasterTermVersionsStore(state => state.setTermsChangedFilter);
    const downloadAuditCsv = useDownloadAuditCsv();

    const termsChangedFilterButtons = [
        {
            onClick: () => setTermsChangedFilter('all'),
            className: termsChangedFilter === 'all' ? 'active' : '',
            content: 'All'
        },
        {
            onClick: () => setTermsChangedFilter('changed'),
            className: termsChangedFilter === 'changed' ? 'active' : '',
            content: 'Show only changed'
        }
    ];

    return {
        1: [
            {
                content: 'Add Series',
                disabled: !editable,
                icon: 'fa-plus',
                onClick: () => openAddMasterTermSeriesButton('BMW')
            },
            ...termsChangedFilterButtons
        ],
        2: [
            {
                content: 'Add Series',
                disabled: !editable,
                icon: 'fa-plus',
                onClick: () => openAddMasterTermSeriesButton('MINI')
            },
            ...termsChangedFilterButtons
        ],
        5: [downloadAuditCsv(5, `matrix_${matrixId}`)]
    };
}

function useHelp() {
    const columns = useMatrixTableColumns({}, undefined, undefined, undefined, undefined, () => {});

    return {
        1: {
            helpId: 'matrixTermsTable',
            columns
        },
        2: {
            helpId: 'matrixTermsTable',
            columns
        },
        4: {
            helpId: 'addendaTable'
        }
    };
}

function MatrixFormTabTitle({ matrixStatus }) {
    const statusName = useChoiceListValue('masterTermVersionStatus', matrixStatus);
    return `${statusName} Matrix`;
}

const termsErrors = terms =>
    Object.entries(
        terms.reduce(
            (acc, term) => {
                acc.noIvsCodes = acc.noIvsCodes + (!term.ivsCodes || term.ivsCodes.length === 0 ? 1 : 0);
                acc.noDevCode = acc.noDevCode + !term.devCode;
                acc.noDescription = acc.noDescription + (!term.description ? 1 : 0);
                return acc;
            },
            {
                noIvsCodes: 0,
                noDevCode: 0,
                noDescription: 0
            }
        )
    )
        .filter(([key, count]) => count > 0)
        .map(([key, count]) => {
            switch (key) {
                case 'noIvsCodes':
                    return `${count} Terms have no IVS codes`;
                case 'noDevCode':
                    return `${count} Terms have no Dev Code`;
                case 'noDescription':
                    return `${count} Terms have no Descriptions`;
                default:
                    return `${key}, ${count}`;
            }
        });

const AgreementsTabTitle = () => {
    const matrixStatus = useMasterTermVersionsStore(state => state.version?.status);
    const agreementCounts = useCountsStore(state => state.data);
    const agreementsCount = agreementCounts?.reduce((acc, f) => acc + f.count, 0);
    return (
        <span>
            {matrixStatus === 'DRFT' && agreementsCount > 0 ? (
                <WarningPopover bodyText="Agreements exist" className="mr-2" id="agreementsExist" />
            ) : null}
            Agreements
            {agreementsCount > 0 ? <span className="ml-2">({agreementsCount})</span> : null}
        </span>
    );
};

const AddendumTabTitle = () => {
    const data = useAddendaStore(state => state.data);
    return <span>Addendum ({data.length})</span>;
};

function TermsTabTitle({ useTableStore, brand }) {
    const terms = useTableStore(state => state.dataCopy);

    const errors = termsErrors(terms || []);
    return (
        <span>
            {errors?.length > 0 ? (
                <WarningPopover
                    bodyText={
                        <>
                            {errors.map(error => (
                                <div key={error}>{error}</div>
                            ))}
                        </>
                    }
                    className="mr-2"
                    id={`${brand}termsErrorsTabTitle`}
                />
            ) : null}
            {brand}
            {brand ? <span className="ml-2">({terms?.length})</span> : null}
        </span>
    );
}

export default function MatrixPageTabs({ openAddMasterTermSeriesButton }) {
    const buttons = useButtons(openAddMasterTermSeriesButton);
    const help = useHelp();
    const matrixStatus = useMasterTermVersionsStore(state => state.version?.status);
    const agreementCounts = useCountsStore(state => state.data);
    const usersVisibleToUser = useUsersVisibleToUserStore(state => state.data);

    return (
        <>
            <AgreementTemplateModal />
            <TabsInCard buttons={buttons} help={help}>
                <TabsGroup>
                    <TabsTabForCard>
                        <MatrixFormTabTitle matrixStatus={matrixStatus} />
                    </TabsTabForCard>
                    <TabsContent withPadding={true}>
                        <EditMasterTermVersionForm />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>
                        <TermsTabTitle brand="BMW" title="BMW" useTableStore={useBMWStore} />
                    </TabsTabForCard>
                    <TabsContent>
                        <MatrixTermsTable brand="BMW" title="BMW" useTableStore={useBMWStore} />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>
                        <TermsTabTitle brand="MINI" title="MINI" useTableStore={useMiniStore} />
                    </TabsTabForCard>
                    <TabsContent>
                        <MatrixTermsTable brand="MINI" title="MINI" useTableStore={useMiniStore} />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>
                        <AgreementsTabTitle />
                    </TabsTabForCard>
                    <TabsContent>
                        {matrixStatus !== 'DRFT' && (
                            <AgreementsDashboardCard
                                data={agreementCounts}
                                usersVisibleToUser={usersVisibleToUser}
                                viewingMatrixVersion={true}
                            />
                        )}
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>
                        <AddendumTabTitle />
                    </TabsTabForCard>
                    <TabsContent>
                        <AddendaTableNoCard />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>Audit</TabsTabForCard>
                    <TabsContent>
                        <MatrixAuditsTable />
                    </TabsContent>
                </TabsGroup>
            </TabsInCard>
        </>
    );
}
