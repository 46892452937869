import React from 'react';
import { SubSectionTitle } from '../SubSection/SubSectionTitle';
import BaseCard from './BaseCard';

const SimpleCard = props => {
    const { buttons, children, title, className, marginClass, isLoading, style } = props;
    return (
        <BaseCard
            children={children}
            className={className}
            marginClass={marginClass}
            style={style}
            title={
                title ? <SubSectionTitle buttons={buttons} isLoading={isLoading} key="card title" title={title} /> : ''
            }
        />
    );
};

export default SimpleCard;
