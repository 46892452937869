import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { closeModal, FORM_PATHS } from '../../actions/modals';
import { AppModalHeader } from '../common/AppModalHeader';
import ReactiveForm from '../common/ReactiveForm';
import demoDef from './demosDef';

const emptyDemo = {
    id: '',
    demoId: -1
};
Object.keys(demoDef).map(k => (emptyDemo[k] = ''));

const ViewDemoModal = props => {
    const { setModalClosed, isOpen, demo, resources } = props;

    if (!isOpen) {
        return null;
    }

    return (
        <Modal isOpen toggle={() => setModalClosed()} className="modal-lg">
            <AppModalHeader toggle={() => setModalClosed()}>View Demo</AppModalHeader>
            <ModalBody>
                <ReactiveForm initialValues={demo || emptyDemo} resources={resources} hideSaveButton={true}>
                    <ReactiveForm.Section title="Demo Details">
                        <ReactiveForm.Text columnDef={demoDef.bookingRef} disabled />
                        <ReactiveForm.Date columnDef={demoDef.demoFrom} disabled />
                        <ReactiveForm.Date columnDef={demoDef.demoTo} disabled />
                        <ReactiveForm.Text columnDef={demoDef.keyForKey} disabled />
                    </ReactiveForm.Section>
                    <ReactiveForm.Section title="Vehicle Details">
                        <ReactiveForm.Text columnDef={demoDef.brand} disabled />
                        <ReactiveForm.Text columnDef={demoDef.bodyGroupCode} disabled />
                        <ReactiveForm.Text columnDef={demoDef.vehicleDescriptionRequested} disabled />
                        <ReactiveForm.Text columnDef={demoDef.vehicleDescriptionActual} disabled />
                        <ReactiveForm.Text columnDef={demoDef.transmission} disabled />
                        <ReactiveForm.Text columnDef={demoDef.fuelType} disabled />
                        <ReactiveForm.Text columnDef={demoDef.registrationNumber} disabled />
                    </ReactiveForm.Section>
                    <ReactiveForm.Section title="Delivery Address">
                        <ReactiveForm.Text columnDef={demoDef.deliveryAddress1} disabled />
                        <ReactiveForm.Text columnDef={demoDef.deliveryAddress2} disabled />
                        <ReactiveForm.Text columnDef={demoDef.deliveryAddress3} disabled />
                        <ReactiveForm.Text columnDef={demoDef.deliveryAddressCity} disabled />
                        <ReactiveForm.Text columnDef={demoDef.deliveryAddressCounty} disabled />
                        <ReactiveForm.Text columnDef={demoDef.deliveryAddressPostcode} disabled />
                    </ReactiveForm.Section>
                </ReactiveForm>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = state => {
    const {
        isOpen,
        data: { demoId }
    } = state.modals.ViewDemoModal;

    const demo = state.demos.find(x => x.demoId === demoId);

    return {
        isOpen,
        demo,
        resources: state.resources
    };
};

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.ViewDemoModal.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewDemoModal);
