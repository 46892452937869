import { ENDPOINTS, IAction } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';

enum EventAction {
	todo = 'ToDo',
	vacation = 'Vacation'
}

export interface IEvent {
	eventId?: number;
	createdBy: number;
	createdDate: Date;
	updatedBy: number;
	updatedDate: number;
	eventAction: EventAction;
	activityRegarding: string;
	status: string;
	action: string;
	startDate: Date;
	endDate: string;
	details: string;
	assigneeId: number;
	assignee: { id: number; descriptor: string };
}

export default (state: IEvent[] = [], action: IAction) => {
	switch (action.type) {
		case RESET_REDUCER:
			return [];
		case ENDPOINTS.API.PAGE:
			return action.data.eventFilter ?
				update(state, action.data.events) : state;
		case ENDPOINTS.API.EVENT_FILTER:
			return update(state, action.data);

		case ENDPOINTS.API.EVENT_POST:
			return update(state, [action.data]);

		default:
			return state;
	}
};

const update = (state: IEvent[], update: IEvent[]): IEvent[] => [
	...state.filter(stateEvent => !update.some(updateEvent => stateEvent.eventId === updateEvent.eventId)),
	...update
];
