import React from 'react';
import { connect } from 'react-redux';
import { redirectIfNotLoggedIn } from '../../actions/Navigation/redirectIfNotLoggedIn';

class RedirectNotLoggedIn extends React.Component {
    componentDidMount() {
        const { onLoggedIn, onLoggedInDispatchArray = [] } = this.props;
        this.props.redirectIfNotLoggedIn(onLoggedIn, onLoggedInDispatchArray);
    }

    render() {
        return <div />;
    }
}


const mapDispatchToProps = dispatch => ({
    redirectIfNotLoggedIn: (action, dispatchArray) =>
        dispatch(
            redirectIfNotLoggedIn(() => {
                action && action();
                dispatchArray.forEach(func => dispatch(func));
            })
        )
});

export default connect(
    undefined,
    mapDispatchToProps
)(RedirectNotLoggedIn);
