import React, { useMemo } from 'react';
import BaseTableInCard from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import useDimensions from '../../common/Hooks/useDimensions';
import { useAgreementLiveTermsStore, useDownloadLiveTermsCsv } from '../Provider/useAgreementLiveTermsStore';
import agreementLiveTermsDef from '../agreementLiveTermsDef';
import { useGoToAgreementPage } from '../../Agreement/Table/CompanyAgreementsTableCard';
import agreementDef from '../../Agreement/agreementDef';

export default function AgreementLiveTermsTable({ totalDiscount }) {
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const terms = useAgreementLiveTermsStore(state => state.data);
    const columns = [
        agreementLiveTermsDef.agreementReference,
        agreementLiveTermsDef.companyName,
        agreementLiveTermsDef.brand,
        agreementLiveTermsDef.masterTermDescription,
        agreementLiveTermsDef.ivsCode,
        agreementLiveTermsDef.ivsCodeDescription,
        agreementDef.salesChannel,
        agreementDef.matrixBanding,
        agreementLiveTermsDef.isActiveSupportBonus,
        agreementLiveTermsDef.isRebate,
        agreementLiveTermsDef.totalDiscount
    ];

    const downloadLiveTermsCsv = useDownloadLiveTermsCsv();
    const goToPage = useGoToAgreementPage();

    const filteredTerms = useMemo(() => {
        if (totalDiscount) {
            return terms.filter(term => term.totalDiscount === totalDiscount);
        }
        return terms;
    }, [terms, totalDiscount]);

    const [ref, { y }] = useDimensions({ liveMeasure: false });
    return (
        <BaseTableInCard
            cardTitle={`Live Terms (${filteredTerms.length})`}
            columns={columns}
            buttons={[downloadLiveTermsCsv(1)]}
            data={filteredTerms}
            isLoading={isLoading}
            onRowClicked={({ rowData }) => !rowData.groupRow && goToPage({ ...rowData, termsVisible: true })}
            refForTableHeight={ref}
            rowKey={agreementLiveTermsDef.id.field}
            tableSizeStyle={{ height: `calc(100vh - ${y}px - 5rem`, width: '100%' }}
        />
    );
}
