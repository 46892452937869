import _ from 'lodash';
export default [
	{
		order: 0,
		type: 'engageEntityType',
		label: 'Action Type',
		options: [
			{
				order: 0,
				color: 3,
				icon: 'fa-handshake-o',
				label: 'Face to Face meeting',
				value: 'faceToFace',
				matches: e => {
					return e.meeting && e.meeting.meetingMode !== 'Telephone';
				}
			},
			{
				order: 1,
				color: 7,
				icon: 'fa-phone',
				label: 'Telephone meeting',
				value: 'telephone',
				matches: e => {
					return _.get(e, 'meeting.meetingMode', '') === 'Telephone';
				}
			},
			{
				order: 2,
				color: 5,
				icon: 'fa-clock-o',
				label: 'Visit Report Action',
				value: 'visitReportAction',
				matches: e => {
					return !!e.visitAction;
				}
			},
			{
				order: 3,
				color: 1,
				icon: 'fa-plane',
				label: 'Holiday',
				value: 'holiday',
				matches: e => {
					return _.get(e, 'event.activityRegarding', '') === 'CSMAnnualLeave';
				}
			},
			{
				order: 4,
				color: 6,
				icon: 'fa-glass',
				label: 'Event',
				value: 'event',
				matches: e => {
					return _.get(e, 'event.activityRegarding', '') === 'CSMEvent';
				}
			},
			{
				order: 4,
				color: 6,
				icon: 'fa-commenting',
				label: 'Communication',
				value: 'communciation',
				matches: e => {
					return !!e.communication;
				}
			}
		]
	}
];
