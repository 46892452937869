import { makePostRequest } from '../../../../../actions/Api/apiHelpers';
import { toastr } from 'react-redux-toastr';

export default function downloadPdfDebug(e, agreement, nextMatrix, setDownloading, testPdfTemplate) {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }

    if (setDownloading) setDownloading(true);

    makePostRequest(['agreement', 'pdf-debug-data'], undefined, {
        agreementId: agreement.agreementId,
        watermark: 'SAMPLE',
        nextMatrix,
        testPdfTemplate
    })
        .then(d => {
            var blob = new Blob(
                [
                    'Replacement Texts\r\n',
                    '-----------------------------\r\n',
                    'This file shows what values can be replaced in the agreement templates. Use the variable name in the format <name>\r\n\r\n',
                    ...Object.entries(d.data || []).map(([key, value]) => `<${key}> = ${value}\r\n`)
                ],
                { type: 'text/plain;charset=utf-8' }
            );
            if (setDownloading) setDownloading(false);

            window.open(window.URL.createObjectURL(blob), '_blank');
        })
        .catch(err => {
            console.error('error', err);
            toastr.error('Debug Info', err.message);

            if (setDownloading) setDownloading(false);
        });
}
