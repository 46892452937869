import React from 'react';
import CapCodesPageContent from '../../CapCodes/Containers/CapCodesPageContent';

export default function CapCodesPage({
    match: {
        params: { ivscodes }
    }
}) {
    const ivsCodes = ivscodes ? decodeURIComponent(ivscodes) : undefined;
    return <CapCodesPageContent ivsCodes={ivsCodes} />;
}
