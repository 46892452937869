import _ from 'lodash';

export const getDataFromString = (item, key) =>
    _.chain(item[key])
        .replace(/\s/g, '')
        .split('|')
        .without('')
        .value();

export const getWorkflowElement = (id, data, type, source, target, column, color, xAxisPosition) => ({
    id,
    data,
    type,
    source,
    target,
    column,
    color,
    xAxisPosition
});

export const nodeVerticalSeparation = 150;
export const xStartsAt = 300;

//TODO use workflowButtons to apply rules to element colors and positions
export const getWorkflowElements = ({ statusList, workFlowRules, /* workFlowButtons,*/ notificationList }) => {
    const statusWorkflowElements = statusList.map(status =>
        getWorkflowElement(
            status.value,
            { label: status.caption },
            'status',
            null,
            null,
            (status.ordinal - (status.ordinal % 100)) / 100,
            null,
            0
        )
    );

    const { notificationElements, notificationEdgesElements } = notificationList.reduce(
        (acc, value) => {
            const notificationElement = getWorkflowElement(
                `email-${value.agreementNotificationId}`,
                { label: value.agreementNotificationName, id: value.agreementNotificationId },
                'notification',
                value.agreementStatus,
                null,
                6,
                null,
                0
            );
            const notificationEdgeElement = getWorkflowElement(
                `${notificationElement.id}-notification`,
                null,
                'edge',
                notificationElement.source,
                notificationElement.id,
                null,
                null,
                0
            );

            acc.notificationElements.push(notificationElement);
            acc.notificationEdgesElements.push(notificationEdgeElement);

            return acc;
        },
        { notificationElements: [], notificationEdgesElements: [] }
    );

    const { statusChangeElements, edgeElements } = workFlowRules.reduce(
        (acc, value) => {
            if (value['btnStatusTo'] === null) return acc;

            const statusList = getDataFromString(value, 'agreementStatus');

            const btnStatusTo = value['btnStatusTo'];
            const btnTitle = value['btnTitle'];
            const statusChange = `${btnStatusTo}-${btnTitle}`;

            for (const statusKey in statusList) {
                const negativeStatus = _.some(['fail', 'cancel', 'delete', 'terminated', 'reject'], val =>
                    _.includes(btnTitle.toLowerCase(), val)
                );
                const positiveStatus = _.some(['pass', 'approve', 'live'], val =>
                    _.includes(btnTitle.toLowerCase(), val)
                );

                const status = statusList[statusKey];

                acc.statusChangeElements.push(
                    getWorkflowElement(
                        statusChange,
                        { label: btnTitle, differentColumn: false },
                        'statusChange',
                        status,
                        btnStatusTo,
                        null,
                        negativeStatus ? 'danger' : positiveStatus ? 'success' : 'primary',
                        btnTitle.includes('Fail') || btnTitle.includes('Cancel') ? -250 : 250
                    )
                );

                acc.edgeElements.push(
                    getWorkflowElement(
                        `${status}${statusChange}-from`,
                        null,
                        'edge',
                        status,
                        statusChange,
                        null,
                        null,
                        0
                    )
                );

                acc.edgeElements.push(
                    getWorkflowElement(
                        `${status}${statusChange}-to`,
                        null,
                        'edge',
                        statusChange,
                        btnStatusTo,
                        null,
                        null,
                        0
                    )
                );
            }

            return acc;
        },
        { statusChangeElements: [], edgeElements: [] }
    );

    const statusNodes = mapStatusElementsToNodes(statusWorkflowElements);
    const statusChangeNodes = mapStatusChangeElementsToNodes(statusChangeElements, statusNodes);
    const edges = mapEdgeElementsToEdges([...edgeElements, ...notificationEdgesElements]);
    const notificationNodes = mapNotificationElementsToNodes(notificationElements, statusChangeNodes);

    return [statusNodes, statusChangeNodes, edges, notificationNodes];
};

const mapStatusElementsToNodes = elements => {
    const columns = workoutWhichStatusesGoInWhichColumns(_.uniqBy(elements, 'id'));

    return elements.map(element => {
        const mappedElement = {
            ...element,
            position: {
                x: xStartsAt + (250 * element.column - 1),
                y: nodeVerticalSeparation * columns[element.column].indexOf(element.id)
            }
        };
        delete mappedElement.source;
        delete mappedElement.target;
        return mappedElement;
    });
};

const mapStatusChangeElementsToNodes = (statusChangeElements, statusNodes) => {
    return _.uniqBy(statusChangeElements, 'id').map(element => {
        const { x: xFrom, y: yFrom } = statusNodes.find(status => element.source.includes(status.id)).position || {
            x: 0,
            y: 0
        };

        const { x: xTo, y: yTo } = statusNodes.find(status => status.id === element.target).position || { x: 0, y: 0 };

        const x = xFrom === xTo ? xFrom + element['xAxisPosition'] : xFrom - (xFrom - xTo) / 2;
        const y = yFrom - (yFrom - yTo) / 2;

        const mappedElement = {
            ...element,
            position: { x, y },
            data: { ...element.data, color: element.color, target: element.target, source: element.source }
        };

        delete mappedElement.source;
        delete mappedElement.target;

        return mappedElement;
    });
};

const mapEdgeElementsToEdges = elements => {
    return _.uniqBy(elements, 'id').map(element => {
        const type = _.some(['fail', 'cancel'], val => _.includes(element.source.toLowerCase(), val));

        const style = element.id.endsWith('-to')
            ? { strokeWidth: '2px', stroke: 'black' }
            : element.id.endsWith('-notification')
            ? { strokeWidth: '1px', stroke: 'blue' }
            : {};
        return {
            ...element,
            targetHandle: type ? 'a' : 'b',
            animated: true,
            style,
            type: element.source.includes('Fail') ? 'smoothstep' : 'default'
        };
    });
};

const mapNotificationElementsToNodes = (elements, statusChangeNodes) => {
    return elements.map((element, index) => {
        const matchingStatusChanges =
            statusChangeNodes
                .filter(change => change.data.target === element.source)
                .reduce((max, current) => (max > current.position.y ? max : current.position.y), 0) ||
            nodeVerticalSeparation * index;

        const mappedElement = {
            ...element,
            position: {
                x: xStartsAt + (250 * element.column - 1),
                y: matchingStatusChanges + 100
            },
            data: { ...element.data, color: element.color }
        };

        delete mappedElement.source;
        delete mappedElement.target;

        return mappedElement;
    });
};

export function workoutWhichStatusesGoInWhichColumns(statusData) {
    return statusData.reduce((acc, element) => {
        if (acc[element.column]) {
            acc[element.column].push(element.id);
        } else {
            acc[element.column] = [element.id];
        }
        return acc;
    }, {});
}
