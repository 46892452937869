import React from 'react';
import { connect } from 'react-redux';
import PageSection from '../../common/PageSection/';
import { Card, CardBody } from 'reactstrap';
import SupportForm from '../Forms/SupportForm';

class RequestSupportPage extends React.Component {
    componentDidMount() {}

    render() {
        return (
            <PageSection onBackClicked={this.props.history.goBack} title="Request Support">
                <div className="container">
                    <Card>
                        <CardBody>
                            <SupportForm cancel={() => this.props.history.goBack()} />
                        </CardBody>
                    </Card>
                </div>
            </PageSection>
        );
    }
}

const mapStateToProps = state => ({
    people: state.people
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestSupportPage);
