import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import { choiceListsConfig, choiceListAdditions } from '../../../config/choiceLists';
import { API_LOGIN_ENDPOINTS } from '../../../actions/ApiAuth/ApiAuthEndpoints';

const defaultState = {
    choiceList: {
        activityregarding: [],
        status: []
    }
};
const initialState = JSON.parse(localStorage.getItem('reduxPersist:resources')) || defaultState;

export function resources(state = initialState, action) {
    switch (action.type) {
        case API_LOGIN_ENDPOINTS.API.API_AUTH_USER_LOGIN:
            const config = choiceListsConfig();
            const result = action.data.resources.choiceList.reduce((acc, item) => {
                const itemColors = config[item.name];
                acc[item.name] = _.orderBy(
                    item.choices
                        .filter(({ value }) => !_.get(itemColors, `${value}.remove`, false))
                        .map(({ caption: value, value: key, ordinal, ...rest }) => ({
                            ...rest,
                            key,
                            value: value,
                            ordinal,
                            ..._.get(itemColors, `${key}`, {})
                        })),
                    ['ordinal', 'key']
                );
                return acc;
            }, {});

            choiceListAdditions().forEach(addition => {
                const updateTarget = _.get(result, addition.choiceListName, []);
                updateTarget.push(addition);
                result[addition.choiceListName] = _.orderBy(updateTarget, ['ordinal']);
            });

            /*if (process.env.NODE_ENV === 'development') {
                Object.entries(config).forEach(([key, config]) => {
                    if (!result.choiceList[key]) {
                        console.warn(`Section: ${key} exists in choiceList.js but is not returned from Service`);
                    } else {
                        const list = action.data.resources.choiceList[key];
                        Object.entries(config).forEach(([choiceKey]) => {
                            if (list.findIndex(x => x.key === choiceKey) === -1) {
                                console.warn(
                                    `Section: ${key}, Choice:${choiceKey} exists in choiceList.js but is not returned from Service`
                                );
                            }
                        });
                    }
                });
            }*/

            const newResources = {
                ...action.data.resources,
                choiceList: result
            };

            return newResources;
        case RESET_REDUCER:
            return defaultState;
        default:
            return state;
    }
}
