import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export class FormatDate extends React.Component {
    static propTypes = {
        date: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment)]),
        showTime: PropTypes.bool
    };

    renderBlank = () => <span />;

    render() {
        const { date, showTime, fromNow, className } = this.props;

        if (!date) {
            this.renderBlank();
        }

        const momentDate = moment(date);

        if (!momentDate.isValid() || momentDate.isBefore(moment().add(-10, 'years'))) {
            return <span />;
        }

        return (
            <span className={className}>
                {showTime
                    ? momentDate.format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`)
                    : momentDate.format(process.env.REACT_APP_DATE_FORMAT)}
                {fromNow && ` (${momentDate.fromNow()})`}
            </span>
        );
    }
}
