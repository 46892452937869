import React from 'react';
import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import ColumnTypeFormatCompanyName, {
    ColumnTypeFormatCompanyNameFromCompanyId
} from '../common/Table/ColumnTypeFormatCompanyName';

const visitAction = {
    id: idColumnDef('id', 'visitActionId'),
    activityRegarding: {
        ...choiceListColumnDef('Actvity Regarding', 'activityRegarding', 'communicationActivityRegarding'),
        emptyOption: 'Choose an appropriate activity regarding...'
    },
    action: {
        ...choiceListColumnDef('Action', 'action', 'communicationAction'),
        emptyOption: 'Choose an appropriate action...'
    },
    companyNameFromCompanyId: {
        ...basicColumnDef('Company', 'companyName'),
        cellRenderer: props => <ColumnTypeFormatCompanyNameFromCompanyId companyId={props.rowData.companyId} />,
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'companyName',
            helpText: 'The company of the agreement'
        }
    },
    eventType: {
        ...choiceListColumnDef('Event Type', 'eventType', 'communicationAction'),
        emptyOption: 'Choose an appropriate activity regarding...'
    },
    assignee: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee'),
        emptyOption: 'Assign the visit action...'
    },
    companyDescriptor: {
        ...basicColumnDef('Company', 'companyDescriptor'),
        searchable: true
    },
    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        path: 'companyDescriptor',
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        )
    },
    endDate: {
        ...dateColumnDef('Close Date', 'endDate'),
        searchable: true
    },
    notes: {
        ...basicColumnDef('Notes', 'notes')
    },
    startDate: {
        ...dateColumnDef('Due Date', 'startDate'),
        searchable: true
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'communicationStatus'),
        emptyOption: 'Choose the status of the visit report action...'
    },
    subject: {
        ...basicColumnDef('Subject', 'subject')
    },
    details: {
        ...basicColumnDef('Details', 'details')
    }
};

export default visitAction;
