import React from 'react';
import BaseTable, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTable';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { DeleteTableRowButton, ResetTableRowButton, TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';
import useDimensions from '../../common/Hooks/useDimensions';
import { permissions, useCanWrite } from '../../../reducers/Api/User/permissions';
import { useDisableUser, useEngageUsersStore } from '../Provider/useEngageUsersStore';
import UpdateRoleModal from '../Modal/UpdateRoleModal';
import ResetPasswordModal from '../Modal/ResetPasswordModal';
import engageUserDef from '../engageUserDef';
import { useAgreementsAdminUsersVisibilityStore } from '../Provider/AgreementsAdminUsersVisibilityStore';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { choiceListColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';

function ActionsRenderer({ rowData, useTableStore }) {
    const newRowData = useTableStore(state => state.dataState.pageData[rowData.index]);
    const disabled = !useCanWrite(permissions.USER_ADMIN);
    const open = useEngageUsersStore(state => state.openModal);
    const disableUser = useDisableUser();

    const onResetPassword = (e, user) => {
        PreventClickThrough(e);
        open(user, true);
    };

    const onDisableUser = (e, user) => {
        PreventClickThrough(e);
        disableUser(user);
    };

    return (
        <TableButtonsContainer>
            <ResetTableRowButton
                disabled={disabled}
                onClick={e => onResetPassword(e, newRowData)}
                title="Reset Password"
            />
            <DeleteTableRowButton disabled={disabled} onClick={e => onDisableUser(e, newRowData)} />
        </TableButtonsContainer>
    );
}

function AgreementsAdminUsersVisibility({ rowData, userAuthors }) {
    const { seesUserIds } =
        (useAgreementsAdminUsersVisibilityStore(state => state?.data) || []).find(x => x.userId === rowData.userId) ||
        {};
    return <>{seesUserIds ? seesUserIds.map(x => userAuthors.get(x.toString())).join(', ') : 'All'}</>;
}

export default function EngageUserTable() {
    const data = useEngageUsersStore(state => state.data);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const disabled = !useCanWrite(permissions.USER_ADMIN);
    const open = useEngageUsersStore(state => state.openModal);
    const columns = useEngageUserTableColumns();

    const [ref, { y }] = useDimensions({ liveMeasure: false });

    return (
        <div ref={ref} style={{ height: `calc(100vh - ${y}px - 1rem`, width: '100%' }}>
            <UpdateRoleModal />
            <ResetPasswordModal />
            <BaseTable
                cardTitle="Engage Users"
                columns={columns}
                data={data}
                expandAll={true}
                groupBy={[engageUserDef.userType.field]}
                isLoading={isLoading}
                onRowClicked={!disabled ? ({ rowData }) => !rowData.groupRow && open(rowData) : undefined}
                rowKey={engageUserDef.id.field}
            />
        </div>
    );
}

export const usersVisibilityColumnDef = userAuthors => ({
    ...choiceListColumnDef('Users Visibility', 'seesUserIds', 'userAuthors'),
    align: 'left',
    help: {
        helpId: 'usersVisibility',
        helpText: 'Controls the agreements shown in counts on agreements dashboard card.'
    },

    noGrow: false,
    cellRenderer: (props, rest) => <AgreementsAdminUsersVisibility {...props} {...rest} userAuthors={userAuthors} />
});

export function useEngageUserTableColumns() {
    const userAuthors = useChoiceListForBaseTable('userAuthors');
    return [
        engageUserDef.name,
        engageUserDef.email,
        engageUserDef.mobile,
        useChoicelistCellDefinition(engageUserDef.userType),
        usersVisibilityColumnDef(userAuthors),
        engageUserDef.lockedOut,
        {
            field: 'actions',
            title: 'Actions',
            align: 'right',
            width: 90,
            noGrow: true,
            cellRenderer: (props, rest) => <ActionsRenderer {...props} {...rest} />
        }
    ];
}
