import create from 'zustand';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import agreementLiveTermsDef from '../agreementLiveTermsDef';
import React, { useEffect, useRef, useState } from 'react';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { CSVLink } from 'react-csv';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';

const idField = agreementLiveTermsDef.id.field;
export const useAgreementLiveTermsStore = create(set => storeWithModal(set, idField));

export function useDownloadLiveTermsCsv() {
    const terms = useAgreementLiveTermsStore(state => state.data);
    const masterTerms = useMasterTermStore(state => state.data);
    const [data, setData] = useState([]);
    const csvRef = useRef();
    const salesChannels = useChoiceListForBaseTable('salesChannel');
    const matrixBandings = useChoiceListForBaseTable('matrixBanding');

    const getData = () => {
        const masterTermsMap = new Map(masterTerms.map(term => [term.masterTermId, term]));
        const mapped = terms.map(
            ({
                activeSupportBonus,
                agreementId,
                approvalLevel,
                base,
                brand,
                companyName,
                description,
                devCode,
                discretionary,
                isActiveSupportBonus,
                isRebate,
                ivsCode,
                masterTermId,
                matrixBanding,
                modelDescription,
                rebate,
                reference,
                salesChannel,
                series,
                totalDiscount
            }) => {
                const {
                    leasing,
                    neuLeasing,
                    neu,
                    neuDiscretionary,
                    flmDiscretionary,
                    publicSector,
                    totalDiscretionary,
                    specialist,
                    rental
                } = masterTermsMap.get(masterTermId) || {};
                return [
                    agreementId,
                    reference,
                    companyName,
                    brand,
                    series,
                    description,
                    devCode,
                    ivsCode,
                    modelDescription,
                    base,
                    discretionary,
                    activeSupportBonus,
                    rebate,
                    totalDiscount,
                    approvalLevel,
                    salesChannels.get(salesChannel),
                    matrixBandings.get(matrixBanding),
                    isActiveSupportBonus,
                    isRebate,
                    leasing,
                    neuLeasing,
                    neu,
                    neuDiscretionary,
                    flmDiscretionary,
                    publicSector,
                    totalDiscretionary,
                    specialist,
                    rental
                ];
            }
        );
        setData(mapped);
    };

    useEffect(() => {
        if (data.length > 0 && csvRef.current && csvRef.current.link) {
            setTimeout(() => {
                csvRef.current && csvRef.current.link.click();
                setData([]);
            });
        }
    }, [data]);

    return id => ({
        id,
        onClick: getData,
        content: (
            <>
                Download CSV
                {data.length > 0 ? (
                    <CSVLink
                        data={data}
                        filename={`live_terms.csv`}
                        headers={[
                            'Agreement Id',
                            'Reference',
                            'Company Name',
                            'Brand',
                            'Series',
                            'Description',
                            'Dev Code',
                            'IVS Code',
                            'Model Description',
                            'Base',
                            'Discretionary',
                            'Active Support Bonus',
                            'Rebate',
                            'Total Discount',
                            'Approval Level',
                            'Sales Channel',
                            'Matrix Banding',
                            'Is ASB',
                            'Is Rebate',
                            'Matrix Leasing',
                            'Matrix NEU Leasing',
                            'Matrix NEU',
                            'Matrix NEU Discretionary',
                            'Matrix FLM Discretionary',
                            'Matrix Public Sector',
                            'Matrix Total Discretionary',
                            'Matrix Specialist',
                            'Matrix Rental'
                        ]}
                        ref={csvRef}
                    />
                ) : null}
            </>
        ),
        icon: 'fa-arrow-down'
    });
}
