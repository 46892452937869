import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import BaseTable from '../../common/BaseTable/BaseTable';
import useDimensions from '../../common/Hooks/useDimensions';
import { getDate } from '../Components/NotificationFactory';
import { useNotificationStore, useUpdateNotifications } from '../Provider/useNotificationStore';
import notificationDef from '../notificationDef';

function useNotificationsTableColumns() {
    const columns = [
        {
            ...notificationDef.title
        },
        {
            ...notificationDef.subject
        },
        {
            ...notificationDef.message,
            cellRenderer: ({ cellData }) => <div dangerouslySetInnerHTML={{ __html: cellData }} />
        },
        {
            ...notificationDef.status,
            width: 100,
            noGrow: true
        },
        {
            ...notificationDef.link,
            width: 150,
            noGrow: true,
            cellRenderer: ({ cellData }) => <Link to={cellData}>{cellData}</Link>
        },
        {
            ...notificationDef.createdDate,
            width: 100,
            noGrow: true,
            cellRenderer: ({ cellData }) => <div>{moment(cellData).fromNow()}</div>
        }
    ];

    return columns;
}

export default function NotificationTable() {
    const notifications = useNotificationStore(state => state.data);
    const columns = useNotificationsTableColumns();
    const readNotification = useUpdateNotifications();

    const tableData = notifications.map(m => ({
        ...m,
        dayGroup: getDate(m.createdDate)
    }));

    const [ref, { y }] = useDimensions({ liveMeasure: false });
    return (
        <div ref={ref} style={{ height: `calc(100vh - ${y}px - 3rem`, width: '100%' }}>
            <BaseTable
                cardTitle="Notifications"
                columns={columns}
                data={tableData}
                expandAll={true}
                groupBy={['dayGroup']}
                helpId="notificationsTableHeading"
                onRowClicked={({ rowData }) =>
                    !rowData.groupRow && rowData.status === 'CREATED' && readNotification([rowData])
                }
                rowKey={notificationDef.id.field}
            />
        </div>
    );
}
