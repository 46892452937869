import React from 'react';
import PageSection from '../../common/PageSection';
import CompanyPeopleTable from '../../Person/Tables/CompanyPeopleTable';
import CompanyTitle from '../../Company/Components/CompanyTitle';
import CompanyProvider from '../../Company/Provider/CompanyProvider';

export default ({
	match: {
		params: { companyId }
	},
	history
}) => {
	return (<CompanyProvider companyId={parseInt(companyId, 10)}>
		<PageSection onBackClicked={history.goBack} title={<CompanyTitle />}>
			<CompanyPeopleTable title={'Contacts'}/>
		</PageSection>
	</CompanyProvider>);
};
