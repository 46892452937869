import axios from 'axios';
import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makePath } from '../../Api/apiHelpers';
import { push } from 'connected-react-router';
import { API_LOGIN_ENDPOINTS } from '../ApiAuthEndpoints';
import { makeAuthUrl } from '../apiAuthHelpers';

export const apiAuthPasswordReset = (token, password, emailAddress) => async (dispatch, getState) => {
    const type = API_LOGIN_ENDPOINTS.API.API_AUTH_PASSWORD_RESET;

    isLoading(dispatch, type, true, {});
    try {
        const data = { token, password, emailAddress };
        const result = await axios({
            method: 'POST',
            url: makeAuthUrl(getState(), makePath(['auth', 'password', 'reset']), {}),
            headers: { 'Content-Type': 'application/json' },
            data
        });

        //const { data: user } = result;

        isLoading(dispatch, type, false, {});
        //dispatch({ type, data: { emailAddress } });
        toastr.success('Password Updated');
        dispatch(push('/'));
        return result || true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});

        if (error.response.status === 400) {
            toastr.error(error.response.data);
        } else {
            toastr.error(type, error.response.data || `Response ${error.message}`);
        }
        return false;
    }
};
