import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { apiFleetProfileEdit } from '../../../actions/Api/FleetProfile/apiFleetProfileEdit';
import SelectInlineChoicelist from '../../common/InlineEdit/SelectInlineChoicelist';
import EditTextField from '../../common/EditField/EditTextField';
import EditDateField from '../../common/EditField/EditDateField';
import EditFieldDisplayValue from '../../common/EditField/EditFieldDisplayValue';
import { isPropertyHasError, isPropertySyncing } from '../../../reducers/Api/helper';
import CaptionBadge from '../../common/FilteredTable/CaptionBadge';

class FleetProfileItem extends React.Component {
    save = updatedValues => {
        const {
            data: { schema, ...profile },
            save
        } = this.props;

        const updatedFleetProfile = _.cloneDeep(profile);

        Object.keys(updatedValues).forEach(x => {
            updatedFleetProfile[x] = updatedValues[x];
        });
        schema.forEach(section => {
            section.fields
                .filter(field => field.type === 'sum')
                .forEach(sumField => {
                    let newSum = 0;
                    sumField.fields.forEach(x => {
                        newSum += updatedFleetProfile[x];
                    });
                    updatedFleetProfile[sumField.field] = newSum;
                });
        });

        save(updatedFleetProfile);
    };

    saveNew = (field, value, type) => {
        const { data: profile, save } = this.props;
        const updatedFleetProfile = _.cloneDeep(profile);

        const originalValue = profile[field];

        if (type === 'number') {
            value = parseInt(value, 10);
        }

        if (originalValue !== value) {
            updatedFleetProfile[field] = value;
            if (field === 'numberOfCars' || field === 'numberOfLCV') {
                updatedFleetProfile.fleetSize = updatedFleetProfile.numberOfCars + updatedFleetProfile.numberOfLCV;
            }
            save(updatedFleetProfile);
        }
    };

    choiceListLabelMapping = name => {
        switch (this.props.choiceListLabelMapping) {
            case 'trimMonths':
                return value => (value.split(' ').pop() === 'Months' ? value.substring(0, value.length - 6) : value);
            default:
                return x => x;
        }
    };

    renderItem = () => {
        const { label, field, type, readonly, multiple, choiceList, resources, data: profile } = this.props;

        let value = _.get(profile, field, '');
        value = value || value === 0 ? value : '';

        if (readonly) {
            const displayValue =
                type === 'choiceList'
                    ? value
                          .toString()
                          .split(',')
                          .filter(x => x.length > 0)
                          .map(x => <CaptionBadge caption={x} choiceList={choiceList} key={x} resources={resources} />)
                    : value;

            return (
                <EditFieldDisplayValue
                    field={field}
                    id={profile.fleetProfileId}
                    isError={isPropertyHasError(profile, field)}
                    isSyncing={isPropertySyncing(profile, field)}
                    show
                    title={label}
                    type={type === 'sum' ? 'number' : 'text'}
                    value={displayValue}
                />
            );
        }

        switch (type) {
            case 'sum':
            case 'percent':
                return (
                    <EditFieldDisplayValue
                        field={field}
                        id={profile.fleetProfileId}
                        isError={isPropertyHasError(profile, field)}
                        isSyncing={isPropertySyncing(profile, field)}
                        show
                        title={label}
                        type={type === 'sum' ? 'number' : 'text'}
                        value={value}
                    />
                );

            case 'text':
            case 'number':
                return (
                    <EditTextField
                        field={field}
                        id={profile.fleetProfileId}
                        isError={isPropertyHasError(profile, field)}
                        isSyncing={isPropertySyncing(profile, field)}
                        onChanged={this.save}
                        title={label}
                        type={type}
                        value={value}
                    />
                );

            case 'date':
                return (
                    <EditDateField
                        field={field}
                        id={profile.fleetProfileId}
                        isError={isPropertyHasError(profile, field)}
                        isSyncing={isPropertySyncing(profile, field)}
                        onChanged={this.save}
                        title={label}
                        type={type}
                        value={value}
                    />
                );

            case 'choiceList':
                return (
                    <SelectInlineChoicelist
                        choiceListName={choiceList}
                        isError={isPropertyHasError(profile, field)}
                        isSyncing={isPropertySyncing(profile, field)}
                        mapChoiceValues={this.choiceListLabelMapping()}
                        multiple={multiple}
                        objectId={profile.fleetProfileId}
                        propertyName={field}
                        save={this.saveNew}
                        title={label}
                        value={value}
                    />
                );

            default:
                return (
                    <EditFieldDisplayValue
                        isError={isPropertyHasError(profile, field)}
                        isSyncing={isPropertySyncing(profile, field)}
                        onClick={() => {}}
                        show={false}
                        title={label}
                        value={value}
                    />
                );
        }
    };

    render = () => <li className="list-group-item" style={{ minHeight: '45px' }}>{this.renderItem()}</li>;
}

const mapStateToProps = state => ({
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (profile, cancel) => dispatch(apiFleetProfileEdit(profile))
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetProfileItem);
