import axios from 'axios';
import { makeAuthUrl } from './apiAuthHelpers';
import { makePath } from '../Api/apiHelpers';
import { cookieSetExpires, cookieSetToken } from '../../reducers/cookies';
import { SET_MODAL_OPEN, closeModal } from '../modals';

export const apiUserReAuthenticate = (emailAddress, password) => async (dispatch, getState) => {
    try {
        const authResult = await axios({
            method: 'POST',
            url: makeAuthUrl(getState(), makePath(['auth', 'token']), {}),
            headers: { 'Content-Type': 'application/json' },
            data: { emailAddress, password }
        });
        //TODO: Separate endpoint for this as it now brings back stuff not needed for initial log in
        const {
            data: { token: auth }
        } = authResult;
        cookieSetToken(auth.token);
        cookieSetExpires(auth.expires);

        dispatch(closeModal('ExpiredTokenModal'));
    } catch (error) {
        dispatch({
            type: SET_MODAL_OPEN,
            name: 'ExpiredTokenModal',
            isOpen: true,
            data: { error: 'Invalid Password' }
        });
    }
};
