import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import EditVisitActionForm from '../Forms/EditVisitActionForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import { closeModal, FORM_PATHS } from '../../../actions/modals';

class EditVisitActionModal extends React.Component {
    render() {
        const {
            modals: {
                EditVisitActionModal: { isOpen, data }
            },
            setModalClosed
        } = this.props;

        return isOpen ? (
            <Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
                <AppModalHeader toggle={() => setModalClosed()}>
                    {this.props.modals.EditVisitActionModal.data.visitActionId < 0
                        ? 'Create Visit Action'
                        : 'Edit Visit Action'}
                </AppModalHeader>
                <ModalBody>
                    <EditVisitActionForm onCancel={() => setModalClosed()} {...data} />
                </ModalBody>
            </Modal>
        ) : (
            ''
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.EditVisitActionModal.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditVisitActionModal);
