import React from 'react';
import { Link } from 'react-router-dom';
import { MandatoryStar } from '../FormkControls/ValidationProvider';
import { FieldLabel } from './FieldLabel';
import { FieldValue } from './FieldValue';

export const DisplayField = ({
    className,
    expand,
    field,
    fontawesome,
    inline,
    help,
    href,
    indicateIsEmpty,
    large,
    link,
    onClick,
    primary,
    title,
    value
}) => {

    let renderedValue = href ? <a href={href}>{value}</a> : link ? <Link to={link}>{value}</Link> : value;
    return (
        <div className={className} onClick={onClick}>
            <FieldLabel className={inline ? 'd-inline-block' : ''} expand={expand} large={large} help={help}>                
                {title}
                {field && <MandatoryStar fieldName={field} />}
            </FieldLabel>
            <FieldValue className={inline ? 'd-inline-block ml-2' : ''} primary={primary}>
                {fontawesome && <i className={`gradient fa d-inline ${fontawesome} mr-2`} />}
                {renderedValue}
                {indicateIsEmpty && !renderedValue && '---'}
                &nbsp;
            </FieldValue>
        </div>
    );
};
