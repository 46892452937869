import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import BaseTableInCard, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { communicationSelected } from '../../../actions/Navigation/Communication/communicationSelected';
import communication from '../communication';
import { CompanyCommunicationsContext } from '../../Company/Provider/CompanyCommunicationsProvider';
import { ENDPOINTS } from '../../../actions/Api/constants';
import meeting from '../meeting';

function CompanyCommunicationsTable() {
    const { communications } = useContext(CompanyCommunicationsContext);

    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const dispatch = useDispatch();

    const columns = [
        { ...meeting.startDate, filter: true, sortable: true },
        useChoicelistCellDefinition(communication.activityRegarding, { width: 200 }),
        useChoicelistCellDefinition(communication.assigneeId, { width: 200 }),
        useChoicelistCellDefinition(communication.status, { width: 150 })
    ];

    return (
        <BaseTableInCard
            cardTitle={`Communications (${communications.length})`}
            columns={columns}
            data={communications}
            isLoading={isLoading}
            noItemMessage="No Contacts"
            onRowClicked={({ rowData }) => dispatch(communicationSelected(rowData))}
            rowKey={meeting.id.field}
            tableSizeStyle={{ height: 'calc(100vh /5)', width: '100%' }}
        />
    );
}

export default CompanyCommunicationsTable;
