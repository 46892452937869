import { FORM_PATHS, openInModalOrPage } from '../../modals';

export const cancelCommunication = communication => (dispatch, getState) => {
    dispatch(
        openInModalOrPage(
            FORM_PATHS.CancelCommunicationModal,
            communication.communicationId,
            communication,
            communication.startDate
        )
    );
};
