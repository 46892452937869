
import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import { getFieldsNotUpdated, setPropertiesSyncing, setPropertySyncing } from '../helper';
import _ from 'lodash';

const initialState = [];

const propertiesToIgnoreForErrors = [
];

export const getLeasingProfileUpdateErrors = (local, remote) => {
    return getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
};



const updateLeasingProfilePostItem = (state, { data: { leasingProfileId, field, value } }) => {
    const profile = state.find(x => x.leasingProfileId === leasingProfileId);
    profile[field] = value;
    setPropertySyncing(profile, field);

    return [_.clone(profile), ...state.filter(x => x.leasingProfileId === profile.leasingProfileId)];
};

const updateLeasingProfilePost = (state, { data }) => {
    const original = state.find(x => data.leasingProfileId === x.leasingProfileId);
    if (!original) {
        return state;
    }
    setPropertiesSyncing(original, data);
    return [data, ...state.filter(x => data.leasingProfileId !== x.leasingProfileId)];
};

const updateLeasingProfilePostCommit = (state, action) => {
    const {
        payload: { data: remote }
    } = action;
    const local = action.meta.leasingProfile || action.meta.data;
    return [
        remote,
        ...state.filter(x => local.leasingProfileId !== x.leasingProfileId && remote.leasingProfileId !== x.leasingProfileId)
    ];
};

const updateLeasingProfilePostRollback = (state, { meta, payload }) => {
    const rollbackTo = meta.unmodifiedLeasingProfile;
    rollbackTo.syncing = false;
    rollbackTo.error = payload.message;
    return [_.clone(rollbackTo), ...state.filter(x => x.leasingProfileId === rollbackTo.leasingProfileId)];
};


const updateCompany = (state, { data }) => {
    data.profile.companyId = data.companyId;
    return [data.profile, ...state.filter(x => data.profile.leasingProfileId !== x.leasingProfileId)];
};

function leasingProfiles(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.PAGE:
            return action.data.leasingProfileFilter
                ? [
                    ...action.data.leasingProfile,
                    ...state.filter(x => action.data.leasingProfileFilter.companyId !== x.companyId)
                ]
                : state;
        /*case ENDPOINTS.API.COMPANY:
                   
           return updateCompany(state, action);*/
        case ENDPOINTS.API.COMPANY_OFFLINE_COMMIT:
            return updateCompany(state, action);
        case ENDPOINTS.API.LEASING_PROFILE_POST:
            return updateLeasingProfilePost(state, action);
        case ENDPOINTS.API.LEASING_PROFILE_POST_COMMIT:
            return updateLeasingProfilePostCommit(state, action);
        case ENDPOINTS.API.LEASING_PROFILE_POST_ROLLBACK:
            return updateLeasingProfilePostRollback(state, action);
        case ENDPOINTS.API.LEASING_PROFILE_POST_ITEM:
            return updateLeasingProfilePostItem(state, action);
        default:
            return state;
    }
}


export default leasingProfiles;
