import { apiFleetProfileEdit } from '../FleetProfile/apiFleetProfileEdit';
import { ENDPOINTS } from '../constants';
import { CLEAR_ERROR } from '../../../reducers/Api/Errors/errors';
import { apiPersonEditOffline } from '../Person/apiPersonEdit';
import { apiLeasingProfileEdit } from '../LeasingProfile/apiLeasingProfileEdit';

export const retryErrors = () => async (dispatch, getState) => {
    getState()
        .errors.filter(error => error.responseCode === 401)
        .forEach(error => {
            switch (error.action.type) {
                case ENDPOINTS.API.FLEET_PROFILE_POST_ROLLBACK:
                    dispatch({ type: CLEAR_ERROR, id: error.id });
                    dispatch(
                        apiFleetProfileEdit(error.action.meta.fleetProfile, error.action.meta.unmodifiedFleetProfile)
                    );
                    break;
                case ENDPOINTS.API.PERSON_POST_ROLLBACK:
                    dispatch({ type: CLEAR_ERROR, id: error.id });
                    dispatch(apiPersonEditOffline(error.action.meta.person, error.action.meta.unmodifiedPerson));
                    break;
                case ENDPOINTS.API.LEASING_PROFILE_POST_ROLLBACK:
                    dispatch({ type: CLEAR_ERROR, id: error.id });
                    dispatch(
                        apiLeasingProfileEdit(error.action.meta.leasingProfile, error.action.meta.unmodifiedLeasingProfile)
                    );
                    break;
                default:
                    break;
            }
        });
};
