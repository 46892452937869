import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, ModalBody, InputGroup, InputGroupAddon, Alert, Input, Button } from 'reactstrap';
import { AppModalHeader } from '../common/AppModalHeader';
import { apiUserReAuthenticate } from '../../actions/ApiAuth/apiUserReAuthenticate';

class ExpiredTokenModal extends React.Component {
    state = {
        password: process.env.REACT_APP_DEFAULT_PASSWORD || ''
    };

    reAuthClick() {
        const { emailAddress, reAuth } = this.props;
        const { password } = this.state;

        reAuth(emailAddress, password);
        this.setState({ password: '' });
    }

    render() {
        const { isOpen, error } = this.props;
        return isOpen ? (
            <Modal isOpen>
                <AppModalHeader hideCloseButton>Your Session Has Timed Out</AppModalHeader>
                <ModalBody>
                    <p>Your credentials have expired. Please re-enter your password to continue.</p>
                    <InputGroup>
                        <Input
                            className={error ? 'is-invalid' : ''}
                            type="password"
                            value={this.state.password}
                            onChange={({ target: { value } }) => this.setState({ password: value })}
                            onKeyPress={({ key }) => key === 'Enter' && this.reAuthClick()}
                        />
                        <InputGroupAddon addonType="append">
                            <Button
                                block
                                color="primary"
                                onClick={() => this.reAuthClick()}
                                disabled={!this.state.password}
                            >
                                Re-Authenticate
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>

                    {error && (
                        <Alert className="mt-3 mb-0" color="danger">
                            {error}
                        </Alert>
                    )}
                </ModalBody>
            </Modal>
        ) : (
            ''
        );
    }
}

const mapStateToProps = state => ({
    emailAddress: state.user.userName,
    isOpen: _.get(state, 'modals.ExpiredTokenModal.isOpen', false),
    error: _.get(state, 'modals.ExpiredTokenModal.data.error', null)
});

const mapDispatchToProps = dispatch => ({
    reAuth: (email, pass) => dispatch(apiUserReAuthenticate(email, pass))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpiredTokenModal);
