import React from 'react';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm/index';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../Provider/storeStates';
import { useLeasingCompaniesStore, useUpdateLeasingCompanies } from '../Provider/useLeasingCompaniesStore';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useChoiceList } from '../../common/Hooks/useChoiceList';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    leasingCompanyId: Yup.number().required('Company is required.')
});

export default function LeasingCompanyForm() {
    const agreementCompanyId = useAgreementsStore(state => state.agreement?.companyId);
    const resources = useResources();
    const leasingCompanies = useChoiceList('leasingCompany');
    const usedCompanies = useLeasingCompaniesStore(state => state.data);
    const availableCompanies = leasingCompanies
        .filter(f => !usedCompanies.some(s => s.leasingCompanyId === parseInt(f.key)))
        .map(m => ({
            key: parseInt(m.key),
            value: m.value
        }));
    const cancel = useLeasingCompaniesStore(state => state.closeModal);
    const saving = useLeasingCompaniesStore(state => state.storeState) === storeStates.saving;
    const updateLeasingCompanies = useUpdateLeasingCompanies(agreementCompanyId);

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={leasingCompany => updateLeasingCompanies([leasingCompany])}
            initialValues={{ companyId: agreementCompanyId }}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Choicelist
                    columnDef={{ ...basicColumnDef('Company', 'leasingCompanyId') }}
                    options={availableCompanies}
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
