import React from 'react';
import { FormatDate } from '../FormatDate';
import SyncingOrErrorIndicator from '../../common/SyncingOrErrorIndicator';

const ColumnTypeDate = ({ showSync, values, field }) =>
    showSync ? (
        <div>
            <SyncingOrErrorIndicator isSyncing={values.syncing} isError={values.error} />
            <FormatDate date={values[field]} />
        </div>
    ) : (
        <FormatDate date={values[field]} />
    );

export default ColumnTypeDate;
