import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

export default function ReactiveFormDropzone({
    accept,
    entity,
    field,
    multiple = true,
    s3Bucket,
    setFieldValue,
    title,
    values
}) {
    const [files, setFiles] = useState(() => {
        const initialFiles = multiple ? [...(values[field] || [])] : [values[field]];

        return initialFiles.map(file => ({
            ...file,
            name: file?.fileName?.substring(file?.fileName?.indexOf('_') + 1, file?.fileName?.length)
        }));
    });
    let currentFiles = multiple ? [...(values[field] || [])] : { ...(values[field] || {}) };

    const onDrop = files => {
        setFieldValue(field, multiple ? [] : {}, false);

        files.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                const contentAsBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
                const fileWithData = {
                    s3Bucket,
                    ...(entity || {}),
                    fileName: file.name,
                    type: file.type,
                    contentAsBase64
                };

                if (multiple) currentFiles.push(fileWithData);
                else currentFiles = fileWithData;

                setFieldValue(field, currentFiles, false);
            };

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsDataURL(file);
        });

        setFiles(files);
    };

    const renderFiles = text => (
        <div>
            {files.length === 0 && text}
            <ul>
                {files.map(f => (
                    <li key={f?.name}>
                        {f?.name}
                        {f?.size ? `- ${f?.size} bytes` : null}
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <>
            <Dropzone
                accept={accept}
                className="w-100 mt-4 mb-4 border border-primary hover-pointer bg-light p-2"
                multiple={multiple}
                onDrop={onDrop}
                style={{ minHeight: '100px' }}
            >
                {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                    if (isDragActive) {
                        return 'This file is authorized';
                    }
                    if (isDragReject) {
                        return 'This file is not authorized';
                    }
                    return acceptedFiles.length || rejectedFiles.length
                        ? renderFiles(`Accepted ${acceptedFiles.length}, rejected ${rejectedFiles.length} files`)
                        : renderFiles(title);
                }}
            </Dropzone>
        </>
    );
}
