import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import AgreementNotificationPeopleForm from '../Forms/AgreementNotificationPeopleForm';
import { useAgreementNotificationPeopleStore } from '../Provider/useAgreementNotificationPeopleStore';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';

export default function AgreementNotificationPeopleModal() {
    const isOpen = useAgreementNotificationPeopleStore(state => state.modalOpen);
    const selectedNotification = useAgreementNotificationPeopleStore(state => state.selected);
    const close = useAgreementNotificationPeopleStore(state => state.closeModal);
    const peopleList = useChoiceListForBaseTable('userAuthors');
    const person = selectedNotification && peopleList.get(selectedNotification.personId);

    return (
        <Modal className="modal-md" isOpen={isOpen} toggle={close}>
            <AppModalHeader toggle={close}>{person ? `Edit ${person}` : 'Add Notification Person'}</AppModalHeader>
            <ModalBody>
                <AgreementNotificationPeopleForm />
            </ModalBody>
        </Modal>
    );
}
