import { FORM_PATHS, openInModalOrPage } from '../../modals';

export const editVisitAction = visitAction => (dispatch, getState) => {
    dispatch(
        openInModalOrPage(
            FORM_PATHS.EditVisitActionModal,
            visitAction.visitActionId,
            visitAction,
            visitAction.startDate
        )
    );
};
