import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import BaseTable, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTable';
import { CompanyDemosContext } from '../../Company/Provider/CompanyDemosProvider';
import demosDef from '../demosDef';
import { FORM_PATHS, openInModalOrPage } from '../../../actions/modals';

function CompanyDemosTable() {
    const dispatch = useDispatch();
    const { demos, isLoading } = useContext(CompanyDemosContext);
    const columns = [
        { ...demosDef.bookingRef, width: 150 },
        demosDef.vehicleDescriptionActual,
        demosDef.demoFrom,
        demosDef.demoTo,
        useChoicelistCellDefinition(demosDef.status, { width: 150 })
    ];
    return (
        <div style={{ height: 'calc(100vh /2)', width: '100%' }}>
            <BaseTable
                columns={columns}
                data={demos}
                isLoading={isLoading}
                onRowClicked={({ rowData }) => dispatch(openInModalOrPage(FORM_PATHS.ViewDemoModal, rowData.demoId))}
                rowKey={demosDef.id.field}
                tableSizeStyle={{ height: 'calc(100vh /2)', width: '100%' }}
            />
        </div>
    );
}

export default CompanyDemosTable;
