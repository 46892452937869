import { ENDPOINTS } from '../constants';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { getAxiosPostRequest, getRestFormatDateTimeNow, offlinePost } from '../apiHelpers';
import { closeModal, FORM_PATHS } from '../../modals';

export const apiPersonEditOffline = (person, originalPerson) => async (dispatch, getState) =>
    dispatch(
        offlinePost(
            ENDPOINTS.API.PERSON_POST,
            ENDPOINTS.API.PERSON_POST_COMMIT,
            ENDPOINTS.API.PERSON_POST_ROLLBACK,
            person,
            getUnmodified(person, getState, originalPerson),
            getPostData(person),
            person.personId,
            ['person'],
            {}
        )
    );

export const apiPersonEdit = person => dispatch => {
    if (person.personId < 0) {
        person.personId = null;
    }
    return axios(getAxiosPostRequest(['person'], undefined, person))
        .then(({ data }) => {
            toastr.success('Update Person', 'Success');
            dispatch(closeModal(FORM_PATHS.EditPerson.modalName, data));
            return data;
        })
        .catch(err => {
            toastr.error('Update Person', `Response ${err.message}`);
            throw err;
        });
};

const getUnmodified = (person, getState, originalPerson) => {
    let unmodifiedPerson = getState().people.find(x => person.personId === x.personId);
    if (!unmodifiedPerson) {
        unmodifiedPerson = originalPerson || { ...person };
    }
    unmodifiedPerson.error = undefined;
    return unmodifiedPerson;
};

const getPostData = person => {
    const data = { ...person };
    if (data.personId < 0) {
        data.personId = null;
        data.createdDate = getRestFormatDateTimeNow();
    }
    data.updatedDate = getRestFormatDateTimeNow();
    return data;
};
