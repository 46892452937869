import create from 'zustand';
import { useDispatch } from 'react-redux';
import { apiLeasingCompaniesPost } from '../../../actions/Api/Company/apiCompanyPost';
import { storeWithModal } from './storeStates';
import leasingCompanyDef from '../leasingCompanyDef';
import { useAgreementsStore } from './useAgreementsStore';

const rowKey = leasingCompanyDef.id.field;
export const useLeasingCompaniesStore = create(set => storeWithModal(set, rowKey));

export function useUpdateLeasingCompanies() {
    const updateLeasingCompaniesFromFetch = useLeasingCompaniesStore(state => state.updateDataFromFetch);
    const agreementId = useAgreementsStore(state => state.agreement.agreementId);
    const setSaving = useLeasingCompaniesStore(state => state.setSaving);
    const setError = useLeasingCompaniesStore(state => state.setError);
    const dispatch = useDispatch();

    return leasingCompanies => {
        setSaving();
        return dispatch(apiLeasingCompaniesPost(leasingCompanies, agreementId))
            .then(response => {
                updateLeasingCompaniesFromFetch(response || []);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
