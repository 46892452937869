import React from 'react';
import GdprPreference from './GdprPreference';

const prefTypes = {
    phone: { descriptor: 'telephone', fa: 'fa-phone' },
    email: { descriptor: 'email', fa: 'fa-at' },
    sms: { descriptor: 'sms', fa: 'fa-mobile' },
    mail: { descriptor: 'direct mail', fa: 'fa-envelope' }
};

const GdprOverview = props => {
    const {
        person: { gdprTelephone, gdprEmail, gdprSms, gdprDirectMail }
    } = props;

    return (
        <div
            style={{
                minWidth: '120px'
            }}
        >
            <GdprPreference type={prefTypes.email} permitted={gdprEmail === 'Optin'} />
            <GdprPreference type={prefTypes.phone} permitted={gdprTelephone === 'Optin'} />
            <GdprPreference type={prefTypes.mail} permitted={gdprDirectMail === 'Optin'} />
            <GdprPreference type={prefTypes.sms} permitted={gdprSms === 'Optin'} />
        </div>
    );
};

export default GdprOverview;
