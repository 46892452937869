import React, { useEffect, useState } from 'react';
import BaseTableInCard, {
    BaseTableInCardContentRight,
    EditablePercentage
} from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import WarningPopover from '../../common/WarningPopover';
import { ENDPOINTS } from '../../../actions/Api/constants';
import TermExclusionsModal, { useTermExclusionsModalStore } from '../Modals/TermExclusionsModal';
import { useTermsTableColumns } from './useTermsTableColumns';
import { useUpdateTerms } from '../Provider/useUpdateTerms';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { agreementPermissions, getPermission } from '../agreementPermissions';

function useFilter() {
    const [filter, setFilter] = useState('all');
    return {
        filter,
        setFilter
    };
}

function useTableUpdateFunctions(useTableStore, brand) {
    const setSaving = useTableStore(state => state.setSaving);
    const setModalOpen = useTermExclusionsModalStore(state => state.setModalOpen);
    const workflow = useAgreementsStore(state => state.agreement?.workflow);
    const agreementRevision = useAgreementsStore(state => state.agreement?.revision);
    const updateTerms = useUpdateTerms();
    const updateRows = useTableStore(state => state.updateRows);
    const setTermsData = useTableStore(state => state.setTermsData);

    const createExceptionsPermission = getPermission(agreementPermissions.createExceptions, workflow);
    const createAddendumExceptionsPermission = getPermission(agreementPermissions.createAddendumExceptions, workflow);

    return {
        onUpdate: (colKey, id, value) => {
            const terms = useTableStore.getState().dataCopy;
            setSaving(true);
            return updateTerms(
                terms
                    .filter(term => term.id === id)
                    .map(term => {
                        const newTerm = { ...term };
                        newTerm[colKey] = parseFloat(value);
                        return newTerm;
                    })
            ).then(d => {
                setSaving(false);
                updateRows(d);
                return d;
            }).catch(() => {
                setSaving(false);
            });
        },
        onUpdateMultiple: (colKey, ids, value) => {
            const terms = useTableStore.getState().dataCopy;
            setSaving(true);
            return updateTerms(
                terms
                    .filter(term => ids.has(term.id))
                    .map(term => {
                        const newTerm = { ...term };
                        newTerm[colKey] = parseFloat(value);
                        return newTerm;
                    })
            ).then(d => {
                setSaving(false);
                updateRows(d);
            }).catch(() => {
                setSaving(false);
            });
        },
        onAddExclusions:
            createExceptionsPermission.isEnabled || createAddendumExceptionsPermission.isEnabled
                ? term => {
                      setModalOpen(useTableStore, term);
                  }
                : null,
        onRemoveExclusion:
            createExceptionsPermission.isEnabled || createAddendumExceptionsPermission.isEnabled
                ? term => {
                      return updateTerms(
                          createAddendumExceptionsPermission.isEnabled && agreementRevision === term.revision
                              ? [
                                    {
                                        ...term,
                                        nextDeleted: true //TODO: Should only be next deleted if earlier revision
                                    }
                                ]
                              : [
                                    {
                                        ...term,
                                        deleted: 1
                                    }
                                ]
                      ).then(d => setTermsData([], new Set([term.id])));
                  }
                : null
    };
}

const ContentRight = ({ filter, setFilter }) => {
    const buttons = [];
    const options = {
        all: 'All',
        requiresAuthorisation: 'Requires Authorisation'
    };
    return (
        <BaseTableInCardContentRight
            buttons={buttons}
            toggles={[
                {
                    id: 1,
                    option: filter,
                    options,
                    selectOption: setFilter
                }
            ]}
        />
    );
};

function TableTitle({ isLoading, title }) {
    const addendumId = useAgreementsStore(state => state.agreement?.addendumId);
    return (
        <>
            <WarningPopover
                bodyText="Changing terms while on a mass addendum will mean the addendum will have to be processed manually"
                id="addendumTermsWarning"
                visible={!isLoading && addendumId}
            />
            {title}
        </>
    );
}

export default function TermsTable({ title, brand, useTableStore }) {
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const { filter, setFilter } = useFilter();
    const onExternalFilter = useTableStore(state => state.onExternalFilter);

    const rowKey = 'id';

    const { onUpdateMultiple, onAddExclusions, onRemoveExclusion, onUpdate } = useTableUpdateFunctions(
        useTableStore,
        brand
    );

    const columns = useTermsTableColumns(onUpdateMultiple, onAddExclusions, onRemoveExclusion);
    const someEnabled = columns.some(e => e.editable);

    useEffect(() => {
        onExternalFilter(
            x => filter === 'all' || x.totalDiscount > x.approvalLimitFinance || x.totalDiscount > x.approvalLimit
        );
    }, [filter, onExternalFilter]);

    return (
        <>
            <TermExclusionsModal useTableStore={useTableStore} />
            <BaseTableInCard
                autoHeight={true}
                cardTitle={<TableTitle isLoading={isLoading} title={title} />}
                columns={columns}
                groupBy={['series']}
                headerEditRenderer={({ dataKey, title }, { useTableStore, selectedRowKeys }) => (
                    <EditablePercentage
                        dataKey={dataKey}
                        editable={true}
                        inputOptions={{ placeholder: `${title} %` }}
                        onUpdate={(key, value) => onUpdateMultiple(key, selectedRowKeys, value)}
                        useTableStore={useTableStore}
                    />
                )}
                helpId="termsTableHeading"
                isLoading={isLoading}
                onUpdate={onUpdate}
                rowClassName={({ ivsCodeId }) => (ivsCodeId ? 'bg-orange' : '')}
                rowKey={rowKey}
                selectable={someEnabled}
                tableSizeStyle={{ width: '100%' }}
                titleContentRight={<ContentRight filter={filter} setFilter={setFilter} />}
                useStore={useTableStore}
            />
        </>
    );
}
