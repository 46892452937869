import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Label } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { STATUS } from './Notification';

const RadioCheckbox = ({ checked }) => {
    const [style, updateStyle] = useState({
        container: { top: 9, right: 0, position: 'absolute' }
    });

    useEffect(
        () =>
            updateStyle({
                container: { top: 9, right: 0, position: 'absolute' },
                checkbox: checked ? {} : { backgroundColor: '#fff' }
            }),
        [checked]
    );

    return (
        <Label className="checkbox-container" style={style.container}>
            <Label className="checkbox-container" style={style.container}>
                <span className="round-checkbox-large" style={style.checkbox} />
            </Label>
            <span className="round-checkbox-small" style={style.checkbox} />
        </Label>
    );
};

const Table = ({ data, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <table>
            {React.Children.map(children, child => React.cloneElement(child, { ...data, isOpen, setIsOpen, toggle }))}
        </table>
    );
};

Table.Header = props => {
    const { subject, title, message, status, isOpen, toggle } = props;

    return (
        <thead>
            <tr>
                <th>
                    <div
                        style={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 1.428,
                            textDecorationSkipInk: 'auto',
                            fontFamily:
                                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'
                        }}
                    >
                        {subject}
                    </div>
                    <div
                        onClick={toggle}
                        onKeyPress={toggle}
                        role="button"
                        style={{
                            color: '#7a869a',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 1.428,
                            textDecorationSkipInk: 'auto'
                        }}
                        tabIndex="0"
                    >
                        {isOpen ? (
                            <>
                                Read Less <i className="fa fa-arrow-circle-o-up" />
                            </>
                        ) : (
                            <>
                                Read More <i className="fa fa-arrow-circle-o-down" />
                            </>
                        )}
                        <Collapse isOpen={isOpen}>
                            Agreement Entity:
                            <br />
                            <ul>
                                <li>{title}</li>
                            </ul>
                            <p>{message}</p>
                        </Collapse>
                    </div>
                </th>
                <th>
                    <RadioCheckbox checked={status === STATUS.CREATED} />
                </th>
            </tr>
        </thead>
    );
};

Table.Body = props => {
    const { createdDate, link } = props;

    return (
        <tbody>
            <tr>
                <td>
                    <Link onClick={e => e.stopPropagation()} to={link}>
                        {_.get(window, 'location.hostname', '') + link}
                    </Link>
                    <div style={{ color: '#7a869a', fontWeight: 400 }}>{moment(createdDate).fromNow()}</div>
                </td>
            </tr>
        </tbody>
    );
};

export default Table;
