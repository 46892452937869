import { useEngageRolePermissionsStore, useUpdateRolePermission } from '../Provider/useEngageRolePermissionsStore';
import { useEngageRolesStore } from '../Provider/useEngageRolesStore';
import React from 'react';
import { permissions, useUsersPermission } from '../../../reducers/Api/User/permissions';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { Button } from 'reactstrap';
import BaseTable from '../../common/BaseTable/BaseTable';
import useDimensions from '../../common/Hooks/useDimensions';

function PermissionButton({ disabled, rolePermission, title, field, icon, updateRolePermission, isButton }) {
    const hasPermission = rolePermission[field];
    return isButton ? (
        <Button
            className={`mr-2 bg-white-not-hover btn-outline-${
                disabled ? 'muted' : hasPermission ? 'success' : 'danger'
            }`}
            disabled={disabled}
            onClick={() => {
                const update = { ...rolePermission };
                update[field] = !hasPermission;
                updateRolePermission(update);
            }}
            title={title}
        >
            <i
                className={`fa ${icon} ${disabled ? 'text-muted' : hasPermission ? 'text-success' : 'text-danger'}`}
                style={{ fontSize: '1.3rem' }}
            />
        </Button>
    ) : (
        <i
            className={`fa ${icon} fa-3x mx-2 my-2 ${
                disabled ? 'text-muted' : hasPermission ? 'text-success' : 'text-danger'
            }`}
            style={{ fontSize: '1.3rem' }}
        />
    );
}

function RolePermissionCell({
    roleId,
    updateRolePermission,
    permissionId,
    hasCreatePermission,
    hasReadPermission,
    hasWritePermission
}) {
    const rolePermissionsData = useEngageRolePermissionsStore(state => state.data);
    const rolePermission = rolePermissionsData.find(rp => rp.permissionId === permissionId && rp.roleId === roleId) || {
        canRead: false,
        canWrite: false,
        canCreate: false,
        roleId,
        permissionId
    };
    const canWrite = useUsersPermission(permissions.USER_ADMIN).canWrite;

    return (
        <div>
            <PermissionButton
                disabled={!hasReadPermission}
                field="canRead"
                icon="fa-glasses"
                isButton={canWrite}
                rolePermission={rolePermission}
                title="Can Read"
                updateRolePermission={updateRolePermission}
            />
            <PermissionButton
                disabled={!hasWritePermission}
                field="canWrite"
                icon="fa-pencil"
                isButton={canWrite}
                rolePermission={rolePermission}
                title="Can Write"
                updateRolePermission={updateRolePermission}
            />
            <PermissionButton
                disabled={!hasCreatePermission}
                field="canCreate"
                icon="fa-plus"
                isButton={canWrite}
                rolePermission={rolePermission}
                title="Can Create"
                updateRolePermission={updateRolePermission}
            />
        </div>
    );
}

export default function EngageRoles() {
    const rolesData = useEngageRolesStore(state => state.data);
    const updateRolePermission = useUpdateRolePermission();
    const columns = [
        {
            ...basicColumnDef('Name', 'description')
        },
        ...rolesData.map(role => ({
            ...basicColumnDef(role.name, role.roleId),
            cellRenderer: ({ rowData: { id, hasCreatePermission, hasReadPermission, hasWritePermission } }) => (
                <RolePermissionCell
                    hasCreatePermission={hasCreatePermission}
                    hasReadPermission={hasReadPermission}
                    hasWritePermission={hasWritePermission}
                    permissionId={id}
                    roleId={role.roleId}
                    updateRolePermission={updateRolePermission}
                />
            )
        }))
    ];

    const [ref, { y }] = useDimensions({ liveMeasure: false });

    //TODO: Convert to table
    return (
        <div ref={ref} style={{ height: `calc(100vh - ${y}px - 1rem`, width: '100%' }}>
            <BaseTable
                columns={columns}
                data={Object.values(permissions)}
                expandAll={true}
                groupBy={['group']}
                isLoading={false}
                onRowClicked={undefined}
                rowKey="id"
            />
        </div>
    );
}
