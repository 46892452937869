import React from 'react';
import { Alert } from 'reactstrap';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm/index';
import { FORM_NAMES } from '../../../actions/formNames';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useResources } from '../../../selectors/Api/useResources';
import { useChoiceListObject } from '../../common/Hooks/useChoiceList';
import { useMasterTermVersionsStore, useUpdateMasterTermVersionStatus } from '../Provider/useMasterTermVersionsStore';
import storeStates from '../../Agreement/Provider/storeStates';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    comment: Yup.string().required('Reason is required.')
});

const evidenceMandatoryValidationSchema = Yup.object().shape({
    comment: Yup.string().required('Reason is required.'),
    evidence: Yup.array().required('Evidence is required.')
});

export default function AddendumStatusChangeReason({
    statusFrom,
    statusTo,
    cancel,
    buttonText,
    evidenceMandatory,
    helpText
}) {
    const version = useMasterTermVersionsStore(state => state.version);
    const saving = useMasterTermVersionsStore(state => state.storeState) === storeStates.saving;
    const updateMasterTermVersionStatus = useUpdateMasterTermVersionStatus();
    const versionStatuses = useChoiceListObject('masterTermVersionStatus');
    const resources = useResources();
    const statusFromText = versionStatuses[statusFrom];
    const statusToText = versionStatuses[statusTo];

    const initialValues = {
        masterTermVersionId: version.masterTermVersionId,
        comment: ''
    };

    const entity = {
        entityId: version.masterTermVersionId,
        entityName: 'MasterTermVersion',
        description: `Master Term Version Status Change from: ${statusFromText} to ${statusToText}`
    };

    const save = form => {
        updateMasterTermVersionStatus(form, statusFrom, statusTo, cancel);
    };

    return (
        <>
            {helpText ? <Alert color="info">{helpText}</Alert> : null}
            <ReactiveForm
                cancel={cancel}
                enableReinitialize={true}
                formName={FORM_NAME}
                handleSubmit={save}
                hideMetaData={true}
                initialValues={initialValues}
                isSaving={saving}
                resources={resources}
                saveButtonContent={buttonText}
                validationSchema={evidenceMandatory ? evidenceMandatoryValidationSchema : validationSchema}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Text columnDef={{ ...basicColumnDef('Comments', 'comment') }} />
                    <ReactiveForm.Dropzone columnDef={{ ...basicColumnDef('Evidence', 'evidence') }} entity={entity} />
                </ReactiveForm.Section>
            </ReactiveForm>
        </>
    );
}
