import React from 'react';
import { AuditChangesCellRenderer } from '../common/BaseTable/CusomCellRenderers';
import { basicColumnDef, choiceListColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: idColumnDef('Id', 'auditId'),
    changes: {
        ...basicColumnDef('Changes', 'changes'),
        type: 'text',
        cellRenderer: props => <AuditChangesCellRenderer {...props} />
    },
    comments: {
        ...basicColumnDef('Comments', 'comments'),
        type: 'text'
    },
    createdBy: {
        ...choiceListColumnDef('Created', 'createdBy', 'userAuthors'),
        type: 'text',
        sortable: true
    }
};
