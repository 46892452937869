import React from 'react';
import CardDropDownButton from '../../common/Buttons/CardDropDownButton';
import SimpleCard from '../../common/Card/SimpleCard';
import person from '../person';
import { ConnectedFieldDetails } from '../../common/Table/addColumnsToRow';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';

function RenderButtons({ companyId }) {
    const onViewCompanyPeople = useSafeDispatchPush(() => ['company', companyId, 'people']);
    return (
        <CardDropDownButton
            items={[
                // {
                //     text: 'Add Person',
                //     fontAwesomeName: 'fa-plus',
                //     onClick: () => createPerson(companyId)
                // },
                {
                    text: 'View All',
                    fontAwesomeName: 'fa-th-list',
                    onClick: () => onViewCompanyPeople(companyId)
                }
            ]}
        />
    );
}

export default function PersonDetails({
    person: personObj,
    fields,
    title,
    companyId,
    isLoading,
    children,
    buttons,
    marginClass
}) {
    if (personObj) {
        personObj.descriptorWithSalutation = `${personObj.salutation} ${personObj.descriptor}`;
    }
    return (
        <SimpleCard
            buttons={buttons || <RenderButtons companyId={companyId} />}
            isLoading={isLoading}
            key={personObj ? personObj.personId : '0'}
            marginClass={marginClass}
            title={title}
        >
            {children ? children : null}
            {personObj ? (
                <ConnectedFieldDetails
                    fields={fields || PersonDetails.fields}
                    idColumnDef={person.id}
                    values={personObj}
                />
            ) : null}
        </SimpleCard>
    );
}

PersonDetails.fields = [
    person.descriptorWithSalutation,
    person.jobTitle,
    person.businessEmailLink,
    person.businessPhone,
    person.ddiPhone,
    person.mobilePhone,
    person.gdprPreferences
];
