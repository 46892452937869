import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const CompanyDemosContext = React.createContext({
    companyId: undefined,
    demos: [],
    isLoading: false
});

const CompanyDemosProvider = ({ companyId, isLoading, demos, children }) => {
    return (
        <CompanyDemosContext.Provider value={{ companyId, demos, isLoading }}>{children}</CompanyDemosContext.Provider>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { companyId }) => state.demos.filter(demo => demo.companyId === companyId)
        ],
        (isLoading, demos) => ({
            isLoading,
            demos
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyDemosProvider);
