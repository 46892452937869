import React, { useContext } from 'react';
import { Alert } from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import PageSection from '../../common/PageSection';
import SyncingOrErrorIndicator from '../../common/SyncingOrErrorIndicator';
import Layout3Columns, { Column1, Column2And3 } from '../../Dashboard/Components/Layout3Columns';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';
import CompanyDetails from '../../Company/Components/CompanyDetails';
import CompanyAffiliates from '../../Company/Components/CompanyAffiliates';
import CompanyPrimaryPeople from '../../Person/Components/CompanyPrimaryPeople';
import CompanyPrimaryAddressesDetails from '../../Address/Components/CompanyPrimaryAddressesDetails';
import { agreementProviderstatus as agreementProviderStatus, useAgreementContext } from '../Provider/AgreementProvider';
import TermsTable from '../Table/TermsTable';
import MasterTermsInformation from './MasterTermsInformation';
import AgreementTabs from './AgreementTabs';
import AgreementHistoryBreadcrumb from './AgreementHistoryBreadcrumb';
import AgreementChecksTable from '../Table/AgreementChecksTable';
import AnticipatedVolumeTable from '../Table/AnticipatedVolumeTableVertical';
import FileList from '../../app/FileList/index';
import { ToolTipProvider } from './AgreementHistoryBreadcrumbToolTip';
import AgreementTestsDropdown from './AgreementTestsDropdown/AgreementTestsDropdown';
import AgreementAuditModal from '../Modals/AgreementAuditModal';
import AgreementRevisionsDropdown from './AgreementRevisionsDropdown';
import AgreementDownloadPdf from './AgreementDownloadPdf';
import AgreementProcessVersion from './AgreementProcessVersion';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';
import { DiscountWarnings } from './DiscountWarnings';
import { CompanyContext } from '../../Company/Provider/CompanyProvider';

function PageTitle() {
    const { company } = useAgreementContext();
    return `Agreement for ${company.name}`;
}

function DebugInfo() {
    const { search } = useLocation();
    const debug = search && queryString.parse(search)?.debug === 'true';
    const minSupport = useAgreementsStore(state => state.agreement?.minSupport);

    if (!debug) return null;

    return (
        <Alert color="info">
            <strong>DEBUG</strong>
            <p>minSupport: {minSupport}</p>
        </Alert>
    );
}

export default function AgreementPageContent() {
    const { status } = useAgreementContext();
    const { goBack } = useHistory();
    const agreementId = useAgreementsStore(state => state.agreement?.id);
    const revision = useAgreementsStore(state => state.agreement?.revision);
    const { company = {} } = useContext(CompanyContext);

    return (
        <ToolTipProvider>
            <PageSection
                loading={!agreementId}
                onBackClicked={goBack}
                title={<PageTitle />}
                titleButtonArea={
                    <div className="d-flex" style={{ gap: '0.5rem' }}>
                        <AgreementTestsDropdown />
                        <AgreementDownloadPdf
                            agreementId={agreementId}
                            companyName={company.name}
                            revisionId={revision}
                        >
                            Download Current Agreement PDF
                        </AgreementDownloadPdf>
                        <AgreementProcessVersion />
                        <AgreementRevisionsDropdown />
                    </div>
                }
                titleContent={
                    <div style={{ gridColumn: '1 / -1', overflowX: 'auto' }}>
                        <AgreementHistoryBreadcrumb />
                    </div>
                }
                titleExtra={
                    <SyncingOrErrorIndicator isLoading={status === agreementProviderStatus.loading} size={20} />
                }
                warning={<DiscountWarnings />}
            >
                <RedirectNotLoggedIn />
                <Layout3Columns>
                    <Column1>
                        <DebugInfo />
                        <MasterTermsInformation />
                        <CompanyDetails />
                        <CompanyAffiliates />
                        <CompanyPrimaryPeople />
                        <CompanyPrimaryAddressesDetails />
                        <FileList />
                    </Column1>
                    <Column2And3>
                        <AgreementAuditModal />
                        <AgreementTabs />
                        <div className="d-grid" style={{ gridTemplateColumns: '1fr 1fr', gap: '0.7rem' }}>
                            <AnticipatedVolumeTable />
                            <AgreementChecksTable />
                        </div>
                        <TermsTable brand="BMW" title="BMW" useTableStore={useBMWStore} />
                        <TermsTable brand="MINI" title="MINI" useTableStore={useMiniStore} />
                    </Column2And3>
                </Layout3Columns>
            </PageSection>
        </ToolTipProvider>
    );
}
