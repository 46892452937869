import React from 'react';
import { connect } from 'react-redux';
import { FormFeedback } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import _ from 'lodash';
import { getChoiceList } from '../LabelText';
import FormikFormGroup from './FormikFormGroup';
import { DisplayField } from '../DisplayField/index';
import { Alert } from 'reactstrap';
import { components } from 'react-select';
import PropTypes from 'prop-types';

const Option = props => (
    <components.Option {...props}>
        {props.value !== '*' && (
            <input checked={props.isSelected} onChange={() => null} style={{ marginRight: 5 }} type="checkbox" />
        )}
        <label>{props.label}</label>
    </components.Option>
);

function SelectPart({
    choiceList,
    filter,
    resources,
    mapChoiceValues,
    values,
    fieldName,
    setFieldValue,
    onFormValueChanged,
    isMulti,
    title,
    options,
    emptyOption,
    disabled,
    noOptionsText,
    isOptionDisabled,
    isClearable,
    allOption,
    allowSelectAll,
    closeMenuOnSelect = true,
    checkBoxStyle,
    hideSelectedOptions,
    maxMenuHeight
}) {
    let useOptions = (options || getChoiceList(resources, choiceList, mapChoiceValues)).map(({ key, value }) => ({
        value: key,
        label: value
    }));

    if (filter) {
        useOptions = useOptions.filter(filter);
    }

    if (noOptionsText && options.length === 0) {
        return <DisplayField title={title} value={<Alert color="warning">{noOptionsText}</Alert>} />;
    }

    const rawValue = _.get(values, fieldName, '');

    return (
        <Select
            allowSelectAll={allowSelectAll}
            className="react-select"
            closeMenuOnSelect={closeMenuOnSelect}
            components={checkBoxStyle ? { Option, makeAnimated } : makeAnimated}
            hideSelectedOptions={hideSelectedOptions}
            isClearable={isClearable}
            isDisabled={disabled}
            isMulti={isMulti}
            isOptionDisabled={isOptionDisabled}
            maxMenuHeight={maxMenuHeight}
            onChange={a => {
                if (!a) {
                    setFieldValue(fieldName, undefined);
                    if (onFormValueChanged) {
                        onFormValueChanged(values, fieldName, undefined, setFieldValue);
                    }
                } else {
                    const value = isMulti ? a.map(x => x.value).join(',') : a.value;

                    if (isMulti && a.includes(allOption)) {
                        setFieldValue(fieldName, useOptions.map(option => option.value).toString());
                    } else {
                        setFieldValue(fieldName, value);
                    }

                    if (isMulti && a.includes(allOption) && onFormValueChanged) {
                        onFormValueChanged(
                            values,
                            fieldName,
                            useOptions.map(option => option.value).toString(),
                            setFieldValue
                        );
                    } else if (onFormValueChanged) {
                        onFormValueChanged(values, fieldName, value, setFieldValue);
                    }
                }
            }}
            options={isMulti ? [allOption, ...useOptions] : useOptions}
            placeholder={emptyOption}
            value={
                isMulti
                    ? rawValue
                        ? rawValue.split(',').map(value => useOptions.find(x => x.value === value))
                        : []
                    : useOptions.find(x => x.value === rawValue) || null
            }
        />
    );
}

class FormikSelectChoicelist extends React.Component {
    render() {
        const {
            values,
            formName,
            fieldName,
            onlyShowSelect,
            title,
            useBigTitle = false,
            help,
            children,
            hideLabel,
            hide,
            touched,
            options,
            errors,
            noOptionsText,
            titleAsLink
        } = this.props;

        if (noOptionsText && options.length === 0) {
            return <DisplayField help={help} title={title} value={<Alert color="warning">{noOptionsText}</Alert>} />;
        }

        const invalid = touched[fieldName] && errors[fieldName];

        if (hide && hide(values)) {
            return null;
        }

        if (onlyShowSelect) {
            return <SelectPart {...this.props} />;
        }

        return (
            <FormikFormGroup
                fieldName={fieldName}
                formName={formName}
                help={help}
                hideLabel={hideLabel}
                title={title}
                to={titleAsLink}
                useBigTitle={useBigTitle}
            >
                <SelectPart {...this.props} />
                {invalid && (
                    <FormFeedback className={invalid ? 'd-block' : ''} tooltip>
                        {errors[fieldName]}
                    </FormFeedback>
                )}
                {children}
            </FormikFormGroup>
        );
    }
}

FormikSelectChoicelist.propTypes = {
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

FormikSelectChoicelist.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*'
    }
};

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(FormikSelectChoicelist);
