import React from 'react';
import * as yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm/index';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import { useEngageUsersStore, useUpdateRole } from '../Provider/useEngageUsersStore';
import engageUserDef from '../engageUserDef';
import { useAgreementsAdminUsersVisibilityStore } from '../Provider/AgreementsAdminUsersVisibilityStore';
import { usersVisibilityColumnDef } from '../Table/EngageUserTable';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;
const validationSchema = yup.object().shape({
    userType: yup
        .string()
        .nullable()
        .required('Please select a role')
});

export default function UpdateRoleForm() {
    const resources = useResources();
    const user = useEngageUsersStore(state => state.selected);
    const cancel = useEngageUsersStore(state => state.closeModal);
    const saving = useEngageUsersStore(state => state.storeState) === storeStates.saving;
    const { seesUserIds } =
        (useAgreementsAdminUsersVisibilityStore(state => state?.data) || []).find(x => x.userId === user?.userId) || {};
    const stringSeesUserIds = seesUserIds ? seesUserIds.join(',') : null;

    const update = useUpdateRole(user?.userType, stringSeesUserIds);

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={update}
            initialValues={{ ...user, seesUserIds: stringSeesUserIds }}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Choicelist columnDef={engageUserDef.userType} />
                <ReactiveForm.Choicelist columnDef={usersVisibilityColumnDef()} isMulti={true} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
