import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import React, { useState } from 'react';
import IvsSearchForm from './IvsSearchForm';

export default function IvsSearchModal({ children, handleSubmit }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={() => setIsOpen(false)}>
                <AppModalHeader toggle={() => setIsOpen(false)}>TERMS BASED FILTER</AppModalHeader>
                <ModalBody>
                    <IvsSearchForm close={() => setIsOpen(false)} save={handleSubmit} />
                </ModalBody>
            </Modal>
            {children(setIsOpen)}
        </>
    );
}
