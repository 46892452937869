import React from 'react';
import BaseTable from '../../common/BaseTable/BaseTable';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { getEntity, useAgreementTemplateStore } from '../Provider/useAgreementTemplateStore';
import agreementTemplateDef from '../agreementTemplateDef';
import { useAgreementTemplateTableColumns } from './AgreementTemplateTable';

const AgreementHeaderTemplateTable = ({ showFilesUsed, type = 'Agreement' }) => {
    const templates = useAgreementTemplateStore(state => state.data);
    const openModal = useAgreementTemplateStore(state => state.openModal);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const columns = useAgreementTemplateTableColumns(type, showFilesUsed);

    return (
        <div style={{ height: `400px`, width: '100%' }}>
            <BaseTable
                cardTitle="Templates"
                columns={columns}
                data={templates}
                helpId="agreementTemplatesTableHeading"
                isLoading={isLoading}
                onRowClicked={({ rowData }) =>
                    !rowData.groupRow &&
                    !rowData.isBaseTemplate &&
                    openModal(rowData, undefined, {
                        entityId: rowData.templateBelongsToEntityId,
                        entity: getEntity(rowData)
                    })
                }
                rowClassName={rowData => rowData.isBaseTemplate && 'cursor-not-allowed'}
                rowKey={agreementTemplateDef.id.field}
            />
        </div>
    );
};

export default AgreementHeaderTemplateTable;
