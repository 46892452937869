import create from 'zustand';
import { updateData } from '../../../actions/Api/apiHelpers';
import { tableStoreState } from '../../common/BaseTable/BaseTable';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { maxTermDiscountPct } from '../../../config.json';

const rowKey = 'id';
const totalField = 'totalDiscount';
const nextTotalField = 'nextTotalDiscount';
export const useBMWStore = create((set, get) => {
    return {
        ...tableStoreState(set, get, { groupBy: ['series'], expandAll: true }),
        saving: false,
        setSaving: (saving) => set({ saving }),
        setTermsData: (add, remove, ordinal) => {
            const state = get();
            state.setData(updateData(state.dataCopy, rowKey, add, false, remove, ordinal));
        },
        getMaxValue: (field, rowIndex, groupRowKeys, isSubtraction = false) => {
            const state = get();
            const selectedRowKeys = groupRowKeys ?? state.selectedRowKeys;
            const smallestMaxValue = state.dataState.pageData
                .filter(f => (rowIndex !== undefined ? f.index === rowIndex : selectedRowKeys.has(f[rowKey])))
                .reduce((acc, f) => {
                    const total = f[nextTotalField] ?? f[totalField];
                    const cellData = !Array.isArray(field)
                        ? f[field]
                        : f[field.find(fld => f[fld] !== undefined && f[fld] !== null)];
                    const maxMinusTotal = maxTermDiscountPct - total;
                    let maxValue;                    

                    if (!isSubtraction) maxValue = maxMinusTotal + cellData;
                    else maxValue = total + cellData; //gives a total of zero

                    if (maxValue < acc) return maxValue;
                    else return acc;
                }, maxTermDiscountPct);

            return smallestMaxValue;
        }
    };
});
export const useMiniStore = create((set, get) => {
    return {
        ...tableStoreState(set, get, { groupBy: ['series'], expandAll: true }),
        saving: false,
        setSaving: (saving) => set({ saving }),
        setTermsData: (add, remove, ordinal) => {
            const state = get();
            state.setData(updateData(state.dataCopy, rowKey, add, false, remove, ordinal));
        },
        getMaxValue: (field, rowIndex, groupRowKeys, isSubtraction = false) => {
            const state = get();
            const selectedRowKeys = groupRowKeys ?? state.selectedRowKeys;
            const smallestMaxValue = state.dataState.pageData
                .filter(f => (rowIndex !== undefined ? f.index === rowIndex : selectedRowKeys.has(f[rowKey])))
                .reduce((acc, f) => {
                    const total = f[nextTotalField] ?? f[totalField];
                    const cellData = !Array.isArray(field)
                        ? f[field]
                        : f[field.find(fld => f[fld] !== undefined && f[fld] !== null)];
                    const maxMinusTotal = maxTermDiscountPct - total;
                    let maxValue;

                    if (!isSubtraction) maxValue = maxMinusTotal + cellData;
                    else maxValue = total + cellData; //gives a total of zero

                    if (maxValue < acc) return maxValue;
                    else return acc;
                }, maxTermDiscountPct);

            return smallestMaxValue;
        }
    };
});

export function useRemainingExclusions() {
    const masterTerms = useMasterTermStore(state => state.data);

    return (terms, term) =>
        (masterTerms.find(f => f.masterTermId === term.masterTermId)?.ivsCodes || []).filter(
            f => !terms.some(t => t.masterTermId === term.masterTermId && t.ivsCodeId === f.ivsCodeId)
        );
}

export function termIsEditedInAddendum(term) {
    return (
        !!term.nextBase || !!term.nextDiscretionary || !!term.nextPublicSector || !!term.nextRebate || !!term.nextAdded
    );
}

export function useFilteredTermsInDatabase(filterFn) {
    const bmwTerms = useBMWStore(state => state.dataCopy);
    const miniTerms = useMiniStore(state => state.dataCopy);
    if (!filterFn) {
        return [...bmwTerms, ...miniTerms];
    }
    return [...bmwTerms.filter(filterFn), ...miniTerms.filter(filterFn)];
}

function countApprovalLevels(
    terms,
    initailCount = {
        financeApproval: 0,
        flmApproval: 0,
        noApproval: 0
    }
) {
    return terms.reduce((acc, term) => {
        const field =
            term.approvalLevel === 'FIN'
                ? 'financeApproval'
                : term.approvalLevel === 'FLM'
                ? 'flmApproval'
                : 'noApproval';
        acc[field]++;
        return acc;
    }, initailCount);
}

export function useCountTermsWhereApprovalRequired() {
    const bmwTerms = useBMWStore(state => state.dataCopy);
    const miniTerms = useMiniStore(state => state.dataCopy);

    const bmwApprovalCounts = countApprovalLevels(bmwTerms);
    return countApprovalLevels(miniTerms, bmwApprovalCounts);
}
