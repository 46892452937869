import React from 'react';
import {
    basicColumnDef,
    choiceListColumnDef,
    emailColumnDef,
    idColumnDef,
    phoneColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import GdprOverview from '../Gdpr/GdprOverview';
import IsPrimaryPerson from '../Person/Tables/IsPrimaryPerson';

const person = {
    id: idColumnDef('id', 'personId'),
    salutation: {
        ...choiceListColumnDef('Salutation', 'salutation', 'personSalutation'),
        emptyOption: 'Select a Salutation...'
    },
    jobTitle: {
        ...basicColumnDef('Job Title', 'jobTitle'),
        type: 'text',
        help: {
            helpId: 'jobTitle',
            helpText: 'The job title of the person'
        }
    },
    descriptor: {
        ...basicColumnDef('Contact Name', 'descriptor'),
        type: 'personName',
        primary: true,
        help: {
            helpId: 'descriptor',
            helpText: 'The contact name of the person'
        }
    },
    descriptorWithSalutation: {
        ...basicColumnDef('Name', 'descriptorWithSalutation'),
        type: 'personName',
        primary: true
    },
    firstName: {
        ...basicColumnDef('First Name', 'firstName')
    },
    lastName: {
        ...basicColumnDef('Last Name', 'lastName')
    },
    contactRole: {
        ...choiceListColumnDef('Contact Role', 'contactRole', 'contactRole', true),
        help: {
            helpId: 'contactRole',
            helpText: 'The contact role of the person'
        }
    },
    // Contact Details
    businessEmail: {
        ...basicColumnDef('Email Address', 'businessEmail'),
        type: 'email'
    },
    businessEmailLink: {
        ...emailColumnDef('Email Address', 'businessEmail'),
        help: {
            helpId: 'businessEmail',
            helpText: 'The email address of the person'
        }
    },
    businessPhone: {
        ...phoneColumnDef('Business Number', 'businessPhone'),
        help: {
            helpId: 'businessPhone',
            helpText: 'The business phone number of the person'
        }
    },
    ddiPhone: {
        ...phoneColumnDef('Direct Dial Number', 'ddiPhone'),
        help: {
            helpId: 'ddiPhone',
            helpText: 'The direct dial phone number of the person'
        }
    },
    mobilePhone: {
        ...phoneColumnDef('Mobile Number', 'mobilePhone'),
        help: {
            helpId: 'mobilePhone',
            helpText: 'The mobile phone number of the person'
        }
    },
    gdprPreferences: {
        ...basicColumnDef('GDPR Preferences', 'gdprPreferences'),
        mapToDisplayedValue: (resources, values) => <GdprOverview person={values} />
    },
    gdprPreferencesInTable: {
        ...basicColumnDef('GDPR Preferences', 'gdprPreferences'),
        right: true,
        sort: false,
        mapToDisplayedValue: (resources, values) => (
            <span className="float-right">
                <GdprOverview person={values} />
            </span>
        ),
        cellRenderer: ({ rowData }) => (
            <span className="float-right my-2">
                <GdprOverview person={rowData} />
            </span>
        ),
        help: {
            helpId: 'gdprPreferences',
            helpText: 'The GDPR preferences of the person'
        }
    },
    gdprEmail: {
        ...choiceListColumnDef('Email', 'gdprEmail', 'personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    gdprDirectMail: {
        ...choiceListColumnDef('Direct Mail', 'gdprDirectMail', 'personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    gdprTelephone: {
        ...choiceListColumnDef('Telephone', 'gdprTelephone', 'personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    gdprSms: {
        ...choiceListColumnDef('SMS', 'gdprSms', 'personGdprEmail'),
        emptyOption: 'Select a GDPR Preference...'
    },
    isPrimaryPerson: {
        ...basicColumnDef('Primary', 'isPrimaryPerson'),
        valueToSortOnMapping: (resources, values, field, actions) => values.isPrimaryPerson,
        mapToDisplayedValue: (resources, values, field, actions) =>
            values.isPrimaryPerson ? <IsPrimaryPerson id={`isPrimary_${values.personId}`} /> : null,
        cellRenderer: ({ cellData, rowData }) =>
            cellData ? <IsPrimaryPerson id={`isPrimary_${rowData.personId}`} /> : null,
        help: {
            helpId: 'isPrimaryPerson',
            helpText: 'If the person is the primary person of the company'
        }
    },
    gdprConfirmed: {
        ...basicColumnDef(
            'By updating the marketing preferences I confirm that have I asked the permission of the contact',
            'gdprConfirmed'
        ),
        valueToSortOnMapping: (resources, values, field, actions) => values.isPrimaryPerson,
        mapToDisplayedValue: (resources, values, field, actions) =>
            values.isPrimaryPerson ? <IsPrimaryPerson id={`isPrimary_${values.personId}`} /> : null
    }
};

export default person;
