import React from 'react';
import HelpPopover from '../HelpPopover';

export const FieldLabel = props => {
    const { className, large, expand, onClick, children, help } = props;
    return (
        <div className={`${className}`} onClick={onClick}>
            <small className={`${large ? 'h6' : ''} text-muted text-uppercase`}>{children}</small>
            {help && (
                <HelpPopover
                    className={`ml-2 ${help.className || ''}`}
                    style={{ top: '-3px', right: '-25px', display: 'flex' }}
                    {...help}
                />
            )}
            {expand !== undefined ? (
                <i className={expand ? 'fa fa-chevron-up float-right' : 'fa fa-chevron-down float-right'} />
            ) : null}
        </div>
    );
};
