import React from 'react';
import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    timeColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import ColumnTypeFormatCompanyName, {
    ColumnTypeFormatCompanyNameFromCompanyId
} from '../common/Table/ColumnTypeFormatCompanyName';
import moment from 'moment';

const meeting = {
    id: idColumnDef('id', 'meetingId'),
    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        )
    },
    companyNameFromCompanyId: {
        ...basicColumnDef('Company', 'companyName'),
        cellRenderer: props => <ColumnTypeFormatCompanyNameFromCompanyId companyId={props.rowData.companyId} />,
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'companyName',
            helpText: 'The company of the agreement'
        }
    },
    companyNameNoClickThrough: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values) => values.companyDescriptor || '',
        searchable: true
    },
    personId: {
        ...choiceListColumnDef('Person', 'personId', 'peopleOptions'),
        emptyOption: 'Select a Person...',
        noOptionsText: 'No people in company'
    },
    subject: {
        ...basicColumnDef('Subject', 'subject'),
        searchable: true
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'communicationStatus'),
        emptyOption: 'Select a status...'
    },
    assigneeId: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee'),
        emptyOption: 'Select an assignee...',
        searchable: true,
        mapping: (resources, values) => (values && values.assigneeId ? values.assigneeId.toString() : '')
    },
    startDate: {
        ...dateColumnDef('Meeting Date', 'startDate'),
        searchable: true
    },
    startTime: {
        ...timeColumnDef('Meeting Time', 'startDate'),
        cellRenderer: props => moment(props.rowData.startDate).format('hh:mm A')
    },
    endDate: {
        ...dateColumnDef('Close Date', 'endDate')
    },
    meetingType: {
        ...choiceListColumnDef('Meeting Type', 'activityRegarding', 'communicationActivityRegarding')
    },
    activityRegarding: {
        ...choiceListColumnDef('Activity Regarding', 'activityRegarding', 'communicationActivityRegarding'),
        emptyOption: 'Select an activity regarding...',
        searchable: true
    },
    meetingMode: {
        ...choiceListColumnDef('Meeting Mode', 'meetingMode', 'communicationMeetingMode'),
        emptyOption: 'Select a meeting mode...',
        searchable: true
    },
    notes: {
        ...basicColumnDef('Details', 'notes'),
        type: 'textarea'
    },
    body: {
        ...basicColumnDef('Details', 'body'),
        type: 'textarea',
        mapToDisplayedValue: (resources, values) => (values && values.body ? values.body.substring(0, 100) : '')
    },
    location: {
        ...basicColumnDef('Location', 'location')
    }
};

export default meeting;
