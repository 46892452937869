import { basicColumnDef, choiceListColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const agreementNotificationDef = {
    id: idColumnDef('id', 'agreementNotificationId'),
    agreementNotificationName: {
        ...basicColumnDef('Name', 'agreementNotificationName'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'agreementNotificationName',
            helpText: 'Name of the notification'
        }
    },
    salesChannels: {
        ...choiceListColumnDef('Sales Channel(s)', 'salesChannels', 'salesChannel'),
        filter: false,
        help: {
            helpId: 'salesChannels',
            helpText: 'Sales channel the notifications are appropriate for, or all channels'
        }
    },
    agreementType: {
        ...choiceListColumnDef('Agreement Type(s)', 'agreementType', 'agreementType'),
        filter: false,
        help: {
            helpId: 'agreementType',
            helpText: 'The agreement type(s)'
        }
    },
    user: {
        ...choiceListColumnDef('User', 'createdBy', 'userAuthors'),
        filter: false,
        sortable: true,
        help: {
            helpId: 'createdBy',
            helpText: 'The user who has created the notification record'
        }
    },
    statusFrom: {
        ...choiceListColumnDef('Status From', 'statusFrom', 'agreementHeaderStatus'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'statusFrom',
            helpText: 'Select All or specific status from which and agreement is moving from.'
        }
    },
    agreementStatus: {
        ...choiceListColumnDef('Status To', 'agreementStatus', 'agreementHeaderStatus'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'agreementStatus',
            helpText: 'When an agreement reaches this status an agreement will be sent'
        }
    },
    emailCC: {
        ...basicColumnDef('Email CC', 'emailCC'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'emailCC',
            helpText: 'The extra email addresses that a notification will be sent to'
        }
    },
    emailSubject: {
        ...basicColumnDef('Email Subject', 'emailSubject'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'emailSubject',
            helpText: 'The subject of the notification email'
        }
    },
    emailBody: {
        ...basicColumnDef('Email Body', 'emailBody'),
        filter: true,
        help: {
            helpId: 'emailBody',
            helpText: 'The body of the notification email'
        }
    },
    email: {
        ...basicColumnDef('Email', 'email'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'email',
            helpText: 'Email content'
        },
        mergeFields: {
            key: 'mergeFields',
            helpTitle: 'Email Merge Fields',
            values: [
                '<<CustomerFirstName>>',
                '<<CustomerTitle>>',
                '<<CustomerSurname>>',
                '<<CompanyName>>',
                '<<CustomerPortalLink>>',
                '<<LeasingPortalLink>>'
            ]
        }
    },
    active: {
        ...basicColumnDef('Active', 'active'),
        sortable: true,
        help: {
            helpId: 'active',
            helpText: 'Set to no, if notification is no longer required'
        }
    },
    sendToOwner: {
        ...basicColumnDef('Send to Owner', 'sendToOwner'),
        sortable: true,
        help: {
            helpId: 'sendToOwner',
            helpText: 'If set to yes, this notification will be sent to the person who created the agreement'
        }
    },
    sendToCustomer: {
        ...basicColumnDef('Send to Customer', 'sendToCustomer'),
        sortable: true,
        help: {
            helpId: 'sendToCustomer',
            helpText: 'If set to yes, this notification will be sent to the customer'
        }
    },
    sendToLeasingCompany: {
        ...basicColumnDef('Send to Leasing Company', 'sendToLeasingCompany'),
        sortable: true,
        help: {
            helpId: 'sendToLeasingCompany',
            helpText: 'If set to yes, this notification will be sent to the leasing company'
        }
    },
    attachToEmail: {
        ...basicColumnDef('Attach to Email', 'attachToEmail'),
        sortable: true,
        help: {
            helpId: 'attachToEmail',
            helpText: 'Attach agreement to the email'
        }
    },
    attachLeasingCsv: {
        ...basicColumnDef('Attach Leasing CSV', 'attachLeasingCsv'),
        sortable: true,
        help: {
            helpId: 'attachLeasingCsv',
            helpText: 'Attach CSV to the leasing company email'
        }
    },
    sender: {
        ...choiceListColumnDef('Sender', 'sender', 'notificationSender'),
        sortable: true,
        help: {
            helpId: 'sender',
            helpText: 'The email address the emails are sent from'
        }
    },
    appendCompanyToMessage: {
        ...basicColumnDef('Append Company to Message', 'appendCompanyToMessage'),
        sortable: true,
        help: {
            helpId: 'appendCompanyToMessage',
            helpText: 'Add company details to message'
        }
    },
    appendAgreementToMessage: {
        ...basicColumnDef('Append Agreement to Message', 'appendAgreementToMessage'),
        sortable: true,
        help: {
            helpId: 'appendAgreementToMessage',
            helpText: 'Add agreement information to message'
        }
    },
    appendLinkToEngage: {
        ...basicColumnDef('Append Link to Engage', 'appendLinkToEngage'),
        sortable: true,
        help: {
            helpId: 'appendLinkToEngage',
            helpText: 'Add a link to the agreement in Engage to the message'
        }
    },
    isMassAddendumEmail: {
        ...basicColumnDef('Mass Addendum', 'isMassAddendumEmail'),
        sortable: true,
        help: {
            helpId: 'isMassAddendumEmail',
            helpText:
                'Status to and from does not matter if the notification is for mass addendum email.' +
                'The email subject and body can be overwritten when creating the mass addendum'
        }
    }
};

export const agreementNotificationPeopleDef = {
    id: idColumnDef('id', 'agreementNotificationPersonId'),
    agreementNotificationId: {
        ...basicColumnDef('Notification', 'agreementNotificationId'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'agreementNotificationId',
            helpText: 'Name of the notification'
        }
    },
    person: {
        ...choiceListColumnDef('Person', 'personId', 'userAuthors'),
        filter: false,
        sortable: true,
        help: {
            helpId: 'personId',
            helpText: 'The person to receive the notification'
        }
    },
    active: {
        ...basicColumnDef('Active', 'active'),
        sortable: true,
        help: {
            helpId: 'active',
            helpText: 'Set to no, if notification person is no longer required'
        }
    }
};

export default agreementNotificationDef;
