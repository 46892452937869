import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import ReactiveForm, { FilteredChoiceList } from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import {
    useAgreementTemplateStore,
    useUpdateTemplates,
    useValidateTemplates
} from '../Provider/useAgreementTemplateStore';
import agreementTemplateDef from '../agreementTemplateDef';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

function validationSchema(modalData) {
    return Yup.object()
        .shape({
            name: Yup.string().required('Name is required.'),
            agreementTemplateFile: Yup.object().test(
                'correct-format',
                'Agreement Template File must be a word document (.doc .docx file extension types)',
                function(agreementTemplateFile) {
                    return isWordDocument(agreementTemplateFile);
                }
            ),
            standardTermsAndConditionsFile: Yup.object().test(
                'correct-format',
                'Terms and Conditions File must be a word document (.doc .docx file extension types)',
                function(standardTermsAndConditionsFile) {
                    return isWordDocument(standardTermsAndConditionsFile);
                }
            ),
            coverLetterFile: Yup.object()
                .test(
                    'correct-format',
                    'Cover Letter File must be a word document (.doc .docx file extension types)',
                    function(coverLetterFile) {
                        return isWordDocument(coverLetterFile);
                    }
                )
                .test('at-least-one', 'You must upload at least one file for the template', function(coverLetterFile) {
                    const { agreementTemplateFile, standardTermsAndConditionsFile } = this.parent;
                    if (
                        !standardTermsAndConditionsFile.fileName &&
                        !agreementTemplateFile.fileName &&
                        !coverLetterFile.fileName
                    ) {
                        return false;
                    }
                    return true;
                })
        })
        .test('salesChannelIsRequiredIfNotForEntity', function({ entity, salesChannel }) {
            return (
                entity ||
                salesChannel ||
                this.createError({
                    path: 'salesChannel',
                    message: 'Sales Channel is required.'
                })
            );
        })
        .test('agreementTemplateFileRequiredIfNotEntityOrAddendum', function({
            entity,
            forAddendum,
            agreementTemplateFile
        }) {
            return (
                entity ||
                forAddendum ||
                agreementTemplateFile?.fileName ||
                this.createError({
                    path: 'agreementTemplate',
                    message: 'Agreement Template File is required.'
                })
            );
        })
        .test('standardTermsAndConditionsFileRequiredIfNotEntityOrAddendum', function({
            entity,
            forAddendum,
            standardTermsAndConditionsFile
        }) {
            return (
                entity ||
                forAddendum ||
                standardTermsAndConditionsFile?.fileName ||
                this.createError({
                    path: 'standardTermsAndConditionsFile',
                    message: 'Terms and Conditions File is required.'
                })
            );
        })
        .test('coverLetterFileRequiredIfNotEntityOrAddendum', function({ entity, forAddendum, coverLetterFile }) {
            return (
                entity ||
                forAddendum ||
                coverLetterFile?.fileName ||
                this.createError({
                    path: 'coverLetterFile',
                    message: 'Cover Letter File is required.'
                })
            );
        });
}

const isWordDocument = file => !file.fileName || !!file.fileName.match(/^.*?<!|doc|docx|DOC|DOCX$/);

export default function AgreementTemplateForm() {
    const cancel = useAgreementTemplateStore(state => state.closeModal);
    const selected = useAgreementTemplateStore(state => state.selected);
    const modalData = useAgreementTemplateStore(state => state.modalData);
    const saving = useAgreementTemplateStore(state => state.storeState) === storeStates.saving;
    const setSaving = useAgreementTemplateStore(state => state.setSaving);
    const setError = useAgreementTemplateStore(state => state.setError);
    const resources = useResources();
    const updateTemplates = useUpdateTemplates();
    const validateTemplates = useValidateTemplates();
    const choiceList = useSelector(state => state.resources.choiceList);
    const dependOnSalesChannel = Object.entries(choiceList)
        .filter(f => f[1].some(s => s.filterOnChoiceList === agreementTemplateDef.salesChannel.field))
        .map(m => m[0]);

    const save = ({ entity, entityId, ...template }) => {
        setSaving();
        validateTemplates([{ ...template }])
            .then(response => {
                if (response) {
                    updateTemplates([
                        {
                            ...template
                        }
                    ]);
                } else setError('The file must contain two tables - one for BMW and Mini Terms');
            })
            .catch(err => console.error(err));
    };

    const templateBelongsToEntity = modalData?.entity || selected?.entity;
    const templateBelongsToEntityId = modalData?.entityId || selected?.entityId;

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={save}
            initialValues={{
                ...modalData,
                agreementTemplateFile: { fileName: '' },
                standardTermsAndConditionsFile: { fileName: '' },
                coverLetterFile: { fileName: '' },
                asbTemplateFile: { fileName: '' },
                ...selected,
                isBaseTemplate: !modalData || !modalData.entity,
                active: selected?.active !== undefined ? selected.active : true,
                forAddendum: selected?.forAddendum || templateBelongsToEntity === 'Addendum',
                templateBelongsToEntityId,
                templateBelongsToEntity
            }}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema(modalData)}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={agreementTemplateDef.name} />
                {!modalData || !modalData.entity ? (
                    <ReactiveForm.Choicelist
                        columnDef={agreementTemplateDef.salesChannel}
                        onFormValueChanged={(values, fieldName, _, setFieldValue) => {
                            dependOnSalesChannel.forEach(f => {
                                setFieldValue(f, undefined);
                            });
                        }}
                    />
                ) : null}
                {!modalData || !modalData.entity ? (
                    <FilteredChoiceList columnDef={agreementTemplateDef.matrixBanding} isMulti={true} />
                ) : null}
                {!modalData || !modalData.entity ? (
                    <FilteredChoiceList columnDef={agreementTemplateDef.agreementType} />
                ) : null}
                <ReactiveForm.Checkbox
                    columnDef={agreementTemplateDef.forAddendum}
                    hide={({ values }) => values?.templateBelongsToEntity === 'Addendum'}
                />
                <ReactiveForm.Dropzone
                    accept=".doc,.docx"
                    columnDef={agreementTemplateDef.agreementTemplateFile}
                    multiple={false}
                />
                <ReactiveForm.Dropzone
                    accept=".doc,.docx"
                    columnDef={agreementTemplateDef.standardTermsAndConditionsFile}
                    multiple={false}
                />
                <ReactiveForm.Dropzone
                    accept=".doc,.docx"
                    columnDef={agreementTemplateDef.coverLetterFile}
                    multiple={false}
                />
                <ReactiveForm.Checkbox columnDef={agreementTemplateDef.active} />
                <ReactiveForm.Text columnDef={agreementTemplateDef.comments} type="textarea" />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
