import create from 'zustand';
import { apiAgreementChecksPost } from '../../../actions/Api/Agreement/apiAgreement';
import { useDispatch } from 'react-redux';
import { storeWithModal, updateDataFromFetch } from './storeStates';
import { useAgreementAuditStore } from './useAgreementAuditStore';
import { useAgreementsStore } from './useAgreementsStore';

const rowKey = 'agreementCheckId';
export const useAgreementChecksStore = create(set => ({
    ...storeWithModal(set, rowKey),
    checksInvalidCount: 0,
    updateDataFromFetch: (fetchedRows, clearState, postedRows = []) => {
        set(state => {
            const newState = updateDataFromFetch(rowKey, state, fetchedRows, clearState, postedRows);
            return {
                ...newState,
                checksInvalidCount: newState.data.filter(
                    f => (f.required && f.outcome === null) || (f.mustPass && f.outcome !== true)
                ).length
            };
        });
    }
}));

export function useUpdateChecks() {
    const agreement = useAgreementsStore(state => state.agreement);
    const updateChecksFromFetch = useAgreementChecksStore(state => state.updateDataFromFetch);
    const setSaving = useAgreementChecksStore(state => state.setSaving);
    const setError = useAgreementChecksStore(state => state.setError);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    return checks => {
        setSaving();
        return dispatch(apiAgreementChecksPost(agreement.companyId, agreement.agreementId, checks))
            .then(response => {
                updateChecksFromFetch(response.agreementChecks || []);
                updateAuditsFromFetch(response.agreementHeaderAudits || []);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}

function agreementCheckIsCurrent(check, agreementStatus, agreementRevision) {
    return (
        check.agreementStatus === agreementStatus &&
        (check.revision === agreementRevision || (check.revision === null && agreementRevision === 0))
    );
}

export const AgreementCheckStatus = {
    needsValue: 'Needs Value',
    needsPositive: 'Must Pass'
};

export function useAgreementChecksSortedWithStatus() {
    const agreementStatus = useAgreementsStore(state => state.agreement?.status);
    const agreementRevision = useAgreementsStore(state => state.agreement?.revision);
    const checks = useAgreementChecksStore(state => state.data);

    //console.table(checksWithStatus, ["outcome", "required", "mustPass", "status", "isCurrent"])

    return checks
        .map(check => ({
            ...check,
            isCurrent: agreementCheckIsCurrent(check, agreementStatus, agreementRevision),
            status: agreementCheckIsCurrent(check, agreementStatus, agreementRevision)
                ? !check.outcome && check.mustPass
                    ? AgreementCheckStatus.needsPositive
                    : check.outcome === null && check.required
                    ? AgreementCheckStatus.needsValue
                    : null
                : null
        }))
        .sort((a, b) => {
            const aRevision = a.revision === null ? 0 : a.revision;
            const bRevision = b.revision === null ? 0 : b.revision;
            return a.isCurrent && !b.isCurrent
                ? -1
                : !a.isCurrent && b.isCurrent
                ? 1
                : aRevision === bRevision
                ? a.ordinal > b.ordinal
                    ? 1
                    : a.ordinal < b.ordinal
                    ? -1
                    : 0
                : aRevision > bRevision
                ? 1
                : -1;
        });
}
