import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import ReactiveForm, { FilteredChoiceList } from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import storeStates from '../../Agreement/Provider/storeStates';
import { useAgreementCheckTypeStore, useUpdateCheckTypes } from '../Provider/useAgreementCheckTypeStore';
import agreementCheckTypeDef from '../agreementCheckTypeDef';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .max(200, 'Max length of name is 200')
        .required('Name is required.'),
    ordinal: Yup.number().min(0)
});

export default function AgreementCheckTypeForm() {
    const cancel = useAgreementCheckTypeStore(state => state.closeModal);
    const checks = useAgreementCheckTypeStore(state => state.data);
    const selected = useAgreementCheckTypeStore(state => state.selected);
    const saving = useAgreementCheckTypeStore(state => state.storeState) === storeStates.saving;
    const setSaving = useAgreementCheckTypeStore(state => state.setSaving);
    const resources = useResources();
    const updateCheckTypes = useUpdateCheckTypes();
    const choiceList = useSelector(state => state.resources.choiceList);
    const dependOnSalesChannel = Object.entries(choiceList)
        .filter(f => f[1].some(s => s.filterOnChoiceList === agreementCheckTypeDef.salesChannels.choiceList))
        .map(m => m[0]);

    const save = checkType => {
        setSaving();
        updateCheckTypes([checkType]);
    };

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={save}
            initialValues={{
                ordinal: checks?.length > 0 ? checks[checks.length - 1].ordinal + 1 : 1,
                ...selected,
                active: selected?.active !== undefined ? selected.active : true
            }}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={agreementCheckTypeDef.name} max={200} />
                <ReactiveForm.Text columnDef={agreementCheckTypeDef.helpText} max={1000} />
                <ReactiveForm.Choicelist columnDef={agreementCheckTypeDef.stage} isMulti={true} />
                <ReactiveForm.Choicelist
                    columnDef={agreementCheckTypeDef.salesChannels}
                    isMulti={true}
                    onFormValueChanged={(values, fieldName, _, setFieldValue) => {
                        dependOnSalesChannel.forEach(f => {
                            setFieldValue(f, undefined);
                        });
                    }}
                />
                <FilteredChoiceList columnDef={agreementCheckTypeDef.agreementType} isMulti={true} />
                <ReactiveForm.Checkbox columnDef={agreementCheckTypeDef.asb} />
                <ReactiveForm.Checkbox columnDef={agreementCheckTypeDef.required} />
                <ReactiveForm.Checkbox columnDef={agreementCheckTypeDef.mandatoryEvidence} />
                <ReactiveForm.Checkbox columnDef={agreementCheckTypeDef.mustPass} />
                <ReactiveForm.Checkbox columnDef={agreementCheckTypeDef.active} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
