import {
    basicColumnDef,
    choiceListColumnDef,
    choiceListColumnSettings,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

const meeting = {
    id: idColumnDef('id', 'meetingId'),
    personId: {
        ...basicColumnDef('Person', 'personId'),
        ...choiceListColumnSettings('peopleOptions'),
        emptyOption: 'Select a Person...',
        noOptionsText: 'No people in company'
    },
    activityRegarding: {
        ...basicColumnDef('Activity Regarding', 'activityRegarding'),
        ...choiceListColumnSettings('communicationActivityRegarding'),
        emptyOption: 'Select an Activity Regarding...'
    },
    location: {
        ...basicColumnDef('Location', 'location')
    },
    eventMethod: {
        ...basicColumnDef('Event Method', 'eventMethod'),
        ...choiceListColumnSettings('communicationMeetingMode'),
        emptyOption: 'Select an Event Method...'
    },
    subject: {
        ...basicColumnDef('Subject', 'subject')
    },
    details: {
        ...basicColumnDef('Description', 'details'),
        type: 'textarea'
    },
    assigneeId: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee'),
        emptyOption: 'Select an assignee...',
        searchable: true,
        mapping: (resources, values) => (values && values.assigneeId ? values.assigneeId.toString() : '')
    },
    startDate: {
        ...basicColumnDef('Meeting Start Date/Time', 'startDate'),
        type: 'date'
    },
    endDate: {
        ...basicColumnDef('Meeting End Date/Time', 'endDate'),
        type: 'date'
    },
    meetingMode: {
        ...choiceListColumnDef('Meeting Mode', 'meetingMode', 'communicationMeetingMode'),
        emptyOption: 'Select a meeting mode...'
    },
    feedback: {
        ...basicColumnDef('Feedback', 'feedback')
    },
    activityResult: {
        ...choiceListColumnDef('Activity Result', 'activityResult', 'communicationActivityResult'),
        emptyOption: 'Select an activity regarding...',
        searchable: true
    },
    rating: {
        ...choiceListColumnDef('Rating', 'rating', 'communicationAppointmentRating'),
        emptyOption: 'Select an rating...',
        searchable: true
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'communicationStatus'),
        emptyOption: 'Select a status...'
    },
    action: {
        ...choiceListColumnDef('Action', 'action', 'communicationAction')
    },
    notes: {
        ...basicColumnDef('Details', 'notes'),
        type: 'textarea'
    }
};

export default meeting;
