import React from 'react';
import { Card, CardBody } from 'reactstrap';

const TableCard = ({ title, titleContentRight, children }) => {
    return (
        <Card className={`mb-4 shadow-sm simple-card`} style={{ overflow: 'hidden' }}>
            <div className="d-flex justify-content-between base-table-in-card-header rounded-t">
                <h5 className={`d-inline logo-font gradient`}>{title}</h5>
                {titleContentRight}
            </div>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    );
}

export default TableCard;