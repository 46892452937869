import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { apiClearAllNotificationsPost } from '../../../actions/Api/Notification/apiNotification';
import NotificationItemsList from './NotificationItemsList';

const CURRENT_DATE = moment();

const DATE = {
    TODAY: CURRENT_DATE.clone().startOf('day'),
    YESTERDAY: CURRENT_DATE.clone()
        .subtract(1, 'days')
        .startOf('day')
};

const TIME = {
    TODAY: 'TODAY',
    YESTERDAY: 'YESTERDAY',
    OLDER: 'OLDER'
};

export const getDate = date => {
    const mDate = moment(date);

    if (mDate.isSame(DATE.TODAY, 'd')) return TIME.TODAY;

    if (mDate.isSame(DATE.YESTERDAY, 'd')) return TIME.YESTERDAY;

    if (mDate.isBefore(DATE.YESTERDAY, 'd')) return TIME.OLDER;
};

const NotificationFactory = ({ notifications }) => {
    const gotoNotifications = useSafeDispatchPush(() => ['notifications']);
    const dispatch = useDispatch();

    const groupedNotifications = _.reduce(
        notifications,
        (result, value) => {
            const date = getDate(value.createdDate);

            switch (date) {
                case TIME.TODAY:
                    result.TODAY.push(value);
                    break;
                case TIME.YESTERDAY:
                    result.YESTERDAY.push(value);
                    break;
                case TIME.OLDER:
                    result.OLDER.push(value);
                    break;
                default:
                    break;
            }

            return result;
        },
        {
            [TIME.TODAY]: [],
            [TIME.YESTERDAY]: [],
            [TIME.OLDER]: []
        }
    );

    const onClearAll = () => {
        dispatch(apiClearAllNotificationsPost());
    };

    return (
        <React.Fragment>
            {Object.keys(groupedNotifications).map(key => {
                if (groupedNotifications[key].length === 0) return null;

                return (
                    <NotificationItemsList
                        data={_.orderBy(groupedNotifications[key], notification => notification.createdDate, ['desc'])}
                        key={key}
                        title={key}
                    />
                );
            })}
            {notifications && notifications.length > 0 && notifications[notifications.length - 1].more > 0 && (
                <Button color="primary" onClick={gotoNotifications}>
                    +{notifications[notifications.length - 1].more} more
                </Button>
            )}
            {notifications && notifications.length > 0 && (
                <Button className="ml-2" color="primary" onClick={onClearAll}>
                    Clear All
                </Button>
            )}
        </React.Fragment>
    );
};

export default NotificationFactory;
