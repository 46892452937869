import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import { apiAgreemenStatusChange } from '../../../actions/Api/Agreement/apiAgreement';
import { useLeasingCompaniesStore } from './useLeasingCompaniesStore';
import { useAgreementsStore } from './useAgreementsStore';
import { useAgreementChecksStore } from './useAgreementChecksStore';
import { useAgreementAuditStore } from './useAgreementAuditStore';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import { useRevisionsStore } from './useRevisionsStore';
import { useAnticipatedVolumesStore } from './useAnticipatedVolumesStore';
import { useBMWStore, useMiniStore } from './useTermsStore';
import { useAgreementTemplateStore } from '../../AgreementTemplates/Provider/useAgreementTemplateStore';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';
import { useAgreementNotificationStore } from '../../AgreementNotifications/Provider';

export const AgreementContext = React.createContext(undefined);

const StatusColors = {
    CHPE: 'neutral',
    APRV: 'positive',
    ADDE: 'negative',
    DECL: 'negative',
    CUTE: 'negative',
    BWTE: 'negative',
    APAW: 'positive',
    CHFA: 'negative',
    DELT: 'negative'
};

export function getStatusColor(status) {
    return StatusColors[status] || 'neutral';
}

export function isOldOrDeletedAgreement(agreement) {
    const oldStatus = ['CUTE', 'BWTE', 'EXPI', 'CLDO', 'DELT'].some(x => x === agreement.status);
    const expiredByDate = moment(agreement.endDate).isBefore(new Date().toDateString());
    return oldStatus || expiredByDate;
}

export const agreementProviderstatus = {
    loading: 'loading',
    error: 'error',
    loaded: 'loaded'
};

export function setBmwAndMiniTerms(terms, setBMWTerms, setMiniTerms) {
    const bmwTerms = (terms || []).filter(term => term.brand === 'BMW');
    const miniTerms = (terms || []).filter(term => term.brand === 'MINI');
    setBMWTerms(bmwTerms);
    setMiniTerms(miniTerms);
}

//Initial provider for loading all data for agreement in one go.
//This should never update after initial load so that whole of agreements page
//Does not re-render
export const AgreementProvider = ({ children, agreementId }) => {
    const [data, setData] = useState({
        status: agreementProviderstatus.loading,
        company: {}
    });
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();

    const agreement = useAgreementsStore(state => state.agreement); //TEMP
    const clearAgreementHeaderData = useAgreementsStore(state => state.clearData); //TEMP
    //const updatePageFromFetch = useUpdatePageFromFetch(); //doesn't work for some reason :(
    const updateLeasingCompaniesFromFetch = useLeasingCompaniesStore(state => state.updateDataFromFetch);
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateAddendaFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const BMWTermsOnColumnSort = useBMWStore(state => state.onColumnSort);
    const MiniTermsOnColumnSort = useMiniStore(state => state.onColumnSort);
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const updateChecksFromFetch = useAgreementChecksStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateAnticipatedVolumesFromFetch = useAnticipatedVolumesStore(state => state.updateDataFromFetch);
    const updateRevisionsFromFetch = useRevisionsStore(state => state.updateDataFromFetch);
    const updateTemplatesFromFetch = useAgreementTemplateStore(state => state.updateDataFromFetch);
    const clearAuditData = useAgreementAuditStore(state => state.clearData);
    const updateNotificationsFromFetch = useAgreementNotificationStore(state => state.updateDataFromFetch);

    const setPageData = response =>
        setData({
            company: (response.companies && response.companies.length > 0 && response.companies[0]) || {},
            status: agreementProviderstatus.loaded
        });

    const { pathname } = useLocation();
    useEffect(() => {
        clearAgreementHeaderData();
        clearAuditData();
        dispatch(apiPage('agreement', agreementId, undefined, pathname))
            .then(response => {
                updateLeasingCompaniesFromFetch(response.leasingCompanies || [], true);
                updateMasterTermsFromFetch(response.masterTerms || [], true);
                setBmwAndMiniTerms(response.agreementTerms, setBMWTerms, setMiniTerms);
                BMWTermsOnColumnSort(undefined);
                MiniTermsOnColumnSort(undefined);

                updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
                updateRevisionsFromFetch(response.agreementRevisions || [], true);
                updateAgreementsFromFetch(response.agreements || [], true);
                updateAddendaFromFetch(response.addendum || [], true);
                updateChecksFromFetch(response.agreementChecks || [], true);
                updateAuditsFromFetch(response.agreementHeaderAudits || [], true);
                updateAnticipatedVolumesFromFetch(response.agreementAnticipatedVolumes || []);
                updateTemplatesFromFetch((response.agreementTemplates || []).reverse(), true);
                updateNotificationsFromFetch(response.agreementNotifications || true);

                //updatePageFromFetch(response);
                setPageData(response);
            })
            .catch(err => console.error('error', err));
    }, [
        agreementId,
        dispatch,
        //updatePageFromFetch,
        updateLeasingCompaniesFromFetch,
        updateAgreementsFromFetch,
        updateAddendaFromFetch,
        updateMasterTermsFromFetch,
        updateChecksFromFetch,
        clearAgreementHeaderData,
        clearAuditData,
        updateAuditsFromFetch,
        updateMasterTermVersionsFromFetch,
        updateRevisionsFromFetch,
        updateAnticipatedVolumesFromFetch,
        updateTemplatesFromFetch,
        setBMWTerms,
        setMiniTerms,
        BMWTermsOnColumnSort,
        MiniTermsOnColumnSort,
        pathname,
        updateNotificationsFromFetch
    ]);

    const updateAgreementStatus = (agreementId, transitionName, statusFrom, statusTo, agreement) => {
        return new Promise((resolve, reject) => {
            setSaving(true);
            dispatch(apiAgreemenStatusChange(agreementId, transitionName, statusFrom, statusTo, agreement))
                .then(response => {
                    setSaving(false);

                    setPageData(response, true);
                    //updatePageFromFetch(response);

                    updateMasterTermsFromFetch(response.masterTerms || [], true);

                    setBmwAndMiniTerms(response.agreementTerms, setBMWTerms, setMiniTerms);

                    updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
                    updateRevisionsFromFetch(response.agreementRevisions || [], true);
                    updateAgreementsFromFetch(response.agreements || [], true);
                    updateAddendaFromFetch(response.addendum || [], true);
                    updateChecksFromFetch(response.agreementChecks || [], true);
                    updateAuditsFromFetch(response.agreementHeaderAudits || [], true);
                    updateAnticipatedVolumesFromFetch(response.agreementAnticipatedVolumes || []);
                    updateTemplatesFromFetch((response.agreementTemplates || []).reverse(), true);
                    resolve();
                })
                .catch(err => {
                    setSaving(false);
                    console.log('error', err);
                    reject();
                });
        });
    };

    return (
        <CompanyProvider companyId={agreement?.companyId}>
            <AgreementContext.Provider
                value={{
                    ...data,
                    setPageData,
                    saving,
                    updateAgreementStatus
                }}
            >
                {children}
            </AgreementContext.Provider>
        </CompanyProvider>
    );
};

export const useAgreementContext = () => {
    const state = useContext(AgreementContext);
    if (state === undefined) {
        throw new Error(`useAgreementContext must be used inside AgreementProvider`);
    }
    return state;
};
