import React from 'react';
import { Button } from 'reactstrap';

export const DeleteTableRowButton = props => TableRowButton(props, 'Delete', 'trash', 'danger');
export const ResetTableRowButton = props => TableRowButton(props, 'Reset', 'undo', 'secondary');
export const EditNotificationTableRowButton = props => TableRowButton(props, 'Edit', 'envelope', 'info');
export const AddTableRowButton = props => TableRowButton(props, 'Add', 'plus', 'primary');
export const CommentTableRowButton = props =>
    TableRowButton(props, props.count ? undefined : 'Add Comment', 'comment', props.count ? 'info' : 'primary');

export const CollapseTableRowButton = props => (
    <i
        className={`fa d-inline fa-lg ${
            props.collapsed ? 'fa-chevron-down' : 'fa-chevron-up'
        } simple-card-dimmed hover-pointer`}
        onClick={() => props.onClick()}
    />
);

export const FilterTableRowButton = props => TableRowButton(props, 'Filter', 'filter', 'primary');

export function TableButtonsContainer({ children }) {
    return <div className="table-button-container">{children}</div>;
}

const TableRowButton = (props, title, icon, outline) => (
    <Button
        className={`btn-sm ${props.className} `}
        color={outline}
        disabled={props.disabled}
        outline={true}
        style={{ opacity: props.disabled ? 0.15 : 1 }}
        title={title}
        {...props}
    >
        <div className="d-flex justify-center align-items-center py-1">
            <i className={`fa d-inline fa-lg fa-${icon}${props.children ? ' mr-2' : ''}`} />
        </div>
        {props.children}
    </Button>
);
