import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import SimpleCard from '../../common/Card/SimpleCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { makePostRequest } from '../../../actions/Api/apiHelpers';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import IconFactory from '../../common/IconFactory';
import Pagination from '../../common/Table/Pagination';

const FileListCard = memo(({ libraryFiles, isLoading }) => {
    const [showAll, setShowAll] = useState(false);
    const [page, setPage] = useState(0);
    const authorsList = useChoiceList('userAuthors');
    const pageSize = 5;
    const minRow = page * pageSize;
    const maxRow = (page + 1) * pageSize - 1;

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const downloadFile = (e, f) => {
        e.preventDefault();
        e.stopPropagation();

        makePostRequest(['library', 'generate-file-url'], undefined, { key: f.fileName })
            .then(d => {
                window.open(d.data, '_blank');
            })
            .catch(err => console.error('error', err));
    };

    const getFileIcon = fileName => {
        const dotIndex = fileName.indexOf('.');
        const type = dotIndex > -1 ? fileName.substring(dotIndex, fileName.length) : '';

        switch (type) {
            case '.jpeg':
            case '.jpg':
            case '.png':
            case '.bmp':
            case '.gif':
                return 'fa-file-image-o';

            case '.mp3':
            case '.wma':
                return 'fa-file-audio-o';

            case '.mp4':
            case '.wmv':
            case '.avi':
                return 'fa-file-video-o';

            case '.pdf':
                return 'fa-file-pdf-o';

            case '.txt':
                return 'fa-file-text-o';

            case '.zip':
                return 'fa-file-zip-o';

            case '.doc':
            case '.docx':
            case '.odt':
                return 'fa-file-word-o';

            case '.xls':
            case '.xlsx':
            case '.csv':
            case '.ods':
                return 'fa-file-excel-o';

            case '.ppt':
            case '.pptx':
            case '.odp':
                return 'fa-file-powerpoint-o';

            default:
                return 'fa-file-o';
        }
    };

    return (
        <SimpleCard title="Files" isLoading={isLoading}>
            {libraryFiles
                .filter((_, i) => showAll || (i >= minRow && i <= maxRow))
                .map(m => {
                    return (
                        <div
                            key={m.id}
                            className="card card-hover border mb-2 cursor-pointer"
                            onClick={e => downloadFile(e, m)}
                        >
                            <div className="row">
                                <div className="col-6 col-sm-4">
                                    <span className="d-block text-center pl-3" style={{ fontSize: '55px' }}>
                                        <IconFactory
                                            icon={{ type: 'fa', id: getFileIcon(m.fileName) }}
                                            isVisible={true}
                                        />
                                    </span>
                                </div>
                                <div className="col-6 col-sm-8 p-3">
                                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {m.fileName.substring(m.fileName.indexOf('_'), m.fileName.length)}
                                    </div>
                                    <div>{m.entityName}</div>
                                    <div>{m.description}</div>
                                    <div>{authorsList.find(f => f.key === `${m.createdBy}`)?.value}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            <Pagination
                key="pagination"
                page={page}
                itemsPerPage={pageSize}
                rowCount={libraryFiles.length}
                onPageChange={page => setPage(page)}
                toggleShowAll={toggleShowAll}
                showAll={showAll}
            />
        </SimpleCard>
    );
});

function FileList({ libraryFiles }) {
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);

    if (!libraryFiles || libraryFiles.length === 0) return null;

    return <FileListCard libraryFiles={libraryFiles} isLoading={isLoading} />;
}

const mapStateToProps = state => ({
    libraryFiles: state.libraryFiles
});

export default connect(mapStateToProps)(FileList);
