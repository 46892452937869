import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import AddMasterTermSeriesForm from '../Forms/AddMasterTermSeriesForm';

export default function AddMasterTermSeriesModal({ children }) {
    const [state, setState] = useState({ brand: '' });
    const close = () => setState({ brand: '' });
    const open = (brand) => setState({ brand, open: true });

    return (
        <>
            <Modal stle={{ overflow: 'hidden' }} isOpen={state.open} toggle={close} className="modal-md">
                <AppModalHeader toggle={close}>Add Series</AppModalHeader>
                <ModalBody>
                    <AddMasterTermSeriesForm {...state} cancel={close} />
                </ModalBody>
            </Modal>
            {children({ open, close })}
        </>
    );
}
