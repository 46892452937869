import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { getAxiosPostRequest } from '../apiHelpers';

export const apiResetPassword = user => {
    return axios(getAxiosPostRequest(['user', 'reset-password'], undefined, user))
        .then(({ data }) => {
            toastr.success('Reset Password', 'Success');
            return data;
        })
        .catch(err => {
            const msg = err.response?.data?.errors?.Password ?? err.message;
            toastr.error('Reset Password', `Response ${msg}`);
            throw err;
        });
};

export const apiUpdateUserRole = user => {
    return axios(getAxiosPostRequest(['user', 'update-user-role'], undefined, user))
        .then(({ data }) => {
            toastr.success('Update User Role', 'Success');
            return data;
        })
        .catch(err => {
            toastr.error('Update User Role', `Response ${err.message}`);
            throw err;
        });
};

export const apiDisableUser = user => {
    return axios(getAxiosPostRequest(['user', 'disable-user', user.userId], undefined, undefined))
        .then(({ data }) => {
            toastr.success('Disable User', 'Success');
            return data;
        })
        .catch(err => {
            toastr.error('Disable User', `Response ${err.message}`);
            throw err;
        });
};
