import React, { useContext } from 'react';
import { push } from 'connected-react-router';
import { cancelCommunication } from '../../../actions/Navigation/Communication/cancelCommunication';
import { completeCommunication } from '../../../actions/Navigation/Communication/completeCommunication';
import { connect } from 'react-redux';
import { createVisitAction } from '../../../actions/Navigation/VisitAction/createVisitAction';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import { safeDispatchPush } from '../../../reducers/util';
import { apiCalendarInvite } from '../../../actions/Api/Communication/apiCalendarInvite';
import { CompanyContext } from '../../Company/Provider/CompanyProvider';
import { MeetingContext } from '../../Meeting/Provider/MeetingProvider';
import { isRoiBooked } from '../../app/Pages/MeetingPage';
import { completeMeeting, CREATE_MEETING_TYPE_COMPLETE } from '../../../actions/Navigation/Meeting/completeMeeting';
import { cancelMeeting } from '../../../actions/Navigation/Meeting/cancelMeeting';
import {createMeeting} from "../../../actions/Navigation/Communication/createMeeting";
import {editMeeting} from "../../../actions/Navigation/Communication/editMeeting";

const addActionButton = (actions, addButton, buttonText, fontawesomeIcon, bootstrapColor, onClickAction) => {
    if (addButton) {
        actions.push({
            buttonText,
            fontawesomeIcon,
            bootstrapColor,
            onClickAction
        });
    }
};

function ViewCommunicationPageButtons({ formikProps, createVisitAction, sendEmail, completeMeeting, cancelMeeting, createMeeting, editMeeting }) {
    const { meeting } = useContext(MeetingContext);
    const { company } = useContext(CompanyContext);
    const actions = [];

    addActionButton(actions, meeting.status === "Complete", "Schedule Next Meeting", "fa-plus", "secondary", () => createMeeting(company.companyId))

    addActionButton(actions, ["Pending", "Cancelled", "Complete"].includes(meeting.status), "Edit Meeting Details", "fa-edit", "secondary", () => editMeeting(meeting));

    addActionButton(actions, true, 'Add Visit Action', 'fa-plus', 'secondary', () => {
        createVisitAction(company.companyId, meeting.meetingId);
    });

    const saveMeeting = () => {
        if (isRoiBooked(formikProps.initialValues.meeting)) {
            completeMeeting({ ...meeting, notes: formikProps.values.notes }, CREATE_MEETING_TYPE_COMPLETE);
        } else {
            formikProps.values.notes = meeting.notes;
            formikProps.handleSubmit();
        }
    };

    addActionButton(actions, meeting.status === 'Pending', 'Complete meeting', 'fa-check', 'success', saveMeeting);

    addActionButton(actions, true, 'Create visit report email', 'fa-envelope', 'success', () =>
        sendEmail(meeting.meetingId)
    );

    // addActionButton(
    // 	actions,
    // 	meeting.status === 'Pending' && user.userId === meeting.assignee.id,
    // 	'Send invite to my calendar',
    // 	'fa-calendar',
    // 	'success',
    // 	() => sendCalendarInvite(meeting.meetingId, url)
    // );
    addActionButton(actions, meeting.status === 'Pending', 'Cancel Appointment', 'fa-ban', 'danger', () =>
        cancelMeeting(meeting)
    );

    return <ActionButtons actions={actions} />;
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    cancelMeeting: (communication, type) => dispatch(cancelMeeting(communication, type)),
    cancelCommunication: (communication, type) => dispatch(cancelCommunication(communication, type)),
    completeCommunication: (communication, type) => dispatch(completeCommunication(communication, type)),
    completeMeeting: (meeting, type) => dispatch(completeMeeting(meeting, type)),
    sendEmail: communicationId => safeDispatchPush(dispatch, push, ['communication', 'email', communicationId]),
    createVisitAction: (companyId, parentCommunicationId) => {
        dispatch(createVisitAction(companyId, parentCommunicationId));
    },
    sendCalendarInvite: (communicationId, url) => dispatch(apiCalendarInvite(communicationId, url)),
    createMeeting: companyId => dispatch(createMeeting(companyId)),
    editMeeting: meeting => dispatch(editMeeting(meeting))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCommunicationPageButtons);
