import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { LargeFormIcon, LargeFormTitle } from './FormikLabel';

export const FormikMultiselect = ({ title, fieldName, values, options, expand, ...rest }) => {
    const [isOpen, setIsOpen] = useState(expand);
    const toggle = () => setIsOpen(!isOpen);

    const value = values?.[fieldName] || '';
    const checkedKeys = value.split(',');

    const optionsWithChecked = options
        .map(option => ({
            ...option,
            isChecked: checkedKeys.includes(option.key)
        }))
        .filter(option => isOpen || option.isChecked);

    return (
        <div className="mb-2">
            <div className="d-flex justify-content-between mt-4 mb-2" onClick={toggle} style={{ cursor: 'pointer' }}>
                <LargeFormTitle title={title} />
                <LargeFormIcon value={isOpen} />
            </div>
            <DataList fieldName={fieldName} value={value} data={optionsWithChecked} {...rest} />
        </div>
    );
};

FormikMultiselect.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    expand: PropTypes.bool
};

FormikMultiselect.defaultProps = {
    title: '',
    options: [],
    expand: false
};

const handleChange = (fieldName, values, value, key, setFieldValue, onFormValueChanged) => {
    const currentData = value.length === 0 ? [] : value.split(',');
    const newData = [key, ...currentData].join(',');
    const filteredData = currentData.filter(value => value !== key).join(',');

    if (currentData.includes(key)) {
        setFieldValue(fieldName, filteredData);
        onFormValueChanged && onFormValueChanged(values, fieldName, filteredData);
    } else {
        setFieldValue(fieldName, newData);
        onFormValueChanged && onFormValueChanged(values, fieldName, newData);
    }
};

const DataList = ({ data, values, fieldName, value, setFieldValue, onFormValueChanged }) =>
    data.map(item => {
        return (
            <Label className="checkbox-container" key={item.key}>
                {item.value}
                <Input
                    checked={item.isChecked}
                    onChange={() => handleChange(fieldName, values, value, item.key, setFieldValue, onFormValueChanged)}
                    type="checkbox"
                />
                <span className="checkbox-checkmark" />
            </Label>
        );
    });

DataList.propTypes = {
    data: PropTypes.array,
    values: PropTypes.object,
    fieldName: PropTypes.string,
    setFieldValue: PropTypes.func,
    onFormValueChanged: PropTypes.func
};

DataList.defaultProps = {
    data: [],
    values: {},
    fieldName: ''
};
