import {
	basicColumnDef,
	choiceListColumnDef,
	dateColumnDef,
	idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

const demosDef = {
	id: idColumnDef('id', 'demoId'),
	brand: {
		...basicColumnDef('Brand', 'brand'),
		type: 'text'
	},
	bookingRef: {
		...basicColumnDef('Booking Ref', 'bookingRef'),
		type: 'text'
	},
	bodyGroupCode: {
		...basicColumnDef('Body Group Code', 'bodyGroupCode'),
		type: 'text'
	},
	vehicleDescriptionRequested: {
		...basicColumnDef('Vehicle Description (Requested)', 'vehicleDescriptionRequested'),
		type: 'text'
	},
	vehicleDescriptionActual: {
		...basicColumnDef('Vehicle Description (Actual)', 'vehicleDescriptionActual'),
		type: 'text'
	},
	transmission: {
		...basicColumnDef('Transmission', 'transmission'),
		type: 'text'
	},
	registrationNumber: {
		...basicColumnDef('Registration Number', 'registrationNumber'),
		type: 'text'
	},
	fuelType: {
		...basicColumnDef('Fuel Type', 'fuelType'),
		type: 'text'
	},
	keyForKey: {
		...basicColumnDef('Key for Key', 'keyForKey'),
		type: 'text'
	},
	demoFrom: {
		...dateColumnDef('Demo Delivery Date', 'dateFrom')
	},
	demoTo: {
		...dateColumnDef('Demo Collection Date', 'dateTo')
	},
	deliveryAddress1: {
		...basicColumnDef('Line 1', 'deliveryAddress1'),
		type: 'text'
	},
	deliveryAddress2: {
		...basicColumnDef('Line 2', 'deliveryAddress2'),
		type: 'text'
	},
	deliveryAddress3: {
		...basicColumnDef('Line 3', 'deliveryAddress3'),
		type: 'text'
	},
	deliveryAddressCity: {
		...basicColumnDef('City', 'deliveryAddressCity'),
		type: 'text'
	},
	deliveryAddressCounty: {
		...basicColumnDef('County', 'deliveryAddressCounty'),
		type: 'text'
	},
	deliveryAddressPostcode: {
		...basicColumnDef('Postcode', 'deliveryAddressPostcode'),
		type: 'text'
	},
	status: {
		...choiceListColumnDef('Status', 'status', 'demoStatus')
	}
};

export default demosDef;
