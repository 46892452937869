import React from 'react';
import _ from 'lodash';
import IconFactory from '../IconFactory';
import { useResources } from '../../../selectors/Api/useResources';

const CaptionBadge = props => {
    //title for hover over text
    const { title, caption, resources, choiceList, deselected, className, onClick, children } = props;
    let value = caption ? caption.toString() : '';

    if (!resources)
        return 'broken';

    let choice =
        resources.choiceList[choiceList] &&
        resources.choiceList[choiceList].find(
            x => toLower(x.key) === toLower(value) || toLower(x.value) === toLower(value)
        );

    if (!choice) {
        choice =
            resources.choiceList[choiceList] &&
            resources.choiceList[choiceList].find(x => x.key.toString() === value || x.value === value.toString());
    }

    let style = {};
    let type = 'badge-light';
    if (deselected) {
        type = 'badge-light line-through';
    } else {
        if (choice) {
            if (choice.color && choice.color[0].indexOf('#') === 0) {
                type = `text-${choice.color[1]}`;
                style = { backgroundColor: choice.color[0] };
            } else if (choice.color) {
                type = `badge-${choice.color[0]} text-${choice.color[1]}`;
            }
        }
    }
    if (choice) {
        value = choice.value || value;
    }

    return (
        <span
            title={title}
            className={`${className} badge ${type}`}
            style={style}
            onClick={onClick ? () => onClick() : undefined}
        >
            <IconFactory icon={_.get(choice, 'icon')} isVisible={!deselected} />
            {children || value}
        </span>
    );
};

const toLower = val => (typeof val === 'string' ? val.toLowerCase() : val);

export function CaptionBadgeResources(props) {
    const resources = useResources();
    return <CaptionBadge {...props} resources={resources} />;
}
export default CaptionBadge;
