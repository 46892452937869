import React, { useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { EditableInputRenderer } from '@returnoninvestment/base-table';
import ShowLoadingSpinner from '../../ShowLoadingSpinner';
import { useToolTipContext } from '../../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';

export function firstWithValue(...params) {
    if (!params) return;

    for (const vl of params) {
        if (vl !== undefined && vl !== null) return vl;
    }
}

export function UpDownArrow({ downIsGood = true, first, second, downTitle, upTitle, equal }) {
    const { setTooltipOpen, setTooltipClosed } = useToolTipContext();
    return (
        <>
            {first < second ? (
                <i
                    className="fa fa-arrow-down"
                    onMouseEnter={() => setTooltipOpen(downTitle)}
                    onMouseLeave={setTooltipClosed}
                    style={{ color: downIsGood ? 'green' : 'red' }}
                />
            ) : first > second ? (
                <i
                    className="fa fa-arrow-up"
                    onMouseEnter={() => setTooltipOpen(upTitle)}
                    onMouseLeave={setTooltipClosed}
                    style={{ color: downIsGood ? 'red' : 'green' }}
                />
            ) : (
                equal
            )}
        </>
    );
}

export function ComparePercentage({
    value,
    defaultValue,
    lastRevisionValue,
    editable,
    onUpdate,
    dataKey,
    dataKeys,
    rowKey,
    downIsGood = true,
    rowData,
    useTableStore
}) {
    const getMaxValue = useTableStore(state => state.getMaxValue);
    const readOnly = useTableStore(state => state.saving);

    const max = useMemo(() => {
        return getMaxValue(
            dataKeys ?? dataKey,
            rowData.index,
            undefined,
            dataKey === 'rebate' || dataKey === 'nextRebate'
        );
    }, [getMaxValue, dataKey, dataKeys, rowData.index]);

    return (
        <EditableInputRenderer
            dataKey={dataKey}
            editable={editable}
            inputAddOn={currentValue => (
                <span className="input-group-text" style={{ cursor: 'initial' }}>
                    {(lastRevisionValue !== undefined || lastRevisionValue !== null) &&
                    lastRevisionValue !== defaultValue ? (
                        <UpDownArrow
                            downIsGood={downIsGood}
                            downTitle={`Less than last revision value of ${lastRevisionValue?.toFixed(2)}%`}
                            equal={<i className="fa fa-percent" />}
                            first={currentValue}
                            second={lastRevisionValue}
                            upTitle={`Greater than last revision value of ${lastRevisionValue?.toFixed(2)}%`}
                        />
                    ) : (
                        <UpDownArrow
                            downIsGood={downIsGood}
                            downTitle={`Less than default value of ${defaultValue?.toFixed(2)}%`}
                            equal={<i className="fa fa-percent" />}
                            first={currentValue}
                            second={defaultValue}
                            upTitle={`Greater than default value of ${defaultValue?.toFixed(2)}%`}
                        />
                    )}
                </span>
            )}
            inputOptions={{ max, step: 0.01, readOnly }}
            loadingElement={<ShowLoadingSpinner isLoading={true} />}
            onError={msg => toastr.error('Update', msg)}
            onInputChangeMutate={currentValue => parseFloat(currentValue) || 0}
            onUpdate={currentValue =>
                onUpdate(dataKey, rowData[rowKey], currentValue).then(response => {
                    if (response.length === 1 && response[0][rowKey]) {
                        return response[0][dataKey];
                    }
                    throw new Error('Invalid Server Response');
                })
            }
            type="number"
            value={firstWithValue(value, lastRevisionValue)?.toFixed(2)}
        />
    );
}

export function DualComparePercentage({
    rowData,
    rowKey,
    dataKey,
    dataKeys,
    editable,
    onUpdate,
    value,
    baseValue,
    previousValue,
    useTableStore
}) {
    const getMaxValue = useTableStore(state => state.getMaxValue);
    const readOnly = useTableStore(state => state.saving);

    const max = useMemo(() => {
        return getMaxValue(
            dataKeys ?? dataKey,
            rowData.index,
            undefined,
            dataKey === 'rebate' || dataKey === 'nextRebate'
        );
    }, [getMaxValue, dataKey, dataKeys, rowData.index]);

    return (
        <EditableInputRenderer
            dataKey={dataKey}
            editable={editable}
            inputAddOn={currentValue => (
                <span
                    className="input-group-text"
                    style={{ cursor: 'initial', width: '40px', justifyContent: 'center' }}
                >
                    {Math.sign(currentValue - baseValue) === Math.sign(currentValue - previousValue) ? (
                        <>
                            <UpDownArrow
                                downTitle={
                                    <>
                                        <div>Less than base value of {baseValue?.toFixed(2)}%</div>
                                        <div>Less than last revision value of {previousValue?.toFixed(2)}%</div>
                                    </>
                                }
                                equal={<i className="fa fa-percent" />}
                                first={currentValue}
                                second={baseValue}
                                upTitle={
                                    <>
                                        <div>Greater than base value of {baseValue?.toFixed(2)}%</div>
                                        <div>Greater than last revision value of {previousValue?.toFixed(2)}%</div>
                                    </>
                                }
                            />
                            <span />
                        </>
                    ) : (
                        <>
                            <UpDownArrow
                                downTitle={`Less than base value of ${baseValue?.toFixed(2)}%`}
                                equal={<i className="fa fa-percent" />}
                                first={currentValue}
                                second={baseValue}
                                upTitle={`Greater than base value of ${baseValue?.toFixed(2)}%`}
                            />
                            <UpDownArrow
                                downTitle={`Less than last revision value of ${previousValue?.toFixed(2)}%`}
                                equal={
                                    previousValue !== undefined && previousValue !== null ? (
                                        <i className="fa fa-percent" />
                                    ) : null
                                }
                                first={currentValue}
                                second={previousValue}
                                upTitle={`Greater than last revision value of ${previousValue?.toFixed(2)}%`}
                            />
                        </>
                    )}
                </span>
            )}
            inputOptions={{ max, step: 0.01, readOnly }}
            loadingElement={<ShowLoadingSpinner isLoading={true} />}
            onError={msg => toastr.error('Update', msg)}
            onInputChangeMutate={currentValue => parseFloat(currentValue) || 0}
            onUpdate={currentValue =>
                onUpdate(dataKey, rowData[rowKey], currentValue).then(response => {
                    if (response.length === 1 && response[0][rowKey]) {
                        return response[0][dataKey];
                    }
                    throw new Error('Invalid Server Response');
                })
            }
            type="number"
            value={firstWithValue(value, previousValue, baseValue)?.toFixed(2)}
        />
    );
}

//function setsAreEqual(setA, setB) {
//    return Array.from(setA.keys()).every(e => setB.has(e));
//}

export function HeaderEditRenderer({ editable, rowData, useTableStore, children }) {
    const selector = state => {
        const row = state.dataState.pageData[rowData.index];
        return new Set(row.keysInGroup.filter(f => state.selectedRowKeys.has(f)));
    };
    const selectedRowKeys = useTableStore(selector); //will re-render on any state change, need setsAreEqual to work
    const visible = editable && selectedRowKeys.size > 0;
    return <div style={{ visibility: visible ? 'visible' : 'hidden' }}>{children({ selectedRowKeys })}</div>;
}

export function EditablePercentage({
    value,
    editable,
    onUpdate,
    dataKey,
    inputOptions,
    useTableStore,
    selectedRowKeys
}) {
    const getMaxValue = useTableStore(state => state.getMaxValue);
    const readOnly = useTableStore(state => state.saving);

    const max = useMemo(() => {
        return getMaxValue(dataKey, undefined, selectedRowKeys, dataKey === 'rebate' || dataKey === 'nextRebate');
    }, [getMaxValue, dataKey, selectedRowKeys]);

    return (
        <EditableInputRenderer
            dataKey={dataKey}
            editable={editable}
            inputAddOn={() => (
                <>
                    <span className="input-group-text" style={{ cursor: 'initial' }}>
                        <i className="fa fa-percent" />
                    </span>
                </>
            )}
            inputOptions={{ step: 0.01, ...inputOptions, max, readOnly }}
            loadingElement={<ShowLoadingSpinner isLoading={true} />}
            onError={msg => toastr.error('Update', msg)}
            onInputChangeMutate={currentValue => parseFloat(currentValue)}
            onUpdate={value => onUpdate(dataKey, value)}
            type="number"
            value={value?.toFixed(2)}
        />
    );
}

export function TotalRenderer({ rowData, useTableStore }) {
    const totalDiscount = useTableStore(state => state.dataState.pageData[rowData.index].totalDiscount);
    const nextTotalDiscount = useTableStore(state => state.dataState.pageData[rowData.index].nextTotalDiscount);
    const approvalLimit = useTableStore(state => state.dataState.pageData[rowData.index].approvalLimit);
    const approvalLimitFinance = useTableStore(state => state.dataState.pageData[rowData.index].approvalLimitFinance);
    const approvalLevel = useTableStore(state => state.dataState.pageData[rowData.index].approvalLevel);

    const displayedTotalDiscount = nextTotalDiscount != null ? nextTotalDiscount : totalDiscount;

    return (
        <EditableInputRenderer
            inputAddOn={() => (
                <span className="input-group-text" style={{ cursor: 'initial' }}>
                    {displayedTotalDiscount > approvalLimitFinance ? (
                        <i
                            className={`fa fa-warning ${approvalLevel ? 'text-danger' : 'text-positive'}`}
                            title={`Over finance approval limit of ${approvalLimitFinance?.toFixed(2)}%`}
                        />
                    ) : displayedTotalDiscount > approvalLimit ? (
                        <i
                            className={`fa fa-warning ${approvalLevel ? 'text-danger' : 'text-positive'}`}
                            title={`Over approval limit of ${approvalLimit?.toFixed(2)}%`}
                        />
                    ) : (
                        <i
                            className="fa fa-check text-positive"
                            title={`Under approval limit of ${approvalLimit?.toFixed(2)}%`}
                        />
                    )}
                </span>
            )}
            inputOptions={{ step: 0.01 }}
            type="text"
            value={`${displayedTotalDiscount?.toFixed(2)}%`}
        />
    );
}

const nextOrCurrent = (next, current) => (next || next === 0 ? next : current);

export function TotalSupportRenderer({ rowData, useTableStore }) {
    const nextBaseDiscount = useTableStore(state => state.dataState.pageData[rowData.index].nextBase);
    const baseDiscount = useTableStore(state => state.dataState.pageData[rowData.index].base);
    const nextDiscretionaryDiscount = useTableStore(state => state.dataState.pageData[rowData.index].nextDiscretionary);
    const discretionaryDiscount = useTableStore(state => state.dataState.pageData[rowData.index].discretionary);

    const displayedTotalDiscount =
        nextOrCurrent(nextBaseDiscount, baseDiscount) + nextOrCurrent(nextDiscretionaryDiscount, discretionaryDiscount);

    return (
        <EditableInputRenderer
            inputAddOn={() => (
                <span className="input-group-text" style={{ cursor: 'initial' }}>
                    <i className="fa fa-percent text-positive" />
                </span>
            )}
            inputOptions={{ step: 0.01 }}
            type="text"
            value={displayedTotalDiscount?.toFixed(2)}
        />
    );
}
