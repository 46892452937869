import React from 'react';
import { Bar } from 'react-chartjs-2';
import DashboardAdminCard from '../Dashboard/DashboardAdminCard';
import { useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel } from './useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true
                }
            }
        ]
    }
};

const CreatedBySalesChannelGraph = ({ agreementsAdminStatusGroupedData }) => {
    const openAgreementsSearch = useSafeDispatchPush(() => ['agreement']);
    const graphData = useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel(
        agreementsAdminStatusGroupedData,
        'AgreementsCreated'
    );

    const getElementAtEvent = element => {
        if (!element.length) return;
        const { _datasetIndex, _index } = element[0];

        openAgreementsSearch(
            {},
            {
                createdAfter: graphData.months[_index].start,
                createdBefore: graphData.months[_index].end,
                salesChannel: graphData.datasets[_datasetIndex].key
            }
        );
    };

    return (
        <DashboardAdminCard helpId="agreementsAdminAgreementsCard" title="Agreements Created By Sales Channel">
            <div className="slight-glow" style={{ background: 'rgb(245 245 250)' }}>
                <Bar data={graphData} getElementAtEvent={getElementAtEvent} options={options} />
            </div>
        </DashboardAdminCard>
    );
};

export default CreatedBySalesChannelGraph;
