import { getStatusColor } from '../agreementPermissions';

export function useStatusChangeButtons(workflow, statusFrom) {
    return (workflow || []).filter(x => x.isVisible && x.btnTitle).map((x, index) => ({
        permission: x,
        buttonText: x.btnTitle,
        modalTitle: x.btnTitle,
        statusFrom,
        buttonType: getStatusColor(x.btnStatusTo),
        statusTo: x.btnStatusTo,
        evidenceMandatory: x.evidenceMandatory,
        helpText: x.helpText,
        id: index
    }));
}
