import _ from 'lodash';
export const SET_COLLAPSED = 'SET_COLLAPSED';

export function collapsed(
    //TODO: create this state from FormPaths
    state = {},
    action
) {
    switch (action.type) {
        case SET_COLLAPSED:
            const newState = _.clone(state);
            newState[action.data.name] = action.data.isCollapsed;
            return newState;
        default:
            return state;
    }
}
