import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import AgreementUpliftForm from '../Forms/AgreementUpliftForm';
import { useAgreementsStore } from '../Provider/useAgreementsStore';

export default function AgreementUpliftModal() {
    const isOpen = useAgreementsStore(state => state.modalOpen);
    const reference = useAgreementsStore(state => state.agreement?.reference);
    const close = useAgreementsStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Uplift Agreement - {reference || 'No Reference'}</AppModalHeader>
                <ModalBody>
                    <AgreementUpliftForm />
                </ModalBody>
            </Modal>
        </>
    );
}
