import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CSVLink } from 'react-csv';
import queryString from 'query-string';
import moment from 'moment';
import BaseTableInCard, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import useDimensions from '../../common/Hooks/useDimensions';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { apiAgreementsDelete } from '../../../actions/Api/Agreement/apiAgreement';
import { useGoToAgreementPage } from '../../Agreement/Table/CompanyAgreementsTableCard';
import { useAgreementsStore } from '../../Agreement/Provider/useAgreementsStore';
import { permissions, useUsersPermission } from '../../../reducers/Api/User/permissions';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';
import { useAddendumRulesStore } from '../../Addendum/Provider/useAddendumRulesStore';
import { addendumProcessStatus } from '../../Addendum/addendumDef';
import agreementDef from '../../Agreement/agreementDef';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

function useDownloadAgreementsCsv(fromAddendumPage) {
    const agreements = useAgreementSearchTableData();
    const addendumId = useAddendaStore(state => state.addendum?.addendumId);
    const versionId = useAddendaStore(state => state.addendum?.masterTermVersionId);
    const rulesCount = useAddendumRulesStore(state => state.data?.length);
    const [data, setData] = useState([]);
    const csvRef = useRef();
    const users = useChoiceListForBaseTable('userAuthors');
    const salesChannels = useChoiceListForBaseTable(agreementDef.salesChannel.choiceList);
    const agreementTypes = useChoiceListForBaseTable(agreementDef.agreementType.choiceList);
    const matrixBandings = useChoiceListForBaseTable(agreementDef.matrixBanding.choiceList);

    const getData = () => {
        const mapped = agreements.map(agreement => {
            const author = users.get(agreement.createdBy?.toString());
            const startDate = moment(agreement.startDate).format(process.env.REACT_APP_DATE_FORMAT);
            const endDate = moment(agreement.endDate).format(process.env.REACT_APP_DATE_FORMAT);
            const salesChannel = salesChannels.get(agreement.salesChannel);
            const agreementType = agreementTypes.get(agreement.agreementType);
            const matrixBanding = matrixBandings.get(agreement.matrixBanding);

            const ruleMatches = agreement.addendumRules?.map(rule => rule.name).join(',');
            const addendumProcessedDate = moment(agreement.addendumProcessedDate).format(
                process.env.REACT_APP_DATE_FORMAT
            );

            const arr = [
                agreement.reference,
                author,
                agreement.termsOfBusiness,
                agreement.companyName?.name,
                startDate,
                endDate,
                salesChannel,
                agreementType
            ];

            if (fromAddendumPage) {
                arr.push(
                    ...[
                        matrixBanding,
                        agreement.status,
                        agreement.masterTermVersionId !== versionId ? 'Yes' : 'No',
                        rulesCount > 0 ? ruleMatches || 'None' : 'N/A',
                        agreement.addendumId === addendumId ? 'Yes' : 'No',
                        agreement.status === 'Addendum Approved'
                            ? 'Manually Approved'
                            : agreement.addendumProcessStatus
                            ? `${addendumProcessStatus[agreement.addendumProcessStatus]} on ${addendumProcessedDate}`
                            : 'No'
                    ]
                );
            }

            return arr;
        });
        setData(mapped);
    };

    useEffect(() => {
        if (data.length > 0 && csvRef.current && csvRef.current.link) {
            setTimeout(() => {
                csvRef.current && csvRef.current.link.click();
                setData([]);
            });
        }
    }, [data]);

    return {
        onClick: getData,
        content: (
            <>
                Download CSV
                {data.length > 0 ? (
                    <CSVLink
                        data={data}
                        filename={`agreements.csv`}
                        headers={[
                            agreementDef.reference.title,
                            agreementDef.createdBy.title,
                            agreementDef.termsOfBusiness.title,
                            agreementDef.companyName.title,
                            agreementDef.startDate.title,
                            agreementDef.endDate.title,
                            agreementDef.salesChannel.title,
                            agreementDef.agreementType.title,
                            ...(fromAddendumPage
                                ? [
                                      agreementDef.matrixBanding.title,
                                      agreementDef.status.title,
                                      'Updating Version',
                                      'Rules Matches',
                                      'On Addendum',
                                      'Processed'
                                  ]
                                : [])
                        ]}
                        ref={csvRef}
                    />
                ) : null}
            </>
        ),
        icon: 'fa-arrow-down',
        id: 'downloadAgreements'
    };
}

function useTableButtons(fromAddendumPage) {
    const dispatch = useDispatch();
    const canPurge = useUsersPermission(permissions.PURGE_DELETED_AGREEMENTS).canWrite;
    const deletedAgreements = useAgreementsStore(state => state.data).filter(x => x.status === 'DELT');
    const removeDeleted = useAgreementsStore(state => state.removeDeleted);
    const downloadAgreementsCsv = useDownloadAgreementsCsv(fromAddendumPage);
    const buttons = [downloadAgreementsCsv];

    if (canPurge && deletedAgreements.length) {
        buttons.push({
            onClick: () =>
                dispatch(apiAgreementsDelete(deletedAgreements.map(x => x.agreementId)))
                    .then(response => {
                        if (response) removeDeleted();
                    })
                    .catch(response => console.error(response)),
            content: 'Purge Deleted Agreements',
            icon: 'fa-trash',
            id: 'purgedeletedagreements'
        });
    }

    return buttons;
}

function Table({ columns, data, groupBy, headerEditRenderer, height, selectable = false, fromAddendumPage = false }) {
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const buttons = useTableButtons(fromAddendumPage);
    const goToPage = useGoToAgreementPage();
    const [ref, { y }] = useDimensions({ liveMeasure: false });
    return (
        <BaseTableInCard
            buttons={buttons}
            cardTitle="Agreements"
            columns={columns}
            data={data}
            expandAll={true}
            groupBy={groupBy}
            headerEditRenderer={headerEditRenderer}
            helpId="agreementsTableHeading"
            isLoading={isLoading}
            noMessageText="No Matching Agreements Found"
            onRowClicked={({ rowData }) => !rowData.groupRow && goToPage(rowData)}
            refForTableHeight={ref}
            rowClassName={rowData => !rowData.termsVisible && 'bg-lightgrey cursor-not-allowed'}
            rowKey={agreementDef.id.field}
            selectable={selectable}
            tableSizeStyle={{ height: height || `calc(100vh - ${y}px - 1rem`, width: '100%' }}
        />
    );
}

export const useAgreementsTableDefaultColumns = (showVersionColumn = false) => {
    return [
        agreementDef.reference,
        agreementDef.createdBy,
        agreementDef.termsOfBusiness,
        agreementDef.companyName,
        agreementDef.startDate,
        agreementDef.endDate,
        showVersionColumn && agreementDef.masterTermVersion,
        useChoicelistCellDefinition(agreementDef.salesChannel),
        useChoicelistCellDefinition(agreementDef.agreementType, { width: 160 })
    ].filter(x => !!x);
};

export function useAgreementSearchTableData(customMap = () => ({})) {
    const agreements = useAgreementsStore(state => state.data);
    const masterTermVersions = useMasterTermVersionsStore(state => state.data);
    const masterTermVersionsMap = new Map((masterTermVersions || []).map(x => [x.masterTermVersionId, x]));
    const companyMap = useSelector(state => new Map(state.companies.map(m => [m.companyId, m])));
    const statusChoiceMap = useChoiceListForBaseTable('agreementHeaderStatus');

    return agreements.map(m => {
        const company = companyMap.get(m.companyId);
        if (company) m[agreementDef.companyName.field] = { ...company };

        return {
            ...m,
            status: statusChoiceMap.get(m.status),
            ...customMap(m),
            masterTermVersion: m.masterTermVersionId ? masterTermVersionsMap.get(m.masterTermVersionId) : undefined
        };
    });
}

export default function AgreementsTable({
    columns,
    customMap,
    groupBy = ['status'],
    headerEditRenderer,
    height,
    selectable = false,
    fromAddendumPage = false
}) {
    const tableData = useAgreementSearchTableData(customMap);
    const { search } = useLocation();
    const showVersionColumn = search && queryString.parse(search)?.masterTermVersionId;
    const defaultColumns = useAgreementsTableDefaultColumns(showVersionColumn);
    const tableColumns = columns || defaultColumns;

    return (
        <Table
            columns={tableColumns}
            data={tableData}
            fromAddendumPage={fromAddendumPage}
            groupBy={groupBy}
            headerEditRenderer={headerEditRenderer}
            height={height}
            selectable={selectable}
        />
    );
}
