import React, { useState } from 'react';
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import _ from 'lodash';
import NotificationFactory from './NotificationFactory';

export const STATUS = {
    READ: 'READ',
    CREATED: 'CREATED'
};

const Notifications = ({ data }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const newNotifications = _.filter(data, notification => notification.status === STATUS.CREATED);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle aria-expanded={dropdownOpen} data-toggle="dropdown" tag="span">
                <li className={`nav-item `}>
                    <div className="nav-link nav-bar-nav-item m-0 p-0">
                        <div className="m-1 p-1" onClick={() => {}} onKeyDown={() => {}} role="button" tabIndex={0}>
                            <i className={`fa d-inline mr-2 ml-3" fa-comment`} />
                            Notifications{' '}
                            {(newNotifications.length > 0 ||
                                (data?.length > 0 && data[data.length - 1].moreUnread > 0)) && (
                                <Badge color="danger">
                                    {newNotifications.length + data[data.length - 1].moreUnread}
                                </Badge>
                            )}
                        </div>
                    </div>
                </li>
            </DropdownToggle>
            <DropdownMenu
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    height: 'max-content',
                                    transform: 'none',
                                    width: '390px',
                                    right: '-100px',
                                    top: '50px',
                                    left: 'inherit'
                                }
                            };
                        }
                    }
                }}
                right
            >
                <div
                    onClick={toggle}
                    onKeyDown={() => {}}
                    role="button"
                    style={{
                        padding: 15,
                        width: 375
                    }}
                    tabIndex={0}
                >
                    <h4 className="logo-font gradient my-0 py-0 d-inline">Notifications</h4>
                    <hr />
                    <NotificationFactory notifications={data} />
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default Notifications;
