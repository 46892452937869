import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const ActionButton = props => {
    const { buttonText, to, fontawesomeIcon, buttonClass, bootstrapColor, onClickAction, disabled, ...rest } = props;

    return to ? (
        <Link className="h-100 text-decoration-none" style={{ color: 'black' }} to={to}>
            <ListGroupItem
                action
                className="hover-pointer d-flex"
                color={bootstrapColor}
                disabled={disabled}
                tag="button"
                type="button"
            >
                <i className={`fa ${fontawesomeIcon} mr-3`} />
                {buttonText}
            </ListGroupItem>
        </Link>
    ) : buttonClass ? (
        <button
            className={buttonClass}
            color={bootstrapColor}
            disabled={disabled}
            onClick={onClickAction}
            type="button"
            {...rest}
        >
            {buttonText}
        </button>
    ) : (
        <ListGroupItem
            action
            className="hover-pointer d-flex"
            color={bootstrapColor}
            disabled={disabled}
            onClick={() => onClickAction()}
            tag="button"
            type="button"
        >
            <i className={`fa ${fontawesomeIcon} mr-3`} />
            {buttonText}
        </ListGroupItem>
    );
};

ActionButton.defaultProps = {
    buttonText: PropTypes.string.isRequired,
    fontawesomeIcon: PropTypes.string.isRequired,
    bootstrapColor: PropTypes.string.isRequired,
    onClickAction: PropTypes.func.isRequired
};

export default ActionButton;
