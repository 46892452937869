import React from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ExpandIcon, types, ToggleRenderer, ChoiceListRenderer } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { DeleteTableRowButton, TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';
import {
    useAgreementNotificationStore,
    useAgreementNotificationPeopleStore,
    useUpdateNotificationPeople
} from '../Provider/index';
import { agreementNotificationPeopleDef } from '../agreementNotificationDef';
import useDimensions from '../../common/Hooks/useDimensions';
import BaseTable from '../../common/BaseTable/BaseTable';

function ActionsRenderer({ onRemoveNotification, disabled, rowData }) {
    const onDelete = event => {
        PreventClickThrough(event);
        onRemoveNotification(rowData);
    };

    return <TableButtonsContainer>{!disabled && <DeleteTableRowButton onClick={onDelete} />}</TableButtonsContainer>;
}

export const useAgreementNotificationPeopleColumns = groupBy => {
    const notifications = useAgreementNotificationStore(state => state.data);
    const notificationsMap = new Map(notifications.map(m => [m.agreementNotificationId, m.agreementNotificationName]));
    const peopleList = useChoiceListForBaseTable('userAuthors');
    const updateNotifications = useUpdateNotificationPeople();

    const onToggleActive = (notification, checked) => {
        updateNotifications([{ ...notification, active: checked }]);
    };

    const onRemoveNotification = notification => {
        updateNotifications([{ ...notification, deleted: 1 }]);
    };

    const columns = [
        {
            ...agreementNotificationPeopleDef.agreementNotificationId,
            type: types.SELECT,
            select: notificationsMap
        },
        {
            ...agreementNotificationPeopleDef.person,
            type: types.SELECT,
            select: peopleList,
            cellRenderer: props => <ChoiceListRenderer columnDef={agreementNotificationPeopleDef.person} {...props} />
        },
        {
            ...agreementNotificationPeopleDef.active,
            width: 70,
            noGrow: true,
            cellRenderer: props => <ToggleRenderer {...props} onToggle={onToggleActive} />
        },
        {
            field: 'actions',
            title: 'Actions',
            width: 70,
            noGrow: true,
            cellRenderer: props => (
                <ActionsRenderer {...props} disabled={false} onRemoveNotification={onRemoveNotification} />
            )
        }
    ];

    return columns.reduce((acc, f) => (groupBy !== f.field ? [...acc, f] : acc), []);
};

const getGroupByText = groupBy => {
    if (groupBy === agreementNotificationPeopleDef.agreementNotificationId.field) return 'Notification';
    else if (groupBy === agreementNotificationPeopleDef.person.field) return 'Person';
    else return 'None';
};

export function AgreementNotificationPeopleTableGroupBy({ groupBy, setGroupBy }) {
    return (
        <UncontrolledDropdown className="d-inline-block dropdown">
            <DropdownToggle caret className="text-white">
                Group By: {getGroupByText(groupBy)}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-info" right>
                <DropdownItem
                    active={!groupBy}
                    onClick={() => setGroupBy(undefined)}
                >
                    None
                </DropdownItem>
                <DropdownItem
                    active={groupBy === agreementNotificationPeopleDef.agreementNotificationId.field}
                    onClick={() => setGroupBy(agreementNotificationPeopleDef.agreementNotificationId.field)}
                >
                    Notification
                </DropdownItem>
                <DropdownItem
                    active={groupBy === agreementNotificationPeopleDef.person.field}
                    onClick={() => setGroupBy(agreementNotificationPeopleDef.person.field)}
                >
                    Person
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

const AgreementNotificationPeopleTable = ({ groupBy }) => {
    const notificationPeople = useAgreementNotificationPeopleStore(state => state.data);
    const openModal = useAgreementNotificationPeopleStore(state => state.openModal);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const notifications = useAgreementNotificationStore(state => state.data);
    const notificationsMap = new Map(notifications.map(m => [m.agreementNotificationId, m.agreementNotificationName]));
    const peopleList = useChoiceListForBaseTable('userAuthors');

    const columns = useAgreementNotificationPeopleColumns(groupBy);
    const [ref, { y }] = useDimensions({ liveMeasure: false });

    const getGroupValue = rowData => {
        if (groupBy === agreementNotificationPeopleDef.agreementNotificationId.field)
            return notificationsMap.get(rowData[groupBy]);
        else if (groupBy === agreementNotificationPeopleDef.person.field) return peopleList.get(rowData[groupBy]);
    };

    return (
        <div ref={ref} style={{ height: `calc(100vh - ${y}px - 1rem`, width: '100%' }}>
            <BaseTable
                cardTitle="Notification People"
                columns={columns}
                data={notificationPeople}
                expandAll={true}
                groupBy={groupBy ? [groupBy] : undefined}
                groupRenderer={({ rowData, ...rest }) => (
                    <div className="py-2" style={{ display: 'inherit' }}>
                        <ExpandIcon {...rowData} {...rest} />
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {getGroupValue(rowData)} ({rowData.groupCount})
                        </div>
                    </div>
                )}
                helpId="agreementNotificationPeopleTableHeading"
                isLoading={isLoading}
                onRowClicked={({ rowData }) => !rowData.groupRow && openModal(rowData)}
                rowKey={agreementNotificationPeopleDef.id.field}
            />
        </div>
    );
};

export default AgreementNotificationPeopleTable;
