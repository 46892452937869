import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { downloadAgreementPdf } from '../../../actions/Api/Agreement/downloadAgreementPdf';

export default function AgreementDownloadPdf({
    agreementId,
    children,
    className,
    companyName,
    revisionId
}) {
    const [downloading, setDownloading] = useState(false);

    if (!revisionId) return null;

    return (
        <Button
            className={className}
            color="info"
            onClick={e => downloadAgreementPdf(e, agreementId, revisionId, companyName, setDownloading)}
        >
            {!downloading ? (
                <>
                    <i className="fa fa-file-pdf-o mr-2" />
                    {children}
                </>
            ) : (
                <ShowLoadingSpinner isLoading size={18} />
            )}
        </Button>
    );
}
