import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import moment from 'moment';
import { isError } from '../../Loading/isError';
import { ENDPOINTS } from '../constants';
import { getAxiosPostOfflineRequest, getAxiosPostRequest, REST_TIME_FORMAT } from '../apiHelpers';
import { createFakeId } from '../../../reducers/util';
import { closeModal, FORM_PATHS } from '../../modals';

export const apiLeadEdit = leadForm => (dispatch, getState) => {

    const lead = { ...leadForm };

    if (lead.leadId === -1) {
        lead.createdDate = moment().format(REST_TIME_FORMAT);
        lead.updatedDate = moment().format(REST_TIME_FORMAT);
        lead.leadId = createFakeId();
    } else {
        lead.updatedDate = moment().format(REST_TIME_FORMAT);
    }

    if (lead.leadId < 0) {
        lead.leadId = null;
    }

    lead.dealershipId = getState().user.dealershipId;

    return axios(getAxiosPostRequest(['lead'], undefined, lead))
        .then(({ data }) => {
            toastr.success('Create UCN Request', 'Success');
            dispatch(closeModal(FORM_PATHS.CreateLead.modalName, data));
            return data;
        })
        .catch(err => {
            toastr.error('Create UCN Request', `Response ${err.message}`);
            throw err;
        });
};

export const apiLeadEditOffline = leadForm => async (dispatch, getState) => {
    const type = ENDPOINTS.API.LEAD_POST;

    const lead = { ...leadForm };

    if (lead.leadId === -1) {
        lead.createdDate = moment().format(REST_TIME_FORMAT);
        lead.updatedDate = moment().format(REST_TIME_FORMAT);
        lead.leadId = createFakeId();
    } else {
        lead.updatedDate = moment().format(REST_TIME_FORMAT);
    }

    try {
        const data = { ...lead };
        if (data.leadId < 0) {
            data.leadId = null;
        }

        data.dealershipId = getState().user.dealershipId;

        const axiosRequest = getAxiosPostOfflineRequest(['lead'], {}, data);

        let unmodifiedLead = getState().leads.find(x => x.leadId === lead.leadId);
        if (!unmodifiedLead) {
            unmodifiedLead = lead;
        }
        unmodifiedLead.error = undefined;

        dispatch({
            type,
            data: { ...lead, syncing: true },
            meta: {
                offline: {
                    effect: axiosRequest,
                    commit: {
                        type: ENDPOINTS.API.LEAD_POST_COMMIT,
                        meta: { lead }
                    },
                    rollback: {
                        type: ENDPOINTS.API.LEAD_POST_ROLLBACK,
                        meta: { unmodifiedLead, lead }
                    }
                }
            }
        });

        toastr.success('UCN Request created successfully.');
        //isLoading(dispatch, type, false, {});
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
