import React from 'react';
import { Card, CardBody } from 'reactstrap';

const BaseCard = props => {
    const { title, className, children, style = {}, marginClass = 'mb-4' } = props;
    return (
        <Card className={`${marginClass} shadow-sm ${className}`} style={style}>
            {title ? (
                <CardBody data-testid="title" className="base-table-in-card-header py-2">
                    {title}
                </CardBody>
            ) : null}
            <CardBody className="pt-0 mb-4 pt-4" data-test-id="card-body">
                {children}
            </CardBody>
        </Card>
    );
};

export default BaseCard;
