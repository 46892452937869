import { basicColumnDef, choiceListColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: idColumnDef('Id', 'companyId'),
    name: {
        ...basicColumnDef('Company Name', 'name'),
        type: 'companyName',
        filter: true,
        sortable: true
    },
    affiliatedTo: {
        ...basicColumnDef('Affiliated To', 'companyName'),
        type: 'companyName'
    },
    businessPhone: {
        ...basicColumnDef('Phone', 'businessPhone'),
        type: 'phone'
    },
    webSite: {
        ...basicColumnDef('Website', 'webSite'),
        type: 'link'
    },
    completedMeetings: {
        ...basicColumnDef('Completed Meetings', 'completedMeetings'),
        type: 'text'
    },
    lastMeetingDate: {
        ...basicColumnDef('Last Meeting Date', 'lastMeetingDate'),
        type: 'date'
    },
    nextMeetingDate: {
        ...basicColumnDef('Next Meeting Date', 'nextMeetingDate'),
        type: 'date'
    },
    registrationsInLast12Months: {
        ...basicColumnDef('Registrations Over 12 Months', 'registrationsInLast12Months'),
        type: 'text'
    },
    totalRegistrations: {
        ...basicColumnDef('Total Registrations', 'totalRegistrations'),
        type: 'text'
    },
    demosInLast12Months: {
        ...basicColumnDef('Demos Over 12 Months', 'demosInLast12Months'),
        type: 'text'
    },
    totalDemos: {
        ...basicColumnDef('Total Demos', 'totalDemos'),
        type: 'text'
    },
    descriptor: {
        ...basicColumnDef('Company', 'descriptor')
    },
    city: {
        ...basicColumnDef('City', 'city'),
        filter: true,
        sortable: true
    },
    postcode: {
        ...basicColumnDef('Postcode', 'postcode'),
        filter: true,
        sortable: true
    },
    ucn: {
        ...basicColumnDef('UCN Number', 'ucn')
    },
    legalEntityType: {
        ...choiceListColumnDef('Legal Entity Type', 'legalEntityType', 'companyLegalEntityType'),
        type: 'choiceList'
    },
    salesChannel: {
        ...choiceListColumnDef('Sales Channel', 'salesChannel', 'companySalesChannel'),
        type: 'choiceList'
    },
    nkamStatus: {
        ...choiceListColumnDef('NKAM Status', 'nkamStatus', 'companyNkamStatus'),
        type: 'choiceList'
    },
    bconId: {
        ...basicColumnDef('BCON', 'bconId'),
        type: 'text'
    },
    cin: {
        ...basicColumnDef('CIN', 'cin'),
        type: 'text'
    },
    tob: {
        ...basicColumnDef('TOB', 'termsOfBusiness'),
        type: 'text'
    },
    registrationNumber: {
        ...basicColumnDef('Company Reg Number', 'registrationNumber'),
        type: 'text'
    },
    liveTerms: {
        ...basicColumnDef('Live Terms in Place', 'liveTerms'),
        type: 'text'
    },
    internationalAgreement: {
        ...basicColumnDef('International Agreement', 'internationalAgreement'),
        type: 'text'
    },

    internationalBonusType: {
        ...basicColumnDef('International Bonus Type', 'internationalBonusType'),
        type: 'text'
    },
    internationalBmwBonus: {
        ...basicColumnDef('International BMW Bonus €', 'internationalBmwBonus'),
        type: 'text'
    },
    internationalMiniBonus: {
        ...basicColumnDef('International MINI Bonus €', 'internationalMiniBonus'),
        type: 'text'
    },

    bvrlaMember: {
        ...basicColumnDef('BVRLA Member', 'bvrlaMember'),
        type: 'text'
    },
    kamMeeting3Months: {
        ...basicColumnDef('KAM Meeting within 3 months', 'kamMeeting3Months'),
        type: 'text'
    },
    kamMeeting12Months: {
        ...basicColumnDef('KAM Meeting within 12 months', 'kamMeeting12Months'),
        type: 'text'
    },
    accountManager: {
        ...choiceListColumnDef('Owning KAM', 'accountManagerDescriptor', 'userAuthors'),
        mapping: (resources, values) => values && values.accountManagerDescriptor,
        type: 'text'
    },
    bmwMatrixBanding: {
        ...choiceListColumnDef('BMW Matrix Banding', 'bmwMatrixBanding', 'companyMatrixBanding'),
        type: 'choiceList'
    },
    bmwDiscretionary: {
        ...choiceListColumnDef('BMW Discretionary', 'bmwDiscretionary', 'companyDiscretionary'),
        type: 'choiceList'
    },
    miniMatrixBanding: {
        ...choiceListColumnDef('MINI Matrix Banding', 'miniMatrixBanding', 'companyMatrixBanding'),
        type: 'choiceList'
    },
    miniDiscretionary: {
        ...choiceListColumnDef('MINI Discretionary', 'miniDiscretionary', 'companyDiscretionary'),
        type: 'choiceList'
    }
};
