import React, { useContext } from 'react';
import { TabsContent, TabsGroup, TabsInCard, TabsTabForCard } from '../../common/Tabs';
import UpdateFleetProfileEditForm from '../../FleetProfile/Forms/UpdateFleetProfileEditForm';
import { CompanyDemosContext } from '../Provider/CompanyDemosProvider';
import { CompanyAgreementsContext } from '../Provider/CompanyAgreementsProvider';
import CompanyDemosTable from '../../Demos/Tables/CompanyDemosTable';
import CompanyAgreementsTable from '../../Agreement/Table/CompanyAgreementsTable';
import { CompanyContext } from '../Provider/CompanyProvider';
import LeasingProfileSection from '../../LeasingProfile';

const CompanyTabs = () => {
    const { demos } = useContext(CompanyDemosContext);
    const { agreements } = useContext(CompanyAgreementsContext);

    const { company = {} } = useContext(CompanyContext);

    const leasingTermsText = 'BusinessTerms';
    const isLeasing = company.salesChannel === leasingTermsText;

    return (
        <TabsInCard>
            <TabsGroup>
                <TabsTabForCard>Profile</TabsTabForCard>
                <TabsContent>
                    <UpdateFleetProfileEditForm agreementStyle={true} />
                </TabsContent>
            </TabsGroup>
            {isLeasing && <TabsGroup>
                <TabsTabForCard>Leasing Profile</TabsTabForCard>
                <TabsContent>
                    <LeasingProfileSection />
                </TabsContent>
            </TabsGroup>}
            <TabsGroup>
                <TabsTabForCard>Demos ({demos.length})</TabsTabForCard>
                <TabsContent id="demo">
                    <CompanyDemosTable />
                </TabsContent>
            </TabsGroup>
            <TabsGroup>
                <TabsTabForCard>Agreements ({agreements.length})</TabsTabForCard>
                <TabsContent id="agreement">
                    <CompanyAgreementsTable />
                </TabsContent>
            </TabsGroup>
        </TabsInCard>
    );
};

export default CompanyTabs;
