import create from 'zustand';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import { useDispatch } from 'react-redux';
import { apiUpdateAgreementsAdminUsersVisibility } from '../../../actions/Api/Agreement/apiAgreement';

const rowKey = 'userId';
export const useAgreementsAdminUsersVisibilityStore = create(set => storeWithModal(set, rowKey));

export const mapAndGroupUserVisibility = agreementsAdminUsersVisibility =>
    Object.entries(
        (agreementsAdminUsersVisibility || []).reduce((acc, { userId, seesUserId }) => {
            if (!acc[userId]) {
                acc[userId] = [];
            }
            acc[userId].push(seesUserId);
            return acc;
        }, {})
    ).map(([userId, seesUserIds]) => ({ userId: parseInt(userId, 10), seesUserIds }));

export function useUpdateAgreementsAdminUsersVisibility(closeModal, setError) {
    const updateData = useAgreementsAdminUsersVisibilityStore(state => state.updateDataFromFetch);
    //const setError = useEngageRolePermissionsStore(state => state.setError);
    const dispatch = useDispatch();

    return (userId, seesUserIds) => {
        return dispatch(apiUpdateAgreementsAdminUsersVisibility(userId, seesUserIds))
            .then(response => {
                updateData(mapAndGroupUserVisibility(response), true);
                closeModal();
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
