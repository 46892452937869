import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import EditMeetingForm from '../Forms/EditMeetingForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import { closeModal, FORM_PATHS } from '../../../actions/modals';

class EditMeetingModal extends React.Component {
	render() {
		const { modals, setModalClosed } = this.props;
		const meetingId = modals.EditMeetingModal.data.meetingId;
		const companyId = modals.EditMeetingModal.data.companyId;

		return modals.EditMeetingModal.isOpen ? (
			<Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
				<AppModalHeader toggle={() => setModalClosed()}>
					{`${meetingId < 0 ? 'Create' : 'Edit'} Meeting`}
				</AppModalHeader>
				<ModalBody>
					<EditMeetingForm
						onCancel={() => setModalClosed()}
						meetingId={meetingId}
						companyId={companyId}
						schema="meeting"
					/>
				</ModalBody>
			</Modal>
		) : (
			''
		);
	}
}

const mapStateToProps = state => ({
	modals: state.modals
});

const mapDispatchToProps = dispatch => ({
	setModalClosed: () => dispatch(closeModal(FORM_PATHS.EditMeetingModal.modalName))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditMeetingModal);
