import { makePostRequest } from '../../../../../actions/Api/apiHelpers';
import { toastr } from 'react-redux-toastr';

const sendTestEmail = (agreementId, notification) => {
    makePostRequest(['agreement', 'send-test-email', agreementId], undefined, notification)
        .then(() => toastr.success('Send Test Email', 'Success'))
        .catch(error => toastr.error('Send Test Email', error.message));
};

export default sendTestEmail;
