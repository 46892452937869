import React from 'react';
import DashboardValuesContainer from './DashboardValuesContainer';
import DashboardAdminCard from './DashboardAdminCard';
import { permissions, useUsersPermission } from '../../../reducers/Api/User/permissions';

export default function TermsMatrixDashboardCard({ data }) {
    const metrics = data.filter(metric => metric.entity === 'ternsMatrix');
    const total = metrics.reduce((acc, { count }) => acc + count, 0);

    const permission = useUsersPermission(permissions.TERMS_MATRIX);
    if (!permission.canRead) return null;

    const transformedMetric = metrics.map(metric => {
        if (metric.value === 'Draft') {
            return {
                ...metric,
                count: metric.value,
                value: 'The current draft matrix',
                href: `../matrix/${metric.rawValue}`
            };
        }
        if (metric.value === 'Live') {
            return {
                ...metric,
                count: metric.value,
                value: 'The current live matrix',
                href: `../matrix/${metric.rawValue}`
            };
        }
        if (metric.value === 'Preparing') {
            return {
                ...metric,
                count: metric.value,
                value: 'Preparing to make live',
                href: `../matrix/${metric.rawValue}`
            };
        }
        if (metric.value === 'Updating') {
            return {
                ...metric,
                count: metric.value,
                value: 'Updating Terms',
                href: `../matrix/${metric.rawValue}`
            };
        }
        if (metric.value === 'Processing') {
            return {
                ...metric,
                count: metric.value,
                value: 'Processing move to live',
                href: `../matrix/${metric.rawValue}`
            };
        }
        return {
            ...metric,
            count: total,
            value: 'View All',
            href: `../matrix`
        };
    });
    return (
        <DashboardAdminCard helpId="agreementsAdminTermsMatrixCard" title="Terms Matrices">
            <DashboardValuesContainer metrics={transformedMetric} />
        </DashboardAdminCard>
    );
}
