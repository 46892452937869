import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeSelector = () =>
    createSelector(
        state => state.resources,
        resources => resources
    );

export const useResources = () => {
    const selector = useMemo(makeSelector, []);
    return useSelector(state => selector(state));
};
