import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { getAxiosPostRequest } from '../apiHelpers';

export const apiClearCache = cacheKey => {
    return axios(getAxiosPostRequest(['cache', 'clear-cache', cacheKey]))
        .then(({ data }) => {
            toastr.success('Clear Cache', 'Success');
            return data;
        })
        .catch(err => {
            toastr.error('Clear Cache', `Response ${err.message}`);
            throw err;
        });
};