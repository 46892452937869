import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, InputGroupAddon } from 'reactstrap';
import FormikText from '../../common/FormkControls/FormikText';
import { safeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { push } from 'connected-react-router';

const CompanySearchForm = () => {
    const dispatch = useDispatch();
    return (
        <Formik
            initialValues={{ name: '' }}
            onSubmit={search => {
                safeDispatchPush(dispatch, push, ['dashboard', 'companies'], search);
            }}
            render={formikProps => {
                formikProps.formName = 'companySearchForm';
                return (
                    <form onSubmit={formikProps.handleSubmit}>
                        <FormikText
                            fieldName="name"
                            hideLabel
                            {...formikProps}
                            inputGroupAddons={
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" type="submit">
                                        Search
                                    </Button>
                                </InputGroupAddon>
                            }
                        />
                    </form>
                );
            }}
        />
    );
};
export default CompanySearchForm;
