import React from 'react';
import AgreementRevisionPageContent from '../../AgreementRevision/Components/AgreementRevisionPageContent';

export default function RevisionPage({
    match: {
        params: { agreementId: strAgreementId, revisionId: strRevisionId }
    }
}) {
    const agreementId = parseInt(strAgreementId, 10);
    const revisionId = parseInt(strRevisionId, 10);

    return <AgreementRevisionPageContent agreementId={agreementId} revisionId={revisionId} />;
}
