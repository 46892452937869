import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import DashboardAdminCard from '../../Agreement/Dashboard/DashboardAdminCard';
import { useChoiceList } from '../../common/Hooks/useChoiceList';

export default function AgreementsAdminReportingLinks() {
    const salesChannels = useChoiceList('salesChannel');

    return (
        <DashboardAdminCard title="Reporting">
            {salesChannels.map(({ key, value }) => (
                <ActionButton
                    bootstrapColor="secondary"
                    buttonText={
                        <div>
                            <h5>{value} Live Terms</h5>
                            <p>Live agreement terms for {value}</p>
                        </div>
                    }
                    fontawesomeIcon="fa-table"
                    key={key}
                    to={`../agreementliveterms?salesChannel=${key}`}
                />
            ))}
        </DashboardAdminCard>
    );
}
