import React from 'react';
import { FormatDate } from '../../FormatDate';
import Initials from '../../Initials';

export function UpdatedByRenderer({ column, rowData: { updatedDate, updatedBy } }) {
    if (!updatedDate) {
        return null;
    }
    const updatedByStr = updatedBy.toString();
    const author = (column.authorsList.find(x => x.key === updatedByStr) || { value: '' }).value;

    return (
        <div>
            <Initials name={author} /> <FormatDate date={updatedDate} />
        </div>
    );
}

export function CreatedByRenderer({ column, rowData: { createdDate, createdBy } }) {
    if (!createdDate) {
        return null;
    }
    const createdByStr = (createdBy || '').toString();
    const author = (column.authorsList.find(x => x.key === createdByStr) || { value: '' }).value;

    return (
        <div>
            <Initials name={author} /> <FormatDate date={createdDate} showTime={true} />
        </div>
    );
}
