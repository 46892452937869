import { useRevisionsStore } from './useRevisionsStore';
import { useAgreementsStore } from './useAgreementsStore';
import { useContractSignatories } from './useContractSignatories';
import { useContext } from 'react';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';

export default function useIsContractApproved() {
    const revisions = useRevisionsStore(state => state.data);
    const agreement = useAgreementsStore(state => state.agreement);
    const { people } = useContext(CompanyPeopleContext);
    const revision = revisions.find(revision => revision.revisionId === agreement.revision) || {};

    const signatories = useContractSignatories();

    return {
        approved: revision.approved,
        people: signatories
            .filter(x => x.signedDate)
            .map(({ signedDate, personId }) => ({
                person: people.find(x => x.personId === personId),
                signedDate
            }))
    };
}
