import React, { Fragment } from 'react';
import { FormatDate } from '../../common/FormatDate';
import { useToolTipContext } from './AgreementHistoryBreadcrumbToolTip';
import { getStatusColor } from '../agreementPermissions';

function BreadcrumbItem({ status, date, changes }) {
    return (
        <>
            <h6 className="nowrap">
                {status}
                {changes && changes.length > 1 ? <strong className="ml-3"> +{changes.length - 1}</strong> : null}{' '}
            </h6>
            <p>
                <FormatDate date={date} />
            </p>
        </>
    );
}

function ItemTooltip({ author, date, comments }) {
    return (
        <div className="my-4 mx-3">
            <p>Last Updated By:</p>
            <p>
                {author}, <FormatDate date={date} />
            </p>
            {comments ? (
                <div>
                    <p>Comments:</p>
                    <p>{comments}</p>
                </div>
            ) : null}
        </div>
    );
}

function GroupTooltip({ changes }) {
    return (
        <div className="d-grid" style={{ gridTemplateColumns: 'auto auto auto' }}>
            {changes.map((change, index) => {
                const statusColor = getStatusColor(change.statusCode);
                const paddingTop =
                    index > 0 && getStatusColor(changes[index - 1].statusCode) === statusColor ? 'pt-0' : 'pt-2';
                return (
                    <Fragment key={change.auditId}>
                        <div className={`pb-2 ${paddingTop} pl-4 pr-2 tooltip-color-${statusColor}`}>
                            {change.status}
                        </div>
                        <div className={`pb-2 ${paddingTop} px-2 tooltip-color-${statusColor}`}>{change.author}</div>
                        <div className={`pb-2 ${paddingTop} pl-2 pr-4 tooltip-color-${statusColor}`}>
                            <FormatDate date={change.date} />
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
}

export default function HistoryBreadcrumbItem({
    statusChange: { collapsedChanges, status, date, statusCode, author, comments }
}) {
    const { setTooltipOpen, setTooltipClosed } = useToolTipContext();

    return (
        <li
            onMouseEnter={() => {
                setTooltipOpen(
                    collapsedChanges && collapsedChanges.length > 1 ? (
                        <GroupTooltip changes={collapsedChanges} />
                    ) : (
                        <ItemTooltip author={author} changes={collapsedChanges} comments={comments} date={date} />
                    )
                );
            }}
            onMouseLeave={() => setTooltipClosed()}
        >
            <div className="d-flex" data-button-type={getStatusColor(statusCode)}>
                <div className="m-0 pt-0 pb-0 position-relative">
                    <BreadcrumbItem changes={collapsedChanges} date={date} status={status} />
                </div>
            </div>
        </li>
    );
}
