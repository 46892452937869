import React from 'react';
import WarningPopover from '../../common/WarningPopover';
import { useChoiceList, useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { useAgreementTemplateStore } from '../Provider/useAgreementTemplateStore';

function useMissingSalesChannels(templates) {
    const salesChannels = useChoiceList('salesChannel');

    return salesChannels.reduce((acc, { key, value }) => {
        if (!templates.some(template => template.active && template.salesChannel === key)) {
            acc.push({ salesChannel: value });
        }
        return acc;
    }, []);
}

function useMissingMatrixBandings(templates) {
    const matrixBandings = useChoiceList('matrixBanding');
    const salesChannelMap = useChoiceListForBaseTable('salesChannel');

    return matrixBandings.reduce((acc, { key, value, filterOnValue }) => {
        if (
            !templates.some(
                template =>
                    template.active &&
                    template.salesChannel === filterOnValue &&
                    (template.matrixBanding === null || template.matrixBanding.includes(key))
            )
        ) {
            acc.push({
                salesChannel: salesChannelMap.get(filterOnValue),
                matrixBanding: value
            });
        }
        return acc;
    }, []);
}

export default function MissingTemplatesAlert() {
    const templates = useAgreementTemplateStore(state => state.data);
    const missingSalesChannels = useMissingSalesChannels(templates);
    const missingMatrixBandings = useMissingMatrixBandings(templates);

    const allErrors = [...missingSalesChannels, ...missingMatrixBandings].sort((a, b) =>
        a.salesChannel > b.salesChannel ? 1 : a.salesChannel < b.salesChannel ? -1 : 0
    );

    if (!templates.length || !allErrors.length) return null;

    return (
        <WarningPopover
            headerText="The following agreement types do not have templates"
            bodyText={
                <ul>
                    {allErrors.sort().map(({ salesChannel, matrixBanding }) => (
                        <li key={`${salesChannel}-${matrixBanding}`}>
                            Sales channel <strong>{salesChannel}</strong> has no active templates
                            {matrixBanding ? (
                                <span>
                                    {' '}
                                    for <strong>{matrixBanding}</strong> banding
                                </span>
                            ) : null}
                        </li>
                    ))}
                </ul>
            }
        />
    );
}
