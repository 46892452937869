import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import queryString from 'query-string';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import addendumDef from '../addendumDef';

const FORM_NAME = FORM_NAMES.AGREEMENTS_FILTER;
const CARD_TITLE = 'Search';

function FormCard({ title, className, children }) {
    return (
        <Card className={`mb-4 shadow-sm simple-card ${className}`} style={{ overflow: 'hidden' }}>
            <div className="d-flex justify-content-between base-table-in-card-header">
                <h5 className="d-inline logo-font gradient mb-0">{title}</h5>
            </div>
            <Container>{children}</Container>
        </Card>
    );
}

function filterAgreementsFilterSearch(search) {
    const parsedQueryString = queryString.parse(search);
    const filter = {
        ...parsedQueryString,
        recent: parsedQueryString.recent === 'true'
    };

    return {
        ...filter
    };
}

export default function AddendumFilter() {
    const { push } = useHistory();
    const { search } = useLocation();
    const filterSplit = filterAgreementsFilterSearch(search);
    const initialValues = {
        ...filterSplit
    };

    const handleSubmit = change => {
        const newFilter = {
            ...filterSplit,
            ...change
        };

        push(`/addendum?${queryString.stringify(newFilter, { skipEmptyString: true })}`);
    };

    return (
        <FormCard className="pb-4" title={CARD_TITLE}>
            <ReactiveForm
                debounced={true}
                enableReinitialize={true}
                formName={FORM_NAME}
                hideCancelButton={true}
                hideSaveButton={true}
                initialValues={initialValues}
                onChange={handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Checkbox
                        columnDef={{ ...basicColumnDef('Recent', 'recent') }}
                        useBigTitle={true}
                    />
                    <ReactiveForm.Multiselect
                        columnDef={addendumDef.status}
                        expand={true}
                    />
                    <ReactiveForm.Multiselect
                        columnDef={addendumDef.reasonCode}
                        expand={true}
                    />
                    <ReactiveForm.Multiselect
                        columnDef={addendumDef.salesChannel}
                    />
                    <ReactiveForm.Text
                        columnDef={addendumDef.name}
                        useBigTitle={true}
                    />
                </ReactiveForm.Section>
            </ReactiveForm>
        </FormCard>
    );
}
