import React, { useContext, useRef, useState } from 'react';
import { useEventListener } from '../../common/Hooks/useEventListener';

export default function AgreementHistoryBreadcrumbToolTip({ contents, visibility }) {
    return (
        <div className="status-tooltip" style={{ visibility: `${visibility}` }}>
            {contents}
        </div>
    );
}

const ToolTipContext = React.createContext(undefined);

export const ToolTipProvider = ({ children }) => {
    const [tooltipState, setTooltipState] = useState({
        visibility: 'hidden'
    });
    const tooltipRef = useRef(null);

    function attachTooltip(e) {
        if (tooltipRef.current?.style) {
            tooltipRef.current.style.left =
                e.pageX - (tooltipState.right ? tooltipRef.current.getBoundingClientRect().width : 0) + 'px';
            tooltipRef.current.style.top = e.pageY + 60 + 'px';
        }
    }

    useEventListener(document, 'mousemove', attachTooltip);

    return (
        <ToolTipContext.Provider
            value={{
                setTooltipOpen: (contents, right = false) =>
                    setTooltipState({ visibility: 'visible', contents, right }),
                setTooltipClosed: () => setTooltipState({ visibility: 'hidden' })
            }}
        >
            <div ref={tooltipRef} className="status-tooltip" style={{ visibility: `${tooltipState.visibility}` }}>
                {tooltipState.contents}
            </div>
            {children}
        </ToolTipContext.Provider>
    );
};

export const useToolTipContext = () => {
    const state = useContext(ToolTipContext);
    if (state === undefined) {
        throw new Error(`useToolTipContext must be used inside ToolTipContext`);
    }
    return state;
};
