import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import queryString from 'query-string';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import companyDef from '../../Company/companyDef';

const FORM_NAME = FORM_NAMES.AGREEMENTS_FILTER;
const CARD_TITLE = 'Search';

function FormCard({ title, className, children }) {
    return (
        <Card className={`mb-4 shadow-sm simple-card ${className}`}>
            <div className="d-flex justify-content-between base-table-in-card-header">
                <h5 className="d-inline logo-font gradient mb-0">{title}</h5>
            </div>
            <Container>{children}</Container>
        </Card>
    );
}

function filterAgreementsFilterSearch(search) {
    const parsedQueryString = queryString.parse(search);
    const filter = {
        ...parsedQueryString
    };

    return {
        ...filter
    };
};

export default function CompanyFilter() {
    const { push } = useHistory();
    const { search } = useLocation();
    const filterSplit = filterAgreementsFilterSearch(search);
    const initialValues = {
        ...filterSplit
    };

    const handleSubmit = change => {
        const newFilter = {
            ...filterSplit,
            ...change
        };

        push(`/dashboard/companies?${queryString.stringify(newFilter, { skipEmptyString: true })}`);
    };

    return (
        <FormCard className="pb-4" title={CARD_TITLE}>
            <ReactiveForm
                debounced={true}
                enableReinitialize={true}
                formName={FORM_NAME}
                hideCancelButton={true}
                hideSaveButton={true}
                initialValues={initialValues}
                onChange={handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Text
                        columnDef={companyDef.name}
                        useBigTitle={true}
                    />
                    <ReactiveForm.Multiselect
                        columnDef={companyDef.salesChannel}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={companyDef.accountManager}
                        isMulti={true}
                    />
                </ReactiveForm.Section>
            </ReactiveForm>
        </FormCard>
    );
};
