import { toastr } from 'react-redux-toastr';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';

export function useGoToAgreementPage() {
    const push = useSafeDispatchPush(agreement => ['agreement', agreement.agreementId]);
    return agreement => {
        if (agreement.termsVisible) push(agreement);
        else toastr.error('View Agreement', "You don't have permission to view this agreement");
    };
}
/*

const CompanyAgreementsTableCard = ({
    isLoading,
    rows,
    resources,
    columns = [
        agreementDef.startDate,
        agreementDef.expiryDate,
        agreementDef.salesChannel,
        agreementDef.reference,
        agreementDef.status,
        {
            field: 'download',
            title: 'Download PDF',
            mapToDisplayedValue: (_, values) => (
                <>
                    {values[agreementDef.revision.field] && (
                        <Button color="info" onClick={e => downloadAgreementPdf(e, values, false, () => {})}>
                            Download PDF
                        </Button>
                    )}
                </>
            )
        }
    ],
    noItemsText = 'There are no agreements',
    breakpoint = 500,
    itemsPerPage = 5
}) => {
    const goToPage = useGoToAgreementPage();

    const tableDefinition = new TableDefinition(columns);
    return (
        <ChoiceListFilteredTableNoDashboardData
            breakpoint={breakpoint}
            cardButtons={[]}
            id="Agreements"
            isLoading={isLoading}
            itemsPerPage={itemsPerPage}
            noItemsText={noItemsText}
            rows={mapToRows(
                rows,
                agreementDef.id,
                resources,
                tableDefinition.columns,
                agreement => goToPage(agreement),
                undefined
            )}
            showFilter={true}
            tableDefinition={tableDefinition}
            tableIsNotInCard={true}
            title="Agreements"
        />
    );
};

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(CompanyAgreementsTableCard);
*/
