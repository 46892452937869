import React, { useMemo } from 'react';
import BigCalendar from 'react-big-calendar';
import { connect } from 'react-redux';
import moment from 'moment';
import { eventStyleGetter } from '../../../reducers/Api/Communications/Comms';
import { createDay } from '../../../actions/Navigation/Communication/createDay';
import MapArray from '../../common/MapArray';
import { mapVisitActionToCalendarEvent } from '../../../reducers/Api/VisitActions/VisitAction';
import { mapCommunicationToCalendarEvent } from '../../../reducers/Api/Communications/Comms';
import { ListGroup } from 'reactstrap';
import filters from '../../Calendar/calendarFilters';
import SimpleCard from '../../common/Card/SimpleCard';
import CommunicationCalendar from '../../Calendar/Pages/CommunicationCalendar';
import appSettings from '../../../config/appSettings';
import { onNavigate, onView, getDefaultDate } from '../../Calendar/calendarNavigation';
import useIsWindowWidthOver from '../../common/Hooks/useIsWindowWidthOver';
import CalendarFilter from '../../Calendar/Components/CalendarFilter';
import { DebugSection } from '../../common/DebugSection';
import PageSection from '../../common/PageSection';
import { eventToCalendarEvent } from '../../../reducers/Api/Events/mappers';
import { meetingToCalendarEvent } from '../../../reducers/Api/Meeting/mappers';
import { filterEventsAssignedTo } from '../../../reducers/Api/Events/filters';
import { filterMeetingsAssignedTo } from '../../../reducers/Api/Meeting/filters';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import { apiCalendarPage } from '../../../actions/Api/Page/apiPage';
import PageLoadingSpinner from './PageLoadingSpinner';

const CalendarPage = props => {
    const {
        resources,
        createDay,
        visitActions,
        communications,
        meetings,
        events,
        user: { userId },
        calendarFilters
    } = props;

    const has2columns = useIsWindowWidthOver(1440);

    let defaultView = props.match.params.view;
    BigCalendar.momentLocalizer(moment);

    //console.log(calendarFilters)

    const calendarEvents = useMemo(() => {
        return [
            ...filterEventsAssignedTo(events, userId).map(x => eventToCalendarEvent(x)),
            ...filterMeetingsAssignedTo(meetings, userId).map(meetingToCalendarEvent),
            ...visitActions
                .filter(x => x.assigneeId === userId)
                .map(x => mapVisitActionToCalendarEvent(x, resources.choiceList)),
            ...communications
                .filter(x => x.assigneeId === userId)
                .map(x => mapCommunicationToCalendarEvent(x, resources.choiceList))
        ].filter(e => {
            const filters = Object.values(calendarFilters);

            return !filters.length || filters.filter(f => !f.matches(e)).length === filters.length;
        });
    }, [calendarFilters, communications, events, meetings, resources, userId, visitActions]);

    return (
        <PageSection title="Calendar" titleExtra={<PageLoadingSpinner />}>
            <div className={has2columns ? 'd-grid page-grid' : ''}>
                <div className={has2columns ? 'grid-cs-1 grid-ce-3' : ''}>
                    <SimpleCard>
                        <div className="pt-3" style={{ height: appSettings.calendar.height || '700px' }}>
                            <CommunicationCalendar
                                defaultDate={getDefaultDate(props).toDate()}
                                defaultView={defaultView}
                                events={calendarEvents}
                                eventStyleGetter={eventStyleGetter}
                                onNavigate={(date, view) => onNavigate(date, view, props)}
                                onView={view => onView(view, props)}
                                views={['month', 'work_week', 'day']}
                            />
                        </div>
                    </SimpleCard>
                </div>
                <div>
                    <div className="mb-4">
                        <ActionButtons
                            actions={appSettings.calendar.buttons.map(button => ({
                                ...button,
                                onClickAction: () => createDay(button.eventType, new Date())
                            }))}
                            title={appSettings.calendar.buttonListTitle}
                        />
                        <ListGroup className="mt-4">
                            <MapArray from={filters.sort((a, b) => a.order > b.order)}>
                                <CalendarFilter />
                            </MapArray>
                        </ListGroup>
                    </div>
                </div>
                <DebugSection sections={[{ name: 'events', object: events }]} />
            </div>
        </PageSection>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    offline: state.offline,
    meetings: state.meetings,
    communications: state.communications,
    visitActions: state.visitActions,
    events: state.events,
    resources: state.resources,
    calendarOptionsSelected: state.settings.showOnCalendarCommunicationTypes,
    filterData: state.settings.calendarFilters,
    calendarFilters: state.settings.calendarFilters
});

const mapDispatchToProps = dispatch => ({
    getEvents: (startDate, endDate) => {
        dispatch(apiCalendarPage(startDate, endDate));
    },
    createDay: (type, startDate, activityRegarding) => dispatch(createDay(type, startDate, activityRegarding))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
