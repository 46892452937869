import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import CompanyFleetProfileEditCard from '../../FleetProfile/Cards/CompanyFleetProfileEditCard';
import MeetingNotes from '../../Communication/Components/MeetingNotes';
import { DebugSection } from '../../common/DebugSection';
import PageSection from '../../common/PageSection';
import FormikErrors from '../../common/FormkControls/FormikErrors';
import ViewCommunicationPageButtons from '../../Communication/Components/ViewCommunicationPageButtons';
import MeetingAndForm from '../../Meeting/Forms/MeetingAndForm';
import CommunicationPageTitleContent from '../../Communication/Components/CommunicationPageTitleContent';
import CompanyPrimaryAddressesDetails from '../../Address/Components/CompanyPrimaryAddressesDetails';
import { createSelector } from 'reselect';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import Layout3Columns, { Column1, Column2, Column3, Column3Top } from '../../Dashboard/Components/Layout3Columns';
import MeetingProvider from '../../Meeting/Provider/MeetingProvider';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';
import MeetingPageOverview from '../../Meeting/Components/MeetingPageOverview';
import MeetingPageCompanyDetails from '../../Company/Components/MeetingPageCompanyDetails';
import CommunicationVisitActionsTable from '../../VisitActions/Tables/CommunicationVisitActionsTable';
import PersonDetails from '../../Person/Components/PersonDetails';
import { apiPage } from '../../../actions/Api/Page/apiPage';

export const isRoiBooked = meeting => meeting && meeting.roiBooked === 'Y';

const schemaFor = meeting =>
    isRoiBooked(meeting)
        ? yup.object().shape({
              meetingNotes: yup.string(),
              rating: yup
                  .string()
                  .required('You must provide a rating for ROI booked appointments.')
                  .nullable(),
              feedback: yup.string().nullable()
          })
        : yup.object().shape({
              meetingNotes: yup.string(),
              rating: yup.string().nullable(),
              feedback: yup.string().nullable()
          });

const saveVisitReport = (formikData, communicationOriginal, saveCommunication) => {
    const { notes, rating, feedback } = formikData;
    const communication = { ...communicationOriginal };
    communication.status = 'Complete';
    communication.notes = notes;
    communication.rating = rating;
    communication.feedback = feedback;
    communication.companyId = communication.companyId || communication.company?.companyId;
    communication.company = undefined;
    saveCommunication(communication);
};

function MeetingPage({
    meeting,
    person,
    meetingId,
    history,
    location: { pathname },
    saveCommunication,
    initialValues
}) {
    return (
        <>
            <RedirectNotLoggedIn onLoggedInDispatchArray={[apiPage('meeting', meetingId, undefined, pathname)]} />
            <MeetingProvider meetingId={meetingId}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={formikData =>
                        console.log('TEST') || saveVisitReport(formikData, meeting, saveCommunication)
                    }
                    validationSchema={schemaFor(meeting)}
                    render={formikProps => {
                        formikProps.formName = 'visitReportForm';
                        return (
                            <div>
                                <PageSection
                                    onBackClicked={history.goBack}
                                    title={meeting.subject ? meeting.subject : ''}
                                    titleContent={<CommunicationPageTitleContent />}
                                >
                                    <FormikErrors errors={formikProps.errors} />
                                    <Layout3Columns>
                                        <Column1>
                                            <MeetingPageOverview />
                                            <MeetingPageCompanyDetails />
                                            <MeetingAndForm hideIdField={true} />
                                            <PersonDetails person={person} />
                                            <CompanyPrimaryAddressesDetails />
                                        </Column1>
                                        <Column2>
                                            <CompanyFleetProfileEditCard />
                                            <MeetingNotes formikProps={formikProps} />
                                            <CommunicationVisitActionsTable meetingId={meeting.meetingId} />
                                        </Column2>
                                        <Column3>
                                            <DebugSection sections={[{ name: 'meeting', object: meeting }]} />
                                        </Column3>
                                        <Column3Top>
                                            <div>
                                                <ViewCommunicationPageButtons
                                                    formikProps={formikProps}
                                                    url={window.location.href}
                                                />
                                            </div>
                                        </Column3Top>
                                    </Layout3Columns>
                                </PageSection>
                            </div>
                        );
                    }}
                />
            </MeetingProvider>
        </>
    );
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.meetings,
            state => state.communications,
            state => state.companies,
            state => state.people,
            (
                _,
                {
                    match: {
                        params: { descriptor, communicationId: strCommunicationId }
                    }
                }
            ) => ({ descriptor, strCommunicationId })
        ],
        (meetings, communications, companies, people, { strCommunicationId, descriptor }) => {
            const meetingId = Number.parseInt(strCommunicationId, 10);
            const meeting = meetings.find(meeting => meeting.meetingId === meetingId);

            //console.log(meeting)

            const communicationId = Number.parseInt(strCommunicationId, 10);
            const communication = communications.find(
                communication => communication.communicationId === communicationId
            );

            const company = communication
                ? companies.find(company => company.companyId === Number.parseInt(meeting.companyId))
                : {};

            //console.log(company)

            const initialValues = {
                notes: _.get(meeting, 'notes', ''),
                rating: _.get(meeting, 'rating', ''),
                feedback: _.get(meeting, 'feedback', ''),
                meeting,
                company
            };

            const person = people.find(x => x.personId === meeting.personId);

            return {
                meetingId,
                meeting,
                company,
                initialValues,
                descriptor,
                person
            };
        }
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    saveCommunication: meeting => dispatch(apiMeetingPost(meeting))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(MeetingPage);
