import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const capCodesDef = {
    id: idColumnDef('Id', 'capDataId'),
    capId: {
        ...basicColumnDef('Cap Id', 'capId'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'capId',
            helpText: 'The cap id'
        }
    },
    capCode: {
        ...basicColumnDef('Cap Code', 'capCode'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'capCode',
            helpText: 'The cap code'
        }
    },
    capStatus: {
        ...basicColumnDef('Cap Status', 'capStatus'),
        sortable: true,
        help: {
            helpId: 'capStatus',
            helpText: 'The cap status'
        }
    },
    modelRange: {
        ...basicColumnDef('Model Range', 'modelRange'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'modelRange',
            helpText: 'The model range of the cap code'
        }
    },
    modelDescription: {
        ...basicColumnDef('Model Description', 'modelDescription'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'modelDescription',
            helpText: 'The description of the cap code'
        }
    },
    ivsCode: {
        ...basicColumnDef('IVS Code', 'ivsCode'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'ivsCode',
            helpText: 'The ivs code of the cap code'
        }
    },
    devCode: {
        ...basicColumnDef('Dev Code', 'devCode'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'devCode',
            helpText: 'The dev code of the cap code'
        }
    },
    vdrVehicleId: {
        ...basicColumnDef('VDR Vehicle Id', 'vdrVehicleId'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'vdrVehicleId',
            helpText: 'The vdr vehicle id of the cap code'
        }
    },
    deleted: {
        ...basicColumnDef('Obsolete', 'deleted'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'deleted',
            helpText: 'Set to no, if cap code is no longer required'
        }
    }
};

export default capCodesDef;
