import React from 'react';
import BaseTableInCard, { CreatedByRenderer } from '../../common/BaseTable/BaseTableInCard';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import AddendumModal from '../Modal/AddendumModal';
import { useAddendaStore } from '../Provider/useAddendaStore';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import addendumDef from '../addendumDef';
import { useChoiceList, useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import useDimensions from '../../common/Hooks/useDimensions';
import BaseTable from '../../common/BaseTable/BaseTable';

const useAddendaTableCard = () => {
    const data = useAddendaStore(state => state.data);
    const open = useSafeDispatchPush(addendum => ['addendum', addendum.addendumId]);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);

    const authorsList = useChoiceList('userAuthors');
    const checksColumns = [
        addendumDef.name,
        { field: 'createdBy', title: 'Created', cellRenderer: CreatedByRenderer, authorsList },
        addendumDef.reasonCode,
        addendumDef.matrixBanding,
        addendumDef.status
    ];

    //get the sales Channel name for the grouping rows
    const statusChoiceMap = useChoiceListForBaseTable('salesChannel');
    const tableData = data.map(m => {
        return { ...m, salesChannel_text: statusChoiceMap.get(m.salesChannel) || 'Unknown' };
    });
    return {
        checksColumns,
        tableData,
        isLoading,
        open
    };
};

export function AddendaTableNoCard({ disabled = false }) {
    const { checksColumns, tableData, isLoading, open } = useAddendaTableCard();
    return (
        <div style={{ height: 600, width: '100%' }}>
            <BaseTable
                columns={checksColumns}
                data={tableData}
                expandAll={true}
                groupBy={['salesChannel_text']}
                isLoading={isLoading}
                onRowClicked={!disabled ? row => (row.rowData?.groupRow ? undefined : open(row.rowData)) : undefined}
                rowKey={addendumDef.id.field}
            />
        </div>
    );
}

export default function AddendaTable({ disabled = false }) {
    const { checksColumns, tableData, isLoading, open } = useAddendaTableCard();
    const [ref, { y }] = useDimensions({ liveMeasure: false });

    return (
        <>
            <AddendumModal />
            <BaseTableInCard
                cardTitle="Mass Addendum"
                columns={checksColumns}
                data={tableData}
                expandAll={true}
                groupBy={['salesChannel_text']}
                helpId="addendaTable"
                isLoading={isLoading}
                onRowClicked={!disabled ? row => (row.rowData?.groupRow ? undefined : open(row.rowData)) : undefined}
                refForTableHeight={ref}
                rowKey={addendumDef.id.field}
                tableSizeStyle={{ height: `calc(100vh - ${y}px - 5rem`, width: '100%' }}
            />
        </>
    );
}
