import { useState, useEffect } from 'react';

const useIsWindowWidthOver = width => {
    const [widthIsOver, setWidthIsOver] = useState(window.innerWidth > width);
    useEffect(() => {
        function updateDimensions() {
            setWidthIsOver(window.innerWidth > width);
        }
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [setWidthIsOver, width]);

    return widthIsOver;
};

export default useIsWindowWidthOver;
