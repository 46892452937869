import * as yup from 'yup';

const validation = yup.object().shape({
    // Personal Information
    salutation: yup
        .string()
        .nullable()
        .required('Salutation is required'),
    jobTitle: yup
        .string()
        .nullable()
        .required('Job Title is required'),
    firstName: yup
        .string()
        .nullable()
        .required('First Name is required'),
    lastName: yup
        .string()
        .nullable()
        .required('Last Name is required'),
    // Contact Details
    businessEmail: yup
        .string()
        .email('Invalid email address')
        .required('Email is required'),
    businessPhone: yup.string().nullable(),
    mobilePhone: yup.string().nullable(),
    // GDPR
    gdprEmail: yup.string().required('Please select the email marketing preference.'),
    gdprDirectMail: yup.string().required('Please select the direct mail marketing preference.'),
    gdprTelephone: yup.string().required('Please select the telephone marketing preference.'),
    gdprSms: yup.string().required('Please select the SMS marketing preference.'),
    isPrimaryPerson: yup.boolean(),
    gdprConfirmed: yup.boolean().test({
        test: value => value === true,
        message: 'You must confirm you have asked the contact for their marketing preferences.'
    })
});

export default validation;
