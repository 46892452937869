import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import NotificationTable from '../Tables/NotificationTable';
import { useNotificationStore } from '../Provider/useNotificationStore';

export default function NotificationsPageContent() {
    const { goBack } = useHistory();
    const updateNotificationsFromFetch = useNotificationStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    //fetch page
    useEffect(() => {
        dispatch(apiPage('notifications'))
            .then(response => {
                updateNotificationsFromFetch(response.userNotifications || [], true);
            })
            .catch(err => console.error('error', err));
    }, [updateNotificationsFromFetch, dispatch]);

    return (
        <PageSection onBackClicked={goBack} title="Notifications" titleExtra={<PageLoadingSpinner />}>
            <NotificationTable />
        </PageSection>
    );
}
