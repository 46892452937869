export default [
    {
        name: 'Fleet',
        fields: [
            {
                label: 'Total Fleet Size',
                field: 'fleetSize',
                type: 'not editable',
            },
            {
                label: 'Number of Cars',
                type: 'number',
                field: 'numberOfCars'
            },
            {
                label: 'Number of LCV',
                type: 'number',
                field: 'numberOfLCV'
            },            {
                label: 'Number Of Plugin Cars',
                type: 'number',
                field: 'numberOfPluginCars'
            },
            {
                label: 'Number Of Electric Cars',
                type: 'number',
                field: 'numberOfElectricCars'
            },
            {
                label: 'Primary Vehicle Use',
                type: 'choiceList',
                multiple: true,
                field: 'primaryVehicleUse',
                choiceList: 'fleetprofilePrimaryVehicleUse'
            },
            {
                label: 'Manufacturer Tender Date',
                type: 'date',
                field: 'dateOfTender'
            },
            {
                label: 'Company Car Funders',
                type: 'choiceList',
                multiple: true,
                field: 'currentFunders',
                choiceList: 'fleetprofileCurrentFunder'
            },
            {
                label:'Leasing Company Tender Date',
                type:'date',
                field: 'leasingCompanyTenderDate'
            },
            {
                label: 'Future Interest',
                type: 'choiceList',
                multiple: true,
                field: 'futureInterest',
                choiceList: 'fleetprofileFutureInterest'
            },
            {
                label: 'Fuel Policy',
                type: 'choiceList',
                multiple: true,
                field: 'fuelPolicy',
                choiceList: 'fleetprofileFuelPolicy'
            },
            {
                label: 'Funding Method',
                type: 'choiceList',
                multiple: true,
                field: 'fundingMethod',
                choiceList: 'fleetprofileFundingMethod'
            },
            {
                label: 'Change Cycle (Months)',
                type: 'choiceList',
                multiple: true,
                field: 'changeCycleMonths',
                choiceList: 'fleetprofileChangeCycleMonths'
            },
            {
                label: 'Change Cycle (Miles)',
                type: 'choiceList',
                multiple: true,
                field: 'changeCycleMiles',
                choiceList: 'fleetprofileChangeCycleMiles'
            }
        ]
    },
    {
        name: 'BMW Group Comparable Cars',
        fields: [
            {
                label: 'Total BMW Fleet',
                type: 'number',
                field: 'numberOfBmw'
            },
            {
                label: 'Total Audi Fleet',
                type: 'number',
                field: 'numberOfAudi'
            },
            {
                label: 'Total Mercedes-Benz Fleet',
                type: 'number',
                field: 'numberOfMercedesBenz'
            },
            {
                label: 'Total Jaguar Fleet',
                type: 'number',
                field: 'numberOfJaguar'
            },
            {
                label: 'Total Land Rover Fleet',
                type: 'number',
                field: 'numberOfLandRover'
            },
            {
                label: 'Total Other Fleet',
                type: 'number',
                field: 'numberOfOtherFleet'
            },
            {
                label: 'Total BMWi Fleet',
                type: 'number',
                field: 'numberOfBmwI'
            },
            {
                label: 'Total Nissan Fleet',
                type: 'number',
                field: 'numberOfNissan'
            },
            {
                label: 'Total MINI Fleet',
                type: 'number',
                field: 'numberOfMini'
            },
            {
                label: 'Total Volvo Fleet',
                type: 'number',
                field: 'numberOfVolvo'
            },
            {
                label: 'Total VW Fleet',
                type: 'number',
                field: 'numberOfVw'
            },
            {
                label: 'Total Ford Fleet',
                type: 'number',
                field: 'numberOfFord'
            },
            {
                label: 'Total SEAT Fleet',
                type: 'number',
                field: 'numberOfSeat'
            }
        ]
    }
];
