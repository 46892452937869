import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: idColumnDef('Id', 'agreementCheckId'),
    outcome: {
        ...basicColumnDef('Outcome', 'outcome'),
        type: 'text',
        help: {
            helpId: 'outcome',
            helpText: 'The outcome of the check'
        }
    },
    name: {
        ...basicColumnDef('Name', 'name'),
        type: 'text',
        help: {
            helpId: 'name',
            helpText: 'The name of the check'
        }
    },
    evidence: {
        ...basicColumnDef('Evidence', 'evidence'),
        type: 'text',
        help: {
            helpId: 'evidence',
            helpText: 'The evidence of the check'
        }
    }
};
