import React, { useContext } from 'react';
import { filterFutureCompanyCommunications } from '../../../reducers/Api/Communications/Comms';
import AgendaCard from '../../Calendar/Cards/AgendaCard';
import { filterFutureCompanyVisitActions } from '../../../reducers/Api/VisitActions/VisitAction';
import { CompanyContext } from '../Provider/CompanyProvider';
import { CompanyCommunicationsContext } from '../Provider/CompanyCommunicationsProvider';
import { CompanyVisitActionsContext } from '../Provider/CompanyVisitActionsProvider';

function CompanyAgenda() {
    const { company, companyId } = useContext(CompanyContext);
    const { communications } = useContext(CompanyCommunicationsContext);
    const { visitActions } = useContext(CompanyVisitActionsContext);
    return (
        <AgendaCard
            title="Upcoming"
            key="CompanyAgendaCard"
            showDayName={true}
            company={company}
            agenda={filterFutureCompanyCommunications(communications, companyId)}
            visitActions={filterFutureCompanyVisitActions(visitActions, companyId)}
        />
    );
}

export default CompanyAgenda;
