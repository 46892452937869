import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { apiAuthPasswordReset } from '../../../actions/ApiAuth/Password/apiAuthPasswordReset';
import { Button } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import appSettings from '../../../config/appSettings';
import { AppLogo } from '../../app/AppLogo';

const testsEnum = {
    email: 0,
    minChars: 1,
    minUpper: 2,
    minLower: 3,
    minNumber: 4,
    minSpecialChar: 5,
    confirmPasswordMatch: 6
};

const tests = [
    {
        name: 'Email - Required',
        passed: false,
        display: false
    },
    {
        name: 'At least eight characters',
        passed: false,
        display: true
    },
    {
        name: 'One uppercase letter',
        passed: false,
        display: true
    },
    {
        name: 'One lowercase letter',
        passed: false,
        display: true
    },
    {
        name: 'One number',
        passed: false,
        display: true
    },
    {
        name: 'One special character',
        passed: false,
        display: true
    },
    {
        name: 'Confirm password must match password',
        passed: false,
        display: true
    }
];

class UpdatePasswordForm extends React.Component {
    state = { show: false };

    handleSubmit = form => {
        const validationFail = tests.find(x => x.passed === false);

        if (validationFail) {
            toastr.error('Please complete all of the required actions before attempting to submit the form.');
            return;
        }

        const { token, sendResetPassword } = this.props;
        const { password, email } = form;
        sendResetPassword(token, password, email);
    };

    validateEmail(value) {
        !value ? (tests[testsEnum.email].passed = false) : (tests[testsEnum.email].passed = true);
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? (tests[testsEnum.email].passed = false)
            : (tests[testsEnum.email].passed = true);
    }

    validatePassword(value) {
        const {
            minLength,
            minUpper,
            minLower,
            minNumber,
            minSpecial,
            validSpecials
        } = appSettings.auth.passwordEntropy;

        // Min Chars
        minLength && value.length < minLength
            ? (tests[testsEnum.minChars].passed = false)
            : (tests[testsEnum.minChars].passed = true);

        // Min Upper
        minUpper && [...value].filter(x => x.match(/[A-Z]/)).length < minUpper
            ? (tests[testsEnum.minUpper].passed = false)
            : (tests[testsEnum.minUpper].passed = true);

        // Min Lower
        minLower && [...value].filter(x => x.match(/[a-z]/)).length < minLower
            ? (tests[testsEnum.minLower].passed = false)
            : (tests[testsEnum.minLower].passed = true);

        // Min Number
        minNumber && [...value].filter(x => x.match(/[0-9]/)).length < minNumber
            ? (tests[testsEnum.minNumber].passed = false)
            : (tests[testsEnum.minNumber].passed = true);

        // Min Special
        minSpecial && validSpecials && [...value].filter(x => validSpecials.includes(x)).length < minSpecial
            ? (tests[testsEnum.minSpecialChar].passed = false)
            : (tests[testsEnum.minSpecialChar].passed = true);
    }

    validateConfirmPassword(values) {
        if (values.password !== '') {
            values.confirmPassword !== values.password
                ? (tests[testsEnum.confirmPasswordMatch].passed = false)
                : (tests[testsEnum.confirmPasswordMatch].passed = true);
        }
    }

    renderRequirements() {
        if (this.state.show) {
            return (
                <div>
                    <div className="mt-4">Password requirements:</div>
                    <div style={{ textAlign: 'left' }} className="mt-4">
                        {this.renderPasswordRequirements()}
                    </div>
                </div>
            );
        }
    }

    renderPasswordRequirements() {
        return tests.map((x, i) => {
            if (!x.display) return null;

            if (x.passed) {
                return (
                    <div
                        key={i}
                        style={{
                            textDecoration: 'line-through',
                            color: 'Black'
                        }}
                    >
                        <i className="fa fa-check-circle-o" style={{ marginRight: '5px' }} />
                        <span style={{ opacity: 0.6 }}>{x.name}</span>
                    </div>
                );
            } else {
                return (
                    <div key={i}>
                        <i className="fa fa-circle-o" style={{ marginRight: '5px', opacity: 0.6 }} />
                        {x.name}
                    </div>
                );
            }
        });
    }

    render() {
        const initialValues = {
            email: process.env.REACT_APP_DEFAULT_USERNAME || '',
            password: '',
            confirmPassword: ''
        };

        return (
            <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
                {({ values }) => (
                    <Form className={this.props.class}>
                        <div className="w-100 text-center mb-4">
                            <AppLogo />
                            <h6 className="mb-0">{this.props.formText}</h6>
                        </div>
                        <div className="mb-2">
                            <Field
                                className="py-4 form-control"
                                name="email"
                                placeholder="Email"
                                validate={this.validateEmail}
                            />
                        </div>
                        <div className="mb-2">
                            <Field
                                className="py-4 form-control"
                                name="password"
                                type="password"
                                placeholder="Enter New Password"
                                onFocus={() => this.setState({ show: true })}
                                validate={this.validatePassword}
                            />
                        </div>
                        <div className="mb-2">
                            <Field
                                className="py-4 form-control"
                                name="confirmPassword"
                                type="password"
                                placeholder="Confirm New Password"
                                validate={() => this.validateConfirmPassword(values)}
                            />
                        </div>
                        <div className="d-grid grid-cols-2 grid-column-gap-2">
                            <div />
                        </div>
                        <Button type="submit" color="primary" className="w-100">
                            {this.props.buttonText}
                        </Button>
                        <div>{this.renderRequirements()}</div>
                    </Form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    sendResetPassword: (token, password, emailAddress) => dispatch(apiAuthPasswordReset(token, password, emailAddress))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatePasswordForm);
