import React from 'react';
import TableCard from '../../common/Card/TableCard';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';

export default function CompareToAgreementCard({ agreementId, revisionId }) {
    const gotoComparePage = useSafeDispatchPush(e => ['agreement', agreementId, revisionId, e.agreementId]);
    return (
        <TableCard title="Compare to agreement">
            <ReactiveForm handleSubmit={gotoComparePage} hideCancelButton={true} saveButtonContent="Compare">
                <ReactiveForm.Section>
                    <ReactiveForm.Text columnDef={{ ...basicColumnDef('Agreement Id', 'agreementId') }} />
                </ReactiveForm.Section>
            </ReactiveForm>
        </TableCard>
    );
}
