import React from 'react';
import makeAnimated from 'react-select/lib/animated';
import Select from 'react-select';

export default function FormSelect({
    options,
    value,
    className,
    onChange,
    placeholder,
    isDisabled,
    isClearable = false,
    width = '200px'
}) {
    return (
        <div style={{ minWidth: width, width, zIndex: 3 }}>
            <Select
                className={`react-select ${className}`}
                components={makeAnimated()}
                isClearable={isClearable}
                isDisabled={isDisabled}
                onChange={onChange}
                options={options || []}
                placeholder={placeholder}
                style={{ zIndex: 3 }}
                value={value}
            />
        </div>
    );
}
