import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import { ToggleRenderer } from '../common/BaseTable/CusomCellRenderers';
import React from 'react';

const agreementTemplateDef = {
    id: idColumnDef('id', 'agreementTemplateId'),
    salesChannel: {
        ...choiceListColumnDef('Sales Channel', 'salesChannel', 'salesChannel'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'salesChannel',
            helpText: 'The sales channel that the template applies to'
        }
    },
    matrixBanding: {
        ...choiceListColumnDef('Matrix Banding(s)', 'matrixBanding', 'matrixBanding'),
        filter: true,
        help: {
            helpId: 'matrixBanding',
            helpText: 'Matrix bandings the notifications are appropriate for, or all bandings'
        }
    },
    agreementType: {
        ...choiceListColumnDef('Agreement Type', 'agreementType', 'agreementType'),
        type: 'text',
        searchable: true,
        filter: true,
        help: {
            helpId: 'agreementType',
            helpText: 'The agreement type'
        }
    },
    agreementEntityType: {
        ...basicColumnDef('Entity Type', 'templateBelongsToEntity'),
        help: {
            helpId: 'templateBelongsToEntity',
            helpText: 'Whether the template shows for agreement, addendum or both'
        }
    },
    forAddendum: {
        ...basicColumnDef('For Addendum', 'forAddendum'),
        help: {
            helpId: 'forAddendum',
            helpText:
                'If set to yes, this template will be used when an addendum PDF is created, and will override any files from the base template'
        },
        cellRenderer: props => <ToggleRenderer {...props} disabled={true} />
    },
    agreementTemplateFile: {
        ...basicColumnDef('Template', 'agreementTemplateFile'),
        filter: true,
        help: {
            helpId: 'agreementTemplateFile',
            helpText: 'The file to be used for the template'
        }
    },
    standardTermsAndConditionsFile: {
        ...basicColumnDef('Agreement Clauses', 'standardTermsAndConditionsFile'),
        filter: true,
        help: {
            helpId: 'standardTermsAndConditionsFile',
            helpText: 'The file to be used for the terms and conditions'
        }
    },
    coverLetterFile: {
        ...basicColumnDef('Cover Letter', 'coverLetterFile'),
        filter: true,
        help: {
            helpId: 'coverLetterFile',
            helpText: 'The file to be used for the cover letter'
        }
    },
    active: {
        ...basicColumnDef('Active', 'active'),
        sortable: true,
        help: {
            helpId: 'active',
            helpText: 'Set to no, if template is no longer required'
        }
    },
    name: {
        ...basicColumnDef('Name', 'name'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'name',
            helpText: 'A short name describing the template'
        }
    },
    agreementId: {
        ...basicColumnDef('Agreement Id', 'templateBelongsToEntityId'),
        help: {
            helpId: 'agreementId',
            helpText: 'Id number of agreement which template belongs to'
        }
    },
    addendumId: {
        ...basicColumnDef('Addendum Id', 'templateBelongsToEntityId'),
        help: {
            helpId: 'addendumId',
            helpText: 'Id number of addendum which template belongs to'
        }
    },
    matrixId: {
        ...basicColumnDef('Matrix Id', 'templateBelongsToEntityId'),
        help: {
            helpId: 'matrixId',
            helpText: 'Id number of matrix which template belongs to'
        }
    },
    comments: {
        ...basicColumnDef('Comments', 'comments')
    },
    createDate: {
        ...dateColumnDef('Create Date', 'createDate')
    }
};

export default agreementTemplateDef;
