import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import React from 'react';
import { BaseTableInCardContentRight } from '../../common/BaseTable/BaseTableInCard';

export default function DashboardAdminCard({
    title,
    href,
    toggles,
    buttons,
    titleContentRight,
    children,
    helpId,
    style = {}
}) {
    if (href) {
        return (
            <Link
                className="background-hover h-100 text-decoration-none"
                style={{ color: 'black', ...style }}
                to={href}
            >
                <DashboardCard helpId={helpId} title={title} titleContentRight={titleContentRight} toggles={toggles}>
                    {children}
                </DashboardCard>
            </Link>
        );
    }
    return (
        <DashboardCard
            buttons={buttons}
            helpId={helpId}
            style={{ ...style }}
            title={title}
            titleContentRight={titleContentRight}
            toggles={toggles}
        >
            {children}
        </DashboardCard>
    );
}

function DashboardCard({ title, style, helpId, children, buttons, toggles, titleContentRight }) {
    return (
        <Card className="shadow-sm simple-card d-flex h-100 flex-column" style={{ alignItems: 'stretch', ...style }}>
            <div className="base-table-in-card-header d-flex justify-content-between">
                <div>
                    <h5 className="d-inline logo-font gradient fancy-serif">{title}</h5>
                </div>
                <BaseTableInCardContentRight
                    buttons={buttons}
                    content={titleContentRight}
                    helpId={helpId}
                    toggles={toggles}
                />
            </div>
            {children}
        </Card>
    );
}
