import { Handle } from 'react-flow-renderer';
import React from 'react';

export const StatusChangeCustomNodeComponent = ({ data }) => {
    return (
        <div
            className={`ma-3 bg-${data.color} text-white`}
            style={{ borderRadius: '0.25rem', padding: '0.375rem 0.75rem' }}
        >
            <Handle position={data['differentColumn'] ? 'left' : 'top'} style={{ borderRadius: 0 }} type="target" />
            <div>{data.label}</div>
            <Handle
                id="a"
                position={data['differentColumn'] ? 'right' : 'bottom'}
                style={{ borderRadius: 0 }}
                type="source"
            />
        </div>
    );
};
