import React, { useMemo, useState } from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import BaseTableInCard, { ExpandIcon, ToggleRenderer } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import useDimensions from '../../common/Hooks/useDimensions';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { DeleteTableRowButton, TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';
import {
    useAgreementCheckTypeStore,
    useUpdateCheckTypes,
    useUpdateCheckTypesWithoutTableUpdate
} from '../Provider/useAgreementCheckTypeStore';
import AgreementCheckTypeModal from '../Modals/AgreementCheckTypeModal';
import agreementCheckTypeDef from '../agreementCheckTypeDef';

function MultiRenderer({ cellData, column: { multiSelect } }) {
    const items = (cellData || undefined)?.split(',').map(m => {
        const label = multiSelect.get(m);
        return (
            <div key={m}>
                <span className="badge badge-light">{label}</span>
            </div>
        );
    });

    return <div className="w-100">{items || 'All'}</div>;
}

function ActionsRenderer({ onRemoveCheckType, disabled, rowData }) {
    const onDelete = event => {
        PreventClickThrough(event);
        onRemoveCheckType(rowData);
    };

    return <TableButtonsContainer>{!disabled && <DeleteTableRowButton onClick={onDelete} />}</TableButtonsContainer>;
}

function useColumns(groupBy) {
    const agreementStatusList = useChoiceListForBaseTable('agreementHeaderStatus');
    const salesChannelList = useChoiceListForBaseTable('salesChannel');
    const agreementTypeList = useChoiceListForBaseTable('agreementType');
    const updateCheckTypes = useUpdateCheckTypes();

    const onToggleRequired = (checkType, checked) => {
        updateCheckTypes([{ ...checkType, required: checked }]);
    };
    const onToggleMandatoryEvidence = (checkType, checked) => {
        updateCheckTypes([{ ...checkType, mandatoryEvidence: checked }]);
    };

    const onToggleMustPass = (checkType, checked) => {
        updateCheckTypes([{ ...checkType, mustPass: checked }]);
    };

    const onToggleActive = (checkType, checked) => {
        updateCheckTypes([{ ...checkType, active: checked }]);
    };

    const onRemoveCheckType = checkType => {
        updateCheckTypes([{ ...checkType, deleted: 1 }]);
    };

    const columns = [
        agreementCheckTypeDef.name,
        agreementCheckTypeDef.helpText,
        {
            ...agreementCheckTypeDef.stage,
            multiSelect: agreementStatusList,
            cellRenderer: MultiRenderer
        },
        {
            ...agreementCheckTypeDef.salesChannels,
            multiSelect: salesChannelList,
            cellRenderer: MultiRenderer
        },
        {
            ...agreementCheckTypeDef.agreementType,
            multiSelect: agreementTypeList,
            cellRenderer: MultiRenderer
        },
        {
            ...agreementCheckTypeDef.asb,
            width: 90,
            noGrow: true,
            cellRenderer: ({ cellData }) => (cellData ? 'Yes' : 'No')
        },
        {
            ...agreementCheckTypeDef.required,
            width: 90,
            noGrow: true,
            cellRenderer: props => <ToggleRenderer {...props} onToggle={onToggleRequired} />
        },
        {
            ...agreementCheckTypeDef.mandatoryEvidence,
            width: 90,
            noGrow: true,
            cellRenderer: props => <ToggleRenderer {...props} onToggle={onToggleMandatoryEvidence} />
        },
        {
            ...agreementCheckTypeDef.mustPass,
            width: 90,
            noGrow: true,
            cellRenderer: props => <ToggleRenderer {...props} onToggle={onToggleMustPass} />
        },
        {
            ...agreementCheckTypeDef.active,
            width: 70,
            noGrow: true,
            cellRenderer: props => <ToggleRenderer {...props} onToggle={onToggleActive} />
        },
        {
            field: 'actions',
            title: 'Actions',
            width: 70,
            noGrow: true,
            cellRenderer: props => <ActionsRenderer {...props} disabled={false} onRemoveCheckType={onRemoveCheckType} />
        }
    ];

    return columns.reduce((acc, f) => (groupBy !== f.field ? [...acc, f] : acc), []);
}

function AgreementCheckTypeTable() {
    const notifications = useAgreementCheckTypeStore(state => state.data);
    const openModal = useAgreementCheckTypeStore(state => state.openModal);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const [groupBy, setGroupBy] = useState();
    const columns = useColumns(groupBy);
    const statusChoiceMap = useChoiceListForBaseTable(agreementCheckTypeDef.stage.choiceList);
    const salesChannelChoiceMap = useChoiceListForBaseTable(agreementCheckTypeDef.salesChannels.choiceList);
    const updateCheckTypes = useUpdateCheckTypesWithoutTableUpdate();

    const getGroupByText = () => {
        if (groupBy === agreementCheckTypeDef.stage.field) return 'Stage';
        else if (groupBy === agreementCheckTypeDef.salesChannels.field) return 'Sales Channel';
        else return 'None';
    };

    const getGroupValue = rowData => {
        if (groupBy === agreementCheckTypeDef.stage.field) {
            return rowData[groupBy]
                ? rowData[groupBy]
                      .split(',')
                      .map(m => statusChoiceMap.get(m))
                      .join(', ')
                : 'All';
        } else if (groupBy === agreementCheckTypeDef.salesChannels.field) {
            return rowData[groupBy]
                ? rowData[groupBy]
                      .split(',')
                      .map(m => salesChannelChoiceMap.get(m))
                      .join(', ')
                : 'All';
        }
    };

    const onDragEnd = (_, changedData) => {
        updateCheckTypes(Array.from(changedData.values()));
    };

    const tableData = useMemo(() => {
        return notifications
            .sort((a, b) => a.ordinal - b.ordinal)
            .map(m => ({ ...m, salesChannels: m.salesChannels || null }));
    }, [notifications]);

    const [ref, { y }] = useDimensions({ liveMeasure: false });
    return (
        <>
            <AgreementCheckTypeModal />
            <BaseTableInCard
                cardTitle="Checks"
                columns={columns}
                data={tableData}
                enableDragDrop={true}
                expandAll={true}
                groupBy={groupBy ? [groupBy] : undefined}
                groupRenderer={({ rowData, ...rest }) => (
                    <div className="py-2" style={{ display: 'inherit' }}>
                        <ExpandIcon {...rowData} {...rest} />
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {getGroupValue(rowData)} ({rowData.groupCount})
                        </div>
                    </div>
                )}
                helpId="agreementCheckTypeTableHeading"
                isLoading={isLoading}
                onDragEnd={onDragEnd}
                onRowClicked={({ rowData }) => !rowData.groupRow && openModal(rowData)}
                refForTableHeight={ref}
                rowKey={agreementCheckTypeDef.id.field}
                tableSizeStyle={{ height: `calc(100vh - ${y}px - 5rem`, width: '100%' }}
                titleContentRight={
                    <div>
                        <button className="btn btn-primary text-white mr-2" onClick={() => openModal()}>
                            <i className="fa fa-plus mr-2" />
                            Add Check
                        </button>
                        <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle caret className="text-white">
                                Group By: {getGroupByText()}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => setGroupBy(undefined)}>None</DropdownItem>
                                <DropdownItem onClick={() => setGroupBy(agreementCheckTypeDef.stage.field)}>
                                    Stage
                                </DropdownItem>
                                <DropdownItem onClick={() => setGroupBy(agreementCheckTypeDef.salesChannels.field)}>
                                    Sales Channel
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                }
            />
        </>
    );
}

export default AgreementCheckTypeTable;
