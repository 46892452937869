import React from 'react';
import PersonEditModal from '../Person/Modal/PersonEditModal';
import ExpiredTokenModal from './ExpiredTokenModal';
import EditDayModal from '../Communication/Modals/EditDayModal';
import EditVisitActionModal from '../VisitActions/Modals/EditVisitActionModal';
import EditCommunicationModal from '../Communication/Modals/EditCommunicationModal';
import EditMeetingModal from '../Meeting/Modals/EditMeetingModal';
import CreateLeadModal from '../Lead/Modals/CreateLeadModal';
import CancelCommunicationModal from '../Communication/Modals/CancelCommunicationModal';
import CompleteCommunicationModal from '../Communication/Modals/CompleteCommunicationModal';
import ViewDemoModal from '../Demos/ViewDemoModal';
import CompleteMeetingModal from '../Meeting/Modals/CompleteMeetingModal';
import EditAddressModal from '../Address/Modals/EditAddressModal';
import CancelMeetingModal from '../Meeting/Modals/CancelMeetingModal';

export default () => (
    <>
        <ExpiredTokenModal />
        <PersonEditModal />
        <EditDayModal />
        <EditVisitActionModal />
        <EditCommunicationModal />
        <EditMeetingModal />
        <CancelCommunicationModal />
        <CancelMeetingModal />
        <CompleteCommunicationModal />
        <CompleteMeetingModal />
        <CreateLeadModal />
        <ViewDemoModal />
        <EditAddressModal />
    </>
);
