import React, { useContext } from 'react';
import leasingProfileSchema from '../../reducers/Schema/leasingProfileSchema';
import MapArray from '../common/MapArray';
import { CompanyContext } from '../Company/Provider/CompanyProvider';
import { ValidationProvider } from '../common/FormkControls/ValidationProvider';
import { connect } from 'react-redux';
import LeasingProfileSectionAgreementStyle from './LeasingProfileSectionAgreementStyle';

const LeasingProfileSection = (props) => {
    const comp = useContext(CompanyContext);

    const { leasingProfile } = comp;

    let allowFullWidth = false;
    const from = leasingProfileSchema;
    const classes = ['d-grid', 'grid-column-gap-1'];

    if (from.length === 3) {
        allowFullWidth = true;
        classes.push('grid-cols-3-center-panel');
    } else {
        classes.push('grid-cols-2-center-panel');
    }
    return <ValidationProvider yup={undefined}>
        <div className="d-grid" style={{ gap: '0', marginTop: '1px', gridTemplateColumns: '1fr 1fr' }}>
            <MapArray
                data={{ ...leasingProfile, schema: from }}
                from={from}
                map={(section, index, data) => ({ ...section, index, data, allowFullWidth })}
            >
                <LeasingProfileSectionAgreementStyle />
            </MapArray>
        </div>
    </ValidationProvider>;
}

const mapStateToProps = state => {
    return {
        choiceList: state.resources.choiceList,
        user: state.user
    }
};

export default connect(mapStateToProps)(LeasingProfileSection);
