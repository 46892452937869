import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { Comments } from '../../Agreement/Modals/AgreementAuditModal';
import AgreementAuditForm from '../../Agreement/Forms/AgreementAuditForm';
import addendumDef from '../addendumDef';
import { useAddendaStore } from '../Provider/useAddendaStore';

export default function AddendumRuleAuditModal() {
    const addendum = useAddendaStore(state => state.addendum);
    const isOpen = useAgreementAuditStore(state => state.modalOpen);
    const close = useAgreementAuditStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Comments</AppModalHeader>
                <ModalBody>
                    <Comments />
                    <AgreementAuditForm
                        parentEntity="Addendum"
                        parentEntityId={addendum?.[addendumDef.id.field]}
                        parentStatus={addendum?.[addendumDef.status.field]}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}
