import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button } from 'reactstrap';
import queryString from 'query-string';
import PageSection from '../../common/PageSection';
import Layout3Columns, { Column1, Column2And3 } from '../../Dashboard/Components/Layout3Columns';
import AddendaTable from '../Tables/AddendaTable';
import AddendumFilter from './AddendumFilter';
import { useAddendaStore } from '../Provider/useAddendaStore';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

export default function AddendaPageContent() {
    const { goBack, push } = useHistory();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const create = useAddendaStore(state => state.openModal);
    const updateFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);

    //fetch page
    useEffect(() => {
        if (!search) push(`/addendum?${queryString.stringify({ status: 'DRFT' })}`);
        else {
            const filter = queryString.parse(search);
            const { status, salesChannel, reasonCode, name, recent } = filter;

            const filterClean = {
                statuses: status && status !== '' ? status.split(',') : undefined,
                salesChannels: salesChannel && salesChannel !== '' ? salesChannel?.split(',') : undefined,
                reasonCodes: reasonCode && reasonCode !== '' ? reasonCode?.split(',') : undefined,
                name,
                recent: recent === 'true'
            };

            dispatch(apiPage('addenda', undefined, filterClean))
                .then(response => {
                    updateFromFetch(response.addendum || [], true);
                    updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
                })
                .catch(err => console.error('error', err));
        }
    }, [updateFromFetch, dispatch, push, search, updateMasterTermVersionsFromFetch]);

    return (
        <PageSection
            onBackClicked={goBack}
            title="Mass Addendum"
            titleButtonArea={
                <Button color="info" onClick={create}>
                    <i className="fa fa-plus mr-2" />
                    Create Mass Addendum
                </Button>
            }
        >
            <Layout3Columns>
                <Column1>
                    <AddendumFilter />
                </Column1>
                <Column2And3>
                    <AddendaTable />
                </Column2And3>
            </Layout3Columns>
        </PageSection>
    );
}
