import React, { useEffect, useState } from 'react';
import PageSection from '../../common/PageSection';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import ExpiresBySalesChannelGraph from '../../Agreement/Graphs/ExpiresBySalesChannelGraph';
import CreatedBySalesChannelGraph from '../../Agreement/Graphs/CreatedBySalesChannelGraph';
import AverageLiveDiscount from '../../Agreement/Graphs/AverageLiveDiscount';
import { permissions, useUsersPermission } from '../../../reducers/Api/User/permissions';

export default function AgreementsReportingPage() {
    const [data, setData] = useState({
        agreementsAdminStatusGroupedData: [],
        averageLiveDiscountsData: [],
        loading: true
    });
    const dispatch = useDispatch();
    const { goBack } = useHistory();

    const authorized = useUsersPermission(permissions.AGREENENTS_ADMIN_PAGE).canRead;

    useEffect(() => {
        let mounted = true;
        authorized &&
            dispatch(apiPage('agreements-reports'))
                .then(response => {
                    mounted &&
                        setData(data => ({
                            loading: false,
                            agreementsAdminStatusGroupedData: response.agreementsAdminStatusGroupedData,
                            averageLiveDiscountsData: response.averageLiveDiscount
                        }));
                })
                .catch(err => {
                    mounted && setData(data => ({ ...data, loading: false }));
                    console.error(err);
                });
        return () => (mounted = false);
    }, [dispatch, authorized]);

    if (data.loading || !authorized) {
        return <></>;
    }

    const agreementsAdminStatusGroupedData = data.agreementsAdminStatusGroupedData || [];
    const averageLiveDiscountsData = data.averageLiveDiscountsData || [];

    return (
        <PageSection onBackClicked={goBack} title="Reports">
            <div className="d-grid" style={{ marginTop: '1rem', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem' }}>
                <CreatedBySalesChannelGraph agreementsAdminStatusGroupedData={agreementsAdminStatusGroupedData} />
                <ExpiresBySalesChannelGraph agreementsAdminStatusGroupedData={agreementsAdminStatusGroupedData} />
                <AverageLiveDiscount
                    agreementsAdminStatusGroupedData={agreementsAdminStatusGroupedData}
                    averageLiveDiscountsData={averageLiveDiscountsData}
                />
            </div>
        </PageSection>
    );
}
