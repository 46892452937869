import { RESET_REDUCER } from '../../../actions/resetReducers';
import { API_LOGIN_ENDPOINTS } from '../../../actions/ApiAuth/ApiAuthEndpoints';
import { ENDPOINTS } from '../../../actions/Api/constants';

const defaultState = {
    descriptor: '',
    email: '',
    roles: [],
    userId: -1,
    userName: '',
    expires: null,
    token: '',
    mobile: '',
    fax: '',
    jobTitle: '',
    browsingHistory: [],
    eventCompanyId: -1
};

const initialState = JSON.parse(localStorage.getItem('reduxPersist:user')) || defaultState;

export const isAlm = user => user && user.roles && user.roles.includes('cv_alm');
export const isLbdm = user => user && user.roles && user.roles.includes('cv_lbdm');
export const isDealer = user => user && user.roles && user.roles.includes('cv_dealer');
export const isDealerOrAlm = user => isDealer(user) || isAlm(user);

export const filterAuthorised = (array, user) =>
    array.filter(item => (item.roles && item.roles.some(role => userHasRole(role, user))) || !item.roles);

export const userHasRole = (role, user) => user.roles.some(userRole => role === userRole);

const commitUserUpdate = (action, state) => {
    const {
        payload: { data },
        meta: {
            modified: { userId }
        }
    } = action;

    const { dealershipUsers, ...rest } = state;

    return { dealershipUsers: [data, ...dealershipUsers.filter(dUser => dUser.userId !== userId)], ...rest };
};

const userRemoveFromDealershipCommit = (action, state) => {
    const { dealershipUsers, ...rest } = state;
    return { dealershipUsers: [...dealershipUsers.filter(dUser => dUser.userId !== action.meta.modified)], ...rest };
};

const userRemoveFromDealershipRollback = (action, state) => {
    const { dealershipUsers, ...rest } = state;
    const unmodifiedUser = action.meta.unmodified;
    unmodifiedUser.syncing = false;
    return {
        dealershipUsers: [unmodifiedUser, ...dealershipUsers.filter(dUser => dUser.userId !== unmodifiedUser.userId)],
        ...rest
    };
};

const rollbackUserUpdate = (action, state) => {
    const {
        meta: {
            unmodified: { userId }
        }
    } = action;

    const { dealershipUsers, ...rest } = state;

    return { dealershipUsers: [...dealershipUsers.filter(dUser => dUser.userId !== userId)], ...rest };
};

export function user(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return defaultState;

        case ENDPOINTS.API.DEALERSHIP_USERS_GET:
            const { dealershipDescriptor, dealershipId, dealershipUsers } = action.data;
            return {
                ...state,
                dealershipDescriptor,
                dealershipId,
                dealershipUsers
            };

        case ENDPOINTS.API.DEALERSHIP_USER_SET:
            const { dealershipUserId, dealershipUserDescriptor, ...other1 } = state;
            return {
                ...other1,
                ...action.data
            };

        case ENDPOINTS.API.USER_REMOVE_FROM_DEALERSHIP_COMMIT:
            return userRemoveFromDealershipCommit(action, state);
        case ENDPOINTS.API.USER_REMOVE_FROM_DEALERSHIP_ROLLBACK:
            return userRemoveFromDealershipRollback(action, state);

        case ENDPOINTS.API.USER_UPDATE_ROLLBACK:
            return rollbackUserUpdate(action, state);
        case ENDPOINTS.API.USER_UPDATE_COMMIT:
            return commitUserUpdate(action, state);
        case ENDPOINTS.API.PAGE:
            const bookmarks = action.data.bookmarks;
            const userNotifications = action.data.userNotifications;
            const rolePermissions = action.data.rolePermissions;

            if (bookmarks || userNotifications || rolePermissions) {
                return {
                    ...state,
                    browsingHistory: bookmarks || state.browsingHistory,
                    notifications: userNotifications || state.notifications,
                    permissions: rolePermissions || state.permissions
                };
            }
            return state;

        case API_LOGIN_ENDPOINTS.API.API_AUTH_USER_LOGIN:
            const {
                expires,
                token,
                descriptor,
                email,
                roles,
                userId,
                dealerships,
                userName,
                fax,
                mobile,
                browsingHistory,
                eventCompanyId,
                jobTitle,
                notifications,
                userType,
                permissions
            } = action.data.profile;
            return {
                ...state,
                expires,
                token,
                descriptor,
                email,
                roles,
                dealerships,
                userId,
                userName,
                fax,
                mobile,
                browsingHistory,
                eventCompanyId,
                jobTitle,
                notifications,
                userType,
                permissions
            };

        /*case ENDPOINTS.API.USER:
            return {
                ...state,
                descriptor: action.data.descriptor,
                email: action.data.email,
                roles: action.data.roles,
                userId: action.data.userId,
                userName: action.data.userName,
                fax: action.data.fax,
                mobile: action.data.mobile,
                browsingHistory: action.data.browsingHistory,
                notifications: action.data.notifications,
                userType: action.data.userType
            };*/
        case ENDPOINTS.API.USER_NOTIFICATION_UPDATE:
            return {
                ...state,
                notifications: action.data
            };
        default:
            return state;
    }
}
