import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import { useAddendaStore } from '../Provider/useAddendaStore';
import AddendumForm from '../Forms/AddendumForm.js';

export default function AddendumModal() {
    const isOpen = useAddendaStore(state => state.modalOpen);
    const close = useAddendaStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-xl" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Create Mass Addendum</AppModalHeader>
                <ModalBody>
                    <AddendumForm />
                </ModalBody>
            </Modal>
        </>
    );
}
