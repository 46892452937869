import React from 'react';
import { PreventClickThrough } from './PreventClickThrough';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { useSelector } from 'react-redux';
import { useCompanyById } from '../../Company/Providers/useCompanyById';

export function ColumnTypeFormatCompanyNameFromCompanyId({ companyId }) {
    const openCompany = useSafeDispatchPush(company => ['company', company.id, company.descriptor]);

    const company = useSelector(state => state.companies.find(x => x.companyId === companyId));

    if (!companyId) {
        return <div />;
    }
    return (
        <ColumnTypeFormatCompanyName
            actions={{ openCompany: (id, descriptor) => openCompany({ id, descriptor }) }}
            values={{ company, companyId }}
        />
    );
}

export function ColumnTypeCompanyName({ companyId, descriptor }) {
    const openCompany = useSafeDispatchPush(company => ['company', company.id, company.descriptor]);
    return (
        <CompanyLink
            actions={{ openCompany: (id, descriptor) => openCompany({ id, descriptor }) }}
            companyId={companyId}
            descriptor={descriptor}
        />
    );
}

const ColumnTypeFormatCompanyName = ({ actions, values }) => {
    if (!actions.openCompany) {
        console.warn('openCompany is not available in actions in addColumnsToRow');
    }

    return values.company && values.company.descriptor ? (
        <CompanyLink actions={actions} companyId={values.companyId} descriptor={values.company.descriptor} />
    ) : values.companyId ? (
        <LookupCompanyId actions={actions} companyId={values.companyId} />
    ) : null;
};

function LookupCompanyId({ companyId, actions }) {
    const company = useCompanyById(companyId);
    return company ? <CompanyLink actions={actions} companyId={companyId} descriptor={company.descriptor} /> : null;
}

function CompanyLink({ actions, companyId, descriptor }) {
    return (
        <div>
            {descriptor.split(' - ').map((string, index) => (
                <div key={index}>
                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                    <a
                        onClick={event => {
                            PreventClickThrough(event);
                            actions.openCompany(companyId, descriptor);
                        }}
                        href=""
                    >
                        {string}
                    </a>
                    {/* eslint-enable jsx-a11y/anchor-is-valid */}
                </div>
            ))}
        </div>
    );
}

export default ColumnTypeFormatCompanyName;
