import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { getAxiosGetRequest, getAxiosPostRequest, makePostRequest } from '../apiHelpers';

export function downloadFile(e, f, path = 'library') {
    e.preventDefault();
    e.stopPropagation();

    makePostRequest([path, 'generate-file-url'], undefined, { key: f.fileName })
        .then(d => {
            window.open(d.data, '_blank');
        })
        .catch(err => console.error('error', err));
}

export const apiUpliftAgreementPost = (agreementId, matrixBanding) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'uplift-agreement', agreementId, matrixBanding]))
        .then(({ data }) => {
            toastr.success('Uplift Agreement', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Uplift Agreement', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiProcessAgreementVersionChangePost = agreementId => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'process-version-change', agreementId]))
        .then(({ data }) => {
            toastr.success('Process Version Change', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Process Version Change', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiPreviewDraftVersion = (agreement, preview) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(
        getAxiosGetRequest(['agreement', 'preview-draft-version'], { agreementId: agreement.agreementId, preview })
    )
        .then(({ data }) => {
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Preview Draft Version', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiUploadSignaturePost = data => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function (myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'upload-signature'], undefined, data))
        .then(({ data }) => {
            toastr.success('Upload Signature', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Upload Signature', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiOverwritePdfUploadPost = (agreementId, revisionId, data) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function (myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'overwrite-pdf'], { agreementId, revisionId }, data))
        .then(({ data }) => {
            toastr.success('Overwrite Pdf', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Overwrite Pdf', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiExistingDataUploadPost = (agreementId, data) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'existing-data-upload', agreementId], undefined, data))
        .then(({ data }) => {
            toastr.success('Existing Data Upload', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Existing Data Upload', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiUploadCapCodesPost = excelFile => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['cap', 'upload-cap-codes'], undefined, excelFile))
        .then(({ data }) => {
            toastr.success('Upload Cap Codes', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Upload Cap Codes', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiCapCodesPost = capCodes => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['cap', 'update-cap-codes'], undefined, capCodes))
        .then(({ data }) => {
            toastr.success('Cap Codes Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Cap Codes Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiCapCodeGet = includeDeleted => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosGetRequest(['cap'], { includeDeleted }))
        .then(({ data }) => {
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('CapCodes Fetch', `Response ${err.message}`);
            }
            throw err;
        });
};

export const apiUploadIvsCodesPost = excelFile => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'upload-ivs-codes'], undefined, excelFile))
        .then(({ data }) => {
            toastr.success('Upload IVS Codes', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Upload IVS Codes', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiIvsCodesPost = ivsCodes => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'update-ivs-codes'], undefined, ivsCodes))
        .then(({ data }) => {
            toastr.success('IVS Codes Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('IVS Codes Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiRolePermissionPost = rolePermission => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['auth', 'role', 'permission'], undefined, rolePermission))
        .then(({ data }) => {
            toastr.success('Permission Updated', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Permission Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiUpdateAgreementsAdminUsersVisibility = (userId, seesUserIds) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['auth', 'user', 'visibility'], undefined, { userId, seesUserIds }))
        .then(({ data }) => {
            toastr.success('Permission Updated', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Permission Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementNotificationsPost = notifications => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['notification', 'update-agreement-notifications'], undefined, notifications))
        .then(({ data }) => {
            toastr.success('Agreement Notifications Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Notifications Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementNotificationPeoplePost = notifications => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(
        getAxiosPostRequest(['notification', 'update-agreement-notification-people'], undefined, notifications)
    )
        .then(({ data }) => {
            toastr.success('Agreement Notification People Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Notification People Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementCheckTypesPost = notifications => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'update-agreement-check-types'], undefined, notifications))
        .then(({ data }) => {
            toastr.success('Agreement Checks Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Checks Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementTemplatesPost = notifications => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'update-agreement-templates'], undefined, notifications))
        .then(({ data }) => {
            toastr.success('Agreement Templates Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Templates Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementValidateTemplatesPost = template => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'validate-pdf-agreement'], undefined, template))
        .then(({ data }) => {
            if (!data) {
                toastr.error(
                    'Template Validation Failed',
                    'The file must contain two tables - one for BMW and Mini Terms'
                );
                return false;
            }
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Template Upload', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementMasterTermsPost = masterTerms => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'update-master-terms'], undefined, masterTerms))
        .then(({ data }) => {
            toastr.success('Master Terms Update', 'Success', { disableCloseButtonFocus: true });
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Master Terms Update', `Response ${err.message}`, { disableCloseButtonFocus: true });
            }

            throw err;
        });
};

export const apiAgreementTermsPost = (agreementId, agreementTerms) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'update-agreement-terms'], { agreementId }, agreementTerms))
        .then(({ data }) => {
            toastr.success('Agreement Terms Update', 'Success', { disableCloseButtonFocus: true });
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Terms Update', `Response ${err.message}`, { disableCloseButtonFocus: true });
            }

            throw err;
        });
};
export const apiAgreementTermsReset = agreementId => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'reset-agreement-terms'], { agreementId }))
        .then(({ data }) => {
            toastr.success('Agreement Terms Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Terms Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementVolumesPost = agreementVolumes => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }
    return axios(getAxiosPostRequest(['agreement', 'update-agreement-volumes'], undefined, agreementVolumes))
        .then(({ data }) => {
            toastr.success('Agreement Volumes Update', 'Success', { disableCloseButtonFocus: true });
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Volumes Update', `Response ${err.message}`, { disableCloseButtonFocus: true });
            }

            throw err;
        });
};

export const apiAgreementChecksPost = (companyId, agreementId, checks) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }
    return axios(getAxiosPostRequest(['agreement', 'update-agreement-checks'], { companyId, agreementId }, checks))
        .then(({ data }) => {
            toastr.success('Agreement Checks Update', 'Success', { disableCloseButtonFocus: true });
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Checks Update', `Response ${err.message}`, { disableCloseButtonFocus: true });
            }

            throw err;
        });
};
export const apiAgreementEntityCommentCreate = comment => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }
    return axios(getAxiosPostRequest(['agreement', 'entity-comments-create'], undefined, comment))
        .then(({ data }) => {
            toastr.success('Create Comment', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Create Comment', `Response ${err.message}`);
            }

            throw err;
        });
};

// AgreementPostReturns everything, use this for just updating the agreement
export const apiAgreementQuickPost = agreement => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'quick-update'], undefined, agreement))
        .then(({ data }) => {
            toastr.success('Agreement Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementPost = agreement => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'update'], undefined, agreement))
        .then(({ data }) => {
            toastr.success('Agreement Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementsDelete = agreementIds => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'delete'], undefined, { agreementIds }))
        .then(({ data }) => {
            toastr.success('Agreements Deleted', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreements Deleted', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreemenStatusChange = (agreementId, transitionName, statusFrom, statusTo, agreement) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    agreement.transitionName = transitionName;
    agreement.Url = window.location.href;
    return axios(
        getAxiosPostRequest(['agreement', 'status-change', agreementId, statusFrom, statusTo], undefined, agreement)
    )
        .then(({ data }) => {
            toastr.success('Agreement Status Change', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Status Change', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementCreate = (companyId, agreementType) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    return axios(getAxiosPostRequest(['agreement', 'create', companyId], { agreementType }))
        .then(({ data }) => {
            toastr.success('Agreement Create', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Agreement Create', `Response ${err.message}`);
            }

            throw err;
        });
};
