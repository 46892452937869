import React from 'react';
import PropTypes from 'prop-types';
import { FieldLabel } from '../DisplayField/FieldLabel';

export const LargeFormTitle = ({ title }) => <h6 className="text-muted text-uppercase">{title}</h6>;
export const LargeFormIcon = ({ value }) => (
    <i className={value ? 'fa fa-chevron-up float-right' : 'fa fa-chevron-down float-right'} />
);

const FormikLabel = props => {
    const { fieldName, title, help, style } = props;
    return (
        <label htmlFor={fieldName} style={style}>
            {props.useBigTitle ? (
                <LargeFormTitle title={title} />
            ) : (
                <FieldLabel help={help}>{title || fieldName}</FieldLabel>
            )}
        </label>
    );
};

FormikLabel.propTypes = {
    formName: PropTypes.string,
    fieldName: PropTypes.string.isRequired
};

export default FormikLabel;
