import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import AgreementCheckTypeForm from '../Forms/AgreementCheckTypeForm';
import { useAgreementCheckTypeStore } from '../Provider/useAgreementCheckTypeStore';

export default function AgreementCheckTypeModal() {
    const isOpen = useAgreementCheckTypeStore(state => state.modalOpen);
    const selectedCheckType = useAgreementCheckTypeStore(state => state.selected);
    const close = useAgreementCheckTypeStore(state => state.closeModal);

    return (
        <Modal className="modal-md" isOpen={isOpen} toggle={close}>
            <AppModalHeader toggle={close}>
                {selectedCheckType ? `Edit ${selectedCheckType.name}` : 'Add Check'}
            </AppModalHeader>
            <ModalBody>
                <AgreementCheckTypeForm />
            </ModalBody>
        </Modal>
    );
}
