import React from 'react';
import * as yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import masterTermVersionDef from '../../MasterTermVersion/masterTermVersionDef';
import { FORM_NAMES } from '../../../actions/formNames';
import { useResources } from '../../../selectors/Api/useResources';
import { useMasterTermVersionsStore, useUpdateMasterTermVersion } from '../Provider/useMasterTermVersionsStore';
import storeStates from '../../Agreement/Provider/storeStates';
import { choiceListColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import {
    useMasterTermVersionSalesStoreChannelKeysAsStrings,
    useUpdateMasterTermVersionSalesChannels
} from '../Provider/useMasterTermVersionSalesChannelsStore';
import FormikFormGroup from '../../common/FormkControls/FormikFormGroup';
import MasterTermVersionChanges from '../Components/MasterTermVersionChanges';

const FORM_NAME = FORM_NAMES.EDIT_MASTER_TERMS;

const masterTermsValidationSchema = yup
    .object({
        reference: yup.string().max(40, 'Please enter 40 characters or less')
    })
    .shape({});

const emptyMasterTermForm = {
    masterTermVersionId: -1,
    status: '',
    comments: '',
    activeFrom: new Date(),
    activeTo: new Date()
};

export default function EditMasterTermVersionForm() {
    const saving = useMasterTermVersionsStore(state => state.storeState) === storeStates.saving;
    const version = useMasterTermVersionsStore(state => state.version);
    const editable = version?.workflow?.find(f => f.permission === 'ChangeSalesChannel')?.isEnabled;
    const updateMasterTermVersion = useUpdateMasterTermVersion();

    const updateMasterTermVersionSalesChannels = useUpdateMasterTermVersionSalesChannels();
    const { affectedChannels, commsSalesChannels } = useMasterTermVersionSalesStoreChannelKeysAsStrings(',');

    const resources = useResources();

    return (
        <ReactiveForm
            enableReinitialize={true}
            formName={FORM_NAME}
            handleSubmit={form => {
                updateMasterTermVersion(form);
                updateMasterTermVersionSalesChannels(form.affectedChannels, form.commsSalesChannels);
            }}
            hideCancelButton={true}
            initialValues={{
                ...emptyMasterTermForm,
                ...version,
                affectedChannels,
                commsSalesChannels
            }}
            isSaving={saving}
            resources={resources}
            validationSchema={masterTermsValidationSchema}
        >
            <ReactiveForm.DisplayInColumns>
                <ReactiveForm.Section>
                    <ReactiveForm.Choicelist columnDef={masterTermVersionDef.status} disabled={true} />
                    <ReactiveForm.Text columnDef={masterTermVersionDef.reference} disabled={!editable} />
                    <ReactiveForm.Text columnDef={masterTermVersionDef.comments} disabled={!editable} type="textarea" />
                    <ReactiveForm.Choicelist columnDef={masterTermVersionDef.typeOfChange} disabled={!editable} />
                    <ReactiveForm.Text columnDef={masterTermVersionDef.customersEmailSubject} />
                    <ReactiveForm.HtmlEditor columnDef={masterTermVersionDef.customersEmailBody} />
                    <ReactiveForm.Checkbox columnDef={masterTermVersionDef.bmwApproved} />
                    <ReactiveForm.Dropzone columnDef={masterTermVersionDef.bmwApprovalEvidence} multiple={false} />
                    <ReactiveForm.Other>
                        {formikProps => (
                            <div className="row">
                                <ReactiveForm.DisplayInColumns formikProps={formikProps}>
                                    <ReactiveForm.Section>
                                        <ReactiveForm.Date
                                            columnDef={masterTermVersionDef.activeFrom}
                                            disabled={true}
                                            hide={formikProps => !formikProps?.values?.activeFrom}
                                        />
                                    </ReactiveForm.Section>
                                    <ReactiveForm.Section>
                                        <ReactiveForm.Date
                                            columnDef={masterTermVersionDef.activeTo}
                                            disabled={true}
                                            hide={formikProps => !formikProps?.values?.activeTo}
                                        />
                                    </ReactiveForm.Section>
                                </ReactiveForm.DisplayInColumns>
                            </div>
                        )}
                    </ReactiveForm.Other>
                </ReactiveForm.Section>
                <ReactiveForm.Section>
                    <ReactiveForm.Choicelist
                        columnDef={{
                            ...choiceListColumnDef('Affected Sales Channels', 'affectedChannels', 'salesChannel')
                        }}
                        disabled={!editable}
                        isMulti={true}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={{
                            ...choiceListColumnDef(
                                'Communicate with Sales Channels',
                                'commsSalesChannels',
                                'salesChannel'
                            )
                        }}
                        disabled={!editable}
                        isMulti={true}
                    />
                    <ReactiveForm.Other>
                        {formikProps => (
                            <FormikFormGroup fieldName={'Chagnes'} title="Changes" useBigTitle={false}>
                                <MasterTermVersionChanges changes={formikProps.values.changesDescriptions} />
                            </FormikFormGroup>
                        )}
                    </ReactiveForm.Other>
                </ReactiveForm.Section>
            </ReactiveForm.DisplayInColumns>
        </ReactiveForm>
    );
}
