import React from 'react';

export const PageSectionTitle = props => {
    const { title, warning, loading } = props;
    return !loading ? (
        <h4 className="logo-font gradient my-0 py-0 d-inline">
            {title} {warning}
        </h4>
    ) : (
        <h4 className="logo-font gradient my-0 py-0 d-inline">Loading Agreement</h4>
    );
};
