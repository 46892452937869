import { push } from 'connected-react-router';
import { editCommunication } from './editCommunication';
import { safeDispatchPush } from '../../../reducers/util';

export const communicationSelected = communication => dispatch => {
    dispatch(editCommunication(communication, 'communication'));
};

export const openMeeting = (communicationId, communicationDescriptor) => dispatch =>
    safeDispatchPush(dispatch, push, ['communication', 'view', communicationId, communicationDescriptor]);
