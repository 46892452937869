import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import AgreementStatusChangeReason from '../Forms/AgreementStatusChangeForm';
import { useAgreementChecksStore } from '../Provider/useAgreementChecksStore';
import { getStatusColor } from '../agreementPermissions';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useFilteredTermsInDatabase } from '../Provider/useTermsStore';
import { useIsValidAnticipatedVolume } from '../Provider/useAnticipatedVolumesStore';
import { useAgreementTemplateStore } from '../../AgreementTemplates/Provider/useAgreementTemplateStore';
import { useContractSignatories } from '../Provider/useContractSignatories';
import { useAgreementAuditStore } from '../Provider/useAgreementAuditStore';

export function useHasEnoughSignatures(agreement) {
    const signatories = useContractSignatories();
    const signatureCount = signatories.filter(x => x.personId !== null).length;

    if (!agreement.workflow) {
        return {
            needsMoreSignatures: false,
            signatureCountError: 0
        };
    }

    const requiredNumberOfSignatures = agreement.workflow.reduce(
        (acc, { signaturesRequired }) => (signaturesRequired > acc ? signaturesRequired : acc),
        0
    );

    return {
        needsMoreSignatures: requiredNumberOfSignatures > signatureCount,
        signatureCountError: `${requiredNumberOfSignatures - signatureCount} more signatures required.`
    };
}

const useHasUplift = agreement => {
    const audits = useAgreementAuditStore(state => state.data).find(
        audit =>
            audit.parentEntity === 'Agreement' &&
            audit.comments === `Agreement uplifted to: ${agreement.matrixBanding}` &&
            audit.changes.some(change => change.col === 'Revision' && change.change === agreement.revision.toString())
    );
    return !!audits;
};

export function useAgreementErrors(permission, statusColour, statusTo) {
    const errors = [];

    const agreement = useAgreementsStore(state => state.agreement);
    const agreementFormDirty = useAgreementsStore(state => state.formDirty);
    const checksInvalidCount = useAgreementChecksStore(state => state.checksInvalidCount);
    const hasUplift = useHasUplift(agreement);
    const { signatureCountError, needsMoreSignatures } = useHasEnoughSignatures(agreement);

    const validTemplates = useAgreementTemplateStore(state => state.data.filter(f => f.active).length > 0);

    const validDraftAgreement =
        agreement &&
        agreement.opportunitySummary !== null &&
        agreement.contractVolume !== null &&
        agreement.agreementType !== null &&
        agreement.matrixBanding !== null;

    //tell user specifically which fields need filling in
    let draftAgreementErrors = [];
    if (agreement.opportunitySummary === null) draftAgreementErrors.push('Opportunity Summary is required. ');
    if (agreement.contractVolume === null) draftAgreementErrors.push('Contract Volume is required. ');

    const validAnticipatedVolume = useIsValidAnticipatedVolume();
    const anyTermsWithNext =
        useFilteredTermsInDatabase(
            f =>
                f.nextBase ||
                f.nextDiscretionary ||
                f.nextActiveSupportBonus ||
                f.nextRebate ||
                f.nextPublicSector ||
                f.nextAdded
        ).length > 0;

    if (statusTo !== 'DELT') {
        if (permission && !permission.isEnabled)
            errors.push(permission.disabledReason || 'Not available at this stage');

        if (needsMoreSignatures) errors.push(signatureCountError);

        const moveToChaseStatus = 'CHA1,CHA2,CHA3'.includes(statusTo);
        if ((!statusColour || statusColour !== 'negative')
            && (!statusTo || !moveToChaseStatus)
            && checksInvalidCount > 0)
            errors.push('Please complete checks');

        if (agreementFormDirty) errors.push('Proposal Form has not been saved');

        if (
            ((!statusTo && 'ADEM,ADCH'.includes(agreement.status)) || statusTo === 'ADCH') &&
            !anyTermsWithNext &&
            !hasUplift
        )
            errors.push('Cannot complete an addendum with no changes');

        if (!validDraftAgreement)
            errors.push(
                `Agreement form is not valid${draftAgreementErrors?.length > 0 ? ': ' + draftAgreementErrors : ''}`
            );

        if (!validAnticipatedVolume) errors.push('Anticipated Volume year values need to be filled in');

        if (!validTemplates) errors.push('Agreement must have an active template');
    }

    return errors;
}

export function StatusChangeButton({
    permission,
    modalTitle,
    statusFrom,
    statusTo,
    buttonClass,
    buttonText,
    evidenceMandatory,
    helpText,
    open,
    close
}) {
    const statusColour = getStatusColor(statusTo);
    const errors = useAgreementErrors(permission, statusColour, statusTo);
    const disabledReason = errors[0];

    return (
        <ActionButton
            bootstrapColor="secondary"
            buttonClass={buttonClass}
            buttonText={buttonText}
            disabled={!!disabledReason}
            fontawesomeIcon="fa-plus"
            onClickAction={() =>
                open(
                    modalTitle,
                    <AgreementStatusChangeReason
                        buttonText={buttonText}
                        permission={permission.permission}
                        cancel={() => close()}
                        evidenceMandatory={evidenceMandatory}
                        helpText={helpText}
                        statusFrom={statusFrom}
                        statusTo={statusTo}
                    />
                )
            }
            title={disabledReason}
        />
    );
}
