import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import EditFieldDisplayValue from './EditFieldDisplayValue';
import FormikDate from '../FormkControls/FormikDate';
import SmallSaveCancelButtons from '../InlineEdit/SmallSaveCancelButtons';

class EditDateField extends PureComponent {
	state = { isEditing: false };

	static propTypes = {
		fontawesome: PropTypes.string,
		id: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		title: PropTypes.string.isRequired,
		value: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		field: PropTypes.string.isRequired,
		onChanged: PropTypes.func.isRequired,
		isSyncing: PropTypes.bool,
		isError: PropTypes.string
	};

	static defaultProps = {
		fontawesome: 'fa-pencil'
	};

	renderEditContents() {
		const { field, value } = this.props;
		return (
			<Formik
				initialValues={{ [field]: value || new Date() }}
				onSubmit={this.props.onChanged}
				render={formikProps => {
					formikProps.formName = 'fleetProfile';
					return (
						<form
							className="ml-2"
							onSubmit={e => {
								formikProps.handleSubmit(e);
								setTimeout(() => this.setState({isEditing: false}), 200);
							}}
						>
							<FormikDate fieldName={field} {...formikProps}>
								<SmallSaveCancelButtons
									showSave={formikProps.initialValues.toString() !== formikProps.values.value}
									showCancel={true}
									onCancel={event => {
										this.setState({ isEditing: false });
										formikProps.handleReset(event);
									}}
								/>
							</FormikDate>
						</form>
					);
				}}
			/>
		);
	}

	renderNotEditing(formId, isEdit) {
		const { primary, fontawesome, type, field, value, title, id, isSyncing, isError } = this.props;

		const form = {
			formId,
			id,
			field,
			title,
			value
		};

		form[field] = value;
		form.type = type;

		return (
			<EditFieldDisplayValue
				onClick={() => this.setState({ isEditing: true })}
				isSyncing={isSyncing}
				isError={isError}
				fontawesome={fontawesome}
				value={value ? moment(value).format('Do MMMM YYYY') : ''}
				title={title}
				primary={primary}
				show={isEdit}
			/>
		);
	}

	render() {
		const { field, id } = this.props;
		const { isEditing } = this.state;

		const formId = `${id}-${field}`;
		return isEditing ? this.renderEditContents() : this.renderNotEditing(formId, isEditing);
	}
}

const mapStateToProps = state => ({
	editFieldForm: state.editFieldForm
});

export default connect(mapStateToProps)(EditDateField);
