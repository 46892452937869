import { POPOVER_CLOSE, POPOVER_OPEN } from '../../actions/Popover';

export function popover(state = '', action) {
    switch (action.type) {
        case POPOVER_OPEN:
            return action.id;
        case POPOVER_CLOSE:
            return '';
        default:
            return state;
    }
}
