import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseTableInCard, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import visitAction from '../visitAction';
import { cvOutstandingVisitActions } from '../../../reducers/Api/VisitActions/VisitAction';
import meeting from '../../Communication/meeting';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { editVisitAction } from '../../../actions/Navigation/VisitAction/editVisitAction';

const OutstandingVisitActionsTable = () => {
    const user = useSelector(state => state.user);
    const visitActions = useSelector(state => state.meetings);
    const rows = cvOutstandingVisitActions(visitActions, user.userId);

    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const dispatch = useDispatch();
    const columns = [
        { ...visitAction.startDate, filter: true, sortable: true },
        { ...visitAction.companyNameFromCompanyId, filter: true, sortable: true },
        useChoicelistCellDefinition(visitAction.activityRegarding, { width: 200 })
    ];

    const editRow = visitAction => dispatch(editVisitAction(visitAction));
    return (
        <BaseTableInCard
            cardTitle={`Outstanding Visit Actions (${rows.length})`}
            columns={columns}
            data={rows}
            isLoading={isLoading}
            noItemMessage="No Contacts"
            onRowClicked={({ rowData }) => editRow(rowData)}
            rowKey={meeting.id.field}
            tableSizeStyle={{ height: 'calc(100vh /3)', width: '100%' }}
        />
    );
};

export default OutstandingVisitActionsTable;
