export interface IAction {
	type: string;
	[propName: string]: any; // For now
}

export const ENDPOINTS = {
	API: {
		ADDRESS: 'ADDRESS',
		PAGE: 'PAGE',
		//ADDRESS_BY_ID: 'ADDRESS_BY_ID',
		ADDRESS_POST: 'ADDRESS_POST',
		AGENDA_GET: 'API_AGENDA_GET',
		AFFINITY_VOUCHER_PATCH: 'AFFINITY_VOUCHER_PATCH',
		AFFINITY_EMPLOYER_FILTER: 'AFFINITY_EMPLOYER_FILTER',
		AFFINITY_EMPLOYER_DOMAIN_PUT: 'AFFINITY_EMPLOYER_DOMAIN_PUT',
		AFFINITY_EMPLOYER_DOMAIN_DELETE: 'AFFINITY_EMPLOYER_DOMAIN_DELETE',
		AFFINITY_EMPLOYERS_ADD_OFFER: 'AFFINITY_EMPLOYERS_ADD_OFFER',
		AFFINITY_EMPLOYERS_PUT: 'AFFINITY_EMPLOYERS_PUT',
		AFFINITY_EMPLOYERS_FILTER: 'AFFINITY_EMPLOYERS_FILTER',
		AFFINITY_EMPLOYERS_POST: 'AFFINITY_EMPLOYERS_POST',
		AFFINITY_EMPLOYER_DOMAINS: 'AFFINITY_EMPLOYER_DOMAINS',
		AFFINITY_OFFER_DETAIL_POST: 'AFFINITY_OFFER_DETAIL_POST',
		AFFINITY_DETAILS_FILTER: 'AFFINITY_DETAILS_FILTER',
		AFFINITY_METRICS_PAGE_VIEWS_TODAY: 'AFFINITY_METRICS_PAGE_VIEWS_TODAY',
		AFFINITY_METRICS_PAGE_VIEWS_WEEK: 'AFFINITY_METRICS_PAGE_VIEWS_WEEK',
		AFFINITY_METRICS_EMPLOYER_VOUCHERS_CREATED: 'AFFINITY_METRICS_EMPLOYER_VOUCHERS_CREATED',
		AFFINITY_METRICS_EMPLOYER_VOUCHERS_USED: 'AFFINITY_METRICS_EMPLOYER_VOUCHERS_USED',
		AFFINITY_METRICS_VEHICLE_VOUCHERS_CREATED: 'AFFINITY_METRICS_VEHICLE_VOUCHERS_CREATED',
		AFFINITY_METRICS_VEHICLE_VOUCHERS_USED: 'AFFINITY_METRICS_VEHICLE_VOUCHERS_USED',
		AFFINITY_EMPLOYER_VERIFICATION_CODE_PUT: 'AFFINITY_EMPLOYER_VERIFICATION_CODE_PUT',
		AFFINITY_METRICS_OFFER_VOUCHERS_USED: 'AFFINITY_METRICS_OFFER_VOUCHERS_USED',
		AFFINITY_METRICS_OFFER_VOUCHERS_CREATED: 'AFFINITY_METRICS_OFFER_VOUCHERS_CREATED',
		AFFINITY_METRICS_OFFER_DETAIL_VOUCHERS_USED: 'AFFINITY_METRICS_OFFER_DETAIL_VOUCHERS_USED',
		AFFINITY_METRICS_OFFER_DETAIL_VOUCHERS_CREATED: 'AFFINITY_METRICS_OFFER_DETAIL_VOUCHERS_CREATED',
		AFFINITY_METRICS_RETAILER_VOUCHERS_USED: 'AFFINITY_METRICS_RETAILER_VOUCHERS_USED',
		AFFINITY_METRICS_USER_VOUCHERS_SEARCHED: 'AFFINITY_METRICS_USER_VOUCHERS_SEARCHED',
		AFFINITY_METRICS_RETAILER_VOUCHERS_SEARCHED: 'AFFINITY_METRICS_RETAILER_VOUCHERS_SEARCHED',
		AFFINITY_METRICS_BROWSER_VERSIONS: 'AFFINITY_METRICS_BROWSER_VERSIONS',
		AFFINITY_METRICS_EMPLOYER_LOGIN: 'AFFINITY_METRICS_EMPLOYER_LOGIN',
		AFFINITY_METRICS_RETAILER_LOGIN: 'AFFINITY_METRICS_RETAILER_LOGIN',
		AFFINITY_OFFER_DETAIL_PUT: 'AFFINITY_OFFER_DETAIL_PUT',
		AFFINITY_OFFER_POST: 'AFFINITY_OFFER_POST',
		AFFINITY_OFFER_PUT: 'AFFINITY_OFFER_PUT',
		AFFINITY_OFFERS_FILTER: 'AFFINITY_OFFERS_FILTER',
		AFFINITY_USER_FILTER: 'AFFINITY_USER_FILTER',
		AFFINITY_USER_POST: 'AFFINITY_USER_POST',
		AFFINITY_USER_POST_EMAIL: 'AFFINITY_USER_POST_EMAIL',
		AFFINITY_USER_SET_EMPLOYER_OR_RETAILER: 'AFFINITY_USER_SET_EMPLOYER_OR_RETAILER',
		AFFINITY_USER_VOUCHERS_FILTER: 'AFFINITY_USER_VOUCHERS_FILTER',
		AFFINITY_RETAILER_FILTER: 'AFFINITY_RETAILER_FILTER',
		AFFINITY_RETAILER_PUT: 'AFFINITY_RETAILER_PUT',
		AFFINITY_RETAILER_POST: 'AFFINITY_RETAILER_POST',
		AFFINITY_VEHICLES_PUT: 'AFFINITY_VEHICLES_PUT',
		AFFINITY_VEHICLES_FILTER: 'AFFINITY_VEHICLES_FILTER',
		AFFINITY_VEHICLE_POST: 'AFFINITY_VEHICLE_POST',
		AFFINITY_VOUCHERS_FILTER: 'AFFINITY_VOUCHERS_FILTER',/*
		AGREEMENT_MODELS_GET: 'AGREEMENT_MODELS_GET',
		AGREEMENT_MASTER_TERMS_GET: 'AGREEMENT_MASTER_TERMS_GET',
		AGREEMENT_PUT: 'AGREEMENT_PUT',
		AGREEMENT_POST: 'AGREEMENT_POST',
		AGREEMENT_STATUS_POST: 'AGREEMENT_STATUS_POST',
		AGREEMENT_FILTER: 'AGREEMENT_FILTER',
		AGREEMENT_DELETE: 'AGREEMENT_DELETE',
		AGREEMENT_EVIDENCE_GET: 'AGREEMENT_EVIDENCE_GET',
		AGREEMENT_EVIDENCE_PUT: 'AGREEMENT_EVIDENCE_PUT',
		AGREEMENT_EVIDENCE_DELETE: 'AGREEMENT_EVIDENCE_DELETE',*/
		DEFAULT_TERMS: 'API_DEFAULT_TERMS',
		COMMUNICATION_GET: 'API_COMMUNICATION_GET',
		COMMUNICATION_POST: 'API_COMMUNICATION_POST',
		COMMUNICATION_POST_ROLLBACK: 'API_COMMUNICATION_POST_ROLLBACK',
		COMMUNICATION_POST_COMMIT: 'API_COMMUNICATION_POST_COMMIT',
		COMMUNICATIONS_FILTER: 'COMMUNICATIONS_FILTER',
		COMMUNICATIONS_FILTER_COMMIT: 'COMMUNICATIONS_FILTER_COMMIT',
		RESOURCE: 'RESOURCE',
		REGISTRATION_STATS_GET: 'REGISTRATION_STATS_GET',
		//COMPANIES: 'COMPANIES',
		COMPANY: 'COMPANY',
		//COMPANY_OFFLINE: 'COMPANY_OFFLINE',
		//COMPANY_OFFLINE_COMMIT: 'COMPANY_OFFLINE_COMMIT',
		//COMPANY_OFFLINE_ROLLBACK: 'COMPANY_OFFLINE_ROLLBACK',
		COMPANY_POST: 'API_COMPANY_POST',
		COMPANY_POST_COMMIT: 'API_COMPANY_POST_COMMIT',
		COMPANY_POST_ROLLBACK: 'API_COMPANY_POST_ROLLBACK',
		COMPANY_SEARCH: 'COMPANY_SEARCH',
		//COMPANY_FILTER: 'COMPANY_FILTER',
		COMPANY_TERMS_GET: 'COMPANY_TERMS_GET',
		DEALERSHIP_USERS_GET: 'DEALERSHIP_USERS_GET',
		DEALERSHIP_USER_SET: 'DEALERSHIP_USER_SET',
		FLEET_PROFILE_POST: 'FLEET_PROFILE_POST',
		FLEET_PROFILE_POST_ITEM: 'FLEET_PROFILE_POST_ITEM',
		FLEET_PROFILE_POST_COMMIT: 'FLEET_PROFILE_COMMIT',
		FLEET_PROFILE_POST_ROLLBACK: 'FLEET_PROFILE_ROLLBACK',
		LEASING_PROFILE_POST: 'LEASING_PROFILE_POST',
		LEASING_PROFILE_POST_ITEM: 'LEASING_PROFILE_POST_ITEM',
		LEASING_PROFILE_POST_COMMIT: 'LEASING_PROFILE_POST_COMMIT',
		LEASING_PROFILE_POST_ROLLBACK: 'LEASING_PROFILE_POST_ROLLBACK',
		LEAD: 'LEAD',
		LEAD_POST: 'LEAD_POST',
		LEAD_POST_COMMIT: 'LEAD_POST_COMMIT',
		LEAD_POST_ROLLBACK: 'LEAD_POST_ROLLBACK',
		SUPPORT_REQUEST: 'SUPPORT_REQUEST',
		REPORTS: 'REPORTS',
		//PERSON: 'API_PERSON',
		PERSON_POST: 'PERSON_POST',
		PERSON_POST_COMMIT: 'PERSON_POST_COMMIT',
		PERSON_POST_ROLLBACK: 'PERSON_POST_ROLLBACK',
		//USER: 'API_USER',
		USERS: 'API_USERS',
		USER_NOTIFICATION_UPDATE: 'USER_NOTIFICATION_UPDATE',
		VISIT_ACTION_GET: 'API_VISIT_ACTION_GET',
		VISIT_ACTION_POST: 'API_VISIT_ACTION_POST',
		VISIT_ACTION_POST_COMMIT: 'API_VISIT_ACTION_POST_COMMIT',
		VISIT_ACTION_POST_ROLLBACK: 'API_VISIT_ACTION_POST_ROLLBACK',
		VISIT_ACTIONS_FILTER: 'API_VISIT_ACTIONS_FILTER',
		//VISIT_ACTIONS_FILTER_COMMIT: 'API_VISIT_ACTIONS_FILTER_COMMIT',
		EVENT_FILTER: 'EVENT_FILTER',
		EVENT_POST: 'EVENT_POST',
		MEETING_FILTER: 'MEETING_FILTER',
		MEETING_GET: 'API_MEETING_GET',
		MEETING_POST: 'API_MEETING_POST',
		MEETING_POST_ROLLBACK: 'API_MEETING_POST_ROLLBACK',
		MEETING_POST_COMMIT: 'API_MEETING_POST_COMMIT'
	}
};
