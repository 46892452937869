import React from 'react';

export const FieldValue = props => {
    const { children, primary, className } = props;
    return (
        <div className="height-fix">
            <h6 className={`${className} show-white-lines ${primary ? 'font-weight-bold' : ''}`}>{children}</h6>
        </div>
    );
};
