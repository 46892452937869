import moment from 'moment';
import _ from 'lodash';
import {ICommunicationFilter} from '../../../actions/Api/Communication/apiCommunicationFilterNew';
import {ENDPOINTS, IAction} from '../../../actions/Api/constants';
import {RESET_REDUCER} from '../../../actions/resetReducers';
import communication from "../../../containers/Communication/communication";
import {addColorToCommunication, addColorToCommunications} from "./Comms";

import { setPropertiesSyncing } from '../helper';

enum EventAction {
	followUpCall = 'BSDRBSMCompanyFollowUpCall'
}

export interface ICommunication {
	communicationId: number;
	activityRegarding: string;
	action: EventAction;
	startDate: Date;
	endDate: Date;
	subject: string;
	status: string;
	details: string;
	createdBy: number;
	createdDate: Date;
	updatedBy: number;
	updatedDate: number;
}

const updateCommunicationPost = (state: ICommunication[],  data:ICommunication ) => {
	const original = state.find(x => data.communicationId === x.communicationId);
	if (original) {
		setPropertiesSyncing(original, data);
	}
	addColorToCommunication(data);
	return [data, ...state.filter(oldComm => data.communicationId !== oldComm.communicationId)];
};

export default (state: ICommunication[] = [], action: IAction) => {
	switch (action.type) {
		case RESET_REDUCER:
			return [];

		case ENDPOINTS.API.COMMUNICATIONS_FILTER:
			return updateState(state, action.data, action.filter);

		case ENDPOINTS.API.PAGE:
			if (action.data.communicationFilter) {
				addColorToCommunications(action.data.communications);
				return [
					...action.data.communications,
					...filterState(state, action.data.communicationFilter, action.data.communications)
				];
			}
			return state;

		case ENDPOINTS.API.COMMUNICATION_POST_ROLLBACK:
			const rollbackTo = {
				...action.meta.unmodified,
				syncing: false,
				error: action.payload.message
			};
			return [rollbackTo, ...state.filter(oldComm => rollbackTo.communicationId !== oldComm.communicationId)];

		case ENDPOINTS.API.COMMUNICATION_POST:
			return updateCommunicationPost(state, action.data);

		case ENDPOINTS.API.COMMUNICATION_POST_COMMIT:
			return postCommit(state, action);

		default:
			return state;
	}
};

export function filterState(
	state: ICommunication[],
	filter: ICommunicationFilter,
	newData: ICommunication[]
): ICommunication[] {
	const matchesState = filterToMatchFunction(filter);

	return state.filter(
		x => !newData.some(newComm => x.communicationId === newComm.communicationId) && !matchesState(x)
	);
}

function filterToMatchFunction({ companyId, assigneeId, startDate, endDate }: ICommunicationFilter): Function {
	const start = startDate === undefined ? undefined : moment(startDate);
	const end = endDate === undefined ? undefined : moment(endDate);

	return (communication: ICommunication) => {
		return (
			(!companyId || _.get(communication, 'company.id', '') === companyId) &&
			(!assigneeId || _.get(communication, 'assignee.id', '') === assigneeId) &&
			((!start && !end) ||
				((!start || start.isBefore(moment(communication.startDate))) &&
					(!end || end.isAfter(moment(communication.startDate)))))
		);
	};
}

const updateState = (
	state: ICommunication[],
	update: ICommunication[],
	filter: ICommunicationFilter = {}
): ICommunication[] => [
	//...state.filter(x => !updatedComms.some(y => x.communicationId === y.communicationId)),
	...update.map(communication => ({ ...communication, assigneeId: _.get(communication, 'assignee.id') })),
	...filterState(state, filter, update)
];

export const idField = communication.id.field;

function postCommit(state:ICommunication[], action:IAction){
	const {
		meta: { modified },
		payload: { data: remote }
	} = action;
	return [
		remote,
		...state.filter((x:any) => modified[idField] !== x[idField] && x[idField] !== remote[idField])
	];
}
