import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { useAddendaStore } from '../../Addendum/Provider/useAddendaStore';
import AddendumPageContent from '../../Addendum/Components/AddendumPageContent';
import { useAgreementsStore } from '../../Agreement/Provider/useAgreementsStore';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { useAddendumRulesStore } from '../../Addendum/Provider/useAddendumRulesStore';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import { useAgreementTemplateStore } from '../../AgreementTemplates/Provider/useAgreementTemplateStore';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

export default function AddendumPage({
    match: {
        params: { addendumId: strAddendumId }
    }
}) {
    const updateFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateRulesFromFetch = useAddendumRulesStore(state => state.updateDataFromFetch);
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);
    const updateIvsCodesFromFetch = useIvsCodesStore(state => state.updateDataFromFetch);
    const updateTemplateFromFetch = useAgreementTemplateStore(state => state.updateDataFromFetch);
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);

    const dispatch = useDispatch();
    const addendumId = parseInt(strAddendumId, 10);
    useEffect(() => {
        dispatch(apiPage('addendum', addendumId))
            .then(response => {
                updateFromFetch(response.addendum || [], true);
                updateAgreementsFromFetch(response.agreements || [], true);
                updateAuditsFromFetch(response.addendumAudits || [], true);
                updateRulesFromFetch(response.addendumRules || [], true);
                updateMasterTermsFromFetch(response.masterTerms || [], true);
                updateIvsCodesFromFetch(response.ivsCodes || [], true);
                updateTemplateFromFetch(response.agreementTemplates || [], true);
                updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
            })
            .catch(err => console.error('error', err));
    }, [
        dispatch,
        updateFromFetch,
        updateAgreementsFromFetch,
        updateAuditsFromFetch,
        updateRulesFromFetch,
        updateMasterTermsFromFetch,
        updateIvsCodesFromFetch,
        addendumId,
        updateTemplateFromFetch,
        updateMasterTermVersionsFromFetch
    ]);

    return <AddendumPageContent />;
}
