import React from 'react';
import { downloadFile } from '../../../../actions/Api/Agreement/apiAgreement';

export default function FileRenderer({ cellData, downloadPath }) {
    return (
        <>
            {cellData?.fileName && (
                <>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={e => downloadFile(e, cellData, downloadPath)}>
                        {cellData.fileName.substring(cellData.fileName.indexOf('_') + 1, cellData.fileName.length)}
                    </a>
                </>
            )}
        </>
    );
}
