import React from 'react';
import AddressTableCard from './AddressTableCard';
import { useCompanyAddresses } from '../../../reducers/Api/Company/company';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';

const AddressTable = ({ breakpoint, companyId }) => {
    const addresses = useCompanyAddresses(companyId);
    const loading = useIsLoading([ENDPOINTS.API.PAGE, ENDPOINTS.API.ADDRESS_POST]);

    return <AddressTableCard companyId={companyId} rows={addresses} isLoading={loading} breakpoint={breakpoint} />;
};

export default AddressTable;
