import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import EditAddressForm from '../Forms/EditAddressForm';
import { closeModal, FORM_PATHS } from '../../../actions/modals';
import { AppModalHeader } from '../../common/AppModalHeader';

class EditAddressModal extends React.Component {
	render() {
		const { modals, setModalClosed } = this.props;
		if (!modals.EditAddressModal.isOpen) {
			return '';
		}

		return (
			<Modal isOpen toggle={() => setModalClosed()} className="modal-lg">
				<AppModalHeader toggle={() => setModalClosed()}>
					{modals.EditAddressModal.data.addressId < 0 ? 'Create Address' : 'Edit Address'}
				</AppModalHeader>
				<ModalBody>
					<EditAddressForm
						companyId={modals.EditAddressModal.data.companyId}
						addressId={modals.EditAddressModal.data.addressId}
						cancel={() => setModalClosed()}
					/>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = state => ({
	modals: state.modals
});

const mapDispatchToProps = dispatch => ({
	setModalClosed: () => dispatch(closeModal(FORM_PATHS.EditAddress.modalName))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditAddressModal);
