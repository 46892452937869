import { IEvent } from '.';

export const filterNext7DaysEvents = (events: IEvent[], userId: number) => {
	const start = new Date();
	start.setHours(0, 0, 0, 0);

	const end = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 8);

	return events.filter(
		x => new Date(x.startDate) >= start && new Date(x.startDate) <= end && x.assigneeId === userId
	);
};

export const filterEventsAssignedTo = (event: IEvent[], userId: number): IEvent[] =>
	event.filter(x => x.assigneeId === userId || (x.assignee && x.assigneeId === userId));
