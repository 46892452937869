import React from 'react';
import { Scatter } from 'react-chartjs-2';
import DashboardAdminCard from '../../Agreement/Dashboard/DashboardAdminCard';
import { useAgreementLiveTermsStore } from '../Provider/useAgreementLiveTermsStore';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true
                }
            }
        ]
    }
};

const useChartData = () => {
    const data = useAgreementLiveTermsStore(state => state.data);

    const counts = Object.entries(
        data.reduce((acc, { totalDiscount }) => {
            if (!acc[totalDiscount]) {
                acc[totalDiscount] = 0;
            }
            acc[totalDiscount]++;
            return acc;
        }, {})
    )
        .map(([discount, count]) => ({ x: parseFloat(discount), y: count }))
        .sort((a, b) => (a.x > b.x ? 1 : a.x < b.x ? -1 : 0));

    const average = (counts.reduce((acc, { x, y }) => acc + x * y, 0) / data.length).toFixed(2);

    return {
        labels: 'Count',
        datasets: [
            {
                label: 'Discount',
                data: counts,
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 200)',
                fill: false,
                borderWidth: 1,
                tension: 0,
                showLine: true
            },
            {
                label: 'Average',
                data: [
                    { x: 0, y: average },
                    { x: counts.length > 0 ? counts[counts.length - 1].x : 0, y: average }
                ],
                pointBackgroundColor: 'rgb(54, 162, 235)',
                pointBorderColor: 'rgb(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 200)',
                fill: false,
                borderWidth: 1,
                tension: 0,
                showLine: true
            }
        ]
    };
};

export default function AgreementLiveTermsGraph({ setGraphFilter }) {
    const graphData = useChartData();

    const getElementAtEvent = element => {
        if (!element.length) return;
        const { _datasetIndex, _index } = element[0];
        const value = graphData.datasets[_datasetIndex].data[_index]
        const discount = value.x;
        setGraphFilter(discount);
    }

    return (
        <div>
            <DashboardAdminCard title="Terms Discounts">
                <div className="slight-glow relative" style={{ background: 'rgb(245 245 250)' }}>
                    <Scatter
                        data={graphData}
                        getElementAtEvent={getElementAtEvent}
                        options={options}
                    />
                </div>
            </DashboardAdminCard>
        </div>
    );
}
