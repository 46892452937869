import React, { useContext } from 'react';
import PersonDetails from './PersonDetails';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';

function CompanyPrimaryPeople() {
    const { isLoading, companyId, people } = useContext(CompanyPeopleContext);
    const primaryPeople = people.filter(x => x.isPrimaryPerson);
    const primaryPerson = primaryPeople.length ? primaryPeople[0] : null;

    return (
        <PersonDetails 
            key={primaryPerson ? primaryPerson.personId : ''}
            title={primaryPerson ? 'Primary Person' : 'No primary person associated with this company'}
            person={primaryPerson}
            companyId={companyId}
            isLoading={isLoading}
        />
    );
}

export default CompanyPrimaryPeople;