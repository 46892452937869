import React, { useContext } from 'react';
import SimpleCard from '../../common/Card/SimpleCard';
import { MeetingContext } from '../Provider/MeetingProvider';
import { DisplayField } from '../../common/DisplayField';

export default () => {
	const { meeting } = useContext(MeetingContext);
	return (
		<SimpleCard title="Meeting Details">
			<DisplayField value={meeting.details} />
		</SimpleCard>
	);
};
