import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { closeModal, FORM_PATHS } from '../../../actions/modals';
import { AppModalHeader } from '../../common/AppModalHeader';
import CancelCommunicationForm from '../Forms/CancelCommunicationForm';

class CancelCommunicationModal extends React.Component {
    render() {
        const { modals, setModalClosed } = this.props;
        return modals.CancelCommunicationModal.isOpen ? (
            <Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
                <AppModalHeader toggle={() => setModalClosed()}>Cancel Communication</AppModalHeader>
                <ModalBody>
                    <CancelCommunicationForm
                        onCancel={() => setModalClosed()}
                        communicationId={modals.CancelCommunicationModal.data.communicationId}
                        companyId={modals.CancelCommunicationModal.data.companyId}
                    />
                </ModalBody>
            </Modal>
        ) : (
            ''
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.CancelCommunicationModal.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelCommunicationModal);
