import React from 'react';
import { Alert } from 'reactstrap';

const filterErrors = ({ errors, touched, submitCount }) => {
    return Object.keys(errors).reduce((acc, key) => {
        if (!touched || submitCount > 0 || touched[key]) {
            acc.push({ key, error: errors[key] });
        }
        return acc;
    }, []);
};

export default props => {
    const errs = filterErrors(props);

    return errs.length ? (
        <Alert className="mt-4" color="danger">
            <p>
                <b>Please correct the following before submitting again:</b>
            </p>
            <ul>
                {errs.map(x =>
                    Array.isArray(x.error) ? (
                        x.error.map(y => <li key={y}>{Object.values(y).join(', ')}</li>)
                    ) : (
                        <li key={x.key}>{typeof x.error === 'string' ? x.error : Object.values(x.error).join(', ')}</li>
                    )
                )}
            </ul>
        </Alert>
    ) : null;
};
