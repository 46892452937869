import React from 'react';
import moment from 'moment';
import { useChoiceListForBaseTable, useChoiceListObject } from '../../common/Hooks/useChoiceList';
import AgreementStatusChangeModal from '../Modals/AgreementStatusChangeModal';
import { StatusChangeButton } from './AgreementActions';
import HistoryBreadcrumbItem from './AgreementHistoryBreadcrumbItem';
import { useAgreementAuditStore } from '../Provider/useAgreementAuditStore';
import { useStatusChangeButtons } from '../Provider/useStatusChangeButtons';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useCountTermsWhereApprovalRequired } from '../Provider/useTermsStore';
import { useWorkflowPermittedMap } from '../../../reducers/Api/User/permissions';

const MAX_BREADCRUMBS = 6; //max number of breadcrumbs allowed before collapse

function StatusChangeButtons({ workflow, statusFrom }) {
    let statusChangeButtons = useStatusChangeButtons(workflow, statusFrom);
    const { financeApproval, flmApproval } = useCountTermsWhereApprovalRequired();

    const workflowPermittedMap = useWorkflowPermittedMap();

    statusChangeButtons = statusChangeButtons.filter(statusChangeButton => {
        const permitted = workflowPermittedMap.get(statusChangeButton.permission?.permission);
        //Not using !permitted as some buttons do not have permissions
        if (permitted === false) return false;

        switch (statusChangeButton.permission.checkAuthorisation) {
            case 'FINA':
                return financeApproval > 0;
            case 'USER':
                return flmApproval > 0 && financeApproval === 0;
            case 'NONE':
                return flmApproval === 0 && financeApproval === 0;

            default:
                return true;
        }
    });

    return (
        <AgreementStatusChangeModal>
            {({ open, close }) =>
                statusChangeButtons.length === 0 ? (
                    <li>
                        <div />
                    </li>
                ) : (
                    <li className="status-crumb-actions">
                        {statusChangeButtons.map(x => (
                            <StatusChangeButton
                                key={x.id}
                                {...x}
                                buttonClass={`hover-pointer nowrap ml-3 ${
                                    x.buttonType === 'negative'
                                        ? 'btn btn-danger'
                                        : x.buttonType === 'positive'
                                        ? 'btn btn-success'
                                        : 'btn btn-info'
                                }`}
                                close={close}
                                open={open}
                                style={{ zIndex: 5000 }}
                            />
                        ))}
                    </li>
                )
            }
        </AgreementStatusChangeModal>
    );
}

function groupStatusChangesForCollapse(statusChanges) {
    return statusChanges.length >= MAX_BREADCRUMBS - 1
        ? [
              {
                  ...statusChanges[0],
                  collapsedChanges: statusChanges.slice(0, statusChanges.length - MAX_BREADCRUMBS + 1)
              },
              ...statusChanges.slice(statusChanges.length - MAX_BREADCRUMBS + 1)
          ]
        : statusChanges;
}

export function useStatusChanges(audits, statusChoiceList) {
    const authorsList = useChoiceListForBaseTable('userAuthors');
    const agreementStatuses = useChoiceListObject(statusChoiceList);

    return groupStatusChangesForCollapse(
        audits
            .filter(audit => audit.newStatus)
            .map(audit => ({
                ...audit,
                date: moment(audit.createdDate),

                status: agreementStatuses[audit.newStatus],
                statusCode: audit.newStatus,
                author: authorsList.get(audit.createdBy.toString())
            }))
            .sort(({ date: a }, { date: b }) => (a.isSame(b) ? 0 : a.isBefore(b) ? -1 : 1))
    );
}

export function HistoryBreadcrumb({ statusChanges, children }) {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', minWidth: 'max-content' }}>
            <div className="d-flex flex-row w-100 status-crumb">
                <ul className="d-flex flex-row status-crumb">
                    {statusChanges.map(statusChange => (
                        <HistoryBreadcrumbItem key={statusChange.auditId} statusChange={statusChange} />
                    ))}
                    {children}
                </ul>
            </div>
        </div>
    );
}

export default function AgreementHistoryBreadcrumb() {
    const workflow = useAgreementsStore(state => state.agreement?.workflow);
    const status = useAgreementsStore(state => state.agreement?.status);
    const audits = useAgreementAuditStore(state => state.data);
    const statusChanges = useStatusChanges(audits, 'agreementHeaderStatus');
    return (
        <HistoryBreadcrumb statusChanges={statusChanges}>
            <StatusChangeButtons workflow={workflow} statusFrom={status} />
        </HistoryBreadcrumb>
    );
}
