import React from 'react';
import { useHistory } from 'react-router';
import PageSection from '../../common/PageSection';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import AgreementRulesTable from '../Tables/AgreementRulesTable';

export default function AgreementRulesPageContent() {
    const { goBack } = useHistory();

    return (
        <PageSection onBackClicked={goBack} title="Agreement Rules" titleExtra={<PageLoadingSpinner />}>
            <AgreementRulesTable />
        </PageSection>
    );
}
