import { push } from 'connected-react-router';
import { retryErrors } from '../Api/RetryErrors/retryErrors';

export const apiUserLoggedIn = () => async dispatch => {
    //dispatch(dashboardGet());
    dispatch(retryErrors());
    //dispatch(apiResource());

    dispatch(push('/dashboard/home'));
};
