import moment from "moment";
export interface IMeeting {
	meetingId: number;
	createdBy: number;
	createdDate: string;
	updatedBy: number;
	updatedDate: string;
	descriptor: string;
	activityRegarding: string;
	startDate: string;
	endDate: string;
	status: string;
	location: string;
	meetingMode: string;
	details: string;
	feedback: string;
	rating: string; // TODO make enum
	roiBooked: string; // TODO make enum or bool
	notes: string;
	subject: string;
	companyId?:number;
	companyDescriptor:string;
	personId?: number;
	personDescriptor: string;
	assigneeId?: number;
	assigneeDescriptor: string;
}


export const filterNext7DaysMeetings = (meetings: IMeeting[], userId: number) => {

	const start = new Date();
	start.setHours(0, 0, 0, 0);

	const end = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 8);

	return meetings.filter(
		x => new Date(x.startDate) >= start && new Date(x.startDate) <= end  && x.assigneeId === userId
	).map(({startDate, endDate, ...rest}) => ({startDate, endDate, ...rest, start:moment(startDate), end:moment(endDate)}));
};

export const filterMeetingsAssignedTo = (meetings: IMeeting[], userId: number) => {
	return meetings.filter(x => x.assigneeId === userId);
};
