import { basicColumnDef, choiceListColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const agreementCheckTypeDef = {
    id: idColumnDef('id', 'agreementCheckTypeId'),
    name: {
        ...basicColumnDef('Name', 'name'),
        filter: true,
        sortable: true,
        help: {
            helpId: 'name',
            helpText: 'Name of the check'
        }
    },
    helpText: {
        ...basicColumnDef('Help Text', 'helpText'),
        type: 'textarea',
        filter: true,
        sortable: true,
        help: {
            helpId: 'helpText',
            helpText: 'Help Text to show next to the check'
        }
    },
    asb: {
        ...basicColumnDef('ASB Only', 'asb'),
        sortable: true,
        help: {
            helpId: 'asb',
            helpTitle: 'Active Support Bonus',
            helpText: 'If yes then check is only required for Active Support Bonus Agreements'
        }
    },
    mandatoryEvidence: {
        ...basicColumnDef('Evidence', 'mandatoryEvidence'),
        sortable: true,
        help: {
            helpId: 'mandatoryEvidence',
            helpText: 'If true, then evidence must be uploaded along with the check'
        }
    },
    required: {
        ...basicColumnDef('Required', 'required'),
        sortable: true,
        help: {
            helpId: 'required',
            helpText: 'Check must be filled in before next stage of agreement'
        }
    },
    stage: {
        ...choiceListColumnDef('Stage', 'stage', 'agreementHeaderStatus'),
        help: {
            helpId: 'agreementHeaderStatus',
            helpText: 'The stage the check is required at'
        }
    },
    salesChannels: {
        ...choiceListColumnDef('Sales Channel(s)', 'salesChannels', 'salesChannel'),
        help: {
            helpId: 'salesChannel',
            helpText: 'Sales channel the checks are appropriate for, or all channels'
        }
    },
    agreementType: {
        ...choiceListColumnDef('Agreement Type', 'agreementType', 'agreementType'),
        help: {
            helpId: 'agreementType',
            helpText: 'The type of the agreement'
        }
    },
    mustPass: {
        ...basicColumnDef('Must Pass', 'mustPass'),
        sortable: true,
        help: {
            helpId: 'mustPass',
            helpText: 'Check must be positive before agreement can progress to next stage'
        }
    },
    active: {
        ...basicColumnDef('Active', 'active'),
        sortable: true,
        help: {
            helpId: 'active',
            helpText: 'Set to no, if check is no longer required'
        }
    }
};

export default agreementCheckTypeDef;
