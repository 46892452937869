import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import FormikFormGroup from './FormikFormGroup';
import { useDebouncedField } from '../ReactiveForm';

const FormikText = ({
    children,
    debounced = false,
    disabled,
    errors,
    fieldName,
    formName,
    handleBlur,
    handleChange,
    hideLabel,
    help,
    inputGroupAddons,
    inputClassName,
    max,
    min,
    onFormValueChanged,
    placeholder,
    prefix,
    step,
    style = {},
    title,
    touched,
    type,
    useBigTitle = false,
    values
}) => {

    const onChange = (value) => {
        onFormValueChanged && onFormValueChanged(values, fieldName, value);
    }

    useDebouncedField(values[fieldName], onChange, debounced);

    const invalid = touched[fieldName] && errors[fieldName];
    return (
        <FormikFormGroup
            useBigTitle={useBigTitle}
            fieldName={fieldName}
            formName={formName}
            help={help}
            hideLabel={hideLabel}
            style={style}
            title={title}
        >
            <InputGroup>
                {prefix && (
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>{prefix}</InputGroupText>
                    </InputGroupAddon>
                )}
                <Input
                    className={inputClassName || type !== 'number' ? 'py-4' : 'form-control-lg'}
                    data-test-id={fieldName}
                    disabled={disabled}
                    invalid={!!invalid}
                    max={max}
                    min={min}
                    name={fieldName}
                    onBlur={handleBlur}
                    onChange={a => {
                        handleChange(a);

                        if (!debounced)
                            onChange(a.target.checked);
                    }} // is this ok to set it to empty stirng //index.js:2177 Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
                    placeholder={placeholder}
                    step={step}
                    type={type}
                    value={values[fieldName] !== undefined ? values[fieldName] : ''}
                />
                {inputGroupAddons}
                {invalid && <FormFeedback tooltip>{errors[fieldName]}</FormFeedback>}
            </InputGroup>
            {children}
        </FormikFormGroup>
    );
};

FormikText.propTypes = {
    values: PropTypes.object.isRequired,
    formName: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    type: PropTypes.string
};

FormikText.defaultProps = {
    type: 'text'
};

export default FormikText;
