import React from 'react';
import { PageSectionTitle } from './PageSection/PageSectionTitle';
import appSettings from '../../config/appSettings';

export const AppModalHeader = props => {
    const { children, toggle, hideCloseButton } = props;

    if (appSettings.style === 'modern') {
        if (hideCloseButton) {
            return (
                <div className="modal-header custom-modal-header">
                    <h4 className="logo-font modal-header-heading">{children}</h4>
                </div>
            );
        }
        return (
            <div className="modal-header custom-modal-header">
                <h4 className="logo-font modal-header-heading">{children}</h4>
                <button className="modal-header-close" onClick={() => toggle()} type="button">
                    X
                </button>
            </div>
        );
    }

    if (hideCloseButton) {
        return (
            <div className="page-title-background pb-1 pt-3 modal-header">
                <div className="pl-1 pt-1 pb-2">
                    <PageSectionTitle title={children} />
                </div>
            </div>
        );
    }
    return (
        <div className="page-title-background pb-1 pt-3 modal-header">
            <button className="navbar-btn position-absolute mr-1 align-text-top" onClick={() => toggle()} type="button">
                <i className="one-rem mt-0 pt-0 fa fa-times" />
            </button>
            <div className="pl-5 pt-1 pb-2">
                <PageSectionTitle title={children} />
            </div>
        </div>
    );
};
