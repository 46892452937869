import { basicColumnDef, choiceListColumnSettings } from '../common/FilteredTable/tableAndFilterHelpers';

const lead = {
    companyName: {
        ...basicColumnDef('Company Name', 'companyName')
    },
    brand: {
        ...basicColumnDef('Brand', 'brand'),
        ...choiceListColumnSettings('brand'),
        emptyOption: 'Select a brand...'
    },
    website: {
        ...basicColumnDef('Website', 'website')
    },
    phoneNumer: {
        ...basicColumnDef('Phone Number', 'phoneNumber')
    },
    salutation: {
        ...basicColumnDef('Salutation', 'salutation'),
        ...choiceListColumnSettings('personSalutation'),
        emptyOption: 'Select a salutation...'
    },
    jobTitle: {
        ...basicColumnDef('Job Title', 'jobTitle')
    },
    firstName: {
        ...basicColumnDef('First name', 'firstName')
    },
    lastName: {
        ...basicColumnDef('Last Name', 'lastName')
    },
    mobile: {
        ...basicColumnDef('Mobile', 'mobile')
    },
    email: {
        ...basicColumnDef('Email', 'email'),
        type: 'email'
    },
    address1: {
        ...basicColumnDef('Address Line 1', 'address1')
    },
    address2: {
        ...basicColumnDef('Address Line 2', 'address2')
    },
    address3: {
        ...basicColumnDef('Address Line 3', 'address3')
    },
    address4: {
        ...basicColumnDef('Address Line 4', 'address4')
    },
    city: {
        ...basicColumnDef('City', 'city')
    },
    county: {
        ...basicColumnDef('County', 'county')
    },
    postcode: {
        ...basicColumnDef('Post Code', 'postcode')
    },
    fleetSize: {
        ...basicColumnDef('Fleet Size', 'fleetSize'),
        type: 'number',
        min: 0,
        max: 1000000
    },
    numberOfCars: {
        ...basicColumnDef('Number of Cars', 'numberOfCars'),
        type: 'number',
        min: 0,
        max: 1000000
    },
    numberOfLCV: {
        ...basicColumnDef('Number of LCV', 'numberOfLCV'),
        type: 'number',
        min: 0,
        max: 1000000
    },
    numberOfVans: {
        ...basicColumnDef('Number of Vans', 'numberOfVans'),
        type: 'number',
        min: 0,
        max: 1000000
    }
};

export default lead;
