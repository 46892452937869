import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { closeModal, FORM_PATHS } from '../../../actions/modals';
import { AppModalHeader } from '../../common/AppModalHeader';
import EditDayForm from '../Forms/EditDayForm';

class EditDayModal extends React.Component {
	render() {
		const { modals, setModalClosed } = this.props;

		return modals.EditDayModal.isOpen ? (
			<Modal isOpen toggle={() => setModalClosed()} className={this.props.className}>
				<AppModalHeader toggle={() => setModalClosed()}>
					{modals.EditDayModal.data.eventId < 0
						? `Create ${modals.EditDayModal.data.eventType || 'Activity'}`
						: `Edit ${modals.EditDayModal.data.eventType || 'Activity'}`}
				</AppModalHeader>
				<ModalBody>
					<EditDayForm
						onCancel={() => setModalClosed()}
						eventId={modals.EditDayModal.data.eventId}
						startDate={modals.EditDayModal.data.startDate}
						eventType={modals.EditDayModal.data.eventType}
					/>
				</ModalBody>
			</Modal>
		) : null;
	}
}

const mapStateToProps = state => ({
	modals: state.modals
});

const mapDispatchToProps = dispatch => ({
	setModalClosed: () => dispatch(closeModal(FORM_PATHS.EditDayModal.modalName))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditDayModal);
