import React from 'react';
import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: {
        ...idColumnDef('Id', 'masterTermVersionId'),
        sortable: true,
        filter: true
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'masterTermVersionStatus'),
        emptyOption: 'Select a status...',
        sortable: true,
        filter: true,
        help: {
            helpId: 'status',
            helpText: 'The status of the master term version'
        }
    },
    reference: {
        ...basicColumnDef('Reference', 'reference'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'reference',
            helpText: 'The reference of the master term version'
        }
    },
    comments: {
        ...basicColumnDef('Comments', 'comments'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'comments',
            helpText: 'The comments of the master term version'
        }
    },
    activeFrom: {
        ...dateColumnDef('Active From', 'activeFrom'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'activeFrom',
            helpText: 'The start of this master term version'
        }
    },
    activeTo: {
        ...dateColumnDef('Active To', 'activeTo'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'activeTo',
            helpText: 'The end of this master term version'
        }
    },
    typeOfChange: {
        ...choiceListColumnDef('Type of Change', 'typeOfChange', 'masterTermVersionTypeOfChange'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'typeOfChange',
            helpText: 'The type of change of this master term version'
        }
    },
    customersEmailSubject: {
        ...basicColumnDef('Customer Email Subject', 'customersEmailSubject'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'customersEmailSubject',
            helpText: 'Email Subject'
        }
    },
    customersEmailBody: {
        ...basicColumnDef('Customer Email', 'customersEmailBody'),
        sortable: true,
        filter: true,
        help: {
            helpId: 'customersEmailBody',
            helpTitle: 'Email Body - Merge Fields',
            helpText: (
                <>
                    <div>{'<<FirstName>>'}</div>
                    <div>{'<<LastName>>'}</div>
                </>
            )
        }
    },
    bmwApproved: {
        ...basicColumnDef('BMW Approved', 'bmwApproved'),
        sortable: true,
        filter: true,
        mapToDisplayedValue: (resources, values, field) => <>{field ? 'Yes' : 'No'}</>,
        help: {
            helpId: 'bmwApproved',
            helpText: 'If the version has been approved'
        }
    },
    bmwApprovalEvidence: {
        ...basicColumnDef('BMW Approval Evidence', 'bmwApprovalEvidence'),
        sortable: true,
        filter: true,
        mapToDisplayedValue: (resources, values, field) => <>{field?.fileName}</>,
        help: {
            helpId: 'bmwApprovalEvidence',
            helpText: 'The approval evidence of this master term version'
        }
    }
};
