import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';

export default (state = [], { type, data, filter }) => {
    switch (type) {
        case ENDPOINTS.API.PAGE:
            return data.agreementFilter
                ? [
                      ...data.agreements,
                      ...state.filter(
                          x =>
                              (data.agreementFilter.companyId === undefined ||
                                  x.companyId !== data.agreementFilter.companyId) &&
                              (data.agreementFilter.agreementId === undefined ||
                                  x.agreementId !== data.agreementFilter.agreementId)
                      )
                  ].slice(0, data.agreements.length > 50 ? data.agreements.length : 50)
                : state;

        case RESET_REDUCER:
            return [];

        default:
            return state;
    }
};
