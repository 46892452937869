const yearlyToMonthly = 'YearlyToMonthly';
const yearlyToQuarterly = 'YearlyToQuarterly';
const quarterlyToMonthly = 'QuarterlyToMonthly';
const quarterlyToYearly = 'QuarterlyToYearly';
const monthlyToYearly = 'MonthlyToYearly';
const monthlyToQuarterly = 'MonthlyToQuarterly';

const getChangeType = (oldFrequency, newFrequency) =>
    oldFrequency === 'Y' && newFrequency === 'M'
        ? yearlyToMonthly
        : oldFrequency === 'Y' && newFrequency === 'Q'
        ? yearlyToQuarterly
        : oldFrequency === 'Q' && newFrequency === 'M'
        ? quarterlyToMonthly
        : oldFrequency === 'Q' && newFrequency === 'Y'
        ? quarterlyToYearly
        : oldFrequency === 'M' && newFrequency === 'Y'
        ? monthlyToYearly
        : monthlyToQuarterly;

const calculateQuarter = (quarterNumber, changeType, months, year) => {
    if (changeType === monthlyToQuarterly) {
        return sum(months.slice(quarterNumber * 3, 3));
    }
    if (changeType === yearlyToQuarterly) {
        return (year - (year % 4)) / 4 + (year % 4 > quarterNumber ? 1 : 0);
    }
    return null;
};

const calculateMonth = (monthNumber, changeType, quarters, year) => {
    if (changeType === quarterlyToMonthly) {
        const quarterIndex = (monthNumber + 3 - (monthNumber % 3)) / 3 - 1;
        const quarter = quarters[quarterIndex];
        return (quarter - (quarter % 3)) / 3 + (quarter % 3 > monthNumber - quarterIndex * 3 ? 1 : 0);
    }
    if (changeType === yearlyToMonthly) {
        return (year - (year % 12)) / 12 + (year % 12 > monthNumber ? 1 : 0);
    }
    return null;
};

const sum = arrayToSum => arrayToSum.reduce((a, b) => a + (b || 0), 0);

export const updateFrequencyOfAnticipatedVolume = (x, newFrequency) => {
    const { frequency, year } = x;

    if (frequency === newFrequency) {
        return x;
    }

    const changeType = getChangeType(frequency, newFrequency);
    const months = [x.jan, x.feb, x.mar, x.apr, x.may, x.jun, x.jul, x.aug, x.sep, x.oct, x.nov, x.dec];
    const quarters = [x.q1, x.q2, x.q3, x.q4];

    return {
        ...x,
        frequency: newFrequency,
        year:
            changeType === monthlyToYearly || changeType === monthlyToQuarterly
                ? sum(months)
                : changeType === quarterlyToYearly || changeType === quarterlyToMonthly
                ? sum(quarters)
                : x.year,
        q1: calculateQuarter(0, changeType, months, year),
        q2: calculateQuarter(1, changeType, months, year),
        q3: calculateQuarter(2, changeType, months, year),
        q4: calculateQuarter(3, changeType, months, year),
        jan: calculateMonth(0, changeType, quarters, year),
        feb: calculateMonth(1, changeType, quarters, year),
        mar: calculateMonth(2, changeType, quarters, year),
        apr: calculateMonth(3, changeType, quarters, year),
        may: calculateMonth(4, changeType, quarters, year),
        jun: calculateMonth(5, changeType, quarters, year),
        jul: calculateMonth(6, changeType, quarters, year),
        aug: calculateMonth(7, changeType, quarters, year),
        sep: calculateMonth(8, changeType, quarters, year),
        oct: calculateMonth(9, changeType, quarters, year),
        nov: calculateMonth(10, changeType, quarters, year),
        dec: calculateMonth(11, changeType, quarters, year)
    };
};
