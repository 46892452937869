import { useUpdateAddendumRules } from '../Provider/useAddendumRulesStore';
import addendumRulesDef from '../addendumRulesDef';
import masterTermDef from '../../MasterTerm/masterTermDef';
import ivsCodeDef from '../../IvsCode/ivsCodeDef';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { AuditTableButton, EditableNumber, ToggleRenderer } from '../../common/BaseTable/CusomCellRenderers';
import { maxTermDiscountPct } from '../../../config.json';
import React from 'react';
import { RulesInEnglish } from '../Components/RulesInEnglish';
import { DeleteTableRowButton, TableButtonsContainer } from '../../common/Buttons/TableButtons';

function ActionsRenderer({ onRemoveRule, disabled, rowData, useTableStore }) {
    const newRowData = useTableStore(state => state.dataState.pageData[rowData.index]);
    return (
        <TableButtonsContainer>
            <AuditTableButton
                entity="AddendumRule"
                entityId={newRowData[addendumRulesDef.id.field]}
                rowData={newRowData}
            />
            <DeleteTableRowButton disabled={disabled} onClick={() => onRemoveRule(newRowData)} />
        </TableButtonsContainer>
    );
}

export function useAddendumRulesTableColumns(canEditRules) {
    const updateRules = useUpdateAddendumRules();

    const onUpdateDecimal = (colKey, value, rule) => {
        return updateRules({ ...rule, [colKey]: parseFloat(value) });
    };

    const onRemoveRule = rule => {
        return updateRules({ ...rule, deleted: 1 });
    };

    const columns = [
        addendumRulesDef.name,
        {
            ...addendumRulesDef.masterTerm,
            cellRenderer: ({ cellData }) => <div>{cellData?.[masterTermDef.description.field]}</div>
        },
        {
            ...addendumRulesDef.ivsCode,
            cellRenderer: ({ cellData }) => (
                <div>
                    {cellData
                        ? `${cellData[ivsCodeDef.ivsCode.field]} - ${cellData[ivsCodeDef.modelDescription.field]}`
                        : null}
                </div>
            )
        },
        {
            ...basicColumnDef('Actions', 'rules'),
            cellRenderer: props => <RulesInEnglish {...props} />
        },
        {
            ...addendumRulesDef.changeType,
            width: 130,
            noGrow: true
        },
        {
            ...addendumRulesDef.targetDiscount,
            width: 170,
            noGrow: true,
            cellRenderer: ({ column, cellData, rowData }, { useTableStore }) => (
                <EditableNumber
                    dataKey={column.dataKey}
                    editable={canEditRules && rowData.changeType !== 'DEL'}
                    inputOptions={{ max: maxTermDiscountPct, step: 0.01 }}
                    onUpdate={onUpdateDecimal}
                    rowData={rowData}
                    useTableStore={useTableStore}
                    value={cellData?.toFixed(2)}
                />
            )
        },
        {
            ...addendumRulesDef.insertIfNotPresent,
            width: 100,
            noGrow: true,
            cellRenderer: props => <ToggleRenderer {...props} disabled={true} />
        },
        {
            field: 'actions',
            title: 'Actions',
            width: 125,
            noGrow: true,
            cellRenderer: (props, rest) => (
                <ActionsRenderer {...props} {...rest} disabled={!canEditRules} onRemoveRule={onRemoveRule} />
            )
        }
    ];

    return columns;
}
