import React from 'react';
import ResetPasswordForm from '../Forms/ResetPasswordForm';

class ResetPasswordPage extends React.Component {
    render = () => (
        <div className="login-background d-flex no-block justify-content-center align-items-center">
            <ResetPasswordForm className="bg-white p-4" token={this.props.match.params.resetToken} />
        </div>
    );
}

export default ResetPasswordPage;
