import _ from 'lodash';

export const getChoiceListState = (state, name) => _.get(state, `resources.choiceList.${name}`, []);

export const getDealerStatusChoiceList = state => getChoiceListState(state, 'dealerStatus');

export const getResources = state => state.resources;

export const getChoiceListKeyFromValue = (choiceList, value) =>
    _.get(choiceList.find(period => period.value === value), `key`, undefined);
