import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import MatricesPageContent from '../../MasterTermVersion/Components/MatricesPageContent';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

export default function MatricesPage() {
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(apiPage('matrices'))
            .then(response => {
                updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
            })
            .catch(err => console.error('error', err));
    }, [dispatch, updateMasterTermVersionsFromFetch]);

    return <MatricesPageContent />;
}
