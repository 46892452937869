import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import { setPropertiesSyncing } from '../helper';
import { FORM_PATHS, SET_MODAL_OPEN } from '../../../actions/modals';

const defaultState = [];
const initialState = JSON.parse(localStorage.getItem('reduxPersist:people')) || defaultState;

const updateFromCompany = (state, action) => {
    if (!action.data.people) {
        return state;
    }
    return [...action.data.people, ...state.filter(oldPerson => action.data.companyId !== oldPerson.companyId)];
};

const updatePersonPost = (state, action) => {
    const { data } = action;
    const original = state.find(x => data.personId === x.personId);
    if (original) {
        setPropertiesSyncing(original, data);
    }
    return updatePersonInState(data, state);
};

const updatePersonInState = (person, state) => {
    //if primary person then update state to make sure no other person is primary
    const newState = state.filter(x => person.personId !== x.personId);
    if (person.isPrimaryPerson) {
        return [person, ...newState.map(({ isPrimaryPerson, ...rest }) => ({ ...rest, isPrimaryPerson: false }))];
    }
    return [person, ...newState];
};

const personSort = (a, b) =>
    a.lastName > b.lastName
        ? 1
        : a.lastName < b.lastName
        ? -1
        : a.firstName > b.firstName
        ? 1
        : a.firstName > b.firstName
        ? -1
        : 0;

export function people(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return defaultState;
        case SET_MODAL_OPEN:
            if (action.isOpen || action.name !== FORM_PATHS.EditPerson.modalName || !action.data.personId) {
                return state;
            }
            return [
                action.data,
                ...state
                    .filter(person => person.personId !== action.data.personId)
                    .map(person => ({
                        ...person,
                        isPrimaryPerson: action.data.isPrimaryPerson ? false : person.isPrimaryPerson
                    }))
            ].sort(personSort);
        case ENDPOINTS.API.PAGE:
            return action.data.people
                ? [
                      ...action.data.people,
                      ...state.filter(oldPerson => !action.data.people.some(x => x.personId === oldPerson.personId))
                  ]
                      .slice(0, action.data.people.length > 50 ? action.data.people.length : 50)
                      .sort(personSort)
                : state;
        case ENDPOINTS.API.COMPANY_OFFLINE_COMMIT:
            return updateFromCompany(state, action.payload);
        case ENDPOINTS.API.PERSON_POST_ROLLBACK:
            const rollbackTo = action.meta.unmodified;
            const person = state.find(person => person.personId === rollbackTo.personId);
            person.syncing = false;
            person.error = action.payload.message;
            return updatePersonInState(_.clone(person), state);
        case ENDPOINTS.API.PERSON_POST_COMMIT:
            const {
                meta: { modified: localPerson },
                payload: { data: remotePerson }
            } = action;
            return [
                remotePerson,
                ...state.filter(x => localPerson.personId !== x.personId && remotePerson.personId !== x.personId)
            ];
        case ENDPOINTS.API.PERSON_POST:
            return updatePersonPost(state, action);

        default:
            return state;
    }
}
