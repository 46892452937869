import React from 'react';
import { Alert } from 'reactstrap';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useResources } from '../../../selectors/Api/useResources';
import { useChoiceListObject } from '../../common/Hooks/useChoiceList';
import { useAddendaStore, useUpdateAddendaStatus } from '../Provider/useAddendaStore';
import storeStates from '../../Agreement/Provider/storeStates';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    comment: Yup.string().required('Reason is required.')
});

const evidenceMandatoryValidationSchema = Yup.object().shape({
    comment: Yup.string().required('Reason is required.'),
    evidence: Yup.array().required('Evidence is required.')
});

export default function AddendumStatusChangeReason({
    statusFrom,
    statusTo,
    afterChange,
    cancel,
    buttonText,
    evidenceMandatory,
    helpText
}) {
    const addendum = useAddendaStore(state => state.addendum);
    const saving = useAddendaStore(state => state.storeState) === storeStates.saving;
    const addendumStatuses = useChoiceListObject('addendumStatus');
    const updateAddendumStatus = useUpdateAddendaStatus();
    const resources = useResources();
    const statusFromText = addendumStatuses[statusFrom];
    const statusToText = addendumStatuses[statusTo];

    const initialValues = {
        addendumId: addendum.addendumId,
        comment: ''
    };

    const entity = {
        entityId: addendum.addendumId,
        entityName: 'Addendum',
        description: `Addendum Status Change from: ${statusFromText} to ${statusToText}`
    };

    const save = form => {
        updateAddendumStatus(form, statusFrom, statusTo, cancel);
        afterChange && afterChange(statusTo);
    };

    return (
        <>
            {helpText ? <Alert color="info">{helpText}</Alert> : null}
            <ReactiveForm
                cancel={cancel}
                enableReinitialize={true}
                formName={FORM_NAME}
                handleSubmit={save}
                hideMetaData={true}
                initialValues={initialValues}
                isSaving={saving}
                resources={resources}
                saveButtonContent={buttonText}
                validationSchema={evidenceMandatory ? evidenceMandatoryValidationSchema : validationSchema}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Text columnDef={{ ...basicColumnDef('Comments', 'comment') }} />
                    <ReactiveForm.Dropzone columnDef={{ ...basicColumnDef('Evidence', 'evidence') }} entity={entity} />
                </ReactiveForm.Section>
            </ReactiveForm>
        </>
    );
}
