import React from 'react';
import { Bar } from 'react-chartjs-2';
import DashboardAdminCard from '../Dashboard/DashboardAdminCard';
import { useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel } from './useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true
                }
            }
        ]
    }
};

const ExpiresBySalesChannelGraph = ({ agreementsAdminStatusGroupedData }) => {
    //const openAgreementsSearch = useSafeDispatchPush(() => ['agreement']);
    const graphData = useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel(
        agreementsAdminStatusGroupedData,
        'ExpiringAgreements',
        true
    );

    /*const getElementAtEvent = element => {
        if (!element.length) return;
        const { _datasetIndex, _index } = element[0];

        openAgreementsSearch({}, {
            expiresAfter: graphData.months[_index].start,
            expiresBefore:graphData.months[_index].end,
            salesChannel:graphData.datasets[_datasetIndex].key
        });
    };*/

    return (
        <DashboardAdminCard helpId="agreementsAdminAgreementsCard" title="Agreements Expiring By Sales Channel">
            <div className="slight-glow" style={{ background: 'rgb(245 245 250)' }}>
                <Bar
                    data={graphData}
                    getElementAtEvent={undefined} //getElementAtEvent}
                    options={options}
                />
            </div>
        </DashboardAdminCard>
    );
};

export default ExpiresBySalesChannelGraph;
