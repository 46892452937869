import React, { useState } from 'react';
import * as yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useResources } from '../../../selectors/Api/useResources';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';
import { createNewMasterTerm, useUpdateTerms } from '../Provider/useMasterTermStore';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;
const validationSchema = yup.object().shape({
    series: yup
        .string()
        .nullable()
        .required('Please enter a series')
});

export default function AddMasterTermSeriesForm({ brand, cancel }) {
    const version = useMasterTermVersionsStore(state => state.version);
    const [saving, setSaving] = useState(false);
    const updateTerms = useUpdateTerms();
    const useTableStore = brand === 'BMW' ? useBMWStore : useMiniStore;
    const setTermsData = useTableStore(state => state.setTermsData);
    const resources = useResources();

    const save = form => {
        const terms = useTableStore.getState().dataCopy;
        const termsForBrand = terms.filter(f => f.brand === brand);
        const ordinal = termsForBrand.length > 0 ? termsForBrand[termsForBrand.length - 1].ordinal + 1 : 0;
        setSaving(true);
        updateTerms([createNewMasterTerm(version, brand, form.series, ordinal)])
            .then(d => {
                setSaving(false);
                setTermsData(d, undefined, ordinal - 1);
                cancel();
            })
            .catch(() => setSaving(false));
    };

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={save}
            isSaving={saving}
            resources={resources}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Series', 'series') }} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
