import React, { useContext } from 'react';
import { Alert } from 'reactstrap';
import FormSelect from '../../common/FormSelect';
import { FieldLabel } from '../../common/DisplayField/FieldLabel';
import { FormatDate } from '../../common/FormatDate';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';
import PersonDetails from '../../Person/Components/PersonDetails';
import { useAgreementsStore, useQuickUpdateAgreement } from '../Provider/useAgreementsStore';
import { useContractSignatories } from '../Provider/useContractSignatories';

function Signatory({ title, personId, isEditable, signedDate, updatedField, peopleOptions }) {
    const agreement = useAgreementsStore(state => state.agreement);
    const updateAgreement = useQuickUpdateAgreement();
    const { isLoading, companyId, people } = useContext(CompanyPeopleContext);
    const person = people.find(person => person.personId === personId) || {};

    return (
        <PersonDetails
            buttons={[]}
            companyId={companyId}
            fields={PersonDetails.fields.slice(1)}
            isLoading={isLoading}
            marginClass="mb-0"
            person={person}
            title={title}
        >
            {signedDate ? (
                <Alert color="success">
                    Signed On:{' '}
                    <strong>
                        <FormatDate date={signedDate} />
                    </strong>
                </Alert>
            ) : null}
            <FieldLabel>Name</FieldLabel>
            <FormSelect
                className="mb-2"
                isClearable={true}
                isDisabled={!isEditable}
                onChange={props => {
                    const updated = { ...agreement };
                    updated[updatedField] = props?.value;
                    updateAgreement(updated);
                }}
                options={peopleOptions}
                placeholder="Select Signatory"
                value={peopleOptions.find(x => x.value === person.personId)}
                width="100%"
            />
        </PersonDetails>
    );
}

export function ContractSignatories({ disabled = false }) {
    const { people } = useContext(CompanyPeopleContext);
    const signatories = useContractSignatories();
    const personId1 = useAgreementsStore(state => state.agreement?.contractSignatory1PersonId);
    const personId2 = useAgreementsStore(state => state.agreement?.contractSignatory2PersonId);

    const peopleOptions = updatedField => {
        const otherPersonId = updatedField === 'contractSignatory1PersonId' ? personId2 : personId1;
        return (people || [])
            .filter(f => f.contactRole?.includes('Signatory') || signatories.some(x => x.personId === f.personId))
            .filter(f => f.personId !== otherPersonId)
            .map(person => ({
                value: person.personId,
                label: person.descriptor
            }));
    };

    return (
        <div className="d-grid" style={{ gridTemplateColumns: '1fr 1fr', marginTop: '1px', gap: '1px' }}>
            {signatories.map(({ personId, isEditable, signedDate, updatedField }, index) => (
                <Signatory
                    isEditable={!disabled && isEditable}
                    key={personId || index}
                    peopleOptions={peopleOptions(updatedField)}
                    personId={personId}
                    signedDate={signedDate}
                    title={`Contract Signatory ${index + 1}`}
                    updatedField={updatedField}
                />
            ))}
        </div>
    );
}
