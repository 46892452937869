import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';

const initialState = {
    companies: [],
    totalResults: 0,
    hasMoreRows: false,
    keyword: '',
    page: 0
};

const updateFromFilter = (state, action) => {
    const {
        data: { companies, page, ...rest }
    } = action;
    if (page === 0) {
        return action.data;
    }

    return {
        companies: [...state.companies, ...companies],
        page,
        ...rest
    };
};

export function companySummaries(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.COMPANY_FILTER:
            return updateFromFilter(state, action);
        case ENDPOINTS.API.COMPANY_SEARCH:
            return action.data;
        default:
            return state;
    }
}
