import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { getAxiosPostRequest } from '../apiHelpers';

export const apiAgreementMasterTermVersionsPost = masterTermVersions => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function (myResolve) {
            myResolve({});
        });
    }

    return axios(getAxiosPostRequest(['mastertermversion', 'update-master-term-version'], undefined, masterTermVersions))
        .then(({ data }) => {
            toastr.success('Master Terms Version Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Master Term Version Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiAgreementMasterTermVersionSalesChannelsPost = masterTermVersions => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function (myResolve) {
            myResolve({});
        });
    }

    return axios(getAxiosPostRequest(['mastertermversion', 'update-master-term-version-sales-channels'], undefined, masterTermVersions))
        .then(({ data }) => {
            toastr.success('Master Terms Version Sales Channels Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Master Term Version Sales Channels Update', `Response ${err.message}`);
            }

            throw err;
        });
};
export const apiMasterTermVersionStatusChange = (versionId, statusFrom, statusTo, version) => {
    return axios(getAxiosPostRequest(['mastertermversion', 'status-change', versionId, statusFrom, statusTo], undefined, version))
        .then(({ data }) => {
            toastr.success('Master Terms Version Status Change', 'Success');
            return data;
        })
        .catch(err => {
            toastr.error('Master Terms Version Status Change', `Response ${err.message}`);
            throw err;
        });
};