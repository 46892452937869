import React from 'react';
import create from 'zustand';
import { DropdownItem, Modal, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm/index';
import { AppModalHeader } from '../../common/AppModalHeader';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import storeStates, { storeWithModal } from '../Provider/storeStates';
import { permissions, RenderIfHasWritePermission } from '../../../reducers/Api/User/permissions';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useAgreementAuditStore } from '../Provider/useAgreementAuditStore';
import { apiOverwritePdfUploadPost } from '../../../actions/Api/Agreement/apiAgreement';

const useOverwritePdfUploadStore = create(set => storeWithModal(set, null));

const validationSchema = Yup.object()
    .shape({
        pdfFile: Yup.object().required('PDF file is required.')
    });

function OverwritePdfUploadForm() {
    const close = useOverwritePdfUploadStore(state => state.closeModal);
    const saving = useOverwritePdfUploadStore(state => state.storeState) === storeStates.saving;
    const setError = useOverwritePdfUploadStore(state => state.setError);
    const setSaving = useOverwritePdfUploadStore(state => state.setSaving);
    const updateDataFromFetch = useOverwritePdfUploadStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();
    const agreementId = useAgreementsStore(state => state.agreement?.agreementId);
    const revision = useAgreementsStore(state => state.agreement?.revision);

    const onSave = (form) => {
        setSaving();
        dispatch(apiOverwritePdfUploadPost(agreementId, revision, { excelFile: form.pdfFile?.contentAsBase64 }))
            .then(response => {
                updateDataFromFetch([]);
                updateAuditsFromFetch(response.agreementHeaderAudits);
            })
            .catch(err => {
                setError(err);
            });
    };

    return (
        <ReactiveForm
            cancel={close}
            handleSubmit={onSave}
            isSaving={saving}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>                
                <ReactiveForm.Dropzone
                    accept=".pdf"
                    columnDef={basicColumnDef('PDF File', 'pdfFile')}
                    multiple={false}
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}

function OverwritePdfUploadModal() {
    const isOpen = useOverwritePdfUploadStore(state => state.modalOpen);
    const close = useOverwritePdfUploadStore(state => state.closeModal);
    return (
        <Modal className="modal-md" isOpen={isOpen} toggle={close}>
            <AppModalHeader toggle={close}>Overwrite Pdf</AppModalHeader>
            <ModalBody>
                <OverwritePdfUploadForm />
            </ModalBody>
        </Modal>
    );
}

export default function OverwritePdfUpload({ className = 'button btn btn-secondary' }) {
    const revision = useAgreementsStore(state => state.agreement?.revision);
    const open = useOverwritePdfUploadStore(state => state.openModal);

    if (!revision) return null;

    return (
        <RenderIfHasWritePermission permission={permissions.OVERWRITE_PDF}>
            <OverwritePdfUploadModal />
            <DropdownItem
                className={`${className}`}
                onClick={open}
            >
                Overwrite Pdf
            </DropdownItem>        
        </RenderIfHasWritePermission>
    );
}