import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import AgreementNotificationForm from '../Forms/AgreementNotificationForm';
import { useAgreementNotificationStore } from '../Provider/useAgreementNotificationStore';

export default function AgreementNotificationModal() {
    const isOpen = useAgreementNotificationStore(state => state.modalOpen);
    const selectedNotification = useAgreementNotificationStore(state => state.selected);
    const close = useAgreementNotificationStore(state => state.closeModal);

    return (
        <Modal className="modal-xl" isOpen={isOpen} toggle={close}>
            <AppModalHeader toggle={close}>
                {selectedNotification ? `Edit ${selectedNotification.agreementNotificationName}` : 'Add Notification'}
            </AppModalHeader>
            <ModalBody>
                <AgreementNotificationForm />
            </ModalBody>
        </Modal>
    );
}
