import React from 'react';
import { Badge } from 'reactstrap';
import { niceColor } from '../../../reducers/util';

export default ({ toggle, show, color, icon, label, badgeColor }) => {
	const baseClassNames = 'hover-pointer mr-2 mb-2 one-point-one-rem';

	const badgeStyle = {};
	if (badgeColor && badgeColor[0].indexOf('#') === 0 && show) {
		badgeStyle.backgroundColor = badgeColor[0];
		badgeStyle.color = textColorForBgColor(badgeColor[0]);
	}

	return (
		<span>
			<Badge
				onClick={toggle}
				className={
					show
						? badgeColor
							? badgeColor[0].indexOf('#') === 0
								? `${baseClassNames} background-hover`
								: `${baseClassNames} bg-${badgeColor[0]}`
							: `${baseClassNames} background-hover background-color-${color}`
						: `${baseClassNames} list-group-item-secondary`
				}
				style={badgeStyle}
			>
				{icon && <i className={`fa ${icon} mr-3`} />}
				{show ? label : <s>{label}</s>}
			</Badge>
		</span>
	);
};

function textColorForBgColor(bgHex) {
	if (bgHex) return bgHex ? niceColor(bgHex) : '#ffffff';
}
