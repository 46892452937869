import { ENDPOINTS } from '../constants';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { getAxiosGetRequest } from '../apiHelpers';
import axios from 'axios';
import { isLoading } from '../../Loading/isLoading';

const type = ENDPOINTS.API.PAGE;

export const apiPage = (page, id, filter, url) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    isLoading(dispatch, type, true, {});

    return axios(getAxiosGetRequest(['page', page], { id, url, ...filter }))
        .then(({ data }) => {
            dispatch({ type, data });
            isLoading(dispatch, type, false, {});
            return data;
        })
        .catch(err => {
            isError(dispatch, type, true, err.message, {});

            if (getState().user.userId > -1) {
                toastr.error(type, `Response ${err.message}`);
            }

            return false;
        });
};

export const apiCalendarPage = (startDate, endDate) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }

    isLoading(dispatch, type, true, {});

    return axios(getAxiosGetRequest(['page', 'calendar'], { startDate, endDate }))
        .then(({ data }) => {
            dispatch({ type, data });
            isLoading(dispatch, type, false, {});
            return data;
        })
        .catch(err => {
            isError(dispatch, type, true, err.message, {});

            if (getState().user.userId > -1) {
                toastr.error(type, `Response ${err.message}`);
            }

            return false;
        });
};
