import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { getAxiosPostRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

export const apiNotificationsPost = notificationIdList => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['notification', 'update'], undefined, notificationIdList))
        .then(({ data }) => {
            toastr.success('Notifications Update', 'Success');
            dispatch({ type: ENDPOINTS.API.USER_NOTIFICATION_UPDATE, data });
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Notifications Update', `Response ${err.message}`);
            }

            throw err;
        });
};

export const apiClearAllNotificationsPost = () => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve, myReject) {
            myReject({ status: 404 });
        });
    }

    return axios(getAxiosPostRequest(['notification', 'clear-all']))
        .then(({ data }) => {
            toastr.success('Clear All Notifications', 'Success');
            dispatch({ type: ENDPOINTS.API.USER_NOTIFICATION_UPDATE, data });
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Clear All Notifications', `Response ${err.message}`);
            }

            throw err;
        });
};
