import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import ivsCodeDef from '../ivsCodeDef';
import { useIvsCodesStore } from '../Provider/IvsCodesProvider';

export default function IvsCodesCSV({ className }) {
    const [mappedData, setMappedData] = useState([]);
    const ivsCodes = useIvsCodesStore(state => state.data);

    return (
        <CSVLink
            className={'button btn btn-secondary ' + className}
            data={mappedData}
            filename="ivs_codes.csv"
            headers={[ivsCodeDef.ivsCode.title, ivsCodeDef.modelDescription.title, ivsCodeDef.devCode.title]}
            onClick={() => {
                setMappedData(ivsCodes.map(ivsCode => [ivsCode.ivsCode, ivsCode.modelDescription, ivsCode.devCode]));
            }}
        >
            Download IVS Codes
        </CSVLink>
    );
}
