import React from 'react';
import PropTypes from 'prop-types';
import MapArray from '../../common/MapArray';
import ActionButton from './ActionButton';
import DashboardAdminCard from '../../Agreement/Dashboard/DashboardAdminCard';

const ActionButtons = ({ title, actions, children }) => {
    //TODO: is loading if company is loading or the person is loading
    return (
        <DashboardAdminCard title={title || 'Actions'}>
            {children}
            <MapArray from={actions}>
                <ActionButton />
            </MapArray>
        </DashboardAdminCard>
    );
};

ActionButtons.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape(ActionButton.defaultProps))
};

ActionButtons.defaultProps = {
    actions: []
};

export default ActionButtons;
