import React from 'react';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import CalendarFilterOption from './CalendarFilterOption';
import { setCalendarFilter } from '../../../actions/settings';

const CalendarFilter = ({ label, options, setCalendarFilter, calendarFilters }) => {
	const sortedFilters = options.filter(x => x.order !== undefined).sort((a, b) => a.order > b.order);
	return (
		<div>
			<ListGroup>
				<ListGroupItem active tag="h5">
					Filter - {label}
				</ListGroupItem>
				<ListGroupItem>
					{sortedFilters.map(f => (
						<CalendarFilterOption
							key={f.label}
							toggle={() => setCalendarFilter(f)}
							show={!calendarFilters[f.label]}
							color={f.color}
							icon={f.icon}
							label={f.label}
							badgeColor={f.badgeColor}
						/>
					))}
				</ListGroupItem>
			</ListGroup>
		</div>
	);
};

const mapStateToProps = state => ({
	calendarFilters: state.settings.calendarFilters
});

const mapDispatchToProps = dispatch => ({
	setCalendarFilter: filter => dispatch(setCalendarFilter(filter))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CalendarFilter);
