import React, { useMemo } from 'react';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import { useLocation } from 'react-router-dom';
import { filterAgreementsFilterSearch } from './AgreementsFilter';

const FORM_NAME = FORM_NAMES.AGREEMENTS_FILTER_IVS;

export default function IvsSearchForm({ close, save }) {
    const ivsCodes = useIvsCodesStore(state => state.data);
    const { search } = useLocation();
    const initialValues = filterAgreementsFilterSearch(search);
    const handleSubmit = form => {
        save(form);
        close();
    };

    const formIvsCodes = useMemo(() => {
        return ivsCodes.map(m => ({
            key: m.ivsCodeId,
            value: `${m.ivsCode} ${m.modelDescription}`
        }));
    }, [ivsCodes]);

    return (
        <ReactiveForm
            formName={FORM_NAME}
            handleSubmit={handleSubmit}
            hideCancelButton={true}
            initialValues={initialValues}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Series', 'termSeries') }} useBigTitle={true} />
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Description', 'termDescription') }}
                    useBigTitle={true}
                />
                <ReactiveForm.Choicelist
                    columnDef={{ ...basicColumnDef('Ivs Code', 'ivsCodeId') }}
                    isClearable={true}
                    options={formIvsCodes}
                    useBigTitle={true}
                />
                <ReactiveForm.Choicelist
                    columnDef={{ ...basicColumnDef('Discount Type', 'termDiscountType') }}
                    isClearable={true}
                    options={[
                        { key: '>', value: 'Greater Than' },
                        { key: '<', value: 'Less Than' }
                    ]}
                    useBigTitle={true}
                />
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Discount', 'termDiscount') }} useBigTitle={true} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
