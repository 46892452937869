import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import Layout3Columns, { Column1, Column2, Column3 } from '../../Dashboard/Components/Layout3Columns';
import CompanyTabs from '../../Company/Tabs/CompanyTabs';
import CompanyVisitActionsTable from '../../VisitActions/Tables/CompanyVisitActionsTable';
import CompanyAgenda from '../../Company/Components/CompanyAgenda';
import CompanyCommunicationsTable from '../../Communication/Tables/CompanyCommunicationsTable';
import CompanyPrimaryPeople from '../../Person/Components/CompanyPrimaryPeople';
import CompanyDetails from '../../Company/Components/CompanyDetails';
import CompanyAffiliates from '../../Company/Components/CompanyAffiliates';
import CompanyPrimaryAddressesDetails from '../../Address/Components/CompanyPrimaryAddressesDetails';
import CompanyActions from '../../Company/Components/CompanyActions';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import CompanyPageTitleContent from '../../Company/Components/CompanyPageTitleContent';
import CompanyTitle from '../../Company/Components/CompanyTitle';
import CompanyMeetingsTable from '../../Communication/Tables/CompanyMeetingsTable';
import PageLoadingSpinner from './PageLoadingSpinner';
import FileList from '../FileList/index';

export default ({
    location: { pathname },
    match: {
        params: { companyId: strCompanyId, companyName }
    },
    history
}) => {
    const companyId = parseInt(strCompanyId, 10);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(apiPage('company', companyId, undefined, pathname));
    }, [companyId, dispatch, pathname]);

    return (
        <CompanyProvider companyId={companyId}>
            <PageSection
                onBackClicked={history.goBack}
                title={<CompanyTitle />}
                titleContent={<CompanyPageTitleContent />}
                titleExtra={<PageLoadingSpinner />}
            >
                <Layout3Columns>
                    <Column1>
                        <CompanyDetails />
                        <CompanyAffiliates />
                        <CompanyPrimaryPeople />
                        <CompanyPrimaryAddressesDetails />
                        <FileList />
                    </Column1>
                    <Column2>
                        <CompanyTabs />
                        <CompanyMeetingsTable />
                        <CompanyCommunicationsTable />
                        <CompanyVisitActionsTable />
                    </Column2>
                    <Column3>
                        <div className="d-grid" style={{ gap: '1rem' }}>
                            <CompanyActions />
                            <CompanyAgenda />
                        </div>
                    </Column3>
                </Layout3Columns>
            </PageSection>
        </CompanyProvider>
    );
};
