import React from 'react';
import { useUpdateNotifications } from '../Provider';
import ToggleRenderer from '../../common/BaseTable/CusomCellRenderers/ToggleRenderer';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';

const NotificationSwitchColumnRenderer = props => {
    const updateNotifications = useUpdateNotifications();

    const onToggle = (notification, value, key) => updateNotifications([{ ...notification, [key]: value }]);

    return (
        <table>
            <tbody>
                {props.column.actions.map(action => (
                    <tr key={action.field} onClick={PreventClickThrough}>
                        <td>{action.title}</td>
                        <td>
                            <ToggleRenderer
                                cellData={props.rowData[action.field]}
                                columnItem={action.field}
                                disabled={props.disabled}
                                onToggle={onToggle}
                                rowData={props.rowData}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default NotificationSwitchColumnRenderer;
