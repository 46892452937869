import React from 'react';
import { FieldLabel } from '../DisplayField/FieldLabel';
import { FieldValue } from '../DisplayField/FieldValue';
import SyncingOrErrorIndicator from '../SyncingOrErrorIndicator';
import { DisplayField } from '../DisplayField/index';

export default ({ onClick, isSyncing, isError, title, show, primary, fontawesome, value, stack }) =>
	stack ? (
		<DisplayField
			className={show ? '' : 'hoverable hover-pointer'}
			onClick={() => (show ? null : onClick())}
			title={
				<span>
					<SyncingOrErrorIndicator isSyncing={isSyncing} error={isError} />
					{title}{' '}
					<i
						className={` gradient fa d-inline ${fontawesome} ml-2 grid-js-end hoverable hover-pointer hoverable-dimmed`}
					/>
				</span>
			}
			value={value}
		/>
	) : (
		<div
			className={`d-grid  ${show ? '' : 'hoverable hover-pointer'} mt-2`}
			style={{ gridTemplateColumns: 'auto auto 20px' }}
			onClick={() => (show ? null : onClick())}
		>
			<FieldLabel className="grid-cs-1">
				<SyncingOrErrorIndicator isSyncing={isSyncing} error={isError} />
				{title}
			</FieldLabel>
			<FieldValue primary={primary} className="text-right">
				{value}
			</FieldValue>
			<i
				className={` gradient fa d-inline ${fontawesome} ml-2 grid-js-end hoverable hover-pointer hoverable-dimmed`}
			/>
		</div>
	);
