import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import AgreementTemplateForm from '../Forms/AgreementTemplateForm';
import { useAgreementTemplateStore } from '../Provider/useAgreementTemplateStore';

export default function AgreementTemplateModal() {
    const isOpen = useAgreementTemplateStore(state => state.modalOpen);
    const selectedTemplate = useAgreementTemplateStore(state => state.selected);
    const close = useAgreementTemplateStore(state => state.closeModal);

    return (
        <Modal className="modal-md" isOpen={isOpen} toggle={close}>
            <AppModalHeader toggle={close}>
                {selectedTemplate ? `Edit ${selectedTemplate.name}` : 'Add Template'}
            </AppModalHeader>
            <ModalBody>
                <AgreementTemplateForm />
            </ModalBody>
        </Modal>
    );
}
