import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';

export default (state = [], { type, data }) => {
    switch (type) {
        case ENDPOINTS.API.PAGE:
            return data?.libraryFiles || [];
        case RESET_REDUCER:
            return [];
        default:
            return state;
    }
};
