import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import BaseTableInCard, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTableInCard';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import meeting from '../meeting';
import { CompanyMeetingsContext } from '../../Company/Provider/CompanyMeetingsProvider';
import { meetingSelected } from '../../../actions/Navigation/Meeting/meetingSelected';
import { ENDPOINTS } from '../../../actions/Api/constants';

const CompanyMeetingsTable = ({ updateMeeting }) => {
    const { meetings } = useContext(CompanyMeetingsContext);
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);
    const dispatch = useDispatch();

    const columns = [
        { ...meeting.startDate, filter: true, sortable: true },
        { ...meeting.startTime, filter: true, sortable: true },
        useChoicelistCellDefinition(meeting.activityRegarding, { width: 200 }),
        { ...meeting.assigneeId },
        useChoicelistCellDefinition(meeting.meetingMode, { width: 150 }),
        useChoicelistCellDefinition(
            meeting.status,

            { width: 150 }
        )
    ];

    const rows = meetings.filter(meeting => meeting.action === 'Meeting');

    return (
        <BaseTableInCard
            cardTitle={`Meetings (${rows.length})`}
            columns={columns}
            data={rows}
            isLoading={isLoading}
            noItemMessage="No Contacts"
            onRowClicked={({ rowData }) => dispatch(meetingSelected(rowData))}
            rowKey={meeting.id.field}
            tableSizeStyle={{ height: 'calc(100vh /5)', width: '100%' }}
        />
    );
};

export default CompanyMeetingsTable;
