import React from 'react';
import {
    basicColumnDef, choiceListColumnDef, emailColumnDef, idColumnDef, phoneColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import IconFactory from '../common/IconFactory';

export default {
    id: idColumnDef('Id', 'userId'),
    email: {
        ...emailColumnDef('Email', 'email'),
        type: 'text',
        help: {
            helpId: 'email',
            helpText: 'Users email, to update please use CRM'
        },
        filter: true,
        sortable: true
    },
    firstname: {
        ...basicColumnDef('Firstname', 'firstName'),
        type: 'text',
        help: {
            helpId: 'firstname',
            helpText: 'Users Firstname, to update please use CRM'
        }
    },
    lastname: {
        ...basicColumnDef('Lastname', 'lastName'),
        type: 'text',
        help: {
            helpId: 'lastname',
            helpText: 'Users Lastname, to update please use CRM'
        }
    },
    name: {
        ...basicColumnDef('Name', 'name'),
        type: 'text',
        help: {
            helpId: 'name',
            helpText: 'Users name, to update please use CRM'
        },
        filter: true,
        sortable: true
    },
    mobile: {
        ...phoneColumnDef('Mobile', 'mobile'),
        type: 'text',
        help: {
            helpId: 'lastname',
            helpText: 'Users Mobile, to update please use CRM'
        },
        filter: true,
        sortable: true
    },
    userType: {
        ...choiceListColumnDef('User Type', 'userType', 'userType'),
        type: 'text',
        help: {
            helpId: 'userType',
            helpText: 'User Type, to update please use CRM'
        }
    },
    lockedOut: {
        ...basicColumnDef('Locked Out', 'lockedOut'),
        type: 'text',
        cellRenderer: ({ cellData }) => (
            <IconFactory
                icon={{ className: 'text-danger', type: 'fa', id: 'fa-exclamation-triangle' }}
                isVisible={cellData}
            />
        ),
        help: {
            helpId: 'lockedOut',
            helpText: 'If user is locked out due to failed login attempts'
        },
        sortable: true
    }
};
