import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { cookieClear } from '../reducers/cookies';

export const RESET_REDUCER = 'RESET_REDUCER';

export const resetReducers = path => async dispatch => {
    try {
        dispatch({ type: RESET_REDUCER });

        cookieClear();

        if (path) {
            dispatch(push(path));
        }
        return true;
    } catch (error) {
        toastr.error('Error', `Response ${error.message}`);
        return false;
    }
};
