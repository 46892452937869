import React, { useEffect, useState } from 'react';
import { makeGetRequest } from '../../../actions/Api/apiHelpers';
import { toastr } from 'react-redux-toastr';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import FormikLabel from '../../common/FormkControls/FormikLabel';
import { useToolTipContext } from '../Components/AgreementHistoryBreadcrumbToolTip';
import TinyMCE from 'react-tinymce';
import { tinyMceDefaultConfig } from '../../common/FormkControls/FormikHtmlEditor';

const EmailToolTip = ({ notification }) => {
    return (
        <div>
            <div>
                <strong className="mr-2">Subject:</strong>
                {notification.subject}
            </div>
            <TinyMCE
                config={{
                    ...tinyMceDefaultConfig,
                    height: 400,
                    toolbar: '',
                    readonly: true
                }}
                content={notification.body}
            />
        </div>
    );
};

const NotificationSummary = ({ notification }) => {
    const to = notification.to.filter(x => x.type === 'to').map(x => x.email);
    const cc = notification.to.filter(x => x.type === 'cc').map(x => x.email);
    const { setTooltipOpen, setTooltipClosed } = useToolTipContext();
    return (
        <>
            <div style={{ gridColumn: 1 }}>{notification.name}</div>
            <div>{notification.sender}</div>
            <div
                className="ml-2 bg-info hover-pointer text-white px-3 d-flex align-items-center justify-center"
                onMouseEnter={() => {
                    setTooltipOpen(<EmailToolTip notification={notification} />);
                }}
                onMouseLeave={() => setTooltipClosed()}
            >
                <i className="d-flex fa fa-lg fa-envelope" />
            </div>

            <div
                className={`${to.length ? 'bg-info hover-pointer' : 'bg-darkgrey'} text-white strong px-3 py-1`}
                title={to.join(', ')}
            >
                TO
            </div>
            <div
                className={`${cc.length ? 'bg-info hover-pointer' : 'bg-darkgrey'} text-white strong px-3 py-1`}
                title={cc.join(', ')}
            >
                CC
            </div>
            <div
                className={`${
                    notification.attachLeasingCsv ? 'bg-info hover-pointer' : 'bg-darkgrey'
                } hover-pointer text-white px-3 d-flex align-items-center justify-center`}
                title="Attach Leasing CSV"
            >
                <i className="d-flex fa fa-lg fa-file-csv" />
            </div>
            <div
                className={`${
                    notification.attachPdf ? 'bg-info hover-pointer' : 'bg-darkgrey'
                } hover-pointer text-white px-3 d-flex align-items-center justify-center`}
                title="Attach Agreement PDF"
            >
                <i className="d-flex fa fa-lg fa-file-pdf" />
            </div>
        </>
    );
};

export default function NotificationsToBeSend({ formikProps, statusTo }) {
    const [{ isLoading, notifications }, setNotifications] = useState({ isLoading: true, notifications: [] });

    const agreementId = formikProps?.values?.agreementId;
    useEffect(() => {
        if (agreementId && statusTo) {
            makeGetRequest(['notification', 'notifications-to-be-sent'], {
                agreementId,
                statusTo
            })
                .then(response => {
                    setNotifications({
                        notifications: response.data,
                        isLoading: false
                    });
                })
                .catch(error => toastr.error('Failed to fetch notifications'));
        }
    }, [agreementId, statusTo]);

    return (
        <div
            className="mb-3 d-grid"
            style={{ gridTemplateColumns: '1fr 1fr auto auto auto auto auto auto', gap: '1px' }}
        >
            <h4 style={{ gridColumn: '1 / -1' }}>Notifications to send</h4>
            {isLoading ? (
                <ShowLoadingSpinner isLoading={true} />
            ) : notifications.length === 0 ? (
                <div>No notifications are set to be sent.</div>
            ) : (
                <>
                    <FormikLabel style={{ gridColumn: 1 }} title="Notification" />
                    <FormikLabel title="From" />
                    {notifications.map(notification => (
                        <NotificationSummary key={notification.id} notification={notification} />
                    ))}
                </>
            )}
        </div>
    );
}
