import React from 'react';
import moment from 'moment';
import ColumnTypeDate from '../Table/ColumnTypeDate';
import CaptionBadge, { CaptionBadgeResources } from './CaptionBadge';
import { FormatDate } from '../FormatDate';

//export const makeFilters = filters => filters.map((filter, index) => ({ ...filter, index }));
//Choicelist can be an array of choicelist names, or a single choicelist name as a string
export const filterSpec = (label, field, choiceList, unselected) => ({
    label,
    field,
    choiceList,
    unselected,
    index: 0
});
export const idColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    mapping: (resources, values) => values[field]
});

export const basicColumnDef = (title, field) => ({
    title,
    field,
    sort: true
});
export const emailColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => (
        <a href={`mailto:${values[field]}`}>
            <span>
                <i className="fa fa-at" /> {values[field]}
            </span>
        </a>
    ),
    cellRenderer: ({ cellData }) => (
        <a href={`mailto:${cellData}`}>
            <span>
                <i className="fa fa-at" /> {cellData}
            </span>
        </a>
    )
});

export const phoneColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => (
        <a href={`tel:${values[field]}`}>
            <span>
                <i className="fa fa-phone" /> {values[field]}
            </span>
        </a>
    ),
    cellRenderer: ({ cellData }) => (
        <a href={`tel:${cellData}`}>
            <span>
                <i className="fa fa-phone" /> {cellData}
            </span>
        </a>
    )
});

export const dateColumnDef = (title, field, showSync = false) => ({
    ...basicColumnDef(title, field),
    mapping: (resources, values) => moment(values[field]).format(process.env.REACT_APP_DATE_FORMAT),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => <ColumnTypeDate values={values} field={field} showSync={showSync} />,
    cellRenderer: ({ cellData }) => <FormatDate date={cellData} />,
    type: 'date'
});

export const timeColumnDef = (title, field, showSync = false) => ({
    ...basicColumnDef(title, field),
    mapping: (_resources, values) => moment(values[field]).format(process.env.REACT_APP_TIME_FORMAT)
});

export const choiceListColumnDef = (title, field, choiceList, isMulti = false) => ({
    ...basicColumnDef(title, field),
    ...choiceListColumnSettings(choiceList, field, isMulti)
});

export const choiceListColumnSettings = (choiceList, defaultField, isMulti = false) => ({
    right: true,
    choiceList: choiceList,
    type: 'captionBadge',
    mapToDisplayedValue: (resources, values, field) => (
        <CaptionBadge choiceList={choiceList} caption={values[field || defaultField]} resources={resources} />
    ),
    cellRenderer: ({ cellData }) => (
        <>
            {!isMulti ? (
                <CaptionBadgeResources caption={cellData} choiceList={choiceList} />
            ) : (
                <div className="w-100">
                    {cellData
                        ?.split(',')
                        .filter(f => f)
                        .map(m => (
                            <div key={m}>
                                <CaptionBadgeResources caption={m} choiceList={choiceList} />
                            </div>
                        ))}
                </div>
            )}
        </>
    )
});

export const mapColumnDefToChoiceList = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title,
              emptyOption: columnDef.emptyOption,
              choiceList: columnDef.choiceList,
              help: columnDef.help
          }
        : undefined;

export const mapColumnDefToCheckbox = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title
          }
        : undefined;

export const mapColumnDefToDate = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title
          }
        : undefined;

export const mapColumnDefToText = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title,
              type: columnDef.type,
              help: columnDef.help
          }
        : undefined;
