import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import PersonForm from '../Forms/PersonForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import { closeModal, FORM_PATHS } from '../../../actions/modals';

class PersonEditModal extends React.Component {
    handleSubmit = form => {};

    render() {
        const { modals, setModalClosed } = this.props;

        if (!modals.PersonEditModal.isOpen) {
            return '';
        }

        return (
            <Modal isOpen toggle={() => setModalClosed()} className="modal-lg">
                <AppModalHeader toggle={() => setModalClosed()}>
                    {modals.PersonEditModal.data.personId < 0 ? 'Create Person' : 'Edit Person'}
                </AppModalHeader>
                <ModalBody>
                    <PersonForm
                        cancel={() => setModalClosed()}
                        companyId={modals.PersonEditModal.data.companyId}
                        personId={modals.PersonEditModal.data.personId}
                    />
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.EditPerson.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonEditModal);
