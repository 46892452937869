import React from 'react';
import { PageSectionTitle } from './PageSectionTitle';
import { Card, CardBody } from 'reactstrap';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

class PageSection extends React.Component {
    render = () => {
        const {
            title,
            children,
            onBackClicked,
            titleContent,
            titleExtra,
            titleButtonArea,
            warning,
            loading
        } = this.props;
        return (
            <div className="px-3 pb-5">
                {title || titleContent ? (
                    <Card className="mt-4 page-title-background pt-2 pb-2 mb-3 shadow-sm simple-card">
                        <CardBody className="py-0">
                            <RedirectNotLoggedIn />
                            <div className="d-grid" style={{ gridTemplateColumns: 'auto auto 1fr', gap: '0.5rem' }}>
                                <button
                                    className="navbar-btn align-text-top"
                                    onClick={() => onBackClicked()}
                                    style={{ visibility: onBackClicked ? 'visible' : 'hidden' }}
                                    type="button"
                                >
                                    <i className="fa fa-arrow-left" />
                                </button>
                                <div className="align-items-center d-flex">
                                    <PageSectionTitle title={title} loading={loading} warning={warning} />
                                    {titleExtra}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                                    {titleButtonArea}
                                </div>
                                {titleContent}
                            </div>
                        </CardBody>
                    </Card>
                ) : null}
                <div className={title || titleContent ? 'py-0' : 'mt-4 py-0'}>{children}</div>
            </div>
        );
    };
}

export default PageSection;
