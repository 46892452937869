import create from 'zustand';
import { storeWithModal, updateDataFromFetch } from '../../Agreement/Provider/storeStates';
import {
    apiAddendumCreate,
    apiAddendumStatusChange,
    apiAddendumUpdate
} from '../../../actions/Api/Addendum/apiAddendum';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { useSafeDispatchPush } from '../../../selectors/Api/useSafeDispatchPush';
import { useAgreementsStore } from '../../Agreement/Provider/useAgreementsStore';

export const addendumPermissions = {
    addAgreementsToAddendum: 'AddAgreementsToAddendum'
};

export function getAddendumPermission(addendumPermission, addendum) {
    return (
        (addendum?.workflow || []).find(x => x.permission === addendumPermission) || {
            permission: addendumPermission,
            isEnabled: false,
            isVisible: false
        }
    );
}

const rowKey = 'addendumId';
export const useAddendaStore = create(set => ({
    ...storeWithModal(set, rowKey),
    updateDataFromFetch: (fetchedRows, clearState, postedRows = []) => {
        set(state => {
            const newState = updateDataFromFetch(rowKey, state, fetchedRows, clearState, postedRows);
            return {
                ...newState,
                addendum: newState.data.length === 1 ? newState.data[0] : {}
            };
        });
    },
    openModal: selected => {
        set(() => ({ selected, modalOpen: true, addendum: {} }));
    }
}));

export function useUpdateAddenda() {
    const updateFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setSaving = useAddendaStore(state => state.setSaving);
    const setError = useAddendaStore(state => state.setError);
    const push = useSafeDispatchPush(addendum => ['addendum', addendum?.addendumId]);

    return addenda => {
        setSaving();
        return (addenda.addendumId ? apiAddendumUpdate(addenda) : apiAddendumCreate(addenda))
            .then(response => {
                updateFromFetch(response.addendum || []);
                updateAuditsFromFetch(response.addendumAudits || []);

                if (!addenda.addendumId) push((response.addendum || [])[0]);

                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useUpdateAddendaStatus() {
    const updateFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const setSaving = useAddendaStore(state => state.setSaving);
    const setError = useAddendaStore(state => state.setError);

    return (addendum, statusFrom, statusTo, cancel) => {
        setSaving();
        return apiAddendumStatusChange(addendum.addendumId, statusFrom, statusTo, addendum)
            .then(response => {
                updateFromFetch(response.addendum || []);
                updateAuditsFromFetch(response.addendumAudits || []);
                updateAgreementsFromFetch(response.agreements || [], true);
                cancel && cancel();
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
