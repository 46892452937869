import { useSelector } from 'react-redux';

//MATCHES ENUM PermissionsEnum in API
export const permissions = {
    USER_ADMIN: {
        id: 1,
        description: 'Can manage users and roles',
        group: 'Admin',
        hasCreatePermission: false,
        hasReadPermission: true,
        hasWritePermission: true
    },
    AGREENENTS_ADMIN_PAGE: {
        id: 15,
        description: 'Agreements Admin Page',
        group: 'Agreements Admin',
        hasCreatePermission: false,
        hasReadPermission: true,
        hasWritePermission: false
    },
    CLEAR_CACHE: {
        id: 16,
        description: 'Clear Cache',
        group: 'Agreements Admin',
        hasCreatePermission: false,
        hasReadPermission: true,
        hasWritePermission: false
    },
    PURGE_DELETED_AGREEMENTS: {
        id: 17,
        description: 'Purge Deleted Agreements',
        group: 'Agreements Admin',
        hasCreatePermission: false,
        hasReadPermission: false,
        hasWritePermission: true
    },
    OVERWRITE_PDF: {
        id: 18,
        description: 'Overwrite Pdf',
        group: 'Agreement Workflow',
        hasCreatePermission: false,
        hasReadPermission: true,
        hasWritePermission: true
    },
    TERMS_MATRIX_ADMIN: {
        id: 7,
        description: 'Terms Matrix',
        group: 'Agreements Admin',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    MASS_ADDENDUM: {
        id: 11,
        description: 'Mass Addendum',
        group: 'Agreements Admin',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    TERMS_MATRIX: {
        id: 12,
        description: 'Terms Matrix',
        group: 'Agreements Admin',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    EXTERNAL_DATA: {
        id: 10,
        description: 'Cap Codes and IVS Codes',
        group: 'Agreements Admin',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    LEA: {
        id: 2,
        description: 'Access to leasing sales channel',
        group: 'Sales Channel',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    NEU: {
        id: 3,
        description: 'Access to National end user sales channel',
        group: 'Sales Channel',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    PSO: {
        id: 4,
        description: 'Access to Public Sector Sales Channel',
        group: 'Sales Channel',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    REN: {
        id: 5,
        description: 'Access to Rental Sales Channel',
        group: 'Sales Channel',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    SPE: {
        id: 6,
        description: 'Access to Specialist Sales',
        group: 'Sales Channel',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    LEASING_PORTAL_USERS: {
        id: 8,
        description: 'Users in leasing portal',
        group: 'External Apps',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    CUSTOMER_PORTAL_USERS: {
        id: 9,
        description: 'Users in customer portal',
        group: 'External Apps',
        hasCreatePermission: true,
        hasReadPermission: true,
        hasWritePermission: true
    },
    APPROVE_BUTTON: {
        id: 13,
        description: 'Approve Agreements',
        group: 'Agreement Workflow',
        hasCreatePermission: false,
        hasReadPermission: false,
        hasWritePermission: true,
        workflowPermission: 'ApproveButton'
    },
    APPROVE_ADDENDUM_BUTTON: {
        id: 14,
        description: 'Approve Addendum',
        group: 'Agreement Workflow',
        hasCreatePermission: false,
        hasReadPermission: false,
        hasWritePermission: true,
        workflowPermission: 'ApproveAddendumButton'
    }
};

export function useUsersPermission(permission) {
    const { permissions = [] } = useSelector(state => state.user);
    return (
        permissions.find(x => x.permissionId === permission.id) || {
            permissionId: permission.id,
            canRead: false,
            canWrite: false,
            canCreate: false
        }
    );
}

export function useWorkflowPermittedMap() {
    const { permissions: userPermissions = [] } = useSelector(state => state.user);

    const workflowPermissions = Object.entries(permissions).reduce((acc, [_, permission]) => {
        if (!permission.workflowPermission) return acc;
        acc.push([
            permission.workflowPermission,
            (userPermissions.find(x => x.permissionId === permission.id) || { canWrite: false }).canWrite
        ]);
        return acc;
    }, []);
    return new Map(workflowPermissions);
}

export function useCanRead(permission) {
    return useUsersPermission(permission).canRead;
}
export function useCanWrite(permission) {
    return useUsersPermission(permission).canWrite;
}

export function RenderIfHasReadPermission({ permission, children }) {
    const permissionsArray = Array.isArray(permission) ? permission : [permission];
    const { permissions = [] } = useSelector(state => state.user);

    const canRead = !permissionsArray.some(
        permission => !permissions.find(x => x.permissionId === permission.id)?.canRead
    );

    return canRead ? children : null;
}

export function RenderIfHasWritePermission({ permission, children }) {
    const permissionsArray = Array.isArray(permission) ? permission : [permission];
    const { permissions = [] } = useSelector(state => state.user);

    const canWrite = !permissionsArray.some(
        permission => !permissions.find(x => x.permissionId === permission.id)?.canWrite
    );

    return canWrite ? children : null;
}
