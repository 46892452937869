import React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import useToggleOpen from './Hooks/useToggleOpen';

export default function WarningPopover({
    id = 'popover-warning',
    headerText,
    bodyText,
    visible = true,
    className = 'mr-3',
    color = 'danger',
    style = {}
}) {
    const { isOpen, setIsOpen, toggle } = useToggleOpen(false);
    return !bodyText || !visible ? null : (
        <>
            <i
                className={`fa fa-exclamation-triangle ${className} text-danger`}
                id={id}
                onMouseEnter={e => {
                    e.stopPropagation();
                    setIsOpen(true);
                }}
                onMouseLeave={e => {
                    e.stopPropagation();
                    setIsOpen(false);
                }}
                style={{ textShadow: '0px 0px 9px #FFFFFF', ...style }}
            />

            <Popover isOpen={isOpen} key="popover" target={id} toggle={toggle}>
                <PopoverHeader>{headerText}</PopoverHeader>
                <PopoverBody>
                    <div className="show-white-lines">{bodyText}</div>
                </PopoverBody>
            </Popover>
        </>
    );
}
