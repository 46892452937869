import React, { useContext } from 'react';
import { CompanyContext } from '../Provider/CompanyProvider';
import { DashboardFieldColumnDef } from '../../Dashboard/Components/DashboardField';
import SimpleCard from '../../common/Card/SimpleCard';
import companyDef from '../companyDef';

export default () => {
	const { company = {} } = useContext(CompanyContext);
	const isLoading = company.loading;

	return (
		<SimpleCard title="Company Information" isLoading={isLoading}>
			<DashboardFieldColumnDef item={company} columnDef={companyDef.name} />
			<DashboardFieldColumnDef item={company} columnDef={companyDef.webSite} />
			<DashboardFieldColumnDef item={company} columnDef={companyDef.businessPhone} />
			<DashboardFieldColumnDef item={company} columnDef={companyDef.registrationNumber} />
			<DashboardFieldColumnDef item={company} columnDef={companyDef.lastMeetingDate} />
		</SimpleCard>
	);
};
