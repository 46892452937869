import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import * as yup from 'yup';
import moment from 'moment';
import PageSection from '../../common/PageSection/';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { apiMeetingEmail } from '../../../actions/Api/Meeting/apiMeetingEmail';
import { getChoiceListValue } from '../../common/LabelText';
import { selectMeetingFromRouteProps } from '../../../reducers/Api/Meeting/selectors';

const schema = yup.object().shape({
    from: yup
        .string()
        .required('From address cannot be empty.')
        .email('From address must be a single, valid email address.'),
    to: yup.string().test({
        test: val => {
            if (!val) return false;

            const emails = val.split(',').map(x => x.trim());
            const emailValidator = yup
                .string()
                .required()
                .email();

            return Promise.all(emails.map(e => emailValidator.isValid(e))).then(arr => arr.every(x => x));
        },
        message: 'To addresses must be one or more valid email addresses, separated by commas.'
    }),
    cc: yup.string().test({
        test: val => {
            if (!val || val === '') return true;

            const emails = val.split(',').map(x => x.trim());
            const emailValidator = yup
                .string()
                .required()
                .email();

            return Promise.all(emails.map(e => emailValidator.isValid(e))).then(arr => arr.every(x => x));
        },
        message: 'CC addresses must be one or more valid email addresses, separated by commas, or empty.'
    }),
    bcc: yup.string().test({
        test: val => {
            if (!val || val === '') return true;

            const emails = val.split(',').map(x => x.trim());
            const emailValidator = yup
                .string()
                .required()
                .email();

            return Promise.all(emails.map(e => emailValidator.isValid(e))).then(arr => arr.every(x => x));
        },
        message: 'BCC addresses must be one or more valid email addresses, separated by commas, or empty.'
    }),
    subject: yup.string().required('Subject cannot be empty.'),
    body: yup.string().required('Body cannot be empty.')
});

class SendVisitReportEmailPage extends React.Component {
    initialValues = null;

    constructor(props) {
        super(props);

        this.state = {
            saving: false
        };
    }

    setSaving(saving) {
        this.setState({ saving });
    }

    handleSubmit(email) {
        const communicationId = parseInt(this.props.match.params.meetingId, 10);
        this.setSaving(true);
        this.props.send(communicationId, email, () => {
            this.setSaving(false);
            toastr.success('MAIL SENT SUCCESSFULLY', `Sent to ${email.to}`);
            this.props.history.goBack();
        }, () => this.setSaving(false));
    }

    getAction = action => getChoiceListValue(this.props.resources, 'visitActionActions', action);

    getShortAr = ar => {
        const {
            resources: {
                choiceList: { communicationActivityRegarding }
            }
        } = this.props;
        const kvPair = communicationActivityRegarding.find(x => x.key === ar);
        return kvPair ? kvPair.value.replace('Visit Action - ', '') : '';
    };

    getEmailBody = (template, communication, person) => {
        const {
            user,
            emailSettings: { includeNotesInEmailComms },
            visitActions
        } = this.props;
        const meetingId = parseInt(this.props.match.params.meetingId, 10);

        let meetingActions = visitActions
            .filter(x => x.meetingId === meetingId)
            .map(va => `<li>${this.getShortAr(va.activityRegarding)} - ${va.details}</li>`)
            .join(' ');

        if (includeNotesInEmailComms.includes(communication.meetingId) && communication.notes) {
            meetingActions += `<br><p>${communication.notes.split('\n').join('<br />')}</p>`;
        }

        return template
            .replace(':contactFirstName', _.get(person, ['firstName'], ''))
            .replace(':meetingActions', meetingActions.length ? `<b>Actions</b><br><ul>${meetingActions}</ul>` : '')
            .replace(':userName', user.descriptor)
            .replace(':userFax', user.fax)
            .replace(':userMobile', user.mobile)
            .replace(':jobTitle', user.jobTitle)
            .replace(':userEmail', user.email);
    };

    getInitialValues() {
        if (this.initialValues) return this.initialValues;

        const {
            meeting,
            person,
            resources: { emailTemplate },
            user
        } = this.props;

        let subject = '';
        let innerTemplate = '';

        const date = moment(meeting.startDate).format('DD/MM/YY');
        const time = moment(meeting.startDate).format('h:mm A');

        if (emailTemplate.length > 0) {
            subject = emailTemplate[0].subject.replace(':meetingTime', `${date} at ${time}`);
            innerTemplate = emailTemplate[0].innerTemplate;
        }

        this.initialValues = {
            from: user.email,
            to: _.get(person, ['businessEmail'], ''),
            cc: user.userName,
            bcc: '',
            subject,
            body: this.getEmailBody(innerTemplate, meeting, person)
        };
        return this.initialValues;
    }

    render() {
        const initialValues = this.getInitialValues();
        return (
            <Container>
                <PageSection onBackClicked={this.props.history.goBack} title="Send Visit Report Summary Email">
                    <ReactiveForm
                        handleSubmit={this.handleSubmit.bind(this)}
                        hideCancelButton={true}
                        initialValues={initialValues}
                        isSaving={this.state.saving}
                        saveButtonContent="Send"
                        validationSchema={schema}
                    >
                        <ReactiveForm.Section>
                            <ReactiveForm.Text columnDef={basicColumnDef('From', 'from')} />
                            <ReactiveForm.Text columnDef={basicColumnDef('To', 'to')} />
                            <ReactiveForm.Text columnDef={basicColumnDef('CC', 'cc')} />
                            <ReactiveForm.Text columnDef={basicColumnDef('BCC', 'bcc')} />
                            <ReactiveForm.Text columnDef={basicColumnDef('Subject', 'subject')} />
                            <ReactiveForm.HtmlEditor columnDef={basicColumnDef('Body', 'body')} />
                        </ReactiveForm.Section>
                    </ReactiveForm>
                </PageSection>
            </Container>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.resources,
            selectMeetingFromRouteProps,
            state => state.people,
            state => state.user,
            state => state.emailSettings,
            state => state.visitActions
        ],
        (resources, meeting, people, user, emailSettings, visitActions) => {
            const personId = meeting.personId;
            const person = people.find(person => person.personId === personId);
            return {
                resources,
                meeting,
                person,
                user,
                emailSettings,
                visitActions
            };
        }
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    send: (meetingId, email, onSuccess, onFail) => dispatch(apiMeetingEmail(meetingId, email, onSuccess, onFail))
});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withRouter(SendVisitReportEmailPage));
