import React from 'react';
import moment from 'moment';

import CalendarEvent from './CalendarEvent';
import { eventStyleGetter, mapVisitActionToCalendarEvent } from '../../../reducers/Api/VisitActions/VisitAction';

const AgendaDayVisitAction = ({ visitAction, editVisitAction, choiceList }) => {
    const event = mapVisitActionToCalendarEvent(visitAction, choiceList);
    event.prepend = `Due By: ${moment(visitAction.end).format('H:mm')}`;
    event.companyName = event.visitAction.companyDescriptor;
    event.type = 'visitaction';

    return (
        <div
            className={`w-100 mt-1 p-2 hover-pointer background-hover background-color-${event.color}`}
            key={visitAction.communicationId}
            onClick={() => editVisitAction(event.visitAction)}
            role="presentation"
            style={eventStyleGetter(event).style}
        >
            <CalendarEvent event={event} />
        </div>
    );
};

export default AgendaDayVisitAction;
