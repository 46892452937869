import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import CreateLeadForm from '../Forms/CreateLeadForm';
import { closeModal, FORM_PATHS } from '../../../actions/modals';

class CreateLeadModal extends React.Component {
    render() {
        const { modals, setModalClosed } = this.props;

        if (!modals.CreateLeadModal.isOpen) {
            return '';
        }

        return (
            <Modal className="modal-lg" isOpen toggle={() => setModalClosed()}>
                <AppModalHeader toggle={() => setModalClosed()}>
                    {`${modals.CreateLeadModal.data.leadId < 0 ? 'Create' : 'Edit'} Request`}
                </AppModalHeader>
                <ModalBody>
                    <CreateLeadForm cancel={() => setModalClosed()} leadId={modals.CreateLeadModal.data.leadId} />
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

const mapDispatchToProps = dispatch => ({
    setModalClosed: () => dispatch(closeModal(FORM_PATHS.CreateLead.modalName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateLeadModal);
