import React from 'react';
import DashboardMetricValue from './DashboardMetricValue';

export default function DashboardValuesContainer({ numberOfCols, metrics, title }) {
    const numberOfColumnsInGrid = numberOfCols ? numberOfCols : metrics.length === 1 ? 1 : metrics.length < 5 ? 2 : 4;
    return (
        <div
            className="d-grid flex-grow-1"
            style={{
                gridTemplateColumns: `repeat(${numberOfColumnsInGrid}, 1fr)`,
                gap: '1px'
            }}
        >
            {title ? <div style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>{title}</div> : null}
            {metrics.map((metric, index) => (
                <DashboardMetricValue
                    column={index % numberOfColumnsInGrid}
                    fontSize={numberOfColumnsInGrid === 1 ? '10rem' : numberOfColumnsInGrid === 2 ? '5rem' : '4rem'}
                    fontSize2={numberOfColumnsInGrid === 1 ? '3rem' : numberOfColumnsInGrid === 2 ? '2rem' : '1.2rem'}
                    href={metric.href}
                    key={`${metric.property}_${metric.value}`}
                    last={index === metrics.length - 1}
                    styleType={metric.style}
                    title={metric.value}
                    total={metric.total}
                    value={metric.count}
                />
            ))}
        </div>
    );
}
