import React from 'react';
import { connect } from 'react-redux';
import MapArray from '../../common/MapArray';
import AgendaKeyOption from './AgendaKeyOption';
import { calendarOptions } from '../../../reducers/calendarOptions';
import { setShowOnAgenda } from '../../../actions/settings';

const calendarKeyOptions = Object.keys(calendarOptions)
    .map(calendarKey => ({ calendarKey, ...calendarOptions[calendarKey] }))
    .filter(({ calendarKey }) => calendarKey !== 'default')
    .sort((a, b) => a.calendarKey.order < b.calendarKey.order);

const AgendaKey = ({ resources, filters, filterData, setShowOnAgenda }) => {
    //if (!filters) {
        return (
            <div>
                <div>
                    <b>Filter:</b>
                </div>

                <MapArray from={calendarKeyOptions}>
                    <AgendaKeyOption />
                </MapArray>
            </div>
        );
    //}
/*
    return filters
        .sort((a, b) => a.order > b.order)
        .map(filter => {
            let options = [];

            if (filter.choiceList) {
                const choices = _.get(resources, `choiceList.${filter.choiceList}`, []);
                options = choices.map(option => ({
                    label: option.value,
                    //color: option.color, //todo color should be set here
                    value: option.key,
                    toggle: show => setShowOnAgenda(filter.label, option.key, show),
                    show: showOnAgenda(filterData, filter.label, option.key)
                }));
            } else {
                options = (filter.options || []).map(option => ({
                    order: option.order,
                    color: option.color,
                    label: option.label,
                    value: option.value,
                    values: option.values,
                    toggle: show => setShowOnAgenda(filter.label, option.value, show),
                    show: showOnAgenda(filterData, filter.label, option.value)
                }));
            }

            return (
                <div key={filter.label} className="mb-3">
                    <div className="mb-1">
                        <b>Agenda Filter - {filter.label}</b>
                    </div>
                    {options.map(o => (
                        <KeyOption key={o.value || o.values.join()} {...o} />
                    ))}
                </div>
            );
        });*/
};

/*
const KeyOption = ({ label, value, show, toggle, icon, color }) => (
    <Badge
        onClick={() => toggle(!show)}
        className={
            show
                ? `ml-2 hover-pointer background-hover background-color-${color}`
                : 'ml-2 hover-pointer list-group-item-secondary'
        }
    >
        {icon && <i className={`fa ${icon} mr-3`} />}
        {!show ? <s>{label}</s> : label}
    </Badge>
);
*/

const mapStateToProps = state => ({
    resources: state.resources,
    filterData: state.settings.agendaFilters
});

const mapDispatchToProps = dispatch => ({
    setShowOnAgenda: (filter, type, show) => dispatch(setShowOnAgenda(filter, type, show))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgendaKey);
