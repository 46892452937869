import * as yup from 'yup';

const validation = yup.object().shape({
    activityRegarding: yup.string().required('Activity Regarding is required'),
    location: yup.string().required('Location is required'),
    meetingMode: yup.string().required('Meeting mode is required'),
    subject: yup.string().required('Subject is required'),
    assigneeId: yup.string().required('Assignee is required'),
    startDate: yup.date().required('Due Date/Time is required'),
    endDate: yup
        .date()
        .when(
            'startDate',
            (startDate, schema) =>
                startDate && schema.min(startDate, 'MEETING END DATE/TIME must be later than MEETING START DATE/TIME ')
        )
});

export default validation;
