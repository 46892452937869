import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { apiEventPost } from '../../../actions/Api/Event/apiEventPost';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { FORM_NAMES } from '../../../actions/formNames';
import appSettings from '../../../config/appSettings';
import ReactiveForm from '../../common/ReactiveForm';

import day from '../day';
import validation from '../day.validation';

const FORM_NAME = FORM_NAMES.EDIT_DAY;

class EditDayForm extends React.Component {
    handleSubmit = values => {
        values.companyId = this.props.user.eventCompanyId;

        switch (values.eventType) {
            case 'Event':
                values.activityRegarding = 'CSMEvent';
                break;

            // case 'AdminDay':
            // 	values.activityRegarding = 'CSMAdminDay';
            // 	break;

            case 'Holiday':
                values.activityRegarding = 'CSMAnnualLeave';
                break;

            case 'Meeting':
                values.activityRegarding = 'CSMEvent';
                break;

            default:
                break;
        }

        this.props.save(values, this.props.onCancel);
    };

    emptyForm = () => {
        const {
            user: { userId, descriptor },
            eventId,
            eventType
        } = this.props;

        const startDate = moment()
            .hour(9)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        const endDate = moment()
            .hour(17)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        const type = appSettings.calendar.buttons.find(a => a.eventType === eventType);

        return {
            status: 'Pending',
            action: type.action,
            activityRegarding: type.activityRegarding,
            category: 'Appointment',
            assigneeId: userId,
            assigneeName: descriptor,
            assignee: {
                id: userId,
                descriptor: descriptor
            },
            createdBy: userId,
            eventType: eventType,
            updatedBy: userId,
            eventId,
            startDate,
            endDate,
            details: '',
            companyId: -1,
            engageEntityType: type.engageEntityType
        };
    };

    render() {
        const { eventId, events, resources, onCancel } = this.props;

        let initialValues = _.cloneDeep(events.find(x => x.eventId === eventId));

        if (!initialValues && eventId < 0) {
            initialValues = this.emptyForm();
        }

        return (
            <ReactiveForm
                cancel={onCancel}
                formName={FORM_NAME}
                handleSubmit={this.handleSubmit}
                initialValues={initialValues}
                resources={resources}
                validationSchema={validation}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Date columnDef={day.startDate} />
                    <ReactiveForm.Date columnDef={day.endDate} />
                    <ReactiveForm.Text columnDef={day.details} />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    events: state.events,
    resources: state.resources,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    save: (event, cancel) => {
        dispatch(apiEventPost(event));
        cancel();
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDayForm);
