import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DashboardValuesContainer from './DashboardValuesContainer';
import DashboardAdminCard from './DashboardAdminCard';

const options = {
    headerStatus: 'View By Status',
    status: 'All (Status)',
    statusRecent: 'Updated This Week (Status)',
    statusExpiring: 'Expiring Soon (Status)',
    statusLiveVersion: 'Live Matrix (Status)',
    statusProcessingVersion: 'Processing Matrices (Status)',
    headerSalesChannel: 'View By Sales Channel',
    statusLiveSalesChannel: 'Live (Sales Channel)',
    statusSalesChannel: 'All (Sales Channel)',
    statusRecentSalesChannel: 'Updated This Week (Sales Channel)',
    statusExpiringSalesChannel: 'Expiring Soon (Sales Channel)'
};

const combinedDashboardStatus = [
    {
        statuses: ['CHPE', 'ADCH'],
        name: 'Awaiting Checks'
    },
    {
        statuses: ['CHFA', 'ADCF'],
        name: 'Checks Failed'
    },
    {
        statuses: ['APAW', 'ADAP'],
        name: 'Awaiting Approval'
    },
    {
        statuses: ['CHA1', 'CHA2', 'CHA3', 'REQS'],
        name: 'Chasing'
    },
    {
        statuses: ['APRV', 'ADOK'],
        name: 'Approved'
    },
    {
        statuses: ['DECL', 'ADDE'],
        name: 'Declined'
    },
    {
        statuses: ['CUTE', 'BWTE'],
        name: 'Terminated',
        style: 'warning'
    }
];

export function Toggle({ options, option, selectOption }) {
    return (
        <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle caret={true} className="dropdown-active text-white">
                {options[option]}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu" right>
                {Object.entries(options).map(([key, value]) =>
                    key.startsWith('header') ? (
                        <DropdownItem className="dropdown-header" header key={key}>
                            {value}
                        </DropdownItem>
                    ) : (
                        <DropdownItem
                            active={key === option}
                            className="px-3"
                            key={key}
                            onClick={() => selectOption(key)}
                        >
                            {key === option && <i className="fa fa-check mr-2" style={{ fontSize: '9px' }} />}
                            {value.split(' (')[0]}
                        </DropdownItem>
                    )
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

const createdByString = usersVisibleToUser =>
    usersVisibleToUser.length > 0 ? `&createdBy=${usersVisibleToUser.join(',')}` : '';

export default function AgreementsDashboardCard({ data, usersVisibleToUser, viewingMatrixVersion = false }) {
    const [option, selectOption] = useState(!viewingMatrixVersion ? 'statusRecent' : 'statusProcessingVersion');
    const seenStatuses = new Set();

    const statusMetrics = data
        .filter(i => i.entity === 'agreement' && i.property === option)
        .map(m => ({ ...m, rawValue: m.rawValue.split('_')[0], versionId: m.rawValue.split('_')[1] }))
        .reduce((acc, c) => {
            if (seenStatuses.has(c.rawValue)) {
                const combined = acc.find(a => a.rawValue.includes(c.rawValue));
                combined.count += c.count;
                return acc;
            }

            const combinedStatus = combinedDashboardStatus.find(cs => cs.statuses.some(s => s === c.rawValue));
            if (combinedStatus) {
                for (const i of combinedStatus.statuses) seenStatuses.add(i);

                return [
                    ...acc,
                    {
                        ...c,
                        value: combinedStatus.name,
                        rawValue: combinedStatus.statuses.join(','),
                        style: combinedStatus.style || c.style
                    }
                ];
            }

            return [...acc, c];
        }, [])
        .map(i => ({
            ...i,
            href:
                option === 'statusRecentSalesChannel'
                    ? `../agreement?salesChannel=${i.rawValue}&recent=true${createdByString(usersVisibleToUser)}`
                    : option === 'statusExpiringSalesChannel'
                    ? `../agreement?salesChannel=${i.rawValue}&expiring=true${createdByString(usersVisibleToUser)}`
                    : option === 'statusLiveSalesChannel'
                    ? `../agreement?salesChannel=${i.rawValue}&status=LIVE${createdByString(usersVisibleToUser)}`
                    : option === 'statusSalesChannel'
                    ? `../agreement?salesChannel=${i.rawValue}${createdByString(usersVisibleToUser)}`
                    : option === 'statusRecent'
                    ? `../agreement?status=${i.rawValue}&recent=true${createdByString(usersVisibleToUser)}`
                    : option === 'statusExpiring'
                    ? `../agreement?status=${i.rawValue}&expiring=true${createdByString(usersVisibleToUser)}`
                    : option === 'statusLiveVersion' || option === 'statusProcessingVersion'
                    ? i.versionId
                        ? `../agreement?status=${i.rawValue}&masterTermVersionId=${i.versionId}${createdByString(
                              usersVisibleToUser
                          )}`
                        : undefined
                    : `../agreement?status=${i.rawValue}${createdByString(usersVisibleToUser)}`
        }));

    return (
        <>
            {!viewingMatrixVersion ? (
                <DashboardAdminCard
                    helpId="agreementsAdminAgreementsCard"
                    title="Agreements"
                    toggles={
                        !viewingMatrixVersion
                            ? [
                                  {
                                      id: 1,
                                      option,
                                      options,
                                      selectOption
                                  }
                              ]
                            : undefined
                    }
                >
                    <DashboardValuesContainer metrics={statusMetrics} />
                </DashboardAdminCard>
            ) : (
                <DashboardValuesContainer metrics={statusMetrics} />
            )}
        </>
    );
}
