import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection/index';
import UpcomingCommunications from '../../Dashboard/Components/UpcomingCommunications';
import SimpleCard from '../../common/Card/SimpleCard';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { createLead } from '../../../actions/Navigation/Lead/createLead';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import BrowserHistory from '../../BrowserHistory/Tables/BrowserHistory';
import ErrorsList from '../../Errors/ErrorsList';
import { OutstandingMeetingsTable } from '../../Communication/Tables/OutstandingMeetingsTable';
import Next7DaysAgendaCard from '../../Communication/Components/Next7DaysAgendaCard';
import CompanySearchForm from '../../CompanySummary/Forms/CompanySearchForm';
import Layout3Columns, { Column1, Column2, Column3 } from '../../Dashboard/Components/Layout3Columns';
import OutstandingVisitActionsTable from '../../VisitActions/Tables/OutstandingVisitActionsTable';
import AgreementsDashboardCard from '../../Agreement/Dashboard/AgreementsDashboardCard';
import UploadSignature from '../../Agreement/Components/UploadSignature';

const HomePage = () => {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        counts: [],
        usersVisibleToUser: [],
        loading: true
    });

    const countData = data.counts || [];

    const setHasSignature = () => {
        setData({ ...data, hasSignature: true });
    }

    useEffect(() => {
        let mounted = true;
        dispatch(apiPage('home'))
            .then(response => {
                mounted &&
                    setData(data => ({
                        loading: false,
                        counts: response.agreementAdminCounts || [],
                        usersVisibleToUser: response.usersVisibleToUser,
                        hasSignature: response.hasSignature
                    }));
            })
            .catch(err => {
                mounted && setData(data => ({ ...data, loading: false }));
                console.error(err);
            });
        return () => (mounted = false);
    }, [dispatch]);

    return (
        <PageSection key={`Home`} title={`Home`}>
            <Layout3Columns>
                <Column1>
                    <UpcomingCommunications />
                    <Next7DaysAgendaCard />
                </Column1>
                <Column2>
                    <SimpleCard title={'Search Companies'}>
                        <CompanySearchForm />
                    </SimpleCard>
                    <OutstandingMeetingsTable />
                    <OutstandingVisitActionsTable />
                    <div style={{ height: 'calc(100vh /2)', width: '100%' }}>
                        <AgreementsDashboardCard data={countData} usersVisibleToUser={data.usersVisibleToUser} />
                    </div>
                </Column2>
                <Column3>
                    <div className="d-grid" style={{ gap: '1rem' }}>
                        <ActionButtons title="Actions">
                            <ActionButton
                                bootstrapColor="info"
                                buttonText="UCN Request"
                                fontawesomeIcon="fa-address-card"
                                onClickAction={() => dispatch(createLead())}
                            />
                            <UploadSignature
                                hasSignature={data?.hasSignature}
                                setHasSignature={setHasSignature}
                            />
                        </ActionButtons>
                        <BrowserHistory />
                        <ErrorsList />
                    </div>
                </Column3>
            </Layout3Columns>
        </PageSection>
    );
};

export default HomePage;
