import create from 'zustand';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import { apiAddendumRuleUpdate } from '../../../actions/Api/Addendum/apiAddendum';
import addendumRulesDef from '../addendumRulesDef';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { useAddendaStore } from './useAddendaStore';

const rowKey = addendumRulesDef.id.field;
export const useAddendumRulesStore = create(set => storeWithModal(set, rowKey));

export function useUpdateAddendumRules() {
    const updateAddendumFromFetch = useAddendaStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const updateFromFetch = useAddendumRulesStore(state => state.updateDataFromFetch);
    const setSaving = useAddendumRulesStore(state => state.setSaving);
    const setError = useAddendumRulesStore(state => state.setError);

    return addendumRule => {
        setSaving();
        return apiAddendumRuleUpdate(addendumRule)
            .then(response => {
                updateAddendumFromFetch(response.addendum || []);
                updateFromFetch(response.addendumRules || [], false, [addendumRule]);
                updateAuditsFromFetch(response.addendumAudits || []);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
