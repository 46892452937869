import _ from 'lodash';
import moment from 'moment';

const convertDaysToMoment = days => {
    return days || days === 0 ? moment().add(days, 'days') : undefined;
};

const mapRangeUnit = (rangeUnit, value) => {
    return rangeUnit === 'days' ? convertDaysToMoment(value) : value;
};

const mapRowValue = (rangeUnit, value) => {
    return rangeUnit === 'days'
        ? value[4] === '-' || Number.isInteger(value)
            ? moment(value)
            : moment(value, process.env.REACT_APP_DATE_FORMAT)
        : value;
};

class Filter {
    constructor(filter, resources) {
        const { choiceList, rangeUnit, order, field, label, unselected = [], extraOptions = [] } = filter;
        this.choiceList = choiceList;
        this.order = order;
        this.field = field;
        this.label = label;
        this.rangeUnit = rangeUnit;

        const choiceListOptions = Array.isArray(choiceList)
            ? choiceList.reduce((acc, list) => [...acc, ..._.get(resources, `choiceList.${list}`, [])], [])
            : _.get(resources, `choiceList.${choiceList}`, []);

        this.options = [...choiceListOptions, ...extraOptions].map(({ min, max, key, value, ...rest }) => ({
            min: mapRangeUnit(rangeUnit, min),
            max: mapRangeUnit(rangeUnit, max),
            value: !unselected.includes(key),
            label: value,
            optionName: key,
            ...rest
        }));
    }
    updateOption(option, value) {
        const clone = _.clone(this);
        clone.options.find(x => x.optionName === option).value = value;
        return clone;
    }

    filterOnRange(rows) {
        const { field, options } = this;
        return rows.filter(row =>
            options.filter(option => option.value).some(option => this.rowIsInTable(row, option, field))
        );
    }

    filterRows(rows) {
        return this.filterOnRange(rows);
    }

    countRowsInTable(rows, option, field) {
        return rows.reduce((acc, row) => acc + (this.rowIsInTable(row, option, field) ? 1 : 0), 0);
    }

    rowIsInTable(row, option, field) {
        let { min, max, optionName } = option;
        optionName = typeof optionName === 'string' ? optionName.toLowerCase() : optionName;

        let rowValue = mapRowValue(this.rangeUnit, row[field]);
        rowValue = typeof rowValue === 'string' ? rowValue.toLowerCase() : rowValue;

        switch (this.rangeUnit) {
            case 'number':
                return (!min || min <= rowValue) && (!max || max > rowValue);
            case 'days':
                return (!min || rowValue.isSameOrAfter(min)) && (!max || rowValue.isBefore(max));
            default:
                return rowValue === optionName; //rowValue?.split(',').some(s => s === optionName); CaptionBadge resources null!
        }
    }

    buildChoiceList(filteredRows) {
        const { field, label, choiceList } = this;
        const options = this.options.reduce((acc, option) => {
            const count = this.countRowsInTable(filteredRows, option, field);

            if (count > 0 || !(option.value && count === 0)) {
                const { count: old, ...rest } = option;
                acc.push({ count, ...rest });
            }
            return acc;
        }, []);

        return { field, label, choiceList, options };
    }

    buildRenderData(filteredRows) {
        return this.buildChoiceList(filteredRows);
    }
}

export default Filter;
