import { basicColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

export default {
    id: idColumnDef('Id', 'masterTermId'),
    masterTermVersionId: {
        ...basicColumnDef('MasterTermVersionId', 'masterTermVersionId'),
        type: 'number'
    },
    previousMasterTermId: {
        ...basicColumnDef('PreviousMasterTermId', 'previousMasterTermId'),
        type: 'number'
    },
    brand: {
        ...basicColumnDef('Brand', 'brand'),
        type: 'text'
    },
    series: {
        ...basicColumnDef('Series', 'series'),
        type: 'text'
    },
    name: {
        ...basicColumnDef('Name', 'name'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'name',
            helpText: 'The name of the master term'
        }
    },
    devCode: {
        ...basicColumnDef('DevCode', 'devCode'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'devCode',
            helpText: 'The dev code of the master term'
        }
    },
    description: {
        ...basicColumnDef('Description', 'description'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'description',
            helpText: 'The description of the master term'
        }
    },
    ivsCodes: {
        ...basicColumnDef('IVS Codes', 'ivsCodes'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'ivsCodes',
            helpText: 'The IVS Codes linked to the master term'
        }
    },
    leasing: {
        ...basicColumnDef('Leasing', 'leasing'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'leasing',
            helpText: 'The discount for the leasing sales channel'
        }
    },
    neuLeasing: {
        ...basicColumnDef('NEU Leasing', 'neuLeasing'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'neuLeasing',
            helpText: 'The discount for the neu leasing sales channel'
        }
    },
    neu: {
        ...basicColumnDef('NEU', 'neu'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'neu',
            helpText: 'The discount for the neu sales channel'
        }
    },
    neuDiscretionary: {
        ...basicColumnDef('NEU Disc', 'neuDiscretionary'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'neuDiscretionary',
            helpText: 'The discretionary discount for the neu sales channel'
        }
    },
    flmDiscretionary: {
        ...basicColumnDef('FLM Disc', 'flmDiscretionary'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'flmDiscretionary',
            helpText: 'The discretionary discount for the flm sales channel'
        }
    },
    totalDiscretionary: {
        ...basicColumnDef('Total Disc', 'totalDiscretionary'),
        type: 'text',
        sortable: true,
        filter: true
    },
    publicSector: {
        ...basicColumnDef('PSO', 'publicSector'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'publicSector',
            helpText: 'The discount for the public sector sales channel'
        }
    },
    specialist: {
        ...basicColumnDef('Specialist', 'specialist'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'specialist',
            helpText: 'The discount for the specialist sales channel'
        }
    },
    rental: {
        ...basicColumnDef('Rental', 'rental'),
        type: 'text',
        sortable: true,
        filter: true,
        help: {
            helpId: 'rental',
            helpText: 'The discount for the rental sales channel'
        }
    }
};
