import React from 'react';
import { Button } from 'reactstrap';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';

function IvsCodesControl({ disabled, rowData }) {
    const open = useIvsCodesStore(state => state.openModal);
    return (
        <Button
            className="btn-sm bg-white-not-hover button-outline-primary text-left text-dark text-truncate"
            disabled={disabled}
            onClick={() => open(rowData)}
            style={{ width: '200px' }}
            title="Edit Ivs Codes"
        >
            <i className="mr-2 fa d-inline fa-lg fa-pencil" />
            {rowData.ivsCodes ? rowData.ivsCodes.map(x => x.ivsCode).join(', ') : 'Assign'}
        </Button>
    );
}

export function IvsCodesEdit({ disabled, rowData, useTableStore }) {
    const rowDataNew = useTableStore(state => state.dataState.pageData[rowData.index]);
    return <IvsCodesControl disabled={disabled} rowData={rowDataNew} />;
}
