import { push } from 'connected-react-router';
import { makeSafeUrl } from '../../reducers/util';
import { useDispatch } from 'react-redux';

export const useSafeDispatchPush = (urlPartsMapping, queryParams) => {
    const dispatch = useDispatch();
    return (entity, entityQuery) =>
        safeDispatchPush(dispatch, push, urlPartsMapping(entity), entityQuery || queryParams);
};

export const safeDispatchPush = (dispatch, push, urlParts, queryParams) =>
    dispatch(push(makeSafeUrl(urlParts, queryParams)));
