import { toastr } from 'react-redux-toastr';
import * as moment from 'moment';
import { PreventClickThrough } from '../../../containers/common/Table/PreventClickThrough';
import { downloadFile, makeGetRequest } from '../apiHelpers';

export function downloadAgreementPdf(e, agreementId, revisionId, companyName, setDownloading) {
    PreventClickThrough(e);

    setDownloading(true);

    makeGetRequest(['agreement', 'get-pdf'], { agreementId, revisionId })
        .then(d => {
            const today = moment().format('DDMMYY');
            const fileName = `BMW Group End User Agreement - ${companyName} - ${agreementId} - ${today}.pdf`;
            downloadFile(d.data, fileName, 'application/pdf');
            setDownloading(false);
        })
        .catch(err => {
            toastr.error('Download PDF', `Response ${err.message}`);
            setDownloading(false);
        });
}
