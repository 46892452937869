import React from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ExpandIcon } from '../../common/BaseTable/BaseTableInCard';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';
import { DeleteTableRowButton, TableButtonsContainer } from '../../common/Buttons/TableButtons';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';
import {
    useAgreementNotificationStore,
    useAgreementNotificationPeopleStore,
    useUpdateNotifications
} from '../Provider/index';
import agreementNotificationDef from '../agreementNotificationDef';
import useDimensions from '../../common/Hooks/useDimensions';
import BaseTable, { useChoicelistCellDefinition } from '../../common/BaseTable/BaseTable';
import NotificationSwitchColumnRenderer from './NotificationSwitchColumnRenderer';
import ToggleRenderer from '../../common/BaseTable/CusomCellRenderers/ToggleRenderer';

const MultiRenderer = ({ cellData, column: { multiSelect } }) => {
    const items = cellData?.split(',').map(m => {
        const label = multiSelect.get(m);
        return (
            <div key={m}>
                <span className="badge badge-light">{label}</span>
            </div>
        );
    });

    return <div className="w-100">{items || 'All'}</div>;
};

function ActionsRenderer({ onRemoveNotification, disabled, people, rowData }) {
    const onDelete = event => {
        PreventClickThrough(event);
        onRemoveNotification(rowData);
    };

    const anyPeople = people.some(f => f.agreementNotificationId === rowData.agreementNotificationId);
    return (
        <TableButtonsContainer>
            {!disabled && !anyPeople && <DeleteTableRowButton onClick={onDelete} />}
        </TableButtonsContainer>
    );
}

export const useNotificationsTableColumns = groupBy => {
    const salesChannelList = useChoiceListForBaseTable('salesChannel');
    const agreementTypeList = useChoiceListForBaseTable('agreementType');
    const agreementStatusList = useChoiceListForBaseTable('agreementHeaderStatus');
    const updateNotifications = useUpdateNotifications();
    const notificationPeople = useAgreementNotificationPeopleStore(state => state.data);

    const onRemoveNotification = notification => {
        updateNotifications([{ ...notification, deleted: 1 }]);
    };

    const columns = [
        agreementNotificationDef.agreementNotificationName,
        {
            ...useChoicelistCellDefinition(agreementNotificationDef.statusFrom),
            multiSelect: agreementStatusList,
            cellRenderer: MultiRenderer
        },
        useChoicelistCellDefinition(agreementNotificationDef.agreementStatus),
        useChoicelistCellDefinition(agreementNotificationDef.user),
        {
            ...agreementNotificationDef.salesChannels,
            multiSelect: salesChannelList,
            cellRenderer: MultiRenderer
        },
        {
            ...agreementNotificationDef.agreementType,
            multiSelect: agreementTypeList,
            cellRenderer: MultiRenderer
        },
        agreementNotificationDef.emailCC,
        {
            ...agreementNotificationDef.email,
            cellRenderer: ({ rowData }) => (
                <div dangerouslySetInnerHTML={{ __html: rowData.emailSubject + '<br/><br/>' + rowData.emailBody }} />
            )
        },
        useChoicelistCellDefinition(agreementNotificationDef.sender),
        {
            ...agreementNotificationDef.isMassAddendumEmail,
            width: 210,
            noGrow: true,
            cellRenderer: ({ cellData, rowData }) => (
                <ToggleRenderer cellData={cellData} disabled={true} rowData={rowData} />
            )
        },
        {
            field: 'settings',
            title: 'Settings',
            actions: [
                agreementNotificationDef.active,
                agreementNotificationDef.sendToOwner,
                agreementNotificationDef.sendToCustomer,
                agreementNotificationDef.sendToLeasingCompany,
                agreementNotificationDef.attachToEmail,
                agreementNotificationDef.attachLeasingCsv,
                agreementNotificationDef.appendCompanyToMessage,
                agreementNotificationDef.appendAgreementToMessage,
                agreementNotificationDef.appendLinkToEngage
            ],
            width: 210,
            noGrow: true,
            cellRenderer: props => (
                <NotificationSwitchColumnRenderer
                    {...props}
                    disabled={false}
                    onRemoveNotification={onRemoveNotification}
                    people={notificationPeople}
                />
            )
        },
        {
            field: 'actions',
            title: 'Actions',
            width: 70,
            noGrow: true,
            cellRenderer: props => (
                <ActionsRenderer
                    {...props}
                    disabled={false}
                    onRemoveNotification={onRemoveNotification}
                    people={notificationPeople}
                />
            )
        }
    ];

    return columns.reduce((acc, f) => (groupBy !== f.field ? [...acc, f] : acc), []);
};

const getGroupByText = groupBy => {
    if (groupBy === agreementNotificationDef.agreementStatus.field) return 'Agreement Status';
    else if (groupBy === agreementNotificationDef.salesChannels.field) return 'Sales Channel';
    else if (groupBy === agreementNotificationDef.user.field) return 'User';
    else return 'None';
};

export function AgreementNotificationGroupByButton({ groupBy, setGroupBy }) {
    return (
        <UncontrolledDropdown className="d-inline-block dropdown">
            <DropdownToggle caret className="text-white">
                Group By: {getGroupByText(groupBy)}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-info" right>
                <DropdownItem active={!groupBy} onClick={() => setGroupBy(undefined)}>
                    None
                </DropdownItem>
                <DropdownItem
                    active={groupBy === agreementNotificationDef.agreementStatus.field}
                    onClick={() => setGroupBy(agreementNotificationDef.agreementStatus.field)}
                >
                    Agreement Status
                </DropdownItem>
                <DropdownItem
                    active={groupBy === agreementNotificationDef.salesChannels.field}
                    onClick={() => setGroupBy(agreementNotificationDef.salesChannels.field)}
                >
                    Sales Channel
                </DropdownItem>
                <DropdownItem
                    active={groupBy === agreementNotificationDef.user.field}
                    onClick={() => setGroupBy(agreementNotificationDef.user.field)}
                >
                    User
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

const AgreementNotificationTable = ({ columns, groupBy }) => {
    const notifications = useAgreementNotificationStore(state => state.data);
    const openModal = useAgreementNotificationStore(state => state.openModal);

    const statusChoiceMap = useChoiceListForBaseTable(agreementNotificationDef.agreementStatus.choiceList);
    const salesChannelChoiceMap = useChoiceListForBaseTable(agreementNotificationDef.salesChannels.choiceList);
    const userList = useChoiceListForBaseTable('userAuthors');

    const getGroupValue = rowData => {
        rowData.createdBy = rowData.createdBy?.toString();
        if (groupBy === agreementNotificationDef.agreementStatus.field) return statusChoiceMap.get(rowData[groupBy]);
        else if (groupBy === agreementNotificationDef.user.field) return userList.get(rowData[groupBy]);
        else if (groupBy === agreementNotificationDef.salesChannels.field)
            return rowData[groupBy]
                ? rowData[groupBy]
                      .split(',')
                      .map(m => salesChannelChoiceMap.get(m))
                      .join(', ')
                : 'All';
    };

    const [ref, { y }] = useDimensions({ liveMeasure: false });

    return (
        <div ref={ref} style={{ height: `calc(100vh - ${y}px - 1rem`, width: '100%' }}>
            <BaseTable
                cardTitle="Notifications"
                columns={columns}
                data={notifications}
                expandAll={true}
                groupBy={groupBy ? [groupBy] : undefined}
                groupRenderer={({ rowData, ...rest }) => (
                    <div className="py-2" style={{ display: 'inherit' }}>
                        <ExpandIcon {...rowData} {...rest} />
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {getGroupValue(rowData)} ({rowData.groupCount})
                        </div>
                    </div>
                )}
                helpId="agreementNotificationsTableHeading"
                onRowClicked={({ rowData }) => !rowData.groupRow && openModal(rowData)}
                rowKey="agreementNotificationId"
            />
        </div>
    );
};

export default AgreementNotificationTable;
