import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { SaveCancelButton } from '../../common/SaveCancelButton';
import { Formik } from 'formik';
import FormikText from '../../common/FormkControls/FormikText';
import { Prompt } from 'react-router-dom';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';
import { toastr } from 'react-redux-toastr';

const MeetingNotesForm = ({ meeting, saveMeeting }) => (
    <Formik
        initialValues={{ notes: meeting.notes || '' }}
        onSubmit={({ notes }) => {
            const { company, ...meetingWithoutCompany } = meeting;
            const values = {
                ...meetingWithoutCompany,
                companyId: meeting.companyId || company?.companyId,
                notes
            };
            saveMeeting(values);
        }}
        render={formikProps => {
            formikProps.formName = 'meetingNotesForm';
            const commNotes = meeting.notes || '';
            return (
                <Fragment>
                    <Prompt
                        key="NavigationPrompt"
                        message={location =>
                            `Meeting Notes have not been saved are you sure that you want to exit this page.`
                        }
                        when={formikProps.values.notes !== commNotes}
                    />
                    <FormikText
                        fieldName="notes"
                        {...formikProps}
                        disabled={meeting.status === 'Complete'}
                        hideLabel
                        type="textarea"
                    />
                    <div className="w-100 text-right">
                        <SaveCancelButton
                            hideCancelButton={meeting.status === 'Complete'}
                            hideSaveButton={meeting.status === 'Complete'}
                            onCancel={() => formikProps.setValues({ notes: commNotes })}
                            onSave={formikProps.handleSubmit}
                        />
                    </div>
                </Fragment>
            );
        }}
    />
);

const mapDispatchToProps = dispatch => ({
    saveMeeting: meeting => {
        dispatch(apiMeetingPost(meeting));
        toastr.success('Meeting notes updated');
    }
});

export default connect(null, mapDispatchToProps)(MeetingNotesForm);
