import moment from "moment";
import { toastr } from "react-redux-toastr";
import { isError } from "../../Loading/isError";
import { getAxiosPostOfflineRequest, REST_TIME_FORMAT } from "../apiHelpers";
import { ENDPOINTS } from "../constants";


export const apiLeasingProfileEdit = (leasingProfileForm, a, b) => async (dispatch, getState) => {

    const leasingProfile = { ...leasingProfileForm };

    const type = ENDPOINTS.API.LEASING_PROFILE_POST;

    try {
        let data = { ...leasingProfile };
        if (data.leasingProfileId < 0) {
            data.leasingProfileId = null;
        }

        data.updatedDate = moment().format(REST_TIME_FORMAT);

        const axiosRequest = getAxiosPostOfflineRequest(['leasingprofile'], {}, data);

        let unmodifiedLeasingProfile = getState().leasingProfiles.find(
            x => x.leasingProfileId === leasingProfile.leasingProfileId
        );

        if (!unmodifiedLeasingProfile) {
            unmodifiedLeasingProfile = a || leasingProfile;
        }

        unmodifiedLeasingProfile.error = undefined;


        dispatch({
            type,
            data: { ...data, syncing: true },
            entityId: `${type}-${data.leasingProfileId}`, //Used in store.js to see if offline updates are targeting the same entity
            meta: {
                offline: {
                    effect: axiosRequest,
                    commit: { type: ENDPOINTS.API.LEASING_PROFILE_POST_COMMIT, meta: { data } },
                    rollback: {
                        type: ENDPOINTS.API.LEASING_PROFILE_POST_ROLLBACK,
                        meta: { unmodifiedLeasingProfile, leasingProfile }
                    }
                }
            }
        });
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
}