import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import LoginPage from '../Authentication/Pages/LoginPage';
import PageWithNavigation from './PageWithNavigation';
import ForgottenPasswordPage from '../Authentication/Pages/ForgottenPasswordPage';
import ResetPasswordPage from '../Authentication/Pages/ResetPasswordPage';
import SetupAccountPage from '../Authentication/Pages/SetupAccountPage';
import Page404 from '../Authentication/Pages/Page404';
import Modals from './Modals';
import { apiAuthUserLogout } from '../../actions/ApiAuth/apiAuthUserLogout';
import appSettings from '../../config/appSettings';
import RequestSupportPage from '../Support/Pages/RequestSupportPage';
import CalendarPage from './Pages/CalendarPage';
import PeoplePage from './Pages/PeoplePage';
import AddressesPage from './Pages/AddressesPage';
import SendVisitReportEmailPage from './Pages/SendVisitReportEmailPage';
import MeetingPage from './Pages/MeetingPage';
import CompanyPage from './Pages/CompanyPage';
import HomePage from './Pages/HomePage';
import CompaniesPage from './Pages/CompaniesPage';
import VersionsPage from './Pages/VersionsPage';
import AgreementPage from './Pages/AgreementPage';
import AgreementsPage from './Pages/AgreementsPage';
import MatricesPage from './Pages/MatricesPage';
import MatrixPage from './Pages/MatrixPage';
import AgreementNotificationsPage from './Pages/AgreementNotificationsPage';
import AgreementCheckTypesPage from './Pages/AgreementCheckTypesPage';
import IvsCodesPage from './Pages/IvsCodesPage';
import RevisionPage from './Pages/RevisionPage';
import RevisionCompareToAgreementPage from './Pages/RevisionCompareToAgreementPage';
import AgreementTemplatesPage from './Pages/AgreementTemplatesPage';
import CapCodesPage from './Pages/CapCodesPage';
import AddendaPage from './Pages/AddendaPage';
import AddendumPage from './Pages/AddendumPage';
import AgreementsAdminPage from './Pages/AgreementsAdminPage';
import AgreementAdminWorkflowPage from './Pages/AgreementAdminWorkflowPage';
import UserManagementEngage from './Pages/UserMangementEngage';
import AgreementRulesPage from './Pages/AgreementRulesPage';
import NotificationsPage from './Pages/NotificationsPage';
import AgreementsReportingPage from './Pages/AgreementsReportingPage';
import AgreementLiveTermsPage from './Pages/AgreementLiveTermsPage';
import RecentActivityPage from './Pages/RecentActivityPage';

export const LANDING_PAGES = [
    { name: 'Companies', path: '/companies/' },
    {
        name: 'Agreements',
        path: '/agreements/'
    } /*{ name: 'ToDo', path: '/todo/' }*/
];

function updateInteraction() {
    lastInteraction = new Date();
}

function isLogOutTime(timeout) {
    const timeSinceLastInteraction = new Date() - lastInteraction;
    return timeSinceLastInteraction > timeout;
}

let lastInteraction;

class Example extends React.Component {
    componentDidMount = () => {
        try {
            updateInteraction();
            document.addEventListener('click', updateInteraction);
            document.addEventListener('keydown', updateInteraction);

            setInterval(async () => {
                if (this.props.user.userId > -1 && isLogOutTime(appSettings.timeout)) {
                    console.log('Logging out...');
                    this.props.logout();
                }
            }, 30000);
        } catch (e) {
            console.log(e);
        }

        window.addEventListener('beforeunload', this.onUnload);
    };

    componentWillUnmount = () => window.removeEventListener('beforeunload', this.onUnload);

    onUnload(e) {
        if (process.env.REACT_APP_NO_OFFLINE === 'true') localStorage.clear();
    }

    render = () => [
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            key="toastr"
        />,
        <section key="index-section">
            {
                //TODO: Only add these if modal is open. They are all being rendered
            }
            <Modals />
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/forgotpassword/" component={ForgottenPasswordPage} />
            <Route exact path="/resetpassword/:resetToken" component={ResetPasswordPage} />
            <Route exact path="/setupaccount/:resetToken" component={SetupAccountPage} />
            <Route
                path="/:id"
                render={() => (
                    <PageWithNavigation>
                        <Switch>
                            <Route exact path="/dashboard/home" component={HomePage} />
                            <Route exact path="/dashboard/companies" component={CompaniesPage} />
                            <Route exact path="/calendar/:view/:year/:month/:day" component={CalendarPage} />
                            <Route exact path="/company/:companyId/people" component={PeoplePage} />
                            <Route exact path="/company/address/:companyId" component={AddressesPage} />
                            <Route path="/company/:companyId/:companyName" component={CompanyPage} />
                            <Route exact path="/agreements/admin/workflow" component={AgreementAdminWorkflowPage} />
                            <Route exact path="/agreements/admin" component={AgreementsAdminPage} />
                            <Route exact path="/agreements/reports" component={AgreementsReportingPage} />
                            <Route exact path="/recentactivity" component={RecentActivityPage} />
                            <Route path="/matrix/:matrixId" component={MatrixPage} />
                            <Route path="/matrix" component={MatricesPage} />
                            <Route path="/agreements/admin/notifications" component={AgreementNotificationsPage} />
                            <Route path="/checks" component={AgreementCheckTypesPage} />
                            <Route path="/templates" component={AgreementTemplatesPage} />
                            <Route path="/capcodes/:ivscodes?" component={CapCodesPage} />
                            <Route path="/ivs" component={IvsCodesPage} />
                            <Route exact path="/agreementrules" component={AgreementRulesPage} />
                            <Route exact path="/agreement" component={AgreementsPage} />
                            <Route exact path="/agreement/:agreementId" component={AgreementPage} />
                            <Route exact path="/agreement/:agreementId/:revisionId" component={RevisionPage} />
                            <Route
                                exact
                                path="/agreement/:agreementId/:revisionId/:compareId"
                                component={RevisionCompareToAgreementPage}
                            />
                            <Route path="/agreementliveterms" component={AgreementLiveTermsPage} />
                            <Route path="/addendum/:addendumId" component={AddendumPage} />
                            <Route path="/addendum" component={AddendaPage} />
                            <Route exact path="/communication/email/:meetingId" component={SendVisitReportEmailPage} />
                            <Route path="/meeting/view/:communicationId/:descriptor" component={MeetingPage} />
                            <Route path="/notifications" component={NotificationsPage} />
                            <Route exact path="/forgotpassword/" />
                            <Route exact path="/resetpassword/:resetToken" />
                            <Route exact path="/setupaccount/:resetToken" />
                            <Route exact path="/versions/" component={VersionsPage} />
                            <Route exact path="/support/request/" component={RequestSupportPage} />
                            <Route exact path="/user/management/engine/" component={UserManagementEngage} />
                            <Route component={Page404} />
                        </Switch>
                    </PageWithNavigation>
                )}
            />
        </section>
    ];
}

const mapStateToProps = state => ({
    user: state.user,
    active: state.active,
    restState: state.restState,
    page: state.page
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(apiAuthUserLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Example);
