import React from 'react';
import { FieldValue } from '../../common/DisplayField/FieldValue';
import moment from 'moment';
import AgendaDayVisitAction from './AgendaDayVisitAction';
import AgendaDayMeeting from './AgendaDayMeeting';
import AgendaDayCommunication from './AgendaDayCommunication';
import AgendaDayEvent from './AgendaDayEvent';

const AgendaDay = props => {
    const { agenda, day, updateCommunication, editVisitAction, choiceList, showDayName } = props;

    const daysComms = agenda.filter(
        comm => moment(comm.startDate).isSameOrBefore(day, 'day') && moment(comm.endDate).isSameOrAfter(day, 'day')
    );

    return (
        <React.Fragment>
            <FieldValue className="mt-3" primary>
                {showDayName ? day.format('dddd Do MMMM') : 'day'}
            </FieldValue>
            {daysComms.length > 0 ? null : <span>No events</span>}
            {daysComms.map(comm => {
                if (comm.visitActionId) {
                    return (
                        <AgendaDayVisitAction
                            choiceList={choiceList}
                            editVisitAction={editVisitAction}
                            key={comm.visitActionId}
                            visitAction={comm}
                        />
                    );
                } else if (comm.eventId) {
                    return (
                        <AgendaDayEvent
                            event={comm}
                            key={comm.communicationId}
                            updateCommunication={updateCommunication}
                        />
                    );
                } else if (comm.meetingId) {
                    return (
                        <AgendaDayMeeting
                            choiceList={choiceList}
                            communication={comm}
                            key={comm.meetingId}
                            updateCommunication={updateCommunication}
                        />
                    );
                } else {
                    return (
                        <AgendaDayCommunication
                            choiceList={choiceList}
                            communication={comm}
                            key={comm.communicationId}
                            updateCommunication={updateCommunication}
                        />
                    );
                }
            })}
        </React.Fragment>
    );
};

export default AgendaDay;
