import create from 'zustand';
import { updateData } from '../../../actions/Api/apiHelpers';
import { tableStoreState } from '../../common/BaseTable/BaseTable';
import { maxTermDiscountPct } from '../../../config.json';

const rowKey = 'masterTermId';
//const totalField = 'totalDiscount';
export const useBMWStore = create((set, get) => {
    return {
        ...tableStoreState(set, get, { rowKey, groupBy: ['series'], expandAll: true }),
        setTermsData: (add, remove, ordinal) => {
            const state = get();
            state.setData(updateData(state.dataCopy, rowKey, add, false, remove, ordinal));
        },
        getMaxValue: () => {
            return maxTermDiscountPct;
        }
    };
});
export const useMiniStore = create((set, get) => {
    return {
        ...tableStoreState(set, get, { rowKey, groupBy: ['series'], expandAll: true }),
        setTermsData: (add, remove, ordinal) => {
            const state = get();
            state.setData(updateData(state.dataCopy, rowKey, add, false, remove, ordinal));
        },
        getMaxValue: () => {
            return maxTermDiscountPct; // TEMP until master terms has total discount calculation somewhere!
        }
    };
});
export const usePreviousMasterTermsStore = create((set, get) => {
    return {
        setData: map => {
            set({ map });
        }
    };
});
