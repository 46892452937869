import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Container, InputGroupAddon } from 'reactstrap';
import queryString from 'query-string';
import ReactiveForm from '../../common/ReactiveForm';
import { FORM_NAMES } from '../../../actions/formNames';
import agreementDef from '../../Agreement/agreementDef';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import IvsSearchModal from './IvsSearchModal';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

const FORM_NAME = FORM_NAMES.AGREEMENTS_FILTER;
const CARD_TITLE = 'Search';

const FormCard = ({ title, className, inContainer = true, children }) => (
    <Card className={`mb-4 shadow-sm simple-card ${className}`} style={{ overflow: 'hidden' }}>
        <div className="d-flex justify-content-between base-table-in-card-header">
            <h5 className="d-inline logo-font gradient mb-0">{title}</h5>
        </div>
        {inContainer ? <Container>{children}</Container> : children}
    </Card>
);

export const makeTermsFilterText = filter => {
    if (
        filter.hasOwnProperty('termSeries') ||
        filter.hasOwnProperty('termDescription') ||
        filter.hasOwnProperty('ivsCodeId') ||
        filter.hasOwnProperty('termDiscountType') ||
        filter.hasOwnProperty('termDiscount') ||
        filter.hasOwnProperty('rebate') ||
        filter.hasOwnProperty('activeSupportBonus') ||
        filter.hasOwnProperty('termDiscount')
    ) {
        return `
        ${filter.termSeries ? `Series: ${filter.termSeries}` : ''}
        ${filter.termDescription ? `Description: ${filter.termDescription}` : ''}
        ${filter.ivsCodeId ? `Ivs Code Id: ${filter.ivsCodeId}` : ''}
        ${filter.termDiscountType ? `Discount Type: ${filter.termDiscountType}` : ''}
        ${filter.termDiscount ? `Discount: ${parseFloat(filter.termDiscount)?.toFixed(2)}` : ''}
        ${filter.rebate ? `Rebate: ${filter.rebate}` : ''}
        ${filter.activeSupportBonus ? `Active Support Bonus: ${filter.activeSupportBonus}` : ''}
        `;
    }
    return 'NONE';
};

export const filterAgreementsFilterSearch = search => {
    const parsedQueryString = queryString.parse(search);
    const filter = {
        ...parsedQueryString,
        ivsCodeId: parsedQueryString.ivsCodeId ? parseInt(parsedQueryString.ivsCodeId) : undefined,
        activeSupportBonus:
            parsedQueryString.activeSupportBonus === 'true'
                ? true
                : parsedQueryString.activeSupportBonus === 'false'
                ? false
                : undefined,
        rebate: parsedQueryString.rebate === 'true' ? true : parsedQueryString.rebate === 'false' ? false : undefined,
        recent: parsedQueryString.recent === 'true' ? true : parsedQueryString.recent === 'false' ? false : undefined,
        expiring:
            parsedQueryString.expiring === 'true' ? true : parsedQueryString.expiring === 'false' ? false : undefined,
        hasClauses:
            parsedQueryString.hasClauses === 'true'
                ? true
                : parsedQueryString.hasClauses === 'false'
                ? false
                : undefined,
        inactiveSignatories:
            parsedQueryString.inactiveSignatories === 'true'
                ? true
                : parsedQueryString.inactiveSignatories === 'false'
                ? false
                : undefined,
        signatoriesRequired:
            parsedQueryString.signatoriesRequired === 'true'
                ? true
                : parsedQueryString.signatoriesRequired === 'false'
                ? false
                : undefined
    };

    return {
        ...filter
    };
};

export const AgreementsFilter = () => {
    const history = useHistory();
    const { search } = useLocation();
    const versions = useMasterTermVersionsStore(state => state.data);
    const versionOptions = versions.map(m => ({ key: m.masterTermVersionId.toString(), value: m.reference }));
    const [disabled, setDisabled] = useState(true);

    const handleSubmit = (change, clearIvsSearch) => {
        const newFilter = {
            ...filterAgreementsFilterSearch(search),
            ...change,
            createdBefore: change.createdBefore || undefined,
            createdAfter: change.createdAfter || undefined,
            termsFilterText: undefined
        };
        change.hasOwnProperty('ivsCodeId') && setDisabled(false);

        if (clearIvsSearch) {
            delete newFilter.termSeries;
            delete newFilter.termDescription;
            delete newFilter.ivsCodeId;
            delete newFilter.termDiscountType;
            delete newFilter.termDiscount;
            setDisabled(true);
        }

        history.push(`/agreement?${queryString.stringify(newFilter, { skipEmptyString: true })}`);
    };

    const filterSplit = filterAgreementsFilterSearch(search);

    const initialValues = {
        ...filterSplit,
        termsFilterText: makeTermsFilterText(filterSplit)
    };

    return (
        <IvsSearchModal handleSubmit={handleSubmit}>
            {setIsOpen => (
                <FormCard className="pb-4" title={CARD_TITLE}>
                    <ReactiveForm
                        debounced={true}
                        enableReinitialize={true}
                        formName={FORM_NAME}
                        hideCancelButton={true}
                        hideSaveButton={true}
                        initialValues={initialValues}
                        onChange={handleSubmit}
                    >
                        <ReactiveForm.Section>
                            <ReactiveForm.Collapse expand={true} title="Time">
                                <ReactiveForm.Checkbox
                                    columnDef={{ ...basicColumnDef('Recent', 'recent') }}
                                    useBigTitle={true}
                                />
                                <ReactiveForm.Checkbox
                                    columnDef={{ ...basicColumnDef('Expiring Soon', 'expiring') }}
                                    useBigTitle={true}
                                />
                                <ReactiveForm.Date
                                    columnDef={{ ...basicColumnDef('Created Before', 'createdBefore') }}
                                    isClearable={true}
                                    useBigTitle={true}
                                />
                                <ReactiveForm.Date
                                    columnDef={{ ...basicColumnDef('Created After', 'createdAfter') }}
                                    isClearable={true}
                                    useBigTitle={true}
                                />
                            </ReactiveForm.Collapse>
                            <ReactiveForm.Collapse title="Type">
                                <ReactiveForm.TriStateCheckBox columnDef={agreementDef.activeSupportBonus} />
                                <ReactiveForm.TriStateCheckBox columnDef={agreementDef.rebate} />
                            </ReactiveForm.Collapse>
                            <ReactiveForm.Multiselect columnDef={agreementDef.status} expand={true} />
                            <ReactiveForm.Multiselect
                                columnDef={agreementDef.masterTermVersionId}
                                options={versionOptions}
                            />
                            <ReactiveForm.Multiselect columnDef={agreementDef.salesChannel} />
                            <ReactiveForm.Multiselect columnDef={agreementDef.agreementType} />

                            <ReactiveForm.Multiselect columnDef={agreementDef.createdBy} />
                            <ReactiveForm.Text columnDef={agreementDef.reference} useBigTitle={true} />
                            <ReactiveForm.Text columnDef={agreementDef.termsOfBusiness} useBigTitle={true} />
                            <ReactiveForm.Text
                                columnDef={{ ...basicColumnDef('Terms Filter', 'termsFilterText') }}
                                disabled={true}
                                onFormValueChanged={values => setDisabled(values?.termsFilterText?.trim?.length < 1)}
                                inputGroupAddons={
                                    <>
                                        <InputGroupAddon addonType="append">
                                            <Button color="info" onClick={() => setIsOpen(true)} type="button">
                                                <i className="fa fa-pencil" />
                                            </Button>
                                        </InputGroupAddon>
                                        <InputGroupAddon addonType="append">
                                            <Button
                                                color="warning"
                                                disabled={disabled}
                                                onClick={() => handleSubmit({}, true)}
                                                type="button"
                                            >
                                                <i className="fa fa-times" />
                                            </Button>
                                        </InputGroupAddon>
                                    </>
                                }
                                useBigTitle={true}
                            />
                            <ReactiveForm.TriStateCheckBox
                                columnDef={{ ...basicColumnDef('Has Clauses', 'hasClauses') }}
                            />
                            <ReactiveForm.TriStateCheckBox
                                columnDef={{ ...basicColumnDef('Inactive Signatories', 'inactiveSignatories') }}
                            />
                            <ReactiveForm.TriStateCheckBox
                                columnDef={{ ...basicColumnDef('Signatories Required', 'signatoriesRequired') }}
                            />
                        </ReactiveForm.Section>
                    </ReactiveForm>
                </FormCard>
            )}
        </IvsSearchModal>
    );
};
