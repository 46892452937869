const appSettings = {
    timeout: 1000 * 60 * 60,
    auth: {
        passwordEntropy: {
            minLength: 8,
            minUpper: 1,
            minLower: 1,
            minNumber: 1,
            minSpecial: 1,
            validSpecials: ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~',
            error:
                'Passwords must be 8 characters, with at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character'
        }
    },
    permissions: {
        canAddAddress: false,
        canEditAddress: false
    },
    miscSettings: {
        calendarEventType: 'standard'
    },
    calendar: {
        name: 'Calendar',
        buttons: [
            {
                type: 'createDay',
                buttonText: 'Add Event',
                fontawesomeIcon: 'fa-plus',
                bootstrapColor: 'secondary',
                action: 'Event',
                eventType: 'Event',
                engageEntityType: 'event'
            },
            {
                type: 'createDay',
                buttonText: 'Add Holiday',
                fontawesomeIcon: 'fa-plus',
                bootstrapColor: 'secondary',
                action: 'CSMAnnualLeave',
                eventType: 'Holiday',
                engageEntityType: 'holiday'
            },
            {
                type: 'createDay',
                buttonText: 'Add Internal Meeting',
                fontawesomeIcon: 'fa-plus',
                bootstrapColor: 'secondary',
                action: 'Meeting',
                eventType: 'Meeting',
                engageEntityType: 'meeting'
            }
        ]
    }
};

export default appSettings;
