import React from 'react';
import { AgreementProvider } from '../../Agreement/Provider/AgreementProvider';
import AgreementPageContent from '../../Agreement/Components/AgreementPageContent';

export default function AgreementPage({
    match: {
        params: { agreementId: strAgreementId }
    }
}) {
    const agreementId = parseInt(strAgreementId, 10);

    return (
        <AgreementProvider agreementId={agreementId}>
            <AgreementPageContent />
        </AgreementProvider>
    );
}
