import { basicColumnDef, choiceListColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';
import { ChoiceListRenderer } from '../common/BaseTable/CusomCellRenderers';
import React from 'react';

const userTypeColumnDef = {
    ...choiceListColumnDef('User Type', 'userType', 'userType'),
    type: 'text',
    help: {
        helpId: 'userType',
        helpText: 'User Type, to update please use CRM'
    }
};

const salesChannelColumnDef = {
    ...choiceListColumnDef('Sales Channel', 'salesChannel', 'salesChannel'),
    type: 'text',
    help: {
        helpId: 'salesChannelColumnDef',
        helpText: 'Sales Channel for agreement'
    }
};

const agreementTypeColumnDef = {
    ...choiceListColumnDef('Agreement Type', 'agreementType', 'agreementType'),
    type: 'text',
    help: {
        helpId: 'agreementTypeColumnDef',
        helpText: 'Agreement Type'
    }
};

const termsEditableColumnDef = {
    ...basicColumnDef('Terms Editable', 'termsEditable'),
    type: 'text',
    help: {
        helpId: 'termsEditable',
        helpText: 'User with this role can edit terms'
    }
};
const termsVisibleColumnDef = {
    ...basicColumnDef('Terms Visible', 'termsVisible'),
    type: 'text',
    help: {
        helpId: 'termsVisible',
        helpText: 'User with Role can view terms'
    }
};
const minSupportColumnDef = {
    ...basicColumnDef('Min Support', 'minSupport'),
    type: 'text',
    help: {
        helpId: 'minSupport',
        helpText: (
            <div className="d-grid" style={{ gridTemplateColumns: 'auto auto', gap: '1rem' }}>
                <strong>NEU+FLM</strong>
                <span>Below NEU + FLM discount without authorisation</span>
                <strong>SPE</strong>
                <span>Below specialist sales discount without authorisation</span>
                <strong>Always</strong>
                <span>Always requires authorisation</span>
                <strong>NA</strong>
                <span>Authorisation not required</span>
            </div>
        )
    }
};

export default {
    id: idColumnDef('Id', 'authorizationLevelId'),
    userType: {
        ...userTypeColumnDef,
        cellRenderer: props => <ChoiceListRenderer columnDef={userTypeColumnDef} {...props} />
    },
    salesChannel: {
        ...salesChannelColumnDef,
        cellRenderer: props => <ChoiceListRenderer columnDef={salesChannelColumnDef} {...props} />
    },
    agreementType: {
        ...agreementTypeColumnDef,
        cellRenderer: props => <ChoiceListRenderer columnDef={agreementTypeColumnDef} {...props} />
    },
    minSupport: {
        ...minSupportColumnDef,
        cellRenderer: ({ cellData }) => (cellData === '0' ? 'Always' : cellData === null ? 'NA' : cellData)
    },
    termsEditable: {
        ...termsEditableColumnDef,
        cellRenderer: ({ cellData }) =>
            cellData ? <i className="fa fa-check text-success" /> : <i className="fa fa-times text-danger" />
    },
    termsVisible: {
        ...termsVisibleColumnDef,
        cellRenderer: ({ cellData }) =>
            cellData ? <i className="fa fa-check text-success" /> : <i className="fa fa-times text-danger" />
    }
};
