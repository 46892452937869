import React from 'react';
import BigCalendar from 'react-big-calendar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CalendarEvent, { ICalendarEvent } from '../Components/CalendarEvent';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { communicationSelected } from '../../../actions/Navigation/Communication/communicationSelected';
import { editVisitAction } from '../../../actions/Navigation/VisitAction/editVisitAction';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import moment from 'moment';
import { meetingSelected } from '../../../actions/Navigation/Meeting/meetingSelected';
import { editDay } from '../../../actions/Navigation/Communication/editDay';

const localizer = BigCalendar.momentLocalizer(moment);

const CommunicationCalendar: React.SFC<any> = props => {
	const {
		events,
		defaultView,
		eventStyleGetter,
		onNavigate,
		defaultDate,
		views,
		onView,
		loadingStates,
		selectEvent
	} = props;

	const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.COMMUNICATIONS_FILTER).isLoading;


	return (
		<>
			<ShowLoadingSpinner isLoading={isLoading} title="Loading Calendar" />
			<BigCalendar
				components={{
					event: CalendarEvent
				}}
				defaultDate={defaultDate}
				defaultView={defaultView}
				eventPropGetter={eventStyleGetter}
				events={events}
				formats={
					{
						eventTimeRangeFormat: () => ""
					}
				}
				localizer={localizer}
				max={new Date(2017, 10, 0, 18, 0, 0)}
				min={new Date(2017, 10, 0, 8, 0, 0)}
				onNavigate={onNavigate}
				onSelectEvent={(event: any) => selectEvent(event)}
				onSelectSlot={() => {}}
				onView={onView}
				selectable
				showMultiDayTimes
				step={60}
				timeslots={1}
				views={views}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	user: state.user,
	offline: state.offline,
	communications: state.communications,
	loadingStates: state.loadingStates
});

const mapDispatchToProps = (dispatch: any) => ({
	selectEvent: (event: ICalendarEvent) => {
		if (event.visitAction) {
			dispatch(editVisitAction(event.visitAction));
			return;
		} else if (event.event) {
			dispatch(editDay(event.event));
			return;
		} else if (event.communication) {
			dispatch(communicationSelected(event.communication));
			return;
		} else if (event.meeting) {
			dispatch(meetingSelected(event.meeting));
			return;
		}
		console.warn(`event not recognised ${event}`);
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CommunicationCalendar));
