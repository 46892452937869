import moment from 'moment';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';

export const salesChannelColors = {
    LEA: 'rgb(255, 99, 132)',
    NEU: 'rgb(54, 162, 235)',
    SPE: 'rgb(75, 192, 192)',
    REN: 'rgb(153, 102, 255)',
    PSO: 'rgb(255, 159, 64)'
};

export function useMapAgreementsAdminStatusGroupedDataToGraphDataForSalesChannel(
    agreementsAdminStatusGroupedData,
    filterDataSet,
    future = false,
    numberOfMonths = 12
) {
    const months = new Array(numberOfMonths).fill(1).map((_, index) => {
        const date = moment()
            .startOf('month')
            .add((future ? 0 : -numberOfMonths + 1) + index, 'month');
        return {
            year: date.year(),
            month: date.month() + 1,
            name: date.format('MMMM'),
            start: date.format(REST_TIME_FORMAT),
            end: date
                .clone()
                .add(1, 'month')
                .format(REST_TIME_FORMAT)
        };
    });
    const filteredData = agreementsAdminStatusGroupedData.reduce(
        (acc, { dataSet, salesChannel, month, year, count }) => {
            if (filterDataSet !== dataSet) {
                return acc;
            }
            const rowKey = `${year}_${month}_${salesChannel}`;

            if (!acc[rowKey]) {
                acc[rowKey] = 0;
            }

            acc[rowKey] += count;
            return acc;
        },
        {}
    );
    const salesChannels = useChoiceList('salesChannel');

    return {
        labels: months.map(month => month.name),
        datasets: salesChannels.map(({ key, value }) => {
            return {
                label: value,
                key: key,
                data: months.map(({ year, month }) => {
                    const rowKey = `${year}_${month}_${key}`;
                    return filteredData[rowKey] || 0;
                }),
                backgroundColor: salesChannelColors[key]
            };
        }),
        months
    };
}
