import create from 'zustand';
import { apiDisableUser, apiResetPassword, apiUpdateUserRole } from '../../../actions/Api/User/apiUser';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import engageUserDef from '../engageUserDef';
import { useUpdateAgreementsAdminUsersVisibility } from './AgreementsAdminUsersVisibilityStore';

const rowKey = engageUserDef.id.field;
export const useEngageUsersStore = create(set => storeWithModal(set, rowKey));

export function useResetPassword() {
    const setSaving = useEngageUsersStore(state => state.setSaving);
    const setError = useEngageUsersStore(state => state.setError);
    const updateFromFetch = useEngageUsersStore(state => state.updateDataFromFetch);

    return user => {
        setSaving();
        return apiResetPassword(user)
            .then(response => {
                updateFromFetch([]);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}

export function useUpdateRole(originalType, originalSeesUserIds) {
    const updateFromFetch = useEngageUsersStore(state => state.updateDataFromFetch);
    const setSaving = useEngageUsersStore(state => state.setSaving);
    const setError = useEngageUsersStore(state => state.setError);
    const closeModal = useEngageUsersStore(state => state.closeModal);
    const updateAgreementsAdminUsersVisibility = useUpdateAgreementsAdminUsersVisibility(closeModal, setError);

    return user => {
        setSaving();

        if (originalType === user.userType && originalSeesUserIds === user.seesUserIds) {
            closeModal();
        }

        if (originalType !== user.userType) {
            return apiUpdateUserRole(user)
                .then(response => {
                    updateFromFetch([response]);
                    return response;
                })
                .catch(error => {
                    setError(error);
                });
        }
        if (originalSeesUserIds !== user.seesUserIds) {
            updateAgreementsAdminUsersVisibility(
                user.userId,
                user.seesUserIds ? user.seesUserIds.split(',').map(x => parseInt(x, 10)) : undefined
            );
        }
    };
}

export function useDisableUser() {
    const updateFromFetch = useEngageUsersStore(state => state.updateDataFromFetch);
    const setSaving = useEngageUsersStore(state => state.setSaving);
    const setError = useEngageUsersStore(state => state.setError);

    return user => {
        setSaving();
        return apiDisableUser(user)
            .then(response => {
                updateFromFetch([], false, [user]);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
