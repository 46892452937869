import React from 'react';
import { HashLoader } from 'react-spinners';
// import { Alert, Button } from 'reactstrap';

const ShowLoadingSpinner = props =>
    props.isLoading ? (
        <div>
            <div className="load-center">
                <HashLoader
                    color={props.color || '#123abc'}
                    loading={props.isLoading}
                    size={props.size ? props.size : 40}
                />
            </div>
            {props.title ? (
                <div className="load-center mt-4">
                    <div>{props.title}</div>
                </div>
            ) : (
                ''
            )}
        </div>
    ) : null;

export default ShowLoadingSpinner;
