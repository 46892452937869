import React from 'react';
import { useAddendumRulesStore } from '../Provider/useAddendumRulesStore';
import addendumRulesDef from '../addendumRulesDef';
import BaseTable from '../../common/BaseTable/BaseTable';
import { useAddendaStore } from '../Provider/useAddendaStore';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import MasterTermVersionChanges from '../../MasterTermVersion/Components/MasterTermVersionChanges';
import SimpleCard from '../../common/Card/SimpleCard';

function MasterTermsVersionChanges() {
    const masterTermVersionId = useAddendaStore(state => state.addendum?.masterTermVersionId);
    const masterTermVersions = useMasterTermVersionsStore(state => state.data);
    const version = masterTermVersions.find(x => x.masterTermVersionId === masterTermVersionId);

    return version.changesDescriptions ? (
        <SimpleCard marginClass="mb-0" style={{ marginTop: '1px' }} title="Changes from previous Terms Matrix">
            <div className="italic mb-3">
                Note if moving an agreement more than 1 version, more changes may be applied than are shown here
            </div>
            <MasterTermVersionChanges changes={version.changesDescriptions} />
        </SimpleCard>
    ) : null;
}

export default function AddendumRulesTable({ columns, canEditRules }) {
    const rules = useAddendumRulesStore(state => state.data);
    const openModal = useAddendumRulesStore(state => state.openModal);

    return (
        <div>
            {rules.length ? (
                <div style={{ height: 400, width: '100%' }}>
                    <BaseTable
                        cardTitle="Rules"
                        className="addendum-rules"
                        columns={columns}
                        data={rules}
                        rowKey={addendumRulesDef.id.field}
                        tableSizeStyle={{ height: 'calc(100vh - 197px)', width: '100%' }}
                        titleContentRight={
                            <div>
                                <button
                                    className="btn btn-primary text-white mr-2"
                                    disabled={!canEditRules}
                                    onClick={() => openModal()}
                                >
                                    <i className="fa fa-plus mr-2" />
                                    Add Rule
                                </button>
                            </div>
                        }
                    />
                </div>
            ) : null}

            <MasterTermsVersionChanges />
        </div>
    );
}
