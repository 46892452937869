import React from 'react';

export default function MasterTermVersionChanges({ changes }) {
    return (changes || []).map(({ key, value: changeList }) => (
        <div key={key}>
            <strong>{key}</strong>
            <ul>
                <div>
                    {changeList.map(change => (
                        <li key={change}>{change}</li>
                    ))}
                </div>
            </ul>
        </div>
    ));
}
