import React, { useContext } from 'react';
import { isPropertyHasError, isPropertySyncing } from '../../../reducers/Api/helper';
import SelectInlineChoicelist from '../../common/InlineEdit/SelectInlineChoicelist';
import PersonDetails from '../../Person/Components/PersonDetails';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';
import { MeetingContext } from '../Provider/MeetingProvider';

const mapPeopleToSelectOptions = people =>
    people.map(x => ({ key: x.personId.toString(), value: `${x.firstName} ${x.lastName}` }));

export default ({ hideIdField = false }) => {
    const { meeting } = useContext(MeetingContext);
    const { isLoading, companyId, people } = useContext(CompanyPeopleContext);

    const contact = people.find(x => meeting.person && x.personId === meeting.person.id);

    return contact ? (
        <PersonDetails
            key={contact.personId}
            title={'Contact'}
            person={contact}
            companyId={companyId}
            isLoading={isLoading}
            buttons={[]}
        >
            {hideIdField ? null : (
                <SelectInlineChoicelist
                    options={mapPeopleToSelectOptions(people)}
                    value={(meeting.person && meeting.person.id && meeting.person.id.toString()) || ''}
                    objectId={meeting.meetingId}
                    propertyName={'personId'}
                    stack
                    required
                    isSyncing={isPropertySyncing(meeting, 'personId')}
                    isError={isPropertyHasError(meeting, 'personId')}
                />
            )}
        </PersonDetails>
    ) : null;
};
