import React from 'react';
import { connect } from 'react-redux';
import { isAnyLoading } from '../../../reducers/Rest/loadingStates';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import UpdatePasswordForm from '../../common/CommonForms/UpdatePasswordForm';

class ResetPasswordForm extends React.Component {


    render() {
        const { token, sendResetPassword, loadingStates, className } = this.props;

        if (isAnyLoading(loadingStates)) {
            return <ShowLoadingSpinner isLoading color="#fff" />;
        }

        return (
            <UpdatePasswordForm
                class={className}
                formText={'Please enter a new password.'}
                buttonText={'Reset Password'}
                token={token}
                sendResetPassword={sendResetPassword}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates
});

export default connect(
    mapStateToProps
)(ResetPasswordForm);
