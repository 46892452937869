import { Handle } from 'react-flow-renderer';
import React from 'react';

export const StatusCustomNodeComponent = ({ data }) => {
    return (
        <div
            className="bg-white text-black"
            style={{
                borderRadius: '0.25rem',
                padding: '1rem',
                borderColor: 'black',
                borderStyle: 'solid',
                borderWidth: 'thin',
                width: 150,
                textAlign: 'center'
            }}
        >
            <div>{data.label}</div>
            <Handle id="a" position="left" style={{ borderRadius: 0 }} type="target" />
            <Handle id="b" position="right" style={{ borderRadius: 0 }} type="target" />
            <Handle id="a" position="bottom" style={{ borderRadius: 0 }} type="source" />
        </div>
    );
};
