import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { getAxiosPostRequest } from '../apiHelpers';

export const API_MEETING_EMAIL = 'API_MEETING_EMAIL';

export interface IMeetingEmail {
	userId: number;
	to: string;
	cc: string;
	bcc: string;
	from: string;
	subject: string;
	body: string;
}

export const apiMeetingEmail = (meetingId: string, email: IMeetingEmail, onSuccess: () => void = () => { }, onFail: () => void = () => { }) => async (
	dispatch: any,
	getState: () => any
) => {
	const { user } = getState();
	let to, cc, bcc, fromAddress;
	if (process.env.REACT_APP_EMAIL_OVERRIDE) {
		to = [process.env.REACT_APP_EMAIL_OVERRIDE];
		cc = [process.env.REACT_APP_EMAIL_OVERRIDE];
		bcc = [process.env.REACT_APP_EMAIL_OVERRIDE];
		fromAddress = 'it@roiltd.co.uk';
	} else {
		to = email.to.split(',').map(x => x.trim());
		cc = email.cc.split(',').map(x => x.trim());
		bcc = email.bcc.split(',').map(x => x.trim());
		fromAddress = email.from;
	}
	const data = {
		userId: user.userId,
		to,
		cc,
		bcc,
		fromAddress,
		fromName: user.descriptor,
		subject: email.subject,
		body: email.body,
	};

	axios(getAxiosPostRequest(['meeting', meetingId, 'email'], undefined, data) as any)
		.then(({ data }) => {
			if (onSuccess) onSuccess();
			return data;
		})
		.catch(err => {
			if (onFail) onFail();
			if (getState().user.userId > -1) {
				toastr.error('Error sending mail', `Response ${err.message}`);
			}
		});
};
