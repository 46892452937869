import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import { useAddendumRulesStore } from '../Provider/useAddendumRulesStore';
import AddendumRuleForm from '../Forms/AddendumRuleForm';

export default function AddendumRuleModal() {
    const isOpen = useAddendumRulesStore(state => state.modalOpen);
    const selectedRule = useAddendumRulesStore(state => state.selected);
    const close = useAddendumRulesStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>{selectedRule?.name ?? 'Add Rule'}</AppModalHeader>
                <ModalBody>
                    <AddendumRuleForm />
                </ModalBody>
            </Modal>
        </>
    );
}
