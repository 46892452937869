import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { apiProcessAgreementVersionChangePost } from '../../../actions/Api/Agreement/apiAgreement';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { PreventClickThrough } from '../../common/Table/PreventClickThrough';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';
import { useAgreementAuditStore } from '../Provider/useAgreementAuditStore';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { useRevisionsStore } from '../Provider/useRevisionsStore';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';

/**
 * Obsolete from BE-992
 * @param {any} param0
 */
export default function AgreementProcessVersion({ className }) {
    const [processing, setProcessing] = useState(false);
    const agreementId = useAgreementsStore(state => state.agreement?.agreementId);
    const agreementStatus = useAgreementsStore(state => state.agreement?.status);
    const nextMasterTermVersionId = useAgreementsStore(state => state.agreement?.nextMasterTermVersionId);

    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);
    const updateMasterTermsFromFetch = useMasterTermStore(state => state.updateDataFromFetch);
    const updateMasterTermVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const updateRevisionsFromFetch = useRevisionsStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    function processVersion(e) {
        PreventClickThrough(e);
        setProcessing(true);

        dispatch(apiProcessAgreementVersionChangePost(agreementId))
            .then(response => {
                setProcessing(false);

                updateMasterTermsFromFetch(response.masterTerms || [], true);

                const bmwTerms = (response.agreementTerms || []).filter(term => term.brand === 'BMW');
                const miniTerms = (response.agreementTerms || []).filter(term => term.brand === 'MINI');
                setBMWTerms(bmwTerms);
                setMiniTerms(miniTerms);

                updateMasterTermVersionsFromFetch(response.masterTermVersions || [], true);
                updateRevisionsFromFetch(response.agreementRevisions || []);
                updateAgreementsFromFetch(response.agreements || []);
                updateAuditsFromFetch(response.agreementHeaderAudits || []);
            })
            .catch(() => {
                setProcessing(false);
            });
    }

    if (agreementStatus !== 'LIVE' || !nextMasterTermVersionId) return null;

    return (
        <Button className={className} color="info" onClick={processVersion}>
            {!processing ? <>Process Version Change</> : <ShowLoadingSpinner isLoading size={18} />}
        </Button>
    );
}
