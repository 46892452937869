export default [
    {
        name: 'Fleet',
        fields: [
            {
                label: 'Total Fleet Size',
                type: 'not editable',
                field: 'fleetSize'
            },
            {
                label: 'Fuel Policy',
                type: 'choiceList',
                multiple: true,
                field: 'fuelPolicy',
                choiceList: 'fleetprofileFuelPolicy'
            },
            {
                label: 'Fleet Policy',
                type: 'choiceList',
                multiple: true,
                field: 'fleetPolicy',
                choiceList: 'fleetprofileFleetPolicy'
            },
            {
                label: 'Manufacturer Tender Date',
                type: 'date',
                field: 'dateOfTender'
            },
            {
                label: 'Company Car Funders',
                type: 'choiceList',
                multiple: true,
                field: 'currentFunders',
                choiceList: 'fleetprofileCurrentFunder'
            },
            {
                label: 'Salary Sacrifice Funders',
                type: 'choiceList',
                multiple: true,
                field: 'salarySacrificeFunders',
                choiceList: 'fleetprofileCurrentFunder'
            },
            {
                label:'Leasing Company Tender Date',
                type:'date',
                field: 'leasingCompanyTenderDate'
            },
            {
                label:'Fleet Management Company',
                type: 'choiceList',
                field: 'managementCompany',
                choiceList: 'fleetprofileManagementCompany'
            },
            {
                label: 'Number of Salary Sacrifice',
                type: 'number',
                field: 'numberOfSalarySacrifice'
            },
            {
                label: 'Active Salary Sacrifice',
                type: 'number',
                field: 'activeSalarySacrifice'
            },
            {
                label: 'Number of Grey Fleet',
                type: 'number',
                field: 'numberOfGreyFleet'
            },
            {
                label: 'Current Year Renewals',
                type: 'number',
                field: 'currentYearRenewals'
            },
            {
                label: 'Current Year +1 Renewals',
                type: 'number',
                field: 'currentYear1Renewals'
            },
            {
                label: 'Current Year +2 Renewals',
                type: 'number',
                field: 'currentYear2Renewals'
            },
            {
                label: 'Current Year +3 Renewals',
                type: 'number',
                field: 'currentYear3Renewals'
            },
            {
                label: 'Total Premium Fleet',
                type: 'not editable',
                field: 'numberOfPremium'
            },
            {
                label: 'C02 Cap',
                type: 'number',
                field: 'cO2Cap'
            },
            {
                label: 'Supplying Retailer',
                type: 'choiceList',
                multiple: true,
                field: 'supplyingRetailer',
                choiceList: 'fleetprofileSupplyingRetailer'
            },
            // {
            //     label: 'CO₂ Banding',
            //     type: 'choiceList',
            //     multiple: false,
            //     field: 'co2Banding',
            //     choiceList: 'fleetprofileCo2Banding'
            // }
        ]
    },
    {
        name: 'BMW Group Comparable Cars',
        fields: [
            {
                label: 'Total BMW Fleet',
                type: 'number',
                field: 'numberOfBmw'
            },
            {
                label: 'Total MINI Fleet',
                type: 'number',
                field: 'numberOfMini'
            },
            {
                label: 'Total Audi Fleet',
                type: 'number',
                field: 'numberOfAudi'
            },
            {
                label: 'Total Mercedes-Benz Fleet',
                type: 'number',
                field: 'numberOfMercedesBenz'
            },
            {
                label: 'Total Jaguar Fleet',
                type: 'number',
                field: 'numberOfJaguar'
            },
            {
                label: 'Total Land Rover Fleet',
                type: 'number',
                field: 'numberOfLandRover'
            },
            {
                label: 'Total Volvo Fleet',
                type: 'number',
                field: 'numberOfVolvo'
            },
            {
                label: 'Total Lexus Fleet',
                type: 'number',
                field: 'totalLexus'
            },
            {
                label: 'Total Tesla Fleet',
                type: 'number',
                field: 'totalTesla'
            },
            {
                label: 'Total Polestar Fleet',
                type: 'number',
                field: 'totalPolestar'
            },
            {
                label: 'Total Genesis Fleet',
                type: 'number',
                field: 'totalGenesis'
            },
            {
                label: 'Total Ford Fleet',
                type: 'number',
                field: 'numberOfFord'
            },
            {
                label: 'Total Hyundai Fleet',
                type: 'number',
                field: 'totalHyundai'
            },
            {
                label: 'Total Kia Fleet',
                type: 'number',
                field: 'totalKia'
            },
            {
                label: 'Total Nissan Fleet',
                type: 'number',
                field: 'numberOfNissan'
            },
            {
                label: 'Total SEAT Fleet',
                type: 'number',
                field: 'numberOfSeat'
            },
            {
                label: 'Total Skoda Fleet',
                type: 'number',
                field: 'totalSkoda'
            },
            {
                label: 'Total Toyota Fleet',
                type: 'number',
                field: 'totalToyota'
            },
            {
                label: 'Total Vauxhall Fleet',
                type: 'number',
                field: 'totalVauxhall'
            },
            {
                label: 'Total VW Fleet',
                type: 'number',
                field: 'numberOfVw'
            },
            {
                label: 'Total Other Fleet',
                type: 'number',
                field: 'numberOfOtherFleet'
            }
        ]
    },
    {
        name: 'TDP',
        fields: [
            {
                label: 'Account Strategy',
                type: 'text',
                field: 'accountStrategy'
            },
            {
                label: 'Customer TDP Status',
                readonly: true,
                type: 'choiceList',
                multiple: false,
                field: 'customerStatus',
                choiceList: 'companyCustomerStatus'
            },

            {
                label: 'BMW Forecast',
                type: 'number',
                field: 'bmwForecast'
            },
            {
                label: 'MINI Forecast',
                type: 'number',
                field: 'miniForecast'
            },
            {
                label: 'EV on Policy',
                type: 'choiceList',
                multiple: false,
                field: 'evOnPolicy',
                choiceList: 'fleetprofileEvOnPolicy'
            },
            {
                label: 'PHEV on Policy',
                type: 'choiceList',
                multiple: false,
                field: 'phevOnPolicy',
                choiceList: 'fleetprofilePhevOnPolicy'
            },
            {
                label: 'MINI on Policy',
                type: 'choiceList',
                multiple: false,
                field: 'miniOnPolicy',
                choiceList: 'fleetprofileEvOnPolicy'
            },
            {
                label: 'Number of Employees',
                type: 'number',
                field: 'numberOfEmployees'
            },
            {
                label: 'BMW Orders',
                type: 'number',
                field: 'bmwOrders',
                readonly: true
            },
            {
                label: 'MINI Orders',
                type: 'number',
                field: 'miniOrders',
                readonly: true
            },
            {
                label: 'BMW Regs',
                type: 'number',
                field: 'bmwRegs',
                readonly: true
            },
            {
                label: 'MINI Regs',
                type: 'number',
                field: 'miniRegs',
                readonly: true
            }
        ]
    },
    {
        name: 'Passenger Cars',
        fields: [
            {
                label: 'Number of Cars',
                type: 'number',
                field: 'numberOfCars'
            },
            {
                label: 'Number of LCV',
                type: 'number',
                field: 'numberOfLCV'
            },
            {
                label: 'Number Of Plugin Cars',
                type: 'number',
                field: 'numberOfPluginCars'
            },
            {
                label: 'Number Of Electric Cars',
                type: 'number',
                field: 'numberOfElectricCars'
            },
            {
                label: 'Other Manufacturers on Fleet',
                type: 'choiceList',
                multiple: true,
                field: 'otherManufacturers',
                choiceList: 'fleetprofileOtherManufacturers'
            },
            {
                label: 'Change Cycle (Months)',
                type: 'choiceList',
                multiple: true,
                field: 'changeCycleMonths',
                choiceList: 'fleetprofileChangeCycleMonths'
            },
            {
                label: 'Change Cycle (Miles)',
                type: 'text',
                field: 'changeCycleMiles',
            }
        ]
    },
    {
        name: 'Fleet Profile Notes',
        fields: [
            {
                label: 'Fleet Profile Notes',
                type: 'text',
                field: 'notes'
            }
        ]
    }
];
