import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import MeetingVisitActionsProvider from './MeetingVisitActionsProvider';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';

export const MeetingContext = React.createContext({
    profile: {},
    meeting: {}
});

class MeetingProvider extends React.Component {
    getStateAndHelpers({ meetingId, loadingStates, resources, meeting, visitActions, saveMeeting }) {
        return {
            meetingId,
            loadingStates,
            resources,
            meeting,
            saveMeeting,
            visitActions
        };
    }

    render() {
        const { children, meeting, meetingId } = this.props;
        return (
            <MeetingContext.Provider value={this.getStateAndHelpers(this.props)}>
                {meeting ? (
                    <MeetingVisitActionsProvider meetingId={meetingId}>
                        <CompanyProvider companyId={meeting.companyId}>{children}</CompanyProvider>
                    </MeetingVisitActionsProvider>
                ) : null}
            </MeetingContext.Provider>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.loadingStates,
            state => state.resources,
            (state, { meetingId }) => state.meetings.find(meeting => meeting.meetingId === meetingId)
        ],
        (loadingStates, resources, meeting) => ({
            loadingStates,
            resources,
            meeting
        })
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    saveMeeting: meeting => dispatch(apiMeetingPost(meeting))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(MeetingProvider);
