import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import { apiUploadCapCodesPost } from '../../../actions/Api/Agreement/apiAgreement';
import { useCapCodesStore } from '../Provider/useCapCodesStore';
import storeStates from '../../Agreement/Provider/storeStates';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { AppModalHeader } from '../../common/AppModalHeader';
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';

const Update = ({ update: { createdDate, updatedDate, capId, modelDescription } }) => (
    <li>
        <strong>{moment(createdDate).isSame(moment(updatedDate), 'minute') ? 'Created' : 'Updated'}</strong>
        <span className="ml-2">{`${capId} ${modelDescription}`}</span>
    </li>
);

export default function CapCodesUpload() {
    const updateCapCodesFromFetch = useCapCodesStore(state => state.updateDataFromFetch);
    const setSaving = useCapCodesStore(state => state.setSaving);
    const setError = useCapCodesStore(state => state.setError);
    const saving = useCapCodesStore(state => state.storeState) === storeStates.saving;
    const dispatch = useDispatch();
    const [updates, setUpdates] = useState();

    const onDrop = ([file]) => {
        const reader = new FileReader();
        reader.onload = () => {
            const contentAsBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');

            setSaving();
            dispatch(apiUploadCapCodesPost({ excelFile: contentAsBase64 }))
                .then(response => {
                    updateCapCodesFromFetch(response, false);
                    setUpdates(response);
                })
                .catch(err => setError(err));
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.readAsDataURL(file);
    };

    const close = () => setUpdates(undefined);

    return (
        <>
            <Modal className="modal-md" isOpen={updates} toggle={close}>
                <AppModalHeader toggle={close}>Changes</AppModalHeader>
                <ModalBody>
                    {!updates ? null : updates.length === 0 ? (
                        'No Cap Codes Updated'
                    ) : (
                        <ul>
                            {updates.map(update => (
                                <Update key={update.capDataId} update={update} />
                            ))}
                        </ul>
                    )}
                </ModalBody>
            </Modal>
            <Dropzone
                accept=".xlsx"
                className="button btn btn-secondary"
                disabled={saving}
                multiple={false}
                onDrop={onDrop}
            >
                {saving ? <ShowLoadingSpinner isLoading size={18} /> : 'Upload Cap Codes'}
            </Dropzone>
        </>
    );
}
