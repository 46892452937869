import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import PageSection from '../../common/PageSection';
import Layout3Columns, { Column1, Column2And3 } from '../../Dashboard/Components/Layout3Columns';
import { useAgreementsStore } from '../../Agreement/Provider/useAgreementsStore';
import { AgreementsFilter } from '../Filter/AgreementsFilter';
import AgreementsTable from '../Table/AgreementsTable';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { useIvsCodesStore } from '../../IvsCode/Provider/IvsCodesProvider';
import { useMasterTermVersionsStore } from '../../MasterTermVersion/Provider/useMasterTermVersionsStore';

export default function AgreementsPageContent() {
    const { goBack, push } = useHistory();
    const { search } = useLocation();
    const updateAgreementsFromFetch = useAgreementsStore(state => state.updateDataFromFetch);
    const updateIvsCodesFromFetch = useIvsCodesStore(state => state.updateDataFromFetch);
    const updateVersionsFromFetch = useMasterTermVersionsStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();

    //fetch page
    useEffect(() => {
        if (!search) push(`/agreement?${queryString.stringify({ status: 'DRFT' })}`);
        else {
            const filter = queryString.parse(search);
            const {
                activeSupportBonus,
                masterTermVersionId,
                status,
                createdBy,
                salesChannel,
                agreementType,
                reference,
                termSeries,
                termDescription,
                ivsCodeId,
                termDiscountType,
                termDiscount,
                recent,
                expiring,
                createdBefore,
                createdAfter,
                rebate,
                hasClauses,
                inactiveSignatories,
                signatoriesRequired
            } = filter;

            const filterClean = {
                activeSupportBonus:
                    activeSupportBonus === 'true' ? true : activeSupportBonus === 'false' ? false : undefined,
                masterTermVersionId:
                    masterTermVersionId && masterTermVersionId !== '' ? masterTermVersionId.split(',') : undefined,
                statuses: status && status !== '' ? status.split(',') : undefined,
                salesChannels: salesChannel && salesChannel !== '' ? salesChannel?.split(',') : undefined,
                agreementTypes: agreementType && agreementType !== '' ? agreementType?.split(',') : undefined,
                reference: reference && reference !== '' ? reference : undefined,
                termSeries,
                termDescription,
                ivsCodeId,
                termDiscountType,
                termDiscount,
                createdBy: createdBy && createdBy !== '' ? createdBy.split(',') : undefined,
                rebate: rebate === 'true' ? true : rebate === 'false' ? false : undefined,
                recent: recent === 'true' ? true : recent === 'false' ? false : undefined,
                expiring: expiring === 'true' ? true : expiring === 'false' ? false : undefined,
                createdBefore,
                createdAfter,
                hasClauses: hasClauses === 'true' ? true : hasClauses === 'false' ? false : undefined,
                inactiveSignatories:
                    inactiveSignatories === 'true' ? true : inactiveSignatories === 'false' ? false : undefined,
                signatoriesRequired:
                    signatoriesRequired === 'true' ? true : signatoriesRequired === 'false' ? false : undefined
            };

            dispatch(apiPage('agreements', undefined, filterClean))
                .then(response => {
                    updateAgreementsFromFetch(response.agreements || [], true);
                    updateIvsCodesFromFetch(response.ivsCodes || [], true);
                    updateVersionsFromFetch(response.masterTermVersions || [], true);
                })
                .catch(err => console.error('error', err));
        }
    }, [updateAgreementsFromFetch, updateIvsCodesFromFetch, updateVersionsFromFetch, dispatch, push, search]);

    return (
        <PageSection onBackClicked={goBack} title="Agreements">
            <Layout3Columns>
                <Column1>
                    <AgreementsFilter />
                </Column1>
                <Column2And3>
                    <AgreementsTable />
                </Column2And3>
            </Layout3Columns>
        </PageSection>
    );
}
