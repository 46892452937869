import React from 'react';
import { isPropertyHasError, isPropertySyncing } from '../../reducers/Api/helper';
import EditFieldDisplayValue from '../common/EditField/EditFieldDisplayValue';
import CaptionBadge from '../common/FilteredTable/CaptionBadge';
import _ from 'lodash';
import EditTextField from '../common/EditField/EditTextField';
import SelectInlineChoicelist from '../common/InlineEdit/SelectInlineChoicelist';
import MonthDayNumbersSelector from '../common/InlineEdit/MonthDayNumbersSelector';
import { connect } from 'react-redux';
import { apiLeasingProfileEdit } from '../../actions/Api/LeasingProfile/apiLeasingProfileEdit';

const LeasingProfileItem = (props) => {
    const { label, field, type, multiple, readonly, choiceList, resources, data: leasingProfile, max } = props;
    let value = _.get(leasingProfile, field, '');
    value = value || value === 0 ? value : '';

    const saveMonthDay = (values) => {
        const { data: profile, save } = props;
        const updatedLeasingProfile = _.cloneDeep(profile);

        updatedLeasingProfile.registrationCutOffJanuary = values[0]?.toString();
        updatedLeasingProfile.registrationCutOffFebruary = values[1]?.toString();
        updatedLeasingProfile.registrationCutOffMarch = values[2]?.toString();
        updatedLeasingProfile.registrationCutOffApril = values[3]?.toString();
        updatedLeasingProfile.registrationCutOffMay = values[4]?.toString();
        updatedLeasingProfile.registrationCutOffJune = values[5]?.toString();
        updatedLeasingProfile.registrationCutOffJuly = values[6]?.toString();
        updatedLeasingProfile.registrationCutOffAugust = values[7]?.toString();
        updatedLeasingProfile.registrationCutOffSeptember = values[8]?.toString();
        updatedLeasingProfile.registrationCutOffOctober = values[9]?.toString();
        updatedLeasingProfile.registrationCutOffNovember = values[10]?.toString();
        updatedLeasingProfile.registrationCutOffDecember = values[11]?.toString();

        save(updatedLeasingProfile);
    };

    const save = updatedValues => {
        const {
            data: { schema, ...profile }, save
        } = props;

        const updatedLeasingProfile = _.cloneDeep(profile);

        Object.keys(updatedValues).forEach(x => {
            updatedLeasingProfile[x] = updatedValues[x];
        });
        schema.forEach(section => {
            section.fields
                .filter(field => field.type === 'sum')
                .forEach(sumField => {
                    let newSum = 0;
                    sumField.fields.forEach(x => {
                        newSum += updatedLeasingProfile[x];
                    });
                    updatedLeasingProfile[sumField.field] = newSum;
                });
        });

        save(updatedLeasingProfile);
    };

    const saveNew = (field, value, type) => {
        const { data: profile, save } = props;
        const updatedLeasingProfile = _.cloneDeep(profile);

        const originalValue = profile[field];

        if (type === 'number') {
            value = parseInt(value, 10);
        }

        if (originalValue !== value) {
            updatedLeasingProfile[field] = value;
            save(updatedLeasingProfile);
        }
    };
    const choiceListLabelMapping = name => {
        switch (props.choiceListLabelMapping) {
            case 'trimMonths':
                return value => (value.split(' ').pop() === 'Months' ? value.substring(0, value.length - 6) : value);
            default:
                return x => x;
        }
    };

    const parseData = () => {
        const { data: leasingProfile } = props;

        return [
            leasingProfile.registrationCutOffJanuary === null ? null : Number(leasingProfile.registrationCutOffJanuary),
            leasingProfile.registrationCutOffFebruary === null ? null : Number(leasingProfile.registrationCutOffFebruary),
            leasingProfile.registrationCutOffMarch === null ? null : Number(leasingProfile.registrationCutOffMarch),
            leasingProfile.registrationCutOffApril === null ? null : Number(leasingProfile.registrationCutOffApril),
            leasingProfile.registrationCutOffMay === null ? null : Number(leasingProfile.registrationCutOffMay),
            leasingProfile.registrationCutOffJune === null ? null : Number(leasingProfile.registrationCutOffJune),
            leasingProfile.registrationCutOffJuly === null ? null : Number(leasingProfile.registrationCutOffJuly),
            leasingProfile.registrationCutOffAugust === null ? null : Number(leasingProfile.registrationCutOffAugust),
            leasingProfile.registrationCutOffSeptember === null ? null : Number(leasingProfile.registrationCutOffSeptember),
            leasingProfile.registrationCutOffOctober === null ? null : Number(leasingProfile.registrationCutOffOctober),
            leasingProfile.registrationCutOffNovember === null ? null : Number(leasingProfile.registrationCutOffNovember),
            leasingProfile.registrationCutOffDecember === null ? null : Number(leasingProfile.registrationCutOffDecember)
        ]
    }

    const chooseElement = () => {

        if (readonly) {
            const displayValue = type === 'choiceList'
                ? value
                    .toString()
                    .split(',')
                    .filter(x => x.length > 0)
                    .map(x => <CaptionBadge caption={x} choiceList={choiceList} key={x} resources={resources} />)
                : value;

            return (
                <EditFieldDisplayValue
                    field={field}
                    id={leasingProfile.fleetProfileId}
                    isError={isPropertyHasError(leasingProfile, field)}
                    isSyncing={isPropertySyncing(leasingProfile, field)}
                    show
                    title={label}
                    type={type === 'sum' ? 'number' : 'text'}
                    value={displayValue} />
            );
        }
        const lengthLimit = max ? { max } : {};
        switch (type) {
            case 'text':
            case 'number':
                return (
                    <EditTextField
                        field={field}
                        id={leasingProfile.fleetProfileId}
                        isError={isPropertyHasError(leasingProfile, field)}
                        isSyncing={isPropertySyncing(leasingProfile, field)}
                        onChanged={save}
                        title={label}
                        type={type}
                        value={value}
                        {...lengthLimit}
                    />
                );
            case 'choiceList':
                return (
                    <SelectInlineChoicelist
                        choiceListName={choiceList}
                        isError={isPropertyHasError(leasingProfile, field)}
                        isSyncing={isPropertySyncing(leasingProfile, field)}
                        mapChoiceValues={choiceListLabelMapping()}
                        multiple={multiple}
                        objectId={leasingProfile.fleetProfileId}
                        propertyName={field}
                        save={saveNew}
                        title={label}
                        value={value} />
                );
            case 'dayOfMonth':
                return (
                    <MonthDayNumbersSelector
                        onChange={saveMonthDay}
                        value={parseData()}
                    />
                );
            default:
                return (
                    <EditFieldDisplayValue
                        isError={isPropertyHasError(leasingProfile, field)}
                        isSyncing={isPropertySyncing(leasingProfile, field)}
                        onClick={() => { }}
                        show={false}
                        title={label}
                        value={value} />
                );
        }
    };
    return <li className="list-group-item" style={{ minHeight: '45px' }}>{chooseElement()}</li>;
};

const mapStateToProps = state => {
    return { resources: state.resources };
};

const mapDispatchToProps = dispatch => ({
    save: (profile, cancel) => dispatch(apiLeasingProfileEdit(profile))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeasingProfileItem);