import React from 'react';
import { CollapseTableRowButton } from '../Buttons/TableButtons';
import ShowLoadingSpinner from '../ShowLoadingSpinner';

export const SubSectionTitle = props => {
    const { title, number, className, collapseName, toggleCollapsed, collapsed, buttons, isLoading } = props;

    return (
        <div className={`relative d-grid grid-cols-2 my-2 ${className ? className : ''}`}>
            <div className="flex items-center pt-1">
                {collapseName ? (
                    <CollapseTableRowButton
                        collapsed={collapsed}
                        onClick={() => toggleCollapsed(collapseName, !collapsed)}
                    />
                ) : (
                    ''
                )}
                {number && <span className="mr-2 logo-font form-subsection-number">{number}</span>}
                <h5 className={`d-inline logo-font gradient mb-2 flex-item ${className} ${collapseName ? 'ml-2' : ''}`}>
                    {title}
                </h5>
            </div>
            <div className="grid-js-end grid-js-bottom mb-2">
                {isLoading ? <ShowLoadingSpinner isLoading size={20} /> : buttons}
            </div>
        </div>
    );
};
