import { useDispatch } from 'react-redux';
import { useAgreementsStore } from './useAgreementsStore';
import { useAgreementAuditStore } from './useAgreementAuditStore';
import { apiAgreementTermsPost, apiAgreementTermsReset } from '../../../actions/Api/Agreement/apiAgreement';
import { useBMWStore, useMiniStore } from './useTermsStore';
import { setBmwAndMiniTerms } from './AgreementProvider';

export function useUpdateTerms() {
    const dispatch = useDispatch();
    const agreement = useAgreementsStore(state => state.agreement);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);

    return terms =>
        dispatch(apiAgreementTermsPost(agreement.agreementId, terms)).then(response => {
            updateAuditsFromFetch(response.agreementHeaderAudits || []);
            return response.agreementTerms || [];
        });
}

export function useResetTerms() {
    const dispatch = useDispatch();
    const agreement = useAgreementsStore(state => state.agreement);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);

    const setBMWTerms = useBMWStore(state => state.setData);
    const setMiniTerms = useMiniStore(state => state.setData);

    return () =>
        dispatch(apiAgreementTermsReset(agreement.agreementId))
            .then(response => {
                updateAuditsFromFetch(response.agreementHeaderAudits || []);
                return response.agreementTerms || [];
            })
            .then(rows => {
                setBmwAndMiniTerms(rows, setBMWTerms, setMiniTerms);
                return rows;
            });
}
