import React from 'react';
import * as Yup from 'yup';
import { FORM_NAMES } from '../../../actions/formNames';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import ReactiveForm from '../../common/ReactiveForm/index';
import agreementDef from '../agreementDef';
import storeStates from '../Provider/storeStates';
import { useAgreementsStore, useUpliftNeuAddendumAgreement } from '../Provider/useAgreementsStore';

const FORM_NAME = FORM_NAMES.REJECT_AGREEMENT;

const validationSchema = Yup.object().shape({
    matrixBanding: Yup.string().required('Matrix banding is required.')
});

export default function AgreementUpliftForm() {
    const agreementId = useAgreementsStore(state => state.agreement?.agreementId);
    const agreementMatrixBanding = useAgreementsStore(state => state.agreement?.matrixBanding);
    const cancel = useAgreementsStore(state => state.closeModal);
    const saving = useAgreementsStore(state => state.storeState) === storeStates.saving;
    const upliftAgreement = useUpliftNeuAddendumAgreement(agreementId);
    const matrixBandingChoiceList = useChoiceList('matrixBanding');

    return (
        <ReactiveForm
            cancel={cancel}
            formName={FORM_NAME}
            handleSubmit={upliftAgreement}
            isSaving={saving}
            validationSchema={validationSchema}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Choicelist
                    columnDef={agreementDef.matrixBanding}
                    options={matrixBandingChoiceList.filter(
                        f =>
                            f.filterOnChoiceList === 'salesChannel' &&
                            f.filterOnValue === 'NEU' &&
                            f.key !== agreementMatrixBanding
                    )}
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
