import React from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import engageUserDef from '../engageUserDef';
import BaseTable from '../../common/BaseTable/BaseTable';
import engageAuthorizationLevelDef from '../engageAuthorizationLevelDef';
import { useEngageAuthorizationLevelStore } from '../Provider/useEngageAuthorizationLevelStore';
import useDimensions from '../../common/Hooks/useDimensions';

export default function EngageAuthorizationLevelTable() {
    const data = useEngageAuthorizationLevelStore(state => state.data);

    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);

    const [ref, { y }] = useDimensions({ liveMeasure: false });

    return (
        <div ref={ref} style={{ height: `calc(100vh - ${y}px - 1rem`, width: '100%' }}>
            <BaseTable
                cardTitle="Engage Users"
                columns={EngageAuthorizationLevelTable.columns}
                data={data}
                expandAll={true}
                groupBy={[engageUserDef.userType.field]}
                isLoading={isLoading}
                onRowClicked={undefined}
                rowKey={engageUserDef.id.field}
            />
        </div>
    );
}

EngageAuthorizationLevelTable.columns = [
    engageAuthorizationLevelDef.salesChannel,
    engageAuthorizationLevelDef.agreementType,
    engageAuthorizationLevelDef.minSupport,
    engageAuthorizationLevelDef.termsEditable,
    engageAuthorizationLevelDef.termsVisible
];
