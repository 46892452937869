import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import { EmptyCompany, setCompanyLoading } from './company';

const updateCompanies = (state, data) => {
    const newState = _.clone(state);
    //data.viewed = Date.now();
    const index = _.findIndex(newState, x => x.companyId === data.companyId);

    if (index > -1) {
        //data.viewed = newState[index].viewed;
        newState[index] = data;
    } else {
        newState.push(data);
    }
    return newState;
};

const commitFetch = (state, action) => {
    const company = action.payload.data;
    //setCompanyViewed(company, action.meta.viewed);
    return [company, ...state.filter(x => company.companyId !== x.companyId)];
};

const setCompanySyncing = (state, action) => {
    let companyIndex = _.findIndex(state, x => x.companyId === action.data.companyId);
    if (companyIndex < 0) {
        const company = _.cloneDeep(EmptyCompany);
        company.companyId = action.data.companyId;
        company.descriptor = '...';
        state.push(company);
        companyIndex = state.length - 1;
    }

    const newState = _.clone(state);
    setCompanyLoading(newState[companyIndex], true);

    //setCompanyViewed(newState[companyIndex], action.data.viewed);
    return newState;
};

export function companies(state = [], action) {
    switch (action.type) {
        case RESET_REDUCER:
            return [];

        case ENDPOINTS.API.COMPANY_OFFLINE:
            return setCompanySyncing(state, action);

        case ENDPOINTS.API.COMPANY_OFFLINE_COMMIT:
            return commitFetch(state, action);

        case ENDPOINTS.API.COMPANY:
        case ENDPOINTS.API.COMPANY_POST:
            return updateCompanies(state, action.data);
        case ENDPOINTS.API.PAGE:
            return action.data.companyFilter
                ? [
                      ...action.data.companies,
                      ...state.filter(s => !action.data.companies.some(a => a.companyId === s.companyId))
                  ].slice(0, action.data.companies.length > 50 ? action.data.companies.length : 50)
                : state;

        case ENDPOINTS.API.COMPANIES:
            return [...action.data, ...state.filter(s => !action.data.some(a => a.companyId === s.companyId))].slice(
                0,
                action.data.length > 50 ? action.data.length : 50
            );

        case ENDPOINTS.API.COMPANY_POST_ROLLBACK:
            return updateCompanies(state, {
                ...action.data.unmodified,
                syncing: false,
                error: action.payload.message
            });

        case ENDPOINTS.API.ADDRESS_POST:
            const { companyId, updatedAddress } = action;
            const company = state.find(x => x.companyId === companyId);

            const otherAddresses = company.addresses.filter(x => x.addressId !== updatedAddress.addressId);

            return [
                ...state.filter(x => x.companyId !== companyId),
                {
                    ...company,
                    addresses: updatedAddress.isPrimaryAddress
                        ? [...otherAddresses.map(x => ({ ...x, isPrimaryAddress: false })), updatedAddress]
                        : [...otherAddresses, updatedAddress]
                }
            ];
        default:
            return state;
    }
}
