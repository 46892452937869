import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import AgreementTemplateTable from '../Tables/AgreementTemplateTable';
import AgreementTemplateAuditsTable from '../Tables/AgreementTemplateAuditsTable';
import { templateEntity, useAgreementTemplateStore } from '../Provider/useAgreementTemplateStore';
import MissingTemplatesAlert from './MissingTemplatesAlert';
import { TabsContent, TabsGroup, TabsInCard, TabsTabForCard } from '../../common/Tabs';
import { ToolTipProvider } from '../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';
import { useAgreementAuditStore } from '../../Agreement/Provider/useAgreementAuditStore';
import { useDownloadAuditCsv } from '../../Agreement/Table/AgreementAuditsTable';

export default function AgreementTemplatesPageContent() {
    const { goBack } = useHistory();
    const openModal = useAgreementTemplateStore(state => state.openModal);
    const updateTemplatesFromFetch = useAgreementTemplateStore(state => state.updateDataFromFetch);
    const updateAuditsFromFetch = useAgreementAuditStore(state => state.updateDataFromFetch);
    const downloadAuditCsv = useDownloadAuditCsv();
    const dispatch = useDispatch();

    //fetch page
    useEffect(() => {
        dispatch(apiPage('agreement-templates'))
            .then(response => {
                updateTemplatesFromFetch(response.agreementTemplates || [], true);
                updateAuditsFromFetch(response.agreementHeaderAudits || [], true);
            })
            .catch(err => console.error('error', err));
    }, [updateTemplatesFromFetch, updateAuditsFromFetch, dispatch]);

    return (
        <ToolTipProvider>
            <PageSection onBackClicked={goBack} title="Templates" titleExtra={<PageLoadingSpinner />}>
                <TabsInCard
                    buttons={{
                        0: [
                            {
                                id: 0,
                                onClick: () => openModal(),
                                content: 'Add Template',
                                icon: 'fa-plus'
                            }
                        ],
                        4: [downloadAuditCsv(4, 'template')]
                    }}
                >
                    <TabsGroup>
                        <TabsTabForCard>
                            <MissingTemplatesAlert /> Base Templates
                        </TabsTabForCard>
                        <TabsContent>
                            <AgreementTemplateTable />
                        </TabsContent>
                    </TabsGroup>
                    <TabsGroup>
                        <TabsTabForCard>Agreement Templates</TabsTabForCard>
                        <TabsContent>
                            <AgreementTemplateTable type={templateEntity.agreement} />
                        </TabsContent>
                    </TabsGroup>
                    <TabsGroup>
                        <TabsTabForCard>Addendum Templates</TabsTabForCard>
                        <TabsContent>
                            <AgreementTemplateTable type={templateEntity.addendum} />
                        </TabsContent>
                    </TabsGroup>
                    <TabsGroup>
                        <TabsTabForCard>Matrix Templates</TabsTabForCard>
                        <TabsContent>
                            <AgreementTemplateTable type={templateEntity.matrix} />
                        </TabsContent>
                    </TabsGroup>
                    <TabsGroup>
                        <TabsTabForCard>Audit</TabsTabForCard>
                        <TabsContent>
                            <AgreementTemplateAuditsTable />
                        </TabsContent>
                    </TabsGroup>
                </TabsInCard>
            </PageSection>
        </ToolTipProvider>
    );
}
