import React, { useContext, memo } from 'react';
import { connect } from 'react-redux';
import { CompanyContext } from '../Provider/CompanyProvider';
import { DashboardFieldColumnDef } from '../../Dashboard/Components/DashboardField';
import SimpleCard from '../../common/Card/SimpleCard';
import companyDef from '../companyDef';
import { ValidationProvider } from '../../common/FormkControls/ValidationProvider';

function CompanyDetails({ affiliates }) {
    const { company = {} } = useContext(CompanyContext);
    const isLoading = company.loading;
    const parentCompany = affiliates.find(x => x.affiliatedCompanyId === company.companyId);

    return <CompanyDetailsCard company={company} isLoading={isLoading} parentCompany={parentCompany} />;
}

const mapStateToProps = state => ({
    affiliates: state.affiliates
});

export default connect(mapStateToProps)(CompanyDetails);

export const CompanyDetailsCard = memo(({ company, parentCompany, isLoading }) => (
    <ValidationProvider yup={undefined}>
        <SimpleCard isLoading={isLoading} title="Company Information">
            <DashboardFieldColumnDef columnDef={companyDef.name} item={company} />
            {parentCompany && <DashboardFieldColumnDef columnDef={companyDef.affiliatedTo} item={parentCompany} />}
            <DashboardFieldColumnDef columnDef={companyDef.webSite} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.businessPhone} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.salesChannel} item={company} readOnly />
            <DashboardFieldColumnDef columnDef={companyDef.accountManager} item={company} readOnly />
            <DashboardFieldColumnDef columnDef={companyDef.nkamStatus} item={company} readOnly />
            <DashboardFieldColumnDef columnDef={companyDef.tob} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.bconId} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.cin} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.legalEntityType} item={company} readOnly />
            <DashboardFieldColumnDef columnDef={companyDef.registrationNumber} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.internationalAgreement} item={company} />
            {company.internationalAgreement === 'Y' && (
                <>
                    <DashboardFieldColumnDef columnDef={companyDef.internationalBonusType} item={company} />
                    <DashboardFieldColumnDef columnDef={companyDef.internationalBmwBonus} item={company} />
                    <DashboardFieldColumnDef columnDef={companyDef.internationalMiniBonus} item={company} />
                </>
            )
            }
            {company.salesChannel === 'SpecialistEndUser' ? (
                <DashboardFieldColumnDef columnDef={companyDef.bvrlaMember} item={company} />
            ) : null}
            <DashboardFieldColumnDef columnDef={companyDef.lastMeetingDate} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.nextMeetingDate} item={company} />
            <DashboardFieldColumnDef columnDef={companyDef.bmwMatrixBanding} item={company} readOnly />
            <DashboardFieldColumnDef columnDef={companyDef.miniMatrixBanding} item={company} readOnly />
        </SimpleCard>
    </ValidationProvider>
));
