import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { apiCapCodeGet, apiPreviewDraftVersion } from '../../../actions/Api/Agreement/apiAgreement';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';
import { useAgreementsStore } from '../Provider/useAgreementsStore';
import { CompanyContext } from '../../Company/Provider/CompanyProvider';

function getTotalDiscount(terms, masterTermId, ivsCodeId, isLeasing) {
    const term =
        terms.find(x => x.ivsCodeId === ivsCodeId && x.masterTermId === masterTermId) ||
        terms.find(x => x.masterTermId === masterTermId);
    let totalDiscount;

    if (isLeasing)
        totalDiscount = term?.nextBase ?? term?.base;
    else
        totalDiscount = term?.nextTotalDiscount ?? term?.totalDiscount;

    return totalDiscount?.toFixed(2);
}

function masterTermsExpandedToIvsCodesWithDiscount(terms, masterTerms, isLeasing) {
    return masterTerms
        .filter(x => !!x.ivsCodes)
        .flatMap(({ ivsCodes, masterTermId }) =>
            ivsCodes
                ?.map(x => ({
                    ivsCode: x.ivsCode,
                    modelDescription: x.modelDescription,
                    devCode: x.devCode,
                    totalDiscount: getTotalDiscount(terms, masterTermId, x.ivsCodeId, isLeasing)
                }))
                .filter(x => !!x.totalDiscount)
        );
}

function mapData(capCodes, terms, masterTerms, startDate, endDate, company, isLeasing) {
    const tableData = masterTermsExpandedToIvsCodesWithDiscount(terms, masterTerms, isLeasing).flatMap(
        ({ ivsCode, modelDescription, devCode, totalDiscount }) =>
            capCodes
                .filter(cap => cap.ivsCode === ivsCode)
                .map(x => ({
                    modelDescription,
                    capCode: x.capCode,
                    capId: x.capId,
                    secondDescription: x.modelDescription,
                    ivsCode,
                    devCode,
                    totalDiscount
                }))
    );

    return tableData.map(row => [
        row.devCode,
        row.ivsCode, //IVS Code
        row.modelDescription, //Model Description
        row.capCode, //CapCode
        row.capId, //VDR Vehicle Id
        row.secondDescription,
        row.totalDiscount, //Total Discount
        startDate, //Start Date
        endDate, //End Date
        company.cin, //Company CIN Number
        company.name, //Company Name
        company.postcode //Postcode
    ]);
}

function formatDateForFile(date) {
    if (!date) return '';
    const momentDate = moment(date);
    return momentDate.format('DD.MM.YYYY');
}

function useLeasingCsvData(nextMatrix) {
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const agreement = useAgreementsStore(state => state.agreement);
    const bmwTerms = useBMWStore(state => state.dataCopy);
    const miniTerms = useMiniStore(state => state.dataCopy);
    const terms = useMemo(() => [...bmwTerms, ...miniTerms], [bmwTerms, miniTerms]);
    const masterTerms = useMasterTermStore(state => state.data);
    const { company = {} } = useContext(CompanyContext);

    return {
        data,
        getCapCodesAndMakeCsvData: async () => {
            let termsToUse = terms;
            let masterTermsToUse = masterTerms;

            if (nextMatrix) {
                const response = await dispatch(apiPreviewDraftVersion(agreement, true));
                termsToUse = response?.agreementTerms || [];
                masterTermsToUse = response?.masterTerms || [];
            }

            dispatch(apiCapCodeGet(false)).then(capCodes => {
                const mappedData = mapData(
                    capCodes,
                    termsToUse,
                    masterTermsToUse,
                    formatDateForFile(agreement.startDate),
                    formatDateForFile(agreement.endDate),
                    company,
                    agreement.salesChannel === 'LEA'
                );
                setData(mappedData);
            });
        },
        cleanUp: () => setData([])
    };
}

export default function LeasingCsv({ className, disabledReason, toggle, nextMatrix = false }) {
    const isLeasing = useAgreementsStore(state => state.agreement?.salesChannel === 'LEA');
    const { data, getCapCodesAndMakeCsvData, cleanUp } = useLeasingCsvData(nextMatrix);
    const csvRef = useRef();
    const agreementId = useAgreementsStore(state => state.agreement?.agreementId);
    const { company = {} } = useContext(CompanyContext);

    //Have to click the csv download in code, since the async version does not seem to work
    useEffect(() => {
        if (data.length > 0 && csvRef.current && csvRef.current.link) {
            setTimeout(() => {
                csvRef.current.link.click();
                cleanUp();
                toggle();
            });
        }
    }, [data, cleanUp, toggle]);

    const today = moment().format('DDMMYY');
    const fileName = `BMW Group Automated Terms - ${company.name} - ${agreementId} - ${today}.csv`;
    return (
        <>
            <DropdownItem
                className={`${!disabledReason ? 'cursor-pointer' : 'disabled'} ${className}`}
                disabled={!!disabledReason}
                onClick={getCapCodesAndMakeCsvData}
                style={{ pointerEvents: 'auto' }}
                title={disabledReason}
                toggle={false}
            >
                Download Leasing CSV
            </DropdownItem>
            {data.length > 0 ? (
                <CSVLink
                    data={data}
                    filename={fileName}
                    headers={[
                        'Series',
                        'IVS Code',
                        'Model Description',
                        'CapCode',
                        'Cap Id',
                        '',
                        isLeasing ? 'Base Discount' : 'Total Discount',
                        'Start Date',
                        'End Date',
                        'CIN Number',
                        'Company Name',
                        'Postcode'
                    ]}
                    ref={csvRef}
                />
            ) : null}
        </>
    );
}
