import create from 'zustand';
import { useDispatch } from 'react-redux';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import { apiIvsCodesPost } from '../../../actions/Api/Agreement/apiAgreement';

export const useIvsCodesStore = create(set => storeWithModal(set, 'ivsCodeId'));
export function useUpdateIvsCodes() {
    const updateIvsCodesFromFetch = useIvsCodesStore(state => state.updateDataFromFetch);
    const setError = useIvsCodesStore(state => state.setError);
    const dispatch = useDispatch();

    return ivsCodes => {
        dispatch(apiIvsCodesPost(ivsCodes))
            .then(response => {
                updateIvsCodesFromFetch(response, false);
                return response;
            })
            .catch(error => {
                setError(error);
            });
    };
}
