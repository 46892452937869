import React from 'react';

const WorkflowStatusChangeForm = ({ source, target }) => {
    return (
        <div>
            <p>Source: {source}</p>
            <p>Target: {target}</p>
        </div>
    );
};

export default WorkflowStatusChangeForm;
