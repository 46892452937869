import ActionButton from '../../common/ActionButtons/ActionButton';
import DashboardAdminCard from '../../Agreement/Dashboard/DashboardAdminCard';
import React from 'react';
import { permissions, RenderIfHasReadPermission } from '../../../reducers/Api/User/permissions';

export default function AgreementsAdminExternalDataLinks() {
    return (
        <RenderIfHasReadPermission permission={permissions.EXTERNAL_DATA}>
            <DashboardAdminCard className="mt-4" helpId="agreementsAdminExternalData" title="External Data">
                <ActionButton
                    bootstrapColor="secondary"
                    buttonText={
                        <div>
                            <h5>CAP Codes</h5>
                            <p>View and Import the cap code data</p>
                        </div>
                    }
                    fontawesomeIcon="fa-address-card"
                    to="../capcodes"
                />
                <ActionButton
                    bootstrapColor="secondary"
                    buttonText={
                        <div>
                            <h5>IVS codes</h5>
                            <p>View and import IVS codes, you can also mark ivs codes as obsolete here</p>
                        </div>
                    }
                    fontawesomeIcon="fa-address-card"
                    to="../ivs"
                />
            </DashboardAdminCard>
        </RenderIfHasReadPermission>
    );
}
