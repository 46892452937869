import React from 'react';
import MatrixPageCsv from './MatrixPageCsv';
import { Link } from 'react-router-dom';

export default function MatrixPageButtons() {
    return (
        <>
            <Link className="button btn btn-info ml-2" to="../capcodes">
                Cap Codes
            </Link>
            <Link className="button btn btn-info ml-2" to="../ivs">
                IVS Codes
            </Link>
            <MatrixPageCsv />
        </>
    );
}
