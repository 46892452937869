import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { getAxiosPostOfflineRequest, getAxiosPostRequest } from '../apiHelpers';

export const apiCompanyPost = (company, dealership) => async (dispatch, getState) => {
    const type = ENDPOINTS.API.COMPANY_POST;

    const axiosRequest = getAxiosPostOfflineRequest(['company'], dealership ? { dealership } : {}, company);

    let unmodified = getState().companies.find(x => company.companyId === x.companyId);
    unmodified.error = undefined;

    dispatch({
        type,
        data: { ...company, syncing: true },
        entityId: `${type}-${company.companyId}`, //Used in store.js to see if offline updates are targeting the same entity
        meta: {
            offline: {
                effect: axiosRequest,
                commit: { type: ENDPOINTS.API.COMPANY_POST_COMMIT, meta: { company } },
                rollback: {
                    type: ENDPOINTS.API.COMPANY_POST_COMMIT,
                    meta: { unmodified, company: { company } }
                }
            }
        }
    });
};

export const apiLeasingCompaniesPost = (leasingCompanies, agreementId) => (dispatch, getState) => {
    if (!getState().offline.online) {
        return new Promise(function(myResolve) {
            myResolve({});
        });
    }
    return axios(getAxiosPostRequest(['company', 'update-leasing-companies'], undefined, {leasingCompanies, agreementId}))
        .then(({ data }) => {
            toastr.success('Leasing Companies Update', 'Success');
            return data;
        })
        .catch(err => {
            if (getState().user.userId > -1) {
                toastr.error('Leasing Companies Update', `Response ${err.message}`);
            }

            throw err;
        });
};
