import React from 'react';
import { useResources } from '../../../../selectors/Api/useResources';
import { getChoiceListValue } from '../../LabelText';
import { FieldLabel } from '../../DisplayField/FieldLabel';
import { FieldValue } from '../../DisplayField/FieldValue';
import IconFactory from '../../IconFactory';
import { useToolTipContext } from '../../../Agreement/Components/AgreementHistoryBreadcrumbToolTip';

export default function JsonCellRenderer({ cellData }) {
    return <pre>{JSON.stringify(cellData, null, 2)}</pre>;
}

export const lookups = {
    Agreement_salesChannel: ['Sales Channel', 'salesChannel'],
    Agreement_status: ['Status', 'agreementHeaderStatus'],
    Agreement_StatusTo: ['Status', 'agreementHeaderStatus'],
    Agreement_activeSupportBonus: ['Matrix Banding', 'matrixBanding'],
    Agreement_agreementTemplate: ['Agreement Template', 'agreementTemplate'],
    Agreement_agreementType: ['Agreement Type', 'agreementTemplate'],
    Matrix_status: ['Status', 'masterTermVersionStatus']
};

const mappings = {
    Agreement_activeSupportBonus: [
        'Active Support Bonus',
        {
            1: 'Yes',
            2: 'No'
        }
    ],
    AgreementAgreementVolume_frequency: [
        'Active Support Bonus',
        {
            Y: 'Yearly',
            M: 'Monthly',
            Q: 'Quaterly'
        }
    ]
};

//[name, prefix, postfix]
const nameMapsWithPrefix = {
    AgreementAgreementTerm_base: ['Base Discount', '', '%'],
    AgreementAgreementTerm_nextTotalDiscount: ['Next Total Discount', '', '%'],
    AgreementAgreementTerm_totalDiscount: ['Total Discount', '', '%']
    //agreementId: ['Agreement Id', '',''],
};

export const formatAuditValue = (resources, parentEntity, entity, col, change) => {
    const key = `${parentEntity}${entity}_${col}`;
    return lookups[key]
        ? [lookups[key][0], getChoiceListValue(resources, lookups[key][1], change)]
        : mappings[key]
        ? [mappings[key][0], mappings[key][1][change] || change]
        : nameMapsWithPrefix[key]
        ? [nameMapsWithPrefix[key][0], `${nameMapsWithPrefix[key][1]}${change}${nameMapsWithPrefix[key][2]}`]
        : [col.split(/(?=[A-Z])/)?.join(' ') || col, change];
};

function AuditChange({ col, change, from, parentEntity, entity }) {
    parentEntity.replace(/\s\s+/g, ' ');

    const resources = useResources();
    const [formatCol, formatChange] = formatAuditValue(resources, parentEntity, entity, col, change);
    const formatFrom = formatAuditValue(resources, parentEntity, entity, col, from)[1];

    return (
        <>
            <FieldLabel>{formatCol}</FieldLabel>
            <FieldValue>
                {from ? formatFrom : '_'}
                <IconFactory icon={{ className: 'ml-2', id: 'fa-long-arrow-alt-right', type: 'fa' }} />
                {formatChange}
            </FieldValue>
        </>
    );
}

function AuditChanges({ cellData, parentEntity, entity }) {
    return (
        <div className="p-2" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            {cellData
                ?.filter(x => ['companyid'].indexOf(x.col.toLowerCase()) === -1)
                .map(({ col, change, from }) => (
                    <AuditChange
                        change={change}
                        col={col}
                        entity={entity}
                        from={from}
                        key={col}
                        parentEntity={parentEntity}
                    />
                ))}
        </div>
    );
}

export const AuditChangesCellRenderer = props => {
    const {
        cellData,
        rowData: { parentEntity, entity }
    } = props;
    const { setTooltipOpen, setTooltipClosed } = useToolTipContext();

    return (
        <>
            {cellData?.length === 1 && (
                <div className="w-100">
                    <AuditChanges {...props} entity={entity} parentEntity={parentEntity} />
                </div>
            )}
            {cellData?.length !== 1 && (
                <div
                    onMouseEnter={() =>
                        cellData?.length > 1 &&
                        setTooltipOpen(<AuditChanges {...props} entity={entity} parentEntity={parentEntity} />)
                    }
                    onMouseLeave={() => setTooltipClosed()}
                >
                    <span className="mr-2">{cellData?.length || 0}</span>
                    {cellData?.length > 0 && <IconFactory icon={{ type: 'fa', id: 'fa-info-circle' }} />}
                </div>
            )}
        </>
    );
};
