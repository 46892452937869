import { useDispatch } from 'react-redux';
import create from 'zustand';
import { apiAgreementMasterTermVersionSalesChannelsPost } from '../../../actions/Api/MasterTermVersion/apiMasterTermVersion';
import { storeWithModal } from '../../Agreement/Provider/storeStates';
import { useChoiceListForBaseTable } from '../../common/Hooks/useChoiceList';

const rowKey = 'masterTermsVersionSalesChannelId';
export const useMasterTermVersionSalesChannelsStore = create(set => storeWithModal(set, rowKey));

export function useMasterTermVersionSalesChannelsStoreAsStrings(separator) {
    const salesChannels = useChoiceListForBaseTable('salesChannel');
    const data = useMasterTermVersionSalesChannelsStore(state => state.data);

    const affectedChannels = data
        .filter(x => x.isUpdatesSalesChannel)
        .map(x => salesChannels.get(x.salesChannel))
        .join(separator);
    const commsSalesChannels = data
        .filter(x => x.isSendsCommunicationsToSalesChannel)
        .map(x => salesChannels.get(x.salesChannel))
        .join(separator);
    return { affectedChannels, commsSalesChannels };
}
export function useMasterTermVersionSalesStoreChannelKeysAsStrings(separator) {
    const data = useMasterTermVersionSalesChannelsStore(state => state.data);

    const affectedChannels = data
        .filter(x => x.isUpdatesSalesChannel)
        .map(x => x.salesChannel)
        .join(separator);
    const commsSalesChannels = data
        .filter(x => x.isSendsCommunicationsToSalesChannel)
        .map(x => x.salesChannel)
        .join(separator);
    return { affectedChannels, commsSalesChannels };
}

export function useMasterTermVersionSalesStoreChanges() {
    const data = useMasterTermVersionSalesChannelsStore(state => state.data);

    return (affectedChannels, commsSalesChannels) => {
        return data.reduce((acc, row) => {
            const isComSent = row.isSendsCommunicationsToSalesChannel;
            const isAffected = row.isUpdatesSalesChannel;

            const isUpdatedCommSent = commsSalesChannels.includes(row.salesChannel);
            const isUpdatedAffected = affectedChannels.includes(row.salesChannel);

            if (isComSent !== isUpdatedCommSent || isAffected !== isUpdatedAffected) {
                acc.push({
                    ...row,
                    isSendsCommunicationsToSalesChannel: isUpdatedCommSent,
                    isUpdatesSalesChannel: isUpdatedAffected
                });
            }

            return acc;
        }, []);
    };
}

export function useUpdateMasterTermVersionSalesChannels() {
    const updateFromFetch = useMasterTermVersionSalesChannelsStore(state => state.updateDataFromFetch);
    const setSaving = useMasterTermVersionSalesChannelsStore(state => state.setSaving);
    const setError = useMasterTermVersionSalesChannelsStore(state => state.setError);
    const dispatch = useDispatch();

    const masterTermVersionSalesStoreChanges = useMasterTermVersionSalesStoreChanges();

    return (affectedChannels, commsSalesChannels) => {
        const changes = masterTermVersionSalesStoreChanges(affectedChannels, commsSalesChannels);

        if (changes.length > 0) {
            setSaving();
            return dispatch(apiAgreementMasterTermVersionSalesChannelsPost(changes))
                .then(response => {
                    updateFromFetch(response || []);
                    return response;
                })
                .catch(error => {
                    setError(error);
                });
        }
    };
}
