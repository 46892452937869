import { createSelector } from 'reselect';
import { getChoiceList } from '../LabelText';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const makeChoiceListSelector = () =>
    createSelector(
        state => state.resources,
        (_, { options, choiceList, mapChoiceValues }) => ({ options, choiceList, mapChoiceValues }),
        (resources, { options, choiceList, mapChoiceValues }) =>
            options || getChoiceList(resources, choiceList, mapChoiceValues)
    );

export function useChoiceListValue(choiceList, key) {
    const list = useChoiceList(choiceList);
    const choice = list.find(x => x.key === key);
    return choice ? choice.value : '';
}

export const useChoiceList = (choiceList, mapChoiceValues, options) => {
    const selectChoiceList = useMemo(makeChoiceListSelector, []);
    return useSelector(state => selectChoiceList(state, { options, choiceList, mapChoiceValues }));
};

export const useChoiceListForSelect = (choiceList, mapChoiceValues, options) => {
    return useChoiceList(choiceList, mapChoiceValues, options).map(({ key, value }) => ({
        value: key,
        label: value
    }));
};

export const useFilteredChoiceList = (choiceList, mapChoiceValues, options, values, isMulti) => {
    return useChoiceList(choiceList, mapChoiceValues, options).filter(({ filterOnChoiceList, filterOnValue }) => {
        if (!values || !filterOnChoiceList) return true;
        else {
            const value = values[filterOnChoiceList] || values[`${filterOnChoiceList}s`];
            return (isMulti && !value) || value?.split(',').some(s => s === filterOnValue);
        }
    });
};

export const useChoiceListObject = (choiceList, mapChoiceValues, options) => {
    return useChoiceList(choiceList, mapChoiceValues, options).reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
    }, []);
};

export const useChoiceListForBaseTable = (choiceList, mapChoiceValues, options) => {
    return new Map(useChoiceList(choiceList, mapChoiceValues, options).map(({ key, value }) => [key, value]));
};
