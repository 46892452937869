import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useMasterTermStore } from '../../MasterTerm/Provider/useMasterTermStore';
import { useBMWStore, useMiniStore } from '../Provider/useTermsStore';

export default function MassTermsUpload({ className }) {
    const [mappedData, setMappedData] = useState([]);

    const bmwTerms = useBMWStore(state => state.dataCopy);
    const miniTerms = useMiniStore(state => state.dataCopy);

    const terms = useMemo(() => [...bmwTerms, ...miniTerms], [bmwTerms, miniTerms]);
    const masterTerms = useMasterTermStore(state => state.data);

    return (
        <CSVLink
            className={className}
            data={mappedData}
            filename="mass_terms.csv"
            headers={['Body Group', 'Model Code', 'Discount Rate']}
            onClick={() => {
                setMappedData(
                    masterTerms.flatMap(masterTerm => {
                        if (masterTerm.ivsCodes?.length > 0) {
                            return masterTerm.ivsCodes.map(ivsCode => {
                                let term = terms.find(term => term.masterTermId === masterTerm.masterTermId
                                    && term.ivsCodeId === ivsCode.ivsCodeId);

                                if (!term) {
                                    term = terms.find(term => term.masterTermId === masterTerm.masterTermId
                                        && !term.ivsCodeId);
                                }

                                return [
                                    term.devCode,
                                    ivsCode.ivsCode,
                                    term.totalDiscount?.toFixed(2)
                                ]
                            });
                        }
                        else {
                            const term = terms.find(term => term.masterTermId === masterTerm.masterTermId);
                            return [[
                                term.devCode,
                                '',
                                term.totalDiscount?.toFixed(2)
                            ]];
                        }
                    })
                );
            }}
        >
            Mass Terms Upload
        </CSVLink>
    );
}
