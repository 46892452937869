import React from 'react';
import { CaptionBadgeResources } from '../../FilteredTable/CaptionBadge';
import { types } from '../BaseTableInCard';
import { choiceListColumnDef } from '../../FilteredTable/tableAndFilterHelpers';
import { useChoiceListForBaseTable } from '../../Hooks/useChoiceList';

export function ChoiceListRenderer({ rowData, columnDef }) {
    return <CaptionBadgeResources caption={rowData[columnDef.field]} choiceList={columnDef.choiceList} />;
}

export const useChoicelistCellDefinition = (columnDef, extra = {}) => {
    const { title, field, choiceList } = columnDef;
    const select = useChoiceListForBaseTable(choiceList);
    return {
        ...choiceListColumnDef(title, field, choiceList),
        noGrow: true,
        width: 150,
        filter: true,
        sortable: true,
        type: types.SELECT,
        select,
        cellRenderer: props => <ChoiceListRenderer columnDef={columnDef} {...props} />,
        ...extra
    };
};
