import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PageSection from '../../common/PageSection';
import PageLoadingSpinner from '../../app/Pages/PageLoadingSpinner';
import { apiPage } from '../../../actions/Api/Page/apiPage';
import { AgreementNotificationTable, AgreementNotificationPeopleTable } from '../Tables/index';
import { useAgreementNotificationStore, useAgreementNotificationPeopleStore } from '../Provider/index';
import { TableHelpText } from '../../common/BaseTable/BaseTableInCard';
import { TabsContent, TabsGroup, TabsInCard, TabsTabForCard } from '../../common/Tabs';
import { AgreementNotificationGroupByButton, useNotificationsTableColumns } from '../Tables/AgreementNotificationTable';
import {
    AgreementNotificationPeopleTableGroupBy,
    useAgreementNotificationPeopleColumns
} from '../Tables/AgreementNotificationPeopleTable';
import AgreementNotificationModal from '../Modals/AgreementNotificationModal';
import AgreementNotificationPeopleModal from '../Modals/AgreementNotificationPeopleModal';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { agreementNotificationPeopleDef } from '../agreementNotificationDef';

export default function AgreementNotificationsPageContent() {
    const { goBack } = useHistory();
    const updateNotificationsFromFetch = useAgreementNotificationStore(state => state.updateDataFromFetch);
    const updateNotificationPeopleFromFetch = useAgreementNotificationPeopleStore(state => state.updateDataFromFetch);
    const dispatch = useDispatch();
    const isLoading = useIsLoading([ENDPOINTS.API.PAGE]);

    //fetch page
    useEffect(() => {
        dispatch(apiPage('agreement-notifications'))
            .then(response => {
                updateNotificationsFromFetch(response.agreementNotifications || [], true);
                updateNotificationPeopleFromFetch(response.agreementNotificationPeople || [], true);
            })
            .catch(err => console.error('error', err));
    }, [updateNotificationsFromFetch, updateNotificationPeopleFromFetch, dispatch]);

    const notificationsTableColumns = useNotificationsTableColumns();
    const allAgreementNotificationPeopleColumns = useAgreementNotificationPeopleColumns([]);

    const openAddPersonModal = useAgreementNotificationPeopleStore(state => state.openModal);
    const openAddNotificationModal = useAgreementNotificationStore(state => state.openModal);

    const [groupBy, setGroupBy] = useState();
    const onTabChanged = tabIndex => {
        setGroupBy(tabIndex === 1 ? agreementNotificationPeopleDef.agreementNotificationId.field : undefined);
    };

    return (
        <PageSection
            onBackClicked={goBack}
            title="Notifications"
            titleExtra={<PageLoadingSpinner />}
        >
            <AgreementNotificationModal />
            <AgreementNotificationPeopleModal />
            <TabsInCard
                buttons={{
                    0: [
                        {
                            onClick: () => openAddNotificationModal(),
                            content: 'Add Notification',
                            icon: 'fa-plus'
                        }
                    ],
                    1: [
                        {
                            onClick: () => openAddPersonModal(),
                            content: 'Add Notification Person',
                            icon: 'fa-plus'
                        }
                    ]
                }}
                help={{
                    0: {
                        helpId: 'agreementNotificationsTableHeading',
                        helpText: <TableHelpText columns={notificationsTableColumns} />
                    },
                    1: {
                        helpId: 'agreementNotificationPeopleTableHeading',
                        helpText: <TableHelpText columns={allAgreementNotificationPeopleColumns} />
                    }
                }}
                isLoading={isLoading}
                onTabChanged={onTabChanged}
                titleContentRight={{
                    0: <AgreementNotificationGroupByButton groupBy={groupBy} setGroupBy={setGroupBy} />,
                    1: <AgreementNotificationPeopleTableGroupBy groupBy={groupBy} setGroupBy={setGroupBy} />
                }}
            >
                <TabsGroup>
                    <TabsTabForCard>Notifications</TabsTabForCard>
                    <TabsContent>
                        <AgreementNotificationTable
                            columns={notificationsTableColumns}
                            groupBy={groupBy}
                            setGroupBy={setGroupBy}
                        />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTabForCard>People</TabsTabForCard>
                    <TabsContent>
                        <AgreementNotificationPeopleTable groupBy={groupBy} setGroupBy={setGroupBy} />
                    </TabsContent>
                </TabsGroup>
            </TabsInCard>
        </PageSection>
    );
}
