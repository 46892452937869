import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import LeasingCompanyForm from '../Forms/LeasingCompanyForm';
import { useLeasingCompaniesStore } from '../Provider/useLeasingCompaniesStore';

export default function LeasingCompanyModal() {
    const isOpen = useLeasingCompaniesStore(state => state.modalOpen);
    const close = useLeasingCompaniesStore(state => state.closeModal);

    return (
        <>
            <Modal className="modal-md" isOpen={isOpen} toggle={close}>
                <AppModalHeader toggle={close}>Add Leasing Company</AppModalHeader>
                <ModalBody>
                    <LeasingCompanyForm />
                </ModalBody>
            </Modal>
        </>
    );
}
